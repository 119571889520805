import { PropType } from "vue";
import FDVue from "@fd/lib/vue";
import {
  ScaffoldElevationModifier,
  ScaffoldRequestTypes,
  ScaffoldTypeModifier
} from "../../../services";
import BuildSheetFormBase from "./SP.BuildSheetFormBase";

export default FDVue.extend({
  name: "sp-build-sheet-form",
  mixins: [BuildSheetFormBase],
  components: {
    "fp-add-remove-field": () => import("@fd/lib/vue/components/FP.AddRemoveField.vue")
  },
  props: {
    scaffoldTypeModifiers: { type: Array as PropType<Array<ScaffoldTypeModifier>> },
    scaffoldElevationModifiers: { type: Array as PropType<Array<ScaffoldElevationModifier>> },
    totalTimesheetHours: { type: Number },

    length: { type: Number },
    width: { type: Number },
    height: { type: Number },
    vlfChangeAmount: { type: Number },
    scaffoldTypeModifierID: { type: String },
    scaffoldElevationModifierID: { type: String },

    hazardStatusResult: { type: String },
    confinedSpace: { type: Boolean },
    ppeRequired: { type: Boolean },
    deckLevels: { type: Number },

    currentScaffoldVlf: { type: Number },
    scaffoldTotalVLF: { type: Number },
    scaffoldRequestType: { type: Number }
  },
  data: function() {
    return {};
  },
  computed: {
    vlfBeforeValue(): number {
      return this.currentScaffoldVlf ?? this.scaffoldTotalVLF ?? 0;
    },
    allowVLFAdd(): boolean {
      return (
        this.scaffoldRequestType == ScaffoldRequestTypes.Erect ||
        this.scaffoldRequestType == ScaffoldRequestTypes.Modify
      );
    },
    allowVLFRemove(): boolean {
      return (
        this.scaffoldRequestType == ScaffoldRequestTypes.Modify ||
        this.scaffoldRequestType == ScaffoldRequestTypes.Dismantle
      );
    },
    totalNormHours(): number {
      let total = this.allNormsWithResponses.reduce((total, norm) => {
        return total + (norm.hours ?? 0);
      }, 0);
      return total;
    }
  },
  methods: {
    lengthUpdated(v: any) {
      this.fieldUpdated("length", this.$parse.sanitizedNumber(v));
    },
    widthUpdated(v: any) {
      this.fieldUpdated("width", this.$parse.sanitizedNumber(v));
    },
    heightUpdated(v: any) {
      this.fieldUpdated("height", this.$parse.sanitizedNumber(v));
    },
    scaffoldTypeModifierIDUpdated(v: any) {
      this.fieldUpdated("scaffoldTypeModifierID", v);
    },
    hazardStatusResultUpdated(v: any) {
      this.fieldUpdated("hazardStatusResult", v);
    },
    scaffoldElevationModifierIDUpdated(v: any) {
      this.fieldUpdated("scaffoldElevationModifierID", v);
    },
    confinedSpaceUpdated(v: any) {
      this.fieldUpdated("confinedSpace", v);
    },
    ppeRequiredUpdated(v: any) {
      this.fieldUpdated("ppeRequired", v);
    },
    deckLevelsUpdated(v: any) {
      this.fieldUpdated("deckLevels", this.$parse.sanitizedNumber(v));
    },
    vlfChangeAmountUpdated(v: any) {
      this.fieldUpdated("vlfChangeAmount", this.$parse.sanitizedNumber(v));
    }
  }
});
