import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import i18n from "../../../i18n";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  EquipmentWithDetails,
  FleetEquipmentWithName,
  FleetWithEquipment,
  Person
} from "../../../services";
import { VForm } from "@fd/lib/vue/types";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import rules from "@fd/lib/vue/rules";
import { SortItemsWithName } from "../../../utils/person";

type EquipmentWithDetailsAndSelected = EquipmentWithDetails & {};

type FleetEquipmentLike = {
  name: string | null | undefined;
  order: number | null | undefined;
};
function CompareFleetEquipment(a: FleetEquipmentLike, b: FleetEquipmentLike) {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name!.toLocaleLowerCase();
  let bName = b.name!.toLocaleLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}
export function SortFleetEquipment<T extends FleetEquipmentLike>(items: T[] | undefined): T[] {
  if (!items) return [];
  return items.sort(CompareFleetEquipment);
}

const FleetDetailsBottomDialog = FDVue.extend({
  name: "fd-fleet-details-bottom-dialog",

  mixins: [dialogSupport, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {},

  data: () => ({
    // *** GLOBAL ***
    step: 1,
    lastStep: 2,

    detailsStep: 1,
    equipmentStep: 2,

    detailserror: false,
    equipmenterror: false,

    // The following will control whether or not the save button shows the processing/loading indicator
    saving: false,

    // *** EQUIPMENT ***
    showOnlyIncludedEquipment: false,
    tablesearchequipment: "",
    selectableEquipment: [] as Array<EquipmentWithDetailsAndSelected>,

    sourceFleet: undefined as FleetWithEquipment | undefined,
    fleet: {
      name: "",
      description: "",
      ownerID: null,
      equipment: [] as FleetEquipmentWithName[]
    } as FleetWithEquipment
  }),

  computed: {
    unwatchedMethodNames(): string[] {
      return ["splitFilter", "isEquipmentSelected", "orderForItem"];
    },
    // *** EQUIPMENT ***

    equipment(): Array<EquipmentWithDetailsAndSelected> {
      let returnValue = this.selectableEquipment;
      if (this.showOnlyIncludedEquipment)
        returnValue = returnValue.filter(x => this.isEquipmentSelected(x));
      return returnValue;
    },

    selectedEquipmentIDs(): string[] {
      return this.fleet.equipment?.map(e => e.equipmentID!) ?? [];
      // return this.selectableEquipment.filter(x => this.isEquipmentSelected(x)).map(x => x.id!);
    },

    searchedEquipment(): Array<EquipmentWithDetailsAndSelected> {
      // This is a hack because the equipment list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .equipmentDataTable as any)?.customFilter;
      if (this.tablesearchequipment && customFilter) {
        return this.equipment.filter(
          x =>
            customFilter(x.name!, this.tablesearchequipment, x) ||
            customFilter(x.serialNumber!, this.tablesearchequipment, x) ||
            customFilter(x.modelNumber!, this.tablesearchequipment, x)
        );
      } else {
        return this.equipment;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedEquipmentSelected(): boolean {
      return this.searchedEquipment.findIndex(x => !this.isEquipmentSelected(x)) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedEquipmentSelected(): boolean {
      var searchedEquipment = this.searchedEquipment;
      return (
        searchedEquipment.findIndex(x => this.isEquipmentSelected(x)) !== -1 &&
        searchedEquipment.findIndex(x => !this.isEquipmentSelected(x)) !== -1
      );
    }
  },

  methods: {
    splitFilter(value: any, search: string | null, item: any): boolean {
      if (value == null || search == null || typeof value === "boolean") return false;

      value = value.toLocaleLowerCase();
      let searchArray = search.split(" ").map(x => x.toLocaleLowerCase());

      // If any search terms are NOT found in the value, return false
      return !(searchArray.findIndex(x => value.indexOf(x) === -1) !== -1);
    },
    async openForNew(
      contractorID: string,
      ownerID: string | null | undefined,
      defaultName: string | undefined = undefined,
      mountPoint?: Element | undefined
    ) {
      this.fleet.contractorID = contractorID;
      this.fleet.ownerID = ownerID;
      this.fleet.name = defaultName;
      this.optOutOfErrorHandling();
      return await this.showDialog!(mountPoint);
    },
    filterInactiveEquipment() {
      if (!this.equipment?.length || !this.fleet) return;

      this.fleet.equipment = this.fleet.equipment?.filter(
        x => this.equipment.findIndex(e => e.id == x.equipmentID) !== -1
      );
    },
    async openExisting(fleet: FleetWithEquipment, mountPoint?: Element | undefined) {
      this.sourceFleet = fleet;
      this.fleet = { ...fleet } as FleetWithEquipment;
      this.filterInactiveEquipment();
      SortFleetEquipment(this.fleet.equipment);
      this.optOutOfErrorHandling();
      return await this.showDialog!(mountPoint);
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailserror = !(this.$refs.detailsform as VForm).validate();

      return !(this.detailserror || this.equipmenterror);
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      if (!this.validate()) {
        var message = i18n.t("contractors.fleets.new-fleet.error-message");
        if (this.detailserror)
          message += "\n\t- " + i18n.t("contractors.fleets.new-fleet.steps.details");
        if (this.equipmenterror)
          message += "\n\t- " + i18n.t("contractors.fleets.new-fleet.steps.equipment");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }

      this.saving = true;
      this.processing = true;

      try {
        if (!this.fleet.id) {
          var newFleetID = await this.$store.dispatch("ADD_FLEET", {
            ...this.fleet
          } as FleetWithEquipment);
          this.fleet.id = newFleetID;
          this.fleet.equipment?.forEach(x => (x.fleetID = newFleetID));
        } else {
          await this.$store.dispatch("UPDATE_FLEET", this.fleet);
        }
        if (!!this.sourceFleet) {
          this.sourceFleet.name = this.fleet.name;
          this.sourceFleet.description = this.fleet.description;
          this.sourceFleet.ownerID = this.fleet.ownerID;
          this.sourceFleet.ownerName = this.fleet.ownerName;
          this.sourceFleet.equipment = this.fleet.equipment;
        }
        this.closeDialog(newFleetID);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    ...mapActions({
      loadEquipment: "LOAD_EQUIPMENTS"
    }),

    // *** EQUIPMENT ***
    orderForItem(id: string) {
      return this.fleet.equipment?.find(x => x.equipmentID == id)?.order;
    },
    async equipmentSelected(item: EquipmentWithDetailsAndSelected) {
      if (this.isEquipmentSelected(item)) return;

      if (!this.fleet.equipment) this.fleet.equipment = [];
      let newFleetEquipment = {
        fleetID: this.fleet.id,
        equipmentID: item.id,
        name: item.name,
        order: this.fleet.equipment.length + 1
      } as FleetEquipmentWithName;
      this.fleet.equipment.push(newFleetEquipment);
    },
    async equipmentDeselected(equipment: EquipmentWithDetails) {
      if (!this.fleet.equipment) return;

      let index = this.fleet.equipment.findIndex(x => x.equipmentID == equipment.id!);
      if (index < 0) return;

      this.fleet.equipment.splice(index, 1);

      this.fleet.equipment.forEach((item, index) => {
        // console.log(`  ${item.name}: ${item.order} --> ${index + 1}`);
        item.order = index + 1;
      });
    },
    isEquipmentSelected(equipment: EquipmentWithDetails) {
      let selectedEquipmentIDs = this.selectedEquipmentIDs;
      return selectedEquipmentIDs.indexOf(equipment.id!) > -1;
    },
    flipEquipmentSelected(item: EquipmentWithDetailsAndSelected) {
      let itemIsSelected = this.isEquipmentSelected(item);
      if (!itemIsSelected) this.equipmentSelected(item);
      else this.equipmentDeselected(item);
    },

    flipSearchedEquipmentSelected() {
      let selected = !this.allSearchedEquipmentSelected;
      for (let equipment of this.searchedEquipment) {
        if (selected) this.equipmentDeselected(equipment);
        else this.equipmentSelected(equipment);
      }
    }
  },

  created: async function() {
    this.processing = true;

    // *** EQUIPMENT ***
    await Promise.all([this.loadEquipment()]);

    this.selectableEquipment = SortItemsWithName(
      (this.$store.state.equipment.fullList as EquipmentWithDetails[]).filter(
        x => x.contractorID == this.fleet.contractorID
      )
    );

    this.filterInactiveEquipment();

    this.processing = false;
  }
});

export default FleetDetailsBottomDialog;

export async function createNewFleet(
  contractorID: string,
  ownerID: string | undefined | null = undefined,
  defaultName: string | undefined = undefined,
  parent?: Vue | Element | null | undefined
): Promise<string | boolean> {
  let dialog = createDialog(FleetDetailsBottomDialog);
  dialog.optOutOfErrorHandling();
  let mountPoint = undefined;
  if (!!parent) {
    mountPoint = document.createElement("div");
    mountPoint.id = "mountPoint";
    if (!!(parent as Vue)) (parent as Vue).$el.appendChild(mountPoint);
    else if (!!(parent as Element)) (parent as Element).appendChild(mountPoint);
  }
  return await dialog.openForNew(contractorID, ownerID, defaultName, mountPoint);
}

export async function updateExistingFleet(
  fleet: FleetWithEquipment,
  parent?: Vue | Element | null | undefined
): Promise<string | boolean> {
  let dialog = createDialog(FleetDetailsBottomDialog);
  dialog.optOutOfErrorHandling();
  let mountPoint = undefined;
  if (!!parent) {
    mountPoint = document.createElement("div");
    mountPoint.id = "mountPoint";
    if (!!(parent as Vue)) (parent as Vue).$el.appendChild(mountPoint);
    else if (!!(parent as Element)) (parent as Element).appendChild(mountPoint);
  }
  return await dialog.openExisting(fleet, mountPoint);
}
