export enum TimesheetRowResourceType {
  Employee = 1,
  Equipment = 2
}
export enum TimesheetRowType {
  DirectWorkOrderRelated = 1,
  DirectGeneral = 2,
  Indirect = 3,
  Equipment = 4
}

export class TimesheetRowTimeValues {
  regularTime = null as number | null;
  overTime = null as number | null;
  doubleTime = null as number | null;
  adding(other: TimesheetRowTimeValues | null | undefined): TimesheetRowTimeValues {
    return new TimesheetRowTimeValues(
      (this.regularTime ?? 0) + (other?.regularTime ?? 0),
      (this.overTime ?? 0) + (other?.overTime ?? 0),
      (this.doubleTime ?? 0) + (other?.doubleTime ?? 0)
    );
  }
  constructor(
    regularTime: number | null = null,
    overTime: number | null = null,
    doubleTime: number | null = null,
    rawValue?: string | number | null | undefined
  ) {
    this.regularTime = regularTime;
    this.overTime = overTime;
    this.doubleTime = doubleTime;

    if (rawValue == null || rawValue == undefined) return;
    if (typeof rawValue == "string" && (rawValue as string).length == 0) return;

    if (!isNaN(Number(rawValue))) {
      // If just a number is typed in this field, we parse it as regular time
      this.regularTime = Number(rawValue);
    } else {
      // A basic number was NOT entered, try to parse out based on "[#]R[#]O[#]D"
      let segmentStart = 0;
      let combinedValue = `${rawValue}`.toLowerCase();

      let rIndex = combinedValue.indexOf("r");
      if (rIndex == -1) rIndex = combinedValue.indexOf("s");
      if (rIndex >= 0) {
        let regularTimeString = combinedValue.substring(segmentStart, rIndex);
        let regularTime = Number(regularTimeString);
        if (!isNaN(regularTime)) this.regularTime = regularTime;
        segmentStart = rIndex + 1;
      }

      let oIndex = combinedValue.indexOf("o");
      if (oIndex >= 0) {
        let overTimeString = combinedValue.substring(segmentStart, oIndex);
        let overTime = Number(overTimeString);
        if (!isNaN(overTime)) this.overTime = overTime;
        segmentStart = oIndex + 1;
      }

      let dIndex = combinedValue.indexOf("d");
      if (dIndex >= 0) {
        let doubleTimeString = combinedValue.substring(segmentStart, dIndex);
        let doubleTime = Number(doubleTimeString);
        if (!isNaN(doubleTime)) this.doubleTime = doubleTime;
        segmentStart = dIndex + 1;
      }
    }
  }
  get showAdvanced(): boolean {
    return !!this.overTime || !!this.doubleTime;
  }
  get summaryString(): string | null {
    if ((!this.overTime || this.overTime == 0) && (!this.doubleTime || this.doubleTime == 0)) {
      return this.regularTime == null || this.regularTime == undefined
        ? null
        : Number(this.regularTime).toFixed(2);
    }

    let values = [];

    if (!!this.regularTime && this.regularTime != 0) {
      values.push(`${this.regularTime.toFixed(2)}s`);
    }
    if (!!this.overTime && this.overTime != 0) {
      values.push(`${this.overTime.toFixed(2)}o`);
    }
    if (!!this.doubleTime && this.doubleTime != 0) {
      values.push(`${this.doubleTime.toFixed(2)}d`);
    }

    return values.join(" ");
  }
  get totalTime(): number {
    return (this.regularTime ?? 0) + (this.overTime ?? 0) + (this.doubleTime ?? 0);
  }
}
export type TimesheetRowTimes = {
  [key: string]: TimesheetRowTimeValues;
};

export function CalculateRowTotalTime(row: TimesheetRow): TimesheetRowTimeValues {
  return CalculateRowTimes(row, row.times);
}
export function CalculateRowRelatedCorrectionTotalTime(row: TimesheetRow): TimesheetRowTimeValues {
  return CalculateRowTimes(row, row.relatedCorrectionTimes);
}
export function CalculateRowTimes(
  row: TimesheetRow,
  times: TimesheetRowTimes | undefined
): TimesheetRowTimeValues {
  if (!times) return new TimesheetRowTimeValues();

  let rowSubTypeIDs = ParseWorkSubTypeIDsFromRow(row);
  let total = rowSubTypeIDs.reduce(
    (a: TimesheetRowTimeValues, b: string) => a.adding(times[b]),
    new TimesheetRowTimeValues()
  );
  if (!!row.equipmentHours && !isNaN(Number(row.equipmentHours))) {
    total.regularTime = (total.regularTime ?? 0) + Number(row.equipmentHours);
  }
  return total;
}
export class TimesheetRow {
  get totalTime(): TimesheetRowTimeValues {
    return CalculateRowTimes(this, this.times);
  }
  get relatedCorrectionTotalTime(): TimesheetRowTimeValues | undefined {
    if (!this.relatedCorrectionTimes) return undefined;
    return CalculateRowTimes(this, this.relatedCorrectionTimes);
  }
  get effectiveTime(): TimesheetRowTimeValues {
    return this.totalTime.adding(this.relatedCorrectionTotalTime);
  }
  get effectiveHasPerDiem(): boolean {
    return this.hasPerDiem && !this.removePerDiem && !this.relatedCorrectionRemovePerDiem;
  }
  get effectiveRemovePerDiem(): boolean {
    return this.removePerDiem && !this.hasPerDiem && !this.relatedCorrectionHasPerDiem;
  }
  get effectiveEquipmentHours(): number {
    return (this.equipmentHours ?? 0) + (this.relatedCorrectionEquipmentHours ?? 0);
  }
  get effectiveEquipmentQuantity(): number {
    return (this.equipmentQuantity ?? 0) + (this.relatedCorrectionEquipmentQuantity ?? 0);
  }
  get effectiveEquipmentDays(): number {
    return (this.equipmentDays ?? 0) + (this.relatedCorrectionEquipmentDays ?? 0);
  }
  constructor(values: {
    timesheetNumber?: string | null | undefined;
    resourceType: TimesheetRowResourceType;
    rowType: TimesheetRowType;
    rowNumber?: number;
    contractorName?: string | null | undefined;
    employeeID?: string | null | undefined;
    employeeName?: string | null | undefined;
    employeeCode?: string | null | undefined;
    employeeBadge?: string | null | undefined;
    scaffoldID?: string | undefined;
    scaffoldNumber?: number | null | undefined;
    areaID?: string | undefined;
    subAreaID?: string | undefined;
    workOrderID?: string | null | undefined;
    workOrderNumber?: string | null | undefined;
    workOrderCostCodeID?: string | null | undefined;
    workOrderClientWorkOrderNumber?: string | null | undefined;
    workOrderChangeOrderNumber?: string | null | undefined;
    workOrderReworkNumber?: string | null | undefined;
    workOrderServiceOrderNumber?: string | null | undefined;
    workOrderPurchaseOrderNumber?: string | null | undefined;
    classificationID?: string | null | undefined;
    classificationDisplayName?: string | null | undefined;
    errorMessage?: string | null | undefined;
    isCorrectionRow?: boolean;
    hasPerDiem?: boolean;
    relatedCorrectionHasPerDiem?: boolean;
    removePerDiem?: boolean;
    relatedCorrectionRemovePerDiem?: boolean;
    equipmentCostCodeID: string | null | undefined;
    equipmentCostCodeDisplayName?: string | null | undefined;
    equipmentHours?: number;
    relatedCorrectionEquipmentHours?: number;
    equipmentDays?: number;
    relatedCorrectionEquipmentDays?: number;
    equipmentQuantity?: number;
    relatedCorrectionEquipmentQuantity?: number;
    times?: TimesheetRowTimes;
    relatedCorrectionTimes?: TimesheetRowTimes;
  }) {
    this.timesheetNumber = values.timesheetNumber;
    this.resourceType = values.resourceType;
    this.rowType = values.rowType;
    this.rowNumber = values.rowNumber ?? 0;

    this.contractorName = values.contractorName;
    this.employeeID = values.employeeID ?? "";
    this.employeeName = values.employeeName;
    this.employeeCode = values.employeeCode;
    this.employeeBadge = values.employeeBadge;

    this.scaffoldID = values.scaffoldID;
    this.scaffoldNumber = values.scaffoldNumber;
    this.areaID = values.areaID;
    this.subAreaID = values.subAreaID;

    this.workOrderID = values.workOrderID;
    this.workOrderNumber = values.workOrderNumber;
    this.workOrderCostCodeID = values.workOrderCostCodeID;

    this.workOrderClientWorkOrderNumber = values.workOrderClientWorkOrderNumber;
    this.workOrderChangeOrderNumber = values.workOrderChangeOrderNumber;
    this.workOrderReworkNumber = values.workOrderReworkNumber;
    this.workOrderServiceOrderNumber = values.workOrderServiceOrderNumber;
    this.workOrderPurchaseOrderNumber = values.workOrderPurchaseOrderNumber;

    this.classificationID = values.classificationID;
    this.classificationDisplayName = values.classificationDisplayName;

    this.errorMessage = values.errorMessage ?? "";

    this.isCorrectionRow = values.isCorrectionRow ?? false;

    this.hasPerDiem = values.hasPerDiem ?? false;
    this.relatedCorrectionHasPerDiem = values.relatedCorrectionHasPerDiem ?? false;

    this.removePerDiem = values.removePerDiem ?? false;
    this.relatedCorrectionRemovePerDiem = values.relatedCorrectionRemovePerDiem ?? false;

    this.equipmentCostCodeID = values.equipmentCostCodeID;
    this.equipmentCostCodeDisplayName = values.equipmentCostCodeDisplayName;

    this.equipmentHours = values.equipmentHours ?? 0;
    this.relatedCorrectionEquipmentHours = values.relatedCorrectionEquipmentHours;

    this.equipmentDays = values.equipmentDays ?? 0;
    this.relatedCorrectionEquipmentDays = values.relatedCorrectionEquipmentDays;

    this.equipmentQuantity = values.equipmentQuantity ?? 0;
    this.relatedCorrectionEquipmentQuantity = values.relatedCorrectionEquipmentQuantity;

    this.times = values.times ?? {};
    this.relatedCorrectionTimes = values.relatedCorrectionTimes;
  }
  timesheetNumber: string | null | undefined;
  contractorName: string | null | undefined;
  employeeID: string;
  employeeName: string | null | undefined;
  employeeCode?: string | null | undefined;
  employeeBadge?: string | null | undefined;
  classificationID: string | null | undefined;
  classificationDisplayName?: string | null | undefined;
  resourceType: TimesheetRowResourceType;
  rowType: TimesheetRowType;
  rowNumber: number;
  workOrderID: string | null | undefined;
  workOrderNumber: string | null | undefined;
  workOrderClientWorkOrderNumber: string | null | undefined;
  workOrderServiceOrderNumber: string | null | undefined;
  workOrderPurchaseOrderNumber: string | null | undefined;
  workOrderChangeOrderNumber: string | null | undefined;
  workOrderReworkNumber: string | null | undefined;
  workOrderPurchaseOrderID: string | null | undefined;
  workOrderExistingTagNumber: string | null | undefined;
  scaffoldID: string | null | undefined;
  scaffoldNumber: number | null | undefined;
  workOrderCostCodeID: string | null | undefined;
  areaID: string | null | undefined;
  areaName: string | null | undefined;
  errorMessage: string;
  subAreaID: string | null | undefined;
  subAreaName: string | null | undefined;
  isCorrectionRow: boolean;
  hasPerDiem: boolean;
  relatedCorrectionHasPerDiem: boolean;
  removePerDiem: boolean;
  relatedCorrectionRemovePerDiem: boolean;
  equipmentCostCodeID: string | null | undefined;
  equipmentCostCodeDisplayName: string | null | undefined;
  equipmentHours: number | null | undefined;
  relatedCorrectionEquipmentHours: number | null | undefined;
  equipmentDays: number | null | undefined;
  relatedCorrectionEquipmentDays: number | null | undefined;
  equipmentQuantity: number | null | undefined;
  relatedCorrectionEquipmentQuantity: number | null | undefined;
  times: TimesheetRowTimes;
  relatedCorrectionTimes: TimesheetRowTimes | undefined;
  notesCount: number | null | undefined;
}
export function areTimesheetRowsEqual(
  a: {
    isCorrectionRow: boolean;
    employeeID: string | undefined;
    classificationID: string | null | undefined;
    resourceType: TimesheetRowResourceType;
    rowType: TimesheetRowType;
    workOrderID: string | null | undefined;
    areaID: string | null | undefined;
    subAreaID: string | null | undefined;
  },
  b: {
    isCorrectionRow: boolean;
    employeeID: string | undefined;
    classificationID: string | null | undefined;
    resourceType: TimesheetRowResourceType;
    rowType: TimesheetRowType;
    workOrderID: string | null | undefined;
    areaID: string | null | undefined;
    subAreaID: string | null | undefined;
  },
  options: {
    ignoreClassification?: boolean;
    ignoreArea?: boolean;
    ignoreSubArea?: boolean;
    relatedForCorrection?: boolean;
  } = {}
): boolean {
  return (
    ((!!options.relatedForCorrection && a.isCorrectionRow != b.isCorrectionRow) ||
      (!options.relatedForCorrection && a.isCorrectionRow == b.isCorrectionRow)) &&
    a.resourceType == b.resourceType &&
    a.rowType == b.rowType &&
    (a.employeeID ?? "") == (b.employeeID ?? "") &&
    (!!options.ignoreClassification || (a.classificationID ?? "") == (b.classificationID ?? "")) &&
    (a.workOrderID ?? "") == (b.workOrderID ?? "") &&
    (!!options.ignoreArea || (a.areaID ?? "") == (b.areaID ?? "")) &&
    (!!options.ignoreSubArea || (a.subAreaID ?? "") == (b.subAreaID ?? ""))
  );
}
export function ParseWorkSubTypeIDsFromRow(row: TimesheetRow): string[] {
  // We need an entry for each work sub type with a value for this row
  // These work sub types are added as keys to the object, but we don't want to create entries for the non-wst keys (like employee, sub area, etc.)
  return ParseWorkSubTypeIDsFromRowTimes(row.times);
}
export function ParseWorkSubTypeIDsFromRowTimes(times: TimesheetRowTimes): string[] {
  // We need an entry for each work sub type with a value for this row
  // These work sub types are added as keys to the object, but we don't want to create entries for the non-wst keys (like employee, sub area, etc.)
  return Object.keys(times);
}

function CompareTimesheetRows(a: TimesheetRow, b: TimesheetRow): number {
  let aRowNumber = a.rowNumber ?? 0;
  let bRowNumber = b.rowNumber ?? 0;
  if (aRowNumber != bRowNumber) {
    return aRowNumber - bRowNumber;
  }

  let aName = a.employeeName!.toLocaleLowerCase();
  let bName = b.employeeName!.toLocaleLowerCase();
  if (aName != bName) {
    if (aName < bName) return -1;
    else if (aName > bName) return 1;
  }

  let aWorkOrderNumber = !isNaN(Number(a.workOrderNumber)) ? Number(a.workOrderNumber!) : 0;
  let aHasWorkOrder = aWorkOrderNumber > 0;
  let bWorkOrderNumber = !isNaN(Number(b.workOrderNumber)) ? Number(b.workOrderNumber!) : 0;
  let bHasWorkOrder = bWorkOrderNumber > 0;
  if (aHasWorkOrder != bHasWorkOrder) {
    if (aHasWorkOrder) return -1;
    else return 1;
  }

  if (aWorkOrderNumber != bWorkOrderNumber) {
    return aWorkOrderNumber - bWorkOrderNumber;
  }

  let aHasArea = !!a.areaID;
  let aAreaID = a.areaID ?? "";
  let aAreaName = a.areaName?.toLowerCase() ?? "";
  let bHasArea = !!b.areaID;
  let bAreaID = b.areaID ?? "";
  let bAreaName = b.areaName?.toLowerCase() ?? "";

  if (aHasArea != bHasArea) {
    if (aHasArea) return -1;
    else return 1;
  }

  if (aAreaID != bAreaID) {
    if (aAreaName > bAreaName) return -1;
    else return 1;
  }

  let aHasSubArea = !!a.subAreaID;
  let aSubAreaID = a.subAreaID ?? "";
  let aSubAreaName = a.subAreaName?.toLowerCase() ?? "";
  let bHasSubArea = !!b.subAreaID;
  let bSubAreaID = b.subAreaID ?? "";
  let bSubAreaName = b.subAreaName?.toLowerCase() ?? "";

  if (aHasSubArea != bHasSubArea) {
    if (aHasSubArea) return -1;
    else return 1;
  }

  if (aSubAreaID != bSubAreaID) {
    if (aSubAreaName > bSubAreaName) return -1;
    else return 1;
  }

  let aIsCorrectionRow = a.isCorrectionRow;
  let bIsCorrectionRow = b.isCorrectionRow;
  if (aIsCorrectionRow != bIsCorrectionRow) {
    if (aIsCorrectionRow) return 1;
    else return -1;
  }

  return 0;
}
export function SortTimesheetRows(rows: TimesheetRow[]): TimesheetRow[] {
  return rows.sort(CompareTimesheetRows);
}
