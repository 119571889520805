import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import {
  ProjectCostCode,
  WorkSubType,
  WorkSubTypeWithParentDetails,
  WorkType,
  workSubTypeService,
  workTypeService
} from "../services";
import rules from "@fd/lib/vue/rules";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDHiddenArgumentName,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import archivedDataList from "../dataMixins/archivedDataList";
import { createNewWorkSubType } from "./components/dialogs/WorkSubTypeNewDialog.vue";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";

type WorkSubTypeWithArchived = WorkSubType & { archived: boolean };
type WorkSubTypeWithArchivedAndMisconfigured = WorkSubTypeWithArchived & { misconfigured: boolean };

export default FDVue.extend({
  name: "sp-work-sub-type-existing",

  mixins: [rules, tabbedView, archivedDataList],

  components: {},
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data() {
    return {
      loaded: false,
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      firstTabKey: `0`,
      detailsTab: new PageTab({
        nameKey: "work-types.work-sub-types.existing.tabs.details",
        key: "0",
        visible: true
      }),
      timesheetTab: new PageTab({
        nameKey: "work-types.work-sub-types.existing.tabs.timesheet",
        key: "1",
        visible: true
      }),
      childTypesTab: new PageTab({
        nameKey: "work-types.work-sub-types.existing.tabs.child-types",
        key: "2",
        visible: false
      }),

      //Simple object for the form data
      workSubType: {} as WorkSubTypeWithArchived,
      workType: {} as WorkType,
      childSubTypes: [] as WorkSubTypeWithArchivedAndMisconfigured[]
    };
  },
  computed: {
    canMoveToNewParent(): boolean {
      if (!!this.workSubType.isParent || !!this.childSubTypes.length) return false;
      let possibleParents = (this.$store.state.workSubTypes
        .fullList as WorkSubTypeWithParentDetails[]).filter(
        x =>
          x.workTypeID == this.workSubType.workTypeID &&
          !!x.isParent &&
          x.id != this.workSubType.parentWorkSubTypeID
      );
      return !!possibleParents.length;
    },
    subTypeCanHaveChildSubTypes(): boolean {
      // If the work sub type is already a parent, let it stay that way to avoid UI clashes
      // Otherwise, equipment and per diem sub types cannot have child types
      return (
        this.workSubType.isParent ||
        (this.workType.isEquipment == false && this.workType.isPerDiem == false)
      );
    },
    isParentDisabledMessage(): string {
      if (!this.subTypeCanHaveChildSubTypes)
        return this.$t(
          "work-types.work-sub-types.existing.per-diem-sub-type-cannot-have-child-types"
        ) as string;
      else if (!!this.workSubType.parentWorkSubTypeID)
        return this.$t(
          "work-types.work-sub-types.existing.child-sub-type-cannot-have-child-types"
        ) as string;
      else if (this.workSubType.isParent && !!this.childSubTypes.length)
        return this.$t(
          "work-types.work-sub-types.existing.sub-type-must-stay-parent-message"
        ) as string;

      return "";
    },
    tabDefinitions(): Tab[] {
      return [this.timesheetTab, this.childTypesTab];
    },

    childSubTypeSearchString: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },
    allCostCodes(): ProjectCostCode[] {
      return this.$store.state.projectCostCodes.fullList as ProjectCostCode[];
    },
    workSubTypeIsMisconfigured(): boolean {
      return (
        !!this.workSubType.id &&
        !this.workSubType.isParent &&
        !this.workSubType.useWorkOrderCostCode &&
        !this.workSubType.defaultCostCodeID
      );
    },
    //#region Child Types
    anyMisconfiguredSubTypes(): boolean {
      return this.childSubTypes.findIndex(x => !!x.misconfigured) !== -1;
    },
    iconColumnArgument(): string {
      return this.anyMisconfiguredSubTypes ? "hasMisconfiguredSubTypes" : FDHiddenArgumentName;
    }
    //#endregion
  },

  watch: {
    workSubType: async function(newValue) {
      this.updateBreadCrumbs();
    },
    "workSubType.isParent": function(newValue, oldValue) {
      if (!this.loaded || newValue == oldValue) return;
      console.log(`workSubType.isParent ${oldValue} -> ${newValue}`);
    },
    "workSubType.defaultCostCodeID": function(newValue, oldValue) {
      if (!this.loaded) return;
      console.log(`workSubType.defaultCostCodeID ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;
      if (!!newValue) this.workSubType.useWorkOrderCostCode = false;
    },
    "workSubType.useWorkOrderCostCode": function(newValue, oldValue) {
      if (!this.loaded) return;
      console.log(`workSubType.useWorkOrderCostCode ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;
      if (!!newValue) this.workSubType.defaultCostCodeID = null;
    },
    "workSubType.isWorkOrderRelated": function(newValue, oldValue) {
      if (!this.loaded) return;
      console.log(`workSubType.isWorkOrderRelated ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;
      if (!newValue) this.workSubType.useWorkOrderCostCode = false;
    }
  },

  methods: {
    updateBreadCrumbs() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/worktypes") {
        this.notifyNewBreadcrumb({
          text: this.$t("work-types.list.title"),
          to: "/worktypes",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.workType.name,
          to: `/worktypes/${this.$route.params.worktypeid}`
        });

        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      if (!!this.workSubType?.name?.length) {
        this.notifyNewBreadcrumb({
          text: this.workSubType.name,
          to: `/worktypes/${this.$route.params.worktypeid}/worksubtypes/${this.$route.params.id}`
        });
      } else {
        this.notifyNewBreadcrumb({
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        });
      }
    },
    close() {
      if (this.$route.name == "WorkSubSubTypeExisting") {
        this.$router.push(
          `/worktypes/${this.$route.params.worktypeid}/worksubtypes/${this.$route.params.parentsubtypeid}`
        );
      } else {
        this.$router.push(`/worktypes/${this.$route.params.worktypeid}`);
      }
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    async moveToParent() {
      if (!this.canMoveToNewParent) return;
      this.inlineMessage.message = null;

      let possibleParents = (this.$store.state.workSubTypes
        .fullList as WorkSubTypeWithParentDetails[]).filter(
        x =>
          x.workTypeID == this.workSubType.workTypeID &&
          !!x.isParent &&
          x.id != this.workSubType.parentWorkSubTypeID
      );

      let newParentID = await showItemSelectionDialog(
        this.$t("work-types.work-sub-types.existing.move-to-parent-dialog-title"),
        this.$t("work-types.work-sub-types.existing.move-to-parent-dialog-field-label"),
        undefined,
        possibleParents,
        "name",
        "id"
      );
      if (!newParentID?.length) {
        return;
      }

      this.processing = true;
      try {
        await workSubTypeService.moveUnderOtherWorkSubType(this.$route.params.id, newParentID);
        // TODO: Show snack bar
        this.close();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      if (
        !this.workSubType.isParent &&
        !this.workSubType.useWorkOrderCostCode &&
        !this.workSubType.defaultCostCodeID
      ) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.workSubType.archived) {
          this.workSubType.archivedDate = null;
        } else if (this.workSubType.archived && !this.workSubType.archivedDate) {
          this.workSubType.archivedDate = new Date(new Date().toUTCString());
        }

        if (!!this.workSubType.isParent) {
          this.workSubType.defaultCostCodeID = null;
          this.workSubType.useWorkOrderCostCode = false;
          this.workSubType.isWorkOrderRelated = false;
        }

        await this.updateWorkSubType(this.workSubType);
        if (closeOnComplete) {
          this.close();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Method used in conjunction with the Delete button.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteWorkSubType({ id: this.workSubType.id, name: this.workSubType.name });
        this.close();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.close();
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadWorkSubType: "LOAD_WORK_SUB_TYPE",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      updateWorkSubType: "UPDATE_WORK_SUB_TYPE",
      deleteWorkSubType: "DELETE_WORK_SUB_TYPE",
      loadCostCodes: "LOAD_PROJECT_COST_CODES"
    }),

    //#region Child Sub Types
    getCostCodeName(item: WorkSubType): string | undefined {
      if (!!item.useWorkOrderCostCode || !item.defaultCostCodeID?.length) return undefined;

      return this.allCostCodes.find(c => c.id == item.defaultCostCodeID)?.name;
    },
    async openNewChildSubTypeDialog() {
      await createNewWorkSubType(
        this.$route.params.worktypeid,
        this.childSubTypes.length,
        this.$route.params.id
      );
      this.resetChildSubTypes();
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      await this.$store.dispatch("DELETE_WORK_SUB_TYPE", item);
      await this.reloadWorkSubTypes();
    },

    async flipArchived(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateWorkSubType({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
        this.reloadWorkSubTypes();
      } catch (error) {
        this.handleError(error as Error, "work-types.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async reloadWorkSubTypes() {
      await this.loadWorkSubTypes({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
      this.resetChildSubTypes();
    },
    async loadData() {
      await this.reloadWorkSubTypes();
    },
    async dragEnded(e: SortableEvent) {
      console.log(`dragEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.childSubTypes.splice(newIndex, 0, ...this.childSubTypes.splice(oldIndex, 1));
      this.childSubTypes.forEach((item, index) => {
        item.order = index + 1;
      });

      await this.updateWorkSubTypeOrdering(
        this.$t("work-types.work-sub-types.snack-bar-order-updated-message") as string,
        null
      );
    },

    async confirmItemOrdering() {
      console.log(`confirmItemOrdering total SubTypes: ${this.childSubTypes.length}`);

      let itemsToUpdate = [] as WorkSubType[];

      this.childSubTypes.forEach((subType, index) => {
        let newOrder = index + 1;
        if (!subType.order || subType.order != newOrder) {
          console.log(`    ${subType.order} --> ${newOrder}`);
          subType.order = newOrder;
          itemsToUpdate.push(subType);
        }
      });

      if (itemsToUpdate.length > 0) {
        console.log(` updating ${itemsToUpdate.length} subTypes' ordering`);
        await workSubTypeService.updateWorkSubTypeOrders(itemsToUpdate);

        var snackbarPayload = {
          text: this.$t("work-types.work-sub-types.snack-bar-order-updated-message"),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
    },

    async updateWorkSubTypeOrdering(message: string, undoCallback: Function | null) {
      this.processing = true;
      this.saving = true;
      try {
        let selectedWorkSubTypes = this.childSubTypes;
        // Just in case we've added or removed a sub type, or if they load with incorrect orders, re-set them based on location
        selectedWorkSubTypes.forEach((subType, index) => {
          subType.order = index + 1;
        });
        await workSubTypeService.updateWorkSubTypeOrders(selectedWorkSubTypes);

        var snackbarPayload = {
          text: message,
          type: "success",
          undoCallback: undoCallback
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    resetChildSubTypes() {
      this.childSubTypes = (this.$store.state.workSubTypes.fullList as WorkSubType[])
        .filter(x => x.parentWorkSubTypeID == this.$route.params.id)
        .map(
          x =>
            ({
              ...x,
              archived: !!x.archivedDate,
              misconfigured: !x.useWorkOrderCostCode && !x.defaultCostCodeID && !x.isParent
            } as WorkSubTypeWithArchivedAndMisconfigured)
        )
        .sort((a, b) => {
          let aOrder = a.order ?? 0;
          let bOrder = b.order ?? 0;
          if (aOrder != bOrder) return aOrder - bOrder;

          let aName = a.name!.toLocaleLowerCase();
          let bName = b.name!.toLocaleLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
    }
    //#endregion
  },

  created: async function() {
    this.inlineMessage.message = null;
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "work-type-sub-type-existing",
      parentalContext: "work-type-existing",
      selectedTab: this.firstTabKey,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });

    let workTypeID = this.$route.params.worktypeid;
    this.workType = this.$store.state.workTypes.fullList.find((x: any) => x.id == workTypeID);
    if (!this.workType) {
      this.workType = await workTypeService.getByID(workTypeID);
    }
    this.updateBreadCrumbs();

    try {
      await this.loadCostCodes();
      await this.loadWorkSubTypes();
      let workSubType = (this.$store.state.workSubTypes.fullList as WorkSubType[]).find(
        x => x.id == this.$route.params.id
      )!;
      if (!workSubType) {
        await this.loadWorkSubType(this.$route.params.id);
        workSubType = (this.$store.state.workSubTypes.fullList as WorkSubType[]).find(
          x => x.id == this.$route.params.id
        )!;
      }
      this.workSubType = {
        ...workSubType,
        archived: !!workSubType.archivedDate
      };

      this.resetChildSubTypes();

      this.$nextTick(() => {
        this.loaded = true;
      });
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});
