var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "pa-0 d-flex align-center justify-end",
      attrs: { fluid: "" }
    },
    [
      _c("div", { staticClass: "pl-3 fd-context-filter-label" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("countsheetgroups.filter-by-craft-type")) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fd-button-context-separator",
          class: {
            "mx-3": _vm.$vuetify.breakpoint.smAndUp,
            "mx-2": _vm.$vuetify.breakpoint.xsOnly
          }
        },
        [_vm._v("\n    |\n  ")]
      ),
      _vm._v(" "),
      _c("v-select", {
        staticClass: "py-2 pr-3 fd-context-filter",
        attrs: {
          outlined: "",
          dense: _vm.$vuetify.breakpoint.xsOnly,
          items: _vm.items,
          value: _vm.value,
          "hide-details": "",
          multiple: ""
        },
        on: { input: v => _vm.$emit("input", v) },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function({ item, index }) {
              return [
                index === 0 && _vm.allItemsSelected
                  ? _c(
                      "v-chip",
                      { attrs: { small: _vm.$vuetify.breakpoint.xsOnly } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("common.all")) +
                            "\n      "
                        )
                      ]
                    )
                  : index === 0
                  ? _c(
                      "v-chip",
                      { attrs: { "x-small": _vm.$vuetify.breakpoint.xsOnly } },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: {
                              small: _vm.$vuetify.breakpoint.smAndUp,
                              "x-small": _vm.$vuetify.breakpoint.xsOnly
                            }
                          },
                          [_vm._v(_vm._s(_vm.$store.getters.icon(item.value)))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "pr-1 fd-context-filter-text" },
                          [
                            _vm._v(
                              "\n          " + _vm._s(item.text) + "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                index === 1 && !_vm.allItemsSelected && _vm.value.length == 2
                  ? _c(
                      "v-chip",
                      { attrs: { "x-small": _vm.$vuetify.breakpoint.xsOnly } },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: {
                              small: _vm.$vuetify.breakpoint.smAndUp,
                              "x-small": _vm.$vuetify.breakpoint.xsOnly
                            }
                          },
                          [_vm._v(_vm._s(_vm.$store.getters.icon(item.value)))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "pr-1 fd-context-filter-text" },
                          [
                            _vm._v(
                              "\n          " + _vm._s(item.text) + "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : index === 1 && !_vm.allItemsSelected
                  ? _c("span", { staticClass: "fd-context-filter-detail" }, [
                      _vm._v(
                        "\n        (+" +
                          _vm._s(_vm.value.length - 1) +
                          " others)\n      "
                      )
                    ])
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }