var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/worktypes/`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pb-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          attrs: { "icons-and-text": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detailsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pb-md-0 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.worktype.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.worktype, "name", $$v)
                                          },
                                          expression: "worktype.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.code"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.worktype.code,
                                          callback: function($$v) {
                                            _vm.$set(_vm.worktype, "code", $$v)
                                          },
                                          expression: "worktype.code"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mx-1" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-3 mb-0 pb-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("common.category"))
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-1 fd-inline-radio-button-qualifier"
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            disabled: _vm.processing,
                                            row: ""
                                          },
                                          model: {
                                            value: _vm.category,
                                            callback: function($$v) {
                                              _vm.category = $$v
                                            },
                                            expression: "category"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.direct"),
                                              value: "direct"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.per-diem"),
                                              value: "perdiem"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.equipment"),
                                              value: "equipment"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.indirect"),
                                              value: "indirect"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t("common.archived"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.worktype.archived,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.worktype,
                                              "archived",
                                              $$v
                                            )
                                          },
                                          expression: "worktype.archived"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.inlineMessage.message
                                        ? _c(
                                            "v-alert",
                                            {
                                              staticClass: "mr-0",
                                              attrs: {
                                                type: _vm.inlineMessage.type
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.inlineMessage.message
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-0 mr-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                link: "",
                                                disabled: _vm.processing,
                                                color: "error",
                                                outlined: "",
                                                small:
                                                  _vm.$vuetify.breakpoint
                                                    .name == "xs"
                                              },
                                              on: { click: _vm.deleteItem }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.delete"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                text: "",
                                                link: "",
                                                disabled: _vm.processing,
                                                small:
                                                  _vm.$vuetify.breakpoint
                                                    .name == "xs"
                                              },
                                              on: { click: _vm.cancel }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.cancel"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("fd-menu-save", {
                                            attrs: {
                                              disabled: _vm.processing,
                                              loading: _vm.saving,
                                              small:
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            on: {
                                              "click:save": function($event) {
                                                return _vm.save(false)
                                              },
                                              "click:save-and-close": function(
                                                $event
                                              ) {
                                                return _vm.save(true)
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.subTypeTab.key,
                              attrs: { href: `#tab-${_vm.subTypeTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.subTypeTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.subTypeTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [
                                _vm._v("fas fa-triangle-person-digging")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.subTypeTab.key,
                              attrs: { value: `tab-${_vm.subTypeTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c("fd-inline-add-button", {
                                            on: {
                                              click: function($event) {
                                                return _vm.openNewWorkSubTypeDialog()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "work-types.work-sub-types.listing-header-additional-info"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: "Search",
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearch,
                                              callback: function($$v) {
                                                _vm.tablesearch = $$v
                                              },
                                              expression: "tablesearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-table-sortable",
                                            rawName: "v-fd-table-sortable"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:[iconColumnArgument].no-sort.class_fd-sm-table-icon-cell.hide-when-small",
                                            value: "",
                                            expression:
                                              "\n                      ''\n                    ",
                                            arg: _vm.iconColumnArgument,
                                            modifiers: {
                                              "no-sort": true,
                                              "class_fd-sm-table-icon-cell": true,
                                              "hide-when-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name.no-sort",
                                            value: _vm.$t("common.name"),
                                            expression: "$t('common.name')",
                                            arg: "name",
                                            modifiers: { "no-sort": true }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:code",
                                            value: _vm.$t("common.alias"),
                                            expression: "$t('common.alias')",
                                            arg: "code"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:costCode",
                                            value: _vm.$t(
                                              "common.default-cost-code"
                                            ),
                                            expression:
                                              "$t('common.default-cost-code')",
                                            arg: "costCode"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:isWorkOrderRelated",
                                            value: _vm.$t(
                                              "common.work-order-related"
                                            ),
                                            expression:
                                              "$t('common.work-order-related')",
                                            arg: "isWorkOrderRelated"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:requiresAdditionalDetails",
                                            value: _vm.$t(
                                              "work-types.work-sub-types.requires-additional-details-label"
                                            ),
                                            expression:
                                              "\n                      $t('work-types.work-sub-types.requires-additional-details-label')\n                    ",
                                            arg: "requiresAdditionalDetails"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:archived.no-sort.hide-when-extra-small.hide-when-header-text-empty",
                                            value: _vm.showArchived
                                              ? _vm.$t("common.archived")
                                              : "",
                                            expression:
                                              "\n                      showArchived ? $t('common.archived') : ''\n                    ",
                                            arg: "archived",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "hide-when-header-text-empty": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.no-sort.show-when-extra-small",
                                            value: item =>
                                              _vm.$router.push(
                                                `/worktypes/${_vm.$route.params.id}/worksubtypes/${item.id}`
                                              ),
                                            expression:
                                              "\n                      item =>\n                        $router.push(`/worktypes/${$route.params.id}/worksubtypes/${item.id}`)\n                    ",
                                            modifiers: {
                                              "no-sort": true,
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "fd-actions-table fd-archiving-table",
                                        class: [
                                          {
                                            "fd-archive-bonus-padding-bottom":
                                              _vm.showArchived
                                          }
                                        ],
                                        attrs: {
                                          "disable-sort": "",
                                          items: _vm.workSubTypes,
                                          search: _vm.tablesearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0",
                                          "items-per-page": 100,
                                          "footer-props": {
                                            "items-per-page-options": [
                                              5,
                                              25,
                                              50,
                                              100,
                                              -1
                                            ]
                                          },
                                          "show-expand":
                                            _vm.hasAnyParentTypesWithChildren
                                        },
                                        on: { "sort:end": _vm.dragEnded },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.data-table-expand",
                                            fn: function({
                                              isExpanded,
                                              expand,
                                              item
                                            }) {
                                              return [
                                                item.childTypes.length
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          justify: "center",
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "v-data-table__expand-icon",
                                                            class: {
                                                              "v-data-table__expand-icon--active": isExpanded
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return expand(
                                                                  !isExpanded
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-chevron-down"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "expanded-item",
                                            fn: function({ item: subType }) {
                                              return [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "fd-embedded-table-container",
                                                    attrs: { colspan: "99" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-subheader",
                                                      {
                                                        staticClass:
                                                          "white--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "work-types.work-sub-types.child-types-table-title"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-data-table", {
                                                      directives: [
                                                        {
                                                          name: "fd-column",
                                                          rawName:
                                                            "v-fd-column:[childTableIconColumnArgument].no-sort.class_fd-sm-table-icon-cell.hide-when-small",
                                                          value: "",
                                                          expression:
                                                            "\n                            ''\n                          ",
                                                          arg:
                                                            _vm.childTableIconColumnArgument,
                                                          modifiers: {
                                                            "no-sort": true,
                                                            "class_fd-sm-table-icon-cell": true,
                                                            "hide-when-small": true
                                                          }
                                                        },
                                                        {
                                                          name: "fd-column",
                                                          rawName:
                                                            "v-fd-column:order.hidden",
                                                          arg: "order",
                                                          modifiers: {
                                                            hidden: true
                                                          }
                                                        },
                                                        {
                                                          name: "fd-column",
                                                          rawName:
                                                            "v-fd-column:name",
                                                          arg: "name"
                                                        },
                                                        {
                                                          name: "fd-column",
                                                          rawName:
                                                            "v-fd-column:code",
                                                          value: _vm.$t(
                                                            "common.alias"
                                                          ),
                                                          expression:
                                                            "$t('common.alias')",
                                                          arg: "code"
                                                        },
                                                        {
                                                          name: "fd-column",
                                                          rawName:
                                                            "v-fd-column:costCode",
                                                          value: _vm.$t(
                                                            "common.default-cost-code"
                                                          ),
                                                          expression:
                                                            "$t('common.default-cost-code')",
                                                          arg: "costCode"
                                                        },
                                                        {
                                                          name: "fd-column",
                                                          rawName:
                                                            "v-fd-column:isWorkOrderRelated",
                                                          value: _vm.$t(
                                                            "common.work-order-related"
                                                          ),
                                                          expression:
                                                            "$t('common.work-order-related')",
                                                          arg:
                                                            "isWorkOrderRelated"
                                                        },
                                                        {
                                                          name: "fd-column",
                                                          rawName:
                                                            "v-fd-column:requiresAdditionalDetails",
                                                          value: _vm.$t(
                                                            "work-types.work-sub-types.requires-additional-details-label"
                                                          ),
                                                          expression:
                                                            "\n                            $t('work-types.work-sub-types.requires-additional-details-label')\n                          ",
                                                          arg:
                                                            "requiresAdditionalDetails"
                                                        }
                                                      ],
                                                      attrs: {
                                                        items:
                                                          subType.childTypes,
                                                        "disable-sort": "",
                                                        "sort-by": "order",
                                                        loading: _vm.processing,
                                                        "loading-text": _vm.$t(
                                                          "common.table-loading-message"
                                                        ),
                                                        "mobile-breakpoint":
                                                          "0",
                                                        "items-per-page": -1,
                                                        "footer-props": {
                                                          "items-per-page-options": [
                                                            -1
                                                          ]
                                                        },
                                                        "hide-default-footer":
                                                          ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key:
                                                              "item.hasMisconfiguredSubTypes",
                                                            fn: function({
                                                              item
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      right: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function({
                                                                            on
                                                                          }) {
                                                                            return [
                                                                              item.misconfigured
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      {
                                                                                        attrs: {
                                                                                          small:
                                                                                            "",
                                                                                          color:
                                                                                            "error"
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "fas fa-exclamation-circle"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "work-types.work-sub-types.cost-code-misconfiguration-error-message"
                                                                          )
                                                                        ) +
                                                                        "\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "item.isWorkOrderRelated",
                                                            fn: function({
                                                              item
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "fd-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        item.isWorkOrderRelated,
                                                                      disabled:
                                                                        _vm.processing,
                                                                      readonly: true
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "item.costCode",
                                                            fn: function({
                                                              item
                                                            }) {
                                                              return [
                                                                item.useWorkOrderCostCode
                                                                  ? _c(
                                                                      "fd-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            item.useWorkOrderCostCode,
                                                                          disabled:
                                                                            _vm.processing,
                                                                          readonly: true,
                                                                          "on-text": _vm.$t(
                                                                            "work-types.work-sub-types.use-work-order-cost-code-label"
                                                                          )
                                                                        }
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "fd-value-display",
                                                                      {
                                                                        attrs: {
                                                                          value: _vm.getCostCodeName(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "item.requiresAdditionalDetails",
                                                            fn: function({
                                                              item
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "fd-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        item.requiresAdditionalDetails,
                                                                      disabled:
                                                                        _vm.processing,
                                                                      readonly: true
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key:
                                              "item.hasMisconfiguredSubTypes",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { right: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              item.misconfigured
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "error"
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "fas fa-exclamation-circle"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "work-types.work-sub-types.cost-code-misconfiguration-error-message"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.fd-drag",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("far fa-grip-lines")]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "footer.prepend",
                                            fn: function() {
                                              return [
                                                _c("fd-archived-data-loader", {
                                                  staticClass: "ml-2 mr-5",
                                                  attrs: {
                                                    showArchived:
                                                      _vm.showArchived,
                                                    showArchivedDateRange:
                                                      _vm.showArchivedDateRange,
                                                    showArchivedMinDate:
                                                      _vm.showArchivedMinDate,
                                                    showArchivedMaxDate:
                                                      _vm.showArchivedMaxDate,
                                                    loading:
                                                      _vm.archivedLoading,
                                                    disabled: _vm.processing,
                                                    "hide-date-range-picker": ""
                                                  },
                                                  on: {
                                                    "update:showArchived": function(
                                                      $event
                                                    ) {
                                                      _vm.showArchived = $event
                                                    },
                                                    "update:show-archived": function(
                                                      $event
                                                    ) {
                                                      _vm.showArchived = $event
                                                    },
                                                    "update:showArchivedDateRange": function(
                                                      $event
                                                    ) {
                                                      _vm.showArchivedDateRange = $event
                                                    },
                                                    "update:show-archived-date-range": function(
                                                      $event
                                                    ) {
                                                      _vm.showArchivedDateRange = $event
                                                    }
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.fd-nav",
                                            fn: function() {
                                              return [
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-right")
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.isWorkOrderRelated",
                                            fn: function({ item }) {
                                              return [
                                                item.isParent
                                                  ? _c("span")
                                                  : _c("fd-checkbox", {
                                                      attrs: {
                                                        value:
                                                          item.isWorkOrderRelated,
                                                        disabled:
                                                          _vm.processing,
                                                        readonly: true
                                                      }
                                                    })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.costCode",
                                            fn: function({ item }) {
                                              return [
                                                item.isParent
                                                  ? _c("span")
                                                  : item.useWorkOrderCostCode
                                                  ? _c("fd-checkbox", {
                                                      attrs: {
                                                        value:
                                                          item.useWorkOrderCostCode,
                                                        disabled:
                                                          _vm.processing,
                                                        readonly: true,
                                                        "on-text": _vm.$t(
                                                          "work-types.work-sub-types.use-work-order-cost-code-label"
                                                        )
                                                      }
                                                    })
                                                  : _c("fd-value-display", {
                                                      attrs: {
                                                        value: _vm.getCostCodeName(
                                                          item
                                                        )
                                                      }
                                                    })
                                              ]
                                            }
                                          },
                                          {
                                            key:
                                              "item.requiresAdditionalDetails",
                                            fn: function({ item }) {
                                              return [
                                                item.isParent
                                                  ? _c("span")
                                                  : _c("fd-checkbox", {
                                                      attrs: {
                                                        value:
                                                          item.requiresAdditionalDetails,
                                                        disabled:
                                                          _vm.processing,
                                                        readonly: true
                                                      }
                                                    })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.archived",
                                            fn: function({ item }) {
                                              return [
                                                _c("v-simple-checkbox", {
                                                  attrs: {
                                                    value: item.archived,
                                                    disabled: _vm.processing
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.flipArchived(
                                                        item
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to: `/worktypes/${_vm.$route.params.id}/worksubtypes/${item.id}`
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteTableItem(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }