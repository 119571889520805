import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import notes, { ParseNoteWithSenderDetails, SortNotesArray } from "../../../dataMixins/notes";
import { TimesheetNoteThread, noteService } from "../../../services";

type NoteThreadDialogResult = number | undefined;
type NoteThreadDialogRowDetails = {
  timesheetID: string;
  isCorrection: boolean;
  employeeID: string;
  equipmentID: string;
  employeeClassificationID?: string | null | undefined;
  equipmentClassificationID?: string | null | undefined;
  workOrderID?: string | null | undefined;
  areaID?: string | null | undefined;
  subAreaID?: string | null | undefined;
  // This `parentContext` link allows the caller to pass in the actual row, so we can manually update the notes count as soon as a new note is posted.
  parentContext?: { notesCount: number | null | undefined } | null | undefined;
};
const NoteThreadDialog = FDVue.extend({
  name: "sp-note-thread-dialog",

  mixins: [dialogSupport, notes],

  data: function() {
    return {
      saving: false,
      timesheetID: "",
      isCorrection: true,
      employeeID: "",
      equipmentID: "",
      employeeClassificationID: undefined as string | null | undefined,
      equipmentClassificationID: undefined as string | null | undefined,
      workOrderID: undefined as string | null | undefined,
      areaID: undefined as string | null | undefined,
      subAreaID: undefined as string | null | undefined,
      // This `parentContext` link allows the caller to pass in the actual row, so we can manually update the notes count as soon as a new note is posted.
      parentContext: undefined as { notesCount: number | null | undefined } | null | undefined
    };
  },
  computed: {
    unwatchedMethodNames(): string[] {
      return ["open"];
    },
    rowDetails(): TimesheetNoteThread {
      return {
        timesheetID: this.timesheetID,
        isCorrection: this.isCorrection,
        employeeID: this.employeeID,
        equipmentID: this.equipmentID,
        classificationID: this.employeeClassificationID,
        equipmentClassificationID: this.equipmentClassificationID,
        workOrderID: this.workOrderID,
        areaID: this.areaID,
        subAreaID: this.subAreaID
      } as TimesheetNoteThread;
    }
  },
  watch: {},
  methods: {
    async loadData() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        let notes = await noteService.getForTimesheetRow(this.rowDetails);
        this.notes = SortNotesArray(notes.map(x => ParseNoteWithSenderDetails(x)));
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },
    async open(rowDetails: NoteThreadDialogRowDetails): Promise<NoteThreadDialogResult> {
      this.timesheetID = rowDetails.timesheetID;
      this.isCorrection = rowDetails.isCorrection;
      this.employeeID = rowDetails.employeeID;
      this.equipmentID = rowDetails.equipmentID;
      this.employeeClassificationID = rowDetails.employeeClassificationID;
      this.equipmentClassificationID = rowDetails.equipmentClassificationID;
      this.workOrderID = rowDetails.workOrderID;
      this.areaID = rowDetails.areaID;
      this.subAreaID = rowDetails.subAreaID;
      this.parentContext = rowDetails.parentContext;
      this.loadData();

      if (await this.showDialog()) {
        return this.notes.length;
      } else {
        return undefined;
      }
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async addNewNote() {
      if (!this.newNoteText.length || !this.timesheetID?.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newNote = await noteService.addNewNoteForTimesheetRow(
          this.newNoteText,
          this.rowDetails
        );
        this.inlineMessage.message = "";
        let noteToAdd = ParseNoteWithSenderDetails(newNote);
        noteToAdd.isNew = true;
        this.notes.push(noteToAdd);
        this.notes = SortNotesArray(this.notes);
        this.newNoteText = "";
        if (this.parentContext) this.parentContext.notesCount = this.notes.length;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    }
  }
});
export default NoteThreadDialog;

export async function openNoteThreadDialog(
  rowDetails: NoteThreadDialogRowDetails
): Promise<NoteThreadDialogResult> {
  let dialog = createDialog(NoteThreadDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(rowDetails);
}
