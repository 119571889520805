var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1024px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: {
          "complete-button-label-key": "common.done",
          name: _vm.imageName
        },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "scaffolddesignnew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("scaffold-designs.new.dialog-title")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.currentUserCanCreateScaffoldDesigns
            ? _c(
                "v-alert",
                { staticClass: "mx-3 mt-3", attrs: { type: "info" } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("scaffold-designs.no-permission-to-create")
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-stepper",
            {
              attrs: { "non-linear": "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                {
                  staticClass: "fd-responsive-stepper",
                  attrs: { height: "60" }
                },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.basicStep,
                        step: _vm.basicStep,
                        editable: "",
                        rules: [() => !_vm.basicStepError]
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("scaffold-designs.new.steps.details")) +
                          "\n          "
                      ),
                      _vm.basicStepError
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "scaffold-designs.new.steps.details-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.photosStep,
                        step: _vm.photosStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("scaffold-designs.new.steps.photos")) +
                          "\n          "
                      ),
                      _vm.photosStepError
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "scaffold-designs.new.steps.photos-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.attachmentsStep,
                        step: _vm.attachmentsStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("scaffold-designs.new.steps.attachments")
                          ) +
                          "\n          "
                      ),
                      _vm.attachmentsStepError
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "scaffold-designs.new.steps.attachments-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.processing || _vm.saving,
                        expression: "processing || saving"
                      }
                    ],
                    attrs: { indeterminate: _vm.processing || _vm.saving }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-0 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.basicStep }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "basicform",
                          staticClass: "scaffold-design-form",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "", tile: "" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 px-2 fd-inline-scaffold-design-keywords-qualifier",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-chip-selector", {
                                        attrs: {
                                          "available-items":
                                            _vm.availableKeywords,
                                          "selected-items":
                                            _vm.selectedKeywords,
                                          "item-label": "name",
                                          disabled: _vm.processing,
                                          "control-label": _vm.$t(
                                            "common.add-tags"
                                          ),
                                          "chip-class":
                                            "keywords-selection-chip-colors",
                                          outlined: false
                                        },
                                        on: {
                                          "update:selectedItems": function(
                                            $event
                                          ) {
                                            _vm.selectedKeywords = $event
                                          },
                                          "update:selected-items": function(
                                            $event
                                          ) {
                                            _vm.selectedKeywords = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 pt-3",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("fd-date-picker", {
                                        attrs: {
                                          cy: "requireddate",
                                          disabled: _vm.processing,
                                          label: _vm.$t(
                                            "scaffold-designs.required-date-label"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldDesign.requiredDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "requiredDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldDesign.requiredDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          "data-cy": "designer",
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-designs.assigned-label"
                                          ),
                                          disabled: _vm.processing,
                                          items: _vm.allDesigners,
                                          "item-text": "name",
                                          "item-value": "id",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldDesign.assignedPersonID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "assignedPersonID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldDesign.assignedPersonID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-designs.reviewer-label"
                                          ),
                                          disabled: _vm.processing,
                                          items: _vm.allReviewers,
                                          "item-text": "name",
                                          "item-value": "id",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.scaffoldDesign.reviewerID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "reviewerID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldDesign.reviewerID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-designs.manager-label"
                                          ),
                                          disabled: _vm.processing,
                                          items: _vm.allManagers,
                                          "item-text": "name",
                                          "item-value": "id",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.scaffoldDesign.managerID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "managerID",
                                              $$v
                                            )
                                          },
                                          expression: "scaffoldDesign.managerID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-1",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          cy: "navisworkstag",
                                          label: _vm.$t(
                                            "scaffold-designs.navisworks-tag-label"
                                          ),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldDesign.navisworksTag,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "navisworksTag",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldDesign.navisworksTag"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-0 pb-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          "data-cy": "isengineeringrequired",
                                          label: _vm.$t(
                                            "scaffold-designs.is-engineering-required-label"
                                          ),
                                          disabled: _vm.processing,
                                          "persistent-hint": "",
                                          hint: _vm.$t(
                                            "scaffold-designs.is-engineering-required-hint"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldDesign
                                              .isEngineeringRequired,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "isEngineeringRequired",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldDesign.isEngineeringRequired"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 pb-3 mt-3",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c("v-subheader", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scaffold-designs.location-sub-header"
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "data-cy": "area",
                                          label: _vm.$t(
                                            "scaffold-designs.area-label"
                                          ),
                                          disabled: _vm.processing,
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.areas,
                                          rules: _vm.scaffoldDesignRules.areaID,
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.scaffoldDesign.areaID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "areaID",
                                              $$v
                                            )
                                          },
                                          expression: "scaffoldDesign.areaID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "6"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "data-cy": "subarea",
                                          label: _vm.$t(
                                            "scaffold-designs.sub-area-label"
                                          ),
                                          disabled:
                                            !_vm.scaffoldDesign.areaID ||
                                            _vm.processing,
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.subAreas,
                                          rules:
                                            _vm.scaffoldDesignRules.subAreaID,
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.scaffoldDesign.subAreaID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "subAreaID",
                                              $$v
                                            )
                                          },
                                          expression: "scaffoldDesign.subAreaID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "12"
                                      }
                                    },
                                    [
                                      _c("fd-textarea", {
                                        attrs: {
                                          cy: "location",
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-designs.specific-work-location-label"
                                          ),
                                          disabled: _vm.processing,
                                          rules:
                                            _vm.scaffoldDesignRules
                                              .specificWorkLocation,
                                          rows: "1",
                                          "auto-grow": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldDesign
                                              .specificWorkLocation,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "specificWorkLocation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldDesign.specificWorkLocation"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c("fd-textarea", {
                                        attrs: {
                                          cy: "description",
                                          outlined: "",
                                          label: _vm.$t(
                                            "scaffold-designs.detailed-work-description-label"
                                          ),
                                          disabled: _vm.processing,
                                          rules:
                                            _vm.scaffoldDesignRules
                                              .detailedWorkDescription,
                                          rows: "1",
                                          "auto-grow": ""
                                        },
                                        model: {
                                          value:
                                            _vm.scaffoldDesign
                                              .detailedWorkDescription,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "detailedWorkDescription",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scaffoldDesign.detailedWorkDescription"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-1 pb-0 reduce-underlying-control-padding",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c("fd-work-package-selector", {
                                        attrs: {
                                          loading: _vm.processing,
                                          refreshItems: _vm.loadWorkPackages,
                                          items: _vm.availableIWPs,
                                          disabled: _vm.processing,
                                          rules:
                                            _vm.scaffoldDesignRules
                                              .workPackageIDs
                                        },
                                        on: {
                                          focused: function($event) {
                                            {
                                              _vm.hideActionButtons = true
                                            }
                                          },
                                          unfocused: function($event) {
                                            {
                                              _vm.hideActionButtons = false
                                            }
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedIWPs,
                                          callback: function($$v) {
                                            _vm.selectedIWPs = $$v
                                          },
                                          expression: "selectedIWPs"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 pb-3 mt-3",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c("v-subheader", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scaffold-designs.coordinates-label"
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-0",
                                      attrs: { cols: "12", sm: "4" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-designs.coordinates-x-label"
                                          ),
                                          disabled: _vm.processing,
                                          numeric: "",
                                          type: "number",
                                          "hide-spin-buttons": ""
                                        },
                                        model: {
                                          value: _vm.scaffoldDesign.coordX,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "coordX",
                                              $$v
                                            )
                                          },
                                          expression: "scaffoldDesign.coordX"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-0",
                                      attrs: { cols: "12", sm: "4" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-designs.coordinates-y-label"
                                          ),
                                          disabled: _vm.processing,
                                          numeric: "",
                                          type: "number",
                                          "hide-spin-buttons": ""
                                        },
                                        model: {
                                          value: _vm.scaffoldDesign.coordY,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "coordY",
                                              $$v
                                            )
                                          },
                                          expression: "scaffoldDesign.coordY"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-0",
                                      attrs: { cols: "12", sm: "4" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffold-designs.coordinates-z-label"
                                          ),
                                          disabled: _vm.processing,
                                          numeric: "",
                                          type: "number",
                                          "hide-spin-buttons": ""
                                        },
                                        model: {
                                          value: _vm.scaffoldDesign.coordZ,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.scaffoldDesign,
                                              "coordZ",
                                              $$v
                                            )
                                          },
                                          expression: "scaffoldDesign.coordZ"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-1 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.photosStep }
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "filesform", on: { submit: _vm.preventSubmit } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c("fd-add-file-button", {
                                        attrs: { disabled: _vm.processing },
                                        on: { change: _vm.selectNewFile }
                                      }),
                                      _vm._v(" "),
                                      _c("v-divider", {
                                        staticClass: "mx-4",
                                        attrs: { inset: "", vertical: "" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "ml-1 mr-3",
                                                        attrs: {
                                                          color: "black",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("info")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scaffold-designs.photos-table-listing-tooltip"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "pl-0",
                                        attrs: {
                                          "append-icon": "search",
                                          label: _vm.$t("common.search"),
                                          "single-line": "",
                                          "hide-details": "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.tablesearchfiles,
                                          callback: function($$v) {
                                            _vm.tablesearchfiles = $$v
                                          },
                                          expression: "tablesearchfiles"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("fd-alert", {
                                    staticClass: "mx-3",
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "attachments.file-not-photo-alert-message",
                                        [_vm.touchedFileName.toUpperCase()]
                                      ),
                                      dismissible: ""
                                    },
                                    model: {
                                      value: _vm.showPhotoTabAttachmentAlert,
                                      callback: function($$v) {
                                        _vm.showPhotoTabAttachmentAlert = $$v
                                      },
                                      expression: "showPhotoTabAttachmentAlert"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-data-table", {
                                    directives: [
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:name",
                                        value: _vm.$t("common.name"),
                                        expression: "$t('common.name')",
                                        arg: "name"
                                      },
                                      {
                                        name: "fd-column",
                                        rawName:
                                          "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                        arg: "actions",
                                        modifiers: {
                                          "no-sort": true,
                                          "hide-when-extra-small": true,
                                          "class_fd-actions-cell": true
                                        }
                                      },
                                      {
                                        name: "fd-column",
                                        rawName:
                                          "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                        arg: "action",
                                        modifiers: {
                                          "no-sort": true,
                                          "show-when-extra-small": true,
                                          "class_fd-actions-cell-edit-only": true
                                        }
                                      }
                                    ],
                                    class: _vm.$vuetify.breakpoint.xsOnly
                                      ? "fd-action-table"
                                      : "fd-actions-table",
                                    attrs: {
                                      items: _vm.photoFiles,
                                      search: _vm.tablesearchfiles,
                                      loading: _vm.processing,
                                      "loading-text": _vm.$t(
                                        "common.table-loading-message"
                                      ),
                                      "sort-by": ["name"],
                                      "item-class": item =>
                                        item.name == _vm.touchedFileName
                                          ? "fd-selected-table-row-background"
                                          : "",
                                      "mobile-breakpoint": "0"
                                    },
                                    on: {
                                      "dblclick:row": (e, data) =>
                                        _vm.viewFile(data.item)
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item.action",
                                        fn: function({ item }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  disabled:
                                                    !_vm.$vuetify.breakpoint
                                                      .lgAndUp ||
                                                    _vm.processing,
                                                  top: ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    _vm.processing
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeFile(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            mdi-delete\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("common.remove")
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item.actions",
                                        fn: function({ item }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "table-4-actions-column-min-width"
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$vuetify.breakpoint
                                                          .lgAndUp ||
                                                        _vm.processing,
                                                      left: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.openFileInNewWindow(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-open-in-new\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.open-in-new-tab"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$vuetify.breakpoint
                                                          .lgAndUp ||
                                                        _vm.processing,
                                                      left: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        !item.isPreviewable ||
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.viewFile(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-eye\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("common.view")
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$vuetify.breakpoint
                                                          .lgAndUp ||
                                                        _vm.processing,
                                                      top: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        !item.isPreviewable ||
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.editFile(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-pencil\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("common.edit")
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$vuetify.breakpoint
                                                          .lgAndUp ||
                                                        _vm.processing,
                                                      top: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeFile(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-delete\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.remove"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-1 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.attachmentsStep }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "attachmentsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c("input", {
                                            ref: "addFileButton",
                                            staticClass: "d-none",
                                            attrs: {
                                              type: "file",
                                              onclick: "this.value=null;"
                                            },
                                            on: {
                                              change: v =>
                                                _vm.selectNewFile(
                                                  v.target.files[0]
                                                )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "add-fab-button-primary",
                                                            attrs: {
                                                              fab: "",
                                                              elevation: "5",
                                                              depressed:
                                                                _vm.processing,
                                                              disabled:
                                                                _vm.processing,
                                                              color: "new"
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-plus")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-list",
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.openNewExternalLinkDialog
                                                      }
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "attachments.external-link.menu-title"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: _vm.selectFile
                                                      }
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "common.local-file"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scaffold-designs.files-table-listing-tooltip"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearchfiles,
                                              callback: function($$v) {
                                                _vm.tablesearchfiles = $$v
                                              },
                                              expression: "tablesearchfiles"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("fd-alert", {
                                        staticClass: "mx-3",
                                        attrs: {
                                          type: "info",
                                          label: _vm.$t(
                                            "attachments.file-is-photo-alert-message",
                                            [_vm.touchedFileName.toUpperCase()]
                                          ),
                                          dismissible: ""
                                        },
                                        model: {
                                          value:
                                            _vm.showAttachmentTabPhotoAlert,
                                          callback: function($$v) {
                                            _vm.showAttachmentTabPhotoAlert = $$v
                                          },
                                          expression:
                                            "showAttachmentTabPhotoAlert"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                            value: "",
                                            expression: "''",
                                            arg: "icon",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-table-icon-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            value: _vm.$t("common.name"),
                                            expression: "$t('common.name')",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                            arg: "action",
                                            modifiers: {
                                              "no-sort": true,
                                              "show-when-extra-small": true,
                                              "class_fd-actions-cell-edit-only": true
                                            }
                                          }
                                        ],
                                        class: _vm.$vuetify.breakpoint.xsOnly
                                          ? "fd-action-table"
                                          : "fd-actions-table",
                                        attrs: {
                                          items: _vm.nonPhotoAttachments,
                                          search: _vm.tablesearchfiles,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "item-class": item =>
                                            item.name == _vm.touchedFileName
                                              ? "fd-selected-table-row-background"
                                              : "",
                                          "mobile-breakpoint": "0"
                                        },
                                        on: {
                                          "dblclick:row": (e, data) =>
                                            _vm.openAttachment(data.item)
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.icon",
                                            fn: function({ item }) {
                                              return [
                                                item.type == "link"
                                                  ? _c("v-icon", [
                                                      _vm._v("fa-link")
                                                    ])
                                                  : item.type == "file" &&
                                                    item.isPhoto
                                                  ? _c("v-icon", [
                                                      _vm._v("fa-camera")
                                                    ])
                                                  : _c("v-icon", [
                                                      _vm._v("fa-file")
                                                    ])
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.action",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$vuetify.breakpoint
                                                          .lgAndUp ||
                                                        _vm.processing,
                                                      top: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteAttachment(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-delete\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.remove"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-4-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.canOpenInNew ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.openAttachment(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-open-in-new\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.open-in-new-tab"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewFile(
                                                                                item.file
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.editAttachment(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteAttachment(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-2 mb-3 d-flex d-sm-none justify-end align-center",
                      class: { "fd-actions-hidden": _vm.hideActionButtons }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "save-small",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.saving,
                            disabled:
                              !_vm.currentUserCanCreateScaffoldDesigns ||
                              _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    {
                      staticClass: "mb-2 mx-1",
                      class: { "fd-actions-hidden": _vm.hideActionButtons }
                    },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.saving,
                            disabled:
                              !_vm.currentUserCanCreateScaffoldDesigns ||
                              _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("|")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "close",
                            text: "",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "continue",
                            disabled: _vm.step == _vm.lastStep,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }