import FDVue from "@fd/lib/vue";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective
} from "@fd/lib/vue/utility/dataTable";
import { mapActions, mapMutations } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  ContractorWithTags,
  ProjectCostCode,
  TimesheetEntry,
  timesheetService,
  TimesheetStatus,
  EmployeeTimeSummary,
  workOrderService,
  WorkOrderWithAllDetails,
  WorkSubTypeWithParentDetails,
  WorkType,
  personService,
  TimesheetType,
  Environment,
  EquipmentForContractor,
  EquipmentClassification
} from "../services";
import { TranslateResult } from "vue-i18n";
import { addTimesheetEntries } from "./components/dialogs/TimesheetEntriesAddDialog.vue";
import {
  UpdatableEquipmentTimesheetEntryWithDetails,
  UpdatableEmployeeTimesheetEntryWithDetails,
  UpdatableTimesheetWithEntries
} from "../utils/timesheet";
import { VDataTable } from "@fd/lib/vue/types";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import rules from "@fd/lib/vue/rules";
import { showTimesheetStatusHistoryDialog } from "./components/dialogs/TimesheetStatusHistoryDialog.vue";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";
import { GetPersonName, SortItemsWithName } from "../utils/person";
import { SelectListOption } from "@fd/lib/vue/utility/select";
import { addTimesheetEquipmentEntries } from "./components/dialogs/TimesheetEquipmentEntriesAddDialog.vue";
import { valueInArray } from "../../../lib/client-util/array";
import { SortWorkSubTypes } from "../utils/worksubtype";
import { SortWorkTypes } from "../utils/worktype";

type EntryGroupingType =
  | "groupnone"
  | "groupperson"
  | "groupequipment"
  | "groupworkorder"
  | "groupemployeeworkorder"
  | "groupcostcode";

export default FDVue.extend({
  name: "fd-timesheet-existing",

  mixins: [serviceErrorHandling, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // *** GLOBAL ***
      slidein: false,
      selectedEntryGroupingType: "groupperson" as EntryGroupingType,
      tablepage: 1,

      isOverriding: false,
      saving: false,
      submitting: false,
      approving: false,
      declining: false,

      isReadonly: false,

      timesheet: {
        currentUserPermissions: {}
      } as UpdatableTimesheetWithEntries,
      timeSummaries: [] as EmployeeTimeSummary[],

      allUsedWorkOrders: [] as WorkOrderWithAllDetails[]
    };
  },

  computed: {
    allTimesheetEntries(): (
      | UpdatableEmployeeTimesheetEntryWithDetails
      | UpdatableEquipmentTimesheetEntryWithDetails
    )[] {
      let allEntries = [] as (
        | UpdatableEmployeeTimesheetEntryWithDetails
        | UpdatableEquipmentTimesheetEntryWithDetails
      )[];
      if (!!this.timesheet.entries?.length) {
        allEntries = allEntries.concat(this.timesheet.entries);
      }
      if (!!this.timesheet.equipmentEntries?.length) {
        allEntries = allEntries.concat(this.timesheet.equipmentEntries);
      }
      return allEntries;
    },
    entryGroupingTypeOptions(): { text: TranslateResult; value: string }[] {
      let options = [
        { text: this.$t("timesheets.existing.group-by-none-radio"), value: "groupnone" }
      ];

      if (!this.timesheetIsEquipment) {
        options = options.concat([
          { text: this.$t("timesheets.existing.group-by-person-radio"), value: "groupperson" },
          {
            text: this.$t("timesheets.existing.group-by-work-order-radio"),
            value: "groupworkorder"
          },
          {
            text: this.$t("timesheets.existing.group-by-person-work-order-radio"),
            value: "groupemployeeworkorder"
          }
        ]);
      } else {
        options.push({
          text: this.$t("timesheets.existing.group-by-equipment-radio"),
          value: "groupequipment"
        });
      }

      options = options.concat([
        { text: this.$t("timesheets.existing.group-by-cost-code-radio"), value: "groupcostcode" }
      ]);
      return options;
    },
    overrideColumnArgument(): string {
      return this.timesheetCanBeOverridden ? "override" : FDHiddenArgumentName;
    },
    timesheetIsEquipment(): boolean {
      return this.timesheet.timesheetTypeID == TimesheetType.Equipment;
    },
    timesheetIsSubmitted(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Submitted;
    },
    timesheetIsDeclined(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Declined;
    },
    timesheetDeclineComments(): string | undefined {
      return this.timesheet?.lastStatusLog?.comments;
    },
    timesheetIsApproved(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Approved;
    },
    timesheetIsCancelled(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Cancelled;
    },
    timesheetCanBeSubmitted(): boolean {
      return (
        (this.timesheet.timesheetStatusID == TimesheetStatus.New ||
          this.timesheet.timesheetStatusID == TimesheetStatus.Declined) &&
        this.timesheet.currentUserPermissions.canSubmit
      );
    },
    timesheetHasEntriesMissingCostCode(): boolean {
      return (
        (!!this.timesheet.entries &&
          this.timesheet.entries.findIndex(
            x => (!x.overridden && !x.costCodeID) || (!!x.overridden && !x.costCodeIDOverride)
          ) !== -1) ||
        (!!this.timesheet.equipmentEntries &&
          this.timesheet.equipmentEntries.findIndex(
            x => (!x.overridden && !x.costCodeID) || (!!x.overridden && !x.costCodeIDOverride)
          ) !== -1)
      );
    },
    timesheetCanBeOverridden(): boolean {
      return (
        this.timesheet.timesheetStatusID == TimesheetStatus.Submitted &&
        this.timesheet.currentUserPermissions.canOverrideSubmittedTimesheetValues
      );
    },
    timesheetCanBeApproved(): boolean {
      return (
        this.timesheet.timesheetStatusID == TimesheetStatus.Submitted &&
        this.timesheet.currentUserPermissions.canApprove &&
        !this.timesheetHasEntriesMissingCostCode
      );
    },
    timesheetCanBeDeclined(): boolean {
      return (
        this.timesheet.timesheetStatusID == TimesheetStatus.Submitted &&
        this.timesheet.currentUserPermissions.canApprove
      );
    },
    timesheetCanBeSaved(): boolean {
      if (!this.isReadonly && this.timesheet.currentUserPermissions.canEditCostCode) return true;
      if (!!this.isReadonly && this.timesheetCanBeOverridden) return true;
      return false;
    },
    timeColumnsEmployee(): string[] {
      return ["regularTime", "overTime", "doubleTime", "units"];
    },
    timeColumnsEquipment(): string[] {
      return ["days", "quantity"];
    },
    showClassificationNameColumn(): boolean {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    showWorkTypeNameColumn(): boolean {
      return !this.timesheetIsEquipment && this.$vuetify.breakpoint.lgAndUp;
    },
    showWorkSubTypeNameColumn(): boolean {
      return !this.timesheetIsEquipment;
    },
    showCostCodeNameColumn(): boolean {
      return this.timesheet.currentUserPermissions.canViewCostCode;
    },
    showWorkorderNumberColumn(): boolean {
      return !this.timesheetIsEquipment;
    },
    showAreaNameColumn(): boolean {
      return !this.timesheetIsEquipment && this.$vuetify.breakpoint.lgAndUp;
    },
    employeeVisibleColumns(): string[] {
      let columns = ["expander"];
      if (this.selectedEntryGroupingType != "groupperson") columns.push("resourceName");
      if (this.showClassificationNameColumn) {
        columns.push("classificationName");
      }
      if (this.showWorkTypeNameColumn) {
        columns.push("workTypeName");
      }
      columns.push("workSubTypeName");
      if (this.showCostCodeNameColumn) {
        if (this.selectedEntryGroupingType != "groupcostcode") columns.push("costCodeName");
      }
      if (this.selectedEntryGroupingType != "groupworkorder") columns.push("workOrderNumber");
      if (this.showAreaNameColumn) {
        columns.push("areaName");
      }

      columns = columns.concat(this.timeColumnsEmployee);

      if (this.timesheetCanBeOverridden) columns.push("override");

      columns.push("action");
      return columns;
    },
    equipmentVisibleColumns(): string[] {
      let columns = ["expander"];

      if (this.selectedEntryGroupingType != "groupequipment") columns.push("equipmentName");

      columns.push("equipmentSerialNumber");

      if (this.showClassificationNameColumn) {
        columns.push("classificationName");
      }

      if (this.showCostCodeNameColumn) {
        if (this.selectedEntryGroupingType != "groupcostcode") columns.push("costCodeName");
      }

      columns = columns.concat(this.timeColumnsEquipment);

      if (this.timesheetCanBeOverridden) columns.push("override");

      columns.push("action");
      return columns;
    },
    numColsEmployee(): number {
      return this.employeeVisibleColumns.length;
    },
    numColsEquipment(): number {
      return this.equipmentVisibleColumns.length;
    },
    preTimeNumColsEmployee(): number {
      return this.numColsEmployee - this.timeColumnsEmployee.length - this.postTimeNumCols;
    },
    preTimeNumColsEquipment(): number {
      return this.numColsEquipment - this.timeColumnsEquipment.length - this.postTimeNumCols;
    },
    postTimeNumCols(): number {
      let cols = 1;
      if (this.timesheetCanBeOverridden) cols++;
      return cols;
    },
    itemsPerPage(): number {
      if (this.selectedEntryGroupingType == "groupperson") return -1;
      else if (this.selectedEntryGroupingType == "groupequipment") return -1;
      else if (this.selectedEntryGroupingType == "groupworkorder") return -1;
      else if (this.selectedEntryGroupingType == "groupemployeeworkorder") return -1;
      else if (this.selectedEntryGroupingType == "groupcostcode") return -1;
      return 25;
    },
    itemsPerPageOptions(): number[] {
      if (this.selectedEntryGroupingType == "groupperson") return [-1];
      else if (this.selectedEntryGroupingType == "groupequipment") return [-1];
      else if (this.selectedEntryGroupingType == "groupworkorder") return [-1];
      else if (this.selectedEntryGroupingType == "groupemployeeworkorder") return [-1];
      else if (this.selectedEntryGroupingType == "groupcostcode") return [-1];
      return [5, 10, 15, 25, 50, -1];
    },
    formattedDay(): string {
      return this.$format.date(this.timesheet.day);
    },
    groupColumn(): string | undefined {
      if (this.selectedEntryGroupingType == "groupperson") return "resourceNameCode";
      else if (this.selectedEntryGroupingType == "groupequipment") return "equipmentName";
      else if (this.selectedEntryGroupingType == "groupworkorder") return "workOrderNumber";
      else if (this.selectedEntryGroupingType == "groupemployeeworkorder")
        return "employeeWorkOrder";
      else if (this.selectedEntryGroupingType == "groupcostcode") return "costCodeName";
      return undefined;
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },
    validWorkOrderCostCodeIDs(): string[] {
      let validWorkOrderCostCodeIDs = [];
      let curEnvironment = this.$store.state.curEnvironment as Environment | undefined;
      if (!!curEnvironment?.defaultErectRequestCostCodeID)
        validWorkOrderCostCodeIDs.push(curEnvironment.defaultErectRequestCostCodeID);
      if (!!curEnvironment?.defaultModifyRequestCostCodeID)
        validWorkOrderCostCodeIDs.push(curEnvironment.defaultModifyRequestCostCodeID);
      if (!!curEnvironment?.defaultDismantleRequestCostCodeID)
        validWorkOrderCostCodeIDs.push(curEnvironment.defaultDismantleRequestCostCodeID);
      return validWorkOrderCostCodeIDs;
    },
    isGrouped(): boolean {
      return this.selectedEntryGroupingType != "groupnone";
    },
    isGroupedByEmployee(): boolean {
      return (
        this.selectedEntryGroupingType == "groupperson" ||
        this.selectedEntryGroupingType == "groupequipment"
      );
    },
    allGroupsExpanded(): boolean {
      console.log(`allGroupsExpanded`);
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith("grouptoggle"));
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace("grouptoggle", "");
        let isOpen = (this.$refs.datatable as VDataTable).openCache[groupName];
        if (!isOpen) {
          console.log(`    group closed: ${ref}`);
          anyGroupsClosed = true;
          break;
        }
      }
      console.log(`  anyGroupsClosed: ${anyGroupsClosed}`);
      return !anyGroupsClosed;
    }
  },

  methods: {
    sanitizeNumber(value: string | number | null | undefined): number {
      return this.$parse.sanitizedNumber(value);
    },
    regularTimeChanged(entry: UpdatableEmployeeTimesheetEntryWithDetails, newValue: any) {
      // When we display  numbers in read-only, we only display 2 decimal digits
      // The server also automatically rounds to 2 decimal digits
      // As such, when the user types the value we want to make sure they don't end up saving a different value than they typed
      let valueNumber = this.sanitizeNumber(newValue);
      let sanitizedValueNumber = Number(valueNumber.toFixed(2));
      if (valueNumber != sanitizedValueNumber) {
        this.$nextTick(() => {
          entry.regularTime = Number(Number(newValue).toFixed(2));
          this.regularTimeChanged(entry, sanitizedValueNumber);
        });
        return;
      }
    },
    overTimeChanged(entry: UpdatableEmployeeTimesheetEntryWithDetails, newValue: any) {
      // When we display  numbers in read-only, we only display 2 decimal digits
      // The server also automatically rounds to 2 decimal digits
      // As such, when the user types the value we want to make sure they don't end up saving a different value than they typed
      let valueNumber = this.sanitizeNumber(newValue);
      let sanitizedValueNumber = Number(valueNumber.toFixed(2));
      if (valueNumber != sanitizedValueNumber) {
        this.$nextTick(() => {
          entry.overTime = Number(Number(newValue).toFixed(2));
          this.overTimeChanged(entry, sanitizedValueNumber);
        });
        return;
      }
    },
    doubleTimeChanged(entry: UpdatableEmployeeTimesheetEntryWithDetails, newValue: any) {
      // When we display  numbers in read-only, we only display 2 decimal digits
      // The server also automatically rounds to 2 decimal digits
      // As such, when the user types the value we want to make sure they don't end up saving a different value than they typed
      let valueNumber = this.sanitizeNumber(newValue);
      let sanitizedValueNumber = Number(valueNumber.toFixed(2));
      if (valueNumber != sanitizedValueNumber) {
        this.$nextTick(() => {
          entry.doubleTime = Number(Number(newValue).toFixed(2));
          this.doubleTimeChanged(entry, sanitizedValueNumber);
        });
        return;
      }
    },
    entryIsEquipment(
      entry:
        | UpdatableEquipmentTimesheetEntryWithDetails
        | UpdatableEmployeeTimesheetEntryWithDetails
    ) {
      return !!(entry as UpdatableEquipmentTimesheetEntryWithDetails)?.equipmentID?.length;
    },
    entryIsEmployee(
      entry:
        | UpdatableEquipmentTimesheetEntryWithDetails
        | UpdatableEmployeeTimesheetEntryWithDetails
    ) {
      return !!(entry as UpdatableEmployeeTimesheetEntryWithDetails)?.employeeID?.length;
    },
    workOrderPlaceholderTextForEntry(
      entry:
        | UpdatableEquipmentTimesheetEntryWithDetails
        | UpdatableEmployeeTimesheetEntryWithDetails
    ): string {
      if (this.entryIsEquipment(entry)) {
        return this.$t("timesheets.existing.equipment-work-order-placeholder") as string;
      } else if (this.timesheet.timesheetTypeID == TimesheetType.Indirect) {
        return this.$t("timesheets.existing.indirect-work-order-placeholder") as string;
      } else {
        return this.$t("timesheets.existing.generalized-direct-work-order-placeholder") as string;
      }
    },
    selectableCostCodesForEntry(
      entry:
        | UpdatableEquipmentTimesheetEntryWithDetails
        | UpdatableEmployeeTimesheetEntryWithDetails
    ): ProjectCostCode[] {
      if (this.entryIsEquipment(entry)) {
        return this.selectableCostCodesForEquipmentEntry(
          entry as UpdatableEquipmentTimesheetEntryWithDetails
        );
      } else if (this.entryIsEmployee(entry)) {
        return this.selectableCostCodesForEmployeeEntry(
          entry as UpdatableEmployeeTimesheetEntryWithDetails
        );
      }
      return [];
    },
    selectableCostCodesForEquipmentEntry(
      entry: UpdatableEquipmentTimesheetEntryWithDetails
    ): ProjectCostCode[] {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.timesheet.contractorID);
      if (!contractor?.costCodeIDs?.length) return [];

      let allEquipment = this.$store.state.equipment.fullList as EquipmentForContractor[];
      let equipment = allEquipment.find(x => x.id == entry.equipmentID);
      if (!equipment) return [];

      var environmentDefaultCostCodeID = this.$store.state.curEnvironment
        .defaultEquipmentCostCodeID;

      var equipmentClassification = this.getEquipmentClassificationForID(
        equipment.equipmentClassificationID
      );
      var defaultClassificationCostCodeID = equipmentClassification?.defaultCostCodeID;

      var contractorClassificationCostCodeID = contractor.equipmentClassifications?.find(
        x =>
          !!x.equipmentClassificationID &&
          x.equipmentClassificationID == equipment?.equipmentClassificationID
      )?.costCodeID;

      var allowedCostCodeIDs: string[] = [];
      if (!!environmentDefaultCostCodeID) allowedCostCodeIDs.push(environmentDefaultCostCodeID);
      if (!!defaultClassificationCostCodeID)
        allowedCostCodeIDs.push(defaultClassificationCostCodeID);
      if (!!contractorClassificationCostCodeID)
        allowedCostCodeIDs.push(contractorClassificationCostCodeID);
      allowedCostCodeIDs = [...new Set(allowedCostCodeIDs)];

      if (!valueInArray(entry.costCodeID, allowedCostCodeIDs)) entry.costCodeID = null;
      if (!entry.costCodeID && !!contractorClassificationCostCodeID)
        entry.costCodeID = contractorClassificationCostCodeID;
      if (allowedCostCodeIDs.length == 1 && !entry.costCodeID)
        entry.costCodeID = allowedCostCodeIDs[0];

      let allCostCodes = this.$store.state.projectCostCodes.fullList as ProjectCostCode[];
      return SortItemsWithName(
        allCostCodes.filter(
          x => contractor!.costCodeIDs?.includes(x.id!) && valueInArray(x.id, allowedCostCodeIDs)
        )
      );
    },
    getEquipmentClassificationForID(
      classificationID: string | null | undefined
    ): EquipmentClassification | undefined {
      return (this.$store.state.equipmentClassifications
        .fullList as EquipmentClassification[]).find(x => x.id == classificationID);
    },
    selectableCostCodesForEmployeeEntry(
      entry: UpdatableEmployeeTimesheetEntryWithDetails
    ): ProjectCostCode[] {
      var selectedWorkSubType = this.selectableWorkSubTypes(entry).find(
        x => x.id == entry.workSubTypeID
      );
      if (!selectedWorkSubType) {
        console.log(`selectedWorkSubType not found`);
        return [];
      }
      if (!this.workSubTypeIsConfiguredCorrectly(selectedWorkSubType)) return [];

      var selectableCostCodeIDs = [] as string[];
      if (selectedWorkSubType.useWorkOrderCostCode) {
        selectableCostCodeIDs = this.validWorkOrderCostCodeIDs;
      } else if (!!selectedWorkSubType.defaultCostCodeID) {
        selectableCostCodeIDs.push(selectedWorkSubType.defaultCostCodeID);
      } else {
        console.log(`selectedWorkSubType not configured`);
        return [];
      }
      console.log(`selectableCostCodeIDs: ${selectableCostCodeIDs}`);
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.timesheet.contractorID);
      if (!contractor?.costCodeIDs?.length) return [];
      return SortItemsWithName(
        (this.$store.state.projectCostCodes.fullList as ProjectCostCode[]).filter(
          x => contractor!.costCodeIDs?.includes(x.id!) && selectableCostCodeIDs.includes(x.id!)
        )
      );
    },
    workSubTypeIsConfiguredCorrectly(
      workSubType: WorkSubTypeWithParentDetails | undefined
    ): boolean {
      if (!workSubType) return false;

      return (
        (!!workSubType.isWorkOrderRelated && !!workSubType.useWorkOrderCostCode) ||
        !!workSubType.defaultCostCodeID
      );
    },
    anyItemsMissingCostCode(
      entries:
        | (
            | UpdatableEmployeeTimesheetEntryWithDetails
            | UpdatableEquipmentTimesheetEntryWithDetails
          )[]
        | undefined
    ): boolean {
      return (
        entries?.findIndex(
          x => (!x.overridden && !x.costCodeID) || (!!x.overridden && !x.costCodeIDOverride)
        ) !== -1 ?? false
      );
    },
    nameForCostCodeID(costCodeID: string): string | undefined {
      return (
        (this.$store.state.projectCostCodes.fullList as ProjectCostCode[]).find(
          x => x.id == costCodeID
        )?.name ?? ""
      );
    },
    overrideEquipmentEntryValues(entry: UpdatableEquipmentTimesheetEntryWithDetails) {
      if (!this.isOverriding) {
        this.isOverriding = true;
      }
      entry.overridden = true;
      entry.costCodeIDOverride = entry.costCodeID;

      entry.originalDays = entry.days ?? 0;
      entry.originalQuantity = entry.quantity ?? 0;
    },
    cancelOverrideEquipmentEntryValues(entry: UpdatableEquipmentTimesheetEntryWithDetails) {
      entry.overridden = false;
      entry.costCodeIDOverride = null;

      entry.days = entry.originalDays;
      entry.originalDays = null;

      entry.quantity = entry.originalQuantity;
      entry.originalQuantity = null;

      let overriddenEntries = this.timesheet.equipmentEntries.filter(x => x.overridden);
      if (!overriddenEntries.length) {
        this.isOverriding = false;
      }
    },
    overrideEmployeeEntryValues(entry: UpdatableEmployeeTimesheetEntryWithDetails) {
      if (!this.isOverriding) {
        this.isOverriding = true;
      }
      entry.overridden = true;
      entry.costCodeIDOverride = entry.costCodeID;

      entry.originalRegularTime = entry.regularTime ?? 0;
      entry.originalOverTime = entry.overTime ?? 0;
      entry.originalDoubleTime = entry.doubleTime ?? 0;
      entry.originalUnits = entry.units ?? 0;
    },
    cancelOverrideEmployeeEntryValues(entry: UpdatableEmployeeTimesheetEntryWithDetails) {
      entry.overridden = false;
      entry.costCodeIDOverride = null;

      entry.regularTime = entry.originalRegularTime;
      entry.originalRegularTime = null;

      entry.overTime = entry.originalOverTime;
      entry.originalOverTime = null;

      entry.doubleTime = entry.originalDoubleTime;
      entry.originalDoubleTime = null;

      entry.units = entry.originalUnits;
      entry.originalUnits = null;

      let overriddenEntries = this.timesheet.entries.filter(x => x.overridden);
      if (!overriddenEntries.length) {
        this.isOverriding = false;
      }
    },
    // This method determines if the row in the data-table should be colored to represent if it is "Urgent".
    timesheetRowClassName(
      item: UpdatableEmployeeTimesheetEntryWithDetails | UpdatableEquipmentTimesheetEntryWithDetails
    ): string[] {
      let classes = [] as string[];
      if (item.isCorrectionEntry) classes.push("fd-correction-table-row-background");
      if (!item.costCodeID) classes.push("fd-missing-cost-code-table-row-background");
      return classes;
    },
    toggleGroups(closed: boolean = true) {
      console.log(`toggleGroups closed: ${closed}`);
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith("grouptoggle"));
      let datatable = this.$refs.datatable as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace("grouptoggle", "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },
    async _initialize() {
      console.log(`_initialize`);

      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/timesheetapproval") {
        this.notifyNewBreadcrumb({
          text: this.$t("timesheets.list.approval-title"),
          to: "/timesheetapproval",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: ``,
          to: `/timesheetapproval/${this.$route.params.id}`
        });
      }

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "timesheets-existing",
        parentalContext: "timesheets",
        searchStringForFiltering: ""
      });

      this.processing = true;
      try {
        console.log(`  get timesheet by id: ${this.$route.params.id}`);
        await Promise.all([
          this.loadContractors(),
          this.loadWorkTypes(),
          this.loadWorkSubTypes(),
          this.loadCostCodes(),
          this.loadEquipmentClassifications(),
          this.loadWorkOrdersForTimesheet()
        ]);
        this.timesheet.id = this.$route.params.id;
        await this.loadTimesheet();
        await this.loadEquipmentForContractor({ contractorID: this.timesheet.contractorID });

        this.isOverriding =
          this.timesheet.entries.findIndex(x => !!x.overridden) !== -1 ||
          this.timesheet.equipmentEntries.findIndex(x => !!x.overridden) !== -1;

        this.$nextTick(() => {
          this.toggleGroups(true);
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadEquipmentClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS",
      loadEquipmentForContractor: "LOAD_EQUIPMENT_FOR_CONTRACTOR"
    }),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    sum(items: any[], propName: string, digits: number = 2): string | undefined {
      let result = items.reduce((a: number, b: any) => a + this.sanitizeNumber(b[propName]), 0);
      return !result ? undefined : result.toFixed(digits);
    },
    labelForGroup(value: string): TranslateResult | string | undefined {
      if (this.selectedEntryGroupingType == "groupperson")
        return this.$t("timesheets.existing.person-group-label", [value]);
      else if (this.selectedEntryGroupingType == "groupequipment")
        return this.$t("timesheets.existing.equipment-group-label", [value]);
      else if (this.selectedEntryGroupingType == "groupworkorder")
        return !!value
          ? this.$t("timesheets.existing.work-order-group-label", [value])
          : this.$t("timesheets.existing.work-order-none-group-label");
      else if (this.selectedEntryGroupingType == "groupemployeeworkorder") {
        if (value.startsWith("0_")) {
          return this.$t(
            "timesheets.existing.person-work-order-none-group-label",
            value.split("_")
          );
        } else {
          return this.$t("timesheets.existing.person-work-order-group-label", value.split("_"));
        }
      } else if (this.selectedEntryGroupingType == "groupcostcode")
        return this.$t("timesheets.existing.cost-code-group-label", [value]);
      return undefined;
    },
    cancel() {
      this.$router.push("/timesheetapproval");
    },
    async baseSave(): Promise<boolean> {
      try {
        var errorEntries = this.timesheet.entries.filter(
          x => !x.regularTime && !x.overTime && !x.doubleTime && !x.units
        );
        if (errorEntries.length) {
          this.inlineMessage.message = this.$t(
            "timesheets.entries.entries-missing-data-error-message"
          );
          this.processing = false;
          this.saving = false;
          return false;
        }

        if (this.timesheet.hasModifiedEmployeeEntries) {
          await timesheetService.updateEmployeeEntriesForTimesheetWithID(
            this.timesheet.id!,
            this.timesheet.modifiedExistingEmployeeEntryData
          );
        }
        if (this.timesheet.hasModifiedEquipmentEntries) {
          await timesheetService.updateEquipmentEntriesForTimesheetWithID(
            this.timesheet.id!,
            this.timesheet.modifiedExistingEquipmentEntryData
          );
        }
        if (this.timesheet.hasRemovedEmployeeEntries) {
          await timesheetService.removeEmployeeEntriesFromTimesheetWithID(
            this.timesheet.id!,
            this.timesheet.removedEmployeeEntryIDs
          );
        }
        if (this.timesheet.hasRemovedEquipmentEntries) {
          await timesheetService.removeEquipmentEntriesFromTimesheetWithID(
            this.timesheet.id!,
            this.timesheet.removedEquipmentEntryIDs
          );
        }

        var snackbarPayload = {
          text: this.$t("timesheets.snack-bar-updated-message", [
            this.timesheet.ownerName,
            this.formattedDay
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        throw error;
      }
      return true;
    },
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        console.log(`form validation failed`);
        this.inlineMessage.message = this.$t("timesheets.existing.form-errors-message");
        this.inlineMessage.type = "error";
        return;
      }
      this.processing = true;
      this.saving = true;
      try {
        if (!this.timesheet.isDirty) {
          this.processing = false;
          this.saving = false;
          var snackbarPayload = {
            text: this.$t("timesheets.entries.no-changes-to-save-message"),
            type: "info",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.processing = false;
          this.saving = false;
          console.log(`no changes to save`);
          return;
        }

        var saved = await this.baseSave();

        if (saved) {
          if (closeOnComplete) {
            this.$router.push("/timesheetapproval");
          } else {
            await this.loadTimesheet();
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    async submitTimesheet() {
      if (!this.timesheet.currentUserPermissions.canSubmit) return;
      this.processing = true;
      this.submitting = true;
      try {
        let submittedToID = undefined as string | undefined;

        if (
          this.timesheet.timesheetTypeID == TimesheetType.Indirect ||
          this.timesheet.timesheetTypeID == TimesheetType.Equipment
        ) {
          let visibleTimeManagers = SortItemsWithName(
            (await personService.getVisibleTimeManagers())
              .filter(x => x.contractorID == this.timesheet.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          var title = this.$t("timesheets.entries.submit-to-time-manager-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.time-manager-label"),
            [this.rules.required],
            visibleTimeManagers,
            "name",
            "id"
          );
          // If details is undefined the dialog was cancelled, if empty we somehow bypassed selecting a TM
          if (!submittedToID) {
            return false;
          }
        } else {
          let visibleGeneralForeman = SortItemsWithName(
            (await personService.getVisibleGeneralForemen())
              .filter(x => x.contractorID == this.timesheet.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          var title = this.$t("timesheets.entries.submit-to-gen-foreman-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.general-foreman-label"),
            [this.rules.required],
            visibleGeneralForeman,
            "name",
            "id"
          );
        }

        // If details is undefined the dialog was cancelled
        if (!submittedToID) {
          this.submitting = false;
          this.processing = false;
          return false;
        }

        this.timesheet.submittedTo = submittedToID;

        await timesheetService.submitTimesheet(this.timesheet.id!, submittedToID);
        this.timesheet.timesheetStatusID = TimesheetStatus.Submitted;
        this.isReadonly = this.timesheet.isLocked;

        var snackbarPayload = {
          text: this.$t("timesheets.list.submit-success", [
            this.timesheet.ownerName,
            this.formattedDay,
            this.formattedDay
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.push("/timesheetapproval");
      } catch (error) {
        if ((error as any).statusCode == 422) {
          var snackbarPayload = {
            text: this.$t("timesheets.list.submit-validation-failed", [
              this.timesheet.ownerName,
              this.formattedDay,
              this.formattedDay
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async declineTimesheet() {
      if (!this.timesheet.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("timesheets.approval.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          this.declining = false;
          this.processing = false;
          return false;
        }

        await timesheetService.declinePendingTimesheet(this.timesheet.id!, reason);
        this.timesheet.timesheetStatusID = TimesheetStatus.Declined;
        this.isReadonly = this.timesheet.isLocked;

        var snackbarPayload = {
          text: this.$t("timesheets.approval.decline-success", [
            this.timesheet.ownerName,
            this.formattedDay
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.push("/timesheetapproval");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },
    async saveAndApproveTimesheet() {
      this.processing = true;
      try {
        if (this.timesheet.isDirty) {
          this.saving = true;
          var saved = await this.baseSave();
          this.saving = false;
          if (!saved) return;
        }

        this.approving = true;
        await this.baseApproveTimesheet();
        this.approving = false;

        this.$router.push("/timesheetapproval");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
        this.approving = false;
      }
    },
    async baseApproveTimesheet() {
      await timesheetService.approvePendingTimesheet(this.timesheet.id!);
      this.timesheet.timesheetStatusID = TimesheetStatus.Approved;
      this.isReadonly = this.timesheet.isLocked;

      var snackbarPayload = {
        text: this.$t("timesheets.approval.approve-success", [
          this.timesheet.ownerName,
          this.formattedDay
        ]),
        type: "success",
        undoCallback: null
      };
      this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
    },
    async approveTimesheet() {
      if (!this.timesheet.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.approving = true;
      try {
        await this.baseApproveTimesheet();

        this.$router.push("/timesheetapproval");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },
    async showStatusLogDialog() {
      await showTimesheetStatusHistoryDialog(this.timesheet?.statusLogs ?? []);
    },
    removeExistingEntry(
      entry:
        | UpdatableEmployeeTimesheetEntryWithDetails
        | UpdatableEquipmentTimesheetEntryWithDetails
    ) {
      if (this.entryIsEquipment(entry))
        this.removeExistingEquipmentEntry(entry as UpdatableEquipmentTimesheetEntryWithDetails);
      else if (this.entryIsEmployee(entry))
        this.removeExistingEmployeeEntry(entry as UpdatableEmployeeTimesheetEntryWithDetails);
    },
    removeExistingEquipmentEntry(entry: UpdatableEquipmentTimesheetEntryWithDetails) {
      const index = this.timesheet.equipmentEntries.indexOf(entry);
      if (index < 0) {
        return;
      }
      let removedEntries = this.timesheet.equipmentEntries.splice(index, 1);
      this.timesheet.removedEquipmentEntryIDs = this.timesheet.removedEquipmentEntryIDs.concat(
        removedEntries.map(x => x.id!)
      );
    },
    removeExistingEmployeeEntry(entry: UpdatableEmployeeTimesheetEntryWithDetails) {
      const index = this.timesheet.entries.indexOf(entry);
      if (index < 0) {
        return;
      }
      let removedEntries = this.timesheet.entries.splice(index, 1);
      this.timesheet.removedEmployeeEntryIDs = this.timesheet.removedEmployeeEntryIDs.concat(
        removedEntries.map(x => x.id!)
      );
    },
    async openNewTimesheetEntryDialog() {
      if (this.timesheetIsEquipment) {
        await this.openNewEquipmentTimesheetEntryDialog();
      } else {
        await this.openNewEmployeeTimesheetEntryDialog();
      }
    },
    async openNewEquipmentTimesheetEntryDialog() {
      if (await addTimesheetEquipmentEntries(this.timesheet)) {
        if (this.timesheetIsEquipment) {
          await Promise.all([this.loadTimesheetEntries()]);
        } else {
          await Promise.all([this.loadTimesheetEntries(), this.loadTimeSummaries()]);
        }
      }
    },
    async openNewEmployeeTimesheetEntryDialog() {
      if (await addTimesheetEntries(this.timesheet)) {
        await Promise.all([this.loadTimesheetEntries(), this.loadTimeSummaries()]);
      }
    },
    async loadTimesheet() {
      let timesheet = await timesheetService.getByID(this.$route.params.id);
      if (!timesheet) {
        this.isReadonly = true;
        this.timeSummaries = [];
        return;
      }

      this.timesheet = new UpdatableTimesheetWithEntries(timesheet);
      this.isReadonly = this.timesheet.isLocked;
      if (
        timesheet.timesheetTypeID == TimesheetType.Equipment &&
        this.selectedEntryGroupingType == "groupperson"
      ) {
        this.selectedEntryGroupingType = "groupequipment";
      }
      await Promise.all([this.loadTimesheetEntries(), this.loadTimeSummaries()]);
    },
    async loadTimesheetEntries() {
      let equipmentTimesheetEntries = await timesheetService.getEquipmentEntriesForTimesheetID(
        this.$route.params.id
      );
      this.timesheet.equipmentEntries = equipmentTimesheetEntries.map(
        x => new UpdatableEquipmentTimesheetEntryWithDetails(x)
      );
      // } else {
      let employeeTimesheetEntries = await timesheetService.getEmployeeEntriesForTimesheetID(
        this.$route.params.id
      );
      this.timesheet.entries = employeeTimesheetEntries.map(
        x => new UpdatableEmployeeTimesheetEntryWithDetails(x)
      );
    },
    async loadTimeSummaries() {
      let timeSummaries = await timesheetService.getEmployeeTimeSummariesForTimesheetWithID(
        this.$route.params.id,
        false
      );
      this.timeSummaries = timeSummaries;
    },
    getTotalTimeForEmployee(
      employeeName: string,
      propertyName: string,
      digits: number = 2
    ): string | undefined {
      let sampleTimeEntry = this.timesheet.entries.find(x => x.employeeName == employeeName);
      let summary = this.timeSummaries.find(x => x.employeeID == sampleTimeEntry?.employeeID);
      if (!summary) return undefined;
      let time = (summary as any)[propertyName];
      if (!time || time == 0) return undefined;
      return time.toFixed(digits);
    },

    // *** REFERENCE DATA ***
    async loadWorkOrdersForTimesheet() {
      if (this.timesheetIsEquipment) return;
      this.allUsedWorkOrders = await workOrderService.getWorkOrdersForTimesheet(
        this.$route.params.id
      );
    },
    selectedEntryEmployeeHasOtherPerDiemRecord(item: TimesheetEntry): boolean {
      if (this.timesheetIsEquipment) return false;
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.timesheet.contractorID);
      if (!contractor?.workTypeIDs?.length) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!perDiemWorkType) return false;

      return (
        this.timesheet.entries.findIndex(
          x =>
            x.id != item.id &&
            x.workTypeID == perDiemWorkType!.id &&
            item.employeeID == x.employeeID
        ) !== -1
      );
    },
    workTypeSelectable(workType: WorkType | undefined, item: TimesheetEntry): boolean {
      if (this.timesheetIsEquipment) return false;
      if (!workType) return false;

      let canEdit = false;
      if (workType.isPerDiem) {
        canEdit = !this.selectedEntryEmployeeHasOtherPerDiemRecord(item) && !item.workOrderID;
      } else {
        var allWorkSubTypes = this.$store.state.workSubTypes
          .fullList as WorkSubTypeWithParentDetails[];
        let workSubTypes = allWorkSubTypes.filter(x => x.workTypeID == workType.id);
        let hasWorkOrderSubTypes = workSubTypes.findIndex(x => !!x.isWorkOrderRelated) !== -1;
        let canSelectWithWorkOrder =
          (workType.isDirect &&
            !workType.isPerDiem &&
            !workType.isEquipment &&
            hasWorkOrderSubTypes) ??
          false;

        let hasIndirectSubTypes = workSubTypes.findIndex(x => !x.isWorkOrderRelated) !== -1;
        let canSelectWithoutWorkOrder = !workType.isDirect || hasIndirectSubTypes;

        let hasWorkOrder = !!item.workOrderID ?? false;
        canEdit =
          (canSelectWithWorkOrder && hasWorkOrder) || (canSelectWithoutWorkOrder && !hasWorkOrder);
      }
      return canEdit;
    },
    selectableWorkTypes(item: TimesheetEntry): SelectListOption<WorkType>[] {
      if (this.timesheetIsEquipment) return [];
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.timesheet.contractorID);

      if (!contractor?.workTypeIDs?.length) return [];

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      return SortWorkTypes(
        allWorkTypes
          .filter(
            x =>
              (this.timesheet.timesheetTypeID == TimesheetType.Equipment && x.isEquipment) ||
              (this.timesheet.timesheetTypeID != TimesheetType.Equipment &&
                (contractor!.workTypeIDs?.includes(x.id!) || x.isPerDiem))
          )
          .map(x => {
            return { ...x, disabled: !this.workTypeSelectable(x, item) };
          })
      );
    },
    workTypeChangedForItem(item: TimesheetEntry, newValue: string) {
      if (this.timesheetIsEquipment) return;
      console.log(`workTypeChangedForItem`);
      item.workTypeID = newValue;
      let selectableWorkSubTypes = this.selectableWorkSubTypes(item);
      if (selectableWorkSubTypes.findIndex(x => x.id == item.workSubTypeID) === -1) {
        console.log(`  Previously selected subtype ID not found.  Clear value.`);
        item.workSubTypeID = undefined;
      }
    },
    workSubTypeSelectable(
      workSubType: WorkSubTypeWithParentDetails | undefined,
      entry: TimesheetEntry
    ): boolean {
      if (!workSubType) return false;
      if (this.timesheetIsEquipment) return false;

      var allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let parentWorkType = allWorkTypes.find(x => x.id == workSubType.workTypeID);
      let canEdit = false;
      if (parentWorkType?.isPerDiem) {
        canEdit = !this.selectedEntryEmployeeHasOtherPerDiemRecord(entry) && !entry.workOrderID;
      } else {
        let canSelectWithWorkOrder =
          (parentWorkType?.isDirect && workSubType.isWorkOrderRelated) ?? false;

        let canSelectWithoutWorkOrder =
          !parentWorkType?.isDirect || !workSubType.isWorkOrderRelated;

        let hasWorkOrder = !!entry.workOrderID ?? false;
        canEdit =
          (canSelectWithWorkOrder && hasWorkOrder) || (canSelectWithoutWorkOrder && !hasWorkOrder);
      }
      return canEdit;
    },
    selectableWorkSubTypes(item: TimesheetEntry): WorkSubTypeWithParentDetails[] {
      if (this.timesheetIsEquipment) return [];
      var allWorkSubTypes = SortWorkSubTypes(
        (this.$store.state.workSubTypes.fullList as WorkSubTypeWithParentDetails[])
          .filter(x => !!this.workSubTypeIsConfiguredCorrectly(x))
          .map(x => {
            return { ...x, disabled: !this.workSubTypeSelectable(x, item) };
          })
      );
      return allWorkSubTypes.filter(x => x.workTypeID == item.workTypeID);
    },
    workSubTypeChangedForItem(item: TimesheetEntry, newValue: string) {
      if (this.timesheetIsEquipment) return;
      console.log(`workSubTypeChangedForItem`);
      item.workSubTypeID = newValue;
      var allWorkSubTypes = this.$store.state.workSubTypes
        .fullList as WorkSubTypeWithParentDetails[];
      let workSubType = allWorkSubTypes.find(x => x.id == item.workSubTypeID);
      let usedWorkOrder = this.allUsedWorkOrders.find(x => x.id == item.workOrderID);
      var costCodeID =
        workSubType?.useWorkOrderCostCode == true && !!usedWorkOrder?.costCodeID
          ? usedWorkOrder!.costCodeID
          : workSubType?.defaultCostCodeID;
      item.costCodeID = costCodeID;
    },
    canEditItem(item: TimesheetEntry): boolean {
      if (!this.timesheet.currentUserPermissions.canEditExistingEntries) return false;
      var editable = !this.isReadonly || (this.isOverriding && !!item.overridden);
      return editable;
    },
    canEditRegularTimeHoursForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;
      if (this.timesheetIsEquipment) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id == item.workTypeID) return false;
      }

      return true;
    },
    canEditOverTimeHoursForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;
      if (this.timesheetIsEquipment) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id == item.workTypeID) return false;
      }

      return true;
    },
    canEditDoubleTimeHoursForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;
      if (this.timesheetIsEquipment) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id == item.workTypeID) return false;
      }

      return true;
    },
    canEditUnitsForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;
      if (this.timesheetIsEquipment) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id != item.workTypeID) return false;
      }

      return true;
    },
    canEditDaysForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;
      if (!this.timesheetIsEquipment) return false;

      return true;
    },
    canEditQuantityForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;
      if (!this.timesheetIsEquipment) return false;

      return true;
    },

    //#region *** INLINE NAVIGATION ***
    getFieldRef(
      fieldName: string,
      item: UpdatableEmployeeTimesheetEntryWithDetails | UpdatableEquipmentTimesheetEntryWithDetails
    ) {
      let id = item.id!.replace("-", "").replace("-", "");
      return `${fieldName}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(
      fieldName: string,
      index: number,
      visibleItems: (
        | UpdatableEmployeeTimesheetEntryWithDetails
        | UpdatableEquipmentTimesheetEntryWithDetails
      )[]
    ) {
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(
      fieldName: string,
      item: UpdatableEmployeeTimesheetEntryWithDetails | UpdatableEquipmentTimesheetEntryWithDetails
    ) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.tablepage <= 1) return;
        this.tablepage -= 1;

        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(
            fieldName,
            datatable.computedItemsPerPage,
            visibleItems
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex, visibleItems);
    },
    async selectNextField(
      fieldName: string,
      item: UpdatableEmployeeTimesheetEntryWithDetails | UpdatableEquipmentTimesheetEntryWithDetails
    ) {
      let entries = this.timesheetIsEquipment
        ? this.timesheet.equipmentEntries
        : this.timesheet.entries;
      let datatable = this.$refs.datatable as VDataTable;
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex >= visibleItems.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(entries.length / datatable.computedItemsPerPage);

        if (this.tablepage >= maxPage) return;
        this.tablepage += 1;

        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, 0, visibleItems);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex, visibleItems);
    },
    async enterPressed(
      e: KeyboardEvent,
      fieldName: string,
      item: UpdatableEmployeeTimesheetEntryWithDetails | UpdatableEquipmentTimesheetEntryWithDetails
    ) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
    //#endregion
  },

  watch: {
    timesheet() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/timesheetapproval") {
        this.notifyNewBreadcrumb({
          text: this.$t("timesheets.list.approval-title"),
          to: "/timesheetapproval",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      let timesheetNumberString = `00000${this.timesheet.timesheetNumber}`.slice(-5);
      this.notifyNewBreadcrumb({
        text: !!this.timesheet.id
          ? this.$t("timesheets.existing.title", [
              this.timesheet.ownerName,
              this.formattedDay,
              timesheetNumberString
            ])
          : this.$t("timesheets.existing.new-timesheet"),
        to: `/timesheetapproval/${this.$route.params.id}`
      });
    },
    selectedEntryGroupingType(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$nextTick(() => {
          this.toggleGroups(true);
        });
      }
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    console.log(`created`);
    this._initialize();
  }
});
