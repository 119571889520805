import { PropType } from "vue";
import FDVue from "../../../../lib/vue";
import { SoftCraftFilteringContextOptions } from "../../dataMixins/softCrafts";

export default FDVue.extend({
  name: "sp-soft-craft-filter-selector",
  props: {
    value: { type: Array as PropType<Array<SoftCraftFilteringContextOptions>> },
    items: { type: Array as PropType<Array<SoftCraftFilteringContextOptions>> },
    allItemsSelected: { type: Boolean }
  },
  computed: {}
});
