import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { WalkdownDetails, WorkOrderDetails } from "./WorkOrderDetailsForm.vue";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { reportService, WorkOrderWithAllDetails } from "../../services";
import downloadBlob from "../../../../lib/client-util/downloadBlob";
import printBlob from "../../../../lib/client-util/printBlob";

const WorkOrderDetailsDialog = FDVue.extend({
  name: "fd-work-order-details-dialog",

  mixins: [dialogSupport],

  components: {
    "fd-work-order-details": () => import("./WorkOrderDetailsForm.vue")
  },

  props: {
    cy: { type: String, default: "fd-work-order-details-dialog" }
  },

  data: function() {
    return {
      workOrder: {} as WorkOrderDetails,
      walkdown: {} as WalkdownDetails,
      hideScopeValues: false
    };
  },

  methods: {
    async openWithData(
      workOrder: WorkOrderDetails,
      walkdown: WalkdownDetails,
      hideScopeValues: boolean = false
    ) {
      this.workOrder = workOrder;
      this.walkdown = walkdown;
      this.hideScopeValues = hideScopeValues;

      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    cancelDialog() {
      this.closeDialog!(false);
    },

    async downloadAndPrintPlannerReport(reportType: string = "pdf") {
      this.processing = true;
      let convertedDate = new Date(
        DateUtil.isoString(this.workOrder.lastStatusChangeDate) + "+0000"
      );
      const localizedStatusChange = DateUtil.localizedDateTimeString(convertedDate);

      var workOrder = (this.workOrder as unknown) as WorkOrderWithAllDetails;
      var blob = await reportService.getSchedulePrintoutReportContentWithData(
        workOrder,
        workOrder.walkdown,
        reportType,
        workOrder.requestSubmittedOn!,
        workOrder.workPackages?.map(x => x.name).join(",") ?? "",
        localizedStatusChange,
        false
      );
      this.processing = false;
      if (reportType == "xls") {
        downloadBlob(blob, "schedular-printout.xlsx");
      } else {
        printBlob(blob, "schedular-printout.pdf", "application/pdf");
      }
    }
  }
});

export default WorkOrderDetailsDialog;

export async function openWorkOrderDetailsDialog(
  workOrder: WorkOrderDetails,
  walkdown: WalkdownDetails,
  hideScopeValues: boolean = false
): Promise<boolean> {
  let dialog = createDialog(WorkOrderDetailsDialog);
  return await dialog.openWithData(workOrder, walkdown, hideScopeValues);
}
