import { PropType } from "vue";
import FDVue from "@fd/lib/vue";
import { ApplicationType, PaintCode, SetUpType, Tsa, WorkCategory } from "../../../services";
import BuildSheetFormBase from "./SP.BuildSheetFormBase";

export default FDVue.extend({
  name: "sp-build-sheet-form-paint",
  mixins: [BuildSheetFormBase],
  components: {
    "sp-build-sheet-norm-form": () => import("./SP.BuildSheetNormForm.vue")
  },
  props: {
    applicationTypes: { type: Array as PropType<Array<ApplicationType>> },
    paintCodes: { type: Array as PropType<Array<PaintCode>> },
    setUpTypes: { type: Array as PropType<Array<SetUpType>> },
    tsas: { type: Array as PropType<Array<Tsa>> },
    workCategories: { type: Array as PropType<Array<WorkCategory>> },

    equipmentNumber: { type: String },
    diameter: { type: String },
    squareFeet: { type: String },
    linearFeet: { type: String },
    paintReason: { type: String },
    coatNumber: { type: Number },
    applicationTypeID: { type: String },
    paintCodeID: { type: String },
    setUpTypeID: { type: String },
    tsaID: { type: String },
    workCategoryID: { type: String }
  },
  data: function() {
    return {
      //FOR MOCKUP PURPOSES
      paintreason: {
        prep: false,
        touchup: false,
        prime: false,
        damage: false,
        paint: false,
        rework: false
      }
    };
  },
  computed: {
    paintReasonValue: {
      get(): string {
        return this.paintReason;
      },
      set(val: string) {
        this.$emit("update:paintReason", val);
      }
    },
    coatNumberValue: {
      get(): number {
        return this.coatNumber;
      },
      set(val: number) {
        this.$emit("update:coatNumber", val);
      }
    }
  },
  watch: {},
  methods: {}
});
