import FDVue from "../../../lib/vue";
import { mapMutations, mapActions } from "vuex";
import serviceErrorHandling from "../../../lib/vue/mixins/serviceErrorHandling";
import {
  Contractor,
  ContractorNorm,
  ContractorNormGroup,
  contractorNormGroupService,
  contractorService
} from "../services";
import { truncateWithEllipsis } from "../../../lib/vue/utility/helper";
import rules from "../../../lib/vue/rules";
type FormattedContractorNorm = ContractorNorm & {
  archived: boolean;
  name: string;
};
export default FDVue.extend({
  name: "sp-contractor-norm-existing",
  mixins: [serviceErrorHandling, rules],
  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      slidein: false,

      contractor: {} as Contractor,
      normGroup: {} as ContractorNormGroup,
      norm: {} as FormattedContractorNorm
    };
  },
  computed: {},
  watch: {
    norm() {
      this.updateBreadCrumbs();
    }
  },
  methods: {
    updateBreadCrumbs() {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/contractors") {
        this.notifyNewBreadcrumb({
          text: this.$t("contractors.list-title"),
          to: "/contractors",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.contractor.name,
          to: `/contractors/${this.$route.params.contractorid}`
        });
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.normGroup.name,
          to: `/contractors/${this.$route.params.contractorid}/normgroups/${this.$route.params.normgroupid}`
        });
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      if (!!this.norm) {
        this.notifyNewBreadcrumb({
          text: this.norm.name,
          to: `/contractors/${this.$route.params.contractorid}/normgroups/${this.$route.params.normgroupid}/norms/${this.$route.params.id}`
        });
      } else {
        this.notifyNewBreadcrumb({
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        });
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadNorm: "LOAD_CONTRACTOR_NORM",
      updateNorm: "UPDATE_CONTRACTOR_NORM",
      deleteNorm: "DELETE_CONTRACTOR_NORM"
    }),
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    close() {
      this.$router.push(
        this.$store.getters.backBreadcrumb.to ||
          `/contractors/${this.$route.params.contractorid}/normgroups/${this.$route.params.normgroupid}`
      );
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    cancel() {
      this.close();
    },
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteNorm({
          id: this.$route.params.id,
          name: this.norm.name
        });
        this.close();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.saving = true;
      try {
        if (!this.norm.archived) {
          this.norm.archivedDate = null;
        } else if (this.norm.archived && !this.norm.archivedDate) {
          this.norm.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateNorm({
          ...this.norm
        });

        if (closeOnComplete) {
          this.close();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    }
  },
  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "contractor-norm-existing",
      parentalContext: "contractors-existing"
    });

    this.processing = true;

    this.contractor = this.$store.state.contractors.fullList.find(
      (x: any) => x.id == this.$route.params.contractorid
    );
    if (!this.contractor) {
      this.contractor = await contractorService.getByID(this.$route.params.contractorid);
    }
    this.normGroup = this.$store.state.contractorNormGroups.fullList.find(
      (x: any) => x.id == this.$route.params.normgroupid
    );
    if (!this.normGroup) {
      this.normGroup = await contractorNormGroupService.getByID(this.$route.params.normgroupid);
    }
    this.updateBreadCrumbs();
    try {
      await Promise.all([this.loadNorm(this.$route.params.id)]);

      let norm = this.$store.state.contractorNorms.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );
      this.norm = {
        ...norm,
        name: truncateWithEllipsis(norm.description, 30),
        archived: !!norm?.archivedDate
      };
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});
