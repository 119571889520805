import { WorkSubTypeWithParentDetails, WorkType } from "../services";
import { SortWorkTypes } from "./worktype";

type WorkSubTypeLike = {
  order: number | undefined;
  effectiveOrder: number | undefined;
  name: string | null | undefined;
};
function CompareWorkSubTypes(a: WorkSubTypeLike, b: WorkSubTypeLike): number {
  let aEffectiveOrder = a.effectiveOrder ?? 0;
  let bEffectiveOrder = b.effectiveOrder ?? 0;
  if (aEffectiveOrder != bEffectiveOrder) return aEffectiveOrder - bEffectiveOrder;

  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}

/// Sorts a list of Work Sub Types by order and name.  To be used only with work sub types all under the same work type
export function SortWorkSubTypes<WST extends WorkSubTypeLike>(
  items: WST[] | null | undefined
): WST[] {
  if (!items) return [];
  return items.sort(CompareWorkSubTypes);
}

/// Sorts of a list of Work Sub Types under multiple work types.  Sorts work types, then sorts sub types for each work type individually
export function SortWorkSubTypesByParentWorkTypes<
  WST extends WorkSubTypeWithParentDetails,
  WT extends WorkType
>(items: WST[] | null | undefined, parents: WT[] | null | undefined): WST[] {
  if (!items?.length) return [];
  if (!parents?.length) return items.sort(CompareWorkSubTypes);

  let sortedParents = SortWorkTypes(parents);
  let allSortedItems = [] as WST[];
  for (let parent of sortedParents) {
    let itemsForParent = SortWorkSubTypes(items.filter(x => x.workTypeID == parent.id));
    allSortedItems = allSortedItems.concat(itemsForParent);
  }
  return allSortedItems;
}
