import FDVue from "@fd/lib/vue";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective
} from "@fd/lib/vue/utility/dataTable";
import { PeopleRow, reportService } from "../../services";
import downloadBlob from "../../../../lib/client-util/downloadBlob";
import printBlob from "../../../../lib/client-util/printBlob";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { PersonWithExtraDetails } from "../../dataMixins/peopleList";

const PeopleList = FDVue.extend({
  name: "fd-people-list",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {},

  props: {
    items: {
      default: () => [],
      type: Array
    },
    allowDelete: {
      default: true,
      type: Boolean
    },
    hideDateRangePicker: {
      default: false,
      type: Boolean
    },
    tablesearch: {
      default: "",
      type: String
    },
    processing: {
      default: false,
      type: Boolean
    },
    archivedLoading: {
      default: false,
      type: Boolean
    },
    showArchived: {
      default: false,
      type: Boolean
    },
    showArchivedDateRange: {
      default: () => [],
      type: Array
    },
    showArchivedMinDate: {
      default: undefined,
      type: Date
    },
    showArchivedMaxDate: {
      default: undefined,
      type: Date
    },
    anyInactiveUsers: {
      default: false,
      type: Boolean
    },
    anyRequestingAccessUsers: {
      default: false,
      type: Boolean
    },
    anyLockedUsers: {
      default: false,
      type: Boolean
    },
    contractorsInUse: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    iconColumnArgument(): string {
      return this.anyLockedUsers || this.anyInactiveUsers || this.anyRequestingAccessUsers
        ? "icon"
        : FDHiddenArgumentName;
    },
    actionColumnArgument(): string {
      return this.allowDelete ? "actions" : "action";
    }
  },

  methods: {
    itemClassName(item: any) {
      if (!this.$store.state.curEnvironment?.allowSelfSignUp) return "";
      return item.isRequestingEnrolment ? "fd-requesting-user-table-row-background" : "";
    },
    async downloadAndPrintWorkOrderAdminReport(reportType: string = "pdf") {
      try {
        var peopleRow = (this.items as PersonWithExtraDetails[]).map(
          x =>
            ({
              isActivated: !x.isActiveWithUnusedLogin,
              lastName: x.lastName,
              firstName: x.firstName,
              contractorID: x.contractorID,
              employeeNumber: x.employeeCode,
              badgeNumber: x.employeeBadge,
              isForeman: x.isForeman,
              isGeneralForeman: x.isGeneralForeman,
              isCoordinator: x.isCoordinator,
              isPlanner: x.isPlanner,
              isTimeManager: x.isTimeManager,
              canSignIn: x.canSignIn
            } as PeopleRow)
        );
        console.log("The items is", this.items);
        var blob = await reportService.getPeoplePrintoutReportContentWithData(
          peopleRow,
          reportType,
          "",
          "",
          false,
          true,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "people-printout.xlsx");
        } else {
          printBlob(blob, "people-printout.pdf", "application/pdf");
        }
      } catch (error) {
        // handle this error
      }
    }
  }
});

export default PeopleList;
