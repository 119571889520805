var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "siteconfiguration" } },
    [
      !_vm.currentUserCanConfigureSettings
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("configuration.no-permission")) + "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { extended: "", loading: _vm.processing } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c("v-breadcrumbs", {
                            class: [
                              _vm.processing
                                ? "breadcrumb-processing-opacity"
                                : "",
                              "pl-0"
                            ],
                            attrs: {
                              items: _vm.$store.state.currentBreadcrumbs,
                              large: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "divider",
                                fn: function() {
                                  return [
                                    _c("v-icon", [_vm._v("fa-chevron-right")])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.processing
                            ? _c("v-progress-circular", {
                                staticClass: "mr-3",
                                attrs: {
                                  indeterminate: true,
                                  rotate: 0,
                                  size: 32,
                                  width: 4,
                                  color: "white"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-tabs",
                {
                  ref: "tabs",
                  staticClass: "mt-3",
                  attrs: {
                    "icons-and-text": "",
                    "show-arrows": !_vm.$vuetify.breakpoint.xsOnly,
                    "next-icon": "fa-arrow-circle-right",
                    "prev-icon": "fa-arrow-circle-left"
                  },
                  model: {
                    value: _vm.active_tab,
                    callback: function($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      key: _vm.detailsTab.key,
                      ref: "tab",
                      attrs: {
                        "data-cy": "details",
                        href: `#tab-${_vm.detailsTab.key}`
                      },
                      on: {
                        click: function($event) {
                          return _vm.tabSelected(_vm.detailsTab)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.detailsTab.tabname) +
                          "\n          "
                      ),
                      _c("v-icon", [_vm._v("fas fa-edit")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.jobsTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.jobsTab.key,
                          ref: "tab",
                          attrs: {
                            "data-cy": "jobs",
                            href: `#tab-${_vm.jobsTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.jobsTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.jobsTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.$store.getters.icon("jobs")))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.notificationsTab.visible ||
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.notificationsTab.key,
                          ref: "tab",
                          attrs: {
                            "data-cy": "notifications",
                            href: `#tab-${_vm.notificationsTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.notificationsTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.notificationsTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [_vm._v("fas fa-bell")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.workflowTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.workflowTab.key,
                          attrs: {
                            "data-cy": "workflow",
                            href: `#tab-${_vm.workflowTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.workflowTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.workflowTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [_vm._v("fa-arrows-turn-to-dots")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.defaultsTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.defaultsTab.key,
                          attrs: {
                            "data-cy": "defaults",
                            href: `#tab-${_vm.defaultsTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.defaultsTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.defaultsTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [_vm._v("fa-pen-field")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.labourTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.labourTab.key,
                          attrs: {
                            "data-cy": "defaults",
                            href: `#tab-${_vm.labourTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.labourTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.labourTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [_vm._v("fa-user-hard-hat")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inspectionsTab.visible || _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.inspectionsTab.key,
                          attrs: {
                            "data-cy": "inspections",
                            href: `#tab-${_vm.inspectionsTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.inspectionsTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.inspectionsTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [
                            _vm._v(
                              _vm._s(_vm.$store.getters.icon("inspections"))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserCanConfigurePrivateSettings &&
                  _vm.allowImports &&
                  (_vm.importsTab.visible || _vm.$vuetify.breakpoint.smAndUp)
                    ? _c(
                        "v-tab",
                        {
                          key: _vm.importsTab.key,
                          attrs: {
                            "data-cy": "imports",
                            href: `#tab-${_vm.importsTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.importsTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.importsTab.tabname) +
                              "\n          "
                          ),
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.$store.getters.icon("imports")))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                      staticStyle: { position: "relative" },
                                      attrs: { text: "" }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.more")) +
                                      "\n\n              "
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("v-icon", [
                                        _vm._v("fas fa-ellipsis-h")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        { staticClass: "grey lighten-3" },
                        _vm._l(_vm.hiddenTabDefinitions, function(item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item.tabname,
                              on: {
                                click: function($event) {
                                  return _vm.showNewTabFromMoreMenu(item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.tabname) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.active_tab,
                    callback: function($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.detailsTab.key,
                      attrs: { value: `tab-${_vm.detailsTab.key}` }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "detailsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.site-name"
                                      ),
                                      disabled: _vm.processing,
                                      rules: [_vm.rules.required]
                                    },
                                    model: {
                                      value: _vm.environment.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.environment, "name", $$v)
                                      },
                                      expression: "environment.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.site-identifier"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value: _vm.environment.identifier,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "identifier",
                                          $$v
                                        )
                                      },
                                      expression: "environment.identifier"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "pt-1", attrs: { cols: "12" } },
                                [
                                  _c("fd-rich-textarea", {
                                    attrs: {
                                      disabled: _vm.processing,
                                      label: _vm.$t("common.description"),
                                      allowImages: false
                                    },
                                    model: {
                                      value: _vm.environment.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "environment.description"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.site-info.shipping-address-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "start" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "12",
                                            md: "12",
                                            lg: "12"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "configuration.site-info.shipping-address-line-1"
                                              ),
                                              disabled: _vm.processing,
                                              "hide-details": ""
                                            },
                                            model: {
                                              value:
                                                _vm.environment
                                                  .shippingAddressLine1,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.environment,
                                                  "shippingAddressLine1",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "environment.shippingAddressLine1"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "12",
                                            md: "12",
                                            lg: "12"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "configuration.site-info.shipping-address-line-2"
                                              ),
                                              disabled: _vm.processing,
                                              "hide-details": ""
                                            },
                                            model: {
                                              value:
                                                _vm.environment
                                                  .shippingAddressLine2,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.environment,
                                                  "shippingAddressLine2",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "environment.shippingAddressLine2"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "12",
                                            md: "12",
                                            lg: "12"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "configuration.site-info.shipping-address-line-3"
                                              ),
                                              disabled: _vm.processing,
                                              "hide-details": ""
                                            },
                                            model: {
                                              value:
                                                _vm.environment
                                                  .shippingAddressLine3,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.environment,
                                                  "shippingAddressLine3",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "environment.shippingAddressLine3"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.environment.enableScaffoldLocation
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                align: "center",
                                                cols: "4"
                                              }
                                            },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  label: "Latitude",
                                                  value:
                                                    _vm.environment.latitude,
                                                  readonly: true,
                                                  "hide-details": "",
                                                  dense: "",
                                                  rules: _vm.environment
                                                    .enableScaffoldLocation
                                                    ? [_vm.rules.required]
                                                    : []
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                align: "center",
                                                cols: "4"
                                              }
                                            },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  label: "Longitude",
                                                  value:
                                                    _vm.environment.longitude,
                                                  readonly: true,
                                                  "hide-details": "",
                                                  dense: "",
                                                  rules: _vm.environment
                                                    .enableScaffoldLocation
                                                    ? [_vm.rules.required]
                                                    : []
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { staticClass: "grow" },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: {
                                                    dense: "",
                                                    "no-gutters": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        outlined: ""
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.changeLocation
                                                      }
                                                    },
                                                    [_vm._v("Change")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "12",
                                                md: "12",
                                                lg: "12"
                                              }
                                            },
                                            [
                                              _c("fp-map", {
                                                attrs: {
                                                  height: "250px",
                                                  value: _vm.location,
                                                  disabled: true,
                                                  zoom: 14,
                                                  "map-display-type":
                                                    _vm.mapLayerType
                                                },
                                                on: {
                                                  "update:mapDisplayType": function(
                                                    $event
                                                  ) {
                                                    _vm.mapLayerType = $event
                                                  },
                                                  "update:map-display-type": function(
                                                    $event
                                                  ) {
                                                    _vm.mapLayerType = $event
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.site-info.site-calculation-settings-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.site-info.site-productivity"
                                      ),
                                      disabled: _vm.processing,
                                      items: _vm.siteProductivityOptions,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.siteProductivity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "siteProductivity",
                                          $$v
                                        )
                                      },
                                      expression: "environment.siteProductivity"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.site-info.site-blended-labour-rate"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.blendedLabourRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "blendedLabourRate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.blendedLabourRate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.site-info.site-factor-1"
                                      ),
                                      disabled: _vm.processing,
                                      suffix: "%",
                                      "hide-details": ""
                                    },
                                    on: { keydown: _vm.fieldKeyDown },
                                    model: {
                                      value: _vm.factor1,
                                      callback: function($$v) {
                                        _vm.factor1 = $$v
                                      },
                                      expression: "factor1"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.site-info.site-factor-2"
                                      ),
                                      disabled: _vm.processing,
                                      suffix: "%",
                                      "hide-details": ""
                                    },
                                    on: { keydown: _vm.fieldKeyDown },
                                    model: {
                                      value: _vm.factor2,
                                      callback: function($$v) {
                                        _vm.factor2 = $$v
                                      },
                                      expression: "factor2"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.site-uses-secondary-deck-planks"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .usesSecondaryTypeDeckPlanks,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "usesSecondaryTypeDeckPlanks",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.usesSecondaryTypeDeckPlanks"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.site-info.site-security-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "px-3 pb-3",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.login-requires-password-and-code"
                                      ),
                                      disabled: _vm.processing,
                                      "persistent-hint": "",
                                      hint: _vm.$t(
                                        "configuration.site-info.login-requires-password-and-code-hint"
                                      )
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .loginRequiresPasswordAndCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "loginRequiresPasswordAndCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.loginRequiresPasswordAndCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-4" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.site-info.site-features-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "px-3 pb-3",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.track-scaffold-vlf"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value: _vm.environment.trackScaffoldVLF,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "trackScaffoldVLF",
                                          $$v
                                        )
                                      },
                                      expression: "environment.trackScaffoldVLF"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.walkdown-vlf-required"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment.trackScaffoldVLF,
                                      hint: !_vm.environment.trackScaffoldVLF
                                        ? _vm.$t(
                                            "configuration.site-info.walkdown-vlf-disabled-explanation"
                                          )
                                        : "",
                                      "persistent-hint": !_vm.environment
                                        .trackScaffoldVLF
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "message",
                                        fn: function({ message }) {
                                          return [
                                            !_vm.environment.trackScaffoldVLF
                                              ? _c("fp-item-disabled-message", {
                                                  attrs: { message: message }
                                                })
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value:
                                        _vm.environment.walkdownVLFRequired,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "walkdownVLFRequired",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.walkdownVLFRequired"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.enable-norm-entry"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value: _vm.environment.enableNorms,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enableNorms",
                                          $$v
                                        )
                                      },
                                      expression: "environment.enableNorms"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.enable-purchase-orders"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment.enablePurchaseOrders,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enablePurchaseOrders",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.enablePurchaseOrders"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "6" } },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.enable-scaffold-location"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment.enableScaffoldLocation,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enableScaffoldLocation",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.enableScaffoldLocation"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "6" } },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.site-info.erect-work-orders-require-scaffold-location"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment.enableScaffoldLocation,
                                      hint: !!_vm.environment
                                        .enableScaffoldLocation
                                        ? _vm.$t(
                                            "configuration.site-info.erect-work-orders-require-scaffold-location-hint"
                                          )
                                        : _vm.$t(
                                            "configuration.site-info.erect-work-orders-require-scaffold-location-disabled-reason"
                                          ),
                                      "persistent-hint": ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "message",
                                        fn: function({ message }) {
                                          return [
                                            !_vm.environment
                                              .enableScaffoldLocation
                                              ? _c("fp-item-disabled-message", {
                                                  attrs: { message: message }
                                                })
                                              : _c("span", [
                                                  _vm._v(_vm._s(message))
                                                ])
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value:
                                        _vm.environment
                                          .erectWorkOrdersRequireScaffoldLocation,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "erectWorkOrdersRequireScaffoldLocation",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.erectWorkOrdersRequireScaffoldLocation"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.jobsTab.key,
                      attrs: { value: `tab-${_vm.jobsTab.key}` }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2 pt-3 fd-subheader-with-control",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { align: "center", cols: "12", sm: "8" }
                            },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticStyle: {
                                    "font-size": "1em",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("configuration.jobs.paint-title")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pr-3 d-flex justify-sm-end" },
                                [
                                  _c("v-switch", {
                                    staticClass:
                                      "fd-remove-messages pl-3 pl-sm-0",
                                    attrs: {
                                      label: _vm.$t("common.enabled"),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value: _vm.environment.enablePaintWork,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enablePaintWork",
                                          $$v
                                        )
                                      },
                                      expression: "environment.enablePaintWork"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2 pt-3 fd-subheader-with-control",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { align: "center", cols: "12", sm: "8" }
                            },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticStyle: {
                                    "font-size": "1em",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "configuration.jobs.insulation-title"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pr-3 d-flex justify-sm-end" },
                                [
                                  _c("v-switch", {
                                    staticClass:
                                      "fd-remove-messages pl-3 pl-sm-0",
                                    attrs: {
                                      label: _vm.$t("common.enabled"),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment.enableInsulationWork,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enableInsulationWork",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.enableInsulationWork"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2 pt-3 fd-subheader-with-control",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { align: "center", cols: "12", sm: "8" }
                            },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticStyle: {
                                    "font-size": "1em",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "configuration.jobs.heattrace-title"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pr-3 d-flex justify-sm-end" },
                                [
                                  _c("v-switch", {
                                    staticClass:
                                      "fd-remove-messages pl-3 pl-sm-0",
                                    attrs: {
                                      label: _vm.$t("common.enabled"),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment.enableHeatTraceWork,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enableHeatTraceWork",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.enableHeatTraceWork"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2 pt-3 fd-subheader-with-control",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { align: "center", cols: "12", sm: "8" }
                            },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticStyle: {
                                    "font-size": "1em",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "configuration.jobs.refractory-title"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pr-3 d-flex justify-sm-end" },
                                [
                                  _c("v-switch", {
                                    staticClass:
                                      "fd-remove-messages pl-3 pl-sm-0",
                                    attrs: {
                                      label: _vm.$t("common.enabled"),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment.enableRefractoryWork,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enableRefractoryWork",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.enableRefractoryWork"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2 pt-3 fd-subheader-with-control",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { align: "center", cols: "12", sm: "8" }
                            },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticStyle: {
                                    "font-size": "1em",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "configuration.jobs.fireproofing-title"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pr-3 d-flex justify-sm-end" },
                                [
                                  _c("v-switch", {
                                    staticClass:
                                      "fd-remove-messages pl-3 pl-sm-0",
                                    attrs: {
                                      label: _vm.$t("common.enabled"),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment.enableFireproofingWork,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enableFireproofingWork",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.enableFireproofingWork"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2 pt-3 fd-subheader-with-control",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { align: "center", cols: "12", sm: "8" }
                            },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticStyle: {
                                    "font-size": "1em",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "configuration.jobs.maintenance-title"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pr-3 d-flex justify-sm-end" },
                                [
                                  _c("v-switch", {
                                    staticClass:
                                      "fd-remove-messages pl-3 pl-sm-0",
                                    attrs: {
                                      label: _vm.$t("common.enabled"),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment.enableMaintenanceWork,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enableMaintenanceWork",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.enableMaintenanceWork"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.notificationsTab.key,
                      attrs: { value: `tab-${_vm.notificationsTab.key}` }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2 pt-3 fd-subheader-with-control",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { align: "center", cols: "12", sm: "8" }
                            },
                            [
                              _c(
                                "v-subheader",
                                {
                                  staticStyle: {
                                    "font-size": "1em",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "configuration.notifications.enable-notifications"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pr-3 d-flex justify-sm-end" },
                                [
                                  _c("v-switch", {
                                    staticClass:
                                      "fd-remove-messages pl-3 pl-sm-0",
                                    attrs: {
                                      label: _vm.$t("common.enabled"),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment.enableNotifications,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "enableNotifications",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.enableNotifications"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.workflowTab.key,
                      attrs: { value: `tab-${_vm.workflowTab.key}`, eager: "" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "workflowform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-2 pt-3" },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    md: "6"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.features.scaffold-request-dismantle-date-required-label"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .scaffoldRequestDismantleDateRequired,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "scaffoldRequestDismantleDateRequired",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.scaffoldRequestDismantleDateRequired"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    md: "6"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.workflow.snapshot-approved-walkdowns-label"
                                      ),
                                      hint: _vm.$t(
                                        "configuration.workflow.snapshot-approved-walkdowns-hint"
                                      ),
                                      "persistent-hint": "",
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .snapshotApprovedWalkdowns,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "snapshotApprovedWalkdowns",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.snapshotApprovedWalkdowns"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.requests-require-walkdown-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.workflow.erect-requests-require-walkdown"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultErectScaffoldRequestRequiresWalkDown,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultErectScaffoldRequestRequiresWalkDown",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultErectScaffoldRequestRequiresWalkDown"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.workflow.modify-requests-require-walkdown"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultModifyScaffoldRequestRequiresWalkDown,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultModifyScaffoldRequestRequiresWalkDown",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultModifyScaffoldRequestRequiresWalkDown"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.workflow.dismantle-requests-require-walkdown"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultDismantleScaffoldRequestRequiresWalkDown,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultDismantleScaffoldRequestRequiresWalkDown",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultDismantleScaffoldRequestRequiresWalkDown"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveScaffoldRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveScaffoldRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveScaffoldRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.scaffoldContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveScaffoldRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-maintenance-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveMaintenanceRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveMaintenanceRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveMaintenanceRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.maintenanceContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveMaintenanceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultMaintenanceWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultMaintenanceWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultMaintenanceWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveMaintenanceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultMaintenanceWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultMaintenanceWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultMaintenanceWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveMaintenanceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultMaintenanceWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultMaintenanceWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultMaintenanceWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveMaintenanceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultMaintenanceWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultMaintenanceWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultMaintenanceWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultMaintenanceWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-paint-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApprovePaintRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApprovePaintRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApprovePaintRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.paintContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApprovePaintRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultPaintWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultPaintWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultPaintWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApprovePaintRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultPaintWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultPaintWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultPaintWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApprovePaintRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultPaintWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultPaintWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultPaintWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApprovePaintRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultPaintWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultPaintWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultPaintWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultPaintWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-insulation-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveInsulationRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveInsulationRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveInsulationRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.insulationContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveInsulationRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultInsulationWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultInsulationWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultInsulationWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveInsulationRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultInsulationWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultInsulationWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultInsulationWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveInsulationRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultInsulationWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultInsulationWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultInsulationWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveInsulationRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultInsulationWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultInsulationWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultInsulationWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultInsulationWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-heattrace-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveHeatTraceRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveHeatTraceRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveHeatTraceRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.heatTraceContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveHeatTraceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultHeatTraceWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultHeatTraceWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultHeatTraceWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultHeatTraceWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultHeatTraceWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveHeatTraceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultHeatTraceWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultHeatTraceWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultHeatTraceWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultHeatTraceWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultHeatTraceWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveHeatTraceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultHeatTraceWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultHeatTraceWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultHeatTraceWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultHeatTraceWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultHeatTraceWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveHeatTraceRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultHeatTraceWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultHeatTraceWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultHeatTraceWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultHeatTraceWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-refractory-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveRefractoryRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveRefractoryRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveRefractoryRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.refractoryContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveRefractoryRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultRefractoryWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultRefractoryWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultRefractoryWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultRefractoryWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultRefractoryWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveRefractoryRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultRefractoryWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultRefractoryWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultRefractoryWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultRefractoryWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultRefractoryWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveRefractoryRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultRefractoryWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultRefractoryWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultRefractoryWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultRefractoryWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultRefractoryWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveRefractoryRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultRefractoryWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultRefractoryWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultRefractoryWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultRefractoryWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-fireproofing-request-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveFireproofingRequests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveFireproofingRequests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveFireproofingRequests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.fireproofingContractors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-contractor-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveFireproofingRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultFireproofingWorkOrderAssignedContractorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultFireproofingWorkOrderAssignedContractorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultFireproofingWorkOrderAssignedContractorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultFireproofingWorkOrderAssignedContractorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableCoordinators(
                                        _vm.environment
                                          .defaultFireproofingWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-coordinator-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveFireproofingRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultFireproofingWorkOrderAssignedCoordinatorID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultFireproofingWorkOrderAssignedCoordinatorID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultFireproofingWorkOrderAssignedCoordinatorID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultFireproofingWorkOrderAssignedCoordinatorID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableGeneralForemen(
                                        _vm.environment
                                          .defaultFireproofingWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-general-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveFireproofingRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultFireproofingWorkOrderAssignedGeneralForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultFireproofingWorkOrderAssignedGeneralForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultFireproofingWorkOrderAssignedGeneralForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultFireproofingWorkOrderAssignedGeneralForemanID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.selectableForemen(
                                        _vm.environment
                                          .defaultFireproofingWorkOrderAssignedContractorID
                                      ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-assigned-foreman-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveFireproofingRequests,
                                      rules:
                                        _vm.configurationRules
                                          .defaultFireproofingWorkOrderAssignedForemanID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultFireproofingWorkOrderAssignedForemanID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultFireproofingWorkOrderAssignedForemanID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultFireproofingWorkOrderAssignedForemanID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "8"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.workflow.auto-count-sheet-approval-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pr-3 d-flex justify-sm-end"
                                    },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "fd-remove-messages pl-3 pl-sm-0",
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value:
                                            _vm.environment
                                              .automaticallyApproveCountSheets,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.environment,
                                              "automaticallyApproveCountSheets",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "environment.automaticallyApproveCountSheets"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allYards,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-from-yard-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveCountSheets,
                                      rules:
                                        _vm.configurationRules
                                          .defaultCountSheetFromYardID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultCountSheetFromYardID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultCountSheetFromYardID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultCountSheetFromYardID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allYards,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.workflow.default-to-yard-label"
                                      ),
                                      disabled:
                                        _vm.processing ||
                                        !_vm.environment
                                          .automaticallyApproveCountSheets,
                                      rules:
                                        _vm.configurationRules
                                          .defaultCountSheetToYardID,
                                      hint: _vm.$t(
                                        "configuration.workflow.default-to-yard-hint"
                                      ),
                                      "persistent-hint":
                                        _vm.environment
                                          .automaticallyApproveCountSheets &&
                                        !_vm.environment
                                          .defaultCountSheetToYardID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultCountSheetToYardID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultCountSheetToYardID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultCountSheetToYardID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.defaultsTab.key,
                      attrs: { value: `tab-${_vm.defaultsTab.key}` }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "defaultsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.defaults.timesheet-default-cost-code-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allCostCodes,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-equipment-cost-code-label"
                                      ),
                                      disabled: _vm.processing,
                                      rules:
                                        _vm.configurationRules
                                          .defaultEquipmentCostCodeID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultEquipmentCostCodeID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultEquipmentCostCodeID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultEquipmentCostCodeID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.defaults.request-default-cost-code-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allCostCodes,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-erect-cost-code-label"
                                      ),
                                      disabled: _vm.processing,
                                      rules:
                                        _vm.configurationRules
                                          .defaultErectRequestCostCodeID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultErectRequestCostCodeID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultErectRequestCostCodeID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultErectRequestCostCodeID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", {
                                staticClass:
                                  "justify-space-between pt-1 pb-1 d-none d-lg-flex",
                                attrs: { align: "center", cols: "0", sm: "6" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allCostCodes,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-modify-cost-code-label"
                                      ),
                                      disabled: _vm.processing,
                                      rules:
                                        _vm.configurationRules
                                          .defaultModifyRequestCostCodeID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultModifyRequestCostCodeID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultModifyRequestCostCodeID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultModifyRequestCostCodeID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", {
                                staticClass:
                                  "justify-space-between pt-1 pb-1 d-none d-lg-flex",
                                attrs: { align: "center", cols: "0", sm: "6" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allCostCodes,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-dismantle-cost-code-label"
                                      ),
                                      disabled: _vm.processing,
                                      rules:
                                        _vm.configurationRules
                                          .defaultDismantleRequestCostCodeID
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultDismantleRequestCostCodeID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultDismantleRequestCostCodeID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultDismantleRequestCostCodeID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.defaults.scaffold-defaults-subheader"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pl-0 pr-0 pb-0 pt-5",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allScaffoldTypeModifiers,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-scaffold-type-modifier-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldTypeModifierID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldTypeModifierID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldTypeModifierID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allScaffoldDistanceModifiers,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-scaffold-distance-modifier-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldDistanceModifierID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldDistanceModifierID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldDistanceModifierID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allScaffoldElevationModifiers,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-scaffold-elevation-modifier-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldElevationModifierID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldElevationModifierID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldElevationModifierID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allScaffoldHeightModifiers,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-scaffold-height-modifier-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultScaffoldHeightModifierID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultScaffoldHeightModifierID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultScaffoldHeightModifierID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      clearable: "",
                                      items: _vm.allBuildDismantleRatios,
                                      "item-text": "ratio",
                                      "item-value": "id",
                                      label: _vm.$t(
                                        "configuration.defaults.default-build-dismantle-ratio-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultBuildDismantleRatioID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultBuildDismantleRatioID",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultBuildDismantleRatioID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.defaults.default-crew-size-label"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": "",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: _vm.environment.defaultCrewSize,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultCrewSize",
                                          $$v
                                        )
                                      },
                                      expression: "environment.defaultCrewSize"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.defaults.default-factor-1-label"
                                      ),
                                      disabled: _vm.processing,
                                      suffix: "%",
                                      "hide-details": "",
                                      type: "number",
                                      min: "0",
                                      "hide-spin-buttons": ""
                                    },
                                    on: { keydown: _vm.fieldKeyDown },
                                    model: {
                                      value: _vm.defaultFactor1,
                                      callback: function($$v) {
                                        _vm.defaultFactor1 = $$v
                                      },
                                      expression: "defaultFactor1"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.defaults.default-factor-2-label"
                                      ),
                                      disabled: _vm.processing,
                                      suffix: "%",
                                      "hide-details": "",
                                      type: "number",
                                      min: "0",
                                      "hide-spin-buttons": ""
                                    },
                                    on: { keydown: _vm.fieldKeyDown },
                                    model: {
                                      value: _vm.defaultFactor2,
                                      callback: function($$v) {
                                        _vm.defaultFactor2 = $$v
                                      },
                                      expression: "defaultFactor2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.labourTab.key,
                      attrs: { value: `tab-${_vm.labourTab.key}` }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "labourform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pt-3",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "6",
                                    lg: "3"
                                  }
                                },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.labour.weekending-day-label"
                                      ),
                                      disabled: _vm.processing,
                                      items: _vm.weekendingDayOptions,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.weekEndingDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "weekEndingDay",
                                          $$v
                                        )
                                      },
                                      expression: "environment.weekEndingDay"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c("v-col", {
                                    attrs: {
                                      align: "center",
                                      cols: "0",
                                      md: "6",
                                      lg: "9"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "6",
                                    lg: "3"
                                  }
                                },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.labour.default-max-daily-employee-hours-label"
                                      ),
                                      disabled: _vm.processing,
                                      items: _vm.dayHourValues,
                                      hint: _vm.$t(
                                        "configuration.labour.default-max-daily-employee-hours-hint"
                                      ),
                                      "persistent-hint": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .defaultMaxDailyEmployeeHours,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "defaultMaxDailyEmployeeHours",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.defaultMaxDailyEmployeeHours"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c("v-col", {
                                    attrs: {
                                      align: "center",
                                      cols: "0",
                                      md: "6",
                                      lg: "9"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.labour.three-week-look-ahead-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pt-3",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12",
                                    md: "6",
                                    lg: "3"
                                  }
                                },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "configuration.labour.daily-work-hours"
                                      ),
                                      disabled: _vm.processing,
                                      items: _vm.dayHourValues,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.dailyWorkHours,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "dailyWorkHours",
                                          $$v
                                        )
                                      },
                                      expression: "environment.dailyWorkHours"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c("v-col", {
                                    attrs: {
                                      align: "center",
                                      cols: "0",
                                      md: "6",
                                      lg: "9"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0 pb-0 mb-0 pt-8",
                                  attrs: { cols: "12" }
                                },
                                [_c("v-subheader", [_vm._v("Work Days")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "2"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.labour.monday-is-work-day"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.mondayIsWorkDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "mondayIsWorkDay",
                                          $$v
                                        )
                                      },
                                      expression: "environment.mondayIsWorkDay"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "2"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.labour.tuesday-is-work-day"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.tuesdayIsWorkDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "tuesdayIsWorkDay",
                                          $$v
                                        )
                                      },
                                      expression: "environment.tuesdayIsWorkDay"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "2"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.labour.wednesday-is-work-day"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.wednesdayIsWorkDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "wednesdayIsWorkDay",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.wednesdayIsWorkDay"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "2"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.labour.thursday-is-work-day"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.thursdayIsWorkDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "thursdayIsWorkDay",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.thursdayIsWorkDay"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "2"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.labour.friday-is-work-day"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.fridayIsWorkDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "fridayIsWorkDay",
                                          $$v
                                        )
                                      },
                                      expression: "environment.fridayIsWorkDay"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", {
                                    attrs: {
                                      align: "center",
                                      cols: "12",
                                      sm: "6",
                                      md: "4",
                                      xl: "2"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "2"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.labour.saturday-is-work-day"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.saturdayIsWorkDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "saturdayIsWorkDay",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.saturdayIsWorkDay"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "2"
                                  }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.labour.sunday-is-work-day"
                                      ),
                                      disabled: _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.environment.sundayIsWorkDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "sundayIsWorkDay",
                                          $$v
                                        )
                                      },
                                      expression: "environment.sundayIsWorkDay"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "12"
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.labour.weekly-thresholds-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-2 pt-3",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "4" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      outlined: "",
                                      items: _vm.weeklyHourValues,
                                      label: _vm.$t(
                                        "configuration.labour.straighttime-short-label"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value: _vm.weeklySTValue,
                                      callback: function($$v) {
                                        _vm.weeklySTValue = $$v
                                      },
                                      expression: "weeklySTValue"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "4" } },
                                [
                                  _c("v-autocomplete", {
                                    class: {
                                      "fd-readonly": _vm.weeklySTValue == null
                                    },
                                    attrs: {
                                      outlined: "",
                                      items: _vm.weeklyHourValues,
                                      label: _vm.$t(
                                        "configuration.labour.overtime-short-label"
                                      ),
                                      disabled: _vm.processing,
                                      readonly: _vm.weeklySTValue == null
                                    },
                                    model: {
                                      value: _vm.weeklyOTValue,
                                      callback: function($$v) {
                                        _vm.weeklyOTValue = $$v
                                      },
                                      expression: "weeklyOTValue"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "4" } },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "fd-readonly",
                                    attrs: {
                                      outlined: "",
                                      items: _vm.weeklyHourValues,
                                      label: _vm.$t(
                                        "configuration.labour.doubletime-short-label"
                                      ),
                                      value: _vm.weeklyDDValue,
                                      disabled: _vm.processing,
                                      readonly: true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          false
                            ? _c(
                                "v-row",
                                { staticClass: "mx-2" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3", md: "2", lg: "1" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          outlined: "",
                                          label: "Weekly Hours",
                                          disabled: _vm.processing,
                                          items: _vm.weekHourValues,
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.weeklyExampleHours,
                                          callback: function($$v) {
                                            _vm.weeklyExampleHours = $$v
                                          },
                                          expression: "weeklyExampleHours"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "9", md: "10", lg: "11" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-range-slider", {
                                                attrs: {
                                                  min: "0",
                                                  max: _vm.weeklyExampleHours,
                                                  color: "success",
                                                  value: [
                                                    _vm.weeklyExampleSTValue,
                                                    _vm.weeklyExampleOTValue
                                                  ],
                                                  "thumb-label": "always",
                                                  readonly: true,
                                                  "hide-details": ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _vm._v(
                                                "** Does not include daily OT/DT Calculations."
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-2 pt-3 fd-subheader-with-control",
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: _vm.allowAdvancedDailyThresholds
                                      ? 8
                                      : 12
                                  }
                                },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticStyle: {
                                        "font-size": "1em",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "configuration.labour.daily-thresholds-subheader"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "4"
                                  }
                                },
                                [
                                  _vm.allowAdvancedDailyThresholds
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "pr-3 d-flex justify-sm-end"
                                        },
                                        [
                                          _c("v-switch", {
                                            staticClass:
                                              "fd-remove-messages pl-3 pl-sm-0",
                                            attrs: {
                                              label: _vm.$t("common.advanced"),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.advancedDailyThresholds,
                                              callback: function($$v) {
                                                _vm.advancedDailyThresholds = $$v
                                              },
                                              expression:
                                                "advancedDailyThresholds"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [_c("v-divider", { staticClass: "mx-3 mt-0" })],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.advancedDailyThresholds
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mx-2 pt-3",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6", md: "4" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          outlined: "",
                                          items: _vm.dailyHourValues,
                                          label: _vm.$t(
                                            "configuration.labour.straighttime-short-label"
                                          ),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.dailySTValue,
                                          callback: function($$v) {
                                            _vm.dailySTValue = $$v
                                          },
                                          expression: "dailySTValue"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6", md: "4" } },
                                    [
                                      _c("v-autocomplete", {
                                        class: {
                                          "fd-readonly":
                                            _vm.dailySTValue == null
                                        },
                                        attrs: {
                                          outlined: "",
                                          items: _vm.dailyHourValues,
                                          label: _vm.$t(
                                            "configuration.labour.overtime-short-label"
                                          ),
                                          disabled: _vm.processing,
                                          readonly: _vm.dailySTValue == null
                                        },
                                        model: {
                                          value: _vm.dailyOTValue,
                                          callback: function($$v) {
                                            _vm.dailyOTValue = $$v
                                          },
                                          expression: "dailyOTValue"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6", md: "4" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "fd-readonly",
                                        attrs: {
                                          outlined: "",
                                          items: _vm.dailyHourValues,
                                          label: _vm.$t(
                                            "configuration.labour.doubletime-short-label"
                                          ),
                                          value: _vm.dailyDDValue,
                                          disabled: _vm.processing,
                                          readonly: true
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          false && !_vm.advancedDailyThresholds
                            ? _c(
                                "v-row",
                                { staticClass: "mx-2" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3", md: "2", lg: "1" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          outlined: "",
                                          label: "Daily Hours",
                                          disabled: _vm.processing,
                                          items: _vm.dayHourValues,
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.dailyExampleHours,
                                          callback: function($$v) {
                                            _vm.dailyExampleHours = $$v
                                          },
                                          expression: "dailyExampleHours"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "9", md: "10", lg: "11" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "shrink" },
                                            [_vm._v("Default")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { staticClass: "grow" },
                                            [
                                              _c("v-range-slider", {
                                                attrs: {
                                                  min: "0",
                                                  max: _vm.dailyExampleHours,
                                                  color: "success",
                                                  value: [
                                                    _vm.dailyExampleSTValue,
                                                    _vm.dailyExampleOTValue
                                                  ],
                                                  ticks: "always",
                                                  "tick-labels": Array.from(
                                                    Array(
                                                      _vm.dailyExampleHours + 1
                                                    ).keys()
                                                  ).map(x => x.toFixed(0)),
                                                  "tick-size": "5",
                                                  readonly: true,
                                                  "hide-details": ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "shrink" },
                                            [_vm._v("Without OT")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { staticClass: "grow" },
                                            [
                                              _c("v-range-slider", {
                                                attrs: {
                                                  min: "0",
                                                  max: _vm.dailyExampleHours,
                                                  color: "success",
                                                  value: [
                                                    _vm.dailyExampleSTValueNoOT,
                                                    _vm.dailyExampleOTValueNoOT
                                                  ],
                                                  ticks: "always",
                                                  "tick-labels": Array.from(
                                                    Array(
                                                      _vm.dailyExampleHours + 1
                                                    ).keys()
                                                  ).map(x => x.toFixed(0)),
                                                  "tick-size": "5",
                                                  readonly: true,
                                                  "hide-details": ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "shrink" },
                                            [_vm._v("Without DD")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { staticClass: "grow" },
                                            [
                                              _c("v-range-slider", {
                                                attrs: {
                                                  min: "0",
                                                  max: _vm.dailyExampleHours,
                                                  color: "success",
                                                  value: [
                                                    _vm.dailyExampleSTValueNoDD,
                                                    _vm.dailyExampleOTValueNoDD
                                                  ],
                                                  ticks: "always",
                                                  "tick-labels": Array.from(
                                                    Array(
                                                      _vm.dailyExampleHours + 1
                                                    ).keys()
                                                  ).map(x => x.toFixed(0)),
                                                  "tick-size": "5",
                                                  readonly: true,
                                                  "hide-details": ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !!_vm.advancedDailyThresholds
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mx-2 pt-3",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-simple-table", [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "10%" },
                                            attrs: { span: "1" }
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "30%" },
                                            attrs: { span: "1" }
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "30%" },
                                            attrs: { span: "1" }
                                          }),
                                          _vm._v(" "),
                                          _c("col", {
                                            staticStyle: { width: "30%" },
                                            attrs: { span: "1" }
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th"),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "configuration.labour.straighttime-short-label"
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "configuration.labour.overtime-short-label"
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "configuration.labour.doubletime-short-label"
                                                  )
                                                )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _vm.processing
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass: "px-0 pb-0",
                                                    staticStyle: {
                                                      height: "4px",
                                                      position: "absolute",
                                                      width: "100%"
                                                    },
                                                    attrs: { colspan: "99" }
                                                  },
                                                  [
                                                    _c("v-progress-linear", {
                                                      attrs: {
                                                        indeterminate: ""
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tbody", [
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("weekdays.monday")
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .mondaySTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "mondaySTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.mondaySTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  class: {
                                                    "fd-readonly":
                                                      _vm.weekdayThresholdValues
                                                        .mondaySTValue == null
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly:
                                                      _vm.weekdayThresholdValues
                                                        .mondaySTValue == null
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .mondayOTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "mondayOTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.mondayOTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  staticClass: "fd-readonly",
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    value: _vm.mondayDDValue,
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("weekdays.tuesday")
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .tuesdaySTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "tuesdaySTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.tuesdaySTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  class: {
                                                    "fd-readonly":
                                                      _vm.weekdayThresholdValues
                                                        .tuesdaySTValue == null
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly:
                                                      _vm.weekdayThresholdValues
                                                        .tuesdaySTValue == null
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .tuesdayOTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "tuesdayOTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.tuesdayOTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  staticClass: "fd-readonly",
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    value: _vm.tuesdayDDValue,
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("weekdays.wednesday")
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .wednesdaySTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "wednesdaySTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.wednesdaySTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  class: {
                                                    "fd-readonly":
                                                      _vm.weekdayThresholdValues
                                                        .wednesdaySTValue ==
                                                      null
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly:
                                                      _vm.weekdayThresholdValues
                                                        .wednesdaySTValue ==
                                                      null
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .wednesdayOTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "wednesdayOTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.wednesdayOTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  staticClass: "fd-readonly",
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    value: _vm.wednesdayDDValue,
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("weekdays.thursday")
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .thursdaySTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "thursdaySTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.thursdaySTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  class: {
                                                    "fd-readonly":
                                                      _vm.weekdayThresholdValues
                                                        .thursdaySTValue == null
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly:
                                                      _vm.weekdayThresholdValues
                                                        .thursdaySTValue == null
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .thursdayOTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "thursdayOTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.thursdayOTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  staticClass: "fd-readonly",
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    value: _vm.thursdayDDValue,
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("weekdays.friday")
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .fridaySTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "fridaySTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.fridaySTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  class: {
                                                    "fd-readonly":
                                                      _vm.weekdayThresholdValues
                                                        .fridaySTValue == null
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly:
                                                      _vm.weekdayThresholdValues
                                                        .fridaySTValue == null
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .fridayOTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "fridayOTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.fridayOTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  staticClass: "fd-readonly",
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    value: _vm.fridayDDValue,
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("weekdays.saturday")
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .saturdaySTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "saturdaySTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.saturdaySTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  class: {
                                                    "fd-readonly":
                                                      _vm.weekdayThresholdValues
                                                        .saturdaySTValue == null
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly:
                                                      _vm.weekdayThresholdValues
                                                        .saturdaySTValue == null
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .saturdayOTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "saturdayOTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.saturdayOTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  staticClass: "fd-readonly",
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    value: _vm.saturdayDDValue,
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("weekdays.sunday")
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .sundaySTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "sundaySTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.sundaySTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  class: {
                                                    "fd-readonly":
                                                      _vm.weekdayThresholdValues
                                                        .sundaySTValue == null
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly:
                                                      _vm.weekdayThresholdValues
                                                        .sundaySTValue == null
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.weekdayThresholdValues
                                                        .sundayOTValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.weekdayThresholdValues,
                                                        "sundayOTValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "weekdayThresholdValues.sundayOTValue"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("v-autocomplete", {
                                                  staticClass: "fd-readonly",
                                                  attrs: {
                                                    outlined: "",
                                                    items: _vm.dailyHourValues,
                                                    label: "",
                                                    value: _vm.sundayDDValue,
                                                    disabled:
                                                      _vm.processing || true,
                                                    readonly: true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "ml-0 mr-0" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "pl-3 pr-0 pt-0 pb-0" },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "configuration.labour.only-included-classifications-label"
                                          ),
                                          disabled:
                                            _vm.processing ||
                                            !(
                                              _vm.computedBasicLabourThresholds
                                                .enableDailyOvertimeThreshold ||
                                              _vm.computedBasicLabourThresholds
                                                .enableDailyDoubletimeThreshold
                                            )
                                        },
                                        model: {
                                          value:
                                            _vm.showOnlyIncludedClassifications,
                                          callback: function($$v) {
                                            _vm.showOnlyIncludedClassifications = $$v
                                          },
                                          expression:
                                            "showOnlyIncludedClassifications"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "ml-4",
                                                        attrs: {
                                                          color: "black",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("info")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "configuration.labour.classifications-selection-sub-menu-information"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mr-0 ml-0" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": "search",
                                      label: "Search",
                                      "single-line": "",
                                      "hide-details": "",
                                      clearable: "",
                                      disabled:
                                        _vm.processing ||
                                        !(
                                          _vm.computedBasicLabourThresholds
                                            .enableDailyOvertimeThreshold ||
                                          _vm.computedBasicLabourThresholds
                                            .enableDailyDoubletimeThreshold
                                        )
                                    },
                                    model: {
                                      value: _vm.classificationsTableSearch,
                                      callback: function($$v) {
                                        _vm.classificationsTableSearch = $$v
                                      },
                                      expression: "classificationsTableSearch"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            directives: [
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:name",
                                value: _vm.$t("common.name"),
                                expression: "$t('common.name')",
                                arg: "name"
                              },
                              {
                                name: "fd-column",
                                rawName:
                                  "v-fd-column:description.hide-when-extra-small",
                                value: _vm.$t("common.description"),
                                expression: "$t('common.description')",
                                arg: "description",
                                modifiers: { "hide-when-extra-small": true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:overtime.no-sort",
                                value: _vm.$t(
                                  "configuration.labour.overtime-full-label"
                                ),
                                expression:
                                  "$t('configuration.labour.overtime-full-label')",
                                arg: "overtime",
                                modifiers: { "no-sort": true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:doubletime.no-sort",
                                value: _vm.$t(
                                  "configuration.labour.doubletime-full-label"
                                ),
                                expression:
                                  "$t('configuration.labour.doubletime-full-label')",
                                arg: "doubletime",
                                modifiers: { "no-sort": true }
                              }
                            ],
                            ref: "classificationsDataTable",
                            attrs: {
                              items: _vm.classifications,
                              search: _vm.classificationsTableSearch,
                              loading: _vm.processing,
                              "loading-text": _vm.$t(
                                "common.table-loading-message"
                              ),
                              "items-per-page": 100,
                              "footer-props": {
                                "items-per-page-options": [5, 25, 50, 100, -1]
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "header.overtime",
                                fn: function({ header }) {
                                  return [
                                    _c("v-checkbox", {
                                      attrs: {
                                        value:
                                          _vm.allSearchedClassificationsOvertimeSelected,
                                        indeterminate:
                                          _vm.someSearchedClassificationsOvertimeSelected,
                                        label: header.text,
                                        disabled:
                                          _vm.processing ||
                                          !_vm.computedBasicLabourThresholds
                                            .enableDailyOvertimeThreshold
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.flipSearchedClassificationsOvertimeSelected()
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.overtime",
                                fn: function({ item }) {
                                  return [
                                    _c("v-simple-checkbox", {
                                      attrs: {
                                        value: item.overtimeSelected,
                                        disabled:
                                          _vm.processing ||
                                          !_vm.computedBasicLabourThresholds
                                            .enableDailyOvertimeThreshold
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.flipClassificationOvertimeSelected(
                                            item
                                          )
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "header.doubletime",
                                fn: function({ header }) {
                                  return [
                                    _c("v-checkbox", {
                                      attrs: {
                                        value:
                                          _vm.allSearchedClassificationsDoubletimeSelected,
                                        indeterminate:
                                          _vm.someSearchedClassificationsDoubletimeSelected,
                                        label: header.text,
                                        disabled:
                                          _vm.processing ||
                                          !_vm.computedBasicLabourThresholds
                                            .enableDailyDoubletimeThreshold
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.flipSearchedClassificationsDoubletimeSelected()
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.doubletime",
                                fn: function({ item }) {
                                  return [
                                    _c("v-simple-checkbox", {
                                      attrs: {
                                        value: item.doubletimeSelected,
                                        disabled:
                                          _vm.processing ||
                                          !_vm.computedBasicLabourThresholds
                                            .enableDailyDoubletimeThreshold
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.flipClassificationDoubletimeSelected(
                                            item
                                          )
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.inspectionsTab.key,
                      attrs: { value: `tab-${_vm.inspectionsTab.key}` }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "inspectionsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-3", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.inspections.can-be-created-historically-label"
                                      ),
                                      disabled:
                                        !_vm.$store.state.curEnvironment
                                          .enableScaffoldInspection ||
                                        _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .scaffoldInspectionCanBeCreatedHistorically,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "scaffoldInspectionCanBeCreatedHistorically",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.scaffoldInspectionCanBeCreatedHistorically"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.inspections.requires-photo-label"
                                      ),
                                      disabled:
                                        !_vm.$store.state.curEnvironment
                                          .enableScaffoldInspection ||
                                        _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .scaffoldInspectionRequiresPhoto,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "scaffoldInspectionRequiresPhoto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.scaffoldInspectionRequiresPhoto"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t(
                                        "configuration.inspections.requires-all-questions-label"
                                      ),
                                      disabled:
                                        !_vm.$store.state.curEnvironment
                                          .enableScaffoldInspection ||
                                        _vm.processing,
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.environment
                                          .scaffoldInspectionRequiresAllQuestions,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.environment,
                                          "scaffoldInspectionRequiresAllQuestions",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "environment.scaffoldInspectionRequiresAllQuestions"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configuration.inspections.inspection-times-subheader"
                                )
                              ) + "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-title",
                            [
                              _c("fd-inline-add-button", {
                                attrs: {
                                  disabled:
                                    !_vm.$store.state.curEnvironment
                                      .enableScaffoldInspection ||
                                    !_vm.canAddInspectionTime ||
                                    _vm.processing
                                },
                                on: { click: _vm.addInspectionTime }
                              }),
                              _vm._v(" "),
                              _c("v-divider", {
                                staticClass: "mx-4",
                                attrs: { inset: "", vertical: "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "ml-1 mr-3",
                                                attrs: {
                                                  color: "black",
                                                  dark: ""
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("info")]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "configuration.inspections.table-listing-tooltip"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              !_vm.canAddInspectionTime
                                ? _c("fp-item-disabled-message", {
                                    staticClass: "ml-2",
                                    staticStyle: { "align-content": "center" },
                                    attrs: {
                                      message: _vm.$t(
                                        "configuration.inspections.max-4-inspection-times-message"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            directives: [
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:style.no-sort",
                                value: _vm.$t(
                                  "configuration.inspections.style-column-header"
                                ),
                                expression:
                                  "$t('configuration.inspections.style-column-header')",
                                arg: "style",
                                modifiers: { "no-sort": true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:name.no-sort",
                                value: _vm.$t(
                                  "configuration.inspections.segment-name-column-header"
                                ),
                                expression:
                                  "\n                $t('configuration.inspections.segment-name-column-header')\n              ",
                                arg: "name",
                                modifiers: { "no-sort": true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:time.no-sort",
                                value: _vm.$t(
                                  "configuration.inspections.inspection-time-column-header"
                                ),
                                expression:
                                  "\n                $t('configuration.inspections.inspection-time-column-header')\n              ",
                                arg: "time",
                                modifiers: { "no-sort": true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:summary.no-sort",
                                value: _vm.$t(
                                  "configuration.inspections.summary-column-header"
                                ),
                                expression:
                                  "$t('configuration.inspections.summary-column-header')",
                                arg: "summary",
                                modifiers: { "no-sort": true }
                              },
                              {
                                name: "fd-column",
                                rawName:
                                  "v-fd-column:action.no-sort.class_fd-action-cell",
                                arg: "action",
                                modifiers: {
                                  "no-sort": true,
                                  "class_fd-action-cell": true
                                }
                              }
                            ],
                            staticClass: "fd-actions-table",
                            attrs: {
                              items: _vm.inspectionTimes,
                              loading: _vm.processing,
                              "loading-text": _vm.$t(
                                "common.table-loading-message"
                              ),
                              "sort-by": ["time"],
                              "mobile-breakpoint": "0",
                              "no-data-text": _vm.$t(
                                "configuration.inspections.no-inspection-times"
                              ),
                              "items-per-page": -1,
                              "footer-props": {
                                "items-per-page-options": [-1]
                              },
                              "hide-default-footer": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item.style",
                                fn: function({ item }) {
                                  return [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            outlined: "",
                                            items: _vm.selectableStyles(
                                              item.style
                                            ),
                                            dense: "",
                                            "hide-details": "",
                                            clearable: "",
                                            disabled:
                                              !_vm.$store.state.curEnvironment
                                                .enableScaffoldInspection ||
                                              _vm.processing
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function({ item: style }) {
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass:
                                                          "fd-inspection-time-chip fd-selected-time-segment",
                                                        class: style.value
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "fd-inspection-time-chip-icon",
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$store.getters.icon(
                                                                    `inspection-time-${style.value}`
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              !!item.name
                                                                ? item.name
                                                                : _vm.$t(
                                                                    `inspections.times.${style.value}-label`
                                                                  )
                                                            )
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function({
                                                  item: style,
                                                  on,
                                                  attrs
                                                }) {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "v-list-item",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  "no-gutters":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-chip",
                                                                  {
                                                                    staticClass:
                                                                      "fd-inspection-time-chip",
                                                                    class: {
                                                                      [style.value]: !style.disabled
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "fd-inspection-time-chip-icon"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$store.getters.icon(
                                                                              `inspection-time-${style.value}`
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            `inspections.times.${style.value}-label`
                                                                          )
                                                                        )
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                style.disabled
                                                                  ? _c(
                                                                      "fp-item-disabled-message",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        staticStyle: {
                                                                          "align-content":
                                                                            "center"
                                                                        },
                                                                        attrs: {
                                                                          message: _vm.$t(
                                                                            "configuration.inspections.time-style-already-selected-message"
                                                                          )
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: item.style,
                                            callback: function($$v) {
                                              _vm.$set(item, "style", $$v)
                                            },
                                            expression: "item.style"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.name",
                                fn: function({ item }) {
                                  return [
                                    _c("fd-text-field", {
                                      attrs: {
                                        outlined: false,
                                        disabled:
                                          !_vm.$store.state.curEnvironment
                                            .enableScaffoldInspection ||
                                          _vm.processing,
                                        "hide-details": ""
                                      },
                                      model: {
                                        value: item.name,
                                        callback: function($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name"
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.time",
                                fn: function({ item }) {
                                  return [
                                    _c("fd-time-picker", {
                                      attrs: {
                                        outlined: false,
                                        value: item.time,
                                        disabled:
                                          !_vm.$store.state.curEnvironment
                                            .enableScaffoldInspection ||
                                          _vm.processing,
                                        "hide-details": ""
                                      },
                                      on: {
                                        "update:value": function($event) {
                                          return _vm.$set(item, "time", $event)
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.summary",
                                fn: function({ item }) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.getTimeRangeSummaryForTime(item)
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.action",
                                fn: function({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-1-action-column-min-width"
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              disabled:
                                                                !_vm.$store
                                                                  .state
                                                                  .curEnvironment
                                                                  .enableScaffoldInspection ||
                                                                _vm.processing
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeInspectionTime(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          mdi-delete\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.remove"))
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.importsTab.key,
                      attrs: { value: `tab-${_vm.importsTab.key}` }
                    },
                    [
                      _vm.allowImports &&
                      _vm.currentUserCanConfigurePrivateSettings
                        ? _c(
                            "v-form",
                            { ref: "importsForm" },
                            [
                              _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-2" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c("v-file-input", {
                                                staticClass:
                                                  "pr-0 pl-0 fd-select-with-context-actions",
                                                attrs: {
                                                  color: "primary",
                                                  label: _vm.$t(
                                                    "common.file-upload"
                                                  ),
                                                  placeholder: _vm.$t(
                                                    "common.select-your-file"
                                                  ),
                                                  "prepend-icon":
                                                    "mdi-paperclip",
                                                  outlined: "",
                                                  disabled: _vm.processing,
                                                  "hide-details": ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function({
                                                        index,
                                                        text
                                                      }) {
                                                        return [
                                                          index < 2
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "keywords-selection-chip-colors",
                                                                  attrs: {
                                                                    dark: "",
                                                                    label: "",
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        text
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1494855435
                                                ),
                                                model: {
                                                  value: _vm.importFile,
                                                  callback: function($$v) {
                                                    _vm.importFile = $$v
                                                  },
                                                  expression: "importFile"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "fd-select-context-actions pt-3",
                                                  attrs: {
                                                    dark: false,
                                                    flat: ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticStyle: {
                                                        "margin-top":
                                                          "0 !important"
                                                      },
                                                      attrs: {
                                                        fab: "",
                                                        elevation: "0",
                                                        "x-small": "",
                                                        dark: "",
                                                        loading: _vm.uploading,
                                                        disabled:
                                                          !_vm.importFile ||
                                                          _vm.processing
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.uploadImportFile
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          fa-upload\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mr-0",
                                  attrs: { type: _vm.inlineMessage.type }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.saving,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanConfigureSettings
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }