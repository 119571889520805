import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  TableHeader
} from "@fd/lib/vue/utility/dataTable";
import {
  Classification,
  ContractorWithTags,
  CrewWithEmployees,
  EmployeeTimeSummary,
  EquipmentClassification,
  EquipmentForContractor,
  FleetWithEquipment,
  LaborTimesheetRow,
  PersonWithDetails,
  ProjectCostCode,
  ProjectLocation,
  projectLocationService,
  reportService,
  ScaffoldRequestTypes,
  TimesheetExplanationWithWorkOrderDetails,
  TimesheetStatus,
  TimesheetType,
  WorkOrderSearchResult,
  workOrderService,
  WorkOrderStatuses,
  WorkSubTypeTimeSummary,
  WorkSubTypeWithParentDetails,
  WorkType
} from "../../../services";
import {
  UpdatableTimesheetWithTimesheetRows,
  UpdateRowCorrections,
  FindRelatedRowForCorrection
} from "../../../utils/timesheet";
import {
  TimesheetRow,
  TimesheetRowType,
  ParseWorkSubTypeIDsFromRow,
  areTimesheetRowsEqual,
  TimesheetRowTimeValues,
  CalculateRowTotalTime,
  TimesheetRowResourceType,
  SortTimesheetRows
} from "../../../utils/timesheetrow";
import HashTable from "../../../utils/hashtable";
import userAccess from "../../../dataMixins/userAccess";
import {
  GetPersonName,
  HasName,
  PersonWithDetailsAndName,
  SortItemsWithName
} from "../../../utils/person";
import { mapActions, mapMutations } from "vuex";
import { VDataTable } from "@fd/lib/vue/types";
import { GroupableSelectListOption, SelectListOption } from "@fd/lib/vue/utility/select";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import { showAdditionalDetailsDialog } from "../../../../../common/client/views/components/AdditionalDetailsDialog.vue";
import { localizedDateTimeString } from "@fd/lib/client-util/datetime";
import { SortCrewEmployees } from "../dialogs/SP.CrewDetailsBottomDialog.vue";
import { PropType } from "vue";
import { formatWorkOrderNumber } from "../../../utils/workorder";
import { formatScaffoldTagNumber } from "../../../utils/scaffold";
import { SortFleetEquipment } from "../dialogs/SP.FleetDetailsBottomDialog.vue";
import { valueInArray } from "@fd/lib/client-util/array";
import { SortWorkSubTypes, SortWorkSubTypesByParentWorkTypes } from "../../../utils/worksubtype";
import printBlob from "../../../../../lib/client-util/printBlob";
import downloadBlob from "../../../../../lib/client-util/downloadBlob";
import { SortWorkTypes } from "../../../utils/worktype";

type FormattedWorkOrder = WorkOrderSearchResult & {
  details: string;
  workOrderNumber: string;
  description: string;
  requestTypeName: string;
  workOrderStatusName: string;
  startDateString: string;
  completedDateString: string;
};
type PossibleNumber = number | string | null | undefined;
type AnyClassification = Classification | EquipmentClassification;
type ClassificationWithDisplayName = AnyClassification & {
  displayName: string | undefined;
};
type WorkSubTypeWithDetails = WorkSubTypeWithParentDetails & {
  isIndirect: boolean | undefined;
  isDirect: boolean | undefined;
  isEquipment: boolean | undefined;
  isPerDiem: boolean | undefined;
};

export type TopLevelWorkSubType<T extends WorkSubTypeWithParentDetails> = T & {
  allChildSubTypes: Array<T>;
  workOrderRelatedChildSubTypes: Array<T>;
  nonWorkOrderRelatedChildSubTypes: Array<T>;
};
export type TopLevelWorkSubTypeWithDetails = TopLevelWorkSubType<WorkSubTypeWithDetails>;

export default FDVue.extend({
  name: "sp-foreman-timesheet-form",
  inheritAttrs: false,
  mixins: [serviceErrorHandling, rules, tabbedView, userAccess],
  components: {
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "sp-timesheet-time-display": () => import("../SP.TimesheetTimeDisplay.vue"),
    "sp-timesheet-perdiem": () => import("./ForemanTimesheetPerdiem.vue"),
    "sp-timesheet-work-sub-type-hours-entry": () =>
      import("../SP.TimesheetWorkSubTypesHoursEntry.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  props: {
    timesheet: {
      type: Object as PropType<UpdatableTimesheetWithTimesheetRows>,
      default: undefined
    },
    employeeTimeSummaries: {
      type: Array as PropType<Array<EmployeeTimeSummary>>,
      default: undefined
    },
    readOnly: { type: Boolean, default: false },
    isInDialog: { type: Boolean, default: false },
    parentContext: { type: String, default: undefined },
    makeCorrections: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  data: function() {
    return {
      panel: 0,
      firstTabKey: "1",
      workspaceTab: new PageTab({
        nameKey: "timesheets.existing.tabs.workspace",
        key: "1",
        visible: true
      }),
      summaryTab: new PageTab({
        nameKey: "timesheets.existing.tabs.summary",
        key: "2",
        visible: true
      }),

      contractor: undefined as ContractorWithTags | undefined,

      workOrderDirectPanelNumber: 0,
      generalDirectPanelNumber: 1,
      indirectPanelNumber: 2,

      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      allCostCodes: [] as ProjectCostCode[],

      allEquipment: [] as EquipmentForContractor[],
      allEquipmentClassifications: [] as EquipmentClassification[],
      allPeople: [] as PersonWithDetailsAndName[],
      allClassifications: [] as Classification[],

      selectedResourceID: null as string | null,
      selectedResourceGroupID: null as string | null,

      allWorkTypes: [] as WorkType[],
      allWorkSubTypes: [] as WorkSubTypeWithDetails[],
      allConfiguredWorkSubTypes: [] as WorkSubTypeWithDetails[],

      timesheetWorkOrders: [] as FormattedWorkOrder[],
      availableWorkOrders: [] as FormattedWorkOrder[],
      selectedWorkOrders: [] as FormattedWorkOrder[],

      workOrderSearch: "",
      workOrderSearching: false,
      didWorkOrderSearch: false,
      timer: null as NodeJS.Timeout | null
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return [
        "perDiemValueChanged",
        "getTimeSummaryStringForWorkSubTypeInRow",
        "workSubTypeNameForExplanation",
        "canEditPerDiem",
        "rowCanEditWorkSubType",
        "countPerDiems",
        "sumRowTimeValues",
        "canEditTimesheet",
        "saveDialog",
        "submitDialog",
        "getFieldRef",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "calculateTotalForRow",
        "calculateTotalForRows",
        "clientWorkOrderNumberForGroup",
        "serviceOrderNumberForGroup",
        "purchaseOrderNumberForGroup"
      ];
    },

    //#region UI
    tabDefinitions(): Tab[] {
      return [this.summaryTab];
    },
    timesheetIsInDialog(): boolean {
      return this.isInDialog;
    },
    workOrderTableHeader(): string {
      return (this.$t("timesheets.existing.work-order-table-header") as string).toUpperCase();
    },
    generalizedDirectLabel(): string {
      return (this.$t("timesheets.existing.generalized-direct-label") as string).toUpperCase();
    },
    indirectTableHeader(): string {
      return (this.$t("timesheets.existing.indirect-table-header") as string).toUpperCase();
    },
    //#endregion

    //#region General Form & Timesheet Props
    isProcessing(): boolean {
      // loading comes in from the form owner, processing is local
      return this.loading || this.processing;
    },
    timeSummaries(): EmployeeTimeSummary[] | undefined {
      return this.employeeTimeSummaries;
    },
    timesheetIsEquipment(): boolean {
      return (this.timesheet?.timesheetTypeID ?? 0) == TimesheetType.Equipment;
    },
    timesheetIsReadonly(): boolean {
      return this.readOnly;
    },
    selectedEntryType(): "indirect" | "generaldirect" | "workorder" {
      // Equipment timesheets don't use this form anymore.  As such, if it's not direct it has to be indirect
      if (this.timesheet.timesheetTypeID != TimesheetType.Direct) return "indirect";
      else if (this.selectedWorkOrders.length) return "workorder";
      else return "generaldirect";
    },
    selectedRowType(): TimesheetRowType {
      let rowType = TimesheetRowType.DirectWorkOrderRelated;
      if (this.selectedEntryType == "generaldirect") rowType = TimesheetRowType.DirectGeneral;
      else if (this.selectedEntryType == "indirect") rowType = TimesheetRowType.Indirect;
      return rowType;
    },
    timesheetIsDeclined(): boolean {
      return this.timesheet?.timesheetStatusID == TimesheetStatus.Declined;
    },
    timesheetDeclineComments(): string | undefined {
      return this.timesheet?.lastStatusLog?.comments;
    },
    hasAnyEquipmentRows(): boolean {
      return !!this.allTimesheetRows.filter(
        x =>
          x.rowType == TimesheetRowType.Equipment ||
          x.resourceType == TimesheetRowResourceType.Equipment
      )?.length;
    },
    hasWorkOrderDirectRows(): boolean {
      let rows = this.allTimesheetRows.filter(
        x => x.rowType == TimesheetRowType.DirectWorkOrderRelated && !!x.workOrderID
      );
      return !!rows?.length;
    },
    hasWorkOrderDirectEquipmentRows(): boolean {
      let rows = this.allTimesheetRows.filter(
        x =>
          x.resourceType == TimesheetRowResourceType.Equipment &&
          x.rowType == TimesheetRowType.DirectWorkOrderRelated &&
          !!x.workOrderID
      );
      return !!rows?.length;
    },
    hasGeneralDirectRows(): boolean {
      let rows = this.allTimesheetRows.filter(x => x.rowType == TimesheetRowType.DirectGeneral);
      return !!rows?.length;
    },
    hasGeneralDirectEquipmentRows(): boolean {
      let rows = this.allTimesheetRows.filter(
        x =>
          x.resourceType == TimesheetRowResourceType.Equipment &&
          x.rowType == TimesheetRowType.DirectGeneral
      );
      return !!rows?.length;
    },
    hasIndirectRows(): boolean {
      let rows = this.allTimesheetRows.filter(x => x.rowType == TimesheetRowType.Indirect);
      return !!rows?.length;
    },
    hasIndirectEquipmentRows(): boolean {
      let rows = this.allTimesheetRows.filter(
        x =>
          x.resourceType == TimesheetRowResourceType.Equipment &&
          x.rowType == TimesheetRowType.Indirect
      );
      return !!rows?.length;
    },
    workOrderNumbersWithDetailWorkSubTypes(): any[] {
      if (!this.timesheet?.id) return [];
      return [...new Set(this.timesheet.explanations?.map(x => x.workOrderNumber))];
    },
    //#endregion

    //#region Timesheet Rows by Type and Permissions
    allTimesheetRows(): TimesheetRow[] {
      if (this.timesheetIsEquipment) return [];
      return this.timesheet?.timesheetRows ?? [];
    },
    indirectTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(
        x => !x.workOrderID && x.rowType == TimesheetRowType.Indirect
      );
    },
    generalizedDirectTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(
        x => !x.workOrderID && x.rowType == TimesheetRowType.DirectGeneral
      );
    },
    workOrderTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(x => !!x.workOrderID);
    },

    //#endregion

    //#region New Row Data Selection
    /*** Layout ***/
    showResourceGroupSelection(): boolean {
      return !this.timesheetIsReadonly;
    },
    showWorkOrderSelection(): boolean {
      return this.timesheet?.timesheetTypeID == TimesheetType.Direct;
    },
    resourceGroupSelectionCols(): number {
      if (!this.showResourceGroupSelection) return 0;
      if (this.$vuetify.breakpoint.xs) return 12;
      if (this.$vuetify.breakpoint.sm) return 6;
      if (this.showWorkOrderSelection) {
        // Shows Crews/Fleets, Employees/Equipment & Work Orders
        return this.$vuetify.breakpoint.lgAndDown ? 6 : 3;
      } else {
        // This only happens on an indirect, editable timesheet
        // Shows only Crews/Fleets & Employees/Equipment
        // Since there are only 2 controls, and we have the space (md+), allow room for the add button on the same row
        if (this.$vuetify.breakpoint.md) return 5;
        return this.$vuetify.breakpoint.lgAndDown ? 4 : 3;
      }
    },
    resourceSelectionCols(): number {
      if (this.$vuetify.breakpoint.xs) return 12;
      if (this.$vuetify.breakpoint.sm) return 6;
      if (this.showResourceGroupSelection && this.showWorkOrderSelection) {
        // Shows Crews/Fleets, Employees/Equipment & Work Orders
        return this.$vuetify.breakpoint.lgAndDown ? 6 : 3;
      } else if (!this.showResourceGroupSelection || this.showWorkOrderSelection) {
        // This only happens on a correction, direct timesheet (add button needs more space)
        // Shows only Employees/Equipment & Work Orders
        // Since there are only 2 controls, and we have the space (md+), allow room for the add button on the same row
        return this.$vuetify.breakpoint.lgAndDown ? 4 : 3;
      } else if (this.showResourceGroupSelection || !this.showWorkOrderSelection) {
        // This only happens on an indirect, editable timesheet (Add button only says "ADD" and isn't very wide)
        // Shows only Crews/Fleets & Employees/Equipment
        // Since there are only 2 controls, and we have the space (md+), allow room for the add button on the same row
        if (this.$vuetify.breakpoint.md) return 5;
        return this.$vuetify.breakpoint.lgAndDown ? 4 : 3;
      } else {
        // This is the only control, plus the add button
        return this.$vuetify.breakpoint.lgAndDown ? 6 : 3;
      }
    },
    workOrderSelectionCols(): number {
      if (!this.showWorkOrderSelection) return 0;
      if (this.$vuetify.breakpoint.xs) return 12;
      if (this.$vuetify.breakpoint.sm) return 6;
      if (this.showResourceGroupSelection) {
        // Shows Crews/Fleets, Employees/Equipment & Work Orders
        return this.$vuetify.breakpoint.lgAndDown ? 6 : 3;
      } else {
        // Shows only Employees/Equipment & Work Orders
        // Since there are only 2 controls, and we have the space (md+), allow room for the add button on the same row
        return this.$vuetify.breakpoint.lgAndDown ? 4 : 3;
      }
    },
    addEntriesButtonCols(): number {
      if (this.$vuetify.breakpoint.xs) return 12;
      let usedCols =
        this.resourceGroupSelectionCols + this.resourceSelectionCols + this.workOrderSelectionCols;
      if (usedCols >= 12) usedCols = usedCols % 12;
      return 12 - usedCols;
    },

    /*** Crew/Fleet ***/
    canModifySelectedCrew(): boolean {
      if (!this.selectedResourceGroupID) return false;
      return this.selectedCrew?.ownerID == this.curUserID || this.currentUserCanConfigureSettings;
    },
    canModifySelectedFleet(): boolean {
      if (!this.selectedResourceGroupID) return false;
      return this.selectedFleet?.ownerID == this.curUserID || this.currentUserCanConfigureSettings;
    },
    groupedSelectableResourceGroups(): GroupableSelectListOption<{
      name: string | undefined;
      id: string | null | undefined;
    }>[] {
      let crews = this.selectableCrews;
      let fleets = this.selectableFleets;
      if (!crews?.length) return fleets;
      if (!fleets?.length) return crews;

      let allResults = [] as GroupableSelectListOption<{
        name: string | undefined;
        id: string | null | undefined;
      }>[];

      allResults.push({ header: this.$t("timesheets.entries.crews-label") });
      allResults.push({ divider: true });
      allResults = allResults.concat(crews);

      allResults.push({
        header: this.$t("timesheets.entries.fleets-label")
      } as any);
      allResults.push({ divider: true });
      allResults = allResults.concat(fleets);

      return allResults;
    },
    selectableCrews(): GroupableSelectListOption<CrewWithEmployees>[] {
      if (this.timesheetIsEquipment) return [];

      // Crews can be changed while on the screen, but from outside the form (in a dialog)
      // As such we need to use the store list every time in case it has changed
      let allCrews = this.$store.state.crews.fullList as CrewWithEmployees[];
      let selectableCrews = allCrews.map(
        x =>
          ({
            ...x
          } as SelectListOption<CrewWithEmployees>)
      );

      let selectableCrewsByOwnerName = selectableCrews.reduce((a, b) => {
        let ownerName = this.allPeople.find(p => p.id == b.ownerID)?.name ?? "";
        // If the crew has an owner but the owner name is not filled in
        // This means the current user doesn't have access to the owner's info (or the owner is archived)
        if (!!ownerName?.length || !b.ownerID?.length) {
          let existingCrews = a[ownerName] ?? [];
          existingCrews.push(b);
          a[ownerName] = existingCrews;
        }
        return a;
      }, {} as HashTable<SelectListOption<CrewWithEmployees>[]>);

      var curUserName = this.allPeople.find(p => p.id == this.curUserID)?.name ?? this.curUserID;
      let myCrews = SortItemsWithName(selectableCrewsByOwnerName[curUserName]);
      let unownedCrews = SortItemsWithName(selectableCrewsByOwnerName[""]);
      let otherCrewOwnerNames = Object.keys(selectableCrewsByOwnerName)
        .filter(x => x != "" && x != curUserName)
        .sort();

      let returnList = [] as GroupableSelectListOption<CrewWithEmployees>[];
      if (myCrews.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.my-crews") });
        myCrews.forEach(c => returnList.push(c));
        if (unownedCrews.length > 0 || otherCrewOwnerNames.length > 0)
          returnList.push({ divider: true });
      }

      if (unownedCrews.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.global-crews") });
        unownedCrews.forEach(c => returnList.push(c));
        if (otherCrewOwnerNames.length > 0) returnList.push({ divider: true });
      }

      if (otherCrewOwnerNames.length > 0) {
        for (let i = 0; i < otherCrewOwnerNames.length; i++) {
          let ownerName = otherCrewOwnerNames[i];
          let list = selectableCrewsByOwnerName[ownerName];
          if (list.length > 0) {
            returnList.push({ header: ownerName });
            list.forEach(c => returnList.push(c));
            if (i + 1 < otherCrewOwnerNames.length) returnList.push({ divider: true });
          }
        }
      }

      return returnList;
    },
    selectableFleets(): GroupableSelectListOption<FleetWithEquipment>[] {
      if (this.timesheetIsEquipment) return [];

      // Fleets can be changed while on the screen, but from outside the form (in a dialog)
      // As such we need to use the store list every time in case it has changed
      let allFleets = this.$store.state.fleets.fullList as FleetWithEquipment[];
      let selectableFleets = allFleets.map(
        x =>
          ({
            ...x
          } as SelectListOption<FleetWithEquipment>)
      );

      let allOwners = this.allPeople;
      let selectableFleetsByOwnerName = selectableFleets.reduce((a, b) => {
        let ownerName = allOwners.find(p => p.id == b.ownerID)?.name ?? "";
        // If the fleet has an owner but the owner name is not filled in
        // This means the current user doesn't have access to the owner's info (or the owner is archived)
        if (!!ownerName?.length || !b.ownerID?.length) {
          let existingFleets = a[ownerName] ?? [];
          existingFleets.push(b);
          a[ownerName] = existingFleets;
        }
        return a;
      }, {} as HashTable<SelectListOption<FleetWithEquipment>[]>);

      var curUserName = allOwners.find(p => p.id == this.curUserID)?.name ?? this.curUserID;
      let myFleets = SortItemsWithName(selectableFleetsByOwnerName[curUserName]);
      let unownedFleets = SortItemsWithName(selectableFleetsByOwnerName[""]);
      let otherFleetOwnerNames = Object.keys(selectableFleetsByOwnerName)
        .filter(x => x != "" && x != curUserName)
        .sort();

      let returnList = [] as GroupableSelectListOption<FleetWithEquipment>[];
      if (myFleets.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.my-fleets") });
        myFleets.forEach(c => returnList.push(c));
        if (unownedFleets.length > 0 || otherFleetOwnerNames.length > 0)
          returnList.push({ divider: true });
      }

      if (unownedFleets.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.global-fleets") });
        unownedFleets.forEach(c => returnList.push(c));
        if (otherFleetOwnerNames.length > 0) returnList.push({ divider: true });
      }

      if (otherFleetOwnerNames.length > 0) {
        for (let i = 0; i < otherFleetOwnerNames.length; i++) {
          let ownerName = otherFleetOwnerNames[i];
          let list = selectableFleetsByOwnerName[ownerName];
          if (list.length > 0) {
            returnList.push({ header: ownerName });
            list.forEach(c => returnList.push(c));
            if (i + 1 < otherFleetOwnerNames.length) returnList.push({ divider: true });
          }
        }
      }

      return returnList;
    },
    selectedCrew(): CrewWithEmployees | undefined {
      // Crews can be changed while on the screen, but from outside the form (in a dialog)
      // As such we need to use the store list every time in case it has changed
      let allCrews = this.$store.state.crews.fullList as CrewWithEmployees[];
      return allCrews.find(x => x.id == this.selectedResourceGroupID);
    },
    selectedFleet(): FleetWithEquipment | undefined {
      // Fleets can be changed while on the screen, but from outside the form (in a dialog)
      // As such we need to use the store list every time in case it has changed
      let allFleets = this.$store.state.fleets.fullList as FleetWithEquipment[];
      return allFleets.find(x => x.id == this.selectedResourceGroupID);
    },

    /*** Employee/Equipment ***/
    groupedSelectableResources(): GroupableSelectListOption<{
      nameWithCode: string;
      id: string | null | undefined;
    }>[] {
      let employees = this.selectableEmployees;
      let equipment = this.selectableEquipmentList;
      if (!employees?.length) return equipment;
      if (!equipment?.length) return employees;

      let allResults = [] as GroupableSelectListOption<{
        nameWithCode: string;
        id: string | null | undefined;
      }>[];

      allResults.push({ header: this.$t("timesheets.entries.employee-label") });
      allResults.push({ divider: true });
      allResults = allResults.concat(employees);

      allResults.push({ header: this.$t("timesheets.entries.equipment-label") });
      allResults.push({ divider: true });
      allResults = allResults.concat(equipment);

      return allResults;
    },
    selectableEquipmentList(): (EquipmentForContractor & {
      nameWithCode: string;
      disabled?: boolean;
    })[] {
      if (!this.timesheet?.contractorID) return [];

      let selectableEquipmentList = this.allEquipment.filter(
        x => x.contractorID == this.timesheet.contractorID
      );
      let sortedSelectableEquipmentList = SortItemsWithName(
        selectableEquipmentList.map(x => ({
          ...x,
          name: x.name,
          nameWithCode: `${x.name}${!!x.serialNumber ? " (" + x.serialNumber + ")" : ""}`,
          disabled: this.allTimesheetRowsAlreadyExists(
            this.timesheet.isLocked,
            x.id,
            undefined,
            TimesheetRowResourceType.Equipment,
            this.selectedRowType,
            this.selectedWorkOrders,
            !!this.selectedWorkOrders.length ? undefined : null, // If we have a work order selected, we don't care about the area.  If we don't, the check against empty area.
            !!this.selectedWorkOrders.length ? undefined : null // If we have a work order selected, we don't care about the subarea.  If we don't, the check against empty subarea.
          )
        }))
      );

      return sortedSelectableEquipmentList;
    },
    selectableEmployees(): (PersonWithDetailsAndName & {
      nameWithCode: string;
      disabled?: boolean;
    })[] {
      if (this.timesheetIsEquipment) return [];

      let selectableEmployees = this.allPeople.filter(
        x => !!x.contractorID && x.contractorID == this.timesheet?.contractorID
      );
      let sortedSelectableEmployees = SortItemsWithName(
        selectableEmployees.map(x => ({
          ...x,
          name: GetPersonName(x),
          nameWithCode: GetPersonName(x, true, true),
          disabled: this.allTimesheetRowsAlreadyExists(
            this.timesheet.isLocked,
            x.id,
            undefined,
            TimesheetRowResourceType.Employee,
            this.selectedRowType,
            this.selectedWorkOrders,
            !!this.selectedWorkOrders.length ? undefined : null, // If we have a work order selected, we don't care about the area.  If we don't, the check against empty area.
            !!this.selectedWorkOrders.length ? undefined : null // If we have a work order selected, we don't care about the subarea.  If we don't, the check against empty subarea.
          )
        }))
      );

      return sortedSelectableEmployees;
    },
    selectedEmployees(): PersonWithDetailsAndName[] {
      var selectedEmployeeIDs = [] as string[];
      if (!!this.selectedResourceID) selectedEmployeeIDs.push(this.selectedResourceID);

      let selectedCrew = this.selectedCrew;
      if (!!selectedCrew)
        selectedEmployeeIDs = selectedEmployeeIDs.concat(
          SortCrewEmployees(selectedCrew.employees).map(x => x.employeeID!)
        );

      let selectableEmployees = this.selectableEmployees;
      return selectedEmployeeIDs
        .filter(x => !!selectableEmployees.find(e => e.id! == x))
        .map(x => selectableEmployees.find(e => e.id! == x)!);
    },
    selectedEquipmentList(): EquipmentForContractor[] {
      var selectedEquipmentIDs = [] as string[];
      if (!!this.selectedResourceID) selectedEquipmentIDs.push(this.selectedResourceID);

      let selectedFleet = this.selectedFleet;
      if (!!selectedFleet) {
        selectedEquipmentIDs = selectedEquipmentIDs.concat(
          SortFleetEquipment(selectedFleet.equipment).map(x => x.equipmentID!)
        );
      }

      let selectableEquipmentList = this.selectableEquipmentList;
      return selectedEquipmentIDs
        .filter(x => !!selectableEquipmentList.find(e => e.id! == x))
        .map(x => selectableEquipmentList.find(e => e.id! == x)!);
    },

    /*** Work Order ***/
    isNonWorkOrderSelected: {
      get(): number | undefined {
        return this.selectedWorkOrders.length ? undefined : 0;
      },
      set(val: number | undefined) {
        if (val !== undefined) {
          this.selectedWorkOrders = [];
        }
      }
    },
    showAvailableWorkOrders(): boolean {
      return !this.workOrderSearching && !!this.availableWorkOrders.length;
    },
    showPreviouslySelectedWorkOrders(): boolean {
      return !this.workOrderSearching && !!this.previouslySelectedWorkOrders.length;
    },
    previouslySelectedWorkOrders(): FormattedWorkOrder[] {
      var selectedWorkOrderIDs = [
        ...new Set(
          this.timesheet.timesheetRows.filter(x => !!x.workOrderID?.length).map(x => x.workOrderID)
        )
      ];
      return this.timesheetWorkOrders.filter(x => valueInArray(x.id, selectedWorkOrderIDs));
    },
    selectedPreviouslySelectedWorkOrders: {
      get(): number[] {
        if (!this.previouslySelectedWorkOrders.length) return [];

        let previouslySelectedWorkOrderIDs = this.previouslySelectedWorkOrders.map(x => x.id);
        let selectedWorkOrderIDs = this.selectedWorkOrders.map(x => x.id);
        return selectedWorkOrderIDs
          .filter(x => valueInArray(x, previouslySelectedWorkOrderIDs))
          .map(x => previouslySelectedWorkOrderIDs.indexOf(x));
      },
      set(val: number[]) {
        let newlySelectedWorkOrderIDs = val.map(x => this.previouslySelectedWorkOrders[x].id);
        let selectedWorkOrderIDs = this.selectedWorkOrders.map(x => x.id);

        this.previouslySelectedWorkOrders.forEach(x => {
          let shouldWorkOrderBeSelected = valueInArray(x.id, newlySelectedWorkOrderIDs);
          let existingIndex = selectedWorkOrderIDs.indexOf(x.id);
          let isWorkOrderSelected = existingIndex >= 0;
          if (shouldWorkOrderBeSelected == isWorkOrderSelected) return;
          if (!shouldWorkOrderBeSelected) {
            this.selectedWorkOrders.splice(existingIndex, 1);
          } else if (!isWorkOrderSelected) {
            this.selectedWorkOrders.push(x);
          }
        });
      }
    },
    workOrderSelectorNoDataText(): string | undefined {
      if (this.workOrderSearching) return this.$t("loading-dot-dot-dot") as string;
      if (!this.didWorkOrderSearch)
        return this.$t("timesheets.existing.no-work-orders", [
          this.$format.date(this.timesheet?.day)
        ]) as string;

      return undefined;
    },
    //#endregion

    //#region Row Metadata Selection
    /*** Areas & Sub Areas ***/
    selectableAreas(): ProjectLocation[] {
      if (!!this.contractor?.includesAllAreas) {
        return SortItemsWithName(this.allAreas);
      }

      if (!this.contractor?.areaIDs?.length) return [];
      return this.allAreas.filter(x => this.contractor!.areaIDs!.includes(x.id!));
    },
    //#endregion

    //#region Work Type Lists
    rawUnsortedContractorWorkTypes(): WorkType[] {
      if (!this.contractor?.workTypeIDs?.length) return [];
      return this.allWorkTypes.filter(
        x => !x.isPerDiem && !x.isEquipment && this.contractor?.workTypeIDs?.includes(x.id!)
      );
    },
    contractorWorkTypes(): WorkType[] {
      if (!this.contractor?.workTypeIDs?.length) return [];
      return SortWorkTypes(
        this.allWorkTypes.filter(
          x => !x.isPerDiem && !x.isEquipment && this.contractor?.workTypeIDs?.includes(x.id!)
        )
      );
    },
    //#endregion

    //#region Work Sub Type Lists
    /**
     * Flat list of UNSORTED WST (without children), but limited only to valid WSTs FOR THE CURRENT CONTRACTOR
     */
    allConfiguredContractorWorkSubTypes(): WorkSubTypeWithDetails[] {
      let contractorWorkTypes = this.contractorWorkTypes;
      return contractorWorkTypes.reduce((a: Array<WorkSubTypeWithDetails>, wt: WorkType) => {
        let subTypes = this.allConfiguredWorkSubTypes.filter(x => x.workTypeID == wt.id);
        if (!!subTypes?.length) a = a.concat(subTypes);
        return a;
      }, [] as WorkSubTypeWithDetails[]);
    },
    /**
     * Used for generating visible columns in the timesheet table itself.
     * @returns A sorted list of top level sub types for the contractor, each sub type containing a list of child types where applicable.
     */
    topLevelContractorWorkSubTypesWithChildren(): TopLevelWorkSubTypeWithDetails[] {
      let contractorWorkSubTypes = this.allConfiguredContractorWorkSubTypes;
      let sortedWorkSubTypes = SortWorkSubTypesByParentWorkTypes(
        contractorWorkSubTypes,
        this.contractorWorkTypes
      );

      let topLevelContractorSubTypes = sortedWorkSubTypes
        .filter(cwst => !cwst.parentWorkSubTypeID)
        .map(tlwst => {
          let allChildSubTypes = SortWorkSubTypes(
            contractorWorkSubTypes.filter(cwst => cwst.parentWorkSubTypeID == tlwst.id)
          );
          let workOrderRelatedChildSubTypes = allChildSubTypes.filter(x => !!x.isWorkOrderRelated);
          let nonWorkOrderRelatedChildSubTypes = allChildSubTypes.filter(
            x => !x.isWorkOrderRelated
          );
          return {
            ...tlwst,
            allChildSubTypes: allChildSubTypes,
            workOrderRelatedChildSubTypes: workOrderRelatedChildSubTypes,
            nonWorkOrderRelatedChildSubTypes: nonWorkOrderRelatedChildSubTypes
          } as TopLevelWorkSubTypeWithDetails;
        })
        .filter(cwst => !cwst.isParent || !!cwst.allChildSubTypes.length);
      return topLevelContractorSubTypes;
    },
    summaryWorkSubTypes(): TopLevelWorkSubTypeWithDetails[] {
      // Equipment timesheets aren't supported on this form
      if (this.timesheetIsEquipment) return [];

      let summaryWorkSubTypes = [] as TopLevelWorkSubTypeWithDetails[];

      if (this.hasIndirectRows) {
        let currentSummarySubTypeIDs = summaryWorkSubTypes.map(wst => wst.id);
        let subTypesToAdd = this.indirectWorkSubTypes.filter(
          x => !valueInArray(x.id, currentSummarySubTypeIDs)
        );
        summaryWorkSubTypes = summaryWorkSubTypes.concat(subTypesToAdd);
      }
      if (this.hasGeneralDirectRows) {
        let currentSummarySubTypeIDs = summaryWorkSubTypes.map(wst => wst.id);
        let subTypesToAdd = this.generalizedDirectWorkSubTypes.filter(
          x => !valueInArray(x.id, currentSummarySubTypeIDs)
        );
        summaryWorkSubTypes = summaryWorkSubTypes.concat(subTypesToAdd);
      }
      if (this.hasWorkOrderDirectRows) {
        let currentSummarySubTypeIDs = summaryWorkSubTypes.map(wst => wst.id);
        let subTypesToAdd = this.workOrderWorkSubTypes.filter(
          x => !valueInArray(x.id, currentSummarySubTypeIDs)
        );
        summaryWorkSubTypes = summaryWorkSubTypes.concat(subTypesToAdd);
      }

      return SortWorkSubTypesByParentWorkTypes(summaryWorkSubTypes, this.allWorkTypes);
    },
    workOrderWorkSubTypes(): TopLevelWorkSubTypeWithDetails[] {
      return this.topLevelContractorWorkSubTypesWithChildren.filter(
        x => !!x.isDirect && (!!x.isWorkOrderRelated || !!x.workOrderRelatedChildSubTypes.length)
      );
    },
    indirectWorkSubTypes(): TopLevelWorkSubTypeWithDetails[] {
      return this.topLevelContractorWorkSubTypesWithChildren.filter(x => !!x.isIndirect);
    },
    generalizedDirectWorkSubTypes(): TopLevelWorkSubTypeWithDetails[] {
      return this.topLevelContractorWorkSubTypesWithChildren.filter(
        x =>
          !!x.isDirect &&
          ((!x.isWorkOrderRelated && !x.isParent) || !!x.nonWorkOrderRelatedChildSubTypes.length)
      );
    },
    //#endregion

    //#region Per Diem
    perDiemSubTypeIsWorkOrderRelated(): boolean {
      return this.perDiemSubType?.isWorkOrderRelated ?? false;
    },
    perDiemSubType(): WorkSubTypeWithParentDetails | undefined {
      if (!this.contractor?.employeesReceivePerDiem) return undefined;
      if (this.timesheetIsEquipment) return undefined;

      let perDiemTypeID = this.allWorkTypes.find(x => !!x.isPerDiem)?.id;
      // This wants a flat list of sub types (without children), but limited only to valid WSTs FOR THE CURRENT CONTRACTOR
      return this.allConfiguredContractorWorkSubTypes.find(x => x.workTypeID == perDiemTypeID);
    },
    //#endregion

    //#region Table Headers
    summaryTableHeaders(): TableHeader[] {
      if (this.timesheetIsEquipment) return [];

      let headers = [
        {
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as string,
          value: "employeeName",
          class: "fd-table-frozen-column",
          cellClass: "fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as string,
          value: "employeeCode",
          class: "fd-table-frozen-column fd-summary-employee-number-column",
          cellClass: "fd-table-frozen-column fd-summary-employee-number-column"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as string,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-summary-classification-column",
          cellClass: "fd-table-frozen-column fd-summary-classification-column"
        },
        {
          text: this.$t("timesheets.existing.work-order-column-label") as string,
          value: "workOrderNumber"
        },
        {
          text: this.$t("timesheets.existing.area-column-label") as string,
          value: "areaName"
        },
        {
          text: this.$t("timesheets.existing.sub-area-column-label") as string,
          value: "subAreaName"
        }
      ] as TableHeader[];

      for (let workSubType of this.summaryWorkSubTypes) {
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      if (this.hasAnyEquipmentRows) {
        headers.push({
          text: (this.$t("timesheets.existing.equipment-column-label") as string).toUpperCase(),
          value: "equipmentHours",
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "text-end"
      });

      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }

      headers.push({
        text: this.$t("common.actions"),
        value: "actions",
        class: "fd-actions-cell",
        cellClass: "fd-actions-cell"
      });

      return headers;
    },
    workOrderTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as string,
          value: "employeeName",
          class: "fd-table-frozen-column fd-workorder-employee-name-column",
          cellClass: "fd-table-frozen-column fd-workorder-employee-name-column"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as string,
          value: "employeeCode",
          class: "fd-table-between-frozen-columns",
          cellClass: "fd-table-between-frozen-columns"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as string,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-workorder-classification-column",
          cellClass: "fd-table-frozen-column fd-workorder-classification-column"
        },
        {
          text: this.$t("timesheets.existing.work-order-column-label") as string,
          value: "workOrderNumber"
        }
      ] as TableHeader[];

      for (let workSubType of this.workOrderWorkSubTypes) {
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      if (this.hasWorkOrderDirectEquipmentRows) {
        headers.push({
          text: (this.$t("timesheets.existing.equipment-column-label") as string).toUpperCase(),
          value: "equipmentHours",
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      if (this.perDiemSubTypeIsWorkOrderRelated) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }

      headers.push({
        text: this.$t("common.actions"),
        value: "actions",
        class: "fd-actions-cell",
        cellClass: "fd-actions-cell"
      });

      return headers;
    },
    generalizedDirectTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "icon",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as string,
          value: "employeeName",
          class: "fd-table-frozen-column fd-nonworkorder-employee-name-column",
          cellClass: "fd-table-frozen-column fd-nonworkorder-employee-name-column"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as string,
          value: "employeeCode",
          class: "fd-table-between-frozen-columns",
          cellClass: "fd-table-between-frozen-columns"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as string,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-nonworkorder-classification-column",
          cellClass: "fd-table-frozen-column fd-nonworkorder-classification-column"
        },
        {
          text: this.$t("timesheets.existing.area-column-label") as string,
          value: "areaName"
        },
        {
          text: this.$t("timesheets.existing.sub-area-column-label") as string,
          value: "subAreaName"
        }
      ] as TableHeader[];

      for (let workSubType of this.generalizedDirectWorkSubTypes) {
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      if (this.hasGeneralDirectEquipmentRows) {
        headers.push({
          text: (this.$t("timesheets.existing.equipment-column-label") as string).toUpperCase(),
          value: "equipmentHours",
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }

      headers.push({
        text: this.$t("common.actions"),
        value: "actions",
        class: "fd-actions-cell",
        cellClass: "fd-actions-cell"
      });

      return headers;
    },
    indirectTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "icon",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as string,
          value: "employeeName",
          class: "fd-table-frozen-column fd-indirect-employee-name-column",
          cellClass: "fd-table-frozen-column fd-indirect-employee-name-column"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as string,
          value: "employeeCode",
          class: "fd-table-between-frozen-columns",
          cellClass: "fd-table-between-frozen-columns"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as string,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-indirect-classification-column",
          cellClass: "fd-table-frozen-column fd-indirect-classification-column"
        },
        {
          text: this.$t("timesheets.existing.area-column-label") as string,
          value: "areaName"
        },
        {
          text: this.$t("timesheets.existing.sub-area-column-label") as string,
          value: "subAreaName"
        }
      ] as TableHeader[];

      for (let workSubType of this.indirectWorkSubTypes) {
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      if (this.hasIndirectEquipmentRows) {
        headers.push({
          text: (this.$t("timesheets.existing.equipment-column-label") as string).toUpperCase(),
          value: "equipmentHours",
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }

      headers.push({
        text: this.$t("common.actions"),
        value: "actions",
        class: "fd-actions-cell",
        cellClass: "fd-actions-cell"
      });

      return headers;
    }
    //#endregion
  },

  watch: {
    timesheet() {
      // When a new direct WO-related row is added to the timesheet, we automatically add a Non-WO-Related row
      // However, WO-Related rows can be added via the labour entry dialog, which doesn't allow Non-WO time
      // Therefore, when the timesheet is set, check for all direct WO-Related rows, and confirm there's a Non-WO-Related row for it
      // We only care about doing this if it's a `Direct` timesheet (else there are no WO-Related rows), and if the timesheet is editable (New or Declined).
      if (
        !!this.timesheet &&
        this.timesheet.timesheetTypeID == TimesheetType.Direct &&
        (this.timesheet.timesheetStatusID == TimesheetStatus.New ||
          this.timesheet.timesheetStatusID == TimesheetStatus.Declined)
      ) {
        for (let row of this.timesheet.timesheetRows.filter(
          x =>
            x.resourceType == TimesheetRowResourceType.Employee &&
            x.rowType == TimesheetRowType.DirectWorkOrderRelated
        )) {
          let relatedIndirectRow = this.timesheet.timesheetRows.find(
            x =>
              x.resourceType == TimesheetRowResourceType.Employee &&
              x.rowType == TimesheetRowType.DirectGeneral &&
              x.employeeID == row.employeeID &&
              !x.workOrderID
          );
          if (!relatedIndirectRow) {
            this.addNonWorkOrderRelatedTimesheetRow(
              false,
              row.employeeID,
              row.employeeName,
              row.employeeCode,
              row.employeeBadge,
              row.classificationID,
              TimesheetRowResourceType.Employee,
              TimesheetRowType.DirectGeneral,
              row.equipmentCostCodeID,
              null,
              null,
              false
            );
          }
        }
        for (let row of this.timesheet.timesheetRows.filter(
          x =>
            x.resourceType == TimesheetRowResourceType.Equipment &&
            x.rowType == TimesheetRowType.DirectWorkOrderRelated
        )) {
          let relatedIndirectRow = this.timesheet.timesheetRows.find(
            x =>
              x.resourceType == TimesheetRowResourceType.Equipment &&
              x.rowType == TimesheetRowType.DirectGeneral &&
              x.employeeID == row.employeeID &&
              !x.workOrderID
          );
          if (!relatedIndirectRow) {
            this.addNonWorkOrderRelatedTimesheetRow(
              false,
              row.employeeID,
              row.employeeName,
              row.employeeCode,
              row.employeeBadge,
              row.classificationID,
              TimesheetRowResourceType.Equipment,
              TimesheetRowType.DirectGeneral,
              row.equipmentCostCodeID,
              null,
              null,
              false
            );
          }
        }
      }
      this.loadDataForTimesheet();
    },
    workOrderSearch(newValue, oldValue) {
      if (newValue == oldValue || (!newValue?.length && !oldValue?.length)) return;
      // If the user kept typing/changed the search before the service call, cancel the previous call in preparation of the new one
      if (this.timer) clearTimeout(this.timer);

      let restrictDay = false;
      let restrictAssignment = false;
      if (!newValue?.length) {
        // If we're searching with NO search value, re-search using the initial "Recommended" search value
        restrictDay = true;
        restrictAssignment = true;
      }

      var obj = this;
      this.workOrderSearching = true;
      // Delay the service call to allow the user to keep typing if they choose before making a server call
      this.timer = setTimeout(async function() {
        obj.processing = true;
        obj.$nextTick(async () => {
          try {
            await obj.loadWorkOrders(newValue, restrictDay, restrictAssignment);
            // We ensure that any timesheet work orders are always included in the list of available work orders in case they are already selected via recent
            // Assuming the user has any text in the search field, these "extra" work orders will be filtered out by text anyway
            obj.timesheetWorkOrders.forEach(x => {
              if (obj.availableWorkOrders.findIndex(aWo => x.id == aWo.id) == -1) {
                obj.availableWorkOrders.push(x);
              }
            });
          } finally {
            obj.timer = null;
            obj.didWorkOrderSearch = true;
            obj.processing = false;
            obj.workOrderSearching = false;
          }
        });
      }, 1500);
    }
  },

  methods: {
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadCrewsForContractor: "LOAD_CREWS_FOR_CONTRACTOR",
      loadFleetsForContractor: "LOAD_FLEETS_FOR_CONTRACTOR",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadEmployees: "LOAD_USERS",
      loadEquipmentForContractor: "LOAD_EQUIPMENT_FOR_CONTRACTOR",
      loadClassifications: "LOAD_CLASSIFICATIONS",
      loadEquipmentClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS"
    }),
    //#region New Row Data Selection
    /*** Crew/Fleet ***/
    crewIsSelectable(crew: CrewWithEmployees | undefined): boolean {
      if (!crew?.employees?.length) return false;

      var crewEmployeesWithoutRows = crew.employees.filter(
        ce =>
          !this.allTimesheetRowsAlreadyExists(
            this.timesheet.isLocked,
            ce.employeeID,
            undefined,
            TimesheetRowResourceType.Employee,
            this.selectedRowType,
            this.selectedWorkOrders,
            !!this.selectedWorkOrders.length ? undefined : null, // If we have a work order selected, we don't care about the area.  If we don't, the check against empty area.
            !!this.selectedWorkOrders.length ? undefined : null // If we have a work order selected, we don't care about the subarea.  If we don't, the check against empty subarea.
          )
      );
      return !!crewEmployeesWithoutRows?.length;
    },
    fleetIsSelectable(fleet: FleetWithEquipment | undefined): boolean {
      if (!fleet?.equipment?.length) return false;

      var fleetEmployeesWithoutRows = fleet.equipment.filter(
        ce =>
          !this.allTimesheetRowsAlreadyExists(
            this.timesheet.isLocked,
            ce.equipmentID,
            undefined,
            TimesheetRowResourceType.Equipment,
            this.selectedRowType,
            this.selectedWorkOrders,
            !!this.selectedWorkOrders.length ? undefined : null, // If we have a work order selected, we don't care about the area.  If we don't, the check against empty area.
            !!this.selectedWorkOrders.length ? undefined : null // If we have a work order selected, we don't care about the subarea.  If we don't, the check against empty subarea.
          )
      );
      return !!fleetEmployeesWithoutRows?.length;
    },

    /*** Work Order ***/
    isWorkOrderSelected(item: FormattedWorkOrder): boolean {
      var selectedWorkOrderIDs = [...new Set(this.selectedWorkOrders.map(x => x.id!))];
      return valueInArray(item.id, selectedWorkOrderIDs);
    },
    toggleWorkOrderSelected(item: FormattedWorkOrder) {
      var selectedWorkOrderIDs = this.selectedWorkOrders.map(x => x.id);
      let existingIndex = selectedWorkOrderIDs.indexOf(item.id);
      if (existingIndex >= 0) {
        this.selectedWorkOrders.splice(existingIndex, 1);
      } else {
        this.selectedWorkOrders.push(item);
      }
    },
    //#endregion

    openNotesDialog(item: TimesheetRow) {
      if (!item) return;
      this.$emit("openNotesDialog", item);
    },
    addTimesheetRowRules(): any {
      return {
        selectedEntryType: [this.rules.required],
        selectedWorkOrders: undefined,
        selectedCrewID: !this.selectedResourceID ? [this.rules.required] : undefined,
        selectedEmployeeID: !this.selectedResourceGroupID ? [this.rules.required] : undefined
      };
    },
    validTimeForDayRule(row: TimesheetRow): boolean | string {
      let totalHours = this.totalTimeForEmployeeOnDay(row.employeeID);

      let errorMessage = "";
      if (totalHours < 0) {
        errorMessage = `${this.$t("timesheets.existing.too-few-hours-error-message")}`;
      } else if (totalHours > this.$store.state.curEnvironment.defaultMaxDailyEmployeeHours) {
        errorMessage = `${this.$t("timesheets.existing.too-many-hours-error-message")}`;
      } else {
        let negativeWstID = ParseWorkSubTypeIDsFromRow(row).find(x => {
          let totalHoursForSubType = this.totalTimeForEmployeeForWorkSubTypeOnDay(
            row.employeeID,
            x
          );
          return totalHoursForSubType < 0;
        });
        if (!!negativeWstID) {
          let workSubType = this.allWorkSubTypes.find(x => x.id == negativeWstID);
          errorMessage = `${this.$t(
            "timesheets.existing.too-few-work-sub-type-hours-error-message",
            [workSubType?.name]
          )}`;
        }
      }

      if (!!errorMessage) {
        if (!row.errorMessage.includes(errorMessage)) {
          row.errorMessage = errorMessage;
        }
        return errorMessage;
      } else {
        row.errorMessage = "";
        return true;
      }
    },
    timesheetRowRules(item: TimesheetRow): Array<Function | boolean | string> {
      return [this.validTimeForDayRule(item)];
    },
    // This method determines if the row in the data-table should be colored to represent if it is "Urgent".
    timesheetRowClassName(item: any) {
      return item.isCorrectionRow ? "fd-correction-table-row-background" : "";
    },
    updateAllIndirectItemValues(workSubTypeID: string | null | undefined, value: PossibleNumber) {
      if (!workSubTypeID?.length) return;

      let rows = this.indirectTimesheetRows;
      let times = new TimesheetRowTimeValues(null, null, null, value);
      for (let row of rows) {
        row.times[workSubTypeID] = times;
        this.confirmEmployeePerDiemForRow(row);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
      this.checkWorkSubTypeAdditionalDetails(times, workSubTypeID, null, null);
    },
    updateAllNonWorkOrderItemValues(
      workSubTypeID: string | null | undefined,
      value: PossibleNumber
    ) {
      if (!workSubTypeID?.length) return;

      let rows = this.generalizedDirectTimesheetRows;
      let times = new TimesheetRowTimeValues(null, null, null, value);
      for (let row of rows) {
        row.times[workSubTypeID] = times;
        this.confirmEmployeePerDiemForRow(row);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
      this.checkWorkSubTypeAdditionalDetails(times, workSubTypeID, null, null);
    },
    updateAllWorkOrderItemValues(
      workOrderNumber: string | null | undefined,
      workSubTypeID: string | null | undefined,
      value: PossibleNumber
    ) {
      if (!workSubTypeID?.length) return;

      let rows = this.workOrderTimesheetRows.filter(
        x =>
          (x.workOrderNumber ?? "") == workOrderNumber &&
          x.resourceType != TimesheetRowResourceType.Equipment
      );
      var workOrderID = "" as string | null | undefined;
      let times = new TimesheetRowTimeValues(null, null, null, value);
      for (let row of rows) {
        row.times[workSubTypeID] = times;
        if (!workOrderID) workOrderID = row.workOrderID;
        this.confirmEmployeePerDiemForRow(row);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
      this.checkWorkSubTypeAdditionalDetails(times, workSubTypeID, workOrderID, workOrderNumber);
    },
    checkWorkSubTypeAdditionalDetails(
      newValue: TimesheetRowTimeValues,
      workSubTypeID: string | null | undefined,
      workOrderID: string | null | undefined,
      workOrderNumber: string | null | undefined
    ) {
      if (!workSubTypeID?.length) return;

      let wst = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!wst?.requiresAdditionalDetails) return;

      let existingExplanation = this.timesheet?.explanations?.find(
        x => x.workSubTypeID == workSubTypeID && x.workOrderID == (workOrderID ?? "")
      );

      if (!!newValue && newValue.totalTime > 0) {
        if (!existingExplanation) {
          this.showNewExplanationDialog(
            workOrderID ?? "",
            workOrderNumber ?? "",
            workSubTypeID,
            wst.name ?? ""
          );
        }
      } else {
        // Value was removed for this WST/WO combination
        // Check if there are any other entries with this combination
        if (!!existingExplanation) {
          let otherExistingRowsWithValue = this.allTimesheetRows.filter(
            x =>
              x.workOrderID == workOrderID &&
              !!x.times[workSubTypeID] &&
              x.times[workSubTypeID].totalTime > 0
          );
          if (!otherExistingRowsWithValue?.length) {
            let existingIndex = this.timesheet!.explanations.indexOf(existingExplanation);
            this.timesheet!.explanations.splice(existingIndex, 1);
          }
        }
      }
    },
    totalTimeForEmployeeForWorkSubType(
      employeeID: string | null | undefined,
      workSubTypeID: string | null | undefined
    ): number {
      if (!employeeID?.length || !workSubTypeID?.length) return 0;

      var total = 0;
      let employeeRows = this.allTimesheetRows.filter(x => x.employeeID == employeeID);
      for (let row of employeeRows) {
        let hours = this.$parse.sanitizedNumber(row.times[workSubTypeID]?.totalTime);
        total += hours;
      }
      return total;
    },
    totalTimeForEmployeeForWorkSubTypeOnDay(
      employeeID: string | null | undefined,
      workSubTypeID: string | null | undefined
    ): number {
      if (!employeeID?.length || !workSubTypeID?.length) return 0;

      let fullSummary = this.timeSummaries?.find(x => x.employeeID == employeeID);
      let summary = fullSummary?.workSubTypeTimeSummaries.find(
        x => x.workSubTypeID == workSubTypeID
      );
      let otherTotalRegularTime = summary?.totalRegularTime ?? 0;
      let totalRegularTime =
        otherTotalRegularTime + this.totalTimeForEmployeeForWorkSubType(employeeID, workSubTypeID);
      return totalRegularTime;
    },
    totalTimeForEmployee(employeeID: string | null | undefined): number {
      if (!employeeID?.length) return 0;

      var total = 0;
      let employeeRows = this.allTimesheetRows.filter(x => x.employeeID == employeeID);
      for (let row of employeeRows) {
        // Clear out all hours values for row to be removed to confirm if explanations also need to be removed
        ParseWorkSubTypeIDsFromRow(row).forEach(wstid => {
          let hours = this.$parse.sanitizedNumber(row.times[wstid]?.totalTime);
          total += hours;
        });
      }
      return total;
    },
    totalTimeForEmployeeOnDay(employeeID: string | null | undefined): number {
      if (!employeeID?.length) return 0;

      let summary = this.timeSummaries?.find(x => x.employeeID == employeeID);
      let otherTotalTimes = new TimesheetRowTimeValues(
        summary?.totalRegularTime,
        summary?.totalOverTime,
        summary?.totalDoubleTime
      );
      let totalTime = otherTotalTimes.totalTime + this.totalTimeForEmployee(employeeID);
      return totalTime;
    },
    confirmEmployeePerDiemForRow(row: TimesheetRow) {
      // If the timesheet is locked and this is a correction row, don't do any automatic logic
      if (row.isCorrectionRow) return;
      if (row.resourceType == TimesheetRowResourceType.Equipment) return;

      // Confirm the hours for this work sub type don't go below 0 or above 18
      let totalRegularTime = this.totalTimeForEmployeeOnDay(row.employeeID);
      let perdiemOtherTimesheetOwner = this.perDiemOwnerFromOtherTimesheet(row.employeeID);
      if (!!perdiemOtherTimesheetOwner || !this.contractor?.employeesReceivePerDiem) return;

      let person = this.allPeople.find(x => x.id == row.employeeID);
      if (!person || !!person.disableAutomaticPerDiem) return;

      let existingPerDiemRow = this.existingUncorrectedPerDiemRowForEmployee(row.employeeID);

      let requiredHours = this.contractor.perDiemHoursRequirement ?? 0;
      if (totalRegularTime < requiredHours) {
        if (!!existingPerDiemRow) existingPerDiemRow.hasPerDiem = false;
        return;
      }

      if (!!existingPerDiemRow) return;

      // We don't have an already existing per diem row, so we either set the current row's per-diem to true, or make a new general row
      // If the perdiem is Work Order Related, we set the current row to true as it's not relevant whether this row is direct or general
      // If ther perdiem is NOT work order related, we look for an existing general row and either set it to true if it exists, or create one with perdiem true if not
      let desiredRowType =
        this.timesheet?.timesheetTypeID == TimesheetType.Indirect
          ? TimesheetRowType.Indirect
          : TimesheetRowType.DirectGeneral;
      if (this.perDiemSubTypeIsWorkOrderRelated || row.rowType == desiredRowType) {
        row.hasPerDiem = true;
      } else {
        let existingIndirectRow = this.getExistingTimesheetRow(
          row.isCorrectionRow,
          row.employeeID,
          undefined,
          TimesheetRowResourceType.Employee,
          desiredRowType,
          null,
          undefined,
          undefined
        );
        if (!!existingIndirectRow) {
          existingIndirectRow.hasPerDiem = true;
        } else {
          let newRow = this.addNonWorkOrderRelatedTimesheetRow(
            row.isCorrectionRow,
            row.employeeID,
            row.employeeName,
            row.employeeCode,
            row.employeeBadge,
            row.classificationID,
            TimesheetRowResourceType.Employee,
            desiredRowType,
            null,
            null,
            null
          );
          newRow.hasPerDiem = true;
        }
      }
    },
    workSubTypeHoursValueChanged(
      row: TimesheetRow,
      workSubTypeID: string | null | undefined,
      value: PossibleNumber
    ) {
      if (!workSubTypeID?.length) return;

      if (
        row.rowType == TimesheetRowType.Equipment ||
        row.resourceType == TimesheetRowResourceType.Equipment
      ) {
        console.error("Tried changing Equipment value by WST ID!");
        return;
      }

      let times = new TimesheetRowTimeValues(null, null, null, value);

      if (!!times.regularTime) times.regularTime = Number(times.regularTime?.toFixed(2));
      if (!!times.overTime) times.overTime = Number(times.overTime?.toFixed(2));
      if (!!times.doubleTime) times.doubleTime = Number(times.doubleTime?.toFixed(2));

      row.times[workSubTypeID] = times;

      // If the timesheet is locked and this is a correction row, don't do any automatic logic
      if (row.isCorrectionRow) return;

      this.confirmEmployeePerDiemForRow(row);
      UpdateRowCorrections(row, this.allTimesheetRows);

      this.checkWorkSubTypeAdditionalDetails(
        times,
        workSubTypeID,
        row.workOrderID,
        row.workOrderNumber
      );
    },
    perDiemValueChanged(row: TimesheetRow) {
      if (row.resourceType == TimesheetRowResourceType.Equipment) return;
      UpdateRowCorrections(row, this.allTimesheetRows);
    },
    async showNewExplanationDialog(
      workOrderID: string,
      workOrderNumber: string,
      workSubTypeID: string | null | undefined,
      workSubTypeName: string
    ) {
      if (!this.timesheet?.id) return;

      let newExplanation = {
        timesheetID: this.timesheet.id,
        workOrderID: workOrderID,
        workOrderNumber: workOrderNumber,
        workSubTypeID: workSubTypeID
      } as TimesheetExplanationWithWorkOrderDetails;

      let title = this.$t("timesheets.existing.additional-details-label", [
        `WO#${workOrderNumber}`
      ]);
      let label = workSubTypeName;
      let explanationText = await showAdditionalDetailsDialog(title, label);

      newExplanation.explanation = explanationText;
      this.timesheet.explanations = this.timesheet.explanations?.concat([newExplanation]);
    },
    addIndirectRowRelatedToExistingRow(row: TimesheetRow) {
      if (row.rowType == TimesheetRowType.DirectWorkOrderRelated) return;
      if (row.rowType == TimesheetRowType.Equipment) return;
      if (!this.timesheet) return;

      // If this person doesn't have a general indirect row, add that too
      let existingIndirectRow = this.timesheetRowAlreadyExists(
        row.isCorrectionRow,
        row.employeeID,
        row.classificationID ?? undefined,
        row.resourceType,
        row.rowType,
        null,
        null,
        null
      );
      if (!existingIndirectRow) {
        this.addNonWorkOrderRelatedTimesheetRow(
          row.isCorrectionRow,
          row.employeeID,
          row.employeeName,
          row.employeeCode,
          row.employeeBadge,
          row.classificationID,
          row.resourceType,
          row.rowType,
          null,
          null,
          null
        );
        this.timesheet!.timesheetRows = SortTimesheetRows(this.timesheet!.timesheetRows);
      }
    },
    getExistingTimesheetRow(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      classificationID: string | null | undefined,
      resourceType: TimesheetRowResourceType,
      rowType: TimesheetRowType,
      workOrderID: string | null | undefined,
      areaID: string | null | undefined,
      subAreaID: string | null | undefined
    ): TimesheetRow | undefined {
      let comparisonRow = {
        isCorrectionRow: isCorrectionRow,
        employeeID: employeeID,
        classificationID: classificationID,
        resourceType: resourceType,
        rowType: rowType,
        workOrderID: workOrderID,
        areaID: areaID,
        subAreaID: subAreaID
      } as TimesheetRow;
      return this.allTimesheetRows?.find(t =>
        areTimesheetRowsEqual(t, comparisonRow, {
          ignoreClassification: classificationID === undefined,
          ignoreArea: areaID === undefined,
          ignoreSubArea: subAreaID === undefined
        })
      );
    },
    allTimesheetRowsAlreadyExists(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      classificationID: string | null | undefined,
      resourceType: TimesheetRowResourceType,
      rowType: TimesheetRowType,
      workOrders: FormattedWorkOrder[],
      areaID: string | null | undefined,
      subAreaID: string | null | undefined
    ): boolean {
      if (!workOrders.length) {
        return !!this.getExistingTimesheetRow(
          isCorrectionRow,
          employeeID,
          classificationID,
          resourceType,
          rowType,
          undefined,
          areaID,
          subAreaID
        );
      }

      let existingRows = [];
      for (let wo of workOrders) {
        let existingRow = this.getExistingTimesheetRow(
          isCorrectionRow,
          employeeID,
          classificationID,
          resourceType,
          rowType,
          wo.id!,
          undefined,
          undefined
        );
        if (!!existingRow) existingRows.push(existingRow);
      }
      return existingRows.length == workOrders.length;
    },
    timesheetRowAlreadyExists(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      classificationID: string | null | undefined,
      resourceType: TimesheetRowResourceType,
      rowType: TimesheetRowType,
      workOrderID: string | null | undefined,
      areaID: string | null | undefined,
      subAreaID: string | null | undefined
    ): boolean {
      return !!this.getExistingTimesheetRow(
        isCorrectionRow,
        employeeID,
        classificationID,
        resourceType,
        rowType,
        workOrderID,
        areaID,
        subAreaID
      );
    },
    workOrderNumberTextForRow(row: TimesheetRow): string | undefined {
      if (!!row.workOrderNumber)
        return this.$t("common.work-order-number", [row.workOrderNumber]) as string;
      return undefined;
    },
    workOrderPlaceholderTextForRow(row: TimesheetRow): string {
      if (row.rowType == TimesheetRowType.DirectGeneral)
        return this.$t("timesheets.existing.generalized-direct-work-order-placeholder") as string;
      else if (row.rowType == TimesheetRowType.Equipment) return "";
      return this.$t("timesheets.existing.indirect-work-order-placeholder") as string;
    },
    selectableSubAreasForRow(row: TimesheetRow | undefined | null): ProjectLocation[] {
      if (!row?.areaID) return [];
      return this.allSubAreas
        .filter(x => x.parentLocationID == row.areaID)
        .map(x => ({
          ...x,
          disabled:
            row.subAreaID != x.id &&
            this.timesheetRowAlreadyExists(
              row.isCorrectionRow,
              row.employeeID,
              undefined,
              row.resourceType,
              row.rowType,
              row.workOrderID,
              row.areaID,
              x.id
            )
        }));
    },
    currentTimesheetExplanationsForWorkOrderNumber(
      woNumber: string
    ): TimesheetExplanationWithWorkOrderDetails[] {
      return this.timesheet?.explanations?.filter(x => (x.workOrderNumber ?? "") == woNumber) ?? [];
    },
    workSubTypeNameForExplanation(
      explanation: TimesheetExplanationWithWorkOrderDetails
    ): string | null | undefined {
      let workSubType = this.allWorkSubTypes.find(x => x.id == explanation.workSubTypeID);
      return workSubType?.name;
    },
    allGroupsExpanded(tableIdentifier: string): boolean {
      let datatableRef = `${tableIdentifier}datatable`;
      let datatable = this.$refs[datatableRef] as VDataTable;
      if (!datatable) {
        return false;
      }
      let toggleRefs = Object.keys(this.$refs).filter(x =>
        x.startsWith(`${tableIdentifier}grouptoggle`)
      );
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if (!isOpen) {
          anyGroupsClosed = true;
          break;
        }
      }
      return !anyGroupsClosed;
    },
    validate(): boolean {
      let additionalDetailsValid =
        (this.$refs.additionaldetailsform as HTMLFormElement)?.validate() ?? true;
      let timesheetValid = (this.$refs.timesheetform as HTMLFormElement)?.validate() ?? true;
      return additionalDetailsValid && timesheetValid;
    },
    rowIsEditable(item: TimesheetRow): boolean {
      if (!item.isCorrectionRow && this.timesheetIsReadonly) return false;
      if (!!item.isCorrectionRow && !this.makeCorrections) return false;
      return true;
    },
    childWorkSubTypesInTopLevelSubType(
      wst: TopLevelWorkSubTypeWithDetails,
      row: TimesheetRow
    ): WorkSubTypeWithDetails[] {
      if (row.rowType == TimesheetRowType.Equipment) return [];
      else if (row.rowType == TimesheetRowType.DirectGeneral)
        return wst.nonWorkOrderRelatedChildSubTypes;
      else if (row.rowType == TimesheetRowType.DirectWorkOrderRelated)
        return wst.workOrderRelatedChildSubTypes;
      return wst.allChildSubTypes;
    },

    /**
     * Determines whether the specified WST is valid for the row AT ALL.
     * @param row
     * @param workSubTypeID
     * @returns
     */
    rowCanUseWorkSubType(row: TimesheetRow, workSubTypeID: string | null | undefined): boolean {
      if (!workSubTypeID?.length) return false;
      if (
        row.rowType == TimesheetRowType.Equipment ||
        row.resourceType == TimesheetRowResourceType.Equipment
      )
        return false;
      let wst = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!wst) return false;

      let workOrderRelatedMatches =
        (!!wst.isWorkOrderRelated && row.rowType == TimesheetRowType.DirectWorkOrderRelated) ||
        (!wst.isWorkOrderRelated && row.rowType == TimesheetRowType.DirectGeneral) ||
        wst.isParent;
      let canEdit =
        (!!wst.isDirect && workOrderRelatedMatches) ||
        (!!wst.isIndirect && row.rowType == TimesheetRowType.Indirect);
      return canEdit;
    },

    /**
     * Determines whether the text field displayed for this WST in the timesheet table is editable for the current user and the current timesheet state.
     *
     * Note: This is ONLY for actual time entry fields (either in the table or in the dialog).  Fields for parent sub types do not use this logic.
     * @param row
     * @param workSubTypeID
     * @returns
     */
    rowCanEditWorkSubType(row: TimesheetRow, workSubTypeID: string | null | undefined): boolean {
      if (!workSubTypeID?.length) return false;
      if (
        row.rowType == TimesheetRowType.Equipment ||
        row.resourceType == TimesheetRowResourceType.Equipment
      )
        return false;
      if (!row.isCorrectionRow && this.timesheetIsReadonly) return false;
      if (!!row.isCorrectionRow && !this.makeCorrections) return false;

      let wst = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!wst) return false;

      let hasWorkOrder = !!row.workOrderID?.length ?? false;
      let canEdit =
        (!!wst.isDirect && !!wst.isWorkOrderRelated && hasWorkOrder) ||
        (!!wst.isDirect &&
          !wst.isWorkOrderRelated &&
          row.rowType == TimesheetRowType.DirectGeneral) ||
        (!!wst.isIndirect && row.rowType == TimesheetRowType.Indirect);
      return canEdit;
    },
    // Finds the row (if exists) for an employee that has Per Diem turned on, and is not corrected to be off
    existingUncorrectedPerDiemRowForEmployee(employeeID: string): TimesheetRow | undefined {
      return this.allTimesheetRows.find(
        x =>
          x.employeeID == employeeID &&
          x.hasPerDiem &&
          !x.removePerDiem &&
          !x.relatedCorrectionRemovePerDiem
      );
    },
    // If the employee has an uncorrected perdiem row that is NOT the specified row
    existingOtherUncorrectedPerDiemRowForEmployee(row: TimesheetRow): TimesheetRow | undefined {
      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(
        row.employeeID
      );
      if (!existingPerDiemRowForEmployee) return undefined;
      return !areTimesheetRowsEqual(row, existingPerDiemRowForEmployee)
        ? existingPerDiemRowForEmployee
        : undefined;
    },
    hasExistingOtherUncorrectedPerDiemRowForEmployee(row: TimesheetRow): boolean {
      return !!this.existingOtherUncorrectedPerDiemRowForEmployee(row);
    },
    // Whether the passed in row has a related row with HasPerDiem = true
    hasExistingRelatedPerDiemRowForRow(item: TimesheetRow): boolean {
      let relatedRow = FindRelatedRowForCorrection(item, this.allTimesheetRows);
      return !!relatedRow?.hasPerDiem;
    },
    existingOtherCorrectionPerDiemRow(row: TimesheetRow): boolean {
      let correctionPerDiemRow = this.allTimesheetRows.find(
        x =>
          x.employeeID == row.employeeID && (x.hasPerDiem || x.removePerDiem) && !!x.isCorrectionRow
      );
      return !!correctionPerDiemRow && !areTimesheetRowsEqual(row, correctionPerDiemRow);
    },
    // Only indirect work rows can have per diem entry
    isPerDiemApplicable(item: TimesheetRow): boolean {
      if (
        item.rowType == TimesheetRowType.Equipment ||
        item.resourceType == TimesheetRowResourceType.Equipment
      )
        return false;
      if (this.perDiemSubType?.isWorkOrderRelated == true) return true;

      return (
        !item.workOrderID &&
        (item.rowType == TimesheetRowType.DirectGeneral ||
          item.rowType == TimesheetRowType.Indirect)
      );
    },
    // Only one row can have a per diem per employee
    canEditPerDiem(item: TimesheetRow): boolean {
      // Per Diem's can ONLY be associated to non-work-order timesheet rows
      if (!!item.workOrderID && !this.perDiemSubType?.isWorkOrderRelated) return false;
      // Only admins on indirect timesheets can edit per diems
      if (
        item.rowType == TimesheetRowType.Equipment ||
        item.resourceType == TimesheetRowResourceType.Equipment
      )
        return false;

      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(
        item.employeeID
      );
      if (!!existingPerDiemRowForEmployee) {
        // If there is an existing per diem row on this timesheet, check if it's the current row
        // If it is it's editable, if it's not it's not editable
        let existingPerDiemRowIsCurrentItem = areTimesheetRowsEqual(
          item,
          existingPerDiemRowForEmployee
        );
        return existingPerDiemRowIsCurrentItem;
      } else {
        // If there isn't an existing per diem row, it may exist on another timesheet.  Check the time summary
        let employeeSummary = this.timeSummaries?.find(x => x.employeeID == item.employeeID);
        let perdiemSummary = employeeSummary?.workSubTypeTimeSummaries.find(
          x => x.workSubTypeID == this.perDiemSubType?.id
        );
        return !perdiemSummary?.totalUnits;
      }
    },
    otherTimesheetEmployeeSummary(employeeID: string): EmployeeTimeSummary | undefined {
      return this.timeSummaries?.find(x => x.employeeID == employeeID);
    },
    otherTimesheetPerDiemSummaryForEmployee(
      employeeID: string
    ): WorkSubTypeTimeSummary | undefined {
      let perdiemSummary = this.otherTimesheetEmployeeSummary(
        employeeID
      )?.workSubTypeTimeSummaries.find(x => x.workSubTypeID == this.perDiemSubType?.id);
      return perdiemSummary;
    },
    hasPerDiemOnOtherTimesheet(employeeID: string): boolean {
      return !!this.otherTimesheetPerDiemSummaryForEmployee(employeeID)?.totalUnits;
    },
    perDiemOwnerFromOtherTimesheet(employeeID: string): string | undefined {
      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(employeeID);
      if (!existingPerDiemRowForEmployee) {
        return this.otherTimesheetEmployeeSummary(employeeID)?.perDiemTimesheetOwnerName;
      }

      return undefined;
    },
    otherPerDiemRowWorkOrderNumber(item: TimesheetRow) {
      return this.existingOtherUncorrectedPerDiemRowForEmployee(item)?.workOrderNumber;
    },
    perDiemReadonlyReason(item: TimesheetRow) {
      let perdiemOtherTimesheetOwner = this.perDiemOwnerFromOtherTimesheet(item.employeeID);
      if (!!perdiemOtherTimesheetOwner) {
        return this.$t("timesheets.per-diem-already-applied-chip-label");
      }
      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(
        item.employeeID
      );
      if (!!existingPerDiemRowForEmployee) {
        // If there is an existing per diem row on this timesheet, check if it's the current row
        // If it is it's editable, if it's not it's not editable
        let existingPerDiemRowIsCurrentItem = areTimesheetRowsEqual(
          item,
          existingPerDiemRowForEmployee
        );

        // If the existing row isn't the current item, but is still the same WO, then we don't need a specific reason
        if (
          !existingPerDiemRowIsCurrentItem &&
          existingPerDiemRowForEmployee.workOrderID != item.workOrderID
        ) {
          if (
            existingPerDiemRowForEmployee.resourceType == TimesheetRowResourceType.Employee &&
            existingPerDiemRowForEmployee.rowType == TimesheetRowType.DirectWorkOrderRelated
          ) {
            return this.$t("timesheets.per-diem-other-work-order-applied-chip-label", [
              formatWorkOrderNumber(existingPerDiemRowForEmployee.workOrderNumber)
            ]);
          } else {
            return this.$t("timesheets.per-diem-general-applied-chip-label");
          }
        }
      }

      // Let the control use its default text
      return undefined;
    },
    canCorrectPerDiemInRow(row: TimesheetRow): boolean {
      if (!row.hasPerDiem) {
        let perDiemOnOtherTimesheet = !!this.perDiemOwnerFromOtherTimesheet(row.employeeID);
        if (perDiemOnOtherTimesheet) {
          return false;
        }
      }
      return true;
    },
    perDiemCorrectionItemsForRow(row: TimesheetRow) {
      let items = [
        { text: this.$t("timesheets.existing.correction-no-per-diem-change"), value: false }
      ];
      if (row.hasPerDiem) {
        items.push({
          text: this.$t("timesheets.existing.correction-add-per-diem"),
          value: true
        });
      } else {
        let perDiemOnOtherTimesheet = !!this.perDiemOwnerFromOtherTimesheet(row.employeeID);
        if (!perDiemOnOtherTimesheet) {
          let relatedRowHasPerDiem = this.hasExistingRelatedPerDiemRowForRow(row);
          if (relatedRowHasPerDiem) {
            items.push({
              text: this.$t("timesheets.existing.correction-remove-per-diem"),
              value: true
            });
          } else {
            items.push({
              text: this.$t("timesheets.existing.correction-add-per-diem"),
              value: true
            });
          }
        }
      }
      return items;
    },
    scaffoldNumberForGroup(workOrderNumber: string): string {
      let number = this.workOrderTimesheetRows.find(
        x => (x.workOrderNumber ?? "") == workOrderNumber
      )?.scaffoldNumber;
      if (!number) return "";
      return formatScaffoldTagNumber(number);
    },
    clientWorkOrderNumberForGroup(workOrderNumber: string): string {
      return (
        this.workOrderTimesheetRows.find(x => (x.workOrderNumber ?? "") == workOrderNumber)
          ?.workOrderClientWorkOrderNumber ?? ""
      );
    },
    serviceOrderNumberForGroup(workOrderNumber: string): string {
      return (
        this.workOrderTimesheetRows.find(x => (x.workOrderNumber ?? "") == workOrderNumber)
          ?.workOrderServiceOrderNumber ?? ""
      );
    },
    purchaseOrderNumberForGroup(workOrderNumber: string): string {
      return (
        this.workOrderTimesheetRows.find(x => (x.workOrderNumber ?? "") == workOrderNumber)
          ?.workOrderPurchaseOrderNumber ?? ""
      );
    },
    canEditEquipment(row: TimesheetRow): boolean {
      if (
        row.rowType != TimesheetRowType.Equipment &&
        row.resourceType != TimesheetRowResourceType.Equipment
      )
        return false;
      if (!row.isCorrectionRow && this.timesheetIsReadonly) return false;
      if (!!row.isCorrectionRow && !this.makeCorrections) return false;

      return true;
    },
    canAddNewRowFromRow(row: TimesheetRow): boolean {
      if (!this.rowIsEditable(row)) return false;

      let existingSimilarRowWithoutArea = this.timesheetRowAlreadyExists(
        row.isCorrectionRow,
        row?.employeeID,
        undefined,
        row?.resourceType,
        row?.rowType,
        row?.workOrderID,
        null,
        null
      );
      return !existingSimilarRowWithoutArea;
    },
    // Only one row can have a per diem per employee
    canEditNightShift(item: TimesheetRow): boolean {
      // Per Diem's can ONLY be associated to non-work-order timesheet rows
      if (!item.workOrderID) return false;
      return !this.timesheetIsReadonly;
    },
    toggleTableGroups(tableIdentifier: string, closed: boolean = true) {
      let toggleRefs = Object.keys(this.$refs).filter(x =>
        x.startsWith(`${tableIdentifier}grouptoggle`)
      );
      let datatable = this.$refs[`${tableIdentifier}datatable`] as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },

    canEditTimesheet(timesheet: UpdatableTimesheetWithTimesheetRows | null | undefined) {
      if (!timesheet?.id) return false;

      let locked = timesheet.isLocked ?? false;
      let currentDay =
        new Date(timesheet.day!.toDateString()).getTime() ==
        new Date(new Date().toDateString()).getTime();
      return !locked && currentDay;
    },
    //#region Time Calculations
    countPerDiems(items: TimesheetRow[]): number {
      let result = items.reduce(
        (a, b) => a + (!!b.hasPerDiem ? 1 : 0) - (!!b.removePerDiem ? 1 : 0),
        0
      );
      return result;
    },
    getTimesForWorkSubTypeInRow(
      row: TimesheetRow,
      workSubTypeID: string | null | undefined
    ): TimesheetRowTimeValues | null | undefined {
      if (!workSubTypeID?.length) return undefined;

      let wst = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!wst) return undefined;
      if (!wst.isParent) {
        return row.times[workSubTypeID];
      }
      let childTypeIDs = this.allWorkSubTypes
        .filter(x => x.parentWorkSubTypeID == workSubTypeID)
        .map(x => x.id!);
      if (!childTypeIDs.length) {
        return row.times[workSubTypeID];
      }
      return this.calculateTotalForWorkSubTypesInRow(row, childTypeIDs);
    },
    wstName(id: string) {
      let wst = this.allWorkSubTypes.find(x => x.id == id);
      return wst?.code ?? wst?.name;
    },
    getTimeSummaryStringForWorkSubTypeInRow(
      row: TimesheetRow,
      workSubTypeID: string | null | undefined
    ): string | null | undefined {
      return this.getTimesForWorkSubTypeInRow(row, workSubTypeID)?.summaryString;
    },
    sumRowTimeValues(
      items: TimesheetRow[],
      propName: string | null | undefined
    ): TimesheetRowTimeValues | null | undefined {
      if (!propName?.length) return undefined;

      let result: TimesheetRowTimeValues = items.reduce(
        (a, b) => a.adding(this.getTimesForWorkSubTypeInRow(b, propName)),
        new TimesheetRowTimeValues()
      );
      return result;
    },
    sumEquipmentHours(items: TimesheetRow[]): string | undefined {
      let result = items.reduce((a, b) => a + this.$parse.sanitizedNumber(b.equipmentHours), 0);
      return !result ? undefined : result.toFixed(2);
    },
    calculateTotalForWorkSubTypesInRow(
      row: TimesheetRow,
      wstIDs: Array<string>
    ): TimesheetRowTimeValues {
      let total = wstIDs.reduce(
        (a, wstID) => a.adding(this.getTimesForWorkSubTypeInRow(row, wstID)),
        new TimesheetRowTimeValues()
      );
      return total;
    },
    calculateTotalForRow(row: TimesheetRow): TimesheetRowTimeValues {
      return CalculateRowTotalTime(row);
    },
    calculateTotalForRows(rows: TimesheetRow[]): TimesheetRowTimeValues {
      let total: TimesheetRowTimeValues = rows.reduce(
        (a: TimesheetRowTimeValues, b: TimesheetRow) => a.adding(this.calculateTotalForRow(b)),
        new TimesheetRowTimeValues()
      );
      return total;
    },
    //#endregion

    // *** NAVIGATION ***
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    addFormOnSubmit(e: Event) {
      e.preventDefault();
      this.addTimesheetRows(this.makeCorrections ?? false);
    },

    // *** ENTRY MANAGEMENT ***
    _addTimesheetRowUsingWorkOrder(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      employeeName: string | null | undefined,
      employeeCode: string | null | undefined,
      employeeBadge: string | null | undefined,
      employeeClassificationID: string | null | undefined,
      resourceType: TimesheetRowResourceType,
      rowType: TimesheetRowType,
      workOrder: FormattedWorkOrder | null,
      equipmentCostCodeID: string | null | undefined,
      areaID: string | null,
      subAreaID: string | null,
      focusOnNewRow: boolean = true
    ): TimesheetRow {
      return this._addTimesheetRow(
        isCorrectionRow,
        employeeID,
        employeeName,
        employeeCode,
        employeeBadge,
        employeeClassificationID,
        resourceType,
        rowType,
        workOrder?.id,
        `${workOrder?.internalNumber ?? ""}`,
        workOrder?.clientWorkOrderReferenceNumber,
        workOrder?.serviceOrderReferenceNumber,
        workOrder?.changeOrderReferenceNumber,
        workOrder?.reworkReferenceNumber,
        workOrder?.scaffoldID,
        workOrder?.scaffoldNumber,
        workOrder?.costCodeID,
        equipmentCostCodeID,
        areaID,
        subAreaID,
        focusOnNewRow
      );
    },
    _addTimesheetRow(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      employeeName: string | null | undefined,
      employeeCode: string | null | undefined,
      employeeBadge: string | null | undefined,
      employeeClassificationID: string | null | undefined,
      resourceType: TimesheetRowResourceType,
      rowType: TimesheetRowType,
      workOrderID: string | null | undefined,
      workOrderNumber: string | null | undefined,
      workOrderClientWorkOrderReferenceNumber: string | null | undefined,
      workOrderServiceOrderReferenceNumber: string | null | undefined,
      workOrderChangeOrderReferenceNumber: string | null | undefined,
      workOrderReworkReferenceNumber: string | null | undefined,
      workOrderScaffoldID: string | null | undefined,
      workOrderScaffoldNumber: number | null | undefined,
      workOrderCostCodeID: string | null | undefined,
      equipmentCostCodeID: string | null | undefined,
      areaID: string | null,
      subAreaID: string | null,
      focusOnNewRow: boolean
    ): TimesheetRow {
      let area = this.allAreas.find(x => x.id == areaID);
      let subArea = this.allSubAreas.find(x => x.id == subAreaID);
      let classificationDisplayName = this.getClassificationDisplayNameForID(
        employeeClassificationID
      );
      let equipmentCostCodeDisplayName = this.allCostCodes.find(x => x.id == equipmentCostCodeID)
        ?.name;
      let existingRow = this.getExistingTimesheetRow(
        isCorrectionRow,
        employeeID,
        employeeClassificationID ?? null,
        resourceType,
        rowType,
        workOrderID,
        areaID,
        subAreaID
      );
      if (!!existingRow) return existingRow;
      let newRow = {
        resourceType: resourceType,
        rowType: rowType,
        isCorrectionRow: isCorrectionRow,
        rowNumber: this.timesheet?.nextRowNumber ?? 1,
        employeeID: employeeID,
        employeeName: employeeName,
        employeeCode: employeeCode,
        employeeBadge: employeeBadge,
        classificationID: employeeClassificationID,
        classificationDisplayName: classificationDisplayName,
        workOrderID: workOrderID ?? "",
        workOrderNumber: workOrderNumber,
        workOrderClientWorkOrderNumber: workOrderClientWorkOrderReferenceNumber,
        workOrderServiceOrderNumber: workOrderServiceOrderReferenceNumber,
        workOrderChangeOrderNumber: workOrderChangeOrderReferenceNumber,
        workOrderReworkNumber: workOrderReworkReferenceNumber,
        scaffoldID: workOrderScaffoldID ?? "",
        scaffoldNumber: workOrderScaffoldNumber ?? 0,
        areaID: areaID ?? "",
        areaName: area?.name,
        subAreaID: subAreaID ?? "",
        subAreaName: subArea?.name,
        workOrderCostCodeID: workOrderCostCodeID ?? "",
        hasPerDiem: false,
        removePerDiem: false,
        equipmentCostCodeID: equipmentCostCodeID,
        equipmentCostCodeDisplayName: equipmentCostCodeDisplayName,
        equipmentHours: 0,
        equipmentDays: 0,
        equipmentQuantity: 0,
        errorMessage: "",
        notesCount: 0,
        times: {}
      } as TimesheetRow;

      // Here we find all available WSTs for this row type so we can prefill with null values
      // The purpose of this is to populate the row object with WST IDs as keys so they can be turned into empty Entries if necessary
      // Right now, we want drafts to save empty entries as a "working state", which will be removed when submitted
      let availableWSTs = [] as TopLevelWorkSubTypeWithDetails[];
      if (resourceType == TimesheetRowResourceType.Employee) {
        switch (rowType) {
          case TimesheetRowType.DirectGeneral:
            availableWSTs = this.generalizedDirectWorkSubTypes;
            break;
          case TimesheetRowType.DirectWorkOrderRelated:
            availableWSTs = this.workOrderWorkSubTypes;
            break;
          case TimesheetRowType.Indirect:
            availableWSTs = this.indirectWorkSubTypes;
            break;
          default:
            availableWSTs = [];
            break;
        }
      }
      for (let wst of availableWSTs) {
        // We don't want to add the ID of a parent WST to the row data as we can't store time against it.
        if (!!wst.isParent || !!wst.allChildSubTypes.length) {
          for (let cwst of wst.allChildSubTypes) {
            newRow.times[cwst.id!] = new TimesheetRowTimeValues();
          }
        } else {
          newRow.times[wst.id!] = new TimesheetRowTimeValues();
        }
      }

      this.timesheet!.timesheetRows.push(newRow);
      UpdateRowCorrections(newRow, this.allTimesheetRows);

      if (!!workOrderID?.length) {
        if (this.timesheetWorkOrders.findIndex(x => x.id == workOrderID) == -1) {
          let workOrder = this.availableWorkOrders.find(x => x.id == workOrderID);
          if (!!workOrder) {
            this.timesheetWorkOrders.push(workOrder);
            this.timesheetWorkOrders.sort(
              (a, b) => (a.internalNumber ?? 0) - (b.internalNumber ?? 0)
            );
          }
        }
      }

      if (focusOnNewRow) {
        this.$nextTick(() => {
          switch (rowType) {
            case TimesheetRowType.DirectGeneral:
              this.panel = this.generalDirectPanelNumber;
              break;
            case TimesheetRowType.DirectWorkOrderRelated:
              this.panel = this.workOrderDirectPanelNumber;
              break;
            case TimesheetRowType.Indirect:
              this.panel = this.indirectPanelNumber;
              break;
            case TimesheetRowType.Equipment:
              break;
            default:
              break;
          }
        });
      }
      return newRow;
    },
    addEmployeeTimesheetRowForWorkOrder(
      isCorrectionRow: boolean,
      e: PersonWithDetailsAndName,
      workOrder: FormattedWorkOrder
    ) {
      // If this person doesn't have a general indirect row, add that too
      // Pass in 'undefined' for area/subarea as we don't care which area the row is for (null would force it to be empty)
      let existingGeneralDirectRow = this.timesheetRowAlreadyExists(
        isCorrectionRow,
        e.id,
        undefined,
        TimesheetRowResourceType.Employee,
        TimesheetRowType.DirectGeneral,
        null,
        undefined,
        undefined
      );
      if (!existingGeneralDirectRow) {
        this.addNonWorkOrderRelatedTimesheetRow(
          isCorrectionRow,
          e.id,
          e.name,
          e.employeeCode,
          e.employeeBadge,
          e.classificationID,
          TimesheetRowResourceType.Employee,
          TimesheetRowType.DirectGeneral,
          null,
          null,
          null
        );
      }

      // Add the workorder timesheet row last so its section is opened isntead of the non-work order section
      this._addTimesheetRowUsingWorkOrder(
        isCorrectionRow,
        e.id,
        e.name,
        e.employeeCode,
        e.employeeBadge,
        e.classificationID,
        TimesheetRowResourceType.Employee,
        TimesheetRowType.DirectWorkOrderRelated,
        workOrder,
        null,
        workOrder.areaID ?? null,
        workOrder.subAreaID ?? null
      );
    },
    addEquipmentTimesheetRowForWorkOrder(
      isCorrectionRow: boolean,
      e: EquipmentForContractor,
      workOrder: FormattedWorkOrder
    ) {
      // If this person doesn't have a general indirect row, add that too
      // Pass in 'undefined' for area/subarea as we don't care which area the row is for (null would force it to be empty)
      let existingGeneralDirectRow = this.timesheetRowAlreadyExists(
        isCorrectionRow,
        e.id,
        undefined,
        TimesheetRowResourceType.Equipment,
        TimesheetRowType.DirectGeneral,
        null,
        undefined,
        undefined
      );
      if (!existingGeneralDirectRow) {
        this.addNonWorkOrderRelatedTimesheetRow(
          isCorrectionRow,
          e.id,
          e.name,
          e.serialNumber,
          e.modelNumber,
          e.equipmentClassificationID,
          TimesheetRowResourceType.Equipment,
          TimesheetRowType.DirectGeneral,
          e.costCodeID,
          null,
          null
        );
      }

      // Add the workorder timesheet row last so its section is opened isntead of the non-work order section
      this._addTimesheetRowUsingWorkOrder(
        isCorrectionRow,
        e.id,
        e.name,
        e.serialNumber,
        e.modelNumber,
        e.equipmentClassificationID,
        TimesheetRowResourceType.Equipment,
        TimesheetRowType.DirectWorkOrderRelated,
        workOrder,
        e.costCodeID,
        workOrder.areaID ?? null,
        workOrder.subAreaID ?? null
      );
    },
    addNonWorkOrderRelatedTimesheetRow(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      employeeName: string | null | undefined,
      employeeCode: string | null | undefined,
      employeeBadge: string | null | undefined,
      employeeClassificationID: string | null | undefined,
      resourceType: TimesheetRowResourceType,
      rowType: TimesheetRowType,
      equipmentCostCodeID: string | null | undefined,
      areaID: string | null,
      subAreaID: string | null,
      focusOnNewRow: boolean = true
    ): TimesheetRow {
      return this._addTimesheetRowUsingWorkOrder(
        isCorrectionRow,
        employeeID,
        employeeName,
        employeeCode,
        employeeBadge,
        employeeClassificationID,
        resourceType,
        rowType,
        null,
        equipmentCostCodeID,
        areaID,
        subAreaID,
        focusOnNewRow
      );
    },
    async addTimesheetRows(isCorrectionRow: boolean) {
      if (!this.timesheet) return;
      if (this.timesheet.isLocked && !isCorrectionRow) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.addform as HTMLFormElement).validate()) {
        return;
      }

      if (!this.selectedEntryType) return;
      if (!this.selectedResourceID && !this.selectedResourceGroupID) return;
      if (
        !!this.selectedResourceGroupID &&
        !this.crewIsSelectable(this.selectedCrew) &&
        !this.fleetIsSelectable(this.selectedFleet)
      )
        return;
      if (this.selectedEntryType == "workorder" && !this.selectedWorkOrders.length) return;

      this.processing = true;
      try {
        let selectedEmployees = this.selectedEmployees;
        selectedEmployees.forEach(e => {
          let selectedWorkOrders = this.selectedWorkOrders;
          if (!!selectedWorkOrders.length) {
            selectedWorkOrders.forEach(wo => {
              this.addEmployeeTimesheetRowForWorkOrder(isCorrectionRow, e, wo);
            });
          } else {
            let rowType = TimesheetRowType.Indirect;
            if (this.selectedEntryType == "generaldirect") rowType = TimesheetRowType.DirectGeneral;
            this.addNonWorkOrderRelatedTimesheetRow(
              isCorrectionRow,
              e.id,
              e.name,
              e.employeeCode,
              e.employeeBadge,
              e.classificationID,
              TimesheetRowResourceType.Employee,
              rowType,
              null,
              null,
              null
            );
          }
        });
        let selectedEquipmentList = this.selectedEquipmentList;
        selectedEquipmentList.forEach(e => {
          let selectedWorkOrders = this.selectedWorkOrders;
          if (!!selectedWorkOrders.length) {
            selectedWorkOrders.forEach(wo => {
              this.addEquipmentTimesheetRowForWorkOrder(isCorrectionRow, e, wo);
            });
          } else {
            let rowType = TimesheetRowType.Indirect;
            if (this.selectedEntryType == "generaldirect") rowType = TimesheetRowType.DirectGeneral;
            this.addNonWorkOrderRelatedTimesheetRow(
              isCorrectionRow,
              e.id,
              e.name,
              e.serialNumber,
              e.modelNumber,
              e.equipmentClassificationID,
              TimesheetRowResourceType.Equipment,
              rowType,
              e.costCodeID,
              null,
              null
            );
          }
        });
        this.timesheet.timesheetRows = SortTimesheetRows(this.timesheet.timesheetRows);

        this.selectedWorkOrders = [];
        this.selectedResourceGroupID = null;
        this.selectedResourceID = null;

        this.$emit("rows-added");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    removeTimesheetRow(row: TimesheetRow) {
      if (!this.timesheet) return;
      const index = this.timesheet.timesheetRows.indexOf(row);
      if (index < 0) {
        return;
      }
      // Clear out all hours values for row to be removed to confirm if explanations also need to be removed
      ParseWorkSubTypeIDsFromRow(row).forEach(wstid => {
        row.times[wstid] = new TimesheetRowTimeValues();
        this.workSubTypeHoursValueChanged(row, wstid, 0);
      });
      row.hasPerDiem = false;
      row.removePerDiem = false;
      this.perDiemValueChanged(row);

      this.timesheet.timesheetRows.splice(index, 1);
    },

    // *** LOADING ***
    formatWorkOrder(result: WorkOrderSearchResult): FormattedWorkOrder {
      return {
        ...result,
        description: formatWorkOrderNumber(result.workOrderNumber),
        workOrderNumber: formatWorkOrderNumber(result.workOrderNumber),
        details: `${ScaffoldRequestTypes[result.scaffoldRequestType!]} (${
          WorkOrderStatuses[result.workOrderStatus!]
        })`,
        requestTypeName: `${ScaffoldRequestTypes[result.scaffoldRequestType!]}`,
        workOrderStatusName: `${WorkOrderStatuses[result.workOrderStatus!]}`,
        startDateString: !!result.startDate ? this.$format.date(result.startDate) : "",
        completedDateString: !!result.completedDate ? this.$format.date(result.completedDate) : ""
      } as FormattedWorkOrder;
    },
    async loadWorkOrders(
      workOrderSearchString: string | undefined,
      restrictDay: boolean,
      restrictAssignment: boolean
    ) {
      // If the current timesheet doesn't have an owner, there can't be any WOs associated so don't bother checking
      // Also, if the current timesheet is indirect it can't have WOs associated even if they exist
      if (!this.timesheet?.ownerID || this.timesheet.timesheetTypeID != TimesheetType.Direct)
        return;

      if (!workOrderSearchString && !this.contractor?.id) {
        return;
      }

      let activeDay = restrictDay ? this.timesheet.day : undefined;
      let assignedForeman = restrictAssignment
        ? (this.timesheet.ownerID as string | undefined)
        : undefined;

      let workOrders = await workOrderService.search(
        activeDay,
        workOrderSearchString,
        undefined,
        this.contractor?.id,
        undefined,
        assignedForeman
      );
      this.availableWorkOrders = workOrders.map(x => this.formatWorkOrder(x));
    },
    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },

    async loadTimesheetWorkOrders(timesheetID: string | null | undefined) {
      if (!timesheetID) return;
      this.timesheetWorkOrders = (
        await workOrderService.getBasicWorkOrderInfoForTimesheet(timesheetID)
      )
        .sort((a, b) => (a.internalNumber ?? 0) - (b.internalNumber ?? 0))
        .map(x => this.formatWorkOrder(x));
    },

    /// This is called both when the form first loads (potentially with a timesheet set) or after the timesheet changes.
    /// That makes thise method ideal for any data changes that need to happen based on timesheet data
    async loadDataForTimesheet() {
      if (!this.timesheet) {
        return;
      }
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      this.contractor = allContractors.find(x => x.id == this.timesheet?.contractorID);

      this.processing = true;
      try {
        // Load the initial list with "suggested" restricted work orders
        await Promise.all([
          this.loadWorkOrders(undefined, true, true),
          this.loadTimesheetWorkOrders(this.timesheet.id),
          this.loadCrewsForContractor(this.timesheet.contractorID!),
          this.loadFleetsForContractor(this.timesheet.contractorID!),
          this.loadEquipmentForContractor({ contractorID: this.timesheet.contractorID })
        ]);
        this.allEquipment = this.$store.state.equipment.fullList as EquipmentForContractor[];
        this.$nextTick(() => {
          this.toggleTableGroups("summary", true);
          if (this.hasWorkOrderDirectRows) this.panel = this.workOrderDirectPanelNumber;
          else if (this.hasGeneralDirectRows) this.panel = this.generalDirectPanelNumber;
          else if (this.hasIndirectRows) this.panel = this.indirectPanelNumber;
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadData() {
      this.processing = true;
      try {
        await Promise.all([
          this.loadAreas(),
          this.loadSubAreas(),
          this.loadContractors(),
          this.loadWorkTypes(),
          this.loadWorkSubTypes(),
          this.loadCostCodes(),
          this.loadEmployees(),
          this.loadClassifications(),
          this.loadEquipmentClassifications()
        ]);
        this.allEquipmentClassifications = this.$store.state.equipmentClassifications
          .fullList as EquipmentClassification[];

        this.allPeople = (this.$store.state.users.fullList as PersonWithDetails[]).map(p => ({
          ...p,
          name: GetPersonName(p)
        }));
        this.allClassifications = this.$store.state.classifications.fullList as Classification[];
        this.allCostCodes = this.$store.state.projectCostCodes.fullList as ProjectCostCode[];

        this.allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];

        let allWorkSubTypes = this.$store.state.workSubTypes
          .fullList as WorkSubTypeWithParentDetails[];
        this.allWorkSubTypes = allWorkSubTypes.map(wst => {
          let workType = this.allWorkTypes.find(wt => wt.id == wst.workTypeID);
          return {
            ...wst,
            isDirect: !!workType?.isDirect,
            isPerDiem: !!workType?.isPerDiem,
            isEquipment: !!workType?.isEquipment,
            isIndirect: !workType?.isDirect && !workType?.isPerDiem && !workType?.isEquipment
          };
        });
        this.allConfiguredWorkSubTypes = this.allWorkSubTypes.filter(
          x =>
            (!!x.isWorkOrderRelated && !!x.useWorkOrderCostCode) ||
            !!x.defaultCostCodeID ||
            x.isParent
        );
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    getClassificationDisplayNameForID(
      classificationID: string | null | undefined
    ): string | undefined {
      let classification: AnyClassification | undefined = this.allClassifications.find(
        x => x.id == classificationID
      );
      if (!classification) {
        classification = this.allEquipmentClassifications.find(x => x.id == classificationID);
      }
      return (classification as any)?.alias ?? classification?.name;
    },
    classificationInUseForResource(
      classificationID: string | null | undefined,
      resourceID: string | null | undefined,
      rowType: TimesheetRowType
    ): boolean {
      if (!this.timesheet?.timesheetRows?.length) return false;

      let index = this.timesheet.timesheetRows.findIndex(x => {
        let matches =
          x.employeeID == resourceID &&
          x.classificationID == classificationID &&
          x.rowType == rowType;
        return matches;
      });
      return index != -1;
    },
    classificationsForRow(row: TimesheetRow): AnyClassification[] {
      if (row.resourceType == TimesheetRowResourceType.Equipment) {
        return this.allEquipmentClassifications.filter(x => x.id == row.classificationID);
      }
      let person = this.allPeople.find(x => x.id == row.employeeID);
      if (!person?.classificationIDs?.length) return [];

      let selectableClassifications = this.allClassifications.filter(
        x => x.id == person?.classificationID || person?.classificationIDs?.includes(x.id!)
      );
      return selectableClassifications;
    },
    groupedClassificationsForRow(
      row: TimesheetRow
    ): GroupableSelectListOption<ClassificationWithDisplayName>[] {
      let selectableClassifications = this.classificationsForRow(row);
      let ungrouped = selectableClassifications
        .map(
          x =>
            ({
              ...x,
              displayName: (x as any).alias ?? x.name,
              disabled: this.classificationInUseForResource(x.id, row.employeeID, row.rowType)
            } as SelectListOption<ClassificationWithDisplayName>)
        )
        .sort((a, b) => {
          let aName = a.displayName!.toLocaleLowerCase();
          let bName = b.displayName!.toLocaleLowerCase();
          if (aName < bName) return -1;
          else if (aName > bName) return 1;
          else return 0;
        });

      let groupedClassifications: GroupableSelectListOption<ClassificationWithDisplayName>[] = [];

      let usedClassifications = ungrouped.filter(x => !!x.disabled);
      let unusedClassifications = ungrouped.filter(x => !x.disabled);

      if (!!unusedClassifications.length) {
        groupedClassifications.push({
          header: this.$t("timesheets.existing.classification-select-new-row-group-label")
        });
        groupedClassifications.push({ divider: true });
        groupedClassifications = groupedClassifications.concat(unusedClassifications);
      }
      if (!!usedClassifications.length) {
        groupedClassifications.push({
          header: this.$t("timesheets.existing.classification-select-in-use-group-label")
        });
        groupedClassifications.push({ divider: true });
        groupedClassifications = groupedClassifications.concat(usedClassifications);
      }

      return groupedClassifications;
    },
    classificationSelected(row: TimesheetRow, newValue: Classification) {
      if (newValue.id == row.classificationID) return;

      let person = this.allPeople.find(x => x.id == row.employeeID);

      if (
        !!person?.classificationID?.length &&
        !this.classificationInUseForResource(newValue.id, person?.id, row.rowType)
      ) {
        this._addTimesheetRow(
          false,
          row.employeeID,
          row.employeeName,
          row.employeeCode,
          row.employeeBadge,
          newValue.id,
          row.resourceType,
          row.rowType,
          row.workOrderID,
          row.workOrderNumber,
          row.workOrderClientWorkOrderNumber,
          row.workOrderServiceOrderNumber,
          row.workOrderChangeOrderNumber,
          row.workOrderServiceOrderNumber,
          row.scaffoldID,
          row.scaffoldNumber,
          row.workOrderCostCodeID,
          row.equipmentCostCodeID,
          row.areaID ?? null,
          row.subAreaID ?? null,
          true
        );
      }
    },

    // *** INLINE NAVIGATION ***
    //#region Text Field Navigation
    getDataTable(tableIdentifier: string) {
      return this.$refs[`${tableIdentifier}datatable`] as VDataTable;
    },
    getFieldRef(tableIdentifier: string, fieldName: string | null | undefined, item: TimesheetRow) {
      let field = fieldName!.replace(/-/g, "");
      let employeeID = item.employeeID!.replace(/-/g, "");
      let areaID = item.areaID?.replace(/-/g, "") ?? "";
      let subAreaID = item.subAreaID?.replace(/-/g, "") ?? "";
      let correctionType = item.isCorrectionRow ? "correction" : "entry";
      return `${tableIdentifier}_${field}_${employeeID}_${areaID}_${subAreaID}_${correctionType}`;
    },
    focusFieldForVisibleItemAtIndex(
      tableIdentifier: string,
      fieldName: string | null | undefined,
      index: number,
      visibleItems: TimesheetRow[]
    ) {
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      this.focusField(tableIdentifier, fieldName, item);
    },
    focusField(tableIdentifier: string, fieldName: string | null | undefined, row: TimesheetRow) {
      let itemFieldRef = this.getFieldRef(tableIdentifier, fieldName, row);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(
      e: Event,
      tableIdentifier: string,
      fieldName: string | null | undefined,
      item: TimesheetRow
    ) {
      e.preventDefault();

      let datatable = this.getDataTable(tableIdentifier);
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex <= 0) {
        let _this = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          _this.focusFieldForVisibleItemAtIndex(
            tableIdentifier,
            fieldName,
            datatable.computedItemsPerPage,
            visibleItems
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, previousIndex, visibleItems);
    },
    async selectNextField(
      e: Event,
      tableIdentifier: string,
      fieldName: string | null | undefined,
      item: TimesheetRow
    ) {
      e.preventDefault();
      let datatable = this.getDataTable(tableIdentifier);
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex >= visibleItems.length - 1) {
        let _this = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          _this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, 0, visibleItems);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, nextIndex, visibleItems);
    },
    async enterPressed(
      e: KeyboardEvent,
      tableIdentifier: string,
      fieldName: string | null | undefined,
      item: TimesheetRow
    ) {
      if (e.shiftKey) await this.selectPreviousField(e, tableIdentifier, fieldName, item);
      else await this.selectNextField(e, tableIdentifier, fieldName, item);
    },
    //#endregion

    navigateBackFromMultiEntryControl(
      tableIdentifier: string,
      workSubTypeID: string,
      row: TimesheetRow
    ) {
      this.focusField(tableIdentifier, workSubTypeID, row);
    },
    navigateForwardFromMultiEntryControl(
      tableIdentifier: string,
      workSubTypeID: string,
      row: TimesheetRow
    ) {
      this.focusField(tableIdentifier, workSubTypeID, row);
    },

    getHourTotalsByWorkSubTypeIDForRow(row: TimesheetRow): { [key: string]: number } {
      let wstIDs = ParseWorkSubTypeIDsFromRow(row);
      let hourTotalsByWorkSubTypeID = wstIDs.reduce((a, b) => {
        a[b] = this.$parse.sanitizedNumber(row.times[b]?.totalTime);
        return a;
      }, {} as HashTable<number>);
      return hourTotalsByWorkSubTypeID;
    },

    async downloadAndPrintPlannerReport(reportType: string = "pdf") {
      let mappedRows = this.allTimesheetRows.map(
        x =>
          ({
            name: x.employeeName,
            employeeName: x.employeeName,
            employeeCode: x.employeeCode,
            employeeBadge: x.employeeBadge,
            employeeClassification: x.classificationDisplayName,
            workOrder: this.workOrderNumberTextForRow(x),
            areaName: x.areaName,
            subAreaName: x.subAreaName,
            hoursTotalByWorkSubTypeID: this.getHourTotalsByWorkSubTypeIDForRow(x),
            perDiem: x.hasPerDiem,
            total: this.calculateTotalForRow(x).totalTime.toFixed(2),
            equipmentDays: x.equipmentDays,
            equipmentQuantity: x.equipmentQuantity
          } as LaborTimesheetRow & HasName)
      );
      let summaryTimesheetRows = SortItemsWithName(mappedRows);
      let usedWorkSubTypeIDs = summaryTimesheetRows
        .map(x =>
          Object.keys(x.hoursTotalByWorkSubTypeID).filter(
            id => !!x.hoursTotalByWorkSubTypeID[id] && x.hoursTotalByWorkSubTypeID[id] > 0
          )
        )
        .reduce((a, b) => {
          b.forEach(id => {
            if (!a.includes(id)) a.push(id);
          });
          return a;
        }, [] as string[]);
      let allWorkSubTypes = this.allWorkSubTypes;
      let usedWorkSubTypes = allWorkSubTypes.filter(x => usedWorkSubTypeIDs.includes(x.id!));
      var blob = await reportService.getLaborTimesheetPrintoutReportContentWithData(
        summaryTimesheetRows,
        usedWorkSubTypes,
        reportType,
        localizedDateTimeString(new Date()),
        false,
        false
      );
      if (reportType == "xls") {
        downloadBlob(blob, "timesheet-labor-printout.xlsx");
      } else {
        printBlob(blob, "timesheet-labor-printout.pdf", "application/pdf");
      }
    },

    ...mapMutations({
      setFilteringContext: "SET_FILTERING_CONTEXT"
    })
  },

  mounted: async function() {
    await this.loadData();
    await this.loadDataForTimesheet();
  },

  created: async function() {
    this.setFilteringContext({
      context: "foreman-timesheet-form",
      parentalContext: this.parentContext,
      selectedTab: `tab-${this.firstTabKey}`
    });
  }
});
