var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-data-table", {
    directives: [
      { name: "fd-column", rawName: "v-fd-column:name", arg: "name" },
      {
        name: "fd-column",
        rawName: "v-fd-column:serialNumber.hide-when-extra-small",
        value: _vm.$t("equipment.serial-number"),
        expression: "$t('equipment.serial-number')",
        arg: "serialNumber",
        modifiers: { "hide-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:description.hide-when-extra-small",
        arg: "description",
        modifiers: { "hide-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:contractorName",
        value: _vm.$t("contractors.contractor-table-header"),
        expression: "$t('contractors.contractor-table-header')",
        arg: "contractorName"
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:archived.no-sort.hide-when-extra-small.hide-when-header-text-empty",
        value: _vm.showArchived ? _vm.$t("common.archived") : "",
        expression: "\n    showArchived ? $t('common.archived') : ''\n  ",
        arg: "archived",
        modifiers: {
          "no-sort": true,
          "hide-when-extra-small": true,
          "hide-when-header-text-empty": true
        }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
        arg: "actions",
        modifiers: {
          "no-sort": true,
          "hide-when-extra-small": true,
          "class_fd-actions-cell": true
        }
      },
      {
        name: "fd-row-navigate",
        rawName: "v-fd-row-navigate.show-when-extra-small",
        value: _vm.navigate,
        expression: "navigate",
        modifiers: { "show-when-extra-small": true }
      }
    ],
    ref: "datatable",
    class: [{ "fd-archive-bonus-padding-bottom": _vm.showArchived }],
    attrs: {
      "data-cy": "list",
      search: _vm.tablesearch,
      loading: _vm.processing,
      "loading-text": _vm.$t("common.table-loading-message"),
      "items-per-page": 100,
      "footer-props": { "items-per-page-options": [5, 25, 50, 100, -1] },
      items: _vm.items,
      "mobile-breakpoint": "0",
      "sort-by": ["serialNumber", "name"]
    },
    scopedSlots: _vm._u([
      {
        key: "item.contractorName",
        fn: function({ item }) {
          return [
            !!item.contractorName
              ? _c("fd-value-display", {
                  attrs: { value: item.contractorName }
                })
              : _c("fd-value-display", {
                  attrs: { value: _vm.$lookup.contractor(item.contractorName) }
                })
          ]
        }
      },
      {
        key: "footer.prepend",
        fn: function() {
          return [
            _c("fd-archived-data-loader", {
              staticClass: "ml-2 mr-3",
              attrs: {
                showArchived: _vm.showArchivedValue,
                showArchivedDateRange: _vm.showArchivedDateRange,
                showArchivedMinDate: _vm.showArchivedMinDate,
                showArchivedMaxDate: _vm.showArchivedMaxDate,
                loading: _vm.archivedLoading,
                disabled: _vm.processing
              },
              on: {
                "update:showArchived": function($event) {
                  _vm.showArchivedValue = $event
                },
                "update:show-archived": function($event) {
                  _vm.showArchivedValue = $event
                },
                "update:showArchivedDateRange": v =>
                  _vm.$emit("update:showArchivedDateRange", v)
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "item.fd-nav",
        fn: function() {
          return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
        },
        proxy: true
      },
      {
        key: "item.archived",
        fn: function({ item }) {
          return [
            _vm.showArchived
              ? _c(
                  "div",
                  [
                    _c("v-simple-checkbox", {
                      attrs: { value: item.archived, disabled: _vm.processing },
                      on: {
                        input: function($event) {
                          return _vm.$emit("flipArchived", item)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "item.actions",
        fn: function({ item }) {
          return [
            _c(
              "div",
              { staticClass: "table-2-actions-column-min-width" },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      icon: "",
                                      link: "",
                                      disabled: _vm.processing,
                                      to: `/equipment/${item.id}`
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      "\n              mdi-pencil\n            "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      disabled:
                        !_vm.$vuetify.breakpoint.lgAndUp || _vm.processing,
                      top: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      icon: "",
                                      loading: _vm.deleting,
                                      disabled: _vm.processing
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "deleteTableItem",
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              mdi-delete\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.remove")))])
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }