var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("timesheets.existing.notes-dialog-title"))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { cols: "12", lg: "8" }
                        },
                        [
                          _c(
                            "v-timeline",
                            { attrs: { dense: "" } },
                            [
                              _c("fd-new-note-timeline-item", {
                                attrs: {
                                  loading: _vm.saving,
                                  disabled: _vm.processing
                                },
                                on: { addNewNote: _vm.addNewNote },
                                model: {
                                  value: _vm.newNoteText,
                                  callback: function($$v) {
                                    _vm.newNoteText = $$v
                                  },
                                  expression: "newNoteText"
                                }
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.notes, function(item, i) {
                                return _c("fd-history-note-timeline-item", {
                                  key: i,
                                  attrs: { note: item }
                                })
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.processing,
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }