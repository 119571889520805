var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        width: "1800px",
        persistent: "",
        "data-cy": "foremantimesheetdialog"
      },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content" },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("timesheets.existing.timesheet-dialog-title", [
                        _vm.formattedDay
                      ])
                    )
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "ml-0 mr-0 fd-primary-context-qualifier-background",
              attrs: { align: "center" }
            },
            [
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-sm-flex",
                              attrs: {
                                icon: "",
                                fab: "",
                                disabled: _vm.processing
                              },
                              on: { click: _vm.selectPreviousDay }
                            },
                            [_c("v-icon", [_vm._v("fa-circle-caret-left")])],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "grow py-3" },
                        [
                          _c("fd-date-picker", {
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              max: new Date()
                            },
                            model: {
                              value: _vm.selectedDay,
                              callback: function($$v) {
                                _vm.selectedDay = $$v
                              },
                              expression: "selectedDay"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-sm-flex",
                              attrs: {
                                icon: "",
                                fab: "",
                                disabled: _vm.processing
                              },
                              on: { click: _vm.selectNextDay }
                            },
                            [_c("v-icon", [_vm._v("fa-circle-caret-right")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  !!_vm.currentTimesheet && _vm.currentTimesheet.isNew
                    ? _c("fd-alert", {
                        attrs: {
                          dense: true,
                          label: _vm.$vuetify.breakpoint.mdAndUp
                            ? _vm.$t(
                                "timesheets.entries.timesheet-will-be-created-message"
                              )
                            : _vm.$t(
                                "timesheets.entries.timesheet-will-be-created-message-short"
                              ),
                          type: "info"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.processing
            ? _c(
                "v-col",
                { staticClass: "px-0 pb-0", attrs: { cols: "12" } },
                [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("fd-foreman-timesheet-form", {
            attrs: {
              "parent-context": _vm.parentContext,
              timesheet: _vm.currentTimesheet,
              "read-only": _vm.isReadonly,
              loading: _vm.processing,
              "is-in-dialog": true
            },
            on: {
              "click:add-crew": _vm.addNewCrew,
              "click:edit-crew": _vm.editCrew,
              "click:delete-crew": _vm.deleteCrew,
              "click:add-fleet": _vm.addNewFleet,
              "click:edit-fleet": _vm.editFleet,
              "click:delete-fleet": _vm.deleteFleet
            }
          }),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isReadonly
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mx-0", attrs: { cols: "12" } },
                    [
                      _vm.timesheetIsSubmitted
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-submitted"
                              )
                            }
                          })
                        : _vm.timesheetIsApproved
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-approved"
                              )
                            }
                          })
                        : _vm.timesheetIsCancelled
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-cancelled"
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    "data-cy": "submit",
                    color: "primary",
                    disabled: _vm.processing || !_vm.canSubmit,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.submitDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-and-submit")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-4 mr-3 fd-button-context-separator" },
                [_vm._v("|")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save-draft",
                    color: "primary",
                    disabled: _vm.processing || !_vm.canSave,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-and-close")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }