import { PropType } from "vue";
import FDVue from "@fd/lib/vue";
import { TimesheetRow, TimesheetRowTimeValues } from "../../utils/timesheetrow";
import { WorkSubType } from "../../services";
import { formatWorkOrderNumber } from "../../utils/workorder";

export default FDVue.extend({
  name: "sp-work-sub-type-hours-entry",
  components: {
    "sp-timesheet-time-display": () => import("./SP.TimesheetTimeDisplay.vue")
  },
  props: {
    value: { type: String, default: undefined },
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "sp-work-sub-type-hours-entry" },
    processing: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    row: { type: Object as PropType<TimesheetRow> },
    workSubType: { type: Object as PropType<WorkSubType>, default: () => ({} as WorkSubType) },
    workOrderNumber: { type: String, default: undefined },
    childSubTypes: { type: Array as PropType<Array<WorkSubType>>, default: () => [] }
  },
  data: function() {
    return {
      menuOpen: false
    };
  },
  computed: {
    title(): string {
      let workSubTypeName = this.workSubType?.name ?? "";
      let formattedWorkOrderNumber = formatWorkOrderNumber(this.workOrderNumber);
      if (!workSubTypeName.length) return formattedWorkOrderNumber;
      if (!formattedWorkOrderNumber.length) return workSubTypeName;
      return workSubTypeName.concat(" - ", formattedWorkOrderNumber);
    },
    activatorRef(): string {
      return `act_${this.workSubType.id}`;
    }
  },
  watch: {
    menuOpen(newValue, oldValue) {
      console.log(`menuOpen changed ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;

      if (!oldValue && !!newValue) {
        this.$nextTick(() => {
          console.log(`\t menuOpen nextTick`);
        });
      }
    }
  },
  methods: {
    //#region Times
    getTimesForWorkSubType(
      workSubTypeID: string | null | undefined
    ): TimesheetRowTimeValues | undefined {
      if (!workSubTypeID) return undefined;
      return this.row.times[workSubTypeID];
    },
    getTimeValueForWorkSubType(workSubTypeID: string | null | undefined) {
      return this.getTimesForWorkSubType(workSubTypeID)?.summaryString ?? "0.00";
    },
    workSubTypeHoursValueChanged(workSubTypeID: string | null | undefined, val: any) {
      this.$emit("change:hours", workSubTypeID, val);
    },
    //#endregion
    focus() {
      (this.$refs[this.activatorRef] as HTMLElement).focus();
    },
    activatorClicked(e: Event, handlers: any, menuValue: any) {
      if (this.disabled || this.readonly) {
        return;
      }
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      return;
    },
    activatorFocusOut() {
      console.log(`activatorFocusOut`);
    },
    activatorFocused(e: FocusEvent, handlers: any, menuValue: any) {
      console.log(`activatorFocused`);
      (this.$refs[this.activatorRef] as HTMLElement).blur();
      this.menuOpen = true;
    },
    activatorBlurred() {},
    textFieldFocusOut(idx: number) {
      console.log(`textFieldFocusOut idx: ${idx}`);
      if (idx > 0 && idx < this.childSubTypes.length - 1) return;
    },
    textFieldFocused(idx: number) {
      console.log(`textFieldFocused idx: ${idx}`);
      // (field as HTMLInputElement)?.select();
    },
    textFieldBlur(e: FocusEvent, idx: number) {
      console.log(`textFieldBlur idx: ${idx}`);
    },
    lastFieldLostFocus(e: FocusEvent) {},
    tabKeyDown(e: KeyboardEvent, idx: number) {
      console.log("tabKeyDown");
      if (idx > 0 && idx < this.childSubTypes.length - 1) return;
      if (e.key != "Tab") return;

      if (idx == 0 && !!e.shiftKey) {
        // Going backwards from first field
        this.$emit("navigate:back", this.workSubType.id);
        this.$nextTick(() => {
          this.menuOpen = false;
        });
      } else if (idx == this.childSubTypes.length - 1 && !e.shiftKey) {
        // Going forwards from last field
        this.$emit("navigate:forward", this.workSubType.id);
        this.$nextTick(() => {
          this.menuOpen = false;
        });
      }
    },

    // *** INLINE NAVIGATION ***
    //#region Text Field Navigation
    getFieldRef(itemIndex: number) {
      let wstID = this.workSubType.id!.replace(/-/g, "");
      return `${wstID}_${itemIndex}`;
    },
    focusFieldAtIndex(currentItemIndex: number, newIndex: number) {
      console.log(`focusFieldAtIndex index: ${newIndex}`);
      let itemsLength = this.childSubTypes.length;
      if (!itemsLength) return;

      if (newIndex < 0) newIndex = 0;
      if (newIndex >= itemsLength) {
        newIndex = itemsLength - 1;
        console.log(`\t newIndex: ${newIndex}`);
      }

      let itemFieldRef = this.getFieldRef(currentItemIndex);
      console.log(`\t itemFieldRef: ${itemFieldRef}`);
      let itemField = this.$refs[itemFieldRef] as any;
      console.log(
        `\t itemField: ${itemField}, length: ${itemField["length"]}, type: ${typeof itemField}`
      );
      if (!!itemField["length"]) {
        itemField = itemField[0];
        console.log(
          `\t\t itemField: ${itemField}, length: ${itemField["length"]}, type: ${typeof itemField}`
        );
      }
      this.$nextTick(() => {
        console.log(
          `\t nexttick focus itemField: ${itemField}, el: ${itemField.$el}, focus: ${itemField?.focus}`
        );
        itemField?.focus();
      });
    },
    async selectPreviousField(e: Event, currentItemIndex: number) {
      e.preventDefault();
      console.log(`selectPreviousField currentItemIndex: ${currentItemIndex}`);
      if (currentItemIndex <= 0) {
        return;
      }
      this.focusFieldAtIndex(currentItemIndex, currentItemIndex - 1);
    },
    async selectNextField(e: Event, currentItemIndex: number) {
      e.preventDefault();
      console.log(`selectNextField currentItemIndex: ${currentItemIndex}`);
      if (currentItemIndex >= this.childSubTypes.length - 1) {
        return;
      }
      this.focusFieldAtIndex(currentItemIndex, currentItemIndex + 1);
    },
    async enterPressed(e: KeyboardEvent, currentItemIndex: number) {
      if (e.shiftKey) await this.selectPreviousField(e, currentItemIndex);
      else await this.selectNextField(e, currentItemIndex);
    }
    //#endregion
  },
  mounted: async () => {}
});
