var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "addform", on: { submit: _vm.addFormOnSubmit } },
        [
          _c(
            "v-container",
            {
              staticClass: "pt-0",
              class: {
                "d-none":
                  !!_vm.currentTimesheet &&
                  !!_vm.currentTimesheet.id &&
                  _vm.currentTimesheetIsReadonly &&
                  !_vm.makeCorrections
              },
              attrs: { fluid: "" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-0 pt-3 pb-2" },
                [
                  !_vm.currentTimesheetIsReadonly
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-0 pt-2",
                          attrs: {
                            cols: "12",
                            sm: "6",
                            md: "6",
                            lg: "6",
                            xl: "3"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              class: {
                                "d-none": _vm.currentTimesheetIsReadonly
                              },
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c("v-autocomplete", {
                                staticClass: "fd-select-with-context-actions",
                                attrs: {
                                  "data-cy": "fleets",
                                  dense: "",
                                  outlined: "",
                                  items: _vm.selectableFleets,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: _vm.$t(
                                    "timesheets.entries.fleet-label"
                                  ),
                                  clearable: "",
                                  disabled:
                                    _vm.isProcessing ||
                                    !!_vm.selectedEquipmentID ||
                                    (_vm.currentTimesheetIsReadonly &&
                                      !_vm.makeCorrections),
                                  "hide-details": "",
                                  rules: !!_vm.selectedEquipmentID
                                    ? []
                                    : [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.selectedFleetID,
                                  callback: function($$v) {
                                    _vm.selectedFleetID = $$v
                                  },
                                  expression: "selectedFleetID"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                {
                                  staticClass: "fd-select-context-actions",
                                  attrs: { dark: false, flat: true }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.$vuetify.breakpoint.lgAndUp ||
                                          _vm.isProcessing,
                                        top: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on: toolTipOn }) {
                                              return [
                                                !!_vm.$listeners[
                                                  "click:add-fleet"
                                                ]
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: "new",
                                                            fab: "",
                                                            elevation: "0",
                                                            "x-small": "",
                                                            dark: "",
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              (_vm.currentTimesheetIsReadonly &&
                                                                !_vm.makeCorrections)
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "click:add-fleet",
                                                                _vm.selectedFleet
                                                              )
                                                            }
                                                          }
                                                        },
                                                        toolTipOn
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-plus")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1723089518
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "timesheets.entries.add-resource-group-label"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.$vuetify.breakpoint.lgAndUp ||
                                          _vm.isProcessing,
                                        top: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on }) {
                                              return [
                                                !!_vm.$listeners[
                                                  "click:edit-fleet"
                                                ]
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              (_vm.currentTimesheetIsReadonly &&
                                                                !_vm.makeCorrections) ||
                                                              !_vm.canModifySelectedFleet
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "click:edit-fleet",
                                                                _vm.selectedFleet
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-pencil")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2237847804
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "timesheets.entries.edit-fleet-label"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.$vuetify.breakpoint.lgAndUp ||
                                          _vm.isProcessing,
                                        top: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on }) {
                                              return [
                                                !!_vm.$listeners[
                                                  "click:delete-fleet"
                                                ] &&
                                                _vm.$vuetify.breakpoint.mdAndUp
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              (_vm.currentTimesheetIsReadonly &&
                                                                !_vm.makeCorrections) ||
                                                              !_vm.canModifySelectedFleet
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "click:delete-fleet",
                                                                _vm.selectedFleet
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-delete")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2966251372
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "timesheets.entries.delete-fleet-label"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "align-center justify-space-between pb-0 pt-2 grow"
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "data-cy": "equipment",
                          dense: "",
                          outlined: "",
                          items: _vm.selectableEquipment,
                          "item-text": "nameWithCode",
                          "item-value": "id",
                          label: _vm.$t("timesheets.entries.equipment-label"),
                          clearable: "",
                          disabled:
                            _vm.isProcessing ||
                            (_vm.currentTimesheetIsReadonly &&
                              !_vm.makeCorrections),
                          "hide-details": "",
                          rules: !!_vm.selectedFleetID
                            ? []
                            : [_vm.rules.required]
                        },
                        model: {
                          value: _vm.selectedEquipmentID,
                          callback: function($$v) {
                            _vm.selectedEquipmentID = $$v
                          },
                          expression: "selectedEquipmentID"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pb-0 pt-2 shrink",
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { height: "40px !important" },
                          attrs: {
                            color: "primary",
                            large: "",
                            loading: _vm.isProcessing,
                            disabled:
                              _vm.isProcessing ||
                              (false && !_vm.selectedEquipmentID) ||
                              (false &&
                                _vm.currentTimesheetIsReadonly &&
                                !_vm.makeCorrections),
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              return _vm.addTimesheetRows(_vm.makeCorrections)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(
                              "\n              fa-circle-arrow-down\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.makeCorrections
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$vuetify.breakpoint.smAndDown
                                      ? _vm.$t(
                                          "timesheets.entries.add-corrections-label-short"
                                        )
                                      : _vm.$t(
                                          "timesheets.entries.add-corrections-label"
                                        )
                                  )
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$vuetify.breakpoint.smAndDown
                                      ? _vm.$t(
                                          "timesheets.entries.add-label-short"
                                        )
                                      : _vm.$t("timesheets.entries.add-label")
                                  )
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "px-3 py-0", attrs: { cols: "12" } },
            [
              _vm.timesheetIsDeclined
                ? _c("fd-alert", {
                    attrs: {
                      type: "warning",
                      label: !!_vm.timesheetDeclineComments
                        ? _vm.$t("timesheets.declined-with-reason")
                        : _vm.$t("timesheets.declined"),
                      comments: _vm.timesheetDeclineComments
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticStyle: { position: "relative" }, attrs: { "no-gutters": "" } },
        [
          _c("v-spacer"),
          _vm._v(" "),
          !!_vm.timesheet
            ? _c("v-switch", {
                staticClass: "pr-3",
                staticStyle: {
                  position: "absolute",
                  right: "4px",
                  top: "10px",
                  "z-index": "2"
                },
                attrs: {
                  "data-cy": "isnightshiftswitch",
                  label: _vm.$vuetify.breakpoint.smAndUp
                    ? _vm.$t("timesheets.is-night-shift-label")
                    : _vm.$t("timesheets.is-night-shift-label-short"),
                  disabled: _vm.isProcessing || _vm.currentTimesheetIsReadonly
                },
                model: {
                  value: _vm.timesheet.isNightShift,
                  callback: function($$v) {
                    _vm.$set(_vm.timesheet, "isNightShift", $$v)
                  },
                  expression: "timesheet.isNightShift"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "timesheetform", on: { submit: _vm.preventSubmit } },
        [
          _c(
            "v-tabs",
            {
              ref: "tabs",
              class: _vm.currentTimesheetIsReadonly ? "mt-3" : "mt-0",
              attrs: {
                "icons-and-text": "",
                "show-arrows": !_vm.$vuetify.breakpoint.xsOnly,
                "next-icon": "fa-arrow-circle-right",
                "prev-icon": "fa-arrow-circle-left"
              },
              model: {
                value: _vm.active_tab,
                callback: function($$v) {
                  _vm.active_tab = $$v
                },
                expression: "active_tab"
              }
            },
            [
              _c(
                "v-tab",
                {
                  key: _vm.workspaceTab.key,
                  ref: "tab",
                  attrs: {
                    "data-cy": "workspace",
                    href: `#tab-${_vm.workspaceTab.key}`
                  },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.workspaceTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.workspaceTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-edit")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.summaryTab.key,
                  attrs: {
                    "data-cy": "summary",
                    href: `#tab-${_vm.summaryTab.key}`
                  },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.summaryTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.summaryTab.tabname) + "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-sigma")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              attrs: { touchless: "" },
              model: {
                value: _vm.active_tab,
                callback: function($$v) {
                  _vm.active_tab = $$v
                },
                expression: "active_tab"
              }
            },
            [
              _c(
                "v-tab-item",
                {
                  key: _vm.workspaceTab.key,
                  attrs: { value: `tab-${_vm.workspaceTab.key}` }
                },
                [
                  !_vm.allTimesheetRows.length
                    ? _c("fd-alert", {
                        class: "mx-3",
                        attrs: {
                          label: _vm.$t(
                            "timesheets.existing.no-workspace-rows"
                          ),
                          type: "info",
                          color: "primary"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { accordion: "" },
                      model: {
                        value: _vm.panel,
                        callback: function($$v) {
                          _vm.panel = $$v
                        },
                        expression: "panel"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        {
                          staticClass: "secondary-section-style",
                          class: { "d-none": !_vm.hasEquipmentRows },
                          attrs: { disabled: _vm.isProcessing }
                        },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(_vm._s(_vm.equipmentTableHeader))
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-container",
                                { staticClass: "pa-0", attrs: { fluid: "" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-data-table", {
                                        ref: "equipmentdatatable",
                                        staticClass:
                                          "fd-timesheet-entries-table fixed-header v-table__overflow",
                                        staticStyle: {
                                          "backface-visibility": "hidden"
                                        },
                                        attrs: {
                                          loading: _vm.isProcessing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0",
                                          "item-class":
                                            _vm.timesheetRowClassName,
                                          "items-per-page": -1,
                                          "footer-props": {
                                            "items-per-page-options": [-1]
                                          },
                                          "hide-default-footer": "",
                                          items: _vm.equipmentTimesheetRows,
                                          headers: _vm.equipmentTableHeaders,
                                          "disable-sort": ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "header.empty",
                                            fn: function() {
                                              return [
                                                !!_vm.currentTimesheet &&
                                                !!_vm.currentTimesheet
                                                  .timesheetRows &&
                                                _vm.currentTimesheet
                                                  .timesheetRows.length > 0
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.isProcessing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              tile:
                                                                                "",
                                                                              color:
                                                                                "primary"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.toggleTableGroups(
                                                                                  "equipment",
                                                                                  _vm.allGroupsExpanded(
                                                                                    "equipment"
                                                                                  )
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              domProps: {
                                                                                textContent: _vm._s(
                                                                                  !_vm.allGroupsExpanded(
                                                                                    "equipment"
                                                                                  )
                                                                                    ? "mdi-plus-box-multiple-outline"
                                                                                    : "mdi-minus-box-multiple-outline"
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              2904428141
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  !_vm.allGroupsExpanded(
                                                                    "equipment"
                                                                  )
                                                                    ? _vm.$t(
                                                                        "common.expand-all"
                                                                      )
                                                                    : _vm.$t(
                                                                        "common.collapse-all"
                                                                      )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.empty",
                                            fn: function({ item }) {
                                              return [
                                                item.isCorrectionRow
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          "x-small":
                                                            _vm.$vuetify
                                                              .breakpoint.sm,
                                                          small:
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fas fa-highlighter-line"
                                                        )
                                                      ]
                                                    )
                                                  : _c("div", [_vm._v(" ")])
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.icon",
                                            fn: function({ item }) {
                                              return [
                                                item.isCorrectionRow
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          "x-small":
                                                            _vm.$vuetify
                                                              .breakpoint.sm,
                                                          small:
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fas fa-highlighter-line"
                                                        )
                                                      ]
                                                    )
                                                  : _c("div", [_vm._v(" ")])
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.employeeCode",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.employeeCode,
                                                    "no-value-text": _vm.$t(
                                                      "common.not-available"
                                                    )
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key:
                                              "item.classificationDisplayName",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value:
                                                      item.classificationDisplayName
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key:
                                              "item.equipmentCostCodeDisplayName",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value:
                                                      item.equipmentCostCodeDisplayName,
                                                    "no-value-text": _vm.$t(
                                                      "common.not-available"
                                                    )
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.equipmentDays",
                                            fn: function({ item }) {
                                              return [
                                                !_vm.canEditEquipment(item)
                                                  ? _c("fd-value-display", {
                                                      attrs: {
                                                        value: _vm.$format.number(
                                                          item.equipmentDays
                                                        ),
                                                        "no-value-key":
                                                          "timesheets.list.no-time-entry-value",
                                                        "no-value-style":
                                                          "text-align: center;"
                                                      }
                                                    })
                                                  : _c("fd-text-field", {
                                                      ref: _vm.getFieldRef(
                                                        "equipment",
                                                        "days",
                                                        item
                                                      ),
                                                      staticClass:
                                                        "fd-short-table-input",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isProcessing ||
                                                          !_vm.rowIsEditable(
                                                            item
                                                          ),
                                                        numeric: "",
                                                        outlined: false,
                                                        "highlight-on-focus":
                                                          "",
                                                        rules: _vm.timesheetRowRules(
                                                          item
                                                        ),
                                                        type: "number",
                                                        "hide-spin-buttons": ""
                                                      },
                                                      on: {
                                                        keydown: [
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "up",
                                                                38,
                                                                $event.key,
                                                                [
                                                                  "Up",
                                                                  "ArrowUp"
                                                                ]
                                                              )
                                                            )
                                                              return null
                                                            return (e =>
                                                              _vm.selectPreviousField(
                                                                e,
                                                                "equipment",
                                                                "days",
                                                                item
                                                              )).apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "down",
                                                                40,
                                                                $event.key,
                                                                [
                                                                  "Down",
                                                                  "ArrowDown"
                                                                ]
                                                              )
                                                            )
                                                              return null
                                                            return (e =>
                                                              _vm.selectNextField(
                                                                e,
                                                                "equipment",
                                                                "days",
                                                                item
                                                              )).apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return (e =>
                                                              _vm.enterPressed(
                                                                e,
                                                                "equipment",
                                                                "days",
                                                                item
                                                              )).apply(
                                                              null,
                                                              arguments
                                                            )
                                                          }
                                                        ]
                                                      },
                                                      model: {
                                                        value:
                                                          item.equipmentDays,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "equipmentDays",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.equipmentDays"
                                                      }
                                                    })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.equipmentQuantity",
                                            fn: function({ item }) {
                                              return [
                                                !_vm.canEditEquipment(item)
                                                  ? _c("fd-value-display", {
                                                      attrs: {
                                                        value: _vm.$format.number(
                                                          item.equipmentQuantity
                                                        ),
                                                        "no-value-key":
                                                          "timesheets.list.no-time-entry-value",
                                                        "no-value-style":
                                                          "text-align: center;"
                                                      }
                                                    })
                                                  : _c("fd-text-field", {
                                                      ref: _vm.getFieldRef(
                                                        "equipment",
                                                        "quantity",
                                                        item
                                                      ),
                                                      staticClass:
                                                        "fd-short-table-input",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isProcessing ||
                                                          !_vm.rowIsEditable(
                                                            item
                                                          ),
                                                        numeric: "",
                                                        outlined: false,
                                                        "highlight-on-focus":
                                                          "",
                                                        rules: _vm.timesheetRowRules(
                                                          item
                                                        ),
                                                        type: "number",
                                                        "hide-spin-buttons": ""
                                                      },
                                                      on: {
                                                        keydown: [
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "up",
                                                                38,
                                                                $event.key,
                                                                [
                                                                  "Up",
                                                                  "ArrowUp"
                                                                ]
                                                              )
                                                            )
                                                              return null
                                                            return (e =>
                                                              _vm.selectPreviousField(
                                                                e,
                                                                "equipment",
                                                                "quantity",
                                                                item
                                                              )).apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "down",
                                                                40,
                                                                $event.key,
                                                                [
                                                                  "Down",
                                                                  "ArrowDown"
                                                                ]
                                                              )
                                                            )
                                                              return null
                                                            return (e =>
                                                              _vm.selectNextField(
                                                                e,
                                                                "equipment",
                                                                "quantity",
                                                                item
                                                              )).apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return (e =>
                                                              _vm.enterPressed(
                                                                e,
                                                                "equipment",
                                                                "quantity",
                                                                item
                                                              )).apply(
                                                              null,
                                                              arguments
                                                            )
                                                          }
                                                        ]
                                                      },
                                                      model: {
                                                        value:
                                                          item.equipmentQuantity,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "equipmentQuantity",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.equipmentQuantity"
                                                      }
                                                    })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.total",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fd-table-column-text-end-override",
                                                    staticStyle: {
                                                      position: "relative"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-1",
                                                                        class: {
                                                                          "d-none": !item
                                                                            .errorMessage
                                                                            .length
                                                                        },
                                                                        staticStyle: {
                                                                          position:
                                                                            "absolute",
                                                                          left:
                                                                            "-15px"
                                                                        },
                                                                        attrs: {
                                                                          color:
                                                                            "error",
                                                                          dark:
                                                                            "",
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              info\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.errorMessage
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "sp-timesheet-time-display",
                                                      {
                                                        attrs: {
                                                          times: _vm.calculateTotalForItem(
                                                            item
                                                          ),
                                                          "display-zero-value":
                                                            ""
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "body.prepend",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "tr",
                                                  {
                                                    staticClass:
                                                      "fd-table-header-row"
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "fd-table-group-first-column fd-table-frozen-column",
                                                        attrs: { colspan: "4" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                           "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "1" }
                                                      },
                                                      [
                                                        _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-short-table-input",
                                                          attrs: {
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              _vm.currentTimesheetIsReadonly,
                                                            numeric: "",
                                                            outlined: false,
                                                            "highlight-on-focus":
                                                              "",
                                                            type: "number",
                                                            "hide-spin-buttons":
                                                              ""
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.updateAllEquipmentDayValues
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "1" }
                                                      },
                                                      [
                                                        _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-short-table-input",
                                                          attrs: {
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              _vm.currentTimesheetIsReadonly,
                                                            numeric: "",
                                                            outlined: false,
                                                            "highlight-on-focus":
                                                              "",
                                                            type: "number",
                                                            "hide-spin-buttons":
                                                              ""
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.updateAllEquipmentQuantityValues
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      { attrs: { colspan: 2 } },
                                                      [_vm._v(" ")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "body.append",
                                            fn: function({ items }) {
                                              return [
                                                _c(
                                                  "tr",
                                                  {
                                                    staticClass:
                                                      "fd-table-summary-row"
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-start fd-table-frozen-column fd-table-summary-row-first-column",
                                                        attrs: { colspan: "4" }
                                                      },
                                                      [
                                                        _c("fd-alert", {
                                                          staticClass:
                                                            "pa-0 mt-0",
                                                          attrs: {
                                                            dense: true,
                                                            label: _vm.$t(
                                                              "timesheets.existing.group-totals-label"
                                                            ),
                                                            type: "hint",
                                                            white: ""
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "1" }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.sumEquipmentDays(
                                                              items
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value",
                                                            "no-value-class":
                                                              "white--text"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "1" }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.sumEquipmentQuantity(
                                                              items
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value",
                                                            "no-value-class":
                                                              "white--text"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                                        attrs: { colspan: 1 }
                                                      },
                                                      [
                                                        _c(
                                                          "sp-timesheet-time-display",
                                                          {
                                                            attrs: {
                                                              times: _vm.calculateTotalForItems(
                                                                items
                                                              ),
                                                              "no-value-key":
                                                                "timesheets.list.no-time-value",
                                                              "no-value-class":
                                                                "white--text"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      { attrs: { colspan: 1 } },
                                                      [_vm._v(" ")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-2-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.isProcessing,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-badge",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "red",
                                                                        dot: "",
                                                                        value: !!item.notesCount
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing ||
                                                                                !_vm.rowIsEditable(
                                                                                  item
                                                                                )
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openNotesDialog(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-note\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.notes"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.isProcessing,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.isProcessing ||
                                                                            !_vm.rowIsEditable(
                                                                              item
                                                                            )
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeTimesheetRow(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.summaryTab.key,
                  attrs: { value: `tab-${_vm.summaryTab.key}` }
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "div",
                        [
                          _c("v-data-table", {
                            ref: "summarydatatable",
                            staticClass:
                              "fd-timesheet-entries-table fixed-header v-table__overflow",
                            staticStyle: { "backface-visibility": "hidden" },
                            attrs: {
                              loading: _vm.isProcessing,
                              "loading-text": _vm.$t(
                                "common.table-loading-message"
                              ),
                              "mobile-breakpoint": "0",
                              "item-class": _vm.timesheetRowClassName,
                              "items-per-page": -1,
                              "footer-props": {
                                "items-per-page-options": [-1]
                              },
                              "hide-default-footer": "",
                              "group-by": "employeeName",
                              items: _vm.allTimesheetRows,
                              headers: _vm.summaryTableHeaders,
                              "disable-sort": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "header.empty",
                                fn: function() {
                                  return [
                                    !!_vm.currentTimesheet &&
                                    !!_vm.currentTimesheet.timesheetRows &&
                                    _vm.currentTimesheet.timesheetRows.length >
                                      0
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  disabled:
                                                    !_vm.$vuetify.breakpoint
                                                      .lgAndUp ||
                                                    _vm.isProcessing,
                                                  left: ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  tile: "",
                                                                  color:
                                                                    "primary"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.toggleTableGroups(
                                                                      "summary",
                                                                      _vm.allGroupsExpanded(
                                                                        "summary"
                                                                      )
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", {
                                                                domProps: {
                                                                  textContent: _vm._s(
                                                                    !_vm.allGroupsExpanded(
                                                                      "summary"
                                                                    )
                                                                      ? "mdi-plus-box-multiple-outline"
                                                                      : "mdi-minus-box-multiple-outline"
                                                                  )
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1436580683
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !_vm.allGroupsExpanded(
                                                        "summary"
                                                      )
                                                        ? _vm.$t(
                                                            "common.expand-all"
                                                          )
                                                        : _vm.$t(
                                                            "common.collapse-all"
                                                          )
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "item.empty",
                                fn: function({ item }) {
                                  return [
                                    item.isCorrectionRow
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              "x-small":
                                                _vm.$vuetify.breakpoint.sm,
                                              small:
                                                _vm.$vuetify.breakpoint.mdAndUp
                                            }
                                          },
                                          [_vm._v("fas fa-highlighter-line")]
                                        )
                                      : _c("div", [_vm._v(" ")])
                                  ]
                                }
                              },
                              {
                                key: "item.classificationDisplayName",
                                fn: function({ item }) {
                                  return [
                                    _c("fd-value-display", {
                                      attrs: {
                                        value: item.classificationDisplayName
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.equipmentCostCodeDisplayName",
                                fn: function({ item }) {
                                  return [
                                    _c("fd-value-display", {
                                      attrs: {
                                        value:
                                          item.equipmentCostCodeDisplayName,
                                        "no-value-text": _vm.$t(
                                          "common.not-available"
                                        )
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "group.header",
                                fn: function({ group, items, isOpen, toggle }) {
                                  return [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "fd-table-group-first-column fd-table-frozen-column",
                                        attrs: {
                                          colspan: _vm.currentTimesheetIsEquipment
                                            ? 3
                                            : 6
                                        }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            ref: `summarygrouptoggle${group}`,
                                            attrs: { icon: "" },
                                            on: { click: toggle }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  isOpen
                                                    ? "mdi-minus"
                                                    : "mdi-plus"
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "timesheets.existing.person-group-label",
                                                [group]
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.currentTimesheetIsEquipment
                                      ? _c(
                                          "td",
                                          { attrs: { colspan: "1" } },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sumEquipmentDays(
                                                  items
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.currentTimesheetIsEquipment
                                      ? _c(
                                          "td",
                                          { attrs: { colspan: "1" } },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sumEquipmentQuantity(
                                                  items
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                        attrs: { colspan: 1 }
                                      },
                                      [
                                        _c("sp-timesheet-time-display", {
                                          attrs: {
                                            times: _vm.calculateTotalForItems(
                                              items
                                            ),
                                            "no-value-key":
                                              "timesheets.list.no-time-value"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", { attrs: { colspan: 1 } }, [
                                      _vm._v(" ")
                                    ])
                                  ]
                                }
                              },
                              {
                                key: "item.equipmentDays",
                                fn: function({ item }) {
                                  return [
                                    !_vm.canEditEquipment(item)
                                      ? _c("fd-value-display", {
                                          attrs: {
                                            value: _vm.$format.number(
                                              item.equipmentDays
                                            ),
                                            "no-value-key":
                                              "timesheets.list.no-time-entry-value",
                                            "no-value-style":
                                              "text-align: center;"
                                          }
                                        })
                                      : _c("fd-text-field", {
                                          ref: _vm.getFieldRef(
                                            "summary",
                                            "equipmentDays",
                                            item
                                          ),
                                          staticClass: "fd-short-table-input",
                                          attrs: {
                                            disabled:
                                              _vm.isProcessing ||
                                              !_vm.rowIsEditable(item),
                                            numeric: "",
                                            outlined: false,
                                            "highlight-on-focus": "",
                                            rules: _vm.timesheetRowRules(item),
                                            type: "number",
                                            "hide-spin-buttons": ""
                                          },
                                          on: {
                                            keydown: [
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "up",
                                                    38,
                                                    $event.key,
                                                    ["Up", "ArrowUp"]
                                                  )
                                                )
                                                  return null
                                                return (e =>
                                                  _vm.selectPreviousField(
                                                    e,
                                                    "summary",
                                                    "equipmentDays",
                                                    item
                                                  )).apply(null, arguments)
                                              },
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "down",
                                                    40,
                                                    $event.key,
                                                    ["Down", "ArrowDown"]
                                                  )
                                                )
                                                  return null
                                                return (e =>
                                                  _vm.selectNextField(
                                                    e,
                                                    "summary",
                                                    "equipmentDays",
                                                    item
                                                  )).apply(null, arguments)
                                              },
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return (e =>
                                                  _vm.enterPressed(
                                                    e,
                                                    "summary",
                                                    "equipmentDays",
                                                    item
                                                  )).apply(null, arguments)
                                              }
                                            ]
                                          },
                                          model: {
                                            value: item.equipmentDays,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "equipmentDays",
                                                $$v
                                              )
                                            },
                                            expression: "item.equipmentDays"
                                          }
                                        })
                                  ]
                                }
                              },
                              {
                                key: "item.equipmentQuantity",
                                fn: function({ item }) {
                                  return [
                                    !_vm.canEditEquipment(item)
                                      ? _c("fd-value-display", {
                                          attrs: {
                                            value: _vm.$format.number(
                                              item.equipmentQuantity
                                            ),
                                            "no-value-key":
                                              "timesheets.list.no-time-entry-value",
                                            "no-value-style":
                                              "text-align: center;"
                                          }
                                        })
                                      : _c("fd-text-field", {
                                          ref: _vm.getFieldRef(
                                            "summary",
                                            "equipmentQuantity",
                                            item
                                          ),
                                          staticClass: "fd-short-table-input",
                                          attrs: {
                                            disabled:
                                              _vm.isProcessing ||
                                              !_vm.rowIsEditable(item),
                                            numeric: "",
                                            outlined: false,
                                            "highlight-on-focus": "",
                                            rules: _vm.timesheetRowRules(item),
                                            type: "number",
                                            "hide-spin-buttons": ""
                                          },
                                          on: {
                                            keydown: [
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "up",
                                                    38,
                                                    $event.key,
                                                    ["Up", "ArrowUp"]
                                                  )
                                                )
                                                  return null
                                                return (e =>
                                                  _vm.selectPreviousField(
                                                    e,
                                                    "summary",
                                                    "equipmentQuantity",
                                                    item
                                                  )).apply(null, arguments)
                                              },
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "down",
                                                    40,
                                                    $event.key,
                                                    ["Down", "ArrowDown"]
                                                  )
                                                )
                                                  return null
                                                return (e =>
                                                  _vm.selectNextField(
                                                    e,
                                                    "summary",
                                                    "equipmentQuantity",
                                                    item
                                                  )).apply(null, arguments)
                                              },
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return (e =>
                                                  _vm.enterPressed(
                                                    e,
                                                    "summary",
                                                    "equipmentQuantity",
                                                    item
                                                  )).apply(null, arguments)
                                              }
                                            ]
                                          },
                                          model: {
                                            value: item.equipmentQuantity,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "equipmentQuantity",
                                                $$v
                                              )
                                            },
                                            expression: "item.equipmentQuantity"
                                          }
                                        })
                                  ]
                                }
                              },
                              {
                                key: "item.total",
                                fn: function({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "fd-table-column-text-end-override",
                                        staticStyle: { position: "relative" }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            class: {
                                                              "d-none": !item
                                                                .errorMessage
                                                                .length
                                                            },
                                                            staticStyle: {
                                                              position:
                                                                "absolute",
                                                              left: "-15px"
                                                            },
                                                            attrs: {
                                                              color: "error",
                                                              dark: "",
                                                              small: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                        info\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(item.errorMessage))
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("sp-timesheet-time-display", {
                                          attrs: {
                                            times: _vm.calculateTotalForItem(
                                              item
                                            ),
                                            "display-zero-value": ""
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "body.append",
                                fn: function({ items }) {
                                  return [
                                    _c(
                                      "tr",
                                      { staticClass: "fd-table-summary-row" },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-start fd-table-frozen-column fd-table-summary-row-first-column",
                                            attrs: {
                                              colspan: _vm.currentTimesheetIsEquipment
                                                ? 3
                                                : 6
                                            }
                                          },
                                          [
                                            _c("fd-alert", {
                                              staticClass: "pa-0 mt-0",
                                              attrs: {
                                                dense: true,
                                                label: _vm.$t(
                                                  "timesheets.existing.grand-total-label"
                                                ),
                                                type: "hint",
                                                white: ""
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.currentTimesheetIsEquipment
                                          ? _c(
                                              "td",
                                              { attrs: { colspan: "1" } },
                                              [
                                                _c("fd-value-display", {
                                                  staticStyle: {
                                                    display: "inline"
                                                  },
                                                  attrs: {
                                                    value: _vm.sumEquipmentDays(
                                                      items
                                                    ),
                                                    "no-value-key":
                                                      "timesheets.list.no-time-value",
                                                    "no-value-class":
                                                      "white--text"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.currentTimesheetIsEquipment
                                          ? _c(
                                              "td",
                                              { attrs: { colspan: "1" } },
                                              [
                                                _c("fd-value-display", {
                                                  staticStyle: {
                                                    display: "inline"
                                                  },
                                                  attrs: {
                                                    value: _vm.sumEquipmentQuantity(
                                                      items
                                                    ),
                                                    "no-value-key":
                                                      "timesheets.list.no-time-value",
                                                    "no-value-class":
                                                      "white--text"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                            attrs: { colspan: 1 }
                                          },
                                          [
                                            _c("sp-timesheet-time-display", {
                                              attrs: {
                                                times: _vm.calculateTotalForItems(
                                                  items
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value",
                                                "no-value-class": "white--text"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: 1 } }, [
                                          _vm._v(" ")
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.actions",
                                fn: function({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-2-actions-column-min-width"
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              disabled:
                                                !_vm.$vuetify.breakpoint
                                                  .lgAndUp || _vm.isProcessing,
                                              top: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-badge",
                                                        {
                                                          attrs: {
                                                            color: "red",
                                                            dot: "",
                                                            value: !!item.notesCount
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    _vm.processing ||
                                                                    !_vm.rowIsEditable(
                                                                      item
                                                                    )
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openNotesDialog(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            mdi-note\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.notes"))
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              disabled:
                                                !_vm.$vuetify.breakpoint
                                                  .lgAndUp || _vm.isProcessing,
                                              left: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              disabled:
                                                                _vm.isProcessing ||
                                                                !_vm.rowIsEditable(
                                                                  item
                                                                )
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeTimesheetRow(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          mdi-delete\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.remove"))
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }