import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { WorkSubType } from "@fd/current/client/services";

const WorkSubTypeNewDialog = FDVue.extend({
  name: "fd-work-sub-type-new-dialog",

  mixins: [dialogSupport, errorHandling, rules],

  components: {},

  data: function() {
    return {
      workSubType: {
        name: ""
      } as WorkSubType
    };
  },

  computed: {},

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        await this.addWorkSubType(this.workSubType);
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addWorkSubType: "ADD_WORK_SUB_TYPE"
    })
  }
});

export default WorkSubTypeNewDialog;

export async function createNewWorkSubType(
  parentWorkTypeID: string,
  existingSubTypeCount: number,
  parentSubTypeID?: string
): Promise<boolean> {
  let dialog = createDialog(WorkSubTypeNewDialog);
  dialog.optOutOfErrorHandling();
  dialog.workSubType.workTypeID = parentWorkTypeID;
  dialog.workSubType.order = existingSubTypeCount + 1;
  dialog.workSubType.parentWorkSubTypeID = parentSubTypeID;
  return await dialog.showDialog!();
}
