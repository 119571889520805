import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { TranslateResult } from "vue-i18n";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  TableHeader
} from "@fd/lib/vue/utility/dataTable";
import {
  ContractorWithTags,
  EquipmentClassification,
  EquipmentForContractor,
  FleetWithEquipment,
  LaborTimesheetRow,
  PersonWithDetails,
  ProjectCostCode,
  TimesheetStatus,
  TimesheetType,
  WorkSubType,
  fleetService,
  reportService
} from "../../../services";
import {
  UpdatableTimesheetWithTimesheetRows,
  UpdateRowCorrections
} from "../../../utils/timesheet";
import {
  ParseWorkSubTypeIDsFromRow,
  SortTimesheetRows,
  TimesheetRow,
  TimesheetRowResourceType,
  TimesheetRowTimeValues,
  TimesheetRowType,
  areTimesheetRowsEqual
} from "../../../utils/timesheetrow";
import userAccess from "../../../dataMixins/userAccess";
import { mapActions, mapMutations } from "vuex";
import { VDataTable } from "@fd/lib/vue/types";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { PropType } from "vue";
import { GetPersonName, HasName, SortItemsWithName } from "../../../utils/person";
import { GroupableSelectListOption, SelectListOption } from "@fd/lib/vue/utility/select";
import { SortFleetEquipment } from "../dialogs/SP.FleetDetailsBottomDialog.vue";
import { localizedDateTimeString } from "../../../../../lib/client-util/datetime";
import downloadBlob from "../../../../../lib/client-util/downloadBlob";
import printBlob from "../../../../../lib/client-util/printBlob";
import HashTable from "../../../utils/hashtable";

type PossibleNumber = number | string | null | undefined;

export default FDVue.extend({
  name: "sp-equipment-timesheet-form",
  inheritAttrs: false,
  mixins: [serviceErrorHandling, rules, tabbedView, userAccess],
  components: {
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "sp-timesheet-time-display": () => import("../SP.TimesheetTimeDisplay.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  props: {
    timesheet: {
      type: Object as PropType<UpdatableTimesheetWithTimesheetRows>,
      default: undefined
    },
    readOnly: { type: Boolean, default: false },
    parentContext: { type: String, default: undefined },
    makeCorrections: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  data: function() {
    return {
      // Variable to hold which expansion panel is currently open
      panel: 0,
      firstTabKey: `0`,
      workspaceTab: {
        tabname: this.$t("timesheets.existing.tabs.workspace"),
        key: "0",
        visible: true
      } as Tab,
      summaryTab: {
        tabname: this.$t("timesheets.existing.tabs.summary"),
        key: "1",
        visible: true
      } as Tab,

      selectedEquipmentID: null as string | null,
      selectedFleetID: null as string | null,
      availableFleets: [] as FleetWithEquipment[]
    };
  },

  computed: {
    isProcessing(): boolean {
      return this.loading || this.processing;
    },
    currentTimesheetHasAnyCorrectionRows(): boolean {
      return (
        !!this.currentTimesheet &&
        this.currentTimesheet.timesheetRows.findIndex(x => !!x.isCorrectionRow) != -1
      );
    },
    canEditCurrentTimesheet(): boolean {
      return this.canEditTimesheet(this.currentTimesheet);
    },
    hasEquipmentRows(): boolean {
      let rows = this.allTimesheetRows.filter(x => x.rowType == TimesheetRowType.Equipment);
      return !!rows?.length;
    },
    equipmentPanelNumber(): number {
      return 0;
    },
    allTimesheetRows(): TimesheetRow[] {
      return this.currentTimesheet?.timesheetRows ?? [];
    },
    equipmentTableHeader(): string {
      return this.$t("timesheets.existing.equipment-table-header")
        .toString()
        .toUpperCase();
    },
    equipmentTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(
        x => !x.workOrderID && x.rowType == TimesheetRowType.Equipment
      );
    },
    tabDefinitions(): Tab[] {
      return [this.summaryTab];
    },
    currentTimesheet(): UpdatableTimesheetWithTimesheetRows | undefined {
      return this.timesheet;
    },
    currentTimesheetIsEquipment(): boolean {
      return (this.currentTimesheet?.timesheetTypeID ?? 0) == TimesheetType.Equipment;
    },
    currentTimesheetIsReadonly(): boolean {
      return this.readOnly;
    },
    canModifySelectedFleet(): boolean {
      if (!this.selectedFleetID) return false;
      return this.selectedFleet?.ownerID == this.curUserID || this.currentUserCanConfigureSettings;
    },
    summaryTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.equipment-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName",
          class: "fd-table-frozen-column",
          cellClass: "fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.equipment-serial-number-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode",
          class: "fd-table-frozen-column fd-summary-employee-number-column",
          cellClass: "fd-table-frozen-column fd-summary-employee-number-column"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-summary-classification-column",
          cellClass: "fd-table-frozen-column fd-summary-classification-column"
        },
        {
          text: this.$t("timesheets.existing.days-column-label"),
          value: "equipmentDays",
          class: "fd-rotate-header-text"
        },
        {
          text: this.$t("timesheets.existing.quantity-column-label"),
          value: "equipmentQuantity",
          class: "fd-rotate-header-text"
        },
        {
          text: this.$t("common.total"),
          value: "total",
          class: "text-end"
        },
        {
          text: this.$t("common.actions"),
          value: "actions",
          class: "fd-actions-cell",
          cellClass: "fd-actions-cell"
        }
      ] as TableHeader[];

      return headers;
    },
    equipmentTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "icon",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.equipment-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName",
          class: "fd-table-between-frozen-columns",
          cellClass: "fd-table-between-frozen-columns"
        },
        {
          text: this.$t("timesheets.existing.equipment-serial-number-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode",
          class: "fd-table-frozen-column fd-equipment-employee-number-column",
          cellClass: "fd-table-frozen-column fd-equipment-employee-number-column"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-equipment-classification-column",
          cellClass: "fd-table-frozen-column fd-equipment-classification-column"
        },
        {
          text: this.$t("timesheets.existing.days-column-label"),
          value: "equipmentDays",
          class: "fd-rotate-header-text"
        },
        {
          text: this.$t("timesheets.existing.quantity-column-label"),
          value: "equipmentQuantity",
          class: "fd-rotate-header-text"
        },
        {
          text: this.$t("common.total"),
          value: "total",
          class: "fd-table-column-text-end-override"
        },
        {
          text: this.$t("common.actions"),
          value: "actions",
          class: "fd-actions-cell",
          cellClass: "fd-actions-cell"
        }
      ] as TableHeader[];

      return headers;
    },
    unwatchedMethodNames(): string[] {
      return [
        "sumRowTimeValues",
        "sumEquipmentDays",
        "sumEquipmentQuantity",
        "canEditTimesheet",
        "getFieldRef",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "calculateTotalForItem",
        "calculateTotalForItems"
      ];
    },
    contractor(): ContractorWithTags | undefined {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.currentTimesheet?.contractorID);
      return contractor;
    },
    selectableEquipment(): (EquipmentForContractor & {
      nameWithCode: string;
      disabled?: boolean;
    })[] {
      let allEquipment = this.$store.state.equipment.fullList as EquipmentForContractor[];
      let selectableEquipmentList = allEquipment.filter(
        x => !!x.contractorID && x.contractorID == this.currentTimesheet?.contractorID
      );
      let sortedSelectableEquipment = SortItemsWithName(
        selectableEquipmentList.map(x => ({
          ...x,
          name: x.name,
          nameWithCode: `${x.name}${!!x.serialNumber ? " (" + x.serialNumber + ")" : ""}`,
          disabled: this.allTimesheetRowsAlreadyExists(this.timesheet.isLocked, x.id, undefined)
        }))
      );

      return sortedSelectableEquipment;
    },
    selectedEquipment(): EquipmentForContractor[] {
      var selectedEquipmentIDs = [] as string[];
      if (!!this.selectedEquipmentID) selectedEquipmentIDs.push(this.selectedEquipmentID);

      let selectedFleet = this.selectedFleet;
      if (!!selectedFleet) {
        selectedEquipmentIDs = selectedEquipmentIDs.concat(
          SortFleetEquipment(selectedFleet.equipment).map(x => x.equipmentID!)
        );
      }

      let selectableEquipment = this.selectableEquipment;
      return selectedEquipmentIDs
        .filter(x => !!selectableEquipment.find(e => e.id! == x))
        .map(x => selectableEquipment.find(e => e.id! == x)!);
    },
    selectableFleets(): GroupableSelectListOption<FleetWithEquipment>[] {
      let selectableFleets = this.availableFleets.map(
        x =>
          ({
            ...x
          } as SelectListOption<FleetWithEquipment>)
      );

      let allOwners = (this.$store.state.users.fullList as PersonWithDetails[]).map(p => ({
        ...p,
        name: GetPersonName(p)
      }));
      let selectableFleetsByOwnerName = selectableFleets.reduce((a, b) => {
        let ownerName = allOwners.find(p => p.id == b.ownerID)?.name ?? "";
        // If the fleet has an owner but the owner name is not filled in
        // This means the current user doesn't have access to the owner's info (or the owner is archived)
        if (!!ownerName?.length || !b.ownerID?.length) {
          let existingFleets = a[ownerName] ?? [];
          existingFleets.push(b);
          a[ownerName] = existingFleets;
        }
        return a;
      }, {} as HashTable<SelectListOption<FleetWithEquipment>[]>);

      var curUserName = allOwners.find(p => p.id == this.curUserID)?.name ?? this.curUserID;
      let myFleets = SortItemsWithName(selectableFleetsByOwnerName[curUserName]);
      let unownedFleets = SortItemsWithName(selectableFleetsByOwnerName[""]);
      let otherFleetOwnerNames = Object.keys(selectableFleetsByOwnerName)
        .filter(x => x != "" && x != curUserName)
        .sort();

      let returnList = [] as GroupableSelectListOption<FleetWithEquipment>[];
      if (myFleets.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.my-fleets") });
        myFleets.forEach(c =>
          returnList.push({ ...c, disabled: !c.equipment?.length } as SelectListOption<
            FleetWithEquipment
          >)
        );
        if (unownedFleets.length > 0 || otherFleetOwnerNames.length > 0)
          returnList.push({ divider: true });
      }

      if (unownedFleets.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.global-fleets") });
        unownedFleets.forEach(c =>
          returnList.push({ ...c, disabled: !c.equipment?.length } as SelectListOption<
            FleetWithEquipment
          >)
        );
        if (otherFleetOwnerNames.length > 0) returnList.push({ divider: true });
      }

      if (otherFleetOwnerNames.length > 0) {
        for (let i = 0; i < otherFleetOwnerNames.length; i++) {
          let ownerName = otherFleetOwnerNames[i];
          let list = selectableFleetsByOwnerName[ownerName];
          if (list.length > 0) {
            returnList.push({ header: ownerName });
            list.forEach(c =>
              returnList.push({ ...c, disabled: !c.equipment?.length } as SelectListOption<
                FleetWithEquipment
              >)
            );
            if (i + 1 < otherFleetOwnerNames.length) returnList.push({ divider: true });
          }
        }
      }

      return returnList;
    },
    selectedFleet(): FleetWithEquipment | undefined {
      return this.availableFleets.find(x => x.id == this.selectedFleetID);
    },
    timesheetIsDeclined(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Declined;
    },
    timesheetDeclineComments(): string | undefined {
      return this.currentTimesheet?.lastStatusLog?.comments;
    }
  },

  watch: {
    timesheet() {
      this.loadDataForTimesheet();
    }
  },

  methods: {
    openNotesDialog(item: TimesheetRow) {
      if (!item) return;
      this.$emit("openNotesDialog", item);
    },
    fleetIsSelectable(fleet: FleetWithEquipment | undefined): boolean {
      if (!fleet?.equipment?.length) return false;

      var fleetEmployeesWithoutRows = fleet.equipment.filter(
        ce =>
          !this.allTimesheetRowsAlreadyExists(this.timesheet.isLocked, ce.equipmentID, undefined)
      );
      return !!fleetEmployeesWithoutRows?.length;
    },
    validTimeForDayRule(row: TimesheetRow): boolean | TranslateResult | string {
      let errorMessage = "";
      if (row.equipmentDays !== undefined && row.equipmentDays !== null && row.equipmentDays < 0) {
        errorMessage = `${this.$t("timesheets.existing.too-few-hours-error-message")}`;
      }

      if (!!errorMessage) {
        if (!row.errorMessage.includes(errorMessage)) {
          row.errorMessage = errorMessage;
        }
        return errorMessage;
      } else {
        row.errorMessage = "";
        return true;
      }
    },
    timesheetRowRules(item: TimesheetRow): Array<Function | boolean | TranslateResult | string> {
      return [this.validTimeForDayRule(item)];
    },
    // This method determines if the row in the data-table should be colored to represent if it is "Urgent".
    timesheetRowClassName(item: any) {
      return item.isCorrectionRow ? "fd-correction-table-row-background" : "";
    },
    updateAllEquipmentDayValues(value: PossibleNumber) {
      let rows = this.equipmentTimesheetRows;
      for (let row of rows) {
        row.equipmentDays = this.$parse.sanitizedNumber(value);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
    },
    updateAllEquipmentQuantityValues(value: PossibleNumber) {
      let rows = this.equipmentTimesheetRows;
      for (let row of rows) {
        row.equipmentQuantity = this.$parse.sanitizedNumber(value);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
    },
    getExistingTimesheetRow(
      isCorrectionRow: boolean,
      equipmentID: string | undefined,
      equipmentClassificationID: string | null | undefined
    ): TimesheetRow | undefined {
      let comparisonRow = {
        isCorrectionRow: isCorrectionRow,
        employeeID: equipmentID,
        classificationID: equipmentClassificationID,
        resourceType: TimesheetRowResourceType.Equipment,
        rowType: TimesheetRowType.Equipment,
        areaID: null,
        subAreaID: null
      } as TimesheetRow;
      return this.allTimesheetRows?.find(t =>
        areTimesheetRowsEqual(t, comparisonRow, {
          ignoreClassification: equipmentClassificationID === undefined,
          ignoreArea: false,
          ignoreSubArea: false
        })
      );
    },
    allTimesheetRowsAlreadyExists(
      isCorrectionRow: boolean,
      equipmentID: string | undefined,
      equipmentClassificationID: string | null | undefined
    ): boolean {
      return !!this.getExistingTimesheetRow(
        isCorrectionRow,
        equipmentID,
        equipmentClassificationID
      );
    },
    timesheetRowAlreadyExists(
      isCorrectionRow: boolean,
      equipmentID: string | undefined,
      equipmentClassificationID: string | null | undefined
    ): boolean {
      return !!this.getExistingTimesheetRow(
        isCorrectionRow,
        equipmentID,
        equipmentClassificationID
      );
    },
    allGroupsExpanded(tableIdentifier: string): boolean {
      let datatableRef = `${tableIdentifier}datatable`;
      let datatable = this.$refs[datatableRef] as VDataTable;
      if (!datatable) {
        return false;
      }
      let toggleRefs = Object.keys(this.$refs).filter(x =>
        x.startsWith(`${tableIdentifier}grouptoggle`)
      );
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if (!isOpen) {
          anyGroupsClosed = true;
          break;
        }
      }
      return !anyGroupsClosed;
    },
    validate(): boolean {
      let additionalDetailsValid =
        (this.$refs.additionaldetailsform as HTMLFormElement)?.validate() ?? true;
      let timesheetValid = (this.$refs.timesheetform as HTMLFormElement)?.validate() ?? true;
      return additionalDetailsValid && timesheetValid;
    },
    rowIsEditable(item: TimesheetRow): boolean {
      if (!item.isCorrectionRow && this.currentTimesheetIsReadonly) return false;
      if (!!item.isCorrectionRow && !this.makeCorrections) return false;
      return true;
    },
    canEditEquipment(row: TimesheetRow): boolean {
      if (!!row.workOrderID || row.rowType != TimesheetRowType.Equipment) return false;
      if (!row.isCorrectionRow && this.currentTimesheetIsReadonly) return false;
      if (!!row.isCorrectionRow && !this.makeCorrections) return false;

      return true;
    },
    canAddNewRowFromRow(row: TimesheetRow): boolean {
      if (!this.rowIsEditable(row)) return false;

      let existingSimilarRowWithoutArea = this.timesheetRowAlreadyExists(
        row.isCorrectionRow,
        row.employeeID,
        undefined
      );
      return !existingSimilarRowWithoutArea;
    },
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadEmployees: "LOAD_USERS",
      loadEquipmentForContractor: "LOAD_EQUIPMENT_FOR_CONTRACTOR",
      loadEquipmentClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS"
    }),
    sumRowTimeValues(
      items: TimesheetRow[],
      propName: string | null | undefined
    ): TimesheetRowTimeValues | null | undefined {
      if (!propName?.length) return undefined;

      let result: TimesheetRowTimeValues = items.reduce(
        (a: TimesheetRowTimeValues, b: TimesheetRow) => a.adding(b.times[propName]),
        new TimesheetRowTimeValues()
      );
      return result;
    },
    sumEquipmentDays(items: TimesheetRow[]): string | undefined {
      let result = items.reduce((a, b) => a + this.$parse.sanitizedNumber(b.equipmentDays), 0);
      return !result ? undefined : result.toFixed(2);
    },
    sumEquipmentQuantity(items: TimesheetRow[]): string | undefined {
      let result = items.reduce((a, b) => a + this.$parse.sanitizedNumber(b.equipmentQuantity), 0);
      return !result ? undefined : result.toFixed(2);
    },
    toggleTableGroups(tableIdentifier: string, closed: boolean = true) {
      let toggleRefs = Object.keys(this.$refs).filter(x =>
        x.startsWith(`${tableIdentifier}grouptoggle`)
      );
      let datatable = this.$refs[`${tableIdentifier}datatable`] as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },

    canEditTimesheet(timesheet: UpdatableTimesheetWithTimesheetRows | null | undefined) {
      if (!timesheet?.id) return false;

      let locked = timesheet.isLocked ?? false;
      let currentDay =
        new Date(timesheet.day!.toDateString()).getTime() ==
        new Date(new Date().toDateString()).getTime();
      return !locked && currentDay;
    },

    calculateTotalForItem(item: TimesheetRow): TimesheetRowTimeValues {
      return new TimesheetRowTimeValues(
        Number(item.equipmentDays) * Number(item.equipmentQuantity)
      );
    },
    calculateTotalForItems(items: any[]): TimesheetRowTimeValues {
      let total: TimesheetRowTimeValues = items.reduce(
        (a: TimesheetRowTimeValues, b: any) => a.adding(this.calculateTotalForItem(b)),
        new TimesheetRowTimeValues()
      );
      return total;
    },

    // *** NAVIGATION ***
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    addFormOnSubmit(e: Event) {
      e.preventDefault();
      this.addTimesheetRows(this.makeCorrections ?? false);
    },

    // *** ENTRY MANAGEMENT ***
    _addTimesheetRow(
      isCorrectionRow: boolean,
      equipmentID: string | undefined,
      equipmentName: string | null | undefined,
      equipmentSerialNumber: string | null | undefined,
      equipmentModelNumber: string | null | undefined,
      equipmentClassificationID: string | null | undefined,
      equipmentCostCodeID: string | null | undefined
    ): TimesheetRow {
      let equipmentClassificationDisplayName = this.getEquipmentClassificationDisplayNameForID(
        equipmentClassificationID
      );
      let equipmentCostCodeDisplayName = this.getCostCodeDisplayNameForID(equipmentCostCodeID);
      let existingRow = this.getExistingTimesheetRow(
        isCorrectionRow,
        equipmentID,
        equipmentClassificationID ?? null
      );
      if (!!existingRow) return existingRow;
      let newRow = {
        resourceType: TimesheetRowResourceType.Equipment,
        rowType: TimesheetRowType.Equipment,
        isCorrectionRow: isCorrectionRow,
        rowNumber: this.currentTimesheet?.nextRowNumber ?? 1,
        employeeID: equipmentID,
        employeeName: equipmentName,
        employeeCode: equipmentSerialNumber,
        employeeBadge: equipmentModelNumber,
        classificationID: equipmentClassificationID,
        classificationDisplayName: equipmentClassificationDisplayName,
        hasPerDiem: false,
        removePerDiem: false,
        equipmentCostCodeID: equipmentCostCodeID,
        equipmentCostCodeDisplayName: equipmentCostCodeDisplayName,
        equipmentHours: 0,
        equipmentDays: 0,
        equipmentQuantity: 1,
        errorMessage: "",
        notesCount: 0,
        times: {}
      } as TimesheetRow;

      this.currentTimesheet!.timesheetRows.push(newRow);
      UpdateRowCorrections(newRow, this.allTimesheetRows);

      return newRow;
    },
    addNonWorkOrderRelatedTimesheetRow(
      isCorrectionRow: boolean,
      equipmentID: string | undefined,
      equipmentName: string | null | undefined,
      equipmentSerialNumber: string | null | undefined,
      equipmentModelNumber: string | null | undefined,
      equipmentClassificationID: string | null | undefined,
      equipmentCostCodeID: string | null | undefined
    ): TimesheetRow {
      return this._addTimesheetRow(
        isCorrectionRow,
        equipmentID,
        equipmentName,
        equipmentSerialNumber,
        equipmentModelNumber,
        equipmentClassificationID,
        equipmentCostCodeID
      );
    },
    async addTimesheetRows(isCorrectionRow: boolean) {
      if (!this.currentTimesheet) {
        return;
      }
      if (this.currentTimesheet.isLocked && !isCorrectionRow) {
        return;
      }

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.addform as HTMLFormElement).validate()) {
        return;
      }

      if (!this.selectedEquipmentID && !this.selectedFleetID) return;
      if (!!this.selectedFleetID && !this.fleetIsSelectable(this.selectedFleet)) return;

      this.processing = true;
      try {
        let selectedEquipment = this.selectedEquipment;
        selectedEquipment.forEach(e => {
          this.addNonWorkOrderRelatedTimesheetRow(
            isCorrectionRow,
            e.id,
            e.name,
            e.serialNumber,
            e.modelNumber,
            e.equipmentClassificationID,
            e.costCodeID
          );
        });
        this.currentTimesheet.timesheetRows = SortTimesheetRows(
          this.currentTimesheet.timesheetRows
        );

        this.selectedEquipmentID = null;

        this.$emit("rows-added");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    removeTimesheetRow(row: TimesheetRow) {
      if (!this.currentTimesheet) return;
      const index = this.currentTimesheet.timesheetRows.indexOf(row);
      if (index < 0) {
        return;
      }
      // Clear out all hours values for row to be removed to confirm if explanations also need to be removed
      ParseWorkSubTypeIDsFromRow(row).forEach(wstid => {
        row.times[wstid] = new TimesheetRowTimeValues();
      });
      row.hasPerDiem = false;
      row.removePerDiem = false;

      this.currentTimesheet.timesheetRows.splice(index, 1);
    },

    // *** LOADING ***
    async loadDataForTimesheet() {
      if (!this.currentTimesheet) {
        return;
      }
      this.processing = true;
      try {
        // Load the initial list with "suggested" restricted work orders
        await Promise.all([
          this.loadFleets(this.currentTimesheet.contractorID!),
          this.loadEquipmentForContractor({ contractorID: this.currentTimesheet.contractorID })
        ]);
        this.$nextTick(() => {
          this.toggleTableGroups("summary", true);
          if (this.hasEquipmentRows) this.panel = this.equipmentPanelNumber;
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadFleets(contractorId: string) {
      this.availableFleets = await fleetService.getByContractorId(contractorId);
    },

    async loadData() {
      this.processing = true;
      try {
        await Promise.all([
          this.loadContractors(),
          this.loadWorkTypes(),
          this.loadWorkSubTypes(),
          this.loadCostCodes(),
          this.loadEmployees(),
          this.loadEquipmentClassifications()
        ]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    getEquipmentClassificationDisplayNameForID(
      equipmentClassificationID: string | null | undefined
    ): string | undefined {
      let equipmentClassification = (this.$store.state.equipmentClassifications
        .fullList as EquipmentClassification[]).find(x => x.id == equipmentClassificationID);
      return equipmentClassification?.name;
    },
    getCostCodeDisplayNameForID(costCodeID: string | null | undefined): string | undefined {
      let costCode = (this.$store.state.projectCostCodes.fullList as ProjectCostCode[]).find(
        x => x.id == costCodeID
      );
      return costCode?.name;
    },

    // *** INLINE NAVIGATION ***
    getDataTable(tableIdentifier: string) {
      return this.$refs[`${tableIdentifier}datatable`] as VDataTable;
    },
    getFieldRef(tableIdentifier: string, fieldName: string | null | undefined, item: TimesheetRow) {
      let field = fieldName!.replace("-", "").replace("-", "");
      let equipmentID = item.employeeID!.replace("-", "").replace("-", "");
      let areaID = item.areaID?.replace("-", "").replace("-", "") ?? "";
      let subAreaID = item.subAreaID?.replace("-", "").replace("-", "") ?? "";
      let correctionType = item.isCorrectionRow ? "correction" : "entry";
      return `${tableIdentifier}_${field}_${equipmentID}_${areaID}_${subAreaID}_${correctionType}`;
    },
    focusFieldForVisibleItemAtIndex(
      tableIdentifier: string,
      fieldName: string | null | undefined,
      index: number,
      visibleItems: TimesheetRow[]
    ) {
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(tableIdentifier, fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(
      e: Event,
      tableIdentifier: string,
      fieldName: string | null | undefined,
      item: TimesheetRow
    ) {
      e.preventDefault();

      let datatable = this.getDataTable(tableIdentifier);
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex <= 0) {
        let _this = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          _this.focusFieldForVisibleItemAtIndex(
            tableIdentifier,
            fieldName,
            datatable.computedItemsPerPage,
            visibleItems
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, previousIndex, visibleItems);
    },
    async selectNextField(
      e: Event,
      tableIdentifier: string,
      fieldName: string | null | undefined,
      item: TimesheetRow
    ) {
      e.preventDefault();
      let datatable = this.getDataTable(tableIdentifier);
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex >= visibleItems.length - 1) {
        let _this = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          _this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, 0, visibleItems);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, nextIndex, visibleItems);
    },
    async enterPressed(
      e: KeyboardEvent,
      tableIdentifier: string,
      fieldName: string | null | undefined,
      item: TimesheetRow
    ) {
      if (e.shiftKey) await this.selectPreviousField(e, tableIdentifier, fieldName, item);
      else await this.selectNextField(e, tableIdentifier, fieldName, item);
    },
    getHourTotalsByWorkSubTypeIDForRow(row: TimesheetRow): { [key: string]: number } {
      let wstIDs = ParseWorkSubTypeIDsFromRow(row);
      let hourTotalsByWorkSubTypeID = wstIDs.reduce((a, b) => {
        a[b] = this.$parse.sanitizedNumber(row.times[b]?.totalTime);
        return a;
      }, {} as HashTable<number>);
      return hourTotalsByWorkSubTypeID;
    },
    async downloadAndPrintPlannerReport(reportType: string = "pdf") {
      let mappedRows = this.allTimesheetRows.map(
        x =>
          ({
            name: x.employeeName,
            employeeName: x.employeeName,
            employeeCode: x.employeeCode,
            employeeBadge: x.employeeBadge,
            employeeClassification: x.classificationDisplayName,
            areaName: x.areaName,
            subAreaName: x.subAreaName,
            hoursTotalByWorkSubTypeID: this.getHourTotalsByWorkSubTypeIDForRow(x),
            total: this.calculateTotalForItem(x).totalTime.toFixed(2),
            equipmentDays: x.equipmentDays,
            equipmentQuantity: x.equipmentQuantity
          } as LaborTimesheetRow & HasName)
      );
      let summaryTimesheetRows = SortItemsWithName(mappedRows);
      let usedWorkSubTypes = [] as WorkSubType[];

      var blob = await reportService.getLaborTimesheetPrintoutReportContentWithData(
        summaryTimesheetRows,
        usedWorkSubTypes,
        reportType,
        localizedDateTimeString(new Date()),
        true,
        true
      );
      if (reportType == "xls") {
        downloadBlob(blob, "timesheet-labor-printout.xlsx");
      } else {
        printBlob(blob, "timesheet-labor-printout.pdf", "application/pdf");
      }
    },
    ...mapMutations({
      setFilteringContext: "SET_FILTERING_CONTEXT"
    })
  },

  mounted: async function() {
    await this.loadData();
    await this.loadDataForTimesheet();
  },

  created: async function() {
    this.setFilteringContext({
      context: "equipment-timesheet-form",
      parentalContext: this.parentContext,
      selectedTab: `tab-${this.firstTabKey}`
    });
  }
});
