import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { TranslateResult } from "vue-i18n";
import { timesheetService, TimesheetWithDetails } from "../../../services";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { DateRangePreset } from "@fd/lib/vue/components/DateRangePicker.vue";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { mapMutations } from "vuex";

type FormattedTimesheetWithDetails = TimesheetWithDetails & {
  timesheetStatus: string | TranslateResult;
  formattedDay: string;
  formattedTotalRegularTime: string;
  formattedTotalOverTime: string;
  formattedTotalDoubleTime: string;
  formattedTotalUnits: string;
};

const TimesheetHistoryDialog = FDVue.extend({
  name: "fd-timesheet-history-dialog",
  mixins: [dialogSupport, rules],
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      timesheets: [] as FormattedTimesheetWithDetails[],
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined
    };
  },
  computed: {
    unwatchedMethodNames() {
      return ["loadTimesheets", "reloadTableData", "loadData", "open"];
    },
    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },
    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          try {
            await this.loadTimesheets();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
          }
        } else this.showArchivedToDate = null;
      }
    },
    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    },
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    }
  },
  methods: {
    ...mapMutations({
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    cancelDialog() {
      this.closeDialog(false);
    },
    async loadTimesheets() {
      this.timesheets = [];
      let timesheets = await timesheetService.getFinishedTimesheets(
        this.showArchivedFromDate,
        this.showArchivedToDate,
        false
      );
      this.timesheets = timesheets.map(
        x =>
          ({
            ...x,
            timesheetNumberString: `00000${x.timesheetNumber}`.slice(-5),
            timesheetStatus: this.$t(`timesheets.status.${x.timesheetStatusID}`),
            formattedDay: DateUtil.stripTimeFromLocalizedDateTime(x.day),
            formattedTotalRegularTime:
              !!x.totalRegularTime && x.totalRegularTime > 0
                ? x.totalRegularTime.toFixed(2)
                : undefined,
            formattedTotalOverTime:
              !!x.totalOverTime && x.totalOverTime > 0 ? x.totalOverTime.toFixed(2) : undefined,
            formattedTotalDoubleTime:
              !!x.totalDoubleTime && x.totalDoubleTime > 0
                ? x.totalDoubleTime.toFixed(2)
                : undefined,
            formattedTotalUnits:
              !!x.totalUnits && x.totalUnits > 0 ? x.totalUnits.toFixed(0) : undefined,
            formattedTotalDays:
              !!x.totalDays && x.totalDays > 0 ? x.totalDays.toFixed(2) : undefined,
            formattedTotalQuantity:
              !!x.totalQuantity && x.totalQuantity > 0 ? x.totalQuantity.toFixed(2) : undefined
          } as FormattedTimesheetWithDetails)
      );
    },
    async reloadTableData() {
      await this.loadData();
    },
    async loadData() {
      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        await this.loadTimesheets();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(parentContext: string): Promise<boolean> {
      this.maxDate = DateUtil.addDaysToDate(null, 0);
      this.minDate = DateUtil.addMonthsToDate(this.maxDate, -2);
      let fromDate = DateUtil.addDaysToDate(null, -13);
      this.setFilteringContext({
        context: "timesheetshistorydialog",
        parentalContext: parentContext,
        showArchivedForFiltering: true,
        showArchivedForFilteringFromDate: fromDate,
        showArchivedForFilteringToDate: this.maxDate,
        searchStringForFiltering: ""
      });

      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    },
    correctTimesheet(timesheet: TimesheetWithDetails) {
      this.closeDialog(timesheet.id);
    }
  }
});
export default TimesheetHistoryDialog;

export async function openTimesheetHistoryDialog(parentContext: string): Promise<boolean> {
  let dialog = createDialog(TimesheetHistoryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(parentContext);
}
