import { PropType } from "vue";
import FDVue from "@fd/lib/vue";
import { NormGroupWithNorms, NormWithResponse } from "./SP.BuildSheetNormForm.vue";

const BuildSheetFormBase = FDVue.extend({
  name: "sp-build-sheet-form-base",
  components: {
    "sp-build-sheet-norm-form": () => import("./SP.BuildSheetNormForm.vue")
  },
  props: {
    processing: { type: Boolean },
    normGroupsWithResponses: { type: Array as PropType<Array<NormGroupWithNorms>> },
    canEditBuildSheetResponses: { type: Boolean },

    notes: { type: String }
  },
  data: function() {
    return {};
  },
  computed: {
    managedNormGroupsWithResponses: {
      get(): Array<NormGroupWithNorms> {
        return this.normGroupsWithResponses;
      },
      set(val: Array<NormGroupWithNorms>) {
        this.fieldUpdated("normGroupsWithResponses", val);
      }
    },
    allNormsWithResponses(): Array<NormWithResponse> {
      return this.normGroupsWithResponses.reduce((responses, group) => {
        return responses.concat(group.normsWithResponses);
      }, [] as Array<NormWithResponse>);
    },
    canEditBuildSheet(): boolean {
      return this.canEditBuildSheetResponses ?? false;
    }
  },
  watch: {},
  methods: {
    fieldUpdated(fieldName: string, v: any) {
      this.$emit(`update:${fieldName}`, v);
    },
    notesUpdated(v: any) {
      this.fieldUpdated("notes", v);
    }
  }
});
export default BuildSheetFormBase;
