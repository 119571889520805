import Vue, { VueConstructor } from "vue";

import type { ReferenceDataManager } from "./register";
import {
  isoDataStore,
  systemDataStore,
  contractorDataStore,
  contractorPurchaseOrderDataStore,
  supplierDataStore,
  disciplineDataStore,
  personDataStore,
  personBySecurityIDDataStore,
  projectLocationDataStore,
  scaffoldBayLengthDataStore,
  scaffoldBayWidthDataStore,
  scaffoldBayHeightDataStore,
  hoardingModifierDataStore,
  internalModifierDataStore,
  scaffoldCongestionFactorDataStore,
  scaffoldElevationModifierDataStore,
  scaffoldHeightModifierDataStore,
  scaffoldTypeModifierDataStore,
  projectCostCodeDataStore
} from "../../store";

export {
  isoDataStore,
  systemDataStore,
  contractorDataStore,
  contractorPurchaseOrderDataStore,
  supplierDataStore,
  disciplineDataStore,
  personDataStore,
  personBySecurityIDDataStore,
  projectLocationDataStore,
  scaffoldBayLengthDataStore,
  scaffoldBayWidthDataStore,
  scaffoldBayHeightDataStore,
  hoardingModifierDataStore,
  internalModifierDataStore,
  scaffoldCongestionFactorDataStore,
  scaffoldElevationModifierDataStore,
  scaffoldHeightModifierDataStore,
  scaffoldTypeModifierDataStore
};

import type { ScaffoldRequestTypes, WorkOrderStatuses } from "../../services";
import { ScaffoldRequestTypeLabels, ScaffoldRequestSubTypeLabels, WorkOrderStatusLabels } from "./enum";
import i18n from "../../i18n";

// TODO: Do we like this pattern? This value will have the type "object" but so would "new String()"
export class StringLoadingPlaceholder extends String {
  public readonly loading = true;
  public toString() { return i18n.t("loading-dot-dot-dot").toString(); }
  public valueOf() { return this.toString(); }
}
export const StringIsLoading = new StringLoadingPlaceholder() as unknown as string;

export abstract class LookupPluginControllerBase<LookupValue> {
  protected abstract doLookup(id: string | null, dataStore: ReferenceDataManager<any>): LookupValue;
  public contractor(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, contractorDataStore) : undefined; }
  public supplier(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, supplierDataStore) : undefined; }
  public discipline(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, disciplineDataStore) : undefined; }
  public person(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, personDataStore) : undefined; }
  public personBySecurityID(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, personBySecurityIDDataStore) : undefined; }
  public location(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, projectLocationDataStore) : undefined; }

  public projectCostCode(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, projectCostCodeDataStore) : undefined; }

  public iso(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, isoDataStore) : undefined; }
  public system(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, systemDataStore) : undefined; }
  
  public purchaseOrder(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, contractorPurchaseOrderDataStore) : undefined; }
  public scaffoldBayLength(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, scaffoldBayLengthDataStore) : undefined; }
  public scaffoldBayWidth(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, scaffoldBayWidthDataStore) : undefined; }
  public scaffoldBayHeight(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, scaffoldBayHeightDataStore) : undefined; }

  public hoardingModifier(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, hoardingModifierDataStore) : undefined; }
  public internalModifier(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, internalModifierDataStore) : undefined; }
  public scaffoldCongestionFactor(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, scaffoldCongestionFactorDataStore) : undefined; }
  public scaffoldElevationModifier(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, scaffoldElevationModifierDataStore) : undefined; }
  public scaffoldHeightModifier(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, scaffoldHeightModifierDataStore) : undefined; }
  public scaffoldTypeModifier(id: string | null | undefined) { return id !== undefined ? this.doLookup(id, scaffoldTypeModifierDataStore) : undefined; }

  public requestType(value: ScaffoldRequestTypes | null | undefined) { return ScaffoldRequestTypeLabels[value!] || value; }
  public requestSubType(value: ScaffoldRequestTypes | null | undefined) { return ScaffoldRequestSubTypeLabels[value!] || value; }
  public workOrderStatus(value: WorkOrderStatuses | null | undefined) { return WorkOrderStatusLabels[value!] || value; }
}

export class LookupItemPluginController extends LookupPluginControllerBase<string | null> {
  protected doLookup(id: string | null, dataStore: ReferenceDataManager<any>) {
    var returnValue = dataStore.lookup(id);
    if (returnValue !== undefined) {
      return returnValue;
    } else {
      return StringIsLoading;
    }
  }
}
export class LookupCaptionPluginController extends LookupPluginControllerBase<string | null> {
  protected doLookup(id: string | null, dataStore: ReferenceDataManager<any>) {
    var returnValue = dataStore.lookupCaption(id);
    if (returnValue !== undefined) {
      return returnValue;
    } else {
      return StringIsLoading;
    }
  }
}
export class LookupIsLoadingPluginController extends LookupPluginControllerBase<boolean> {
  protected doLookup(id: string | null, dataStore: ReferenceDataManager<any>) {
    if (id) {
      return dataStore.getItemState(id).isLoading;
    } else {
      return false;
    }
  }
}

// TODO: Can we provide typings for these against the Vue base object? Should we, or should we just have people reference the Lookup instance directly?
export function LookupPlugin(Vue: VueConstructor<Vue>) {
  Vue.prototype.$lookupItem = new LookupItemPluginController();
  Vue.prototype.$lookup = new LookupCaptionPluginController();
  Vue.prototype.$lookupIsLoading = new LookupIsLoadingPluginController();
}

declare module "vue/types/vue" {
  interface Vue {
    $lookupItem: LookupItemPluginController;
    $lookup: LookupCaptionPluginController;
    $lookupIsLoading: LookupIsLoadingPluginController;
  }
}