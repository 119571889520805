import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  contractorService,
  ContractorWithTags,
  Discipline,
  disciplineService,
  ProjectLocation,
  projectLocationService,
  scaffoldRequestService,
  ScaffoldRequestStatuses,
  ScaffoldRequestTypes,
  ScaffoldRequestWithDetails,
  Tag,
  workOrderService
} from "../services";
import {
  openActiveWorkForScaffoldDialog,
  WorkForScaffoldDetails
} from "../views/components/ActiveWorkForScaffoldDialog.vue";
import { WorkOrderDetails } from "../views/components/WorkOrderDetailsForm.vue";
import { ScaffoldRequestWithExtraDetails } from "./scaffoldRequest";
export {
  ParseScaffoldRequestWithExtraDetails,
  ScaffoldRequestWithExtraDetails
} from "./scaffoldRequest";
import { stripTimeFromLocalizedDateTime } from "@fd/lib/client-util/datetime";
import { valueInArray } from "@fd/lib/client-util/array";
import { filterByTags } from "../services/taggableItems";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { softCrafts } from "./softCrafts";

export function WorkForScaffoldDetailsFromScaffoldRequest(item: ScaffoldRequestWithExtraDetails) {
  var workOrderDetails = WorkOrderDetailsFromScaffoldRequest(item);
  var formattedRequiredDate = !!item.requiredDate
    ? stripTimeFromLocalizedDateTime(item.requiredDate)
    : undefined;
  return {
    ...workOrderDetails,
    workType: "request",
    scaffoldRequestId: item.id,
    walkdown: item.walkdown,
    formattedRequiredDate: formattedRequiredDate,
    requestType: item.requestType,
    displayIdentifier: `R-${item.internalRequestNumber}`,
    archivedDate: item.archivedDate,
    isArchived: item.archived,
    currentUserRequestPermissions: item.currentUserPermissions,
    currentUserWorkOrderPermissions: undefined
  } as WorkForScaffoldDetails;
}
export function WorkOrderDetailsFromScaffoldRequest(
  item: ScaffoldRequestWithExtraDetails
): WorkOrderDetails {
  var submittedOn = item.submittedOn;
  if (!submittedOn && item.scaffoldRequestStatus != ScaffoldRequestStatuses.Draft) {
    submittedOn = item.created;
  }
  return {
    approvalComments: item.workOrderApprovalComments,
    lastStatusChangeDate: item.lastStatusChangeDate,
    lastStatusChangedBy: item.lastStatusChangedBy,
    workPackageNames: item.workPackageNames,
    isModifyRequest: item.isModifyRequest,
    assignedContractorName: item.workOrderAssignedContractorName,
    coordinatorName: item.workOrderCoordinatorName,
    generalForemanName: item.workOrderGeneralForemanName,
    foremanName: item.workOrderForemanName,
    requestingContractorName: item.requestingContractorName,
    requestingEmployeeName: item.requestingEmployeeName,
    requestSubmittedOn: submittedOn,
    requestSubmitterName: item.submitterName ?? item.requestorName, // The requestor is the user to first created the request
    areaName: item.areaName,
    subAreaName: item.subAreaName,
    scaffoldNumber: item.scaffoldNumber,
    requestNumber: item.internalRequestNumber,
    disciplineName: item.disciplineName,
    requestStatus: item.scaffoldRequestStatus,
    requestType: item.requestType,
    requestSubType: item.requestSubType,
    isPlanned: item.isPlanned,
    specificWorkLocation: item.specificWorkLocation,
    siteContact: item.siteContact,
    detailedWorkDescription: item.detailedWorkDescription,
    internalNumber: item.workOrderNumber ?? undefined,
    workOrderStatus: item.workOrderStatus,
    workOrderStatusDetail: item.workOrderRequestStatusDetails,
    priority:
      !!item.workOrderPriority && item.workOrderPriority > 0
        ? Math.min(item.workOrderPriority, 5)
        : 5,
    progress: item.workOrderProgress ?? 0,
    requestDate: item.workOrderRequestDate,
    startDate: item.workOrderStartDate,
    completedDate: item.workOrderCompletedDate,
    requiredDate: item.requiredDate,
    approvedRequiredDate: item.approvedRequiredDate,
    requiredUntilDate: item.requiredUntilDate,
    notes: item.notes,
    isClientWorkOrder: item.isClientWorkOrder,
    clientWorkOrderReferenceNumber: item.clientWorkOrderReferenceNumber,
    clientWorkOrderReason: item.clientWorkOrderReason,
    isChangeOrder: item.isChangeOrder,
    changeOrderReferenceNumber: item.changeOrderReferenceNumber,
    changeOrderReason: item.changeOrderReason,
    isRework: item.isRework,
    reworkReferenceNumber: item.reworkReferenceNumber,
    reworkReason: item.reworkReason,
    isServiceOrder: item.isServiceOrder,
    serviceOrderReferenceNumber: item.serviceOrderReferenceNumber,
    serviceOrderReason: item.serviceOrderReason,
    purchaseOrderID: item.purchaseOrderID,
    existingTagNumber: item.existingTagNumber,
    currentUserPermissions: {
      ...item.currentUserPermissions,
      canViewEstimateDetails: false
    },
    isUrgent: item.isUrgent,
    isUrgentDetail: item.isUrgentDetail,
    lastUrgentDetailChangedBy: undefined,
    lastUrgentDetailChangedDate: undefined,
    lastUrgentValueChangedBy: undefined,
    lastUrgentValueChangedDate: undefined
  };
}

export default FDVue.extend({
  mixins: [userAccess, serviceErrorHandling, softCrafts],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-work-order-details": () => import("../views/components/WorkOrderDetailsForm.vue"),
    "fd-scaffold-number-with-badges": () =>
      import("../views/components/ScaffoldNumberWithBadges.vue")
  },

  data: function() {
    return {
      allowAllSelection: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      allScaffoldRequests: [] as ScaffoldRequestWithExtraDetails[],

      allContractors: [] as ContractorWithTags[],
      allDisciplines: [] as Discipline[],
      currentUserDisciplines: [] as Discipline[],
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],

      archivedLoading: false,

      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },

  computed: {
    filteredScaffoldRequests(): ScaffoldRequestWithExtraDetails[] {
      var selectedRequests = this.allScaffoldRequests;
      if (!!this.selectedContractors?.length) {
        selectedRequests = selectedRequests.filter(
          x =>
            valueInArray(x.requestingContractorID, this.selectedContractors) ||
            valueInArray(x.assignedContractorID, this.selectedContractors)
        );
      }
      if (this.selectedDisciplines.length) {
        selectedRequests = selectedRequests.filter(x =>
          valueInArray(x.disciplineID, this.selectedDisciplines)
        );
      }
      if (this.selectedAreas.length) {
        selectedRequests = selectedRequests.filter(x => valueInArray(x.areaID, this.selectedAreas));
      }
      if (this.selectedSubAreas.length) {
        selectedRequests = selectedRequests.filter(x =>
          valueInArray(x.subAreaID, this.selectedSubAreas)
        );
      }

      return filterByTags(this.tagsSelectedForFiltering, selectedRequests);
    },

    canViewContractorFilter(): boolean {
      return this.curUserCanViewAllContractors || this.curUserContractorIDs.length != 1;
    },

    canViewDisciplinesFilter(): boolean {
      return (
        !!this.currentUserDisciplines &&
        (this.currentUserDisciplines.length != 1 || this.selectableDisciplines.length > 1)
      );
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    selectableDisciplines(): any[] {
      let selectableDisciplineIDs = this.allScaffoldRequests
        .filter(x => !!x.disciplineID)
        .map(x => x.disciplineID!);
      selectableDisciplineIDs = [...new Set(selectableDisciplineIDs)];
      return this.allDisciplines.filter(x => selectableDisciplineIDs.includes(x.id!));
    },

    selectedDisciplines: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.disciplinesForFiltering;
      },
      set(val) {
        this.$store.commit("SET_DISCIPLINES_FOR_FILTERING", val);
      }
    },

    selectableAreas(): any[] {
      let selectableAreaIDs = this.allScaffoldRequests.filter(x => !!x.areaID).map(x => x.areaID!);
      selectableAreaIDs = [...new Set(selectableAreaIDs)];
      return this.allAreas.filter(x => selectableAreaIDs.includes(x.id!));
    },

    selectedAreas: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.areasForFiltering;
      },
      set(val) {
        this.$store.commit("SET_AREAS_FOR_FILTERING", val);
      }
    },

    selectableSubAreas(): any[] {
      let selectableSubAreaIDs = this.allScaffoldRequests
        .filter(x => !!x.subAreaID)
        .map(x => x.subAreaID!);
      selectableSubAreaIDs = [...new Set(selectableSubAreaIDs)];

      let selectedAreaIDs = this.selectedAreas;
      return this.allSubAreas.filter(
        x =>
          selectableSubAreaIDs.includes(x.id!) &&
          !!selectedAreaIDs.length &&
          selectedAreaIDs.includes(x.parentLocationID)
      );
    },

    selectedSubAreas: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.subAreasForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SUB_AREAS_FOR_FILTERING", val);
      }
    },

    selectedContractors: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },

    selectableContractors(): ContractorWithTags[] {
      let selectableContractorIDs = this.allScaffoldRequests.map(x => x.requestingContractorID);
      return this.allContractors.filter(x => selectableContractorIDs.includes(x.id));
    },

    showArchived: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        let currentProcessing = this.processing;
        this.processing = true;
        this.archivedLoading = true;
        try {
          await this.loadRequests();
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
          this.archivedLoading = false;
        }
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.loadRequests();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    },

    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.allScaffoldRequests);
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadRequests();
        this.inlineMessage.message = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // Override in implementation to load screen-specific requests
    async loadRequests() {
      console.log("Please override `loadRequests` in screen implementation.");
    },

    workOrderDetailsFromRequest(item: ScaffoldRequestWithExtraDetails): WorkOrderDetails {
      return WorkOrderDetailsFromScaffoldRequest(item);
    },

    async flipArchived(item: ScaffoldRequestWithExtraDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        // Flipping the archived switch has no effect on the SP1 work order, so we can do a basic update here
        await scaffoldRequestService.updateItem(item.id!, {
          ...item,
          archivedDate: archivedDate
        });
        await this.loadRequests();
      } catch (error) {
        this.handleError(error as Error, "workpackages.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    // *** EXISTING WORK AND CONFLICTS ***

    scaffoldRequestScaffoldHasPotentialConflict(item: ScaffoldRequestWithExtraDetails): boolean {
      let isDismantle = item.requestType == ScaffoldRequestTypes.Dismantle;

      let scaffoldSubmittedRequestIDs = item.scaffoldSubmittedRequestIDs ?? [];
      let scaffoldDismantleRequestIDs = item.scaffoldDismantleRequestIDs ?? [];
      let allDismantleWorkOrderIDs = item.scaffoldDismantleWorkOrderIDs ?? [];
      let allActiveWorkOrderIDs = item.scaffoldActiveWorkOrderIDs ?? [];

      let otherScaffoldRequestIDs = scaffoldSubmittedRequestIDs.filter(x => x != item.id);
      let hasOtherWork = allActiveWorkOrderIDs.length > 0 || otherScaffoldRequestIDs.length > 0;

      let nonDismantleRequestIDs = scaffoldSubmittedRequestIDs.filter(
        x => !scaffoldDismantleRequestIDs.includes(x)
      );
      let nonDismantleWorkOrderIDs = allActiveWorkOrderIDs.filter(
        x => !allDismantleWorkOrderIDs.includes(x)
      );

      let currentIsDismantleWithOtherWork = isDismantle && hasOtherWork;

      let totalDismantleItemCount =
        allDismantleWorkOrderIDs.length + scaffoldDismantleRequestIDs.length;
      let scaffoldHasMultipleDismantles = totalDismantleItemCount > 1;

      let scaffoldHasActiveDismantleWithOtherWork =
        totalDismantleItemCount > 0 &&
        (nonDismantleRequestIDs.length > 0 || nonDismantleWorkOrderIDs.length > 0);
      return (
        currentIsDismantleWithOtherWork ||
        scaffoldHasMultipleDismantles ||
        scaffoldHasActiveDismantleWithOtherWork
      );
    },

    // ANY other work (other requests, or any active WOs)
    scaffoldRequestScaffoldOtherSubmittedRequestIDs(
      scaffoldRequest: ScaffoldRequestWithDetails
    ): string[] {
      let allRequestIDs = scaffoldRequest.scaffoldSubmittedRequestIDs ?? [];
      let otherRequestIDs = allRequestIDs.filter(x => x != scaffoldRequest.id);
      return otherRequestIDs;
    },
    scaffoldRequestScaffoldHasOtherActiveWork(
      scaffoldRequest: ScaffoldRequestWithDetails
    ): boolean {
      let hasOtherActiveRequests =
        this.scaffoldRequestScaffoldOtherSubmittedRequestIDs(scaffoldRequest).length > 0;
      let hasActiveWorkOrders = !!scaffoldRequest.scaffoldActiveWorkOrderIDs?.length;
      return !!scaffoldRequest.scaffoldID && (hasOtherActiveRequests || hasActiveWorkOrders);
    },

    // Other DISMANTLE Work (other dismantle requests, or any active dismantle WOs)
    scaffoldRequestScaffoldOtherDismantleScaffoldRequestIDs(
      scaffoldRequest: ScaffoldRequestWithDetails
    ): string[] {
      let dismantleIDs = scaffoldRequest.scaffoldDismantleRequestIDs ?? [];
      let otherDismantleIDs = dismantleIDs.filter(x => x != scaffoldRequest.id);
      return otherDismantleIDs;
    },

    scaffoldRequestScaffoldHasOtherDismantleWork(
      scaffoldRequest: ScaffoldRequestWithDetails
    ): boolean {
      let hasOtherDismantleRequests =
        this.scaffoldRequestScaffoldOtherDismantleScaffoldRequestIDs(scaffoldRequest).length > 0;
      let hasActiveDismantleWorkOrders = !!scaffoldRequest.scaffoldDismantleWorkOrderIDs?.length;
      return (
        !!scaffoldRequest.scaffoldID && (hasOtherDismantleRequests || hasActiveDismantleWorkOrders)
      );
    },

    async openWorkDetailsForScaffoldDialog(
      item: ScaffoldRequestWithExtraDetails,
      approvingDismantleRequest: boolean
    ): Promise<boolean> {
      var submittedRequests = [] as ScaffoldRequestWithExtraDetails[];
      let requestIDs = item.scaffoldSubmittedRequestIDs ?? [];
      requestIDs.forEach(otherRequestId => {
        var loadedScaffoldRequest = this.allScaffoldRequests.find(x => x.id == otherRequestId);
        if (!!loadedScaffoldRequest) {
          submittedRequests.push(loadedScaffoldRequest);
        }
      });
      // If the current item isn't included, that means there's an active work order instead
      // However we still include it so that something shows.
      // The dialog will replace it after loading the data anyway
      if (!requestIDs.includes(item.id!)) submittedRequests.push(item);
      var convertedRequests = submittedRequests.map(x =>
        WorkForScaffoldDetailsFromScaffoldRequest(x)
      );

      let result = await openActiveWorkForScaffoldDialog(
        item.scaffoldNumber,
        convertedRequests,
        item.id,
        false, // This is never starting a dismantle Work Order
        approvingDismantleRequest,
        false
      );
      if (!approvingDismantleRequest && result) {
        await this.reloadTableData();
      }
      return result;
    },

    // *** DATA LOADING ***

    // DOES NOT manage processing or error message logic
    async loadContractors(): Promise<void> {
      this.allContractors = await contractorService.getAll(false, null, null);
    },

    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getAll(false, null, null);
      this.allDisciplines = disciplines;
    },

    // DOES NOT manage processing or error message logic
    async loadCurrentUserDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getByPersonID(this.curUserID);
      this.currentUserDisciplines = disciplines;
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },

    // *** SCAFF BADGE COUNTS ***
    requestOtherRequestsBadgeCount(request: ScaffoldRequestWithExtraDetails): number {
      return this.scaffoldRequestScaffoldOtherSubmittedRequestIDs(request).length;
    },

    requestRequestsBadgeCount(request: ScaffoldRequestWithExtraDetails): number {
      return request.scaffoldSubmittedRequestIDs?.length ?? 0;
    },

    requestWorkOrderBadgeCount(request: ScaffoldRequestWithExtraDetails): number {
      return request.scaffoldActiveWorkOrderIDs?.length ?? 0;
    },

    requestOtherWorkOrderBadgeCount(request: ScaffoldRequestWithExtraDetails): number {
      let allActiveWorkOrderIDs = request.scaffoldActiveWorkOrderIDs ?? [];
      let otherRequestIDs = allActiveWorkOrderIDs.filter(x => x != request.relatedWorkOrderID);
      return otherRequestIDs.length;
    },

    requestDismantleBadgeCount(request: ScaffoldRequestWithExtraDetails): number {
      return request.scaffoldDismantleWorkOrderIDs?.length ?? 0;
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});
