var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("fd-privacy-dialog"),
      _vm._v(" "),
      _c("fd-terms-dialog"),
      _vm._v(" "),
      _c("div", { staticClass: "sign-in-panel-overall-back-panel" }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            {
              "appear-nicely": _vm.showAccessSuccessfullyRequestedMessage,
              "disappear-nicely": !_vm.showAccessSuccessfullyRequestedMessage,
              "signup-screen-floating-placement-mobile-override":
                _vm.smallishMobileDevice || _vm.smallWidthBrowser
            },
            "signup-screen-floating-placement",
            "sign-in-controls-container"
          ],
          staticStyle: { opacity: "0", "pointer-events": "none" }
        },
        [
          _c(
            "span",
            {
              class: {
                "signup-panel-title-text-regular":
                  !_vm.smallishMobileDevice && !_vm.smallWidthBrowser,
                "signup-panel-title-text-smaller":
                  _vm.smallishMobileDevice || _vm.smallWidthBrowser
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.isRequestingAccess
                      ? _vm.$t("signup.access-requested-title")
                      : _vm.$t("signup.enrolment-requested-title")
                  ) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              class: [
                {
                  "mt-5": !_vm.landscapeOrientation
                },
                "ml-0",
                "mr-0"
              ]
            },
            [
              _c(
                "span",
                {
                  class: {
                    "signup-panel-sub-title-text-regular":
                      !_vm.smallishMobileDevice && !_vm.smallWidthBrowser,
                    "signup-panel-sub-title-text-smaller":
                      _vm.smallishMobileDevice || _vm.smallWidthBrowser
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isRequestingAccess
                          ? _vm.$t("signup.access-requested-explanation")
                          : _vm.$t("signup.enrolment-requested-explanation", [
                              _vm.$store.state.applicationName
                            ])
                      ) +
                      "\n      "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              class: {
                "pt-7": !_vm.tabletMobileDevice,
                "pt-3": _vm.tabletMobileDevice
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.processing,
                    text: "",
                    small: "",
                    to: "/login"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("signup.go-back-to-sign-in-link")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            {
              "appear-nicely": _vm.showAccessCodeEntryForActivation,
              "disappear-nicely": !_vm.showAccessCodeEntryForActivation,
              "signup-screen-floating-placement-mobile-override":
                _vm.smallishMobileDevice || _vm.smallWidthBrowser
            },
            "signup-screen-floating-placement",
            "sign-in-controls-container"
          ],
          staticStyle: { opacity: "0", "pointer-events": "none" }
        },
        [
          _c(
            "v-form",
            { ref: "accessCodeEntry", on: { submit: _vm.processAccessCode } },
            [
              _vm.inlineMessage.message
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-5 ml-0 mr-0",
                      attrs: { type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: {
                    "signup-panel-title-text-regular":
                      !_vm.smallishMobileDevice && !_vm.smallWidthBrowser,
                    "signup-panel-title-text-smaller":
                      _vm.smallishMobileDevice || _vm.smallWidthBrowser
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          "signup.quick-access-code-request-response-title"
                        )
                      ) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.showAccessCodeEntryForActivation
                ? _c(
                    "v-row",
                    {
                      class: [
                        {
                          "mt-5": !_vm.landscapeOrientation
                        },
                        "ml-0",
                        "mr-0"
                      ]
                    },
                    [
                      !!_vm.accessCodeChallenge
                        ? _c(
                            "span",
                            {
                              class: {
                                "signup-panel-sub-title-text-regular":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser,
                                "signup-panel-sub-title-text-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    !!_vm.accessCodeChallenge
                                      .sentToPhoneNumberTail &&
                                      _vm.accessCodeChallenge
                                        .sentToEmailAddresses.length > 0
                                      ? _vm.$t(
                                          "signup.quick-access-code-communication-email-and-phone-verification-explanation"
                                        )
                                      : _vm.accessCodeChallenge
                                          .sentToEmailAddresses.length > 0
                                      ? _vm.$t(
                                          "signup.quick-access-code-communication-email-verification-explanation"
                                        )
                                      : _vm.$t(
                                          "signup.quick-access-code-communication-phone-verification-explanation"
                                        )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !!_vm.accessCodeChallenge &&
              _vm.accessCodeChallenge.sentToEmailAddresses.length > 0
                ? _c(
                    "v-row",
                    {
                      class: [
                        {
                          "mt-5": !_vm.landscapeOrientation,
                          "mt-1": _vm.landscapeOrientation
                        },
                        "ml-0",
                        "mr-0"
                      ]
                    },
                    [
                      !!_vm.accessCodeChallenge &&
                      _vm.accessCodeChallenge.sentToEmailAddresses.length == 1
                        ? _c(
                            "span",
                            { staticStyle: { "font-size": "1.0em" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "signup.quick-access-code-request-response-inline-email-statement"
                                  )
                                ) + "\n          "
                              ),
                              _c(
                                "span",
                                { staticClass: "signup-call-out-text-style" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "fd-restrict-contact-text-width"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            !!_vm.accessCodeChallenge &&
                                              _vm.accessCodeChallenge
                                                .sentToEmailAddress
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _c(
                            "span",
                            { staticStyle: { "font-size": "1.0em" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "signup.quick-access-code-request-response-inline-multiple-emails-statement"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !!_vm.accessCodeChallenge &&
              _vm.accessCodeChallenge.sentToPhoneNumbers.length > 0
                ? _c(
                    "v-row",
                    {
                      class: [
                        { "mt-5": !_vm.landscapeOrientation },
                        "ml-0",
                        "mr-0"
                      ]
                    },
                    [
                      !!_vm.accessCodeChallenge &&
                      _vm.accessCodeChallenge.sentToPhoneNumbers.length == 1
                        ? _c(
                            "span",
                            { staticStyle: { "font-size": "1.0em" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    !!_vm.accessCodeChallenge.sentToEmailAddress
                                      ? _vm.$t(
                                          "signup.quick-access-code-request-response-inline-phone-same-statement"
                                        )
                                      : _vm.$t(
                                          "signup.quick-access-code-request-response-inline-phone-statement"
                                        )
                                  ) +
                                  "\n          "
                              ),
                              _c(
                                "span",
                                { staticClass: "signup-call-out-text-style" },
                                [
                                  _vm._v(
                                    "***-***-*" +
                                      _vm._s(
                                        !!_vm.accessCodeChallenge &&
                                          _vm.accessCodeChallenge
                                            .sentToPhoneNumberTail
                                      )
                                  )
                                ]
                              ),
                              _vm._v(".\n        ")
                            ]
                          )
                        : _c(
                            "span",
                            { staticStyle: { "font-size": "1.0em" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "signup.quick-access-code-request-response-inline-multiple-phones-statement"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("fd-code-entry", {
                ref: "accesscodeentry",
                class: ["pt-5"],
                staticStyle: { "margin-left": "-6px" },
                attrs: { disabled: _vm.processing, value: _vm.accesscode },
                on: { codeEntered: _vm.codeEntered }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ml-0 mr-0 mt-1" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      staticStyle: { "margin-left": "-25px" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: "",
                            disabled: _vm.processing
                          },
                          on: { click: _vm.startOverFromAccessCodeEntry }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.start-over")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      staticStyle: { "margin-left": "-25px" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.processing,
                            text: "",
                            small: ""
                          },
                          on: { click: _vm.resendAccessCode }
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.resend-code-label")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            {
              "appear-nicely": _vm.showAccountInfo,
              "disappear-nicely": !_vm.showAccountInfo,
              "signup-screen-floating-placement-mobile-override":
                _vm.smallishMobileDevice || _vm.smallWidthBrowser
            },
            "signup-screen-floating-placement",
            "sign-in-controls-container"
          ]
        },
        [
          _c(
            "v-form",
            { ref: "enterAccountInfoForm", on: { submit: _vm.sendAccessCode } },
            [
              _vm.inlineMessage.message &&
              !_vm.smallishMobileDevice &&
              !_vm.smallWidthBrowser
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-5 ml-0 mr-0",
                      attrs: { type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inlineMessage.message &&
              (_vm.smallishMobileDevice || _vm.smallWidthBrowser)
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-0 mb-0 ml-0 mr-0",
                      attrs: { dense: "", type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ml-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pl-0 pb-0 grow", attrs: { md: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "ml-0 fd-position-relative",
                          attrs: { justify: "start" }
                        },
                        [
                          _c(
                            "span",
                            {
                              class: {
                                "signup-panel-title-text-regular":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser,
                                "signup-panel-title-text-smaller":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser,
                                "signup-panel-title-text-medium":
                                  _vm.tabletMobileDevice &&
                                  !_vm.smallishMobileDevice,
                                "login-panel-title-text-regular":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser &&
                                  !_vm.tabletMobileDevice,
                                "signup-panel-title-text-landscape-mobile":
                                  _vm.smallishMobileDevice &&
                                  _vm.landscapeOrientation
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("signup.title", [
                                    _vm.$store.state.applicationName
                                  ])
                                )
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "ml-0 mb-5",
                          attrs: { justify: "start" }
                        },
                        [
                          _c(
                            "span",
                            {
                              class: {
                                "signup-panel-sub-title-text":
                                  !_vm.smallishMobileDevice &&
                                  !_vm.smallWidthBrowser,
                                "signup-panel-title-text-smallest":
                                  _vm.smallishMobileDevice ||
                                  _vm.smallWidthBrowser
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  (_vm.smallishMobileDevice ||
                                    _vm.smallWidthBrowser) &&
                                    !_vm.landscapeOrientation
                                    ? _vm.$t("signup.sub-title-short")
                                    : _vm.$t("signup.sub-title")
                                )
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      class: [
                        "shrink",
                        {
                          hidden:
                            !_vm.smallishMobileDevice && !_vm.smallWidthBrowser,
                          "pb-0": _vm.landscapeOrientation
                        }
                      ],
                      attrs: { md: "0" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pt-0 pb-0", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        bottom: "",
                                        left: "",
                                        "offset-y": "",
                                        origin: "top right",
                                        transition: "scale-transition",
                                        disabled: _vm.processing
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function({ attrs, on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        "min-width": "0",
                                                        text: ""
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-translate")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "appbar-menu" },
                                        [
                                          _c(
                                            "v-list",
                                            { attrs: { tile: false, nav: "" } },
                                            [
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  model: {
                                                    value: _vm.language,
                                                    callback: function($$v) {
                                                      _vm.language = $$v
                                                    },
                                                    expression: "language"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.languageslist,
                                                  function(languagechoice) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: languagechoice.name
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    languagechoice.name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                class: [
                                                                  _vm.language ==
                                                                  languagechoice.number
                                                                    ? "selected-menu-icon-color"
                                                                    : "unselected-menu-icon-color"
                                                                ]
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fas fa-language"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !(_vm.smallishMobileDevice && _vm.landscapeOrientation)
                ? _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            ref: "firstnameinput",
                            staticClass: "mt-3 mb-0 mr-2",
                            attrs: {
                              label: _vm.$t("signup.first-name"),
                              "data-cy": "firstname",
                              outlined: "",
                              disabled: _vm.processing,
                              dense:
                                _vm.smallishMobileDevice ||
                                _vm.smallWidthBrowser ||
                                _vm.tabletMobileDevice,
                              rules: _vm.signupRules.firstName
                            },
                            model: {
                              value: _vm.firstname,
                              callback: function($$v) {
                                _vm.firstname = $$v
                              },
                              expression: "firstname"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            ref: "lastnameinput",
                            staticClass: "mt-3 mb-0 ml-2",
                            attrs: {
                              label: _vm.$t("signup.last-name"),
                              "data-cy": "lastname",
                              outlined: "",
                              disabled: _vm.processing,
                              dense:
                                _vm.smallishMobileDevice ||
                                _vm.smallWidthBrowser ||
                                _vm.tabletMobileDevice,
                              rules: _vm.signupRules.lastName
                            },
                            model: {
                              value: _vm.lastname,
                              callback: function($$v) {
                                _vm.lastname = $$v
                              },
                              expression: "lastname"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.smallishMobileDevice && _vm.landscapeOrientation
                ? _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { cols: 4 } },
                        [
                          _c("v-text-field", {
                            ref: "firstnameinput",
                            staticClass: "mt-3 mb-0 mr-1",
                            attrs: {
                              label: _vm.$t("signup.first-name"),
                              "data-cy": "firstname",
                              outlined: "",
                              disabled: _vm.processing,
                              dense:
                                _vm.smallishMobileDevice ||
                                _vm.smallWidthBrowser ||
                                _vm.tabletMobileDevice,
                              rules: _vm.signupRules.firstName
                            },
                            model: {
                              value: _vm.firstname,
                              callback: function($$v) {
                                _vm.firstname = $$v
                              },
                              expression: "firstname"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { cols: 4 } },
                        [
                          _c("v-text-field", {
                            ref: "lastnameinput",
                            staticClass: "mt-3 mb-0 ml-1 mr-1",
                            attrs: {
                              label: _vm.$t("signup.last-name"),
                              "data-cy": "lastname",
                              outlined: "",
                              disabled: _vm.processing,
                              dense:
                                _vm.smallishMobileDevice ||
                                _vm.smallWidthBrowser ||
                                _vm.tabletMobileDevice,
                              rules: _vm.signupRules.lastName
                            },
                            model: {
                              value: _vm.lastname,
                              callback: function($$v) {
                                _vm.lastname = $$v
                              },
                              expression: "lastname"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { cols: 4 } },
                        [
                          _c("v-select", {
                            ref: "contractorinput",
                            staticClass: "mt-3 mb-0 ml-1 mr-1",
                            attrs: {
                              label: _vm.$t("signup.contractor"),
                              items: _vm.contractors,
                              "data-cy": "contractor",
                              outlined: "",
                              disabled: _vm.processing,
                              dense:
                                _vm.smallishMobileDevice ||
                                _vm.smallWidthBrowser ||
                                _vm.tabletMobileDevice,
                              rules: _vm.signupRules.contractor
                            },
                            model: {
                              value: _vm.contractorID,
                              callback: function($$v) {
                                _vm.contractorID = $$v
                              },
                              expression: "contractorID"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !(_vm.smallishMobileDevice && _vm.landscapeOrientation)
                ? _c("v-select", {
                    ref: "contractorinput",
                    staticClass: "mt-0 mb-0",
                    attrs: {
                      label: _vm.$t("signup.contractor"),
                      items: _vm.contractors,
                      "data-cy": "contractor",
                      outlined: "",
                      disabled: _vm.processing,
                      dense:
                        _vm.smallishMobileDevice ||
                        _vm.smallWidthBrowser ||
                        _vm.tabletMobileDevice,
                      rules: _vm.signupRules.contractor
                    },
                    model: {
                      value: _vm.contractorID,
                      callback: function($$v) {
                        _vm.contractorID = $$v
                      },
                      expression: "contractorID"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !(_vm.smallishMobileDevice && _vm.landscapeOrientation)
                ? _c("v-select", {
                    ref: "disciplineinput",
                    staticClass: "mt-0 mb-0",
                    attrs: {
                      label: _vm.$t("signup.discipline"),
                      items: _vm.disciplines,
                      "data-cy": "discipline",
                      outlined: "",
                      disabled: _vm.processing,
                      dense:
                        _vm.smallishMobileDevice ||
                        _vm.smallWidthBrowser ||
                        _vm.tabletMobileDevice,
                      rules: _vm.signupRules.discipline
                    },
                    model: {
                      value: _vm.disciplineID,
                      callback: function($$v) {
                        _vm.disciplineID = $$v
                      },
                      expression: "disciplineID"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !(_vm.smallishMobileDevice && _vm.landscapeOrientation)
                ? _c("v-text-field", {
                    ref: "usernameinput",
                    staticClass: "mt-0 mb-0",
                    attrs: {
                      type: "email",
                      autocomplete: "username",
                      "data-cy": "emailAddressOrPhoneNumber",
                      label:
                        _vm.smallishMobileDevice || _vm.smallWidthBrowser
                          ? _vm.$t("signup.email-or-phone-short-input-label")
                          : _vm.$t("signup.email-or-phone-input-label"),
                      outlined: "",
                      disabled: _vm.processing,
                      dense:
                        _vm.smallishMobileDevice ||
                        _vm.smallWidthBrowser ||
                        _vm.tabletMobileDevice,
                      rules: _vm.signupRules.emailAddressOrPhoneNumber
                    },
                    model: {
                      value: _vm.emailAddressOrPhoneNumber,
                      callback: function($$v) {
                        _vm.emailAddressOrPhoneNumber = $$v
                      },
                      expression: "emailAddressOrPhoneNumber"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.smallishMobileDevice && _vm.landscapeOrientation
                ? _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            ref: "usernameinput",
                            class: ["mr-1", "mt-0", "mb-0"],
                            attrs: {
                              type: "email",
                              autocomplete: "username",
                              "data-cy": "emailAddressOrPhoneNumber",
                              label: _vm.$t(
                                "signup.email-or-phone-short-input-label"
                              ),
                              outlined: "",
                              disabled: _vm.processing,
                              dense:
                                _vm.smallishMobileDevice ||
                                _vm.smallWidthBrowser ||
                                _vm.tabletMobileDevice,
                              rules: _vm.signupRules.emailAddressOrPhoneNumber
                            },
                            model: {
                              value: _vm.emailAddressOrPhoneNumber,
                              callback: function($$v) {
                                _vm.emailAddressOrPhoneNumber = $$v
                              },
                              expression: "emailAddressOrPhoneNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { cols: 4 } },
                        [
                          _c("v-select", {
                            ref: "disciplineinput",
                            class: ["mx-1", "mt-0", "mb-0"],
                            attrs: {
                              label: _vm.$t("signup.discipline"),
                              items: _vm.disciplines,
                              "data-cy": "discipline",
                              outlined: "",
                              disabled: _vm.processing,
                              dense:
                                _vm.smallishMobileDevice ||
                                _vm.smallWidthBrowser ||
                                _vm.tabletMobileDevice,
                              rules: _vm.signupRules.discipline
                            },
                            model: {
                              value: _vm.disciplineID,
                              callback: function($$v) {
                                _vm.disciplineID = $$v
                              },
                              expression: "disciplineID"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ma-0 pb-3" },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-switch", {
                    staticClass: "my-0 pb-3",
                    attrs: {
                      label: "I also need to log in.",
                      disabled: _vm.processing,
                      "hide-details": "",
                      dense:
                        _vm.smallishMobileDevice ||
                        _vm.smallWidthBrowser ||
                        _vm.tabletMobileDevice
                    },
                    model: {
                      value: _vm.isRequestingAccess,
                      callback: function($$v) {
                        _vm.isRequestingAccess = $$v
                      },
                      expression: "isRequestingAccess"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              !_vm.smallishMobileDevice && !_vm.smallWidthBrowser
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "marketing-main-color-button-style width-100 mt-5",
                          attrs: {
                            loading: _vm.processing,
                            disabled: _vm.processing,
                            type: "submit",
                            depressed: "",
                            "x-large": ""
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.next-label")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          class: {
                            "pt-7": !_vm.tabletMobileDevice,
                            "pt-3": _vm.tabletMobileDevice
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.processing,
                                text: "",
                                small: "",
                                to: "/"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("signup.return-to-sign-in-link"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.smallishMobileDevice || _vm.smallWidthBrowser) &&
              !_vm.landscapeOrientation
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "marketing-main-color-button-style width-100 mt-3",
                          attrs: {
                            loading: _vm.processing,
                            disabled: _vm.processing,
                            type: "submit",
                            depressed: ""
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("signup.next-label")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pt-6", attrs: { justify: "end" } },
                        [
                          _c("v-col", {
                            staticClass: "pl-0 pr-0 pt-0",
                            attrs: { cols: "6" }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pr-3",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.logoSource,
                                      "max-height": _vm.brandImageHeight,
                                      height: _vm.brandImageHeight,
                                      "max-width": _vm.brandImageWidth,
                                      width: _vm.brandImageWidth
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          class: {
                            "pt-7": !_vm.tabletMobileDevice,
                            "pt-3": _vm.tabletMobileDevice
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.processing,
                                text: "",
                                small: "",
                                to: "/"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("signup.return-to-sign-in-link"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.smallishMobileDevice && _vm.landscapeOrientation
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "7" } },
                        [
                          _c(
                            "v-btn",
                            {
                              class: [
                                "marketing-main-color-button-style",
                                "width-100"
                              ],
                              attrs: {
                                loading: _vm.processing,
                                disabled: _vm.processing,
                                type: "submit",
                                depressed: "",
                                large: "",
                                "x-large": !_vm.smallishMobileDevice
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.next-label")))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "5" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "height-100",
                              attrs: { justify: "center", align: "start" }
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: _vm.logoSource,
                                  "max-height": _vm.brandImageHeight,
                                  height: _vm.brandImageHeight,
                                  "max-width": _vm.brandImageWidth,
                                  width: _vm.brandImageWidth
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.smallishMobileDevice && _vm.landscapeOrientation
                ? _c(
                    "v-row",
                    {
                      class: [
                        {
                          "pt-7": !_vm.tabletMobileDevice,
                          "pt-3": _vm.tabletMobileDevice
                        }
                      ]
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.processing,
                            text: "",
                            small: "",
                            to: "/"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("signup.return-to-sign-in-link"))
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "wedge-cover hide-when-medium" }),
      _vm._v(" "),
      !_vm.smallishMobileDevice && !_vm.smallWidthBrowser
        ? _c("div", { staticClass: "sign-in-accent-panel" })
        : _vm._e(),
      _vm._v(" "),
      !_vm.smallishMobileDevice && !_vm.smallWidthBrowser
        ? _c(
            "div",
            {
              staticClass: "sign-in-accent-panel-logo-placement",
              style: "top:" + _vm.logobrandImageTopValue + "px;"
            },
            [
              _c(
                "v-row",
                { attrs: { align: "end", justify: "end" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        top: "",
                        right: "",
                        "offset-y": "",
                        origin: "bottom left",
                        transition: "scale-transition",
                        disabled: _vm.processing
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function({ attrs, on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { "min-width": "0", text: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "login-panel-language-button-style",
                                        attrs: { color: "white" }
                                      },
                                      [_vm._v("mdi-translate")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        770119138
                      )
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "appbar-menu" },
                        [
                          _c(
                            "v-list",
                            { attrs: { tile: false, nav: "" } },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  model: {
                                    value: _vm.language,
                                    callback: function($$v) {
                                      _vm.language = $$v
                                    },
                                    expression: "language"
                                  }
                                },
                                _vm._l(_vm.languageslist, function(
                                  languagechoice
                                ) {
                                  return _c(
                                    "v-list-item",
                                    { key: languagechoice.name },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(languagechoice.name))
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              class: [
                                                _vm.language ==
                                                languagechoice.number
                                                  ? "selected-menu-icon-color"
                                                  : "unselected-menu-icon-color"
                                              ]
                                            },
                                            [_vm._v("fas fa-language")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "signup-panel-full-size-privacy-terms-button-style",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.setPrivacyDialog(!_vm.dialogPrivacy)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("privacy.link-label")) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "mr-5 signup-panel-full-size-privacy-terms-button-style",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.setTermsDialog(!_vm.dialogPrivacy)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("terms.link-label")) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-img", {
                    attrs: {
                      src: _vm.logoReversedSource,
                      "max-height": _vm.brandImageHeight,
                      height: _vm.brandImageHeight,
                      "max-width": _vm.brandImageWidth,
                      width: _vm.brandImageWidth
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }