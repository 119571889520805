import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective
} from "@fd/lib/vue/utility/dataTable";
import { mapMutations, mapActions } from "vuex";
import { EquipmentWithDetails } from "../services";
import archivedDataList from "../dataMixins/archivedDataList";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import rules from "@fd/lib/vue/rules";
import { showEquipmentNewDialog } from "./components/dialogs/SP.EquipmentNewDialog.vue";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import { contractorDataStore } from "../store";
import { filterByContractors, filterByTags } from "../services/taggableItems";

type FormattedEquipment = EquipmentWithDetails & {
  contractorName: string | null | undefined;
  archived: boolean;
};

export default FDVue.extend({
  name: "sp-equipment-list",

  // archivedDataList already uses the serviceErrorHandling
  mixins: [archivedDataList, userAccess, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },
  components: {
    "sp-equipment-list-table": () => import("./components/SP.EquipmentListTable.vue")
  },

  data: function() {
    return {
      deleting: false,
      // Table Footer page size options
      itemsPerPage: 100,
      itemsPerPageOptions: [5, 25, 50, 100, -1]
    };
  },

  computed: {
    equipment(): FormattedEquipment[] {
      var storeEquipment = this.$store.state.equipment.fullList as EquipmentWithDetails[];
      let equipment = storeEquipment.map(
        x =>
          ({
            ...x,
            description: truncateWithEllipsis(stripHtml(x.description)),
            contractorName: contractorDataStore.lookupCaption(x.contractorID ?? null),
            archived: !!x.archivedDate
          } as FormattedEquipment)
      );
      return filterByContractors(
        this.contractorsSelectedForFiltering,
        filterByTags(this.tagsSelectedForFiltering, equipment)
      );
    },
    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },
    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },
    contractorsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },
    tagsInUse() {
      return this.$store.getters.getSortedInUseTags(this.$store.state.equipment.fullList);
    },
    contractorsInUse() {
      return this.$store.getters.getSortedInUseContractors(this.$store.state.equipment.fullList);
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapActions({
      loadStoreEquipment: "LOAD_EQUIPMENTS",
      updateEquipment: "UPDATE_EQUIPMENT",
      deleteEquipment: "DELETE_EQUIPMENT",
      loadTags: "LOAD_TAGS",
      loadContractors: "LOAD_CONTRACTORS"
    }),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    async openNewDialog() {
      await showEquipmentNewDialog();
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadData() {
      await this.loadStoreEquipment({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
    },

    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteEquipment(item);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    async flipArchived(item: FormattedEquipment) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateEquipment({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "equipment",
      parentalContext: null,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      contractorsForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });

    this.notifyNewBreadcrumb({
      text: this.$t("equipment.list.title"),
      to: "/equipment",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadTags(), this.loadContractors(), this.reloadTableData()]);
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});
