import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import userAccess from "../dataMixins/userAccess";
import { mapActions, mapMutations } from "vuex";
import { ContractorWithTags, EquipmentClassification, EquipmentWithDetails } from "../services";
import rules from "@fd/lib/vue/rules";
import { showEquipmentClassificationHistoryDialog } from "./components/dialogs/SP.EquipmentClassificationHistoryDialog.vue";

type EquipmentWithArchived = EquipmentWithDetails & { isArchived: boolean };

export default FDVue.extend({
  name: "sp-equipment-existing",

  mixins: [userAccess, serviceErrorHandling, rules],

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue")
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      equipment: {} as EquipmentWithArchived,
      selectedTags: [] as any[],

      //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
      slidein: false
    };
  },

  computed: {
    equipmentRules() {
      return {
        name: [this.rules.required],
        serialNumber: [],
        contractorID: [],
        equipmentClassificationID: [],
        description: [],
        manufacturer: [],
        modelNumber: [],
        modelYear: [],
        unitColor: []
      };
    },
    availableTags(): any[] {
      return this.$store.getters.sortedEnabledTags;
    },
    canEditContractor(): boolean {
      return true;
    },
    contractors(): ContractorWithTags[] {
      return this.$store.getters.sortedEnabledContractors as ContractorWithTags[];
    },
    canEditEquipmentClassification(): boolean {
      return true;
    },
    equipmentClassifications(): EquipmentClassification[] {
      let allClassifications = this.$store.getters
        .sortedEnabledEquipmentClassifications as EquipmentClassification[];
      if (!allClassifications?.length) return [];

      let contractor = this.contractors.find(x => x.id == this.equipment.contractorID);
      if (!contractor) return [];

      let classificationIDs = contractor.equipmentClassifications?.map(
        x => x.equipmentClassificationID
      );
      if (!classificationIDs?.length) return [];

      return allClassifications.filter(x => classificationIDs!.includes(x.id!));
    }
  },

  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadEquipment: "LOAD_EQUIPMENT",
      updateEquipment: "UPDATE_EQUIPMENT",
      deleteEquipment: "DELETE_EQUIPMENT",
      loadTags: "LOAD_TAGS",
      loadContractors: "LOAD_CONTRACTORS",
      loadEquipmentClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS"
    }),
    cancel() {
      this.$router.push("/equipment");
    },
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        var archivedDate = null;
        if (this.equipment.isArchived && !this.equipment.archivedDate) {
          archivedDate = new Date(new Date().toUTCString());
        }
        this.equipment.archivedDate = archivedDate;

        this.equipment.created = undefined;
        this.equipment.createdBy = undefined;
        this.equipment.updated = undefined;
        this.equipment.updatedBy = undefined;

        var selectedTagIDs =
          this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id!) : null;

        await this.updateEquipment({
          ...this.equipment,
          tagIDs: selectedTagIDs
        });
        if (closeOnComplete) {
          this.$router.push("/equipment");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteEquipment({
          id: this.$route.params.id,
          name: this.equipment.name
        });
        this.$router.push("/equipment");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },
    classificationHistoryClicked() {
      showEquipmentClassificationHistoryDialog(this.equipment.classificationLogs);
    }
  },

  watch: {
    equipment() {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/equipment") {
        this.notifyNewBreadcrumb({
          text: this.$t("equipment.list.title"),
          to: "/equipment",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: this.equipment.name,
        to: `/equipment/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);
    this.processing = true;
    try {
      await Promise.all([
        this.loadTags(),
        this.loadContractors(),
        this.loadEquipmentClassifications(),
        this.loadEquipment(this.$route.params.id)
      ]);
      var storeEquipment = this.$store.state.equipment.fullList as EquipmentWithDetails[];
      let equipment = storeEquipment.find((x: any) => x.id == this.$route.params.id);

      this.equipment = {
        ...equipment,
        isArchived: !!equipment?.archivedDate
      } as EquipmentWithArchived;

      if (equipment?.tagIDs) {
        let tags = this.$store.state.tags.fullList;
        this.selectedTags = equipment.tagIDs
          .map((x: any) => tags.find((y: any) => y.id == x))
          .filter((x: any) => x);
      } else {
        this.selectedTags = [];
      }
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});
