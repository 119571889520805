var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "buildsheetdialog" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("norms.work-order-entry-dialog.title")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "font-size": "12px", "line-height": "1.5" } },
                [
                  !!_vm.workOrder.internalNumber
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-2 fd-status-chip",
                          attrs: { small: !_vm.$vuetify.breakpoint.lgAndUp }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-wo-number",
                                  [_vm.workOrder.internalNumber]
                                )
                              ) +
                              " -\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-request-type",
                                  [
                                    _vm.$t(
                                      `scaffold-requests.types.${_vm.workOrder.scaffoldRequestType}`
                                    ),
                                    _vm.$t(
                                      `scaffold-requests.sub-types.${_vm.workOrder.scaffoldRequestSubType}`
                                    )
                                  ]
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.workOrder.scaffoldNumber
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-2 fd-status-chip",
                          attrs: { small: !_vm.$vuetify.breakpoint.lgAndUp }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-tag-number",
                                  [_vm.workOrder.scaffoldNumber]
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.isScaffoldWorkOrder
            ? _c("sp-build-sheet-form-scaffold", {
                attrs: {
                  scaffoldTypeModifiers: _vm.allScaffoldTypes,
                  scaffoldElevationModifiers: _vm.allScaffoldElevations,
                  totalTimesheetHours: _vm.totalTimesheetHours,
                  processing: _vm.processing,
                  normGroupsWithResponses: _vm.normGroupsWithNorms,
                  length: _vm.buildSheet.length,
                  width: _vm.buildSheet.width,
                  height: _vm.buildSheet.height,
                  deckLevels: _vm.buildSheet.deckLevels,
                  hazardStatusResult: _vm.buildSheet.hazardLevel,
                  confinedSpace: _vm.buildSheet.confinedSpace,
                  ppeRequired: _vm.buildSheet.ppeRequired,
                  scaffoldTypeModifierID: _vm.buildSheet.scaffoldTypeModifierID,
                  scaffoldElevationModifierID:
                    _vm.buildSheet.scaffoldElevationModifierID,
                  notes: _vm.buildSheet.notes,
                  vlfChangeAmount: _vm.buildSheet.vlfChangeAmount,
                  currentScaffoldVlf: _vm.buildSheet.currentScaffoldVlf,
                  scaffoldTotalVLF: _vm.workOrder.scaffoldTotalVLF,
                  scaffoldRequestType: _vm.workOrder.scaffoldRequestType,
                  canEditBuildSheetResponses:
                    _vm.workOrder.currentUserPermissions
                      .canEditBuildSheetResponses
                },
                on: {
                  "update:normGroupsWithResponses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:norm-groups-with-responses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:length": function($event) {
                    return _vm.$set(_vm.buildSheet, "length", $event)
                  },
                  "update:width": function($event) {
                    return _vm.$set(_vm.buildSheet, "width", $event)
                  },
                  "update:height": function($event) {
                    return _vm.$set(_vm.buildSheet, "height", $event)
                  },
                  "update:deckLevels": function($event) {
                    return _vm.$set(_vm.buildSheet, "deckLevels", $event)
                  },
                  "update:deck-levels": function($event) {
                    return _vm.$set(_vm.buildSheet, "deckLevels", $event)
                  },
                  "update:hazardStatusResult": function($event) {
                    return _vm.$set(_vm.buildSheet, "hazardLevel", $event)
                  },
                  "update:hazard-status-result": function($event) {
                    return _vm.$set(_vm.buildSheet, "hazardLevel", $event)
                  },
                  "update:confinedSpace": function($event) {
                    return _vm.$set(_vm.buildSheet, "confinedSpace", $event)
                  },
                  "update:confined-space": function($event) {
                    return _vm.$set(_vm.buildSheet, "confinedSpace", $event)
                  },
                  "update:ppeRequired": function($event) {
                    return _vm.$set(_vm.buildSheet, "ppeRequired", $event)
                  },
                  "update:ppe-required": function($event) {
                    return _vm.$set(_vm.buildSheet, "ppeRequired", $event)
                  },
                  "update:scaffoldTypeModifierID": function($event) {
                    return _vm.$set(
                      _vm.buildSheet,
                      "scaffoldTypeModifierID",
                      $event
                    )
                  },
                  "update:scaffold-type-modifier-i-d": function($event) {
                    return _vm.$set(
                      _vm.buildSheet,
                      "scaffoldTypeModifierID",
                      $event
                    )
                  },
                  "update:scaffoldElevationModifierID": function($event) {
                    return _vm.$set(
                      _vm.buildSheet,
                      "scaffoldElevationModifierID",
                      $event
                    )
                  },
                  "update:scaffold-elevation-modifier-i-d": function($event) {
                    return _vm.$set(
                      _vm.buildSheet,
                      "scaffoldElevationModifierID",
                      $event
                    )
                  },
                  "update:notes": function($event) {
                    return _vm.$set(_vm.buildSheet, "notes", $event)
                  },
                  "update:vlfChangeAmount": function($event) {
                    return _vm.$set(_vm.buildSheet, "vlfChangeAmount", $event)
                  },
                  "update:vlf-change-amount": function($event) {
                    return _vm.$set(_vm.buildSheet, "vlfChangeAmount", $event)
                  }
                }
              })
            : _vm.isPaintWorkOrder
            ? _c("sp-build-sheet-form-paint", {
                attrs: {
                  processing: _vm.processing,
                  canEditBuildSheetResponses:
                    _vm.workOrder.currentUserPermissions
                      .canEditBuildSheetResponses,
                  normGroupsWithResponses: _vm.normGroupsWithNorms,
                  notes: _vm.buildSheet.notes,
                  applicationTypes: _vm.allApplicationTypes,
                  paintCodes: _vm.allPaintCodes,
                  setUpTypes: _vm.allSetUpTypes,
                  tsas: _vm.allTsas,
                  workCategories: _vm.allWorkCategories,
                  applicationTypeID: _vm.buildSheet.applicationTypeID,
                  paintCodeID: _vm.buildSheet.paintCodeID,
                  setUpTypeID: _vm.buildSheet.setUpTypeID,
                  tsaID: _vm.buildSheet.tsaID,
                  workCategoryID: _vm.buildSheet.workCategoryID,
                  equipmentNumber: _vm.buildSheet.equipmentNumber,
                  diameter: _vm.buildSheet.diameter,
                  squareFeet: _vm.buildSheet.squareFeet,
                  linearFeet: _vm.buildSheet.linearFeet,
                  paintReason: _vm.buildSheet.paintReason,
                  coatNumber: _vm.buildSheet.coatNumber
                },
                on: {
                  "update:normGroupsWithResponses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:norm-groups-with-responses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:notes": function($event) {
                    return _vm.$set(_vm.buildSheet, "notes", $event)
                  },
                  "update:applicationTypeID": function($event) {
                    return _vm.$set(_vm.buildSheet, "applicationTypeID", $event)
                  },
                  "update:application-type-i-d": function($event) {
                    return _vm.$set(_vm.buildSheet, "applicationTypeID", $event)
                  },
                  "update:paintCodeID": function($event) {
                    return _vm.$set(_vm.buildSheet, "paintCodeID", $event)
                  },
                  "update:paint-code-i-d": function($event) {
                    return _vm.$set(_vm.buildSheet, "paintCodeID", $event)
                  },
                  "update:setUpTypeID": function($event) {
                    return _vm.$set(_vm.buildSheet, "setUpTypeID", $event)
                  },
                  "update:set-up-type-i-d": function($event) {
                    return _vm.$set(_vm.buildSheet, "setUpTypeID", $event)
                  },
                  "update:tsaID": function($event) {
                    return _vm.$set(_vm.buildSheet, "tsaID", $event)
                  },
                  "update:tsa-i-d": function($event) {
                    return _vm.$set(_vm.buildSheet, "tsaID", $event)
                  },
                  "update:workCategoryID": function($event) {
                    return _vm.$set(_vm.buildSheet, "workCategoryID", $event)
                  },
                  "update:work-category-i-d": function($event) {
                    return _vm.$set(_vm.buildSheet, "workCategoryID", $event)
                  },
                  "update:equipmentNumber": function($event) {
                    return _vm.$set(_vm.buildSheet, "equipmentNumber", $event)
                  },
                  "update:equipment-number": function($event) {
                    return _vm.$set(_vm.buildSheet, "equipmentNumber", $event)
                  },
                  "update:diameter": function($event) {
                    return _vm.$set(_vm.buildSheet, "diameter", $event)
                  },
                  "update:squareFeet": function($event) {
                    return _vm.$set(_vm.buildSheet, "squareFeet", $event)
                  },
                  "update:square-feet": function($event) {
                    return _vm.$set(_vm.buildSheet, "squareFeet", $event)
                  },
                  "update:linearFeet": function($event) {
                    return _vm.$set(_vm.buildSheet, "linearFeet", $event)
                  },
                  "update:linear-feet": function($event) {
                    return _vm.$set(_vm.buildSheet, "linearFeet", $event)
                  },
                  "update:paintReason": function($event) {
                    return _vm.$set(_vm.buildSheet, "paintReason", $event)
                  },
                  "update:paint-reason": function($event) {
                    return _vm.$set(_vm.buildSheet, "paintReason", $event)
                  },
                  "update:coatNumber": function($event) {
                    return _vm.$set(_vm.buildSheet, "coatNumber", $event)
                  },
                  "update:coat-number": function($event) {
                    return _vm.$set(_vm.buildSheet, "coatNumber", $event)
                  }
                }
              })
            : _vm.isInsulationWorkOrder
            ? _c("sp-build-sheet-form-insulation", {
                attrs: {
                  processing: _vm.processing,
                  canEditBuildSheetResponses:
                    _vm.workOrder.currentUserPermissions
                      .canEditBuildSheetResponses,
                  normGroupsWithResponses: _vm.normGroupsWithNorms,
                  notes: _vm.buildSheet.notes
                },
                on: {
                  "update:normGroupsWithResponses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:norm-groups-with-responses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:notes": function($event) {
                    return _vm.$set(_vm.buildSheet, "notes", $event)
                  }
                }
              })
            : _vm.isInsulationWorkOrder
            ? _c("sp-build-sheet-form-maintenance", {
                attrs: {
                  processing: _vm.processing,
                  canEditBuildSheetResponses:
                    _vm.workOrder.currentUserPermissions
                      .canEditBuildSheetResponses,
                  normGroupsWithResponses: _vm.normGroupsWithNorms,
                  notes: _vm.buildSheet.notes
                },
                on: {
                  "update:normGroupsWithResponses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:norm-groups-with-responses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:notes": function($event) {
                    return _vm.$set(_vm.buildSheet, "notes", $event)
                  }
                }
              })
            : _c("sp-build-sheet-form-generic", {
                attrs: {
                  processing: _vm.processing,
                  canEditBuildSheetResponses:
                    _vm.workOrder.currentUserPermissions
                      .canEditBuildSheetResponses,
                  normGroupsWithResponses: _vm.normGroupsWithNorms,
                  notes: _vm.buildSheet.notes
                },
                on: {
                  "update:normGroupsWithResponses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:norm-groups-with-responses": function($event) {
                    _vm.normGroupsWithNorms = $event
                  },
                  "update:notes": function($event) {
                    return _vm.$set(_vm.buildSheet, "notes", $event)
                  }
                }
              }),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "px-3 pb-3" },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save-draft",
                    color: "primary",
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-and-close")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }