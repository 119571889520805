import FDVue from "@fd/lib/vue";
import { PropType } from "vue";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import { ContractorNorm, ContractorNormGroup } from "../../../services";
import { softCrafts } from "../../../dataMixins/softCrafts";

type FormattedContractorNormGroup = ContractorNormGroup & {
  archived: boolean;
};

export default FDVue.extend({
  name: "sp-contractor-existing-norm-groups-form",
  mixins: [softCrafts],
  props: {
    normGroups: { type: Array as PropType<Array<FormattedContractorNormGroup>>, default: () => [] },
    norms: { type: Array as PropType<Array<ContractorNorm>>, default: () => [] },
    processing: { type: Boolean, default: false },
    archivedLoading: { type: Boolean, default: false },
    showArchived: { type: Boolean, default: false },
    showArchivedDateRange: { type: Array as PropType<Array<Date>> },
    showArchivedMinDate: { type: Date },
    showArchivedMaxDate: { type: Date }
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },
  data: function() {
    return {
      searchStringForFiltering: ""
    };
  },
  computed: {
    managedShowArchived: {
      get(): Boolean {
        return this.showArchived;
      },
      set(val: Boolean) {
        this.$emit("update:showArchived", val);
      }
    },
    managedShowArchivedDateRange: {
      get(): Date[] {
        return this.showArchivedDateRange;
      },
      set(val: Date[]) {
        this.$emit("update:showArchivedDateRange", val);
      }
    }
  },
  methods: {
    normsForGroup(item: FormattedContractorNormGroup): ContractorNorm[] {
      return this.norms.filter(x => x.contractorNormGroupID == item.id);
    },
    normNavigationPath(item: ContractorNorm): string {
      return `/contractors/${this.$route.params.id}/normgroups/${item.contractorNormGroupID}/norms/${item.id}`;
    },
    navigateToNorm(item: ContractorNorm) {
      this.$router.push(this.normNavigationPath(item));
    },
    deleteNormsTableItem(item: ContractorNorm) {
      this.$emit("deleteNormsTableItem", item);
    },

    normGroupNavigationPath(item: FormattedContractorNormGroup): string {
      return `/contractors/${this.$route.params.id}/normgroups/${item.id}`;
    },
    navigateToGroup(item: FormattedContractorNormGroup) {
      this.$router.push(this.normGroupNavigationPath(item));
    },
    openNewNormGroupDialog() {
      this.$emit("openNewNormGroupDialog");
    },
    deleteNormGroupsTableItem(item: FormattedContractorNormGroup) {
      this.$emit("deleteNormGroupsTableItem", item);
    },
    flipNormGroupArchived(item: FormattedContractorNormGroup) {
      this.$emit("flipNormGroupArchived", item);
    },
    async dragNormEnded(e: SortableEvent) {
      this.$emit("dragNormEnded", e);
    },
    async dragNormGroupEnded(e: SortableEvent) {
      this.$emit("dragNormGroupEnded", e);
    }
  }
});
