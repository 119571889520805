import { WorkType } from "../services";

function CompareWorkTypes(a: WorkType, b: WorkType): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
export function SortWorkTypes<WT extends WorkType>(items: WT[] | null | undefined): WT[] {
  if (!items) return [];
  return items.sort(CompareWorkTypes);
}
