var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "px-0 mx-0" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("fd-text-field", {
                staticClass: "mb-3",
                attrs: {
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  label: _vm.$t("norms.work-order-entry-dialog.notes"),
                  value: _vm.notes,
                  "hide-details": ""
                },
                on: { input: _vm.notesUpdated }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("sp-build-sheet-norm-form", {
        attrs: {
          processing: _vm.processing,
          normGroupsWithResponses: _vm.normGroupsWithResponses,
          canEditBuildSheetResponses: _vm.canEditBuildSheetResponses
        },
        on: {
          "update:normGroupsWithResponses": function($event) {
            _vm.normGroupsWithResponses = $event
          },
          "update:norm-groups-with-responses": function($event) {
            _vm.normGroupsWithResponses = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }