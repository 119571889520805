import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective
} from "@fd/lib/vue/utility/dataTable";
import { createNewPerson } from "./components/dialogs/PersonNewDialog.vue";
import peopleList, { PersonWithExtraDetails } from "../dataMixins/peopleList";
import userAccess from "../dataMixins/userAccess";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { PeopleRow, PersonWithDetails, reportService } from "../services";
import downloadBlob from "../../../lib/client-util/downloadBlob";
import printBlob from "../../../lib/client-util/printBlob";
import { valueInArray } from "../../../lib/client-util/array";

export default FDVue.extend({
  mixins: [peopleList, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-people-list": () => import("./components/PeopleListTable.vue")
  },

  data: () => ({}),

  computed: {
    iconColumnArgument(): string {
      return this.anyInactiveUsers ? "inactive" : FDHiddenArgumentName;
    },
    anyInactiveUsers(): boolean {
      // Users are inactive if the person is not archived, and the loginState is inactive
      return !!this.users.find(x => x.isActiveWithUnusedLogin);
    },
    anyRequestingAccessUsers(): boolean {
      // Users are inactive if the person is not archived, and the loginState is inactive
      return (
        !!this.$store.state.curEnvironment?.allowSelfSignUp &&
        !!this.users.find(x => x.isRequestingEnrolment)
      );
    },
    anyLockedUsers(): boolean {
      // Users are inactive if the person is not archived, and the loginState is inactive
      return !!this.users.find(x => x.isActiveAccountLocked);
    }
  },

  methods: {
    openNewDialog() {
      createNewPerson();
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      await this.deleteUser(item);
      await this.loadUsers();
    },

    async flipArchived(item: PersonWithExtraDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.isArchived ? null : new Date(new Date().toUTCString());
        await this.updateUser({
          id: item.id,
          archivedDate: archivedDate,
          firstName: item.firstName,
          lastName: item.lastName
        } as PersonWithDetails);
        await this.refreshUserList();
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async flipCanLogIn(item: PersonWithExtraDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for disabled, since we're flipping it
        var loginDisabledDate = item.isLoginActive ? new Date(new Date().toUTCString()) : null;
        await this.updateUser({
          id: item.id,
          loginDisabledDate: loginDisabledDate,
          firstName: item.firstName,
          lastName: item.lastName
        } as PersonWithDetails);
        await this.refreshUserList();
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadTags: "LOAD_TAGS",
      updateUser: "UPDATE_USER"
    }),
    async downloadAndPrintWorkOrderAdminReport(reportType: string = "pdf") {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        var peopleRow = this.users.map(
          x =>
            ({
              isActivated: !x.isActiveWithUnusedLogin,
              lastName: x.lastName,
              firstName: x.firstName,
              contractorID: x.contractorID,
              employeeNumber: x.employeeCode,
              badgeNumber: x.employeeBadge,
              isForeman: x.isForeman,
              isGeneralForeman: x.isGeneralForeman,
              isCoordinator: x.isCoordinator,
              isPlanner: x.isPlanner,
              isTimeManager: x.isTimeManager,
              canSignIn: x.canSignIn
            } as PeopleRow)
        );
        var contractorFilterValue = !!this.contractorsSelectedForFiltering?.length
          ? this.contractorsInUse
              .filter(x => valueInArray(x.id!, this.contractorsSelectedForFiltering))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;

        var status = this.personListContext.replace(/^\w/, char => char.toUpperCase());
        var blob = await reportService.getPeoplePrintoutReportContentWithData(
          peopleRow,
          reportType,
          contractorFilterValue,
          status,
          this.anyInactiveUsers,
          false,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "people-printout.xlsx");
        } else {
          printBlob(blob, "people-printout.pdf", "application/pdf");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  mounted: function() {},

  created: async function() {
    this.notifyNewBreadcrumb({
      text: this.$t("users.menu-title"),
      to: "/people",
      resetHistory: true
    });
  }
});
