import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";

export default FDVue.extend({
  name: "sp-equipment-list",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {},

  props: {
    items: {
      default: () => [],
      type: Array
    },
    tablesearch: {
      default: "",
      type: String
    },
    processing: {
      default: false,
      type: Boolean
    },
    deleting: {
      default: false,
      type: Boolean
    },
    archivedLoading: {
      default: false,
      type: Boolean
    },
    showArchived: {
      default: false,
      type: Boolean
    },
    showArchivedDateRange: {
      default: () => [],
      type: Array
    },
    showArchivedMinDate: {
      default: undefined,
      type: Date
    },
    showArchivedMaxDate: {
      default: undefined,
      type: Date
    }
  },
  computed: {
    showArchivedValue: {
      get(): boolean {
        return this.showArchived;
      },
      set(val: boolean) {
        console.log(`SP.EquipmentListTable showArchivedValue.set val: ${val}`);
        this.$emit("update:showArchived", val);
      }
    }
  },
  methods: {
    navigate(item: any) {
      this.$emit("navigate", item);
    }
  }
});
