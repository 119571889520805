var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "materialorders" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.currentUserCanViewMaterialOrders
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("material-orders.no-permission-to-view")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "add-fab-button-primary",
                                        attrs: {
                                          fab: "",
                                          absolute: "",
                                          bottom: "",
                                          left: "",
                                          elevation: "5",
                                          depressed:
                                            _vm.processing ||
                                            !_vm.currentUserCanSubmitMaterialOrders,
                                          disabled:
                                            _vm.processing ||
                                            !_vm.currentUserCanSubmitMaterialOrders,
                                          color: "new"
                                        }
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                { on: { click: _vm.openNewRequestDialog } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "material-orders.new.material-request"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.openNewReturnDialog } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "material-orders.new.material-return"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "d-flex align-center justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background"
            },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "fd-badge-radio-group",
                  attrs: { row: "" },
                  model: {
                    value: _vm.materialOrderContext,
                    callback: function($$v) {
                      _vm.materialOrderContext = $$v
                    },
                    expression: "materialOrderContext"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "material-orders.list.selection-type-request"
                      ),
                      value: "request",
                      "data-cy": "radiorequest"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "material-orders.list.selection-type-return"
                      ),
                      value: "return",
                      "data-cy": "radioreturn"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-md-0 order-1",
                      attrs: { cols: "12", sm: "12", md: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-0 mt-md-5 mr-5",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                disabled: _vm.processing,
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: _vm.$vuetify.breakpoint.smAndUp
                                  }
                                },
                                [_vm._v("mdi-reload")]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            ref: "search",
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "pt-md-3",
                              "mt-md-1",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:numberString",
                value: _vm.$t("material-orders.list.number-column-label"),
                expression: "$t('material-orders.list.number-column-label')",
                arg: "numberString"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:orderDate.hide-when-extra-small",
                value: _vm.$t("material-orders.list.order-date-column-label"),
                expression:
                  "$t('material-orders.list.order-date-column-label')",
                arg: "orderDate",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:expectedDate",
                value: _vm.$t(
                  "material-orders.list.expected-date-column-label"
                ),
                expression:
                  "$t('material-orders.list.expected-date-column-label')",
                arg: "expectedDate"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:status",
                value: _vm.$t("material-orders.list.order-status-column-label"),
                expression:
                  "$t('material-orders.list.order-status-column-label')",
                arg: "status"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:supplier.hide-when-extra-small",
                value: _vm.$t("material-orders.list.supplier-column-label"),
                expression: "$t('material-orders.list.supplier-column-label')",
                arg: "supplier",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:archived.hide-when-extra-small.hide-when-header-text-empty",
                value: _vm.showArchived ? _vm.$t("common.archived") : "",
                expression:
                  "\n        showArchived ? $t('common.archived') : ''\n      ",
                arg: "archived",
                modifiers: {
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:action.no-sort.hide-when-extra-small.class_fd-action-cell",
                arg: "action",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-action-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: item => _vm.$router.push(`/materialorders/${item.id}`),
                expression:
                  "item => $router.push(`/materialorders/${item.id}`)",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            ref: "datatable",
            class: { "fd-archive-bonus-padding-bottom": _vm.showArchived },
            attrs: {
              "data-cy": "list",
              "mobile-breakpoint": "0",
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              },
              items: _vm.filteredMaterialOrders,
              "sort-by": "orderDate",
              "sort-desc": ""
            },
            scopedSlots: _vm._u([
              {
                key: "item.orderDate",
                fn: function({ item }) {
                  return [_vm._v(_vm._s(_vm.formatDate(item.orderDate)))]
                }
              },
              {
                key: "item.expectedDate",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: { value: _vm.formatDate(item.expectedDate) }
                    })
                  ]
                }
              },
              {
                key: "item.status",
                fn: function({ item }) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`material-orders.statuses.${item.orderStatusID}`)
                      )
                    )
                  ]
                }
              },
              {
                key: "item.supplier",
                fn: function({ item }) {
                  return [_vm._v(_vm._s(_vm.$lookup.supplier(item.supplierID)))]
                }
              },
              {
                key: "footer.prepend",
                fn: function() {
                  return [
                    _c("fd-archived-data-loader", {
                      staticClass: "ml-2 mr-3",
                      attrs: {
                        showArchived: _vm.showArchived,
                        showArchivedDateRange: _vm.showArchivedDateRange,
                        showArchivedMinDate: _vm.showArchivedMinDate,
                        showArchivedMaxDate: _vm.showArchivedMaxDate,
                        loading: _vm.archivedLoading,
                        disabled: _vm.processing
                      },
                      on: {
                        "input:showArchived": v => (_vm.showArchived = v),
                        "input:showArchivedDateRange": v =>
                          (_vm.showArchivedDateRange = v)
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "item.fd-nav",
                fn: function() {
                  return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                },
                proxy: true
              },
              {
                key: "item.action",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-1-action-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: `/materialorders/${item.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }