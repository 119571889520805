import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import { EquipmentClassification, ContractorWithTags } from "../../services";
import { VDataTable } from "@fd/lib/vue/types";

export type EquipmentClassificationWithSelectedAndCustomRates = EquipmentClassification & {
  selected: boolean;
  contractorDailyRate: number;
  contractorWeeklyRate: number;
  contractorMonthlyRate: number;
  contractorCostCodeID: string | null | undefined;
};

const equipmentClassificationSelection = FDVue.extend({
  data: function() {
    return {
      contractor: {} as ContractorWithTags,
      // Used to change pages in the datatable programmatically
      equipmentClassificationstablepage: 1,
      // *** EquipmentClassifications ***
      showOnlyIncludedEquipmentClassifications: false,
      equipmentClassificationsTableSearch: "",
      selectableEquipmentClassifications: [] as Array<
        EquipmentClassificationWithSelectedAndCustomRates
      >
    };
  },
  computed: {
    // *** EQUIPMENT CLASSIFICATIONS ***
    equipmentClassifications(): Array<EquipmentClassificationWithSelectedAndCustomRates> {
      let returnValue = this.selectableEquipmentClassifications;
      if (this.showOnlyIncludedEquipmentClassifications)
        returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedEquipmentClassifications(): EquipmentClassificationWithSelectedAndCustomRates[] {
      return this.selectableEquipmentClassifications.filter(x => x.selected);
    },
    selectedEquipmentClassificationIDs(): string[] {
      return this.selectedEquipmentClassifications.map(x => x.id!);
    },

    searchedEquipmentClassifications(): Array<EquipmentClassificationWithSelectedAndCustomRates> {
      // This is a hack because the equipmentClassifications list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .equipmentClassificationsDataTable as any)?.customFilter;
      if (this.equipmentClassificationsTableSearch && customFilter) {
        return this.equipmentClassifications.filter(
          x =>
            customFilter(x.name!, this.equipmentClassificationsTableSearch, x) ||
            customFilter(x.description!, this.equipmentClassificationsTableSearch, x)
        );
      } else {
        return this.equipmentClassifications;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedEquipmentClassificationsSelected(): boolean {
      return this.searchedEquipmentClassifications.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedEquipmentClassificationsSelected(): boolean {
      var searchedEquipmentClassifications = this.searchedEquipmentClassifications;
      return (
        searchedEquipmentClassifications.findIndex(x => x.selected) !== -1 &&
        searchedEquipmentClassifications.findIndex(x => !x.selected) !== -1
      );
    }
  },
  methods: {
    // *** GLOBAL ***
    ...mapActions({
      loadEquipmentClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS"
    }),

    // *** INLINE NAVIGATION ***
    getContractorEquipmentClassificationFieldRef(
      fieldName: string,
      item: EquipmentClassificationWithSelectedAndCustomRates
    ) {
      let id = item.id!.replace("-", "").replace("-", "");
      return `${fieldName}_${id}`;
    },
    focusFieldForVisibleEquipmentClassificationsItemAtIndex(fieldName: string, index: number) {
      let visibleItems = (this.$refs.equipmentClassificationsDataTable as VDataTable)
        .internalCurrentItems;
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getContractorEquipmentClassificationFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousEquipmentClassificationsField(
      fieldName: string,
      item: EquipmentClassificationWithSelectedAndCustomRates
    ) {
      let datatable = this.$refs.equipmentClassificationsDataTable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.equipmentClassificationstablepage <= 1) return;
        this.equipmentClassificationstablepage -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleEquipmentClassificationsItemAtIndex(
            fieldName,
            datatable.computedItemsPerPage
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleEquipmentClassificationsItemAtIndex(fieldName, previousIndex);
    },
    async selectNextEquipmentClassificationsField(
      fieldName: string,
      item: EquipmentClassificationWithSelectedAndCustomRates
    ) {
      let datatable = this.$refs.equipmentClassificationsDataTable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex >= visibleParts.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(this.equipmentClassifications.length / datatable.computedItemsPerPage);
        if (this.equipmentClassificationstablepage >= maxPage) return;

        this.equipmentClassificationstablepage += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleEquipmentClassificationsItemAtIndex(fieldName, 0);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleEquipmentClassificationsItemAtIndex(fieldName, nextIndex);
    },
    async equipmentClassificationsEnterPressed(
      e: KeyboardEvent,
      fieldName: string,
      item: EquipmentClassificationWithSelectedAndCustomRates
    ) {
      if (e.shiftKey) await this.selectPreviousEquipmentClassificationsField(fieldName, item);
      else await this.selectNextEquipmentClassificationsField(fieldName, item);
    },

    // *** EQUIPMENT CLASSIFICATIONS ***
    flipEquipmentClassificationSelected(
      item: EquipmentClassificationWithSelectedAndCustomRates & { selected: boolean }
    ) {
      item.selected = !item.selected;
    },

    flipSearchedEquipmentClassificationsSelected() {
      let selected = !this.allSearchedEquipmentClassificationsSelected;
      for (let equipmentClassification of this.searchedEquipmentClassifications) {
        if (equipmentClassification.selected !== selected) {
          equipmentClassification.selected = selected;
        }
      }
    }
  }
});

export default equipmentClassificationSelection;
