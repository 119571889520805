import { PropType } from "vue";
import FDVue from "../../../../../lib/vue";
import { ContractorNorm, ContractorNormGroup } from "../../../services";

export type NormWithResponse = ContractorNorm & {
  answeredDescription: string | undefined;
  quantity: number | null | undefined;
  loadedQuantity: number | null | undefined;
  squareFeet: number | null | undefined;
  loadedSquareFeet: number | null | undefined;
  hours: number | null | undefined;
  loadedHours: number | null | undefined;
  text: string | null | undefined;
  loadedText: string | null | undefined;
};
export type NormGroupWithNorms = ContractorNormGroup & {
  normsWithResponses: Array<NormWithResponse>;
};
export default FDVue.extend({
  name: "sp-build-sheet-norm-form",

  components: {},
  props: {
    processing: { type: Boolean },
    normGroupsWithResponses: { type: Array as PropType<Array<NormGroupWithNorms>> },
    canEditBuildSheetResponses: { type: Boolean }
  },
  data: function() {
    return {
      openPanels: [0] as number[]
    };
  },
  computed: {
    groupsWithNorms(): Array<NormGroupWithNorms> {
      return this.normGroupsWithResponses.filter(x => !!x.normsWithResponses.length);
    },
    canEditBuildSheet(): boolean {
      return this.canEditBuildSheetResponses ?? false;
    }
  },
  methods: {
    clearNormResponses(item: NormWithResponse) {
      item.quantity = null;
      item.squareFeet = null;
      item.hours = null;
      item.text = null;
      this.$emit("update:normGroupsWithResponses", this.normGroupsWithResponses);
    },
    normQuantityUpdated(norm: NormWithResponse, quantity: any) {
      norm.quantity = this.$parse.sanitizedNumber(quantity);
      this.$emit("update:normGroupsWithResponses", this.normGroupsWithResponses);
    },
    normSquareFeetUpdated(norm: NormWithResponse, squareFeet: any) {
      norm.squareFeet = this.$parse.sanitizedNumber(squareFeet);
      this.$emit("update:normGroupsWithResponses", this.normGroupsWithResponses);
    },
    normHoursUpdated(norm: NormWithResponse, hours: any) {
      norm.hours = this.$parse.sanitizedNumber(hours);
      this.$emit("update:normGroupsWithResponses", this.normGroupsWithResponses);
    },

    // *** INLINE NAVIGATION ***
    getFieldRef(fieldName: string, item: NormWithResponse) {
      let field = fieldName!.replace("-", "").replace("-", "");
      let id = item.id!.replace("-", "").replace("-", "");
      let fieldRef = `${field}_${id}`;
      return fieldRef;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, groupNumber: number, index: number) {
      if (!this.normGroupsWithResponses.length) return;
      let groupIndex = (this.normGroupsWithResponses as NormGroupWithNorms[]).findIndex(
        x => x.displayOrder == groupNumber
      );
      if (groupIndex < 0) return;
      let group = this.normGroupsWithResponses[groupIndex];
      if (!group?.normsWithResponses?.length) return;
      let normsWithResponses = group.normsWithResponses;

      if (!this.openPanels.includes(groupIndex)) {
        this.openPanels.push(groupIndex);
        let self = this;

        this.$nextTick(() => {
          setTimeout(() => {
            self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, index);
          }, 500);
        });
        return;
      }

      if (index < 0) index = 0;
      if (index >= normsWithResponses.length) index = normsWithResponses.length - 1;
      let item = normsWithResponses[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      itemField = itemField;
      this.$nextTick(() => {
        itemField?.focus();
        itemField?.$el?.focus();
        itemField?.$textField?.focus();
      });
    },
    selectLastFieldInPreviousGroup(fieldName: string, currentGroupNumber: number) {
      if (currentGroupNumber <= 1) return;
      let groupNumber = currentGroupNumber - 1;
      var visibleGroup = this.normGroupsWithResponses.find(x => x.displayOrder == groupNumber);
      if (!visibleGroup) {
        this.selectLastFieldInPreviousGroup(fieldName, groupNumber);
        return;
      }
      let self = this;
      // Wait a tick to allow the table's page change to update its current items
      this.$nextTick(() => {
        self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 999);
      });
    },
    async selectPreviousField(e: KeyboardEvent, fieldName: string, item: NormWithResponse) {
      let groupID = item.contractorNormGroupID;
      let group = (this.normGroupsWithResponses as NormGroupWithNorms[]).find(x => x.id == groupID);
      if (!group?.normsWithResponses?.length) return;

      let currentGroupNumber = group.displayOrder ?? 0;
      let normsWithResponses = group.normsWithResponses;

      let currentItemIndex = normsWithResponses.indexOf(item);
      if (currentItemIndex <= 0) {
        this.selectLastFieldInPreviousGroup(fieldName, currentGroupNumber);
        e.preventDefault();
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, currentGroupNumber, previousIndex);
      e.preventDefault();
    },
    selectFirstFieldInNextGroup(fieldName: string, currentGroupNumber: number) {
      let groupNumber = currentGroupNumber + 1;
      let maxGroupNumber = this.normGroupsWithResponses.reduce(
        (a: number, b) => Math.max(b.displayOrder ?? -1, a),
        0
      );
      if (groupNumber > maxGroupNumber) return;

      var visibleGroup = this.normGroupsWithResponses.find(x => x.displayOrder == groupNumber);
      if (!visibleGroup) {
        this.selectFirstFieldInNextGroup(fieldName, groupNumber);
        return;
      }

      let self = this;
      // Wait a tick to allow the table's page change to update its current items
      this.$nextTick(() => {
        self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 0);
      });
    },
    async selectNextField(e: KeyboardEvent, fieldName: string, item: NormWithResponse) {
      let groupID = item.contractorNormGroupID;
      let group = (this.normGroupsWithResponses as NormGroupWithNorms[]).find(x => x.id == groupID);
      if (!group?.normsWithResponses?.length) return;

      let currentGroupNumber = group.displayOrder ?? 0;
      let normsWithResponses = group.normsWithResponses;

      let currentItemIndex = normsWithResponses.indexOf(item);
      if (currentItemIndex >= normsWithResponses.length - 1) {
        this.selectFirstFieldInNextGroup(fieldName, currentGroupNumber);
        e.preventDefault();
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, currentGroupNumber, nextIndex);
      e.preventDefault();
    },
    async enterPressed(e: KeyboardEvent, fieldName: string, item: NormWithResponse) {
      if (e.shiftKey) await this.selectPreviousField(e, fieldName, item);
      else await this.selectNextField(e, fieldName, item);
    }
  }
});
