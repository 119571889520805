var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      class: { "fd-readonly": _vm.readonly },
      attrs: {
        "nudge-right": 40,
        "close-on-content-click": false,
        "open-on-click": _vm.readonly,
        "open-on-focus": false,
        "min-width": "290px",
        "content-class": "fd-timesheet-work-sub-sub-types-hours-entry-content",
        "data-cy": `${!!_vm.cy ? _vm.cy : _vm.dataCy}-picker-menu`,
        readonly: _vm.readonly
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function({ attrs, on, value: menuValue }) {
            return [
              _c(
                "span",
                [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        class: [
                          "px-0",
                          {
                            "d-none": !_vm.readonly && !_vm.disabled
                          }
                        ],
                        attrs: { small: "", text: "" },
                        on: {
                          "!click": function($event) {
                            return (e =>
                              _vm.activatorClicked(e, on, menuValue)).apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      on
                    ),
                    [
                      _vm._v("\n        " + _vm._s(_vm.value) + "\n        "),
                      _c(
                        "v-icon",
                        { staticClass: "ml-1 pt-1", attrs: { small: "" } },
                        [_vm._v("mdi-eye")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          ref: _vm.activatorRef,
                          class: {
                            "d-none": _vm.readonly || _vm.disabled
                          },
                          attrs: {
                            "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
                            label: _vm.$props.label,
                            value: _vm.value
                          },
                          on: {
                            click: e => _vm.activatorClicked(e, on, menuValue),
                            focus: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return (e =>
                                _vm.activatorFocused(e, on, menuValue)).apply(
                                null,
                                arguments
                              )
                            },
                            focusout: function($event) {
                              return _vm.activatorFocusOut()
                            },
                            blur: function($event) {
                              return _vm.activatorBlurred()
                            }
                          }
                        },
                        "v-text-field",
                        { ..._vm.$attrs, ...attrs },
                        false
                      ),
                      on
                    )
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menuOpen,
        callback: function($$v) {
          _vm.menuOpen = $$v
        },
        expression: "menuOpen"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "fd-timesheet-work-sub-sub-types-hours-entry-card-title"
            },
            [
              _c("span", [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
              ]),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          ),
          _vm._v(" "),
          _vm.readonly
            ? _c(
                "v-container",
                {
                  staticClass:
                    "fd-timesheet-work-sub-sub-types-hours-entry-container pa-0"
                },
                [
                  _c("v-simple-table", [
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.childSubTypes, function(wst, idx) {
                          return _c("tr", { key: idx }, [
                            _c("td", { attrs: { colspan: "8" } }, [
                              _vm._v(_vm._s(!!wst.code ? wst.code : wst.name))
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { attrs: { colspan: "4" } },
                              [
                                _c("sp-timesheet-time-display", {
                                  attrs: {
                                    times: _vm.getTimesForWorkSubType(wst.id),
                                    "single-line": ""
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        }),
                        _vm._v(" "),
                        _c("tr", { staticClass: "fd-table-summary-row" }, [
                          _c("td", { attrs: { colspan: "8" } }, [
                            _vm._v(_vm._s(_vm.$t("common.total")))
                          ]),
                          _vm._v(" "),
                          _c("td", { attrs: { colspan: "4" } }, [
                            _vm._v(_vm._s(_vm.value))
                          ])
                        ])
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _c(
                "v-container",
                {
                  staticClass:
                    "fd-timesheet-work-sub-sub-types-hours-entry-container pb-0"
                },
                [
                  _vm._l(_vm.childSubTypes, function(wst, idx) {
                    return _c(
                      "v-row",
                      { key: idx, attrs: { dense: "" } },
                      [
                        _c("v-col", { attrs: { cols: "8" } }, [
                          _vm._v(_vm._s(!!wst.code ? wst.code : wst.name))
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _vm.readonly
                              ? _c("sp-timesheet-time-display", {
                                  attrs: {
                                    times: _vm.getTimesForWorkSubType(wst.id),
                                    "single-line": ""
                                  }
                                })
                              : _c("v-text-field", {
                                  key: wst.id,
                                  ref: _vm.getFieldRef(idx),
                                  refInFor: true,
                                  staticClass: "fd-less-short-table-input",
                                  attrs: {
                                    value: _vm.getTimeValueForWorkSubType(
                                      wst.id
                                    ),
                                    outlined: false,
                                    "highlight-on-focus": "",
                                    "hide-details": "",
                                    autofocus: idx == 0,
                                    disabled: _vm.processing || _vm.disabled
                                  },
                                  on: {
                                    focus: function($event) {
                                      return _vm.textFieldFocused(idx)
                                    },
                                    focusout: function($event) {
                                      return _vm.textFieldFocusOut(idx)
                                    },
                                    blur: e => _vm.textFieldBlur(e, idx),
                                    change: v =>
                                      _vm.workSubTypeHoursValueChanged(
                                        wst.id,
                                        v
                                      ),
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "tab",
                                            9,
                                            $event.key,
                                            "Tab"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.tabKeyDown(e, idx)).apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.selectPreviousField(
                                            e,
                                            idx
                                          )).apply(null, arguments)
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.selectNextField(e, idx)).apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(e, idx)).apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    ]
                                  }
                                })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "fd-table-summary-row" },
                    [
                      _c("v-col", { attrs: { cols: "8" } }, [
                        _vm._v(_vm._s(_vm.$t("common.total")))
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _vm._v(_vm._s(_vm.value))
                      ])
                    ],
                    1
                  )
                ],
                2
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }