import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  ProjectCostCode,
  WorkSubType,
  WorkType,
  workSubTypeService,
  workTypeService
} from "../services";
import rules from "@fd/lib/vue/rules";
import serviceErrorHandling from "../../../lib/vue/mixins/serviceErrorHandling";

type WorkSubTypeWithArchived = WorkSubType & { archived: boolean };

export default FDVue.extend({
  name: "sp-work-sub-sub-type-existing",

  mixins: [serviceErrorHandling, rules],

  components: {},

  data() {
    return {
      loaded: false,
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      //Simple object for the form data
      workSubType: {} as WorkSubTypeWithArchived,
      parentWorkSubType: {} as WorkSubType,
      workType: {} as WorkType
    };
  },
  computed: {
    allCostCodes(): ProjectCostCode[] {
      return this.$store.state.projectCostCodes.fullList as ProjectCostCode[];
    },
    workSubTypeIsMisconfigured(): boolean {
      return (
        !!this.workSubType.id &&
        !this.workSubType.isParent &&
        !this.workSubType.useWorkOrderCostCode &&
        !this.workSubType.defaultCostCodeID
      );
    }
  },

  methods: {
    updateBreadCrumbs() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/worktypes") {
        this.notifyNewBreadcrumb({
          text: this.$t("work-types.list.title"),
          to: "/worktypes",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.workType.name,
          to: `/worktypes/${this.$route.params.worktypeid}`
        });

        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.parentWorkSubType.name,
          to: `/worktypes/${this.$route.params.worktypeid}/worksubtypes/${this.$route.params.parentsubtypeid}`
        });

        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      if (!!this.workSubType?.name?.length) {
        this.notifyNewBreadcrumb({
          text: this.workSubType.name,
          to: `/worktypes/${this.$route.params.worktypeid}/worksubtypes/${this.$route.params.parentsubtypeid}/worksubsubtypes/${this.$route.params.id}`
        });
      } else {
        this.notifyNewBreadcrumb({
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        });
      }
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      if (
        !this.workSubType.isParent &&
        !this.workSubType.useWorkOrderCostCode &&
        !this.workSubType.defaultCostCodeID
      ) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.workSubType.archived) {
          this.workSubType.archivedDate = null;
        } else if (this.workSubType.archived && !this.workSubType.archivedDate) {
          this.workSubType.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateWorkSubType(this.workSubType);
        if (closeOnComplete) {
          this.$router.push(
            `/worktypes/${this.$route.params.worktypeid}/worksubtypes/${this.$route.params.parentsubtypeid}`
          );
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Method used in conjunction with the Delete button.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteWorkSubType({ id: this.workSubType.id, name: this.workSubType.name });
        this.$router.push(
          `/worktypes/${this.$route.params.worktypeid}/worksubtypes/${this.$route.params.parentsubtypeid}`
        );
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push(
        `/worktypes/${this.$route.params.worktypeid}/worksubtypes/${this.$route.params.parentsubtypeid}`
      );
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadWorkType: "LOAD_WORK_TYPE",
      loadWorkSubType: "LOAD_WORK_SUB_TYPE",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      updateWorkSubType: "UPDATE_WORK_SUB_TYPE",
      deleteWorkSubType: "DELETE_WORK_SUB_TYPE",
      loadCostCodes: "LOAD_PROJECT_COST_CODES"
    })
  },

  watch: {
    workSubType: async function(newValue) {
      this.updateBreadCrumbs();
    },
    "workSubType.defaultCostCodeID": function(newValue, oldValue) {
      if (!this.loaded) return;
      console.log(`workSubType.defaultCostCodeID ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;
      if (!!newValue) this.workSubType.useWorkOrderCostCode = false;
    },
    "workSubType.useWorkOrderCostCode": function(newValue, oldValue) {
      if (!this.loaded) return;
      console.log(`workSubType.useWorkOrderCostCode ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;
      if (!!newValue) this.workSubType.defaultCostCodeID = null;
    },
    "workSubType.isWorkOrderRelated": function(newValue, oldValue) {
      if (!this.loaded) return;
      console.log(`workSubType.isWorkOrderRelated ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;
      if (!newValue) this.workSubType.useWorkOrderCostCode = false;
    }
  },

  created: async function() {
    this.inlineMessage.message = null;
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "work-type-sub-sub-type-existing",
      parentalContext: "work-type-sub-type-existing"
    });

    let parentSubTypeID = this.$route.params.parentsubtypeid;
    this.parentWorkSubType = this.$store.state.workSubTypes.fullList.find(
      (x: any) => x.id == parentSubTypeID
    );
    if (!this.parentWorkSubType) {
      this.parentWorkSubType = await workSubTypeService.getByID(parentSubTypeID);
    }

    let workTypeID = this.$route.params.worktypeid;
    this.workType = this.$store.state.workTypes.fullList.find((x: any) => x.id == workTypeID);
    if (!this.workType) {
      this.workType = await workTypeService.getByID(workTypeID);
    }

    try {
      await this.loadCostCodes();
      await this.loadWorkSubType(this.$route.params.id);
      var workSubType = (this.$store.state.workSubTypes.fullList as WorkSubType[]).find(
        x => x.id == this.$route.params.id
      )!;
      this.workSubType = {
        ...workSubType,
        archived: !!workSubType.archivedDate
      };
      this.$nextTick(() => {
        this.loaded = true;
      });
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});
