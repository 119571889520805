import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import { mapMutations, mapActions } from "vuex";
import { EquipmentClassification, equipmentClassificationService } from "../services";
import archivedDataList from "../dataMixins/archivedDataList";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import rules from "@fd/lib/vue/rules";
import { showEquipmentClassificationNewDialog } from "./components/dialogs/SP.EquipmentClassificationNewDialog.vue";

type FormattedEquipmentClassification = EquipmentClassification & {
  archived: boolean;
};

export default FDVue.extend({
  name: "sp-equipment-classifications-list",

  // archivedDataList already uses the serviceErrorHandling
  mixins: [archivedDataList, userAccess, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      // equipmentClassifications: [] as FormattedEquipmentClassification[],
      deleting: false,
      // Table Footer page size options
      itemsPerPage: 100,
      itemsPerPageOptions: [5, 25, 50, 100, -1]
    };
  },

  computed: {
    equipmentClassifications(): FormattedEquipmentClassification[] {
      var storeEquipmentClassifications = this.$store.state.equipmentClassifications
        .fullList as EquipmentClassification[];
      return storeEquipmentClassifications.map(
        x =>
          ({
            ...x,
            archived: !!x.archivedDate
          } as FormattedEquipmentClassification)
      );
    },
    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapActions({
      loadStoreEquipmentClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS",
      updateEquipmentClassification: "UPDATE_EQUIPMENT_CLASSIFICATION",
      deleteEquipmentClassification: "DELETE_EQUIPMENT_CLASSIFICATION"
    }),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    async openNewDialog() {
      await showEquipmentClassificationNewDialog();
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadData() {
      await this.loadStoreEquipmentClassifications({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
    },

    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteEquipmentClassification(item);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    async flipArchived(item: FormattedEquipmentClassification) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateEquipmentClassification({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "equipmentclassifications",
      parentalContext: null,
      searchStringForFiltering: "",
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });

    this.notifyNewBreadcrumb({
      text: this.$t("equipment-classifications.list.title"),
      to: "/equipmentclassifications",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.reloadTableData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

