import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import {
  ContractorWithTags,
  EquipmentWithDetails,
  EquipmentClassification
} from "../../../services";

const EquipmentNewDialog = FDVue.extend({
  name: "sp-equipment-new-dialog",

  mixins: [dialogSupport, rules],

  data: function() {
    return {
      saving: false,
      contractorIDLocked: false,
      equipment: {
        name: "",
        serialNumber: "",
        description: "",
        contractorID: "",
        equipmentClassificationID: "",
        manufacturer: "",
        modelNumber: "",
        modelYear: "",
        unitColor: ""
      } as EquipmentWithDetails
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["open", "loadData"];
    },
    equipmentRules() {
      return {
        name: [this.rules.required],
        serialNumber: [],
        contractorID: [],
        equipmentClassificationID: [],
        description: [],
        manufacturer: [],
        modelNumber: [],
        modelYear: [],
        unitColor: []
      };
    },
    contractors(): ContractorWithTags[] {
      return this.$store.getters.sortedEnabledContractors as ContractorWithTags[];
    },
    equipmentClassifications(): EquipmentClassification[] {
      let allClassifications = this.$store.getters
        .sortedEnabledEquipmentClassifications as EquipmentClassification[];
      if (!allClassifications?.length) return [];

      let contractor = this.contractors.find(x => x.id == this.equipment.contractorID);
      if (!contractor) return [];

      let classificationIDs = contractor.equipmentClassifications?.map(
        x => x.equipmentClassificationID
      );
      if (!classificationIDs?.length) return [];

      return allClassifications.filter(x => classificationIDs!.includes(x.id!));
    }
  },

  methods: {
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadEquipmentClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS"
    }),
    async loadData() {
      await Promise.all([this.loadContractors(), this.loadEquipmentClassifications()]);
    },
    async open(options?: { contractorID?: string }) {
      this.optOutOfErrorHandling();
      this.equipment.contractorID = options?.contractorID;
      if (!!this.equipment.contractorID?.length) this.contractorIDLocked = true;

      this.loadData();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.saving = true;
      this.processing = true;
      try {
        await this.addEquipment({
          ...this.equipment
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    ...mapActions({
      addEquipment: "ADD_EQUIPMENT"
    })
  }
});

export default EquipmentNewDialog;

export async function showEquipmentNewDialog(options?: {
  contractorID?: string;
}): Promise<boolean> {
  let dialog = createDialog(EquipmentNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(options);
}
