import FDVue from "@fd/lib/vue";
import {
  ContractorWithTags,
  OwnerTimesheetSummary,
  Person,
  personService,
  timesheetService,
  TimesheetStatus,
  TimesheetType,
  TimesheetWithDetails,
  userService
} from "../services";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { mapActions, mapMutations } from "vuex";
import { createNewTimesheet } from "./components/dialogs/TimesheetNewDialog.vue";
import rules from "@fd/lib/vue/rules";
import { TranslateResult } from "vue-i18n";
import {
  GetPersonName,
  HasContractorName,
  JoinNameValues,
  PeopleFromSelectableList,
  PersonWithDetailsAndName,
  SelectablePerson,
  SortItemsWithName
} from "../utils/person";
import { valueInArray } from "@fd/lib/client-util/array";
import userAccess from "../dataMixins/userAccess";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";
import archivedDataList from "../dataMixins/archivedDataList";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import { VDataTable } from "../../../lib/vue/types";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";

type EntryGroupingType = "groupnone" | "groupperson" | "groupday" | "groupstatus";
type FormattedTimesheetWithDetails = TimesheetWithDetails & {
  classification: string;
  timesheetNumberString: string;
  timesheetStatus: string | TranslateResult;
  formattedDay: string;
  formattedTotalRegularTime: string;
  formattedTotalOverTime: string;
  formattedTotalDoubleTime: string;
  formattedTotalUnits: string;
  formattedTotalDays: string;
  formattedTotalQuantity: string;
};
export default FDVue.extend({
  name: "fd-foreman-timesheets",

  mixins: [archivedDataList, rules, userAccess],

  directives: {
    "fd-column": FDColumnDirective,
    "fd-row-navigate": FDRowNavigateDirective
  },

  data: function() {
    return {
      screenLoaded: false,
      selectedGroupingType: "groupday" as EntryGroupingType,
      timesheets: [] as TimesheetWithDetails[],

      showDirectTimesheets: true,
      showIndirectTimesheets: true,
      showEquipmentTimesheets: true,

      submitting: false,
      cancelling: false,

      allPeople: [] as Person[],
      timesheetSummary: [] as OwnerTimesheetSummary[],

      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },
  computed: {
    groupColumn(): string | undefined {
      if (this.selectedGroupingType == "groupperson") return "ownerName";
      else if (this.selectedGroupingType == "groupday") return "isoDay";
      else if (this.selectedGroupingType == "groupstatus") return "timesheetStatus";
      return undefined;
    },
    groupSortDesc(): boolean {
      if (this.selectedGroupingType == "groupday") return true;
      return false;
    },
    statusItems(): { value: number; text: string }[] {
      var values = Object.keys(TimesheetStatus);
      var keys = values.filter(x => !isNaN(Number(x))).map(x => Number(x)) as number[];
      var items = keys.map(x => {
        return {
          value: x,
          text: TimesheetStatus[x]
        };
      });
      return items;
    },
    selectableFilterStatuses(): any[] {
      return this.statusItems
        .map(x => {
          return {
            text: `${x.text}`,
            value: x.value,
            count: this.timesheets.filter(t => t.timesheetStatusID == x.value).length
          };
        })
        .filter(x => x.count > 0);
    },

    selectedStatuses: {
      get(): number[] {
        var selectedStatuses = (this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )?.statusesForFiltering ?? []) as number[];
        return selectedStatuses;
      },
      set(val: number[]) {
        this.$store.commit("SET_STATUSES_FOR_FILTERING", val);
      }
    },
    visibleTimesheets(): TimesheetWithDetails[] {
      let timesheets: TimesheetWithDetails[] = this.timesheets;

      if (!!this.contractorIDsSelectedForFiltering?.length) {
        let contractorIDs = this.contractorIDsSelectedForFiltering;
        timesheets = timesheets.filter(x => contractorIDs.includes(x.contractorID!));
      }

      if (!!this.selectedOwnerID) {
        timesheets = timesheets.filter(x => x.ownerID == this.selectedOwnerID);
      }

      if (!!this.selectedStatuses?.length) {
        timesheets = timesheets.filter(x =>
          this.selectedStatuses.includes(x.timesheetStatusID ?? 0)
        );
      }

      timesheets = timesheets.filter(
        x =>
          (this.showDirectTimesheets && x.timesheetTypeID == TimesheetType.Direct) ||
          (this.showIndirectTimesheets && x.timesheetTypeID == TimesheetType.Indirect) ||
          (this.showEquipmentTimesheets && x.timesheetTypeID == TimesheetType.Equipment)
      );

      return timesheets;
    },
    // *** FILTERING ***
    selectedOwnerID: {
      get() {
        let selectedOwnerIDs = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.peopleForFiltering;
        return !!selectedOwnerIDs?.length ? selectedOwnerIDs[0] : null;
      },
      set(val) {
        if (val == this.selectedOwnerID) return;

        let selectedOwnerIDs = [];
        if (!!val) selectedOwnerIDs.push(val);
        this.$store.commit("SET_PEOPLE_FOR_FILTERING", selectedOwnerIDs);
        // if (!this.screenLoaded) return;
        // this.timesheets = [];
        // this.reloadTableData();
      }
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    canViewContractorFilter(): boolean {
      return this.curUserCanViewAllContractors || this.curUserContractorIDs.length != 1;
    },

    selectableContractors(): ContractorWithTags[] {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let selectableContractorIDs = this.allPeople.map(x => x.contractorID);
      selectableContractorIDs = [...new Set(selectableContractorIDs)];
      return allContractors
        .filter(x => !!x.tracksEmployeeTime && selectableContractorIDs.includes(x.id))
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;

          return 0;
        });
    },
    selectableContractorsWithCount(): any[] {
      return this.selectableContractors.map(x => {
        return {
          ...x,
          count: this.timesheetCountForContractor(x.id)
        };
      });
    },
    contractorIDsSelectedForFiltering: {
      get(): string[] {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val: string[]) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },

    canViewPersonFilter(): boolean {
      // Hide the person filter if the current user is the only person in the list
      let people = PeopleFromSelectableList(this.selectableVisibleOwners);
      let isInOwnersList = people.find(x => x.id == this.curUserID);
      let hidePersonFilter = isInOwnersList && people.length == 1;
      return !hidePersonFilter;
    },

    selectableOwnersWithCount(): any[] {
      return this.selectableVisibleOwnersWithTimesheets.map(x => {
        return {
          ...x,
          count: this.timesheetCountForPerson((x as PersonWithDetailsAndName)?.id)
        };
      });
    },

    visibleOwnersWithTimesheets(): (PersonWithDetailsAndName & HasContractorName)[] {
      return this.visibleOwners.filter(x => this.timesheetCountForPerson(x.id));
    },
    selectableVisibleOwnersWithTimesheets(): SelectablePerson[] {
      return this.groupedSelectablePersonList(this.visibleOwnersWithTimesheets);
    },

    visibleOwners(): (PersonWithDetailsAndName & HasContractorName)[] {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let visibleOwnersWithoutDetails = !this.contractorIDsSelectedForFiltering?.length
        ? this.allPeople
        : this.allPeople.filter(x =>
            valueInArray(x.contractorID, this.contractorIDsSelectedForFiltering)
          );
      let visibleOwnersWithDetails = visibleOwnersWithoutDetails
        .map(x => {
          return {
            ...x,
            name: JoinNameValues(x.firstName, x.lastName),
            contractorName: allContractors.find(c => c.id == x.contractorID)?.name
          } as PersonWithDetailsAndName & HasContractorName;
        })
        .sort((a, b) => {
          let contractorA = (a.contractorName ?? "").toLowerCase();
          let contractorB = (b.contractorName ?? "").toLowerCase();
          if (contractorA < contractorB) return -1;
          else if (contractorA > contractorB) return 1;

          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;

          return 0;
        });

      return visibleOwnersWithDetails;
    },

    selectableVisibleOwners(): SelectablePerson[] {
      return this.groupedSelectablePersonList(this.visibleOwners);
    },

    // *** EXPAND/COLLAPSE ALL ***
    allGroupsExpanded(): boolean {
      let datatableRef = `datatable`;
      let datatable = this.$refs[datatableRef] as VDataTable;
      if (!datatable) {
        console.log(`datatable "${datatableRef}" not found`);
        return false;
      }
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith(`grouptoggle`));
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if (!isOpen) {
          anyGroupsClosed = true;
          break;
        }
      }
      return !anyGroupsClosed;
    }
  },

  watch: {},

  methods: {
    labelForGroup(groupValue: string) {
      if (this.selectedGroupingType == "groupperson")
        return this.$t("timesheets.list.person-group-label", [groupValue]);
      else if (this.selectedGroupingType == "groupday")
        return this.$t("timesheets.list.day-group-label", [
          DateUtil.stripTimeFromLocalizedDateTime(groupValue)
        ]);
      else if (this.selectedGroupingType == "groupstatus")
        return this.$t("timesheets.list.status-group-label", [groupValue]);
      return undefined;
    },
    groupedSelectablePersonList(
      visibleOwnersWithDetails: (PersonWithDetailsAndName & HasContractorName)[]
    ): SelectablePerson[] {
      let contractorNames = visibleOwnersWithDetails
        .map(x => x.contractorName)
        .reduce((distinctNames: string[], contractorName: string) => {
          if (!distinctNames.includes(contractorName)) distinctNames.push(contractorName);

          return distinctNames;
        }, []);

      if (contractorNames.length > 1) {
        let contractorGroupedSelectableItems = [] as SelectablePerson[];
        contractorNames.forEach((name, index) => {
          let ownersForContractor = visibleOwnersWithDetails.filter(x => x.contractorName == name);
          if (!ownersForContractor?.length) return;

          if (index > 0) {
            contractorGroupedSelectableItems.push({
              divider: true
            });
          }
          if (!!name) {
            contractorGroupedSelectableItems.push({
              header: name
            });
          }

          contractorGroupedSelectableItems = contractorGroupedSelectableItems.concat(
            ownersForContractor
          );
        });
        return contractorGroupedSelectableItems;
      } else {
        return visibleOwnersWithDetails;
      }
    },
    timesheetCountForPerson(personID: string | null | undefined): number | undefined {
      if (!personID) return undefined;

      if (this.showArchived) {
        let personTimesheets = this.timesheets.filter(x => x.ownerID == personID);
        return personTimesheets.length ?? 0;
      }

      return this.timesheetSummary.find(s => s.ownerID == personID)?.timesheetCount ?? 0;
    },
    timesheetCountForContractor(contractorID: string | null | undefined): number | undefined {
      return this.timesheets.filter(x => x.contractorID == contractorID).length;
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS"
    }),

    toggleTableGroups(closed: boolean = true) {
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith(`grouptoggle`));
      let datatable = this.$refs[`datatable`] as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },

    async openNewDialog() {
      let contractorID = undefined;
      if (this.contractorIDsSelectedForFiltering.length == 1)
        contractorID = this.contractorIDsSelectedForFiltering[0];
      let timesheetID = await createNewTimesheet(contractorID, this.selectedOwnerID);
      if (!!timesheetID) {
        this.$router.push(`/foremantimesheets/${timesheetID}`);
      }
    },

    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
      console.log(`loadData showArchived: ${this.showArchived}`);

      if (this.showArchived) {
        this.timesheetSummary = [];
        await this.loadArchivedTimesheets();
      } else {
        this.timesheetSummary = await timesheetService.getOpenTimesheetSummary();
        await this.loadOpenAndPendingTimesheets();
      }

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    async loadArchivedTimesheets() {
      this.timesheets = [];
      let timesheets = await timesheetService.getFinishedTimesheets(
        this.showArchivedFromDate,
        this.showArchivedToDate,
        true
      );
      this.timesheets = timesheets.map(
        x =>
          ({
            ...x,
            classification: !!x.ownerClassificationAlias
              ? x.ownerClassificationAlias
              : x.ownerClassificationName,
            timesheetNumberString: `00000${x.timesheetNumber}`.slice(-5),
            timesheetStatus: this.$t(`timesheets.status.${x.timesheetStatusID}`),
            isoDay: DateUtil.isoDateString(x.day),
            formattedDay: DateUtil.stripTimeFromLocalizedDateTime(x.day),
            formattedTotalRegularTime:
              !!x.totalRegularTime && x.totalRegularTime > 0
                ? x.totalRegularTime.toFixed(2)
                : undefined,
            formattedTotalOverTime:
              !!x.totalOverTime && x.totalOverTime > 0 ? x.totalOverTime.toFixed(2) : undefined,
            formattedTotalDoubleTime:
              !!x.totalDoubleTime && x.totalDoubleTime > 0
                ? x.totalDoubleTime.toFixed(2)
                : undefined,
            formattedTotalUnits:
              !!x.totalUnits && x.totalUnits > 0 ? x.totalUnits.toFixed(0) : undefined,
            formattedTotalDays:
              !!x.totalDays && x.totalDays > 0 ? x.totalDays.toFixed(2) : undefined,
            formattedTotalQuantity:
              !!x.totalQuantity && x.totalQuantity > 0 ? x.totalQuantity.toFixed(2) : undefined
          } as FormattedTimesheetWithDetails)
      );
    },
    async loadOpenAndPendingTimesheets() {
      this.timesheets = [];
      let timesheets = await timesheetService.getAllVisibleOpenTimesheets();
      this.timesheets = timesheets.map(
        x =>
          ({
            ...x,
            classification: !!x.ownerClassificationAlias
              ? x.ownerClassificationAlias
              : x.ownerClassificationName,
            timesheetNumberString: `00000${x.timesheetNumber}`.slice(-5),
            timesheetStatus: this.$t(`timesheets.status.${x.timesheetStatusID}`),
            isoDay: DateUtil.isoDateString(x.day),
            formattedDay: DateUtil.stripTimeFromLocalizedDateTime(x.day),
            formattedTotalRegularTime:
              !!x.totalRegularTime && x.totalRegularTime > 0
                ? x.totalRegularTime.toFixed(2)
                : undefined,
            formattedTotalOverTime:
              !!x.totalOverTime && x.totalOverTime > 0 ? x.totalOverTime.toFixed(2) : undefined,
            formattedTotalDoubleTime:
              !!x.totalDoubleTime && x.totalDoubleTime > 0
                ? x.totalDoubleTime.toFixed(2)
                : undefined,
            formattedTotalUnits:
              !!x.totalUnits && x.totalUnits > 0 ? x.totalUnits.toFixed(0) : undefined,
            formattedTotalDays:
              !!x.totalDays && x.totalDays > 0 ? x.totalDays.toFixed(2) : undefined,
            formattedTotalQuantity:
              !!x.totalQuantity && x.totalQuantity > 0 ? x.totalQuantity.toFixed(2) : undefined
          } as FormattedTimesheetWithDetails)
      );
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // DOES NOT manage processing or error message logic
    async loadOwners(): Promise<void> {
      if (this.currentUserCanCreateIndirectTimesheets) {
        this.allPeople = await userService.getAll(false, null, null);
      } else {
        this.allPeople = await personService.getAllVisibleDirectTimesheetCreators();
      }

      let owners = this.allPeople;
      if (!this.selectedOwnerID) {
        if (owners.length == 1) {
          this.selectedOwnerID = owners[0].id!;
        } else {
          let currentUserOwner = this.visibleOwnersWithTimesheets.find(x => x.id == this.curUserID);
          this.selectedOwnerID = currentUserOwner?.id!;
        }
      }
    },

    timesheetIsDeclined(item: FormattedTimesheetWithDetails) {
      return item.timesheetStatusID == TimesheetStatus.Declined;
    },
    timesheetIsSubmitted(item: FormattedTimesheetWithDetails) {
      return item.timesheetStatusID == TimesheetStatus.Submitted;
    },
    timesheetCanBeSubmitted(item: FormattedTimesheetWithDetails) {
      return (
        (item.timesheetStatusID == TimesheetStatus.New ||
          item.timesheetStatusID == TimesheetStatus.Declined) &&
        item.currentUserPermissions.canSubmit
      );
    },
    timesheetCanBeApproved(item: FormattedTimesheetWithDetails) {
      return (
        item.timesheetStatusID == TimesheetStatus.Submitted &&
        item.currentUserPermissions.canApprove
      );
    },
    timesheetCanBeDeclined(item: FormattedTimesheetWithDetails) {
      return (
        item.timesheetStatusID == TimesheetStatus.Submitted &&
        item.currentUserPermissions.canApprove
      );
    },
    timesheetCanBeCancelled(item: FormattedTimesheetWithDetails): boolean {
      return (
        (item.timesheetStatusID == TimesheetStatus.New ||
          item.timesheetStatusID == TimesheetStatus.Declined) &&
        item.currentUserPermissions.canCancel
      );
    },
    async submitTimesheet(item: FormattedTimesheetWithDetails) {
      if (!item.currentUserPermissions.canSubmit) return;
      this.processing = true;
      this.submitting = true;
      try {
        let submittedToID = undefined as string | undefined;

        if (
          item.timesheetTypeID == TimesheetType.Indirect ||
          item.timesheetTypeID == TimesheetType.Equipment
        ) {
          let visibleTimeManagers = SortItemsWithName(
            (await personService.getVisibleTimeManagers())
              .filter(x => x.contractorID == item.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          var title = this.$t("timesheets.entries.submit-to-time-manager-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.time-manager-label"),
            [this.rules.required],
            visibleTimeManagers,
            "name",
            "id"
          );
          // If details is undefined the dialog was cancelled, if empty we somehow bypassed selecting a TM
          if (!submittedToID) {
            return false;
          }
        } else {
          let visibleGeneralForeman = SortItemsWithName(
            (await personService.getVisibleGeneralForemen())
              .filter(x => x.contractorID == item.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          let title = this.$t("timesheets.entries.submit-to-gen-foreman-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.general-foreman-label"),
            [this.rules.required],
            visibleGeneralForeman,
            "name",
            "id"
          );
        }

        // If details is undefined the dialog was cancelled
        if (!submittedToID) {
          this.processing = false;
          this.submitting = false;
          return false;
        }

        item.submittedTo = submittedToID;

        await timesheetService.submitTimesheet(item.id!, submittedToID);
        item.timesheetStatusID = TimesheetStatus.Submitted;
        item.timesheetStatus = this.$t(`timesheets.status.${item.timesheetStatusID}`);

        let snackbarPayload = {
          text: this.$t("timesheets.list.submit-success", [item.ownerName, item.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        await this.reloadTableData();
      } catch (error) {
        if ((error as any).statusCode == 422) {
          let snackbarPayload = {
            text: this.$t("timesheets.list.submit-validation-failed", [
              item.ownerName,
              item.formattedDay
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async cancelTimesheet(item: FormattedTimesheetWithDetails) {
      this.processing = true;
      this.cancelling = true;
      try {
        // get reason
        var title = this.$t("scaffold-request-approvals.cancel-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.cancelling = false;
          this.processing = false;
          return false;
        }

        await timesheetService.cancelPendingTimesheet(item.id!, reason);

        let timesheetNumberString = `00000${item.timesheetNumber}`.slice(-5);
        var snackbarPayload = {
          text: this.$t("timesheets.existing.cancel-success", [timesheetNumberString]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        await this.reloadTableData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.cancelling = false;
        this.processing = false;
      }
    }
  },
  created: async function() {
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "foremantimesheets",
      parentalContext: null,
      contractorsForFiltering: [],
      searchStringForFiltering: "",
      contextForFiltering: "all",
      peopleForFiltering: [],
      statusesForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });

    this.notifyNewBreadcrumb({
      text: this.$t("timesheets.list.title"),
      to: "/foremantimesheets",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadContractors(), this.loadOwners()]);
      await this.loadData();
      this.screenLoaded = true;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  },
  beforeDestroy: function() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});
