import FDVue from "@fd/lib/vue";
import BuildSheetFormBase from "./SP.BuildSheetFormBase";

export default FDVue.extend({
  name: "sp-build-sheet-form-maintenance",
  mixins: [BuildSheetFormBase],
  components: {},
  props: {},
  data: function() {
    return {};
  },
  computed: {},
  methods: {}
});
