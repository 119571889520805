// GENERATED CODE - DO NOT EDIT
import { executeServiceRequest, executeUploadServiceRequest } from "@fd/lib/client-util/serviceRequest";
export const accessCodeService = {
  verifyUnusedPersonalInformationAndGenerateAccessCodeForSignup(
    firstName: string,
    lastName: string,
    contractorID: string | null,
    emailAddress: string,
    phoneNumber: string,
    languageCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.VerifyUnusedPersonalInformationAndGenerateAccessCodeForSignup", {
        firstName,
        lastName,
        contractorID,
        emailAddress,
        phoneNumber,
        languageCode,
      }
    );
  },
  requestUserAccessWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
    isRequestingAccess: boolean,
    firstName: string,
    lastName: string,
    contractorID: string | null,
    disciplineID: string | null,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.RequestUserAccessWithAccessCode", {
        accessCodeChallenge,
        accessCode,
        isRequestingAccess,
        firstName,
        lastName,
        contractorID,
        disciplineID,
      }
    );
  },
  resendAccessCodeForSignup(
    accessCodeChallenge: AccessCodeChallenge,
    languageCode: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ResendAccessCodeForSignup", {
        accessCodeChallenge,
        languageCode,
      }
    );
  },
  resendAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ResendAccessCode", {
        accessCodeChallenge,
      }
    );
  },
  verifyUnusedEmailAddressOrPhoneNumberForSignup(
    emailAddress: string,
    phoneNumber: string,
    languageCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.VerifyUnusedEmailAddressOrPhoneNumberForSignup", {
        emailAddress,
        phoneNumber,
        languageCode,
      }
    );
  },
  verifyEmailAddressOrPhoneNumber(
    emailAddress: string,
    phoneNumber: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.VerifyEmailAddressOrPhoneNumber", {
        emailAddress,
        phoneNumber,
      }
    );
  },
  confirmVerifyEmailOrPhoneNumberWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ConfirmVerifyEmailOrPhoneNumberWithAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
  activateUserInformation(
    loginChallenge: LoginChallenge,
    emailAddress: string,
    phoneNumber: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ActivateUserInformation", {
        loginChallenge,
        emailAddress,
        phoneNumber,
      }
    );
  },
  activateAccountWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ActivateAccountWithAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
  generateAccessCode(
    loginChallenge: LoginChallenge,
    existingAccessCodeChallenge: AccessCodeChallenge | undefined,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.GenerateAccessCode", {
        loginChallenge,
        existingAccessCodeChallenge,
      }
    );
  },
  loginWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.LoginWithAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
}
export const blobFileService = {
  getByPersonID(
    personId: string,
  ): Promise<Array<BlobFile>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.GetByPersonID", {
        personId,
      }
    );
  },
  getPersonBlobFilesByPersonID(
    personIDs: Array<string>,
  ): Promise<{ [key: string]: Array<BlobFile> }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.GetPersonBlobFilesByPersonID", {
        personIDs,
      }
    );
  },
  getByScaffoldRequestID(
    scaffoldRequestId: string,
  ): Promise<Array<BlobFile>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.GetByScaffoldRequestID", {
        scaffoldRequestId,
      }
    );
  },
  getScaffoldRequestBlobFilesByScaffoldRequestID(
    scaffoldRequestIDs: Array<string>,
  ): Promise<{ [key: string]: Array<BlobFile> }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.GetScaffoldRequestBlobFilesByScaffoldRequestID", {
        scaffoldRequestIDs,
      }
    );
  },
  getByScaffoldDesignID(
    scaffoldDesignId: string,
  ): Promise<Array<BlobFile>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.GetByScaffoldDesignID", {
        scaffoldDesignId,
      }
    );
  },
  addItem(
    clientData: BlobFile,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: BlobFile,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.DeleteItem", {
        id,
      }
    );
  },
  downloadFile(
    blobID: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.DownloadFile", {
        blobID,
      }
    );
  },
  getByIsoID(
    isoID: string,
  ): Promise<Array<BlobFile>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.GetByIsoID", {
        isoID,
      }
    );
  },
  getByIsoIDs(
    isoIDs: Array<string>,
  ): Promise<{ [key: string]: Array<BlobFile> }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.GetByIsoIDs", {
        isoIDs,
      }
    );
  },
  downloadIsoFile(
    blobID: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.DownloadIsoFile", {
        blobID,
      }
    );
  },
  uploadIsoFile(
    isoId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<string> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.UploadIsoFile", requestBody, {
        isoId,
        fileName,
      }
    );
  },
  deleteIsoFile(
    blobID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.DeleteIsoFile", {
        blobID,
      }
    );
  },
  deleteAllIsoFiles(
    isoId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.DeleteAllIsoFiles", {
        isoId,
      }
    );
  },
  getByJobID(
    jobID: string,
  ): Promise<Array<BlobFile>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.GetByJobID", {
        jobID,
      }
    );
  },
  getByJobIDs(
    jobIDs: Array<string>,
  ): Promise<{ [key: string]: Array<BlobFile> }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.GetByJobIDs", {
        jobIDs,
      }
    );
  },
  uploadJobFile(
    jobId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<string> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.UploadJobFile", requestBody, {
        jobId,
        fileName,
      }
    );
  },
  deleteJobFile(
    blobID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.DeleteJobFile", {
        blobID,
      }
    );
  },
  deleteAllJobFiles(
    jobId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BlobFileService.DeleteAllJobFiles", {
        jobId,
      }
    );
  },
}
export const buildDismantleRatioService = {
  getByID(
    id: string,
  ): Promise<BuildDismantleRatio> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BuildDismantleRatioService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<BuildDismantleRatio>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BuildDismantleRatioService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<BuildDismantleRatio>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BuildDismantleRatioService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: BuildDismantleRatio,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BuildDismantleRatioService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: BuildDismantleRatio,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BuildDismantleRatioService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BuildDismantleRatioService.DeleteItem", {
        id,
      }
    );
  },
}
export const buildSheetService = {
  getByWorkOrderID(
    workOrderId: string,
  ): Promise<BuildSheet> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BuildSheetService.GetByWorkOrderID", {
        workOrderId,
      }
    );
  },
}
export const applicationTypeService = {
  getByID(
    id: string,
  ): Promise<ApplicationType> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ApplicationTypeService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ApplicationType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ApplicationTypeService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ApplicationType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ApplicationTypeService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ApplicationType,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ApplicationTypeService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ApplicationType,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ApplicationTypeService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ApplicationTypeService.DeleteItem", {
        id,
      }
    );
  },
}
export const paintCodeService = {
  getByID(
    id: string,
  ): Promise<PaintCode> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaintCodeService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PaintCode>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaintCodeService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PaintCode>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaintCodeService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: PaintCode,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaintCodeService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: PaintCode,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaintCodeService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaintCodeService.DeleteItem", {
        id,
      }
    );
  },
}
export const setUpTypeService = {
  getByID(
    id: string,
  ): Promise<SetUpType> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SetUpTypeService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<SetUpType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SetUpTypeService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<SetUpType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SetUpTypeService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: SetUpType,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SetUpTypeService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: SetUpType,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SetUpTypeService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SetUpTypeService.DeleteItem", {
        id,
      }
    );
  },
}
export const tsaService = {
  getByID(
    id: string,
  ): Promise<Tsa> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TsaService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Tsa>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TsaService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Tsa>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TsaService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: Tsa,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TsaService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: Tsa,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TsaService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TsaService.DeleteItem", {
        id,
      }
    );
  },
}
export const workCategoryService = {
  getByID(
    id: string,
  ): Promise<WorkCategory> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkCategoryService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkCategory>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkCategoryService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkCategory>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkCategoryService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: WorkCategory,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkCategoryService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: WorkCategory,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkCategoryService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkCategoryService.DeleteItem", {
        id,
      }
    );
  },
}
export const classificationService = {
  getByID(
    id: string,
  ): Promise<Classification> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ClassificationService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Classification>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ClassificationService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Classification>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ClassificationService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: Classification,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ClassificationService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: Classification,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ClassificationService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ClassificationService.DeleteItem", {
        id,
      }
    );
  },
}
export const configurationService = {
  getConfiguration(
  ): Promise<ClientConfiguration> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ConfigurationService.GetConfiguration", {
      }
    );
  },
}
export const contractorNormGroupService = {
  updateNormGroupOrders(
    norms: Array<ContractorNormGroup>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormGroupService.UpdateNormGroupOrders", {
        norms,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorNormGroup>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormGroupService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorNormGroup>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormGroupService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ContractorNormGroup> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormGroupService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: ContractorNormGroup,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormGroupService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormGroupService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormGroupService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ContractorNormGroup,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormGroupService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormGroupService.DeleteItem", {
        id,
      }
    );
  },
}
export const contractorNormService = {
  updateNormOrders(
    norms: Array<ContractorNorm>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormService.UpdateNormOrders", {
        norms,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorNorm>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorNorm>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ContractorNorm> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: ContractorNorm,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ContractorNorm,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorNormService.DeleteItem", {
        id,
      }
    );
  },
}
export const contractorPurchaseOrderService = {
  getByID(
    id: string,
  ): Promise<ContractorPurchaseOrder> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorPurchaseOrderService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorPurchaseOrder>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorPurchaseOrderService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorPurchaseOrder>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorPurchaseOrderService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ContractorPurchaseOrder,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorPurchaseOrderService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ContractorPurchaseOrder,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorPurchaseOrderService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorPurchaseOrderService.DeleteItem", {
        id,
      }
    );
  },
  updatePurchaseOrderOrders(
    purchaseOrders: Array<ContractorPurchaseOrder>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorPurchaseOrderService.UpdatePurchaseOrderOrders", {
        purchaseOrders,
      }
    );
  },
  getReferenceList(
  ): Promise<Array<ContractorPurchaseOrder>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorPurchaseOrderService.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ContractorPurchaseOrder>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorPurchaseOrderService.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const contractorService = {
  getPubliclyAvailableContractors(
  ): Promise<Array<BasicContractor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetPubliclyAvailableContractors", {
      }
    );
  },
  getScaffoldRequestingContractors(
  ): Promise<Array<ContractorWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetScaffoldRequestingContractors", {
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ContractorWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ContractorWithTags> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: ContractorWithTags,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    id: string,
    clientData: ContractorWithTags,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.UpdateItem", {
        id,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.DeleteItem", {
        id,
      }
    );
  },
  getByName(
    name: string,
  ): Promise<ContractorWithTags> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetByName", {
        name,
      }
    );
  },
  getContractor(
  ): Promise<PublicContractorDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetContractor", {
      }
    );
  },
  getContractorID(
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetContractorID", {
      }
    );
  },
  getPeople(
  ): Promise<Array<PublicPersonDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ContractorService.GetPeople", {
      }
    );
  },
}
export const countSheetGroupService = {
  getByID(
    id: string,
  ): Promise<CountSheetGroupWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<CountSheetGroupWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<CountSheetGroupWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: CountSheetGroupWithParts,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.AddItem", {
        clientData,
      }
    );
  },
  updateCountSheetGroupParts(
    countSheetGroupID: string,
    parts: Array<Part>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.UpdateCountSheetGroupParts", {
        countSheetGroupID,
        parts,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: CountSheetGroupWithParts,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  updateCountSheetGroupOrders(
    groups: Array<CountSheetGroupWithParts>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.UpdateCountSheetGroupOrders", {
        groups,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetGroupService.DeleteItem", {
        id,
      }
    );
  },
}
export const countSheetService = {
  getAllApproved(
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<CountSheetWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetAllApproved", {
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllPendingOrDeclined(
  ): Promise<Array<CountSheetWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetAllPendingOrDeclined", {
      }
    );
  },
  getByScaffoldID(
    scaffoldID: string,
  ): Promise<Array<CountSheetWithPartsForScaffold>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetByScaffoldID", {
        scaffoldID,
      }
    );
  },
  getByID(
    countSheetID: string,
  ): Promise<CountSheetWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetByID", {
        countSheetID,
      }
    );
  },
  getByWorkOrderID(
    workOrderID: string,
  ): Promise<CountSheetWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.GetByWorkOrderID", {
        workOrderID,
      }
    );
  },
  approvePendingCountSheet(
    countSheetID: string,
    fromYardID: string | null,
    toYardID: string | null,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.ApprovePendingCountSheet", {
        countSheetID,
        fromYardID,
        toYardID,
      }
    );
  },
  declinePendingCountSheet(
    countSheetID: string,
    declineReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.DeclinePendingCountSheet", {
        countSheetID,
        declineReason,
      }
    );
  },
  cancelPendingCountSheet(
    countSheetID: string,
    cancelReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.CancelPendingCountSheet", {
        countSheetID,
        cancelReason,
      }
    );
  },
  saveCountSheet(
    countSheetID: string,
    data: CountSheetWithParts,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.SaveCountSheet", {
        countSheetID,
        data,
      }
    );
  },
  submitCountSheet(
    countSheetID: string,
  ): Promise<CountSheetWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CountSheetService.SubmitCountSheet", {
        countSheetID,
      }
    );
  },
}
export const crewService = {
  getByID(
    id: string,
  ): Promise<CrewWithEmployees> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CrewService.GetByID", {
        id,
      }
    );
  },
  getByContractorId(
    contractorId: string,
  ): Promise<Array<CrewWithEmployees>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CrewService.GetByContractorId", {
        contractorId,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<CrewWithEmployees>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CrewService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<CrewWithEmployees>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CrewService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: CrewWithEmployees,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CrewService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: CrewWithEmployees,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CrewService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.CrewService.DeleteItem", {
        id,
      }
    );
  },
}
export const disciplineService = {
  getPubliclyAvailableDisciplines(
  ): Promise<Array<BasicDiscipline>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetPubliclyAvailableDisciplines", {
      }
    );
  },
  getByName(
    name: string,
  ): Promise<Discipline> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetByName", {
        name,
      }
    );
  },
  getByContractorID(
    contractorID: string | null,
  ): Promise<Array<Discipline>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetByContractorID", {
        contractorID,
      }
    );
  },
  getByPersonID(
    personID: string,
  ): Promise<Array<Discipline>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetByPersonID", {
        personID,
      }
    );
  },
  addItem(
    clientData: Discipline,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: Discipline,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.DeleteItem", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Discipline>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Discipline>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Discipline> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.GetByID", {
        id,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DisciplineService.UnarchiveItem", {
        itemID,
      }
    );
  },
}
export const dropoffLocationService = {
  getByName(
    name: string,
  ): Promise<DropoffLocation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.GetByName", {
        name,
      }
    );
  },
  addItem(
    clientData: DropoffLocation,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: DropoffLocation,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.DeleteItem", {
        id,
      }
    );
  },
  updateDropoffLocationOrders(
    dropoffLocations: Array<DropoffLocation>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.UpdateDropoffLocationOrders", {
        dropoffLocations,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<DropoffLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<DropoffLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<DropoffLocation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.GetByID", {
        id,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DropoffLocationService.UnarchiveItem", {
        itemID,
      }
    );
  },
}
export const environmentService = {
  getEnvironmentDefaultInspectionTimes(
  ): Promise<Array<InspectionTime>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetEnvironmentDefaultInspectionTimes", {
      }
    );
  },
  getEnvironmentThemeName(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetEnvironmentThemeName", {
      }
    );
  },
  getEnvironmentLoginTimeoutMinutes(
  ): Promise<number | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetEnvironmentLoginTimeoutMinutes", {
      }
    );
  },
  saveEnvironmentDailyThresholdClassifications(
    overtimeClassificationIDs: Array<string>,
    doubletimeClassificationIDs: Array<string>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.SaveEnvironmentDailyThresholdClassifications", {
        overtimeClassificationIDs,
        doubletimeClassificationIDs,
      }
    );
  },
  saveEnvironmentDefaultInspectionTimes(
    inspectionTimes: Array<InspectionTime>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.SaveEnvironmentDefaultInspectionTimes", {
        inspectionTimes,
      }
    );
  },
  getDefaultWorkOrderCostCodeIDs(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetDefaultWorkOrderCostCodeIDs", {
      }
    );
  },
  uploadEnvironmentBaseDataFile(
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.UploadEnvironmentBaseDataFile", requestBody, {
      }
    );
  },
  getSmsAccessCodeFromPhoneNumber(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetSmsAccessCodeFromPhoneNumber", {
      }
    );
  },
  getApplicationNameKey(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetApplicationNameKey", {
      }
    );
  },
  getApplicationNameConfiguration(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetApplicationNameConfiguration", {
      }
    );
  },
  getApplicationName(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetApplicationName", {
      }
    );
  },
  getEnvironmentSelfSignUp(
  ): Promise<boolean | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetEnvironmentSelfSignUp", {
      }
    );
  },
  getEnvironmentEnterpriseSignUp(
  ): Promise<boolean | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetEnvironmentEnterpriseSignUp", {
      }
    );
  },
  getCurrentEnvironmentID(
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetCurrentEnvironmentID", {
      }
    );
  },
  getCurrent(
  ): Promise<EnvironmentWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetCurrent", {
      }
    );
  },
  downloadOrgLogo(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.DownloadOrgLogo", {
      }
    );
  },
  uploadOrgLogo(
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.UploadOrgLogo", requestBody, {
      }
    );
  },
  deleteOrgLogo(
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.DeleteOrgLogo", {
      }
    );
  },
  getAll(
  ): Promise<Array<Environment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Environment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Environment,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: Environment,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.DeleteItem", {
        id,
      }
    );
  },
}
export const equipmentClassificationService = {
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<EquipmentClassification>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentClassificationService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
  ): Promise<Array<EquipmentClassification>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentClassificationService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<EquipmentClassification> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentClassificationService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: EquipmentClassification,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentClassificationService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: EquipmentClassification,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentClassificationService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentClassificationService.DeleteItem", {
        id,
      }
    );
  },
}
export const equipmentService = {
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<EquipmentWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<EquipmentWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<EquipmentWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentService.GetByID", {
        id,
      }
    );
  },
  getAllForContractor(
    contractorID: string,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<EquipmentForContractor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentService.GetAllForContractor", {
        contractorID,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: EquipmentWithDetails,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: EquipmentWithDetails,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EquipmentService.DeleteItem", {
        id,
      }
    );
  },
}
export const estimateSettingsService = {
  getCurrentEnvironmentEstimateSettings(
  ): Promise<EstimateSettings> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EstimateSettingsService.GetCurrentEnvironmentEstimateSettings", {
      }
    );
  },
  updateCurrentEnvironmentEstimateSettings(
    clientData: EstimateSettings,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EstimateSettingsService.UpdateCurrentEnvironmentEstimateSettings", {
        clientData,
      }
    );
  },
}
export const externalLinkService = {
  getByPersonID(
    personId: string,
  ): Promise<Array<ExternalLink>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetByPersonID", {
        personId,
      }
    );
  },
  getByJobID(
    jobId: string,
  ): Promise<Array<ExternalLink>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetByJobID", {
        jobId,
      }
    );
  },
  getByIsoID(
    isoId: string,
  ): Promise<Array<ExternalLink>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetByIsoID", {
        isoId,
      }
    );
  },
  getPersonExternalLinksByPersonID(
    personIDs: Array<string>,
  ): Promise<{ [key: string]: Array<ExternalLink> }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetPersonExternalLinksByPersonID", {
        personIDs,
      }
    );
  },
  getByScaffoldRequestID(
    scaffoldRequestId: string,
  ): Promise<Array<ExternalLink>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetByScaffoldRequestID", {
        scaffoldRequestId,
      }
    );
  },
  getByScaffoldDesignID(
    scaffoldDesignId: string,
  ): Promise<Array<ExternalLink>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetByScaffoldDesignID", {
        scaffoldDesignId,
      }
    );
  },
  getScaffoldRequestExternalLinksByScaffoldRequestID(
    scaffoldRequestIDs: Array<string>,
  ): Promise<{ [key: string]: Array<ExternalLink> }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.GetScaffoldRequestExternalLinksByScaffoldRequestID", {
        scaffoldRequestIDs,
      }
    );
  },
  addItem(
    clientData: ExternalLink,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ExternalLink,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ExternalLinkService.DeleteItem", {
        id,
      }
    );
  },
}
export const fleetService = {
  getByID(
    id: string,
  ): Promise<FleetWithEquipment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.FleetService.GetByID", {
        id,
      }
    );
  },
  getByContractorId(
    contractorId: string,
  ): Promise<Array<FleetWithEquipment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.FleetService.GetByContractorId", {
        contractorId,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<FleetWithEquipment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.FleetService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<FleetWithEquipment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.FleetService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: FleetWithEquipment,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.FleetService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: FleetWithEquipment,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.FleetService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.FleetService.DeleteItem", {
        id,
      }
    );
  },
}
export const hoardingModifierService = {
  getByID(
    id: string,
  ): Promise<HoardingModifier> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.HoardingModifierService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<HoardingModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.HoardingModifierService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<HoardingModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.HoardingModifierService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: HoardingModifier,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.HoardingModifierService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: HoardingModifier,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.HoardingModifierService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.HoardingModifierService.DeleteItem", {
        id,
      }
    );
  },
}
export const internalModifierService = {
  getByID(
    id: string,
  ): Promise<InternalModifier> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.InternalModifierService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<InternalModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.InternalModifierService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<InternalModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.InternalModifierService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: InternalModifier,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.InternalModifierService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: InternalModifier,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.InternalModifierService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.InternalModifierService.DeleteItem", {
        id,
      }
    );
  },
}
export const isoService = {
  getByTestPackageID(
    testpackageID: string,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ISO>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.GetByTestPackageID", {
        testpackageID,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  searchAll(
    searchString: string,
  ): Promise<Array<IsoSearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.SearchAll", {
        searchString,
      }
    );
  },
  getReferenceList(
  ): Promise<Array<ISO>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ISO>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.GetReferencesByID", {
        ids,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ISO>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ISO>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ISO> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: ISO,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ISO,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.IsoService.DeleteItem", {
        id,
      }
    );
  },
}
export const jobEstimateSnapshotService = {
  getEstimateSnapshotsForJobWithID(
    jobID: string,
  ): Promise<Array<JobEstimateSnapshotWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobEstimateSnapshotService.GetEstimateSnapshotsForJobWithID", {
        jobID,
      }
    );
  },
  getJobEstimateComponentTotalTimesUsingLWH(
    estimateComponent: JobEstimateComponent,
  ): Promise<EstimateTotalTimes> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobEstimateSnapshotService.GetJobEstimateComponentTotalTimesUsingLWH", {
        estimateComponent,
      }
    );
  },
  getJobWorkingComponentTotalTimesUsingLWH(
    workingComponent: JobWorkingComponent,
  ): Promise<EstimateTotalTimes> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobEstimateSnapshotService.GetJobWorkingComponentTotalTimesUsingLWH", {
        workingComponent,
      }
    );
  },
  getJobEstimateComponentTotalTimesUsingPartsList(
    estimateComponent: EstimateComponentData,
    partQuantities: Array<TakeoffPart>,
  ): Promise<EstimateTotalTimes> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobEstimateSnapshotService.GetJobEstimateComponentTotalTimesUsingPartsList", {
        estimateComponent,
        partQuantities,
      }
    );
  },
  generateAndUploadNewEstimateSnapshotForJobWithID(
    jobID: string,
    downloadFile: boolean,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobEstimateSnapshotService.GenerateAndUploadNewEstimateSnapshotForJobWithID", {
        jobID,
        downloadFile,
      }
    );
  },
  populateJobEstimateFileBySnapshotID(
    estimateSnapshotID: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobEstimateSnapshotService.PopulateJobEstimateFileBySnapshotID", {
        estimateSnapshotID,
      }
    );
  },
  getJobEstimateFileList(
    estimateId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobEstimateSnapshotService.GetJobEstimateFileList", {
        estimateId,
      }
    );
  },
  downloadJobEstimateGeneratedExcelFile(
    estimateId: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobEstimateSnapshotService.DownloadJobEstimateGeneratedExcelFile", {
        estimateId,
      }
    );
  },
}
export const jobService = {
  addItem(
    clientData: JobWithData,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: JobWithData,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  getJobListForType(
    jobType: JobTypes,
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<JobWithData>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobService.GetJobListForType", {
        jobType,
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    jobID: string,
  ): Promise<JobWithData> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobService.GetByID", {
        jobID,
      }
    );
  },
  resumeJobByID(
    jobID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobService.ResumeJobByID", {
        jobID,
      }
    );
  },
  markJobAsReadyByID(
    jobID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobService.MarkJobAsReadyByID", {
        jobID,
      }
    );
  },
  putJobOnHoldByID(
    jobID: string,
    onHoldReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobService.PutJobOnHoldByID", {
        jobID,
        onHoldReason,
      }
    );
  },
  cancelJobByID(
    jobID: string,
    cancelReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobService.CancelJobByID", {
        jobID,
        cancelReason,
      }
    );
  },
  releaseJobByID(
    jobID: string,
    assignedContractorID: string | null | undefined,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobService.ReleaseJobByID", {
        jobID,
        assignedContractorID,
      }
    );
  },
}
export const jobWorkingEstimateService = {
  getWorkingComponentsForJob(
    jobID: string,
  ): Promise<Array<JobWorkingComponent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingEstimateService.GetWorkingComponentsForJob", {
        jobID,
      }
    );
  },
  reAddLWHWorkingComponent(
    componentID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingEstimateService.ReAddLWHWorkingComponent", {
        componentID,
      }
    );
  },
  removeWorkingComponent(
    componentID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingEstimateService.RemoveWorkingComponent", {
        componentID,
      }
    );
  },
  updateWorkingComponentUsingLWH(
    componentID: string,
    clientData: JobWorkingComponent,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingEstimateService.UpdateWorkingComponentUsingLWH", {
        componentID,
        clientData,
      }
    );
  },
  updateDesignedWorkingComponent(
    componentID: string,
    partQuantities: Array<JobWorkingTakeoff>,
    clientData: JobWorkingComponent,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingEstimateService.UpdateDesignedWorkingComponent", {
        componentID,
        partQuantities,
        clientData,
      }
    );
  },
  addNewLWHWorkingComponentToJob(
    jobID: string,
    component: JobWorkingComponent,
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingEstimateService.AddNewLWHWorkingComponentToJob", {
        jobID,
        component,
      }
    );
  },
  addNewDesignedWorkingComponentToJob(
    jobID: string,
    partQuantities: Array<JobWorkingTakeoff>,
    component: JobWorkingComponent,
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingEstimateService.AddNewDesignedWorkingComponentToJob", {
        jobID,
        partQuantities,
        component,
      }
    );
  },
  generateNewModifyEstimateForJobWithID(
    jobID: string,
    componentData: JobWorkingComponent,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingEstimateService.GenerateNewModifyEstimateForJobWithID", {
        jobID,
        componentData,
      }
    );
  },
  generateNewModifyEstimateForJobWithIDFromEstimateData(
    jobID: string,
    componentData: IEstimateComponentData,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingEstimateService.GenerateNewModifyEstimateForJobWithIDFromEstimateData", {
        jobID,
        componentData,
      }
    );
  },
}
export const jobWorkingTakeoffService = {
  getWorkingTakeoffForJobWithID(
    jobID: string,
  ): Promise<Array<TakeoffPartWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingTakeoffService.GetWorkingTakeoffForJobWithID", {
        jobID,
      }
    );
  },
  getWorkingTakeoffForJobWorkingComponentWithID(
    jobWorkingComponentID: string,
  ): Promise<Array<TakeoffPartWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.JobWorkingTakeoffService.GetWorkingTakeoffForJobWorkingComponentWithID", {
        jobWorkingComponentID,
      }
    );
  },
}
export const lemsService = {
  getByContractorIDAndDate(
    contractorID: string,
    date: Date,
  ): Promise<LEMWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.GetByContractorIDAndDate", {
        contractorID,
        date,
      }
    );
  },
  getByID(
    lemID: string,
  ): Promise<LEMWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.GetByID", {
        lemID,
      }
    );
  },
  getAll(
    fromDay: Date | null,
    toDay: Date | null,
  ): Promise<Array<LEMWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.GetAll", {
        fromDay,
        toDay,
      }
    );
  },
  getAllPending(
  ): Promise<Array<LEMWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.GetAllPending", {
      }
    );
  },
  removeEmployeeEntriesFromTimesheetInLEMWithID(
    lemID: string,
    timesheetID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.RemoveEmployeeEntriesFromTimesheetInLEMWithID", {
        lemID,
        timesheetID,
      }
    );
  },
  updateEmployeeEntriesForLEMWithID(
    lemID: string,
    employeeEntries: Array<EmployeeLEMEntry>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.UpdateEmployeeEntriesForLEMWithID", {
        lemID,
        employeeEntries,
      }
    );
  },
  removeEquipmentEntriesFromTimesheetInLEMWithID(
    lemID: string,
    timesheetID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.RemoveEquipmentEntriesFromTimesheetInLEMWithID", {
        lemID,
        timesheetID,
      }
    );
  },
  updateEquipmentEntriesForLEMWithID(
    lemID: string,
    equipmentEntries: Array<EquipmentLEMEntry>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.UpdateEquipmentEntriesForLEMWithID", {
        lemID,
        equipmentEntries,
      }
    );
  },
  getEmployeeEntriesForLEMID(
    lemID: string,
  ): Promise<Array<EmployeeLEMEntryWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.GetEmployeeEntriesForLEMID", {
        lemID,
      }
    );
  },
  getEquipmentEntriesForLEMID(
    lemID: string,
  ): Promise<Array<EquipmentLEMEntryWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.GetEquipmentEntriesForLEMID", {
        lemID,
      }
    );
  },
  addItem(
    clientData: LEM,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    lemID: string,
    clientData: LEM,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.UpdateItem", {
        lemID,
        clientData,
      }
    );
  },
  submitLEM(
    lemID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.SubmitLEM", {
        lemID,
      }
    );
  },
  approvePendingLEM(
    lemID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.ApprovePendingLEM", {
        lemID,
      }
    );
  },
  declinePendingLEM(
    lemID: string,
    declineReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.DeclinePendingLEM", {
        lemID,
        declineReason,
      }
    );
  },
  importTimesheetDetailsIntoLEM(
    lemID: string,
    timesheetIDs: Array<string>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LEMsService.ImportTimesheetDetailsIntoLEM", {
        lemID,
        timesheetIDs,
      }
    );
  },
}
export const locationFilterService = {
  getPeopleWithFilters(
    personID: string,
  ): Promise<Array<PersonWithLocations>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.GetPeopleWithFilters", {
        personID,
      }
    );
  },
  getByPersonID(
    personID: string,
  ): Promise<LocationFilterWithLocations> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.GetByPersonID", {
        personID,
      }
    );
  },
  getPeopleWithMatchingFilter(
    personID: string,
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.GetPeopleWithMatchingFilter", {
        personID,
      }
    );
  },
  mergeMatchingFiltersService(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.MergeMatchingFiltersService", {
      }
    );
  },
  setLocationsInFilterForPersonID(
    projectLocationIDs: Array<string>,
    includesAllLocations: boolean,
    personID: string,
    additionalModifyPersonIDs: Array<string>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LocationFilterService.SetLocationsInFilterForPersonID", {
        projectLocationIDs,
        includesAllLocations,
        personID,
        additionalModifyPersonIDs,
      }
    );
  },
}
export const loginService = {
  getLoginChallenge(
    emailAddress: string,
    phoneNumber: string,
    personalIdentifier: string,
  ): Promise<LoginChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.GetLoginChallenge", {
        emailAddress,
        phoneNumber,
        personalIdentifier,
      }
    );
  },
  unlockAccount(
    personID: string,
  ): Promise<PersonLoginStates | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.UnlockAccount", {
        personID,
      }
    );
  },
  respondToLoginChallenge(
    loginContext: Uint8Array,
    publicHash: Uint8Array,
    contactMethodID: string | null,
  ): Promise<LoginInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.RespondToLoginChallenge", {
        loginContext,
        publicHash,
        contactMethodID,
      }
    );
  },
  getCurrentLoginInformation(
  ): Promise<LoginInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.GetCurrentLoginInformation", {
      }
    );
  },
  logout(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.Logout", {
      }
    );
  },
  getCurrentUserAccessInformation(
  ): Promise<AccessInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.GetCurrentUserAccessInformation", {
      }
    );
  },
  sendNewAccountNotice(
    baseUrl: string,
    personID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.SendNewAccountNotice", {
        baseUrl,
        personID,
      }
    );
  },
  updateUserPassword(
    publicSalt: Uint8Array,
    publicHash: Uint8Array,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.UpdateUserPassword", {
        publicSalt,
        publicHash,
      }
    );
  },
}
export const mapService = {
  getMapRoadTile(
    zoom: number,
    x: number,
    y: number,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MapService.GetMapRoadTile", {
        zoom,
        x,
        y,
      }
    );
  },
  getMapImageryTile(
    zoom: number,
    x: number,
    y: number,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MapService.GetMapImageryTile", {
        zoom,
        x,
        y,
      }
    );
  },
}
export const materialOrderService = {
  cancelMaterialOrder(
    materialOrderID: string,
    reason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.CancelMaterialOrder", {
        materialOrderID,
        reason,
      }
    );
  },
  abandonMaterialOrder(
    materialOrderID: string,
    reason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.AbandonMaterialOrder", {
        materialOrderID,
        reason,
      }
    );
  },
  getBackOrderedPartCounts(
  ): Promise<Array<BackOrderPartDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.GetBackOrderedPartCounts", {
      }
    );
  },
  getByID(
    materialOrderID: string,
  ): Promise<MaterialOrderWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.GetByID", {
        materialOrderID,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    fromDate: Date | null,
    toDate: Date | null,
  ): Promise<Array<MaterialOrderWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.GetAll", {
        forcedArchivedState,
        fromDate,
        toDate,
      }
    );
  },
  addItem(
    data: MaterialOrder,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.AddItem", {
        data,
      }
    );
  },
  submitMaterialOrder(
    materialOrderID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.SubmitMaterialOrder", {
        materialOrderID,
      }
    );
  },
  approveMaterialOrder(
    materialOrderID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.ApproveMaterialOrder", {
        materialOrderID,
      }
    );
  },
  declineMaterialOrder(
    materialOrderID: string,
    declineReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.DeclineMaterialOrder", {
        materialOrderID,
        declineReason,
      }
    );
  },
  createRequestMaterialOrder(
    supplierID: string,
    orderDate: Date | null,
    expectedDate: Date | null | undefined,
    referenceNumber: string | undefined,
    contactPerson: string | undefined,
    instructions: string | undefined,
    parts: Array<MaterialOrderPartDetails>,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.CreateRequestMaterialOrder", {
        supplierID,
        orderDate,
        expectedDate,
        referenceNumber,
        contactPerson,
        instructions,
        parts,
      }
    );
  },
  createReturnMaterialOrder(
    supplierID: string,
    orderDate: Date | null,
    expectedDate: Date | null | undefined,
    referenceNumber: string | undefined,
    contactPerson: string | undefined,
    instructions: string | undefined,
    parts: Array<MaterialOrderPartDetails>,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.CreateReturnMaterialOrder", {
        supplierID,
        orderDate,
        expectedDate,
        referenceNumber,
        contactPerson,
        instructions,
        parts,
      }
    );
  },
  getTransfersForMaterialOrder(
    materialOrderID: string,
  ): Promise<Array<TransferWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.GetTransfersForMaterialOrder", {
        materialOrderID,
      }
    );
  },
  updateMaterialOrder(
    orderID: string,
    orderDate: Date,
    expectedDate: Date | null | undefined,
    referenceNumber: string | undefined,
    contactPerson: string | undefined,
    instructions: string | undefined,
    parts: Array<MaterialOrderPartDetails> | undefined,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.UpdateMaterialOrder", {
        orderID,
        orderDate,
        expectedDate,
        referenceNumber,
        contactPerson,
        instructions,
        parts,
      }
    );
  },
  fulfillOrder(
    materialOrderID: string,
    yardID: string,
    transferDate: Date,
    transactionNumber: string,
    parts: Array<TransferPartDetails>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MaterialOrderService.FulfillOrder", {
        materialOrderID,
        yardID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
}
export const messageService = {
  getForScaffoldRequest(
    scaffoldRequestID: string,
  ): Promise<Array<MessageWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.GetForScaffoldRequest", {
        scaffoldRequestID,
      }
    );
  },
  getForWorkOrder(
    workOrderID: string,
  ): Promise<Array<MessageWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.GetForWorkOrder", {
        workOrderID,
      }
    );
  },
  getForJob(
    jobID: string,
  ): Promise<Array<MessageWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.GetForJob", {
        jobID,
      }
    );
  },
  addNewMessageForScaffoldRequest(
    messageText: string,
    scaffoldRequestID: string,
  ): Promise<MessageWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.AddNewMessageForScaffoldRequest", {
        messageText,
        scaffoldRequestID,
      }
    );
  },
  addNewMessageForWorkOrder(
    messageText: string,
    workOrderID: string,
  ): Promise<MessageWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.AddNewMessageForWorkOrder", {
        messageText,
        workOrderID,
      }
    );
  },
  addNewMessageForJob(
    messageText: string,
    jobID: string,
  ): Promise<MessageWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.MessageService.AddNewMessageForJob", {
        messageText,
        jobID,
      }
    );
  },
}
export const noteService = {
  getForScaffoldDesign(
    scaffoldDesignID: string,
  ): Promise<Array<NoteWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.GetForScaffoldDesign", {
        scaffoldDesignID,
      }
    );
  },
  getForScaffoldRequest(
    scaffoldRequestID: string,
  ): Promise<Array<NoteWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.GetForScaffoldRequest", {
        scaffoldRequestID,
      }
    );
  },
  getForWorkOrder(
    workOrderID: string,
  ): Promise<Array<NoteWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.GetForWorkOrder", {
        workOrderID,
      }
    );
  },
  getForWorkOrders(
    workOrderIDs: Array<string>,
  ): Promise<Array<NoteWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.GetForWorkOrders", {
        workOrderIDs,
      }
    );
  },
  getForJob(
    jobID: string,
  ): Promise<Array<NoteWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.GetForJob", {
        jobID,
      }
    );
  },
  getForTimesheetRow(
    clientData: TimesheetNoteThread,
  ): Promise<Array<NoteWithSenderDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.GetForTimesheetRow", {
        clientData,
      }
    );
  },
  addNewNoteForScaffoldDesign(
    noteText: string,
    scaffoldDesignID: string,
  ): Promise<NoteWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.AddNewNoteForScaffoldDesign", {
        noteText,
        scaffoldDesignID,
      }
    );
  },
  addNewNoteForScaffoldRequest(
    noteText: string,
    scaffoldRequestID: string,
  ): Promise<NoteWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.AddNewNoteForScaffoldRequest", {
        noteText,
        scaffoldRequestID,
      }
    );
  },
  addNewNoteForWorkOrder(
    noteText: string,
    workOrderID: string,
  ): Promise<NoteWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.AddNewNoteForWorkOrder", {
        noteText,
        workOrderID,
      }
    );
  },
  addNewNoteForJob(
    noteText: string,
    jobID: string,
  ): Promise<NoteWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.AddNewNoteForJob", {
        noteText,
        jobID,
      }
    );
  },
  addNewNoteForTimesheetRow(
    noteText: string,
    clientData: TimesheetNoteThread,
  ): Promise<NoteWithSenderDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NoteService.AddNewNoteForTimesheetRow", {
        noteText,
        clientData,
      }
    );
  },
}
export const notificationService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<NotificationWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<NotificationWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetByID", {
        id,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.DeleteItem", {
        id,
      }
    );
  },
  addItem(
    data: Notification,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.AddItem", {
        data,
      }
    );
  },
  startSmsNotifications(
    phoneNumber: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.StartSmsNotifications", {
        phoneNumber,
      }
    );
  },
  stopSmsNotifications(
    phoneNumber: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.StopSmsNotifications", {
        phoneNumber,
      }
    );
  },
  setAllowNotificationsContactMethods(
    contactMethods: Array<ContactMethod>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.SetAllowNotificationsContactMethods", {
        contactMethods,
      }
    );
  },
  updateItem(
    itemID: string,
    data: Notification,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  getCurrentUserActiveNotifications(
  ): Promise<Array<NotificationWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetCurrentUserActiveNotifications", {
      }
    );
  },
  getCurrentUserUnreadNotifications(
  ): Promise<Array<NotificationWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetCurrentUserUnreadNotifications", {
      }
    );
  },
  markNotificationWithReadStatus(
    notificationID: string,
    readStatus: boolean,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.MarkNotificationWithReadStatus", {
        notificationID,
        readStatus,
      }
    );
  },
  sendTestNotificationToLoggedInPerson(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.SendTestNotificationToLoggedInPerson", {
      }
    );
  },
}
export const ownerService = {
  getAll(
  ): Promise<Array<Owner>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Owner> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Owner,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: Owner,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.OwnerService.DeleteItem", {
        id,
      }
    );
  },
}
export const partService = {
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PartWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PartWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByCountSheetGroupID(
    countSheetGroupID: string,
  ): Promise<Array<PartWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetByCountSheetGroupID", {
        countSheetGroupID,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PartWithTags> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: PartWithTags,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: PartWithTags,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.DeleteItem", {
        id,
      }
    );
  },
  getAllPartYardBalances(
  ): Promise<Array<YardTotal>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetAllPartYardBalances", {
      }
    );
  },
  getDefaultImportSettings(
  ): Promise<PartsImportSettings> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.GetDefaultImportSettings", {
      }
    );
  },
  validatePartList(
    includeMPP: boolean,
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.ValidatePartList", requestBody, {
        includeMPP,
      }
    );
  },
  validateCustomizedPartList(
    includeMPP: boolean,
    friendlyIdentifierColumnNameOverride: string,
    publicIDTemplateColumnName: string,
    nameTemplateColumnName: string,
    descriptionTemplateColumnName: string,
    longDescriptionTemplateColumnName: string,
    weightTemplateColumnName: string,
    mppTemplateColumnName: string,
    lashingMPPTemplateColumnName: string,
    cleatingMPPTemplateColumnName: string,
    carpentryMPPTemplateColumnName: string,
    otherMPPTemplateColumnName: string,
    rentalRateTemplateColumnName: string,
    costUsedTemplateColumnName: string,
    costNewTemplateColumnName: string,
    designationTemplateColumnName: string,
    tagsTemplateColumnName: string,
    suppliersTemplateColumnName: string,
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.ValidateCustomizedPartList", requestBody, {
        includeMPP,
        friendlyIdentifierColumnNameOverride,
        publicIDTemplateColumnName,
        nameTemplateColumnName,
        descriptionTemplateColumnName,
        longDescriptionTemplateColumnName,
        weightTemplateColumnName,
        mppTemplateColumnName,
        lashingMPPTemplateColumnName,
        cleatingMPPTemplateColumnName,
        carpentryMPPTemplateColumnName,
        otherMPPTemplateColumnName,
        rentalRateTemplateColumnName,
        costUsedTemplateColumnName,
        costNewTemplateColumnName,
        designationTemplateColumnName,
        tagsTemplateColumnName,
        suppliersTemplateColumnName,
      }
    );
  },
  uploadPartList(
    includeMPP: boolean,
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.UploadPartList", requestBody, {
        includeMPP,
      }
    );
  },
  uploadCustomizedPartList(
    includeMPP: boolean,
    friendlyIdentifierColumnNameOverride: string,
    publicIDTemplateColumnName: string,
    nameTemplateColumnName: string,
    descriptionTemplateColumnName: string,
    longDescriptionTemplateColumnName: string,
    weightTemplateColumnName: string,
    mppTemplateColumnName: string,
    lashingMPPTemplateColumnName: string,
    cleatingMPPTemplateColumnName: string,
    carpentryMPPTemplateColumnName: string,
    otherMPPTemplateColumnName: string,
    rentalRateTemplateColumnName: string,
    costUsedTemplateColumnName: string,
    costNewTemplateColumnName: string,
    designationTemplateColumnName: string,
    tagsTemplateColumnName: string,
    suppliersTemplateColumnName: string,
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.UploadCustomizedPartList", requestBody, {
        includeMPP,
        friendlyIdentifierColumnNameOverride,
        publicIDTemplateColumnName,
        nameTemplateColumnName,
        descriptionTemplateColumnName,
        longDescriptionTemplateColumnName,
        weightTemplateColumnName,
        mppTemplateColumnName,
        lashingMPPTemplateColumnName,
        cleatingMPPTemplateColumnName,
        carpentryMPPTemplateColumnName,
        otherMPPTemplateColumnName,
        rentalRateTemplateColumnName,
        costUsedTemplateColumnName,
        costNewTemplateColumnName,
        designationTemplateColumnName,
        tagsTemplateColumnName,
        suppliersTemplateColumnName,
      }
    );
  },
  uploadPartList_Old(
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.UploadPartList_Old", requestBody, {
      }
    );
  },
  downloadPartListTemplate(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.DownloadPartListTemplate", {
      }
    );
  },
  downloadPartList(
    includeMPP: boolean,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PartService.DownloadPartList", {
        includeMPP,
      }
    );
  },
}
export const personalSettingService = {
  getForCurrentPerson(
  ): Promise<PersonalSetting> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalSettingService.GetForCurrentPerson", {
      }
    );
  },
  updateItemForCurrentPerson(
    clientData: PersonalSetting,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalSettingService.UpdateItemForCurrentPerson", {
        clientData,
      }
    );
  },
}
export const personService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PersonWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: PersonWithDetails,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: PersonWithDetails,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.DeleteItem", {
        id,
      }
    );
  },
  getAllActiveRequestors(
  ): Promise<Array<PersonWithParsedChildIDs>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllActiveRequestors", {
      }
    );
  },
  getAllDesignPersonnel(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllDesignPersonnel", {
      }
    );
  },
  getAllCoordinators(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllCoordinators", {
      }
    );
  },
  getAllVisibleDirectTimesheetCreators(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllVisibleDirectTimesheetCreators", {
      }
    );
  },
  getAllVisibleIndirectTimesheetCreators(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllVisibleIndirectTimesheetCreators", {
      }
    );
  },
  getAllTimeManagers(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllTimeManagers", {
      }
    );
  },
  getVisibleTimeManagers(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetVisibleTimeManagers", {
      }
    );
  },
  getAllGeneralForemen(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllGeneralForemen", {
      }
    );
  },
  getVisibleGeneralForemen(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetVisibleGeneralForemen", {
      }
    );
  },
  getAllForemen(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetAllForemen", {
      }
    );
  },
  getVisibleForemen(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.GetVisibleForemen", {
      }
    );
  },
  acceptCurrentTermsAndConditions(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonService.AcceptCurrentTermsAndConditions", {
      }
    );
  },
}
export const projectCostCodeService = {
  getByProjectID(
    projectID: string,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectCostCode>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectCostCodeService.GetByProjectID", {
        projectID,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ProjectCostCode> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectCostCodeService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectCostCode>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectCostCodeService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectCostCode>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectCostCodeService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ProjectCostCode,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectCostCodeService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ProjectCostCode,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectCostCodeService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectCostCodeService.DeleteItem", {
        id,
      }
    );
  },
  getReferenceList(
  ): Promise<Array<ProjectCostCode>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectCostCodeService.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ProjectCostCode>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectCostCodeService.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const projectLocationService = {
  getByProjectID(
    projectID: string,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetByProjectID", {
        projectID,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByParentLocationID(
    parentLocationID: string,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetByParentLocationID", {
        parentLocationID,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllAreas(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllAreas", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllActiveAreas(
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllActiveAreas", {
      }
    );
  },
  getVisibleAreas(
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetVisibleAreas", {
      }
    );
  },
  getAllAreasForPerson(
    personID: string,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllAreasForPerson", {
        personID,
      }
    );
  },
  getAllSubAreas(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllSubAreas", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllActiveSubAreas(
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllActiveSubAreas", {
      }
    );
  },
  getVisibleSubAreas(
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetVisibleSubAreas", {
      }
    );
  },
  getAllSubAreasForPerson(
    personID: string,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllSubAreasForPerson", {
        personID,
      }
    );
  },
  addItem(
    clientData: ProjectLocation,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ProjectLocation,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.DeleteItem", {
        id,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ProjectLocation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.GetByID", {
        id,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectLocationService.UnarchiveItem", {
        itemID,
      }
    );
  },
}
export const projectService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectWithParts>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ProjectWithParts> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: ProjectWithParts,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: ProjectWithParts,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.UpdateItem", {
        id,
        data,
      }
    );
  },
  updateProjectParts(
    projectID: string,
    partIDs: Array<string>,
    included: boolean,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.UpdateProjectParts", {
        projectID,
        partIDs,
        included,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ProjectService.DeleteItem", {
        id,
      }
    );
  },
}
export const questionService = {
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<QuestionWithText>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.QuestionService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<QuestionWithText>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.QuestionService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<QuestionWithText> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.QuestionService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: QuestionWithText,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.QuestionService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: QuestionWithText,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.QuestionService.UpdateItem", {
        id,
        data,
      }
    );
  },
  updateQuestionOrders(
    questions: Array<Question>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.QuestionService.UpdateQuestionOrders", {
        questions,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.QuestionService.DeleteItem", {
        id,
      }
    );
  },
}
export const supplierService = {
  getReferenceList(
  ): Promise<Array<Supplier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<Supplier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.GetReferencesByID", {
        ids,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<SupplierWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<SupplierWithTags>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<SupplierWithTags> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: SupplierWithTags,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    supplierID: string,
    data: SupplierWithTags,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.UpdateItem", {
        supplierID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SupplierService.DeleteItem", {
        id,
      }
    );
  },
}
export const regionService = {
  getAll(
  ): Promise<Array<Region>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Region> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Region,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: Region,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.RegionService.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldBayHeightService = {
  getByID(
    id: string,
  ): Promise<ScaffoldBayHeight> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayHeightService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldBayHeight>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayHeightService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldBayHeight>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayHeightService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ScaffoldBayHeight,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayHeightService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldBayHeight,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayHeightService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayHeightService.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldBayLengthService = {
  getByID(
    id: string,
  ): Promise<ScaffoldBayLength> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayLengthService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldBayLength>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayLengthService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldBayLength>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayLengthService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ScaffoldBayLength,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayLengthService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldBayLength,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayLengthService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayLengthService.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldBayWidthService = {
  getByID(
    id: string,
  ): Promise<ScaffoldBayWidth> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayWidthService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldBayWidth>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayWidthService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldBayWidth>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayWidthService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ScaffoldBayWidth,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayWidthService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldBayWidth,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayWidthService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldBayWidthService.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldCongestionFactorService = {
  getByID(
    id: string,
  ): Promise<ScaffoldCongestionFactor> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldCongestionFactorService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldCongestionFactor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldCongestionFactorService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldCongestionFactor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldCongestionFactorService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ScaffoldCongestionFactor,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldCongestionFactorService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldCongestionFactor,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldCongestionFactorService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldCongestionFactorService.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldDesignEstimateSnapshotService = {
  getEstimateSnapshotsForScaffoldDesignWithID(
    scaffoldDesignID: string,
  ): Promise<Array<ScaffoldDesignEstimateSnapshotWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignEstimateSnapshotService.GetEstimateSnapshotsForScaffoldDesignWithID", {
        scaffoldDesignID,
      }
    );
  },
  getScaffoldDesignEstimateComponentTotalTimesUsingLWH(
    estimateComponent: ScaffoldDesignEstimateComponent,
  ): Promise<EstimateTotalTimes> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignEstimateSnapshotService.GetScaffoldDesignEstimateComponentTotalTimesUsingLWH", {
        estimateComponent,
      }
    );
  },
  getScaffoldDesignWorkingComponentTotalTimesUsingLWH(
    workingComponent: ScaffoldDesignWorkingComponent,
  ): Promise<EstimateTotalTimes> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignEstimateSnapshotService.GetScaffoldDesignWorkingComponentTotalTimesUsingLWH", {
        workingComponent,
      }
    );
  },
  getScaffoldDesignEstimateComponentTotalTimesUsingPartsList(
    estimateComponent: EstimateComponentData,
    partQuantities: Array<TakeoffPart>,
  ): Promise<EstimateTotalTimes> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignEstimateSnapshotService.GetScaffoldDesignEstimateComponentTotalTimesUsingPartsList", {
        estimateComponent,
        partQuantities,
      }
    );
  },
  generateAndUploadNewEstimateSnapshotForScaffoldDesignWithID(
    scaffoldDesignID: string,
    downloadFile: boolean,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignEstimateSnapshotService.GenerateAndUploadNewEstimateSnapshotForScaffoldDesignWithID", {
        scaffoldDesignID,
        downloadFile,
      }
    );
  },
  populateScaffoldDesignEstimateFileBySnapshotID(
    estimateSnapshotID: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignEstimateSnapshotService.PopulateScaffoldDesignEstimateFileBySnapshotID", {
        estimateSnapshotID,
      }
    );
  },
  getScaffoldDesignEstimateFileList(
    estimateId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignEstimateSnapshotService.GetScaffoldDesignEstimateFileList", {
        estimateId,
      }
    );
  },
  downloadScaffoldDesignEstimateGeneratedExcelFile(
    estimateId: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignEstimateSnapshotService.DownloadScaffoldDesignEstimateGeneratedExcelFile", {
        estimateId,
      }
    );
  },
}
export const scaffoldDesignService = {
  getAll(
  ): Promise<Array<ScaffoldDesignWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.GetAll", {
      }
    );
  },
  getAllReleased(
  ): Promise<Array<ScaffoldDesignWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.GetAllReleased", {
      }
    );
  },
  getByID(
    designId: string,
  ): Promise<ScaffoldDesignWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.GetByID", {
        designId,
      }
    );
  },
  addItem(
    clientData: ScaffoldDesignWithDetails,
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    designId: string,
    data: ScaffoldDesignWithDetails,
    callingContext: string | undefined | null,
  ): Promise<ScaffoldDesign> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.UpdateItem", {
        designId,
        data,
        callingContext,
      }
    );
  },
  releaseScaffoldDesign(
    id: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.ReleaseScaffoldDesign", {
        id,
      }
    );
  },
  getScaffoldDesignFileList(
    designId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.GetScaffoldDesignFileList", {
        designId,
      }
    );
  },
  downloadScaffoldDesignFile(
    designId: string,
    fileName: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.DownloadScaffoldDesignFile", {
        designId,
        fileName,
      }
    );
  },
  uploadScaffoldDesignFile(
    designId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.UploadScaffoldDesignFile", requestBody, {
        designId,
        fileName,
      }
    );
  },
  deleteScaffoldDesignFile(
    designId: string,
    fileName: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.DeleteScaffoldDesignFile", {
        designId,
        fileName,
      }
    );
  },
  deleteAllScaffoldDesignFiles(
    designId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignService.DeleteAllScaffoldDesignFiles", {
        designId,
      }
    );
  },
}
export const scaffoldDesignTakeoffService = {
  getTakeoffForScaffoldDesignWithID(
    scaffoldDesignID: string,
  ): Promise<Array<ScaffoldDesignTakeoffPart>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignTakeoffService.GetTakeoffForScaffoldDesignWithID", {
        scaffoldDesignID,
      }
    );
  },
}
export const scaffoldDesignWorkingEstimateService = {
  getWorkingComponentsForScaffoldDesign(
    scaffoldDesignID: string,
  ): Promise<Array<ScaffoldDesignWorkingComponent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingEstimateService.GetWorkingComponentsForScaffoldDesign", {
        scaffoldDesignID,
      }
    );
  },
  reAddLWHWorkingComponent(
    componentID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingEstimateService.ReAddLWHWorkingComponent", {
        componentID,
      }
    );
  },
  removeWorkingComponent(
    componentID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingEstimateService.RemoveWorkingComponent", {
        componentID,
      }
    );
  },
  updateWorkingComponentUsingLWH(
    componentID: string,
    clientData: ScaffoldDesignWorkingComponent,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingEstimateService.UpdateWorkingComponentUsingLWH", {
        componentID,
        clientData,
      }
    );
  },
  updateDesignedWorkingComponent(
    componentID: string,
    partQuantities: Array<ScaffoldDesignWorkingTakeoff>,
    clientData: ScaffoldDesignWorkingComponent,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingEstimateService.UpdateDesignedWorkingComponent", {
        componentID,
        partQuantities,
        clientData,
      }
    );
  },
  addNewLWHWorkingComponentToScaffoldDesign(
    scaffoldDesignID: string,
    component: ScaffoldDesignWorkingComponent,
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingEstimateService.AddNewLWHWorkingComponentToScaffoldDesign", {
        scaffoldDesignID,
        component,
      }
    );
  },
  addNewDesignedWorkingComponentToScaffoldDesign(
    scaffoldDesignID: string,
    partQuantities: Array<ScaffoldDesignWorkingTakeoff>,
    component: ScaffoldDesignWorkingComponent,
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingEstimateService.AddNewDesignedWorkingComponentToScaffoldDesign", {
        scaffoldDesignID,
        partQuantities,
        component,
      }
    );
  },
  generateNewModifyEstimateForScaffoldDesignWithID(
    scaffoldDesignID: string,
    componentData: ScaffoldDesignWorkingComponent,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingEstimateService.GenerateNewModifyEstimateForScaffoldDesignWithID", {
        scaffoldDesignID,
        componentData,
      }
    );
  },
  generateNewModifyEstimateForScaffoldDesignWithIDFromEstimateData(
    scaffoldDesignID: string,
    componentData: IEstimateComponentData,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingEstimateService.GenerateNewModifyEstimateForScaffoldDesignWithIDFromEstimateData", {
        scaffoldDesignID,
        componentData,
      }
    );
  },
}
export const scaffoldDesignWorkingTakeoffService = {
  getWorkingTakeoffForScaffoldDesignWithID(
    scaffoldDesignID: string,
  ): Promise<Array<TakeoffPartWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingTakeoffService.GetWorkingTakeoffForScaffoldDesignWithID", {
        scaffoldDesignID,
      }
    );
  },
  getWorkingTakeoffForScaffoldDesignWorkingComponentWithID(
    scaffoldDesignWorkingComponentID: string,
  ): Promise<Array<TakeoffPartWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDesignWorkingTakeoffService.GetWorkingTakeoffForScaffoldDesignWorkingComponentWithID", {
        scaffoldDesignWorkingComponentID,
      }
    );
  },
}
export const scaffoldDistanceModifierService = {
  getByID(
    id: string,
  ): Promise<ScaffoldDistanceModifier> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDistanceModifierService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldDistanceModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDistanceModifierService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldDistanceModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDistanceModifierService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ScaffoldDistanceModifier,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDistanceModifierService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldDistanceModifier,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDistanceModifierService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldDistanceModifierService.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldElevationModifierService = {
  getByName(
    name: string,
  ): Promise<ScaffoldElevationModifier> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldElevationModifierService.GetByName", {
        name,
      }
    );
  },
  addItem(
    clientData: ScaffoldElevationModifier,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldElevationModifierService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldElevationModifier,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldElevationModifierService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldElevationModifierService.DeleteItem", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldElevationModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldElevationModifierService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldElevationModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldElevationModifierService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ScaffoldElevationModifier> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldElevationModifierService.GetByID", {
        id,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldElevationModifierService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldElevationModifierService.UnarchiveItem", {
        itemID,
      }
    );
  },
}
export const scaffoldHeightModifierService = {
  getByID(
    id: string,
  ): Promise<ScaffoldHeightModifier> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldHeightModifierService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldHeightModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldHeightModifierService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldHeightModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldHeightModifierService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ScaffoldHeightModifier,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldHeightModifierService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldHeightModifier,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldHeightModifierService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldHeightModifierService.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldInspectionService = {
  getScaffoldInspectionTimes(
  ): Promise<Array<InspectionTime>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetScaffoldInspectionTimes", {
      }
    );
  },
  getScaffoldInspectionTimeRanges(
  ): Promise<Array<ScaffoldInspectionTimeRange>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetScaffoldInspectionTimeRanges", {
      }
    );
  },
  getScaffoldInspectionTimeRangesConvertedToDay(
    dayStart: Date | null,
  ): Promise<Array<ScaffoldInspectionTimeRange>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetScaffoldInspectionTimeRangesConvertedToDay", {
        dayStart,
      }
    );
  },
  getScaffoldInspectionTimeRangesWithScaffoldInspectionStatus(
    scaffoldID: string | null,
    dayStart: Date | null,
  ): Promise<Array<ScaffoldInspectionTimeRange>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetScaffoldInspectionTimeRangesWithScaffoldInspectionStatus", {
        scaffoldID,
        dayStart,
      }
    );
  },
  getScaffoldInspectionStatusesForScaffoldForTimeRange(
    scaffoldID: string,
    timeStart: Date,
    timeEnd: Date,
  ): Promise<ScaffoldWithInspectionStatus> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetScaffoldInspectionStatusesForScaffoldForTimeRange", {
        scaffoldID,
        timeStart,
        timeEnd,
      }
    );
  },
  getStandingScaffoldInspectionStatusesForTimeRange(
    timeStart: Date,
    timeEnd: Date,
  ): Promise<Array<ScaffoldWithInspectionStatus>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetStandingScaffoldInspectionStatusesForTimeRange", {
        timeStart,
        timeEnd,
      }
    );
  },
  getStandingScaffoldInspectionStatusesForDay(
    dayStart: Date,
  ): Promise<Array<ScaffoldWithInspectionStatus>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetStandingScaffoldInspectionStatusesForDay", {
        dayStart,
      }
    );
  },
  getByScaffoldID(
    scaffoldID: string,
    fromDate: Date,
    toDate: Date,
  ): Promise<Array<ScaffoldInspectionWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetByScaffoldID", {
        scaffoldID,
        fromDate,
        toDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldInspectionWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldInspectionWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    itemID: string,
  ): Promise<ScaffoldInspectionWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetByID", {
        itemID,
      }
    );
  },
  addItem(
    clientData: ScaffoldInspectionWithDetails,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.AddItem", {
        clientData,
      }
    );
  },
  getScaffoldInspectionFileList(
    scaffoldInspectionId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.GetScaffoldInspectionFileList", {
        scaffoldInspectionId,
      }
    );
  },
  downloadScaffoldInspectionFile(
    scaffoldInspectionId: string,
    fileName: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.DownloadScaffoldInspectionFile", {
        scaffoldInspectionId,
        fileName,
      }
    );
  },
  uploadScaffoldInspectionFile(
    scaffoldInspectionId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.UploadScaffoldInspectionFile", requestBody, {
        scaffoldInspectionId,
        fileName,
      }
    );
  },
  deleteScaffoldInspectionFile(
    scaffoldInspectionId: string,
    fileName: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.DeleteScaffoldInspectionFile", {
        scaffoldInspectionId,
        fileName,
      }
    );
  },
  deleteAllScaffoldInspectionFiles(
    scaffoldInspectionId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldInspectionService.DeleteAllScaffoldInspectionFiles", {
        scaffoldInspectionId,
      }
    );
  },
}
export const scaffoldRequestService = {
  getByRequestNumber(
    requestNumber: string,
  ): Promise<ScaffoldRequest> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetByRequestNumber", {
        requestNumber,
      }
    );
  },
  getByIDWithDetails(
    scaffoldRequestID: string,
  ): Promise<ScaffoldRequestWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetByIDWithDetails", {
        scaffoldRequestID,
      }
    );
  },
  getApprovalsWithDetails(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldRequestWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetApprovalsWithDetails", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithDetails(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldRequestWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetAllWithDetails", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addScaffoldRequest(
    scaffoldRequest: ScaffoldRequestWithChildIDs,
    isBeingSubmitted: boolean,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.AddScaffoldRequest", {
        scaffoldRequest,
        isBeingSubmitted,
      }
    );
  },
  updateScaffoldRequestDetails(
    scaffoldRequest: ScaffoldRequestWithChildIDs,
  ): Promise<ScaffoldRequest> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UpdateScaffoldRequestDetails", {
        scaffoldRequest,
      }
    );
  },
  submitScaffoldRequest(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.SubmitScaffoldRequest", {
        itemID,
      }
    );
  },
  updateScaffoldRequestApproval(
    scaffoldRequestID: string,
    isApproved: boolean,
    contractorID: string | null,
    approvalComments: string,
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UpdateScaffoldRequestApproval", {
        scaffoldRequestID,
        isApproved,
        contractorID,
        approvalComments,
      }
    );
  },
  getDismantleWorkOrdersForScaffoldTag(
    scaffoldNumber: number,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetDismantleWorkOrdersForScaffoldTag", {
        scaffoldNumber,
      }
    );
  },
  getSubmittedRequestsForScaffoldTag(
    scaffoldNumber: number,
  ): Promise<Array<ScaffoldRequestWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetSubmittedRequestsForScaffoldTag", {
        scaffoldNumber,
      }
    );
  },
  getDismantleRequestsForScaffoldTag(
    scaffoldNumber: number,
  ): Promise<Array<ScaffoldRequestWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetDismantleRequestsForScaffoldTag", {
        scaffoldNumber,
      }
    );
  },
  cancelScaffoldRequest(
    scaffoldRequestID: string,
    reason: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.CancelScaffoldRequest", {
        scaffoldRequestID,
        reason,
      }
    );
  },
  getScaffoldRequestFileList(
    requestId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetScaffoldRequestFileList", {
        requestId,
      }
    );
  },
  downloadScaffoldRequestFile(
    requestId: string,
    fileName: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile", {
        requestId,
        fileName,
      }
    );
  },
  uploadScaffoldRequestFile(
    requestId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UploadScaffoldRequestFile", requestBody, {
        requestId,
        fileName,
      }
    );
  },
  deleteScaffoldRequestFile(
    requestId: string,
    fileName: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DeleteScaffoldRequestFile", {
        requestId,
        fileName,
      }
    );
  },
  deleteAllScaffoldRequestFiles(
    requestId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DeleteAllScaffoldRequestFiles", {
        requestId,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldRequest>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldRequest>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<ScaffoldRequest> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: ScaffoldRequest,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldRequest,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestService.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldRequestSubTypeHelper = {
  getAllScaffoldRequestSubTypesWithParent(
  ): Promise<Array<ScaffoldRequestSubTypeWithParent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllScaffoldRequestSubTypesWithParent", {
      }
    );
  },
  getAllMaintenanceRequestSubTypesWithParent(
  ): Promise<Array<ScaffoldRequestSubTypeWithParent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllMaintenanceRequestSubTypesWithParent", {
      }
    );
  },
  getAllPaintRequestSubTypesWithParent(
  ): Promise<Array<ScaffoldRequestSubTypeWithParent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllPaintRequestSubTypesWithParent", {
      }
    );
  },
  getAllInsulationRequestSubTypesWithParent(
  ): Promise<Array<ScaffoldRequestSubTypeWithParent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllInsulationRequestSubTypesWithParent", {
      }
    );
  },
  getAllHeatTraceRequestSubTypesWithParent(
  ): Promise<Array<ScaffoldRequestSubTypeWithParent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllHeatTraceRequestSubTypesWithParent", {
      }
    );
  },
  getAllRefractoryRequestSubTypesWithParent(
  ): Promise<Array<ScaffoldRequestSubTypeWithParent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllRefractoryRequestSubTypesWithParent", {
      }
    );
  },
  getAllFireproofingRequestSubTypesWithParent(
  ): Promise<Array<ScaffoldRequestSubTypeWithParent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllFireproofingRequestSubTypesWithParent", {
      }
    );
  },
  getAllRequestSubTypesWithParent(
  ): Promise<Array<ScaffoldRequestSubTypeWithParent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestSubTypeHelper.GetAllRequestSubTypesWithParent", {
      }
    );
  },
}
export const scaffoldRequestTypeHelper = {
  getAllScaffoldRequestTypeDetails(
  ): Promise<Array<ScaffoldRequestTypeDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllScaffoldRequestTypeDetails", {
      }
    );
  },
  getAllMaintenanceRequestTypeDetails(
  ): Promise<Array<ScaffoldRequestTypeDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllMaintenanceRequestTypeDetails", {
      }
    );
  },
  getAllPaintRequestTypeDetails(
  ): Promise<Array<ScaffoldRequestTypeDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllPaintRequestTypeDetails", {
      }
    );
  },
  getAllInsulationRequestTypeDetails(
  ): Promise<Array<ScaffoldRequestTypeDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllInsulationRequestTypeDetails", {
      }
    );
  },
  getAllHeatTraceRequestTypeDetails(
  ): Promise<Array<ScaffoldRequestTypeDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllHeatTraceRequestTypeDetails", {
      }
    );
  },
  getAllRefractoryRequestTypeDetails(
  ): Promise<Array<ScaffoldRequestTypeDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllRefractoryRequestTypeDetails", {
      }
    );
  },
  getAllFireproofingRequestTypeDetails(
  ): Promise<Array<ScaffoldRequestTypeDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllFireproofingRequestTypeDetails", {
      }
    );
  },
  getAllRequestTypeDetails(
  ): Promise<Array<ScaffoldRequestTypeDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldRequestTypeHelper.GetAllRequestTypeDetails", {
      }
    );
  },
}
export const scaffoldService = {
  getAllScaffolds(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetAllScaffolds", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllScaffoldsOptimized(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldForList>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetAllScaffoldsOptimized", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getStandingPartCountsForScaffoldID(
    scaffoldID: string,
    minTransferDate: Date | null,
    maxTransferDate: Date | null,
  ): Promise<Array<PartWithTotal>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetStandingPartCountsForScaffoldID", {
        scaffoldID,
        minTransferDate,
        maxTransferDate,
      }
    );
  },
  getBasicScaffoldInfo(
    scaffoldID: string,
  ): Promise<ScaffoldInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetBasicScaffoldInfo", {
        scaffoldID,
      }
    );
  },
  getByID(
    scaffoldID: string,
  ): Promise<ScaffoldWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetByID", {
        scaffoldID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldWithDetails,
  ): Promise<Scaffold> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  getStandingScaffoldsWithLocations(
  ): Promise<Array<Scaffold>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.GetStandingScaffoldsWithLocations", {
      }
    );
  },
  searchAll(
    searchString: string,
    includeInactiveScaffolds: boolean,
  ): Promise<Array<ScaffoldSearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldService.SearchAll", {
        searchString,
        includeInactiveScaffolds,
      }
    );
  },
}
export const scaffoldTypeModifierService = {
  getByID(
    id: string,
  ): Promise<ScaffoldTypeModifier> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldTypeModifierService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldTypeModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldTypeModifierService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ScaffoldTypeModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldTypeModifierService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: ScaffoldTypeModifier,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldTypeModifierService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: ScaffoldTypeModifier,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldTypeModifierService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ScaffoldTypeModifierService.DeleteItem", {
        id,
      }
    );
  },
}
export const searchService = {
  globalSearch(
    searchString: string,
    tagIDs: Array<string>,
  ): Promise<Array<SearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SearchService.GlobalSearch", {
        searchString,
        tagIDs,
      }
    );
  },
}
export const statisticsService = {
  getRequestsNeedingApprovalCount(
  ): Promise<number> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.StatisticsService.GetRequestsNeedingApprovalCount", {
      }
    );
  },
  getWalkdownsToCompleteCount(
  ): Promise<number> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.StatisticsService.GetWalkdownsToCompleteCount", {
      }
    );
  },
  getEstimatesToCompleteCount(
  ): Promise<number> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.StatisticsService.GetEstimatesToCompleteCount", {
      }
    );
  },
  getInspectionsToCompleteCount(
    fromDate: Date,
  ): Promise<number> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.StatisticsService.GetInspectionsToCompleteCount", {
        fromDate,
      }
    );
  },
}
export const systemService = {
  getReferenceList(
  ): Promise<Array<System>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<System>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.GetReferencesByID", {
        ids,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<System>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<System>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<System> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: System,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: System,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SystemService.DeleteItem", {
        id,
      }
    );
  },
}
export const testPackageService = {
  getBySystemID(
    systemID: string,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<TestPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestPackageService.GetBySystemID", {
        systemID,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<TestPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestPackageService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<TestPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestPackageService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<TestPackage> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestPackageService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: TestPackage,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestPackageService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestPackageService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestPackageService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: TestPackage,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestPackageService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestPackageService.DeleteItem", {
        id,
      }
    );
  },
}
export const testService = {
  testSimpleMethod(
    input: string,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestService.TestSimpleMethod", {
        input,
      }
    );
  },
  testFailedMethod(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestService.TestFailedMethod", {
      }
    );
  },
  testServiceMethod(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestService.TestServiceMethod", {
      }
    );
  },
  testAuthentication(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TestService.TestAuthentication", {
      }
    );
  },
}
export const timesheetService = {
  getDatesForWeekContainingDate(
    date: Date,
  ): Promise<Array<DayInWeek>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetDatesForWeekContainingDate", {
        date,
      }
    );
  },
  getTimeSummaryForContractorInWeek(
    contractorID: string,
    date: Date,
  ): Promise<Array<EmployeeWeekTimeSummary>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetTimeSummaryForContractorInWeek", {
        contractorID,
        date,
      }
    );
  },
  getByOwnerIDAndDate(
    ownerID: string,
    date: Date,
    includeCancelled: boolean,
  ): Promise<TimesheetWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetByOwnerIDAndDate", {
        ownerID,
        date,
        includeCancelled,
      }
    );
  },
  getOpenTimesheetSummary(
  ): Promise<Array<OwnerTimesheetSummary>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetOpenTimesheetSummary", {
      }
    );
  },
  getEmployeeTimeSummariesForEmployeesFromOtherTimesheetsOnDay(
    employeeIDList: Array<string>,
    date: Date,
    excludeTimesheetID: string,
  ): Promise<Array<EmployeeTimeSummary>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetEmployeeTimeSummariesForEmployeesFromOtherTimesheetsOnDay", {
        employeeIDList,
        date,
        excludeTimesheetID,
      }
    );
  },
  getEmployeeTimeSummariesForTimesheetWithID(
    timesheetID: string,
    excludeCurrentTimesheet: boolean,
  ): Promise<Array<EmployeeTimeSummary>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetEmployeeTimeSummariesForTimesheetWithID", {
        timesheetID,
        excludeCurrentTimesheet,
      }
    );
  },
  getByID(
    timesheetID: string,
  ): Promise<TimesheetWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetByID", {
        timesheetID,
      }
    );
  },
  getAllVisibleOpenTimesheets(
  ): Promise<Array<TimesheetWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetAllVisibleOpenTimesheets", {
      }
    );
  },
  getCorrectedTimesheets(
    fromDay: Date | null,
    toDay: Date | null,
  ): Promise<Array<TimesheetWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetCorrectedTimesheets", {
        fromDay,
        toDay,
      }
    );
  },
  getFinishedTimesheets(
    fromDay: Date | null,
    toDay: Date | null,
    includeCancelled: boolean,
  ): Promise<Array<TimesheetWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetFinishedTimesheets", {
        fromDay,
        toDay,
        includeCancelled,
      }
    );
  },
  getTimesheetsForLabourEntryDialog(
    ownerID: string,
    fromDay: Date | null,
    toDay: Date | null,
  ): Promise<Array<TimesheetWithChildren>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetTimesheetsForLabourEntryDialog", {
        ownerID,
        fromDay,
        toDay,
      }
    );
  },
  getAllForOwner(
    ownerID: string,
    fromDay: Date | null,
    toDay: Date | null,
  ): Promise<Array<TimesheetWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetAllForOwner", {
        ownerID,
        fromDay,
        toDay,
      }
    );
  },
  getAllForDay(
    day: Date,
    includeDayShift: boolean,
    includeNightShift: boolean,
    includeEquipment: boolean,
    includeCancelled: boolean,
  ): Promise<Array<TimesheetWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetAllForDay", {
        day,
        includeDayShift,
        includeNightShift,
        includeEquipment,
        includeCancelled,
      }
    );
  },
  getAll(
    fromDay: Date | null,
    toDay: Date | null,
  ): Promise<Array<TimesheetWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetAll", {
        fromDay,
        toDay,
      }
    );
  },
  getListOptimized(
    filterTimesheetIDs: Array<string>,
    filterStatuses: Array<TimesheetStatus>,
    fromDay: Date | null,
    toDay: Date | null,
    filterContractorID: string | null,
    filterOwnerIDs: Array<string>,
  ): Promise<Array<TimesheetWithChildren>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetListOptimized", {
        filterTimesheetIDs,
        filterStatuses,
        fromDay,
        toDay,
        filterContractorID,
        filterOwnerIDs,
      }
    );
  },
  getAllPending(
  ): Promise<Array<TimesheetWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetAllPending", {
      }
    );
  },
  saveTimesheet(
    timesheetID: string,
    data: Timesheet,
    employeeEntries: Array<TimesheetEntry>,
    equipmentEntries: Array<EquipmentTimesheetEntry>,
    isCorrection: boolean,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.SaveTimesheet", {
        timesheetID,
        data,
        employeeEntries,
        equipmentEntries,
        isCorrection,
      }
    );
  },
  updateExplanationsForTimesheetWithID(
    timesheetID: string,
    explanations: Array<TimesheetExplanation>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.UpdateExplanationsForTimesheetWithID", {
        timesheetID,
        explanations,
      }
    );
  },
  removeEmployeeEntriesFromTimesheetWithID(
    timesheetID: string,
    entryIDs: Array<string>,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.RemoveEmployeeEntriesFromTimesheetWithID", {
        timesheetID,
        entryIDs,
      }
    );
  },
  updateEmployeeEntriesForTimesheetWithID(
    timesheetID: string,
    entries: Array<TimesheetEntry>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.UpdateEmployeeEntriesForTimesheetWithID", {
        timesheetID,
        entries,
      }
    );
  },
  addEmployeeEntriesToTimesheetWithID(
    timesheetID: string,
    entries: Array<TimesheetEntry>,
  ): Promise<Array<TimesheetEntry>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.AddEmployeeEntriesToTimesheetWithID", {
        timesheetID,
        entries,
      }
    );
  },
  removeEquipmentEntriesFromTimesheetWithID(
    timesheetID: string,
    equipmentEntryIDs: Array<string>,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.RemoveEquipmentEntriesFromTimesheetWithID", {
        timesheetID,
        equipmentEntryIDs,
      }
    );
  },
  updateEquipmentEntriesForTimesheetWithID(
    timesheetID: string,
    entries: Array<EquipmentTimesheetEntry>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.UpdateEquipmentEntriesForTimesheetWithID", {
        timesheetID,
        entries,
      }
    );
  },
  addEquipmentEntriesToTimesheetWithID(
    timesheetID: string,
    entries: Array<EquipmentTimesheetEntry>,
  ): Promise<Array<EquipmentTimesheetEntry>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.AddEquipmentEntriesToTimesheetWithID", {
        timesheetID,
        entries,
      }
    );
  },
  getEquipmentEntriesForTimesheetID(
    timesheetID: string,
  ): Promise<Array<EquipmentTimesheetEntryWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetEquipmentEntriesForTimesheetID", {
        timesheetID,
      }
    );
  },
  getEmployeeEntriesForTimesheetID(
    timesheetID: string,
  ): Promise<Array<EmployeeTimesheetEntryWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetEmployeeEntriesForTimesheetID", {
        timesheetID,
      }
    );
  },
  checkOwnerHasTimesheetForContractorOnDay(
    ownerID: string,
    contractorID: string,
    day: Date,
    timesheetType: TimesheetType,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.CheckOwnerHasTimesheetForContractorOnDay", {
        ownerID,
        contractorID,
        day,
        timesheetType,
      }
    );
  },
  addItem(
    clientData: Timesheet,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.AddItem", {
        clientData,
      }
    );
  },
  updateDayForTimesheet(
    timesheetID: string,
    day: Date,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.UpdateDayForTimesheet", {
        timesheetID,
        day,
      }
    );
  },
  updateItem(
    timesheetID: string,
    clientData: Timesheet,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.UpdateItem", {
        timesheetID,
        clientData,
      }
    );
  },
  submitTimesheet(
    timesheetID: string,
    submittedToID: string | null | undefined,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.SubmitTimesheet", {
        timesheetID,
        submittedToID,
      }
    );
  },
  approvePendingTimesheet(
    timesheetID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.ApprovePendingTimesheet", {
        timesheetID,
      }
    );
  },
  declinePendingTimesheet(
    timesheetID: string,
    declineReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.DeclinePendingTimesheet", {
        timesheetID,
        declineReason,
      }
    );
  },
  cancelPendingTimesheet(
    timesheetID: string,
    cancelReason: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.CancelPendingTimesheet", {
        timesheetID,
        cancelReason,
      }
    );
  },
  getApprovedTimesheetsForLemWithID(
    lemID: string,
  ): Promise<Array<TimesheetWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetApprovedTimesheetsForLemWithID", {
        lemID,
      }
    );
  },
  getApprovedTimesheetsOnDayForContractorWithID(
    day: Date,
    contractorID: string,
  ): Promise<Array<TimesheetWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetApprovedTimesheetsOnDayForContractorWithID", {
        day,
        contractorID,
      }
    );
  },
  getNoteThreadDetailsForTimesheet(
    timesheetID: string,
  ): Promise<Array<TimesheetNoteThreadWithNoteCount>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TimesheetService.GetNoteThreadDetailsForTimesheet", {
        timesheetID,
      }
    );
  },
}
export const transferService = {
  getRelatedScaffoldPartSources(
    countSheetID: string,
  ): Promise<Array<TransferPartDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.GetRelatedScaffoldPartSources", {
        countSheetID,
      }
    );
  },
  getScaffoldPartSources(
    scaffoldID: string | null,
  ): Promise<Array<TransferPartDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.GetScaffoldPartSources", {
        scaffoldID,
      }
    );
  },
  reverseTransfer(
    transferID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.ReverseTransfer", {
        transferID,
      }
    );
  },
  correctTransferToNewDestinationYard(
    transferID: string,
    toYardID: string,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.CorrectTransferToNewDestinationYard", {
        transferID,
        toYardID,
      }
    );
  },
  overrideTransferDetails(
    transferID: string,
    fromYardID: string | null | undefined,
    toYardID: string | null | undefined,
    transferDate: Date | undefined,
    transactionNumber: string | undefined,
    parts: Array<TransferPartDetails> | undefined,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.OverrideTransferDetails", {
        transferID,
        fromYardID,
        toYardID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  transferPartsBetweenYards(
    fromYardID: string,
    toYardID: string,
    transferDate: Date,
    transactionNumber: string,
    parts: Array<TransferPartDetails>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.TransferPartsBetweenYards", {
        fromYardID,
        toYardID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  deliverPartsToScaffold(
    yardID: string,
    scaffoldID: string,
    workOrderID: string | null,
    transferDate: Date,
    transactionNumber: string,
    parts: Array<TransferPartDetails>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.DeliverPartsToScaffold", {
        yardID,
        scaffoldID,
        workOrderID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  returnPartsToYard(
    scaffoldID: string,
    yardID: string,
    workOrderID: string | null,
    transferDate: Date,
    transactionNumber: string,
    parts: Array<TransferPartDetails>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.ReturnPartsToYard", {
        scaffoldID,
        yardID,
        workOrderID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  transferPartsBetweenScaffolds(
    sourceScaffoldID: string,
    targetScaffoldID: string,
    transferDate: Date,
    transactionNumber: string,
    parts: Array<TransferPartDetails>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.TransferPartsBetweenScaffolds", {
        sourceScaffoldID,
        targetScaffoldID,
        transferDate,
        transactionNumber,
        parts,
      }
    );
  },
  mergeScaffolds(
    sourceScaffoldID: string,
    targetScaffoldID: string,
    transferDate: Date,
    transactionNumber: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.MergeScaffolds", {
        sourceScaffoldID,
        targetScaffoldID,
        transferDate,
        transactionNumber,
      }
    );
  },
  getByID(
    transferID: string,
  ): Promise<TransferWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.GetByID", {
        transferID,
      }
    );
  },
  getAll(
    fromDate: Date | null,
    toDate: Date | null,
  ): Promise<Array<TransferWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.GetAll", {
        fromDate,
        toDate,
      }
    );
  },
  getMaterialFileList(
    transferId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.GetMaterialFileList", {
        transferId,
      }
    );
  },
  downloadMaterialFile(
    transferId: string,
    fileName: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.DownloadMaterialFile", {
        transferId,
        fileName,
      }
    );
  },
  uploadMaterialFile(
    transferId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.UploadMaterialFile", requestBody, {
        transferId,
        fileName,
      }
    );
  },
  deleteMaterialFile(
    transferId: string,
    fileName: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.DeleteMaterialFile", {
        transferId,
        fileName,
      }
    );
  },
  deleteAllMaterialFiles(
    transferId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TransferService.DeleteAllMaterialFiles", {
        transferId,
      }
    );
  },
}
export const userService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PersonWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithIncludes(
    includeChildren: boolean,
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PersonWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetAllWithIncludes", {
        includeChildren,
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getCurrent(
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetCurrent", {
      }
    );
  },
  getCurrentUserFullName(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetCurrentUserFullName", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetByID", {
        id,
      }
    );
  },
  getByIDWithIncludes(
    id: string,
    includeChildren: boolean,
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetByIDWithIncludes", {
        id,
        includeChildren,
      }
    );
  },
  addItem(
    data: PersonWithDetails,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: PersonWithDetails,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteItem", {
        id,
      }
    );
  },
  downloadUserPhoto(
    id: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DownloadUserPhoto", {
        id,
      }
    );
  },
  uploadUserPhoto(
    personId: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.UploadUserPhoto", requestBody, {
        personId,
      }
    );
  },
  deleteUserPhoto(
    personId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteUserPhoto", {
        personId,
      }
    );
  },
  getUserFileList(
    personId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetUserFileList", {
        personId,
      }
    );
  },
  downloadUserFile(
    personId: string,
    fileName: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DownloadUserFile", {
        personId,
        fileName,
      }
    );
  },
  uploadUserFile(
    personId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.UploadUserFile", requestBody, {
        personId,
        fileName,
      }
    );
  },
  deleteUserFile(
    personId: string,
    fileName: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteUserFile", {
        personId,
        fileName,
      }
    );
  },
  deleteAllUserFiles(
    personId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteAllUserFiles", {
        personId,
      }
    );
  },
}
export const lemsListService = {
  getAll(
    fromDay: Date | null,
    toDay: Date | null,
  ): Promise<Array<LemsListDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LemsListService.GetAll", {
        fromDay,
        toDay,
      }
    );
  },
  getAllPending(
  ): Promise<Array<LemsListDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LemsListService.GetAllPending", {
      }
    );
  },
}
export const walkdownReferenceDataService = {
  getAllScaffoldBayLengths(
  ): Promise<Array<ScaffoldBayLength>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldBayLengths", {
      }
    );
  },
  getAllScaffoldBayWidths(
  ): Promise<Array<ScaffoldBayWidth>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldBayWidths", {
      }
    );
  },
  getAllScaffoldBayHeights(
  ): Promise<Array<ScaffoldBayHeight>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldBayHeights", {
      }
    );
  },
  getAllScaffoldTypeModifiers(
  ): Promise<Array<ScaffoldTypeModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldTypeModifiers", {
      }
    );
  },
  getActiveScaffoldTypeModifiers(
  ): Promise<Array<ScaffoldTypeModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetActiveScaffoldTypeModifiers", {
      }
    );
  },
  getAllScaffoldDistanceModifiers(
  ): Promise<Array<ScaffoldDistanceModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldDistanceModifiers", {
      }
    );
  },
  getAllScaffoldElevationModifiers(
  ): Promise<Array<ScaffoldElevationModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldElevationModifiers", {
      }
    );
  },
  getAllScaffoldHeightModifiers(
  ): Promise<Array<ScaffoldHeightModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldHeightModifiers", {
      }
    );
  },
  getAllBuildDismantleRatios(
  ): Promise<Array<BuildDismantleRatio>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllBuildDismantleRatios", {
      }
    );
  },
  getAllScaffoldCongestionFactors(
  ): Promise<Array<ScaffoldCongestionFactor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllScaffoldCongestionFactors", {
      }
    );
  },
  getAllInternalModifiers(
  ): Promise<Array<InternalModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllInternalModifiers", {
      }
    );
  },
  getAllHoardingModifiers(
  ): Promise<Array<HoardingModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownReferenceDataService.GetAllHoardingModifiers", {
      }
    );
  },
}
export const walkdownService = {
  startNewWalkdown(
    workOrderId: string,
  ): Promise<Walkdown> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownService.StartNewWalkdown", {
        workOrderId,
      }
    );
  },
  updateItem(
    walkdownId: string,
    data: Walkdown,
  ): Promise<Walkdown> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WalkdownService.UpdateItem", {
        walkdownId,
        data,
      }
    );
  },
}
export const workOrderEstimateSnapshotService = {
  getEstimateSnapshotsForWorkOrderWithID(
    workOrderID: string,
  ): Promise<Array<WorkOrderEstimateSnapshotWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderEstimateSnapshotService.GetEstimateSnapshotsForWorkOrderWithID", {
        workOrderID,
      }
    );
  },
  getWorkOrderEstimateComponentTotalTimesUsingLWH(
    estimateComponent: WorkOrderEstimateComponent,
  ): Promise<EstimateTotalTimes> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderEstimateSnapshotService.GetWorkOrderEstimateComponentTotalTimesUsingLWH", {
        estimateComponent,
      }
    );
  },
  getWorkOrderWorkingComponentTotalTimesUsingLWH(
    workingComponent: WorkOrderWorkingComponent,
  ): Promise<EstimateTotalTimes> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderEstimateSnapshotService.GetWorkOrderWorkingComponentTotalTimesUsingLWH", {
        workingComponent,
      }
    );
  },
  getWorkOrderEstimateComponentTotalTimesUsingPartsList(
    estimateComponent: EstimateComponentData,
    partQuantities: Array<TakeoffPart>,
  ): Promise<EstimateTotalTimes> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderEstimateSnapshotService.GetWorkOrderEstimateComponentTotalTimesUsingPartsList", {
        estimateComponent,
        partQuantities,
      }
    );
  },
  generateAndUploadNewEstimateSnapshotForWorkOrderWithID(
    workOrderID: string,
    downloadFile: boolean,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderEstimateSnapshotService.GenerateAndUploadNewEstimateSnapshotForWorkOrderWithID", {
        workOrderID,
        downloadFile,
      }
    );
  },
  populateWorkOrderEstimateFileBySnapshotID(
    estimateSnapshotID: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderEstimateSnapshotService.PopulateWorkOrderEstimateFileBySnapshotID", {
        estimateSnapshotID,
      }
    );
  },
  getWorkOrderEstimateFileList(
    estimateId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderEstimateSnapshotService.GetWorkOrderEstimateFileList", {
        estimateId,
      }
    );
  },
  downloadWorkOrderEstimateGeneratedExcelFile(
    estimateId: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderEstimateSnapshotService.DownloadWorkOrderEstimateGeneratedExcelFile", {
        estimateId,
      }
    );
  },
}
export const workOrderNormResponseService = {
  getResponsesForWorkOrder(
    workOrderID: string,
  ): Promise<Array<WorkOrderNormResponseWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderNormResponseService.GetResponsesForWorkOrder", {
        workOrderID,
      }
    );
  },
  updateWorkOrderNormResponses(
    workOrderID: string,
    clientData: BuildSheet,
    responses: Array<WorkOrderNormResponse>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderNormResponseService.UpdateWorkOrderNormResponses", {
        workOrderID,
        clientData,
        responses,
      }
    );
  },
}
export const workOrderService = {
  getDefaultImportSettings(
  ): Promise<WorkOrderImportSettings> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetDefaultImportSettings", {
      }
    );
  },
  validateWorkOrderList(
    friendlyIdentifierColumnNameOverride: string,
    workOrderNumberTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    requiredDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.ValidateWorkOrderList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        workOrderNumberTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        requiredDateTemplateColumnNameOverride,
      }
    );
  },
  uploadWorkOrderList(
    friendlyIdentifierColumnNameOverride: string,
    workOrderNumberTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    requiredDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.UploadWorkOrderList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        workOrderNumberTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        requiredDateTemplateColumnNameOverride,
      }
    );
  },
  downloadWorkOrderListTemplate(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.DownloadWorkOrderListTemplate", {
      }
    );
  },
  downloadWorkOrderList(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.DownloadWorkOrderList", {
      }
    );
  },
  updateItem(
    workOrderId: string,
    data: WorkOrderWithAllDetails,
    callingContext: string | undefined | null,
  ): Promise<WorkOrder> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.UpdateItem", {
        workOrderId,
        data,
        callingContext,
      }
    );
  },
  getWorkOrderByID(
    workOrderID: string,
  ): Promise<WorkOrderWithAllDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrderByID", {
        workOrderID,
      }
    );
  },
  getWorkOrdersForTodoListForForeman(
    foremanID: string,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrdersForTodoListForForeman", {
        foremanID,
      }
    );
  },
  getWorkOrdersToEstimate(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrdersToEstimate", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWorkOrders(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderForAllWorkOrdersList>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetAllWorkOrders", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getWorkOrderHistory(
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
    assignedForemanID: string | null,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrderHistory", {
        archivedFromDate,
        archivedToDate,
        assignedForemanID,
      }
    );
  },
  getWorkOrdersRequiringAdminWork(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrdersRequiringAdminWork", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getWorkOrdersToSchedule(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrdersToSchedule", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByScaffoldID(
    scaffoldID: string,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetByScaffoldID", {
        scaffoldID,
      }
    );
  },
  getDismantleWorkOrdersForScaffoldTag(
    scaffoldID: string | null,
    scaffoldNumber: number,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetDismantleWorkOrdersForScaffoldTag", {
        scaffoldID,
        scaffoldNumber,
      }
    );
  },
  getActiveWorkOrdersForScaffoldTag(
    scaffoldID: string | null,
    scaffoldNumber: number,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetActiveWorkOrdersForScaffoldTag", {
        scaffoldID,
        scaffoldNumber,
      }
    );
  },
  getBasicWorkOrderInfoForTimesheet(
    timesheetID: string | null,
  ): Promise<Array<WorkOrderSearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetBasicWorkOrderInfoForTimesheet", {
        timesheetID,
      }
    );
  },
  getWorkOrdersForTimesheet(
    timesheetID: string | null,
  ): Promise<Array<WorkOrderWithAllDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetWorkOrdersForTimesheet", {
        timesheetID,
      }
    );
  },
  search(
    activeOnlyDate: Date | null | undefined,
    workOrderNumberSearchString: string | undefined,
    scaffoldIDSearchString: string | undefined,
    assignedContractorIDSearchString: string | undefined,
    assignedForemanIDSearchString: string | undefined,
    historicallyAssignedForemanIDSearchString: string | undefined,
  ): Promise<Array<WorkOrderSearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.Search", {
        activeOnlyDate,
        workOrderNumberSearchString,
        scaffoldIDSearchString,
        assignedContractorIDSearchString,
        assignedForemanIDSearchString,
        historicallyAssignedForemanIDSearchString,
      }
    );
  },
  getEmployeeTotalDirectTimeHoursForWorkOrder(
    workOrderID: string,
  ): Promise<number> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetEmployeeTotalDirectTimeHoursForWorkOrder", {
        workOrderID,
      }
    );
  },
  startThreeWeekLookAheadExport(
    workOrderIDs: Array<string>,
    date: Date | null,
    productiveHoursPerDay: number | null,
    isWorkDay: Array<boolean>,
  ): Promise<Uint8Array> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.StartThreeWeekLookAheadExport", {
        workOrderIDs,
        date,
        productiveHoursPerDay,
        isWorkDay,
      }
    );
  },
  getThreeWeekLookaheadResult(
    requestContext: Uint8Array,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.GetThreeWeekLookaheadResult", {
        requestContext,
      }
    );
  },
  updateWorkOrderRequiredDates(
    workOrderIDs: Array<string>,
    newRequiredDate: Date,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.UpdateWorkOrderRequiredDates", {
        workOrderIDs,
        newRequiredDate,
      }
    );
  },
  createNewWorkOrderToEstimate(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.CreateNewWorkOrderToEstimate", {
      }
    );
  },
  createNewWorkOrderToSchedule(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.CreateNewWorkOrderToSchedule", {
      }
    );
  },
  createNewWorkOrderForToDoList(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderService.CreateNewWorkOrderForToDoList", {
      }
    );
  },
}
export const workOrderStatusHelper = {
  getApprovalsSelectableScaffoldRequestStatuses(
  ): Promise<Array<ScaffoldRequestStatusDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetApprovalsSelectableScaffoldRequestStatuses", {
      }
    );
  },
  getWorkingSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetWorkingSelectableWorkOrderStatuses", {
      }
    );
  },
  getRequiresAdminSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetRequiresAdminSelectableWorkOrderStatuses", {
      }
    );
  },
  getHistorySelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetHistorySelectableWorkOrderStatuses", {
      }
    );
  },
  getEstimatesSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetEstimatesSelectableWorkOrderStatuses", {
      }
    );
  },
  getSchedulerSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetSchedulerSelectableWorkOrderStatuses", {
      }
    );
  },
  getToDoListSelectableWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetToDoListSelectableWorkOrderStatuses", {
      }
    );
  },
  getAllWorkOrderStatuses(
  ): Promise<Array<WorkOrderStatusDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderStatusHelper.GetAllWorkOrderStatuses", {
      }
    );
  },
}
export const workOrderTakeoffService = {
  getTakeoffForWorkOrderWithID(
    workOrderID: string,
  ): Promise<Array<WorkOrderTakeoffPart>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderTakeoffService.GetTakeoffForWorkOrderWithID", {
        workOrderID,
      }
    );
  },
}
export const workOrderWorkingEstimateService = {
  getWorkingComponentsForWorkOrder(
    workOrderID: string,
  ): Promise<Array<WorkOrderWorkingComponent>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingEstimateService.GetWorkingComponentsForWorkOrder", {
        workOrderID,
      }
    );
  },
  reAddLWHWorkingComponent(
    componentID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingEstimateService.ReAddLWHWorkingComponent", {
        componentID,
      }
    );
  },
  removeWorkingComponent(
    componentID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingEstimateService.RemoveWorkingComponent", {
        componentID,
      }
    );
  },
  updateWorkingComponentUsingModify(
    componentID: string,
    clientData: WorkOrderWorkingComponent,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingEstimateService.UpdateWorkingComponentUsingModify", {
        componentID,
        clientData,
      }
    );
  },
  updateWorkingComponentUsingLWH(
    componentID: string,
    clientData: WorkOrderWorkingComponent,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingEstimateService.UpdateWorkingComponentUsingLWH", {
        componentID,
        clientData,
      }
    );
  },
  updateDesignedWorkingComponent(
    componentID: string,
    partQuantities: Array<WorkOrderWorkingTakeoff>,
    clientData: WorkOrderWorkingComponent,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingEstimateService.UpdateDesignedWorkingComponent", {
        componentID,
        partQuantities,
        clientData,
      }
    );
  },
  addNewLWHWorkingComponentToWorkOrder(
    workOrderID: string,
    component: WorkOrderWorkingComponent,
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingEstimateService.AddNewLWHWorkingComponentToWorkOrder", {
        workOrderID,
        component,
      }
    );
  },
  addNewDesignedWorkingComponentToWorkOrder(
    workOrderID: string,
    partQuantities: Array<WorkOrderWorkingTakeoff>,
    component: WorkOrderWorkingComponent,
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingEstimateService.AddNewDesignedWorkingComponentToWorkOrder", {
        workOrderID,
        partQuantities,
        component,
      }
    );
  },
  addNewModifyWorkingComponentToWorkOrder(
    workOrderID: string,
    componentData: WorkOrderWorkingComponent,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingEstimateService.AddNewModifyWorkingComponentToWorkOrder", {
        workOrderID,
        componentData,
      }
    );
  },
}
export const workOrderWorkingTakeoffService = {
  getWorkingTakeoffForWorkOrderWithID(
    workOrderID: string,
  ): Promise<Array<TakeoffPartWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingTakeoffService.GetWorkingTakeoffForWorkOrderWithID", {
        workOrderID,
      }
    );
  },
  getWorkingTakeoffForWorkOrderWorkingComponentWithID(
    workOrderWorkingComponentID: string,
  ): Promise<Array<TakeoffPartWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkOrderWorkingTakeoffService.GetWorkingTakeoffForWorkOrderWorkingComponentWithID", {
        workOrderWorkingComponentID,
      }
    );
  },
}
export const workPackageService = {
  getDefaultImportSettings(
  ): Promise<WorkPackagesImportSettings> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetDefaultImportSettings", {
      }
    );
  },
  validateWorkPackageList(
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.ValidateWorkPackageList", requestBody, {
      }
    );
  },
  validateCustomizedWorkPackageList(
    friendlyIdentifierColumnNameOverride: string,
    activityIDTemplateColumnNameOverride: string,
    nameTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    finishDateTemplateColumnNameOverride: string,
    completionPercentageTemplateColumnNameOverride: string,
    workStatusTemplateColumnNameOverride: string,
    purposeTemplateColumnNameOverride: string,
    plannedStartDateTemplateColumnNameOverride: string,
    plannedFinishDateTemplateColumnNameOverride: string,
    cwpPlannedStartDateTemplateColumnNameOverride: string,
    cwpPlannedFinishDateTemplateColumnNameOverride: string,
    forecastStartDateTemplateColumnNameOverride: string,
    forecastFinishDateTemplateColumnNameOverride: string,
    disciplineTemplateColumnNameOverride: string,
    unitTemplateColumnNameOverride: string,
    constructionWorkAreaTemplateColumnNameOverride: string,
    areaTemplateColumnNameOverride: string,
    longDescriptionTemplateColumnNameOverride: string,
    descriptionTemplateColumnNameOverride: string,
    cwpTemplateColumnNameOverride: string,
    estimatedHoursTemplateColumnNameOverride: string,
    earnedHoursTemplateColumnNameOverride: string,
    statusDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<Blob> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.ValidateCustomizedWorkPackageList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        activityIDTemplateColumnNameOverride,
        nameTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        finishDateTemplateColumnNameOverride,
        completionPercentageTemplateColumnNameOverride,
        workStatusTemplateColumnNameOverride,
        purposeTemplateColumnNameOverride,
        plannedStartDateTemplateColumnNameOverride,
        plannedFinishDateTemplateColumnNameOverride,
        cwpPlannedStartDateTemplateColumnNameOverride,
        cwpPlannedFinishDateTemplateColumnNameOverride,
        forecastStartDateTemplateColumnNameOverride,
        forecastFinishDateTemplateColumnNameOverride,
        disciplineTemplateColumnNameOverride,
        unitTemplateColumnNameOverride,
        constructionWorkAreaTemplateColumnNameOverride,
        areaTemplateColumnNameOverride,
        longDescriptionTemplateColumnNameOverride,
        descriptionTemplateColumnNameOverride,
        cwpTemplateColumnNameOverride,
        estimatedHoursTemplateColumnNameOverride,
        earnedHoursTemplateColumnNameOverride,
        statusDateTemplateColumnNameOverride,
      }
    );
  },
  uploadWorkPackageList(
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.UploadWorkPackageList", requestBody, {
      }
    );
  },
  uploadCustomizedWorkPackageList(
    friendlyIdentifierColumnNameOverride: string,
    activityIDTemplateColumnNameOverride: string,
    nameTemplateColumnNameOverride: string,
    startDateTemplateColumnNameOverride: string,
    finishDateTemplateColumnNameOverride: string,
    completionPercentageTemplateColumnNameOverride: string,
    workStatusTemplateColumnNameOverride: string,
    purposeTemplateColumnNameOverride: string,
    plannedStartDateTemplateColumnNameOverride: string,
    plannedFinishDateTemplateColumnNameOverride: string,
    cwpPlannedStartDateTemplateColumnNameOverride: string,
    cwpPlannedFinishDateTemplateColumnNameOverride: string,
    forecastStartDateTemplateColumnNameOverride: string,
    forecastFinishDateTemplateColumnNameOverride: string,
    disciplineTemplateColumnNameOverride: string,
    unitTemplateColumnNameOverride: string,
    constructionWorkAreaTemplateColumnNameOverride: string,
    areaTemplateColumnNameOverride: string,
    longDescriptionTemplateColumnNameOverride: string,
    descriptionTemplateColumnNameOverride: string,
    cwpTemplateColumnNameOverride: string,
    estimatedHoursTemplateColumnNameOverride: string,
    earnedHoursTemplateColumnNameOverride: string,
    statusDateTemplateColumnNameOverride: string,
    requestBody: Blob,
  ): Promise<BulkImportResults> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.UploadCustomizedWorkPackageList", requestBody, {
        friendlyIdentifierColumnNameOverride,
        activityIDTemplateColumnNameOverride,
        nameTemplateColumnNameOverride,
        startDateTemplateColumnNameOverride,
        finishDateTemplateColumnNameOverride,
        completionPercentageTemplateColumnNameOverride,
        workStatusTemplateColumnNameOverride,
        purposeTemplateColumnNameOverride,
        plannedStartDateTemplateColumnNameOverride,
        plannedFinishDateTemplateColumnNameOverride,
        cwpPlannedStartDateTemplateColumnNameOverride,
        cwpPlannedFinishDateTemplateColumnNameOverride,
        forecastStartDateTemplateColumnNameOverride,
        forecastFinishDateTemplateColumnNameOverride,
        disciplineTemplateColumnNameOverride,
        unitTemplateColumnNameOverride,
        constructionWorkAreaTemplateColumnNameOverride,
        areaTemplateColumnNameOverride,
        longDescriptionTemplateColumnNameOverride,
        descriptionTemplateColumnNameOverride,
        cwpTemplateColumnNameOverride,
        estimatedHoursTemplateColumnNameOverride,
        earnedHoursTemplateColumnNameOverride,
        statusDateTemplateColumnNameOverride,
      }
    );
  },
  downloadWorkPackageListTemplate(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.DownloadWorkPackageListTemplate", {
      }
    );
  },
  downloadWorkPackageList(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.DownloadWorkPackageList", {
      }
    );
  },
  getAllWithStatus(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
    statuses: Array<string>,
  ): Promise<Array<WorkPackageForList>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetAllWithStatus", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
        statuses,
      }
    );
  },
  getUsedWorkStatuses(
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetUsedWorkStatuses", {
      }
    );
  },
  searchAll(
    searchString: string,
  ): Promise<Array<WorkPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.SearchAll", {
        searchString,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkPackage>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<WorkPackage> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: WorkPackage,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: WorkPackage,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkPackageService.DeleteItem", {
        id,
      }
    );
  },
}
export const workSubTypeService = {
  getByID(
    id: string,
  ): Promise<WorkSubType> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkSubTypeService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkSubTypeWithParentDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkSubTypeService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkSubTypeWithParentDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkSubTypeService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: WorkSubType,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkSubTypeService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: WorkSubType,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkSubTypeService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkSubTypeService.DeleteItem", {
        id,
      }
    );
  },
  updateWorkSubTypeOrders(
    subTypes: Array<WorkSubType>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkSubTypeService.UpdateWorkSubTypeOrders", {
        subTypes,
      }
    );
  },
  moveUnderOtherWorkSubType(
    itemID: string,
    destinationWorkSubTypeID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkSubTypeService.MoveUnderOtherWorkSubType", {
        itemID,
        destinationWorkSubTypeID,
      }
    );
  },
}
export const workTypeService = {
  getByID(
    id: string,
  ): Promise<WorkType> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkTypeService.GetByID", {
        id,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkTypeWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkTypeService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkTypeWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkTypeService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    clientData: WorkType,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkTypeService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: WorkType,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkTypeService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  updateWorkTypeOrders(
    types: Array<WorkType>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkTypeService.UpdateWorkTypeOrders", {
        types,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.WorkTypeService.DeleteItem", {
        id,
      }
    );
  },
}
export const yardService = {
  getByName(
    name: string,
  ): Promise<Yard> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.GetByName", {
        name,
      }
    );
  },
  addItem(
    clientData: Yard,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.AddItem", {
        clientData,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: Yard,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.DeleteItem", {
        id,
      }
    );
  },
  updateYardOrders(
    yards: Array<Yard>,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.UpdateYardOrders", {
        yards,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Yard>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Yard>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Yard> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.GetByID", {
        id,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.YardService.UnarchiveItem", {
        itemID,
      }
    );
  },
}
export const diagnosticService = {
  generateServerErrorCodes(
  ): Promise<{ [key: string]: string }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DiagnosticService.GenerateServerErrorCodes", {
      }
    );
  },
  getStatus(
  ): Promise<AppStatus> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DiagnosticService.GetStatus", {
      }
    );
  },
  getCurrentVersion(
  ): Promise<Version> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DiagnosticService.GetCurrentVersion", {
      }
    );
  },
}
export const baseEnvironmentService = {
  getSmsAccessCodeFromPhoneNumber(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetSmsAccessCodeFromPhoneNumber", {
      }
    );
  },
  getApplicationNameKey(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetApplicationNameKey", {
      }
    );
  },
  getApplicationNameConfiguration(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetApplicationNameConfiguration", {
      }
    );
  },
  getApplicationName(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetApplicationName", {
      }
    );
  },
  getEnvironmentSelfSignUp(
  ): Promise<boolean | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetEnvironmentSelfSignUp", {
      }
    );
  },
  getEnvironmentEnterpriseSignUp(
  ): Promise<boolean | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetEnvironmentEnterpriseSignUp", {
      }
    );
  },
  getCurrentEnvironmentID(
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetCurrentEnvironmentID", {
      }
    );
  },
  getCurrent(
  ): Promise<EnvironmentWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetCurrent", {
      }
    );
  },
  downloadOrgLogo(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.DownloadOrgLogo", {
      }
    );
  },
  uploadOrgLogo(
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.UploadOrgLogo", requestBody, {
      }
    );
  },
  deleteOrgLogo(
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.DeleteOrgLogo", {
      }
    );
  },
  getAll(
  ): Promise<Array<Environment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Environment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Environment,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: Environment,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.DeleteItem", {
        id,
      }
    );
  },
}
export const languageService = {
  getLanguageCodeForLanguageID(
    id: string,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.GetLanguageCodeForLanguageID", {
        id,
      }
    );
  },
  getAll(
  ): Promise<Array<Language>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Language> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Language,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: Language,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.DeleteItem", {
        id,
      }
    );
  },
}
export const paymentService = {
  getPublishableKey(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.GetPublishableKey", {
      }
    );
  },
  startPayment(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.StartPayment", {
      }
    );
  },
  createPaymentMethod(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.CreatePaymentMethod", {
      }
    );
  },
  deletePaymentMethod(
    paymentMethodID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.DeletePaymentMethod", {
        paymentMethodID,
      }
    );
  },
  getPaymentMethods(
  ): Promise<Array<PaymentMethodInfo>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.GetPaymentMethods", {
      }
    );
  },
  getAvailableSubscriptions(
  ): Promise<Array<SubscriptionInfo>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.GetAvailableSubscriptions", {
      }
    );
  },
  createSubscription(
    paymentMethodID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.CreateSubscription", {
        paymentMethodID,
      }
    );
  },
}
export const personalEmailAddressService = {
  getAll(
  ): Promise<Array<PersonalEmailAddress>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonalEmailAddress> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: PersonalEmailAddress,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: PersonalEmailAddress,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.DeleteItem", {
        id,
      }
    );
  },
  getByPersonID(
    PersonId: string,
  ): Promise<PersonalEmailAddress> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.GetByPersonID", {
        PersonId,
      }
    );
  },
}
export const personalPhoneNumberService = {
  getAll(
  ): Promise<Array<PersonalPhoneNumber>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonalPhoneNumber> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: PersonalPhoneNumber,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: PersonalPhoneNumber,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.DeleteItem", {
        id,
      }
    );
  },
  getByPersonID(
    PersonId: string,
  ): Promise<PersonalPhoneNumber> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.GetByPersonID", {
        PersonId,
      }
    );
  },
}
export const tagService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Tag>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByNames(
    tagNames: Array<string>,
  ): Promise<Array<Tag>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetByNames", {
        tagNames,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Tag>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Tag> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: Tag,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: Tag,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.DeleteItem", {
        id,
      }
    );
  },
}
export const versionService = {
  getVersion(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.VersionService.GetVersion", {
      }
    );
  },
}
export const countSheetListService = {
  getApprovedCountSheetsForGrid(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<GridCountSheet>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ViewSpecific.CountSheetListService.GetApprovedCountSheetsForGrid", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getApprovedCountSheetTransfersForGrid(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<GridCountSheetTransfer>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ViewSpecific.CountSheetListService.GetApprovedCountSheetTransfersForGrid", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getApprovedCountSheetWorkOrdersForGrid(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderWithAllDetails2>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ViewSpecific.CountSheetListService.GetApprovedCountSheetWorkOrdersForGrid", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getApprovedCountSheetWorkPackagesForGrid(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderPackageListItem>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ViewSpecific.CountSheetListService.GetApprovedCountSheetWorkPackagesForGrid", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
}
export const workOrderSchedulerService = {
  getWorkOrdersForSchedulerGrid(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderForSchedulerGrid>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ViewSpecific.WorkOrderSchedulerService.GetWorkOrdersForSchedulerGrid", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getWorkOrderDetailsForSchedulerGrid(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderExtraDetailsForSchedulerGrid>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ViewSpecific.WorkOrderSchedulerService.GetWorkOrderDetailsForSchedulerGrid", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getWalkdownDetailsForSchedulerGrid(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WalkdownExtraDetailsForSchedulerGrid>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ViewSpecific.WorkOrderSchedulerService.GetWalkdownDetailsForSchedulerGrid", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getWorkPackagesForSchedulerGrid(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<WorkOrderPackageForSchedulerGrid>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ViewSpecific.WorkOrderSchedulerService.GetWorkPackagesForSchedulerGrid", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
}
export const contractorService2 = {
  getReferenceList(
  ): Promise<Array<Contractor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ContractorService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<Contractor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ContractorService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const disciplineService2 = {
  getReferenceList(
  ): Promise<Array<Discipline>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.DisciplineService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<Discipline>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.DisciplineService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const personBySecurityIDService2 = {
  getReferenceList(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.PersonBySecurityIDService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.PersonBySecurityIDService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const personService2 = {
  getReferenceList(
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.PersonService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<Person>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.PersonService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const projectLocationService2 = {
  getReferenceList(
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectLocationService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectLocationService2.GetReferencesByID", {
        ids,
      }
    );
  },
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<ProjectLocation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectLocationService2.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    data: ProjectLocation,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectLocationService2.AddItem", {
        data,
      }
    );
  },
  updateItem(
    data: ProjectLocation,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectLocationService2.UpdateItem", {
        data,
      }
    );
  },
  archiveItem(
    id: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectLocationService2.ArchiveItem", {
        id,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectLocationService2.DeleteItem", {
        id,
      }
    );
  },
}
export const projectService2 = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Project>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectService2.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  addItem(
    data: Project,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectService2.AddItem", {
        data,
      }
    );
  },
  updateItem(
    data: Project,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectService2.UpdateItem", {
        data,
      }
    );
  },
  archiveItem(
    id: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectService2.ArchiveItem", {
        id,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ProjectService2.DeleteItem", {
        id,
      }
    );
  },
}
export const scaffoldBayLengthService2 = {
  getReferenceList(
  ): Promise<Array<ScaffoldBayLength>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldBayLengthService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ScaffoldBayLength>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldBayLengthService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const scaffoldBayWidthService2 = {
  getReferenceList(
  ): Promise<Array<ScaffoldBayWidth>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldBayWidthService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ScaffoldBayWidth>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldBayWidthService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const scaffoldBayHeightService2 = {
  getReferenceList(
  ): Promise<Array<ScaffoldBayHeight>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldBayHeightService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ScaffoldBayHeight>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldBayHeightService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const hoardingModifierService2 = {
  getReferenceList(
  ): Promise<Array<HoardingModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.HoardingModifierService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<HoardingModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.HoardingModifierService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const internalModifierService2 = {
  getReferenceList(
  ): Promise<Array<InternalModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.InternalModifierService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<InternalModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.InternalModifierService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const scaffoldCongestionFactorService2 = {
  getReferenceList(
  ): Promise<Array<ScaffoldCongestionFactor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldCongestionFactorService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ScaffoldCongestionFactor>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldCongestionFactorService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const scaffoldElevationModifierService2 = {
  getReferenceList(
  ): Promise<Array<ScaffoldElevationModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldElevationModifierService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ScaffoldElevationModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldElevationModifierService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const scaffoldHeightModifierService2 = {
  getReferenceList(
  ): Promise<Array<ScaffoldHeightModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldHeightModifierService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ScaffoldHeightModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldHeightModifierService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const scaffoldTypeModifierService2 = {
  getReferenceList(
  ): Promise<Array<ScaffoldTypeModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldTypeModifierService2.GetReferenceList", {
      }
    );
  },
  getReferencesByID(
    ids: Array<string>,
  ): Promise<Array<ScaffoldTypeModifier>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ReferenceData.ScaffoldTypeModifierService2.GetReferencesByID", {
        ids,
      }
    );
  },
}
export const reportService = {
  getWorkOrderAdminPrintoutReportContentWithData(
    workOrders: Array<WorkOrderWithAllDetails>,
    reportType: string,
    contractorFilterValue: string,
    disciplineFilterValue: string,
    areaFilterValue: string,
    subAreaFilterValue: string,
    generalForemanFilterValue: string,
    foremanFilterValue: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetWorkOrderAdminPrintoutReportContentWithData", {
        workOrders,
        reportType,
        contractorFilterValue,
        disciplineFilterValue,
        areaFilterValue,
        subAreaFilterValue,
        generalForemanFilterValue,
        foremanFilterValue,
        clientPrintDateTimeString,
      }
    );
  },
  getRequestApprovalPrintoutReportContentWithData(
    scaffold: Array<ScaffoldRequestWithDetails>,
    reportType: string,
    contractorFilterValue: string,
    disciplineFilterValue: string,
    areaFilterValue: string,
    subAreaFilterValue: string,
    approvalStatus: string,
    craftType: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetRequestApprovalPrintoutReportContentWithData", {
        scaffold,
        reportType,
        contractorFilterValue,
        disciplineFilterValue,
        areaFilterValue,
        subAreaFilterValue,
        approvalStatus,
        craftType,
        clientPrintDateTimeString,
      }
    );
  },
  getYardBalancesPrintoutReportContentWithData(
    parts: Array<PartWithYardBalance>,
    yards: Array<Yard>,
    reportType: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetYardBalancesPrintoutReportContentWithData", {
        parts,
        yards,
        reportType,
        clientPrintDateTimeString,
      }
    );
  },
  getPlannerPrintoutReportContentWithData(
    workOrders: Array<WorkOrderToPrint>,
    reportType: string,
    contractorFilterValue: string,
    disciplineFilterValue: string,
    areaFilterValue: string,
    subAreaFilterValue: string,
    generalForemanFilterValue: string,
    foremanFilterValue: string,
    statusFilterValue: string,
    assignedSelected: string,
    unassignedSelected: string,
    onlyMineSelected: string,
    showOnlyToDos: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetPlannerPrintoutReportContentWithData", {
        workOrders,
        reportType,
        contractorFilterValue,
        disciplineFilterValue,
        areaFilterValue,
        subAreaFilterValue,
        generalForemanFilterValue,
        foremanFilterValue,
        statusFilterValue,
        assignedSelected,
        unassignedSelected,
        onlyMineSelected,
        showOnlyToDos,
        clientPrintDateTimeString,
      }
    );
  },
  getAllOrdersPrintoutReportContentWithData(
    workOrders: Array<WorkOrderForAllWorkOrdersList>,
    reportType: string,
    clientPrintDateTimeString: string,
    contractorFilterValue: string,
    disciplineFilterValue: string,
    areaFilterValue: string,
    subAreaFilterValue: string,
    generalForemanFilterValue: string,
    foremanFilterValue: string,
    statusFilterValue: string,
    craftFilterValue: string,
    onlyMine: boolean,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetAllOrdersPrintoutReportContentWithData", {
        workOrders,
        reportType,
        clientPrintDateTimeString,
        contractorFilterValue,
        disciplineFilterValue,
        areaFilterValue,
        subAreaFilterValue,
        generalForemanFilterValue,
        foremanFilterValue,
        statusFilterValue,
        craftFilterValue,
        onlyMine,
      }
    );
  },
  getSchedulePrintoutReportContentWithData(
    workOrder: WorkOrderWithAllDetails,
    walkDown: WalkdownWithRequestDetails,
    reportType: string,
    date: Date | null,
    iwp: string,
    localizedStatusChange: string,
    needsExtraInfo: boolean,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetSchedulePrintoutReportContentWithData", {
        workOrder,
        walkDown,
        reportType,
        date,
        iwp,
        localizedStatusChange,
        needsExtraInfo,
      }
    );
  },
  getTimesheetSummaryPrintoutReportContentWithData(
    timesheetRows: Array<SummaryTimesheetRow>,
    workSubTypes: Array<WorkSubType>,
    reportType: string,
    clientPrintDateTimeString: string,
    showOverviewOnly: boolean,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetTimesheetSummaryPrintoutReportContentWithData", {
        timesheetRows,
        workSubTypes,
        reportType,
        clientPrintDateTimeString,
        showOverviewOnly,
      }
    );
  },
  getLaborTimesheetPrintoutReportContentWithData(
    timesheetRows: Array<LaborTimesheetRow>,
    workSubTypes: Array<WorkSubType>,
    reportType: string,
    clientPrintDateTimeString: string,
    showOverviewOnly: boolean,
    isEquipment: boolean,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetLaborTimesheetPrintoutReportContentWithData", {
        timesheetRows,
        workSubTypes,
        reportType,
        clientPrintDateTimeString,
        showOverviewOnly,
        isEquipment,
      }
    );
  },
  getScaffoldLogPrintoutReportContentWithData(
    timesheetRows: Array<ScaffoldLogRow>,
    reportType: string,
    contractorFilterValue: string,
    areaFilterValue: string,
    subAreaFilterValue: string,
    isStandingScaffoldDisplayed: boolean | null,
    isDismantledScaffoldDisplayed: boolean | null,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetScaffoldLogPrintoutReportContentWithData", {
        timesheetRows,
        reportType,
        contractorFilterValue,
        areaFilterValue,
        subAreaFilterValue,
        isStandingScaffoldDisplayed,
        isDismantledScaffoldDisplayed,
        clientPrintDateTimeString,
      }
    );
  },
  getPeoplePrintoutReportContentWithData(
    peopleRow: Array<PeopleRow>,
    reportType: string,
    contractorFilterValue: string,
    status: string,
    hasInactiveUsers: boolean | null,
    singleContractor: boolean,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetPeoplePrintoutReportContentWithData", {
        peopleRow,
        reportType,
        contractorFilterValue,
        status,
        hasInactiveUsers,
        singleContractor,
        clientPrintDateTimeString,
      }
    );
  },
  getMaterialTransferPrintoutReportContentWithData(
    transferRows: Array<MaterialTransferRow>,
    reportType: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetMaterialTransferPrintoutReportContentWithData", {
        transferRows,
        reportType,
        clientPrintDateTimeString,
      }
    );
  },
  getTimesheetWeekendingPrintoutReportContentWithData(
    employeeWeekTimeSummaries: Array<EmployeeWeekTimeSummary>,
    daysOfWeek: Array<DayInWeek>,
    totalTimeSummary: EmployeeWeekTimeSummary,
    reportType: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetTimesheetWeekendingPrintoutReportContentWithData", {
        employeeWeekTimeSummaries,
        daysOfWeek,
        totalTimeSummary,
        reportType,
        clientPrintDateTimeString,
      }
    );
  },
  getStandingScaffoldInspectionStatusPrintoutWithData(
    scaffolds: Array<ScaffoldWithInspectionDetails>,
    reportType: string,
    clientPrintDateTimeString: string,
    inspectionDay: string,
    timeSegmentName: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetStandingScaffoldInspectionStatusPrintoutWithData", {
        scaffolds,
        reportType,
        clientPrintDateTimeString,
        inspectionDay,
        timeSegmentName,
      }
    );
  },
  getScaffoldInspectionHistoryPrintoutWithData(
    inspections: Array<ScaffoldInspectionWithHistoryData>,
    reportType: string,
    clientPrintDateTimeString: string,
    tagNumber: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetScaffoldInspectionHistoryPrintoutWithData", {
        inspections,
        reportType,
        clientPrintDateTimeString,
        tagNumber,
      }
    );
  },
  getMaterialRequestPrintoutWithData(
    order: MaterialOrderForReport,
    reportType: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetMaterialRequestPrintoutWithData", {
        order,
        reportType,
        clientPrintDateTimeString,
      }
    );
  },
  getMaterialReturnPrintoutWithData(
    order: MaterialOrderForReport,
    reportType: string,
    clientPrintDateTimeString: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.GetMaterialReturnPrintoutWithData", {
        order,
        reportType,
        clientPrintDateTimeString,
      }
    );
  },
}
export interface AccessCodeChallenge {
  securityContext: Uint8Array
  loginContext: Uint8Array
  contactMethodID: string | null
  codeContext: Uint8Array
  expires: Date
  attempts: number
  verifyEmailAddressID: Uint8Array
  sentToEmailAddress: string
  sentToEmailAddresses: Array<Uint8Array>
  verifyPhoneNumberID: Uint8Array
  sentToPhoneNumberTail: string
  sentToPhoneNumbers: Array<Uint8Array>
  loginInformation: LoginInformation
}
export interface LoginChallenge {
  firstName: string
  loginContext: Uint8Array
  publicSalt: Uint8Array
  contactMethodID: string | null
  canReceiveAccessCodes: boolean
  needsActivation: boolean
  verifiedContactMethods: Array<ContactMethod>
}
export interface BlobFile {
  id: string | undefined
  personID: string | null | undefined
  scaffoldRequestID: string | null | undefined
  scaffoldDesignID: string | null | undefined
  workOrderID: string | null | undefined
  jobID: string | null | undefined
  isoID: string | null | undefined
  name: string | undefined
  address: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface BuildDismantleRatio {
  id: string | undefined
  ratio: string | undefined
  dismantleMultiplier: number | null | undefined
  erectMultiplier: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface BuildSheet {
  id: string | undefined
  workOrderID: string | undefined
  ownerID: string | undefined
  isNotRequiredOnDay: boolean | undefined
  notes: string | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  deckLevels: number | null | undefined
  hazardLevel: string | undefined
  confinedSpace: boolean | undefined
  ppeRequired: boolean | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  currentScaffoldVlf: number | null | undefined
  vlfChangeAmount: number | null | undefined
  workCategoryID: string | null | undefined
  paintCodeID: string | null | undefined
  setUpTypeID: string | null | undefined
  applicationTypeID: string | null | undefined
  tsaID: string | null | undefined
  equipmentNumber: string | undefined
  diameter: string | undefined
  squareFeet: string | undefined
  linearFeet: string | undefined
  paintReason: string | undefined
  coatNumber: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ApplicationType {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface PaintCode {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface SetUpType {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Tsa {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkCategory {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Classification {
  id: string | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  regularRate: number | null | undefined
  overtimeRate: number | null | undefined
  doubleTimeRate: number | null | undefined
  enableDailyOvertimeThreshold: boolean | undefined
  employeeDailyOvertimeHoursThreshold: number | null | undefined
  enableDailyDoubletimeThreshold: boolean | undefined
  employeeDailyDoubletimeHoursThreshold: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ClientConfiguration {
  tileServiceUrl: string
}
export interface ContractorNormGroup {
  id: string | undefined
  contractorID: string | undefined
  displayOrder: number | undefined
  name: string | undefined
  description: string | undefined
  isScaffoldGroup: boolean | undefined
  isMaintenanceGroup: boolean | undefined
  isPaintGroup: boolean | undefined
  isInsulationGroup: boolean | undefined
  isHeatTraceGroup: boolean | undefined
  isRefractoryGroup: boolean | undefined
  isFireproofingGroup: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ContractorNorm {
  id: string | undefined
  contractorNormGroupID: string | null | undefined
  contractorID: string | undefined
  displayOrder: number | undefined
  description: string | undefined
  isActive: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ContractorPurchaseOrder {
  id: string | undefined
  contractorID: string | undefined
  displayOrder: number | undefined
  purchaseOrderNumber: string | undefined
  description: string | undefined
  isActive: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface BasicContractor {
  id: string | undefined
  name: string | undefined
  alias: string | undefined
}
export interface ContractorWithTags {
  tagIDs: Array<string> | undefined
  disciplineIDs: Array<string> | undefined
  areaIDs: Array<string> | undefined
  costCodeIDs: Array<string> | undefined
  workTypeIDs: Array<string> | undefined
  classifications: Array<ContractorClassificationWithName> | undefined
  equipmentClassifications: Array<ContractorEquipmentClassificationWithName> | undefined
  workSubTypes: Array<ContractorWorkSubTypeWithDetails> | undefined
  id: string | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  tagIDJson: string | undefined
  isScaffoldCompany: boolean | undefined
  isPaintCompany: boolean | undefined
  isMaintenanceCompany: boolean | undefined
  isInsulationCompany: boolean | undefined
  isHeatTraceCompany: boolean | undefined
  isRefractoryCompany: boolean | undefined
  isFireproofingCompany: boolean | undefined
  canActAsProxy: boolean | undefined
  tracksEmployeeTime: boolean | undefined
  allowDirectTimesheets: boolean | undefined
  allowIndirectTimesheets: boolean | undefined
  allowEquipmentTimesheets: boolean | undefined
  employeesReceivePerDiem: boolean | undefined
  automaticallyApplyWeekendPerDiem: boolean | undefined
  perDiemHoursRequirement: number | null | undefined
  includesAllDisciplines: boolean | undefined
  disciplineIDJson: string | undefined
  includesAllAreas: boolean | undefined
  areaIDJson: string | undefined
  includesAllCostCodes: boolean | undefined
  costCodeIDJson: string | undefined
  includesAllWorkTypes: boolean | undefined
  workTypeIDJson: string | undefined
  employeeOvertimeHoursThreshold: number | null | undefined
  employeeDoubletimeHoursThreshold: number | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PublicContractorDetails {
  id: string | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
}
export interface PublicPersonDetails {
  id: string | undefined
  personalIdentifier: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  emailAddresses: Array<string> | undefined
  phoneNumbers: Array<string> | undefined
}
export interface CountSheetGroupWithParts {
  parts: Array<Part> | undefined
  id: string | undefined
  name: string | undefined
  description: string | undefined
  isScaffoldGroup: boolean | undefined
  isMaintenanceGroup: boolean | undefined
  isPaintGroup: boolean | undefined
  isInsulationGroup: boolean | undefined
  isHeatTraceGroup: boolean | undefined
  isRefractoryGroup: boolean | undefined
  isFireproofingGroup: boolean | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Part {
  id: string | undefined
  countSheetGroupID: string | null | undefined
  countSheetGroupDisplayOrder: number | null | undefined
  publicID: string | undefined
  name: string | undefined
  description: string | undefined
  longDescription: string | undefined
  weight: number | null | undefined
  mpp: number | null | undefined
  cleatingMPP: number | null | undefined
  lashingMPP: number | null | undefined
  carpentryMPP: number | null | undefined
  otherMPP: number | null | undefined
  rentalRate: number | null | undefined
  costUsed: number | null | undefined
  costNew: number | null | undefined
  designation: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface CountSheetWithParts {
  parts: Array<CountSheetPartWithDetails> | undefined
  workOrder: WorkOrderWithAllDetails
  submittedByName: string
  submittedByContractorName: string
  reviewApprovedByName: string
  reviewStatusName: string
  countSheetTypeName: string
  relatedTransferNumbers: Array<string>
  currentUserPermissions: SummarizedCountSheetPermissions
  id: string | undefined
  workOrderID: string | null | undefined
  countSheetTypeID: CountSheetType | undefined
  reviewStatusID: CountSheetReviewStatus | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  approvedFromYardID: string | null | undefined
  approvedToYardID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface CountSheetWithPartsForScaffold {
  parts: Array<CountSheetPartWithDetails> | undefined
  workOrder: WorkOrder
  relatedTransferNumbers: Array<string>
  currentUserPermissions: SummarizedCountSheetPermissions
  id: string | undefined
  workOrderID: string | null | undefined
  countSheetTypeID: CountSheetType | undefined
  reviewStatusID: CountSheetReviewStatus | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  approvedFromYardID: string | null | undefined
  approvedToYardID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface CrewWithEmployees {
  employees: Array<CrewEmployeeWithName> | undefined
  ownerName: string
  id: string | undefined
  contractorID: string | undefined
  ownerID: string | null | undefined
  name: string | undefined
  description: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface BasicDiscipline {
  id: string | undefined
  name: string | undefined
}
export interface Discipline {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  craftCode: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface DropoffLocation {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface InspectionTime {
  id: string | undefined
  name: string | undefined
  style: string | undefined
  environmentID: string | null | undefined
  time: Date | undefined
}
export interface EnvironmentWithDetails {
  enableScaffoldInspection: boolean | null
  enableScaffoldDesign: boolean | null
  enableTimesheets: boolean | null
  enableLabourEntry: boolean | null
  enableMaterialManagement: boolean | null
  theme: string
  loginTimeoutMinutes: number | null
  allowExperimentalFeatures: boolean | null
  allowSelfSignUp: boolean | null
  allowEnterpriseSignUp: boolean | null
  smsAccessCodeFromPhoneNumber: string
  identifier: string | undefined
  shippingAddressLine1: string | undefined
  shippingAddressLine2: string | undefined
  shippingAddressLine3: string | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
  enableMaintenanceWork: boolean | undefined
  enablePaintWork: boolean | undefined
  enableInsulationWork: boolean | undefined
  enableHeatTraceWork: boolean | undefined
  enableRefractoryWork: boolean | undefined
  enableFireproofingWork: boolean | undefined
  enableNorms: boolean | undefined
  enablePurchaseOrders: boolean | undefined
  enableScaffoldLocation: boolean | undefined
  erectWorkOrdersRequireScaffoldLocation: boolean | undefined
  trackScaffoldVLF: boolean | undefined
  walkdownVLFRequired: boolean | undefined
  workOrderActualVLFRequired: boolean | undefined
  scaffoldRequestDismantleDateRequired: boolean | undefined
  siteProductivity: number | null | undefined
  blendedLabourRate: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  usesSecondaryTypeDeckPlanks: boolean | undefined
  defaultErectScaffoldRequestRequiresWalkDown: boolean | undefined
  defaultModifyScaffoldRequestRequiresWalkDown: boolean | undefined
  defaultDismantleScaffoldRequestRequiresWalkDown: boolean | undefined
  snapshotApprovedWalkdowns: boolean | undefined
  defaultEquipmentCostCodeID: string | null | undefined
  defaultErectRequestCostCodeID: string | null | undefined
  defaultModifyRequestCostCodeID: string | null | undefined
  defaultDismantleRequestCostCodeID: string | null | undefined
  automaticallyApproveScaffoldRequests: boolean | undefined
  defaultWorkOrderAssignedContractorID: string | null | undefined
  defaultWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApprovePaintRequests: boolean | undefined
  defaultPaintWorkOrderAssignedContractorID: string | null | undefined
  defaultPaintWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultPaintWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultPaintWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveInsulationRequests: boolean | undefined
  defaultInsulationWorkOrderAssignedContractorID: string | null | undefined
  defaultInsulationWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultInsulationWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultInsulationWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveHeatTraceRequests: boolean | undefined
  defaultHeatTraceWorkOrderAssignedContractorID: string | null | undefined
  defaultHeatTraceWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultHeatTraceWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultHeatTraceWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveRefractoryRequests: boolean | undefined
  defaultRefractoryWorkOrderAssignedContractorID: string | null | undefined
  defaultRefractoryWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultRefractoryWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultRefractoryWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveFireproofingRequests: boolean | undefined
  defaultFireproofingWorkOrderAssignedContractorID: string | null | undefined
  defaultFireproofingWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultFireproofingWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultFireproofingWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveMaintenanceRequests: boolean | undefined
  defaultMaintenanceWorkOrderAssignedContractorID: string | null | undefined
  defaultMaintenanceWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultMaintenanceWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultMaintenanceWorkOrderAssignedForemanID: string | null | undefined
  defaultScaffoldTypeModifierID: string | null | undefined
  defaultScaffoldDistanceModifierID: string | null | undefined
  defaultScaffoldElevationModifierID: string | null | undefined
  defaultScaffoldHeightModifierID: string | null | undefined
  defaultBuildDismantleRatioID: string | null | undefined
  defaultCrewSize: number | null | undefined
  defaultFactor1: number | null | undefined
  defaultFactor2: number | null | undefined
  automaticallyApproveCountSheets: boolean | undefined
  defaultCountSheetFromYardID: string | null | undefined
  defaultCountSheetToYardID: string | null | undefined
  defaultEmployeeOvertimeHoursThreshold: number | null | undefined
  defaultEmployeeDoubletimeHoursThreshold: number | null | undefined
  defaultDailyEmployeeOvertimeHoursThreshold: number | null | undefined
  defaultDailyEmployeeDoubletimeHoursThreshold: number | null | undefined
  weekEndingDay: number | undefined
  defaultMaxDailyEmployeeHours: number | undefined
  dailyWorkHours: number | undefined
  mondayIsWorkDay: boolean | undefined
  tuesdayIsWorkDay: boolean | undefined
  wednesdayIsWorkDay: boolean | undefined
  thursdayIsWorkDay: boolean | undefined
  fridayIsWorkDay: boolean | undefined
  saturdayIsWorkDay: boolean | undefined
  sundayIsWorkDay: boolean | undefined
  scaffoldInspectionCanBeCreatedHistorically: boolean | undefined
  scaffoldInspectionRequiresPhoto: boolean | undefined
  scaffoldInspectionRequiresAllQuestions: boolean | undefined
  id: string | undefined
  name: string | undefined
  description: string | undefined
  enableNotifications: boolean | undefined
  loginRequiresPasswordAndCode: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Environment {
  identifier: string | undefined
  shippingAddressLine1: string | undefined
  shippingAddressLine2: string | undefined
  shippingAddressLine3: string | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
  enableMaintenanceWork: boolean | undefined
  enablePaintWork: boolean | undefined
  enableInsulationWork: boolean | undefined
  enableHeatTraceWork: boolean | undefined
  enableRefractoryWork: boolean | undefined
  enableFireproofingWork: boolean | undefined
  enableNorms: boolean | undefined
  enablePurchaseOrders: boolean | undefined
  enableScaffoldLocation: boolean | undefined
  erectWorkOrdersRequireScaffoldLocation: boolean | undefined
  trackScaffoldVLF: boolean | undefined
  walkdownVLFRequired: boolean | undefined
  workOrderActualVLFRequired: boolean | undefined
  scaffoldRequestDismantleDateRequired: boolean | undefined
  siteProductivity: number | null | undefined
  blendedLabourRate: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  usesSecondaryTypeDeckPlanks: boolean | undefined
  defaultErectScaffoldRequestRequiresWalkDown: boolean | undefined
  defaultModifyScaffoldRequestRequiresWalkDown: boolean | undefined
  defaultDismantleScaffoldRequestRequiresWalkDown: boolean | undefined
  snapshotApprovedWalkdowns: boolean | undefined
  defaultEquipmentCostCodeID: string | null | undefined
  defaultErectRequestCostCodeID: string | null | undefined
  defaultModifyRequestCostCodeID: string | null | undefined
  defaultDismantleRequestCostCodeID: string | null | undefined
  automaticallyApproveScaffoldRequests: boolean | undefined
  defaultWorkOrderAssignedContractorID: string | null | undefined
  defaultWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApprovePaintRequests: boolean | undefined
  defaultPaintWorkOrderAssignedContractorID: string | null | undefined
  defaultPaintWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultPaintWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultPaintWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveInsulationRequests: boolean | undefined
  defaultInsulationWorkOrderAssignedContractorID: string | null | undefined
  defaultInsulationWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultInsulationWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultInsulationWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveHeatTraceRequests: boolean | undefined
  defaultHeatTraceWorkOrderAssignedContractorID: string | null | undefined
  defaultHeatTraceWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultHeatTraceWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultHeatTraceWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveRefractoryRequests: boolean | undefined
  defaultRefractoryWorkOrderAssignedContractorID: string | null | undefined
  defaultRefractoryWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultRefractoryWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultRefractoryWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveFireproofingRequests: boolean | undefined
  defaultFireproofingWorkOrderAssignedContractorID: string | null | undefined
  defaultFireproofingWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultFireproofingWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultFireproofingWorkOrderAssignedForemanID: string | null | undefined
  automaticallyApproveMaintenanceRequests: boolean | undefined
  defaultMaintenanceWorkOrderAssignedContractorID: string | null | undefined
  defaultMaintenanceWorkOrderAssignedCoordinatorID: string | null | undefined
  defaultMaintenanceWorkOrderAssignedGeneralForemanID: string | null | undefined
  defaultMaintenanceWorkOrderAssignedForemanID: string | null | undefined
  defaultScaffoldTypeModifierID: string | null | undefined
  defaultScaffoldDistanceModifierID: string | null | undefined
  defaultScaffoldElevationModifierID: string | null | undefined
  defaultScaffoldHeightModifierID: string | null | undefined
  defaultBuildDismantleRatioID: string | null | undefined
  defaultCrewSize: number | null | undefined
  defaultFactor1: number | null | undefined
  defaultFactor2: number | null | undefined
  automaticallyApproveCountSheets: boolean | undefined
  defaultCountSheetFromYardID: string | null | undefined
  defaultCountSheetToYardID: string | null | undefined
  defaultEmployeeOvertimeHoursThreshold: number | null | undefined
  defaultEmployeeDoubletimeHoursThreshold: number | null | undefined
  defaultDailyEmployeeOvertimeHoursThreshold: number | null | undefined
  defaultDailyEmployeeDoubletimeHoursThreshold: number | null | undefined
  weekEndingDay: number | undefined
  defaultMaxDailyEmployeeHours: number | undefined
  dailyWorkHours: number | undefined
  mondayIsWorkDay: boolean | undefined
  tuesdayIsWorkDay: boolean | undefined
  wednesdayIsWorkDay: boolean | undefined
  thursdayIsWorkDay: boolean | undefined
  fridayIsWorkDay: boolean | undefined
  saturdayIsWorkDay: boolean | undefined
  sundayIsWorkDay: boolean | undefined
  scaffoldInspectionCanBeCreatedHistorically: boolean | undefined
  scaffoldInspectionRequiresPhoto: boolean | undefined
  scaffoldInspectionRequiresAllQuestions: boolean | undefined
  id: string | undefined
  name: string | undefined
  description: string | undefined
  enableNotifications: boolean | undefined
  loginRequiresPasswordAndCode: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface EquipmentClassification {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  defaultCostCodeID: string | null | undefined
  dailyRate: number | null | undefined
  weeklyRate: number | null | undefined
  monthlyRate: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EquipmentWithDetails {
  classificationLogs: Array<EquipmentClassificationLogWithDetails> | undefined
  tagIDs: Array<string> | undefined
  id: string | undefined
  contractorID: string | null | undefined
  equipmentClassificationID: string | null | undefined
  name: string | undefined
  description: string | undefined
  serialNumber: string | undefined
  manufacturer: string | undefined
  modelNumber: string | undefined
  modelYear: string | undefined
  unitColor: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EquipmentForContractor {
  costCodeID: string | null | undefined
  id: string | undefined
  contractorID: string | null | undefined
  equipmentClassificationID: string | null | undefined
  name: string | undefined
  description: string | undefined
  serialNumber: string | undefined
  manufacturer: string | undefined
  modelNumber: string | undefined
  modelYear: string | undefined
  unitColor: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EstimateSettings {
  id: string | undefined
  environmentID: string | undefined
  collarPartID: string | null | undefined
  mudsillPartID: string | null | undefined
  screwJackPartID: string | null | undefined
  ladderGatePartID: string | null | undefined
  clampPartID: string | null | undefined
  crazyLegPartID: string | null | undefined
  davitArmPartID: string | null | undefined
  standard3_0mPartID: string | null | undefined
  standard2_5mPartID: string | null | undefined
  standard2_0mPartID: string | null | undefined
  standard1_5mPartID: string | null | undefined
  standard1_0mPartID: string | null | undefined
  standard0_5mPartID: string | null | undefined
  starterBracketPartID: string | null | undefined
  ladder10ftPartID: string | null | undefined
  ladder06ftPartID: string | null | undefined
  ladder05ftPartID: string | null | undefined
  ladder03ftPartID: string | null | undefined
  yoyo100ftPartID: string | null | undefined
  yoyo050ftPartID: string | null | undefined
  yoyo030ftPartID: string | null | undefined
  yoyo020ftPartID: string | null | undefined
  steelTube13ftPartID: string | null | undefined
  steelTube10ftPartID: string | null | undefined
  steelTube08ftPartID: string | null | undefined
  steelTube06ftPartID: string | null | undefined
  steelTube04ftPartID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ExternalLink {
  id: string | undefined
  personID: string | null | undefined
  scaffoldRequestID: string | null | undefined
  scaffoldDesignID: string | null | undefined
  jobID: string | null | undefined
  isoID: string | null | undefined
  name: string | undefined
  address: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface FleetWithEquipment {
  equipment: Array<FleetEquipmentWithName> | undefined
  ownerName: string
  id: string | undefined
  contractorID: string | undefined
  ownerID: string | null | undefined
  name: string | undefined
  description: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface HoardingModifier {
  id: string | undefined
  name: string | undefined
  modifier: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface InternalModifier {
  id: string | undefined
  name: string | undefined
  modifier: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ISO {
  id: string | undefined
  testPackageID: string | null | undefined
  name: string | undefined
  description: string | undefined
  code: string | undefined
  estimatedWorkHours: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface IsoSearchResult {
  id: string | undefined
  name: string | undefined
  testPackageName: string | undefined
  systemName: string | undefined
}
export interface JobEstimateSnapshotWithDetails {
  hasGeneratedExcelFile: boolean
  creatorName: string
  components: Array<JobEstimateComponent> | undefined
  id: string | undefined
  jobID: string | null | undefined
  notes: string | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  isEstimateGenerationSuccessful: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EstimateTotalTimes {
  estimatedTotalErectMinutes: number | null
  estimatedTotalDismantleMinutes: number | null
  estimatedTotalModifyMinutes: number | null
  estimatedTotalMobilizationMinutes: number | null
  estimatedTotalDemobilizationMinutes: number | null
  estimatedTotalHoardingMinutes: number | null
  estimatedTotalTime: number | null
  estimatedErectMPP: number | null
  estimatedDismantleMPP: number | null
  estimatedTotalWeight: number | null
  estimatedTotalPartCount: number | null
}
export interface JobEstimateComponent {
  id: string | undefined
  jobEstimateSnapshotID: string | undefined
  generationTypeID: PartGenerationType | undefined
  scaffoldTypeID: ScaffoldType | undefined
  scaffoldSubTypeID: ScaffoldSubType | undefined
  isNew: boolean | undefined
  isModified: boolean | undefined
  isRemoved: boolean | undefined
  isExcluded: boolean | undefined
  notes: string | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  barricadeGates: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  modificationPercent: number | null | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface JobWorkingComponent {
  id: string | undefined
  jobID: string | undefined
  jobEstimateComponentID: string | null | undefined
  generationTypeID: PartGenerationType | undefined
  scaffoldTypeID: ScaffoldType | undefined
  scaffoldSubTypeID: ScaffoldSubType | undefined
  isNew: boolean | undefined
  isModified: boolean | undefined
  isRemoved: boolean | undefined
  isExcluded: boolean | undefined
  notes: string | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  barricadeGates: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  modificationPercent: number | null | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EstimateComponentData {
  generationTypeID: PartGenerationType | undefined
  scaffoldTypeID: ScaffoldType | undefined
  scaffoldSubTypeID: ScaffoldSubType | undefined
  isNew: boolean | undefined
  isModified: boolean | undefined
  isRemoved: boolean | undefined
  isExcluded: boolean | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  barricadeGates: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  modificationPercent: number | null | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
}
export interface TakeoffPart {
  id: string | undefined
  partID: string | undefined
  quantity: number | undefined
  totalWeight: number | null | undefined
  estimatedErectMinutes: number | null | undefined
  estimatedDismantleMinutes: number | null | undefined
  estimatedModifyMinutes: number | null | undefined
  estimatedMobilizationMinutes: number | null | undefined
  estimatedHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
}
export interface JobWithData {
  workPackageIDs: Array<string> | undefined | null
  tagIDs: Array<string> | undefined
  relatedWorkOrders: Array<WorkOrder>
  id: string | undefined
  internalNumber: number | undefined
  requestorID: string | undefined
  jobStatusID: JobStatuses | undefined
  jobStatusDetail: string | undefined
  jobTypeID: JobTypes | undefined
  requestType: ScaffoldRequestTypes | undefined
  requestSubType: ScaffoldRequestSubTypes | undefined
  scaffoldDesignID: string | null | undefined
  scaffoldID: string | null | undefined
  scaffoldNumber: number | null | undefined
  isoID: string | null | undefined
  contractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  isClientWorkOrder: boolean | undefined
  clientWorkOrderReferenceNumber: string | undefined
  clientWorkOrderReason: string | undefined
  isChangeOrder: boolean | undefined
  changeOrderReferenceNumber: string | undefined
  changeOrderReason: string | undefined
  isRework: boolean | undefined
  reworkReferenceNumber: string | undefined
  reworkReason: string | undefined
  isServiceOrder: boolean | undefined
  serviceOrderReferenceNumber: string | undefined
  serviceOrderReason: string | undefined
  purchaseOrderID: string | null | undefined
  existingTagNumber: string | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export enum JobTypes {
  Scaffold = 0,
  Maintenance = 1,
  Paint = 2,
  Insulation = 3,
  HeatTrace = 4,
  Refractory = 5,
  Fireproofing = 6,
}
export interface JobWorkingTakeoff {
  id: string | undefined
  jobID: string | undefined
  jobWorkingComponentID: string | null | undefined
  partID: string | undefined
  quantity: number | undefined
  totalWeight: number | null | undefined
  estimatedErectMinutes: number | null | undefined
  estimatedDismantleMinutes: number | null | undefined
  estimatedModifyMinutes: number | null | undefined
  estimatedMobilizationMinutes: number | null | undefined
  estimatedHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface IEstimateComponentData {
  isNew: boolean | undefined
  isModified: boolean | undefined
  isRemoved: boolean | undefined
  isExcluded: boolean | undefined
}
export interface TakeoffPartWithDetails {
  id: string | undefined
  partID: string | undefined
  quantity: number | undefined
  estimatedErectMinutes: number | null | undefined
  estimatedDismantleMinutes: number | null | undefined
  estimatedModifyMinutes: number | null | undefined
  estimatedMobilizationMinutes: number | null | undefined
  estimatedHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
  weight: number | null | undefined
  totalWeight: number | null | undefined
  mpp: number | null | undefined
  partName: string | undefined
  partDescription: string | undefined
  partPublicID: string | undefined
}
export interface LEMWithDetails {
  importedTimesheets: Array<TimesheetWithDetails>
  statusLogs: Array<LEMStatusLogWithDetails>
  lastStatusLog: LEMStatusLogWithDetails
  contractorName: string
  submittedByName: string
  reviewApprovedByName: string
  entryCount: number | null
  totalRegularTime: number | null
  totalOverTime: number | null
  totalDoubleTime: number | null
  totalUnits: number | null
  totalDays: number | null
  totalQuantity: number | null
  currentUserPermissions: SummarizedLEMPermissions
  id: string | undefined
  internalNumber: number | undefined
  environmentID: string | null | undefined
  contractorID: string | undefined
  day: Date | undefined
  lemStatusID: LemStatus | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EmployeeLEMEntry {
  id: string | undefined
  lemID: string | undefined
  sourceTimesheetID: string | null | undefined
  isCorrectionEntry: boolean | undefined
  workOrderID: string | null | undefined
  scaffoldID: string | null | undefined
  employeeID: string | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  workTypeID: string | null | undefined
  workSubTypeID: string | null | undefined
  classificationID: string | null | undefined
  costCodeID: string | null | undefined
  regularTime: number | null | undefined
  overTime: number | null | undefined
  doubleTime: number | null | undefined
  units: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EquipmentLEMEntry {
  id: string | undefined
  lemID: string | undefined
  sourceTimesheetID: string | null | undefined
  isCorrectionEntry: boolean | undefined
  workOrderID: string | null | undefined
  scaffoldID: string | null | undefined
  equipmentID: string | undefined
  equipmentClassificationID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  costCodeID: string | null | undefined
  regularTime: number | null | undefined
  days: number | null | undefined
  quantity: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EmployeeLEMEntryWithDetails {
  timesheetNumber: number | null
  timesheetTypeID: TimesheetType | null
  timesheetOwnerName: string
  employeeName: string
  employeeCode: string
  workOrderNumber: string
  scaffoldNumber: number | null
  areaName: string
  subAreaName: string
  workTypeName: string
  workSubTypeName: string
  classificationName: string
  costCodeName: string
  id: string | undefined
  lemID: string | undefined
  sourceTimesheetID: string | null | undefined
  isCorrectionEntry: boolean | undefined
  workOrderID: string | null | undefined
  scaffoldID: string | null | undefined
  employeeID: string | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  workTypeID: string | null | undefined
  workSubTypeID: string | null | undefined
  classificationID: string | null | undefined
  costCodeID: string | null | undefined
  regularTime: number | null | undefined
  overTime: number | null | undefined
  doubleTime: number | null | undefined
  units: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EquipmentLEMEntryWithDetails {
  timesheetNumber: number | null
  timesheetTypeID: TimesheetType | null
  timesheetOwnerName: string
  equipmentName: string
  equipmentSerialNumber: string
  workOrderNumber: string
  scaffoldNumber: number | null
  areaName: string
  subAreaName: string
  classificationName: string
  costCodeName: string
  id: string | undefined
  lemID: string | undefined
  sourceTimesheetID: string | null | undefined
  isCorrectionEntry: boolean | undefined
  workOrderID: string | null | undefined
  scaffoldID: string | null | undefined
  equipmentID: string | undefined
  equipmentClassificationID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  costCodeID: string | null | undefined
  regularTime: number | null | undefined
  days: number | null | undefined
  quantity: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface LEM {
  id: string | undefined
  internalNumber: number | undefined
  environmentID: string | null | undefined
  contractorID: string | undefined
  day: Date | undefined
  lemStatusID: LemStatus | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface PersonWithLocations {
  id: string
  firstName: string
  lastName: string
  areas: Array<AreaWithSubAreas>
}
export interface LocationFilterWithLocations {
  locations: Array<ProjectLocation>
  areas: Array<AreaWithSubAreas>
  id: string | undefined
  includesAllLocations: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Person {
  employeeCode: string | undefined
  employeeBadge: string | undefined
  isTimeManager: boolean | undefined
  disableAutomaticPerDiem: boolean | undefined
  isRequestingEnrolment: boolean | undefined
  isRequestingAccess: boolean | undefined
  classificationID: string | null | undefined
  classificationIDJson: string | undefined
  contractorID: string | null | undefined
  includesAllContractors: boolean | undefined
  contractorIDJson: string | undefined
  includesAllProjects: boolean | undefined
  projectIDJson: string | undefined
  includesAllDisciplines: boolean | undefined
  disciplineIDJson: string | undefined
  locationFilterID: string | null | undefined
  isPlanner: boolean | null | undefined
  isCoordinator: boolean | null | undefined
  isGeneralForeman: boolean | null | undefined
  isForeman: boolean | null | undefined
  isDesigner: boolean | null | undefined
  isDesignManager: boolean | null | undefined
  isInspector: boolean | null | undefined
  canRequestScaffolds: boolean | null | undefined
  id: string | undefined
  environmentID: string | null | undefined
  securityCredentialID: string | null | undefined
  personalIdentifier: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  languageID: string | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export enum PersonLoginStates {
  None = 0,
  Unused = 1,
  Active = 2,
  Locked = 3,
  Deactivated = 4,
}
export interface LoginInformation {
  firstName: string
  lastName: string
  emailAddress: string
  loginUsername: string
  userID: string
  languageID: string | null
  hasAcceptedTermsAndConditions: boolean
  requiresNewTermsAndConditions: boolean
  awaitingSecondFactor: boolean
  accessCodeChallenge: AccessCodeChallenge
}
export interface AccessInformation {
  includesAllContractors: boolean | undefined
  contractorIDs: Array<string> | undefined
  homeContractorID: string | null | undefined
  homeContractorName: string | undefined
  includesAllProjects: boolean | undefined
  projectIDs: Array<string> | undefined
  canConfigureSettings: boolean | undefined
  canConfigurePrivateSettings: boolean | undefined
  canConfigureContractorEmployees: boolean | undefined
  canEnterHistoricalData: boolean | undefined
  canCreateScaffoldInspections: boolean | undefined
  canCreateScaffoldDesigns: boolean | undefined
  canReleaseScaffoldDesigns: boolean | undefined
  canSubmitScaffoldRequests: boolean | undefined
  canCreateFireproofingJobs: boolean | undefined
  canCreateHeatTraceJobs: boolean | undefined
  canCreateInsulationJobs: boolean | undefined
  canCreateMaintenanceJobs: boolean | undefined
  canCreatePaintJobs: boolean | undefined
  canCreateRefractoryJobs: boolean | undefined
  canCreateScaffoldJobs: boolean | undefined
  canReleaseFireproofingJobs: boolean | undefined
  canReleaseHeatTraceJobs: boolean | undefined
  canReleaseInsulationJobs: boolean | undefined
  canReleaseMaintenanceJobs: boolean | undefined
  canReleasePaintJobs: boolean | undefined
  canReleaseRefractoryJobs: boolean | undefined
  canReleaseScaffoldJobs: boolean | undefined
  canWalkDownUnassignedWorkOrders: boolean | undefined
  canImportWorkOrders: boolean | undefined
  canViewScaffoldDesigns: boolean | undefined
  canViewScaffoldInspections: boolean | undefined
  canViewScaffoldRequestApprovals: boolean | undefined
  canViewWorkOrders: boolean | undefined
  canViewWorkOrderEstimates: boolean | undefined
  canViewWorkOrderSchedule: boolean | undefined
  canEditWorkOrderSchedule: boolean | undefined
  canMoveWorkOrdersForward: boolean | undefined
  canViewToDoList: boolean | undefined
  canViewScaffolds: boolean | undefined
  canViewMaterialApproval: boolean | undefined
  canViewTransfers: boolean | undefined
  canViewMaterialOrders: boolean | undefined
  canViewTimesheets: boolean | undefined
  canViewTimesheetApprovals: boolean | undefined
  canOwnDirectTimesheets: boolean | undefined
  canCreateIndirectTimesheets: boolean | undefined
  canCorrectApprovedTimesheets: boolean | undefined
  canCancelTimesheets: boolean | undefined
  canViewLEMs: boolean | undefined
  canCreateTransfers: boolean | undefined
  canSubmitMaterialOrders: boolean | undefined
  canViewMaintenanceJobs: boolean | undefined
  canViewPaintJobs: boolean | undefined
  canViewInsulationJobs: boolean | undefined
  canViewHeatTraceJobs: boolean | undefined
  canViewRefractoryJobs: boolean | undefined
  canViewFireproofingJobs: boolean | undefined
}
export interface BackOrderPartDetails {
  partID: string
  backOrderCount: number
  orderDetails: Array<BackOrderPartOrderDetails>
}
export interface MaterialOrderWithDetails {
  parts: Array<MaterialOrderPartWithDetails> | undefined
  direction: MaterialOrderDirection | null
  id: string | undefined
  number: number | undefined
  orderDate: Date | undefined
  expectedDate: Date | null | undefined
  referenceNumber: string | undefined
  contactPerson: string | undefined
  instructions: string | undefined
  fromSupplierID: string | null | undefined
  toSupplierID: string | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  orderStatusID: MaterialOrderStatus | undefined
  reviewDeclineReason: string | undefined
  cancelReason: string | undefined
  lastStatusChangeTime: Date | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface MaterialOrder {
  id: string | undefined
  number: number | undefined
  orderDate: Date | undefined
  expectedDate: Date | null | undefined
  referenceNumber: string | undefined
  contactPerson: string | undefined
  instructions: string | undefined
  fromSupplierID: string | null | undefined
  toSupplierID: string | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  orderStatusID: MaterialOrderStatus | undefined
  reviewDeclineReason: string | undefined
  cancelReason: string | undefined
  lastStatusChangeTime: Date | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface MaterialOrderPartDetails {
  partID: string
  count: number
}
export interface TransferWithDetails {
  parts: Array<TransferPartWithDetails> | undefined
  direction: TransferDirection | null
  fromScaffoldNumber: number | null
  toScaffoldNumber: number | null
  fromYardName: string
  toYardName: string
  fromSupplierName: string
  toSupplierName: string
  workOrderNumber: string
  relatedTransfers: Array<Transfer>
  reversalTransfer: Transfer
  currentUserPermissions: SummarizedTransferPermissions
  id: string | undefined
  internalNumber: number | undefined
  transferDate: Date | undefined
  workOrderID: string | null | undefined
  transactionNumber: string | undefined
  fromSupplierID: string | null | undefined
  toSupplierID: string | null | undefined
  fromYardID: string | null | undefined
  toYardID: string | null | undefined
  fromScaffoldID: string | null | undefined
  toScaffoldID: string | null | undefined
  transferTypeID: TransferType | undefined
  transferContextID: string | null | undefined
  isAutomaticReturnToSource: boolean | undefined
  sourceToNewDestinationTransferID: string | null | undefined
  reversedByTransferID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface TransferPartDetails {
  partID: string
  count: number
  yardID: string | null
  currentAssignedCount: number | null
}
export interface MessageWithSenderDetails {
  sender: Person | undefined
  id: string | undefined
  messageThreadID: string | undefined
  text: string | undefined
  personID: string | undefined
  sentTime: Date | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface NoteWithSenderDetails {
  sender: Person | undefined
  id: string | undefined
  noteThreadID: string | undefined
  text: string | undefined
  personID: string | undefined
  sentTime: Date | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface TimesheetNoteThread {
  id: string | undefined
  timesheetID: string | undefined
  noteThreadID: string | undefined
  isCorrection: boolean | undefined
  employeeID: string | null | undefined
  equipmentID: string | null | undefined
  classificationID: string | null | undefined
  equipmentClassificationID: string | null | undefined
  workOrderID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface NotificationWithDetails {
  isRead: boolean | undefined
  sentTime: Date | undefined
  projectID: string | null | undefined
  contractorID: string | null | undefined
  id: string | undefined
  title: string | undefined
  description: string | undefined
  severity: NotificationSeverity | undefined
  personID: string | null | undefined
  trigger: string | undefined
  context: string | undefined
  contextID: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Notification {
  projectID: string | null | undefined
  contractorID: string | null | undefined
  id: string | undefined
  title: string | undefined
  description: string | undefined
  severity: NotificationSeverity | undefined
  personID: string | null | undefined
  trigger: string | undefined
  context: string | undefined
  contextID: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ContactMethod {
  id: string
  allowNotifications: boolean
  allowAccessCodes: boolean
  phoneNumberTail: string
  emailAddress: string
}
export interface Owner {
  id: string | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  enabled: boolean | undefined
}
export interface PartWithTags {
  countSheetGroupName: string | undefined
  tagIDs: Array<string> | undefined
  supplierIDs: Array<string> | undefined
  id: string | undefined
  countSheetGroupID: string | null | undefined
  countSheetGroupDisplayOrder: number | null | undefined
  publicID: string | undefined
  name: string | undefined
  description: string | undefined
  longDescription: string | undefined
  weight: number | null | undefined
  mpp: number | null | undefined
  cleatingMPP: number | null | undefined
  lashingMPP: number | null | undefined
  carpentryMPP: number | null | undefined
  otherMPP: number | null | undefined
  rentalRate: number | null | undefined
  costUsed: number | null | undefined
  costNew: number | null | undefined
  designation: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface YardTotal {
  yardID: string
  partID: string | null
  name: string
  total: number
}
export interface PartsImportSettings {
  identifyingColumnFriendlyName: string
  publicID: string
  name: string
  description: string
  longDescription: string
  weight: string
  mpp: string
  lashingMPP: string
  cleatingMPP: string
  carpentryMPP: string
  otherMPP: string
  countSheetGroupID: string
  rentalRate: string
  costUsed: string
  costNew: string
  designation: string
  tagNames: string
  supplierNames: string
  columnFriendlyNames: Array<string>
}
export interface BulkImportResults {
  foundRowCount: number | null
  updatedRowCount: number | null
  insertedRowCount: number | null
  successful: boolean
}
export interface PersonalSetting {
  id: string | undefined
  personID: string | undefined
  workOrderSchedulerColumnsJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PersonWithDetails {
  classificationLogs: Array<PersonClassificationLogWithDetails> | undefined
  tagIDs: Array<string> | undefined
  emailAddresses: Array<PersonalEmailAddressWithVerified> | undefined
  phoneNumbers: Array<PersonalPhoneNumberWithVerified> | undefined
  loginDisabledDate: Date | null | undefined
  loginState: PersonLoginStates | undefined
  isLoginActive: boolean | undefined
  isArchived: boolean | undefined
  canSignIn: boolean | undefined
  hasVerifiedContactMethod: boolean | undefined
  currentUserPermissions: SummarizedPersonPermissions
  projectIDs: Array<string> | undefined
  classificationIDs: Array<string> | undefined
  contractorIDs: Array<string> | undefined
  disciplineIDs: Array<string> | undefined
  canConfigureSettings: boolean | undefined
  canConfigurePrivateSettings: boolean | undefined
  canUpdateAssociatedWorkOrdersWithoutBeingAssigned: boolean | undefined
  forceReadonlyAccess: boolean | undefined
  canEnterHistoricalData: boolean | undefined
  canCreateScaffoldInspections: boolean | undefined
  canCreateScaffoldDesigns: boolean | undefined
  canReleaseScaffoldDesigns: boolean | undefined
  canSubmitScaffoldRequests: boolean | undefined
  canApproveScaffoldRequests: boolean | undefined
  canApproveScaffoldRequestsFromAllAssignedContractors: boolean | undefined
  canCreateFireproofingJobs: boolean | undefined
  canCreateHeatTraceJobs: boolean | undefined
  canCreateInsulationJobs: boolean | undefined
  canCreateMaintenanceJobs: boolean | undefined
  canCreatePaintJobs: boolean | undefined
  canCreateRefractoryJobs: boolean | undefined
  canCreateScaffoldJobs: boolean | undefined
  canReleaseFireproofingJobs: boolean | undefined
  canReleaseHeatTraceJobs: boolean | undefined
  canReleaseInsulationJobs: boolean | undefined
  canReleaseMaintenanceJobs: boolean | undefined
  canReleasePaintJobs: boolean | undefined
  canReleaseRefractoryJobs: boolean | undefined
  canReleaseScaffoldJobs: boolean | undefined
  canEditScaffoldStatus: boolean | undefined
  canEditScaffoldDismantleDate: boolean | undefined
  canWalkDownUnassignedWorkOrders: boolean | undefined
  canApproveWalkdowns: boolean | undefined
  canApproveCountSheets: boolean | undefined
  canSubmitTimesheets: boolean | undefined
  canEditTimesheetCostCode: boolean | undefined
  canEditTimesheetWorkType: boolean | undefined
  canApproveTimesheets: boolean | undefined
  canSeeOtherPeopleTimesheets: boolean | undefined
  canOwnDirectTimesheets: boolean | undefined
  canCreateIndirectTimesheets: boolean | undefined
  canCorrectApprovedTimesheets: boolean | undefined
  canCancelTimesheets: boolean | undefined
  canSubmitLEMs: boolean | undefined
  canApproveLEMs: boolean | undefined
  canConfigureContractorEmployees: boolean | undefined
  canCreateTransfers: boolean | undefined
  canOverrideTransferValues: boolean | undefined
  canSubmitMaterialOrders: boolean | undefined
  canApproveMaterialOrders: boolean | undefined
  canFulfillMaterialOrders: boolean | undefined
  canImportWorkOrders: boolean | undefined
  canViewWorkOrderEstimateDetails: boolean | undefined
  canEditWorkOrderEstimateDetails: boolean | undefined
  canEditWorkOrderBuildSheetResponses: boolean | undefined
  canEditWorkOrderArea: boolean | undefined
  canEditWorkOrderAreaForAllAssignedContractors: boolean | undefined
  canEditWorkOrderLocation: boolean | undefined
  canEditWorkOrderLocationForAllAssignedContractors: boolean | undefined
  canEditWorkOrderWorkDescription: boolean | undefined
  canEditWorkOrderWorkDescriptionForAllAssignedContractors: boolean | undefined
  canEditWorkOrderPriority: boolean | undefined
  canEditWorkOrderPriorityForAllAssignedContractors: boolean | undefined
  canEditWorkOrderProgress: boolean | undefined
  canEditWorkOrderProgressForAllAssignedContractors: boolean | undefined
  canEditWorkOrderStatus: boolean | undefined
  canEditWorkOrderStatusForAllAssignedContractors: boolean | undefined
  canCancelWorkOrder: boolean | undefined
  canCancelWorkOrderForAllAssignedContractors: boolean | undefined
  canEditWorkOrderRequiredDate: boolean | undefined
  canEditWorkOrderRequiredDateForAllAssignedContractors: boolean | undefined
  canEditWorkOrderPlannedWorkDate: boolean | undefined
  canEditWorkOrderPlannedWorkDateForAllAssignedContractors: boolean | undefined
  canEditWorkOrderContractor: boolean | undefined
  canEditWorkOrderCoordinator: boolean | undefined
  canEditWorkOrderCoordinatorForAllAssignedContractors: boolean | undefined
  canEditWorkOrderGeneralForeman: boolean | undefined
  canEditWorkOrderGeneralForemanForAllAssignedContractors: boolean | undefined
  canEditWorkOrderForeman: boolean | undefined
  canEditWorkOrderForemanForAllAssignedContractors: boolean | undefined
  canEditWorkOrderWorkPackages: boolean | undefined
  canViewScaffoldDesigns: boolean | undefined
  canViewScaffoldInspections: boolean | undefined
  canViewScaffoldRequestApprovals: boolean | undefined
  canViewWorkOrders: boolean | undefined
  canViewWorkOrderEstimates: boolean | undefined
  canViewWorkOrderSchedule: boolean | undefined
  canEditWorkOrderSchedule: boolean | undefined
  canViewToDoList: boolean | undefined
  canViewScaffolds: boolean | undefined
  canViewMaterialApproval: boolean | undefined
  canViewTransfers: boolean | undefined
  canViewMaterialOrders: boolean | undefined
  canViewTimesheets: boolean | undefined
  canViewLEMs: boolean | undefined
  securityCredentialLastUpdated: Date | null
  securityCredentialLastUpdatedBy: string | null
  securityCredentialLastUpdatedByName: string
  securityCredentialLastUpdatedByIdentifier: string
  employeeCode: string | undefined
  employeeBadge: string | undefined
  isTimeManager: boolean | undefined
  disableAutomaticPerDiem: boolean | undefined
  isRequestingEnrolment: boolean | undefined
  isRequestingAccess: boolean | undefined
  classificationID: string | null | undefined
  classificationIDJson: string | undefined
  contractorID: string | null | undefined
  includesAllContractors: boolean | undefined
  contractorIDJson: string | undefined
  includesAllProjects: boolean | undefined
  projectIDJson: string | undefined
  includesAllDisciplines: boolean | undefined
  disciplineIDJson: string | undefined
  locationFilterID: string | null | undefined
  isPlanner: boolean | null | undefined
  isCoordinator: boolean | null | undefined
  isGeneralForeman: boolean | null | undefined
  isForeman: boolean | null | undefined
  isDesigner: boolean | null | undefined
  isDesignManager: boolean | null | undefined
  isInspector: boolean | null | undefined
  canRequestScaffolds: boolean | null | undefined
  id: string | undefined
  environmentID: string | null | undefined
  securityCredentialID: string | null | undefined
  personalIdentifier: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  languageID: string | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PersonWithParsedChildIDs {
  tagIDs: Array<string> | undefined
  projectIDs: Array<string> | undefined
  classificationIDs: Array<string> | undefined
  contractorIDs: Array<string> | undefined
  disciplineIDs: Array<string> | undefined
  employeeCode: string | undefined
  employeeBadge: string | undefined
  isTimeManager: boolean | undefined
  disableAutomaticPerDiem: boolean | undefined
  isRequestingEnrolment: boolean | undefined
  isRequestingAccess: boolean | undefined
  classificationID: string | null | undefined
  classificationIDJson: string | undefined
  contractorID: string | null | undefined
  includesAllContractors: boolean | undefined
  contractorIDJson: string | undefined
  includesAllProjects: boolean | undefined
  projectIDJson: string | undefined
  includesAllDisciplines: boolean | undefined
  disciplineIDJson: string | undefined
  locationFilterID: string | null | undefined
  isPlanner: boolean | null | undefined
  isCoordinator: boolean | null | undefined
  isGeneralForeman: boolean | null | undefined
  isForeman: boolean | null | undefined
  isDesigner: boolean | null | undefined
  isDesignManager: boolean | null | undefined
  isInspector: boolean | null | undefined
  canRequestScaffolds: boolean | null | undefined
  id: string | undefined
  environmentID: string | null | undefined
  securityCredentialID: string | null | undefined
  personalIdentifier: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  languageID: string | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ProjectCostCode {
  id: string | undefined
  projectID: string | undefined
  name: string | undefined
  code: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ProjectLocation {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  projectID: string | null | undefined
  parentLocationID: string | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ProjectWithParts {
  partIDs: Array<string> | undefined
  id: string | undefined
  name: string | undefined
  description: string | undefined
  productivity: number | null | undefined
  labourRate: number | null | undefined
  ownerID: string | undefined
  regionID: string | null | undefined
  includesAllParts: boolean | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface QuestionWithText {
  textByLanguageCode: { [key: string]: string } | undefined
  id: string | undefined
  displayOrder: number | undefined
  categoryID: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Question {
  id: string | undefined
  displayOrder: number | undefined
  categoryID: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Supplier {
  id: string | undefined
  environmentID: string | null | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  addressLine1: string | undefined
  addressLine2: string | undefined
  addressLine3: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface SupplierWithTags {
  tagIDs: Array<string> | undefined
  partIDs: Array<string> | undefined
  id: string | undefined
  environmentID: string | null | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  addressLine1: string | undefined
  addressLine2: string | undefined
  addressLine3: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Region {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  enabled: boolean | undefined
}
export interface ScaffoldBayHeight {
  id: string | undefined
  name: string | undefined
  meters: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldBayLength {
  id: string | undefined
  name: string | undefined
  inches: number | null | undefined
  deckPartID: string | null | undefined
  deckPartID_Secondary: string | null | undefined
  ledgerPartID: string | null | undefined
  bracePartID: string | null | undefined
  toeBoardPartID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldBayWidth {
  id: string | undefined
  name: string | undefined
  inches: number | null | undefined
  deckPlankCount: number | null | undefined
  deckPlankCount_Secondary: number | null | undefined
  ledgerPartID: string | null | undefined
  deckingLedgerPartID: string | null | undefined
  bracePartID: string | null | undefined
  toeBoardPartID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldCongestionFactor {
  id: string | undefined
  name: string | undefined
  modifier: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldDesignEstimateSnapshotWithDetails {
  hasGeneratedExcelFile: boolean
  creatorName: string
  components: Array<ScaffoldDesignEstimateComponent> | undefined
  id: string | undefined
  scaffoldDesignID: string | null | undefined
  notes: string | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  isEstimateGenerationSuccessful: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldDesignEstimateComponent {
  id: string | undefined
  scaffoldDesignEstimateSnapshotID: string | undefined
  generationTypeID: PartGenerationType | undefined
  scaffoldTypeID: ScaffoldType | undefined
  scaffoldSubTypeID: ScaffoldSubType | undefined
  isNew: boolean | undefined
  isModified: boolean | undefined
  isRemoved: boolean | undefined
  isExcluded: boolean | undefined
  notes: string | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  barricadeGates: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  modificationPercent: number | null | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldDesignWorkingComponent {
  id: string | undefined
  scaffoldDesignID: string | undefined
  scaffoldDesignEstimateComponentID: string | null | undefined
  generationTypeID: PartGenerationType | undefined
  scaffoldTypeID: ScaffoldType | undefined
  scaffoldSubTypeID: ScaffoldSubType | undefined
  isNew: boolean | undefined
  isModified: boolean | undefined
  isRemoved: boolean | undefined
  isExcluded: boolean | undefined
  notes: string | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  barricadeGates: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  modificationPercent: number | null | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldDesignWithDetails {
  scaffoldNumber: number | null | undefined
  scaffoldStatus: ScaffoldStatuses | null | undefined
  tagIDs: Array<string> | undefined
  workPackageIDs: Array<string> | undefined | null
  currentUserPermissions: SummarizedScaffoldDesignPermissions
  id: string | undefined
  scaffoldID: string | null | undefined
  noteThreadID: string | null | undefined
  scaffoldDesignStatusID: ScaffoldDesignStatus | undefined
  scaffoldDesignStatusDetail: string | undefined
  assignedPersonID: string | null | undefined
  reviewerID: string | null | undefined
  managerID: string | null | undefined
  releasedOn: Date | null | undefined
  releasedBy: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  navisworksTag: string | undefined
  requiredDate: Date | null | undefined
  isEngineeringRequired: boolean | undefined
  coordX: number | null | undefined
  coordY: number | null | undefined
  coordZ: number | null | undefined
  priority: number | undefined
  progress: number | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldDesign {
  id: string | undefined
  scaffoldID: string | null | undefined
  noteThreadID: string | null | undefined
  scaffoldDesignStatusID: ScaffoldDesignStatus | undefined
  scaffoldDesignStatusDetail: string | undefined
  assignedPersonID: string | null | undefined
  reviewerID: string | null | undefined
  managerID: string | null | undefined
  releasedOn: Date | null | undefined
  releasedBy: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  navisworksTag: string | undefined
  requiredDate: Date | null | undefined
  isEngineeringRequired: boolean | undefined
  coordX: number | null | undefined
  coordY: number | null | undefined
  coordZ: number | null | undefined
  priority: number | undefined
  progress: number | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldDesignTakeoffPart {
  partName: string | undefined
  partDescription: string | undefined
  partPublicID: string | undefined
  id: string | undefined
  scaffoldDesignID: string | undefined
  scaffoldDesignEstimateSnapshotID: string | null | undefined
  scaffoldDesignEstimateComponentID: string | null | undefined
  partID: string | undefined
  quantity: number | undefined
  totalWeight: number | null | undefined
  estimatedErectMinutes: number | null | undefined
  estimatedDismantleMinutes: number | null | undefined
  estimatedModifyMinutes: number | null | undefined
  estimatedMobilizationMinutes: number | null | undefined
  estimatedHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldDesignWorkingTakeoff {
  id: string | undefined
  scaffoldDesignID: string | undefined
  scaffoldDesignWorkingComponentID: string | null | undefined
  partID: string | undefined
  quantity: number | undefined
  totalWeight: number | null | undefined
  estimatedErectMinutes: number | null | undefined
  estimatedDismantleMinutes: number | null | undefined
  estimatedModifyMinutes: number | null | undefined
  estimatedMobilizationMinutes: number | null | undefined
  estimatedHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldDistanceModifier {
  id: string | undefined
  name: string | undefined
  modifier: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldElevationModifier {
  id: string | undefined
  name: string | undefined
  modifier: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldHeightModifier {
  id: string | undefined
  name: string | undefined
  modifier: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldInspectionTimeRange {
  name: string
  style: string
  start: Date
  startTimeString: string
  end: Date
  endTimeString: string
  lastInspectionStatus: InspectionStatus | null
}
export interface ScaffoldWithInspectionStatus {
  inspectionStatus: InspectionStatus
  lastInspectionDateTime: Date | null
  lastInspectionCreated: Date | null
  lastInspectedBy: string | null
  inspections: Array<ScaffoldInspectionWithQuestions>
  currentSegmentInspection: ScaffoldInspection
  tagIDs: Array<string> | undefined | null
  id: string | undefined
  internalNumber: number | undefined
  scaffoldStatus: ScaffoldStatuses | undefined
  contractorID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  externalReferenceNumber: string | undefined
  plannedErectDate: Date | null | undefined
  actualErectDate: Date | null | undefined
  plannedDismantleDate: Date | null | undefined
  actualDismantleDate: Date | null | undefined
  totalVLF: number | null | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ScaffoldInspectionWithDetails {
  timeSegment: ScaffoldInspectionTimeRange
  scaffoldNumber: number | null
  scaffoldSpecificWorkLocation: string
  scaffoldContractorID: string | null
  scaffoldAreaID: string | null
  scaffoldSubAreaID: string | null
  scaffoldStatus: ScaffoldStatuses | null
  scaffoldActualErectDate: Date | null
  scaffoldActualDismantleDate: Date | null
  questionAnswers: Array<QuestionWithAnswer> | undefined
  tagIDs: Array<string> | undefined | null
  id: string | undefined
  scaffoldID: string | undefined
  questionAnswersJson: string | undefined
  additionalInformation: string | undefined
  inspectionDateTime: Date | undefined
  inspectedBy: string | undefined
  inspectionDidPass: boolean | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldRequest {
  id: string | undefined
  internalRequestNumber: string | undefined
  requestorID: string | undefined
  workOrderNumber: number | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  scaffoldRequestStatus: ScaffoldRequestStatuses | undefined
  scaffoldRequestStatusDetail: string | undefined
  scaffoldRequestApprovalIsAutomatic: boolean | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  requestType: ScaffoldRequestTypes | undefined
  requestSubType: ScaffoldRequestSubTypes | undefined
  scaffoldDesignID: string | null | undefined
  scaffoldID: string | null | undefined
  scaffoldNumber: number | null | undefined
  jobID: string | null | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  siteContact: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  isPlanned: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  isWalkdownRequired: boolean | undefined
  isClientWorkOrder: boolean | undefined
  clientWorkOrderReferenceNumber: string | undefined
  clientWorkOrderReason: string | undefined
  isChangeOrder: boolean | undefined
  changeOrderReferenceNumber: string | undefined
  changeOrderReason: string | undefined
  isRework: boolean | undefined
  reworkReferenceNumber: string | undefined
  reworkReason: string | undefined
  isServiceOrder: boolean | undefined
  serviceOrderReferenceNumber: string | undefined
  serviceOrderReason: string | undefined
  purchaseOrderID: string | null | undefined
  existingTagNumber: string | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ScaffoldRequestWithDetails {
  relatedWorkOrderID: string | null
  scaffoldSubmittedRequestIDs: Array<string>
  scaffoldDismantleRequestIDs: Array<string>
  scaffoldActiveWorkOrderIDs: Array<string>
  scaffoldDismantleWorkOrderIDs: Array<string>
  lastStatusChangeDate: Date | null
  lastStatusChangedBy: string
  isModifyRequest: boolean
  walkdown: WalkdownWithRequestDetails
  requestingContractorName: string
  assignedContractorID: string | null
  coordinatorID: string | null
  generalForemanID: string | null
  foremanID: string | null
  workOrderAssignedContractorName: string
  requestorName: string
  requestingEmployeeName: string
  submitterName: string
  areaName: string
  subAreaName: string
  disciplineName: string
  canEditRequest: boolean
  requestStatusName: string
  workOrderApprovalComments: string
  workOrderPriority: number | null
  workOrderProgress: number | null
  workOrderCoordinatorName: string
  workOrderGeneralForemanName: string
  workOrderForemanName: string
  workOrderRequestStatusDetails: string
  workOrderRequestDate: Date | null
  workOrderStartDate: Date | null
  workOrderCompletedDate: Date | null
  workPackageNames: Array<string> | undefined
  workPackageIDs: Array<string> | undefined | null
  tagIDs: Array<string> | undefined
  currentUserPermissions: SummarizedScaffoldRequestPermissions
  id: string | undefined
  internalRequestNumber: string | undefined
  requestorID: string | undefined
  workOrderNumber: number | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  scaffoldRequestStatus: ScaffoldRequestStatuses | undefined
  scaffoldRequestStatusDetail: string | undefined
  scaffoldRequestApprovalIsAutomatic: boolean | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  requestType: ScaffoldRequestTypes | undefined
  requestSubType: ScaffoldRequestSubTypes | undefined
  scaffoldDesignID: string | null | undefined
  scaffoldID: string | null | undefined
  scaffoldNumber: number | null | undefined
  jobID: string | null | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  siteContact: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  isPlanned: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  isWalkdownRequired: boolean | undefined
  isClientWorkOrder: boolean | undefined
  clientWorkOrderReferenceNumber: string | undefined
  clientWorkOrderReason: string | undefined
  isChangeOrder: boolean | undefined
  changeOrderReferenceNumber: string | undefined
  changeOrderReason: string | undefined
  isRework: boolean | undefined
  reworkReferenceNumber: string | undefined
  reworkReason: string | undefined
  isServiceOrder: boolean | undefined
  serviceOrderReferenceNumber: string | undefined
  serviceOrderReason: string | undefined
  purchaseOrderID: string | null | undefined
  existingTagNumber: string | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ScaffoldRequestWithChildIDs {
  workPackageIDs: Array<string> | undefined | null
  tagIDs: Array<string> | undefined
  currentUserPermissions: SummarizedScaffoldRequestPermissions
  id: string | undefined
  internalRequestNumber: string | undefined
  requestorID: string | undefined
  workOrderNumber: number | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  scaffoldRequestStatus: ScaffoldRequestStatuses | undefined
  scaffoldRequestStatusDetail: string | undefined
  scaffoldRequestApprovalIsAutomatic: boolean | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  requestType: ScaffoldRequestTypes | undefined
  requestSubType: ScaffoldRequestSubTypes | undefined
  scaffoldDesignID: string | null | undefined
  scaffoldID: string | null | undefined
  scaffoldNumber: number | null | undefined
  jobID: string | null | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  siteContact: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  isPlanned: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  isWalkdownRequired: boolean | undefined
  isClientWorkOrder: boolean | undefined
  clientWorkOrderReferenceNumber: string | undefined
  clientWorkOrderReason: string | undefined
  isChangeOrder: boolean | undefined
  changeOrderReferenceNumber: string | undefined
  changeOrderReason: string | undefined
  isRework: boolean | undefined
  reworkReferenceNumber: string | undefined
  reworkReason: string | undefined
  isServiceOrder: boolean | undefined
  serviceOrderReferenceNumber: string | undefined
  serviceOrderReason: string | undefined
  purchaseOrderID: string | null | undefined
  existingTagNumber: string | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface WorkOrderWithAllDetails {
  scaffoldRequestSubType: ScaffoldRequestSubTypes | null | undefined
  countSheet: CountSheet
  isAnyTimeEntered: boolean
  isTimeEntryCompleted: boolean
  scaffoldSubmittedRequestIDs: Array<string>
  scaffoldDismantleRequestIDs: Array<string>
  scaffoldActiveWorkOrderIDs: Array<string>
  scaffoldDismantleWorkOrderIDs: Array<string>
  requestDate: Date | null | undefined
  lastStatusChangeDate: Date | null | undefined
  lastStatusChangedBy: string
  lastStatusChangedByCredentialID: string | null | undefined
  urgentChangeLogs: Array<WorkOrderUrgentLogWithDetails>
  lastUrgentValueChangedDate: Date | null | undefined
  lastUrgentValueChangedBy: string | undefined
  lastUrgentDetailChangedDate: Date | null | undefined
  lastUrgentDetailChangedBy: string | undefined
  workPackages: Array<WorkPackage> | undefined
  walkdown: WalkdownWithRequestDetails
  isModifyRequest: boolean
  externalLinks: Array<ExternalLink>
  fileNames: Array<string>
  blobFiles: Array<BlobFile>
  isArchived: boolean | undefined
  tagIDs: Array<string> | undefined
  requestingContractorName: string | undefined
  requestingEmployeeName: string | undefined
  requestSubmittedOn: Date | null | undefined
  requestSubmitterCredentialID: string | null | undefined
  requestSubmitterName: string | undefined
  scaffoldExternalReferenceNumber: string | undefined
  requestNumber: string | undefined
  jobNumber: number | null | undefined
  scaffoldTotalVLF: number | null | undefined
  requestStatus: ScaffoldRequestStatuses | undefined
  workOrderStatusName: string | undefined
  requestTypeName: string | undefined
  requestSubTypeName: string | undefined
  crewSize: string | undefined
  workDays: string | undefined
  modificationHours: string | undefined
  length: string | undefined
  bayLengthName: string | undefined
  lengthBayCount: number | null | undefined
  width: string | undefined
  bayWidthName: string | undefined
  widthBayCount: number | null | undefined
  height: string | undefined
  bayHeightName: string | undefined
  isHardBarricade: boolean | null | undefined
  accessType: number | null | undefined
  barricadeGates: number | null | undefined
  deckLevels: number | null | undefined
  assignedContractorName: string | undefined
  coordinatorName: string | undefined
  generalForemanName: string | undefined
  foremanName: string | undefined
  areaName: string | undefined
  subAreaName: string | undefined
  disciplineName: string | undefined
  currentUserPermissions: SummarizedWorkOrderPermissions
  id: string | undefined
  internalNumber: number | undefined
  scaffoldRequestType: ScaffoldRequestTypes | undefined
  scaffoldRequestID: string | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  scaffoldDesignID: string | null | undefined
  scaffoldID: string | null | undefined
  scaffoldNumber: number | null | undefined
  jobID: string | null | undefined
  isoID: string | null | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  workOrderStatusDetail: string | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  assignedContractorID: string | null | undefined
  coordinatorID: string | null | undefined
  generalForemanID: string | null | undefined
  foremanID: string | null | undefined
  priority: number | undefined
  progress: number | undefined
  startDate: Date | null | undefined
  plannedWalkdownStartDate: Date | null | undefined
  plannedWorkStartDate: Date | null | undefined
  completedDate: Date | null | undefined
  isPlanned: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  costCodeID: string | null | undefined
  isClientWorkOrder: boolean | undefined
  clientWorkOrderReferenceNumber: string | undefined
  clientWorkOrderReason: string | undefined
  isChangeOrder: boolean | undefined
  changeOrderReferenceNumber: string | undefined
  changeOrderReason: string | undefined
  isRework: boolean | undefined
  reworkReferenceNumber: string | undefined
  reworkReason: string | undefined
  isServiceOrder: boolean | undefined
  serviceOrderReferenceNumber: string | undefined
  serviceOrderReason: string | undefined
  purchaseOrderID: string | null | undefined
  existingTagNumber: string | undefined
  scaffoldLatitude: number | null | undefined
  scaffoldLongitude: number | null | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldRequestSubTypeWithParent {
  value: ScaffoldRequestSubTypes
  parentRequestType: ScaffoldRequestTypes
}
export interface ScaffoldRequestTypeDetails {
  value: ScaffoldRequestTypes
}
export interface ScaffoldWithDetails {
  tagIDs: Array<string> | undefined
  erectRequestNumber: string | undefined
  lastWorkOrderContractorID: string | null | undefined
  lastWorkOrderStatus: WorkOrderStatuses | null
  workOrders: Array<WorkOrderWithAllDetails> | undefined
  vlfHistory: Array<ScaffoldVerticalLengthFeetLogWithDetails> | undefined
  workPackages: Array<WorkPackage> | undefined
  inventory: Array<Part> | undefined
  transactions: Array<Transfer> | undefined
  currentUserPermissions: SummarizedScaffoldPermissions
  id: string | undefined
  internalNumber: number | undefined
  scaffoldStatus: ScaffoldStatuses | undefined
  contractorID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  externalReferenceNumber: string | undefined
  plannedErectDate: Date | null | undefined
  actualErectDate: Date | null | undefined
  plannedDismantleDate: Date | null | undefined
  actualDismantleDate: Date | null | undefined
  totalVLF: number | null | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ScaffoldForList {
  scaffoldNumber: number | null
  lastWorkOrderContractorID: string | null
  lastWorkOrderStatus: WorkOrderStatuses | null
  workPackages: Array<WorkPackage>
  tagIDs: Array<string>
  id: string | undefined
  internalNumber: number | undefined
  scaffoldStatus: ScaffoldStatuses | undefined
  contractorID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  externalReferenceNumber: string | undefined
  plannedErectDate: Date | null | undefined
  actualErectDate: Date | null | undefined
  plannedDismantleDate: Date | null | undefined
  actualDismantleDate: Date | null | undefined
  totalVLF: number | null | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PartWithTotal {
  partID: string
  total: number
}
export interface ScaffoldInformation {
  id: string
  externalReferenceNumber: string
  scaffoldNumber: number | null
  erectRequestNumber: string
  scaffoldStatus: ScaffoldStatuses
  plannedErectDate: Date | null
  actualErectDate: Date | null
  plannedDismantleDate: Date | null
  actualDismantleDate: Date | null
  contractorID: string | null
  areaID: string | null
  subAreaID: string | null
  specificWorkLocation: string
}
export interface Scaffold {
  id: string | undefined
  internalNumber: number | undefined
  scaffoldStatus: ScaffoldStatuses | undefined
  contractorID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  specificWorkLocation: string | undefined
  externalReferenceNumber: string | undefined
  plannedErectDate: Date | null | undefined
  actualErectDate: Date | null | undefined
  plannedDismantleDate: Date | null | undefined
  actualDismantleDate: Date | null | undefined
  totalVLF: number | null | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface ScaffoldSearchResult {
  id: string | undefined
  internalNumber: number | undefined
  scaffoldStatus: ScaffoldStatuses | undefined
  existingRequestNumber: string
  areaID: string | null | undefined
  areaName: string
  subAreaID: string | null | undefined
  subAreaName: string
  specificWorkLocation: string | undefined
  externalReferenceNumber: string | undefined
  plannedErectDate: Date | null | undefined
  actualErectDate: Date | null | undefined
  plannedDismantleDate: Date | null | undefined
  actualDismantleDate: Date | null | undefined
}
export interface ScaffoldTypeModifier {
  id: string | undefined
  isActive: boolean | undefined
  scaffoldTypeID: ScaffoldType | undefined
  scaffoldSubTypeID: ScaffoldSubType | undefined
  name: string | undefined
  modifier: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface SearchResult {
  scaffoldRequest: ScaffoldRequestResult
  scaffold: ScaffoldResult
  person: PersonResult
  workPackage: WorkPackageResult
  workOrder: WorkOrderResult
  countSheet: CountSheetResult
  resultID: string | undefined | null
  foundTagIDs: Array<string>
}
export interface System {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  code: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface TestPackage {
  id: string | undefined
  systemID: string | null | undefined
  name: string | undefined
  description: string | undefined
  code: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface DayInWeek {
  dayNumber: number
  date: Date | null
  displayOrder: number
  name: string
}
export interface EmployeeWeekTimeSummary {
  employeeID: string | null
  employeeName: string
  employeeCode: string
  employeeBadge: string
  classificationName: string
  classificationAlias: string
  totalRegularTime: number
  totalOverTime: number
  totalDoubleTime: number
  totalTime: number
  totalPerDiemCount: number
  totalEquipmentHours: number
  dailyTimeSummaries: Array<DayTimeSummary>
}
export interface TimesheetWithDetails {
  creatorID: string | null
  creatorName: string
  associatedToLemNumber: string
  ownerClassificationName: string
  ownerClassificationAlias: string
  submittedByName: string
  submittedToName: string
  reviewApprovedByName: string
  entryCount: number | null
  totalRegularTime: number | null
  totalOverTime: number | null
  totalDoubleTime: number | null
  totalUnits: number | null
  totalDays: number | null
  totalQuantity: number | null
  hasEntriesMissingCostCode: boolean | null
  statusLogs: Array<TimesheetStatusLogWithDetails>
  explanations: Array<TimesheetExplanationWithWorkOrderDetails>
  lastStatusLog: TimesheetStatusLogWithDetails
  ownerName: string
  contractorName: string
  currentUserPermissions: SummarizedTimesheetPermissions
  id: string | undefined
  timesheetNumber: number | undefined
  lemID: string | null | undefined
  ownerID: string | undefined
  contractorID: string | undefined
  day: Date | undefined
  isNightShift: boolean | undefined
  timesheetTypeID: TimesheetType | undefined
  timesheetStatusID: TimesheetStatus | undefined
  submittedBy: string | null | undefined
  submittedTo: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface OwnerTimesheetSummary {
  ownerID: string | null
  timesheetCount: number | null
}
export interface EmployeeTimeSummary {
  employeeID: string | null
  perDiemTimesheetOwnerName: string
  perDiemWorkOrderNumber: string
  workSubTypeTimeSummaries: Array<WorkSubTypeTimeSummary>
  totalRegularTime: number
  totalDoubleTime: number
  totalOverTime: number
  totalUnits: number
}
export interface TimesheetWithChildren {
  statusLogs: Array<TimesheetStatusLogWithDetails>
  explanations: Array<TimesheetExplanationWithWorkOrderDetails>
  lastStatusLog: TimesheetStatusLogWithDetails
  ownerName: string
  contractorName: string
  currentUserPermissions: SummarizedTimesheetPermissions
  id: string | undefined
  timesheetNumber: number | undefined
  lemID: string | null | undefined
  ownerID: string | undefined
  contractorID: string | undefined
  day: Date | undefined
  isNightShift: boolean | undefined
  timesheetTypeID: TimesheetType | undefined
  timesheetStatusID: TimesheetStatus | undefined
  submittedBy: string | null | undefined
  submittedTo: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export enum TimesheetStatus {
  New = 1,
  Submitted = 2,
  Approved = 3,
  Declined = 4,
  Cancelled = 5,
}
export interface Timesheet {
  id: string | undefined
  timesheetNumber: number | undefined
  lemID: string | null | undefined
  ownerID: string | undefined
  contractorID: string | undefined
  day: Date | undefined
  isNightShift: boolean | undefined
  timesheetTypeID: TimesheetType | undefined
  timesheetStatusID: TimesheetStatus | undefined
  submittedBy: string | null | undefined
  submittedTo: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface TimesheetEntry {
  id: string | undefined
  timesheetID: string | undefined
  isCorrectionEntry: boolean | undefined
  rowNumber: number | undefined
  workOrderID: string | null | undefined
  scaffoldID: string | null | undefined
  employeeID: string | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  workTypeID: string | null | undefined
  workSubTypeID: string | null | undefined
  classificationID: string | null | undefined
  costCodeID: string | null | undefined
  costCodeIDOverride: string | null | undefined
  regularTime: number | null | undefined
  originalRegularTime: number | null | undefined
  overTime: number | null | undefined
  originalOverTime: number | null | undefined
  doubleTime: number | null | undefined
  originalDoubleTime: number | null | undefined
  units: number | null | undefined
  originalUnits: number | null | undefined
  overridden: boolean | undefined
  overriddenBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EquipmentTimesheetEntry {
  id: string | undefined
  timesheetID: string | undefined
  isCorrectionEntry: boolean | undefined
  rowNumber: number | undefined
  workOrderID: string | null | undefined
  scaffoldID: string | null | undefined
  equipmentID: string | undefined
  equipmentClassificationID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  costCodeID: string | null | undefined
  costCodeIDOverride: string | null | undefined
  regularTime: number | null | undefined
  originalRegularTime: number | null | undefined
  days: number | null | undefined
  originalDays: number | null | undefined
  quantity: number | null | undefined
  originalQuantity: number | null | undefined
  overridden: boolean | undefined
  overriddenBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface TimesheetExplanation {
  id: string | undefined
  timesheetID: string | undefined
  workOrderID: string | null | undefined
  workSubTypeID: string | null | undefined
  explanation: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EquipmentTimesheetEntryWithDetails {
  workOrderType: number | null
  contractorName: string
  equipmentName: string
  equipmentSerialNumber: string
  equipmentModelNumber: string
  workOrderNumber: string
  workOrderClientWorkOrderNumber: string
  workOrderChangeOrderNumber: string
  workOrderReworkNumber: string
  workOrderServiceOrderNumber: string
  workOrderPurchaseOrderID: string | null
  workOrderPurchaseOrderNumber: string
  workOrderExistingTagNumber: string
  scaffoldNumber: number | null
  areaName: string
  subAreaName: string
  classificationName: string
  costCodeName: string
  costCodeOverrideName: string
  id: string | undefined
  timesheetID: string | undefined
  isCorrectionEntry: boolean | undefined
  rowNumber: number | undefined
  workOrderID: string | null | undefined
  scaffoldID: string | null | undefined
  equipmentID: string | undefined
  equipmentClassificationID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  costCodeID: string | null | undefined
  costCodeIDOverride: string | null | undefined
  regularTime: number | null | undefined
  originalRegularTime: number | null | undefined
  days: number | null | undefined
  originalDays: number | null | undefined
  quantity: number | null | undefined
  originalQuantity: number | null | undefined
  overridden: boolean | undefined
  overriddenBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EmployeeTimesheetEntryWithDetails {
  workOrderType: number | null
  contractorName: string
  employeeName: string
  employeeCode: string
  employeeBadge: string
  workOrderNumber: string
  workOrderClientWorkOrderNumber: string
  workOrderChangeOrderNumber: string
  workOrderReworkNumber: string
  workOrderServiceOrderNumber: string
  workOrderPurchaseOrderID: string | null
  workOrderPurchaseOrderNumber: string
  workOrderExistingTagNumber: string
  scaffoldNumber: number | null
  areaName: string
  subAreaName: string
  workTypeName: string
  workSubTypeName: string
  classificationName: string
  classificationAlias: string
  costCodeName: string
  costCodeOverrideName: string
  id: string | undefined
  timesheetID: string | undefined
  isCorrectionEntry: boolean | undefined
  rowNumber: number | undefined
  workOrderID: string | null | undefined
  scaffoldID: string | null | undefined
  employeeID: string | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  workTypeID: string | null | undefined
  workSubTypeID: string | null | undefined
  classificationID: string | null | undefined
  costCodeID: string | null | undefined
  costCodeIDOverride: string | null | undefined
  regularTime: number | null | undefined
  originalRegularTime: number | null | undefined
  overTime: number | null | undefined
  originalOverTime: number | null | undefined
  doubleTime: number | null | undefined
  originalDoubleTime: number | null | undefined
  units: number | null | undefined
  originalUnits: number | null | undefined
  overridden: boolean | undefined
  overriddenBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export enum TimesheetType {
  Direct = 0,
  Indirect = 1,
  Equipment = 2,
}
export interface TimesheetNoteThreadWithNoteCount {
  noteCount: number | null
  id: string | undefined
  timesheetID: string | undefined
  noteThreadID: string | undefined
  isCorrection: boolean | undefined
  employeeID: string | null | undefined
  equipmentID: string | null | undefined
  classificationID: string | null | undefined
  equipmentClassificationID: string | null | undefined
  workOrderID: string | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface LemsListDetails {
  id: string
  internalNumber: number
  contractorID: string
  day: Date
  lemStatusID: LemStatus
  entryCount: number | null
  totalRegularTime: number | null
  totalOverTime: number | null
  totalDoubleTime: number | null
  totalUnits: number | null
  totalDays: number | null
  totalQuantity: number | null
  currentUserPermissions: SummarizedLEMPermissions
  importedTimesheets: Array<LemsListTimesheetDetails>
}
export interface Walkdown {
  id: string | undefined
  workOrderID: string | null | undefined
  scaffoldRequestID: string | null | undefined
  walkdownStatus: WalkdownStatuses | undefined
  notes: string | undefined
  startedOn: Date | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  approvedBy: string | null | undefined
  approvalComments: string | undefined
  approvalChangedDate: Date | null | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  isHardBarricade: boolean | undefined
  barricadeGates: number | null | undefined
  vlfChangeAmount: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderEstimateSnapshotWithDetails {
  hasGeneratedExcelFile: boolean
  creatorName: string
  components: Array<WorkOrderEstimateComponent> | undefined
  id: string | undefined
  workOrderID: string | null | undefined
  walkdownID: string | null | undefined
  notes: string | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  isEstimateGenerationSuccessful: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderEstimateComponent {
  id: string | undefined
  workOrderEstimateSnapshotID: string | undefined
  generationTypeID: PartGenerationType | undefined
  scaffoldTypeID: ScaffoldType | undefined
  scaffoldSubTypeID: ScaffoldSubType | undefined
  isNew: boolean | undefined
  isModified: boolean | undefined
  isRemoved: boolean | undefined
  isExcluded: boolean | undefined
  notes: string | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  barricadeGates: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  modificationPercent: number | null | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderWorkingComponent {
  id: string | undefined
  workOrderID: string | undefined
  workOrderEstimateComponentID: string | null | undefined
  generationTypeID: PartGenerationType | undefined
  scaffoldTypeID: ScaffoldType | undefined
  scaffoldSubTypeID: ScaffoldSubType | undefined
  isNew: boolean | undefined
  isModified: boolean | undefined
  isRemoved: boolean | undefined
  isExcluded: boolean | undefined
  notes: string | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  barricadeGates: number | null | undefined
  factor1: number | null | undefined
  factor2: number | null | undefined
  modificationPercent: number | null | undefined
  estimatedTotalErectMinutes: number | null | undefined
  estimatedTotalDismantleMinutes: number | null | undefined
  estimatedTotalModifyMinutes: number | null | undefined
  estimatedTotalMobilizationMinutes: number | null | undefined
  estimatedTotalDemobilizationMinutes: number | null | undefined
  estimatedTotalHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  estimatedTotalWeight: number | null | undefined
  estimatedTotalPartCount: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderNormResponseWithDetails {
  norm: ContractorNorm
  workOrderID: string | undefined
  contractorNormID: string | undefined
  normDisplayOrder: number | null | undefined
  normDescription: string | undefined
  quantity: number | null | undefined
  squareFeet: number | null | undefined
  hours: number | null | undefined
  text: string | undefined
}
export interface WorkOrderNormResponse {
  workOrderID: string | undefined
  contractorNormID: string | undefined
  normDisplayOrder: number | null | undefined
  normDescription: string | undefined
  quantity: number | null | undefined
  squareFeet: number | null | undefined
  hours: number | null | undefined
  text: string | undefined
}
export interface WorkOrderImportSettings {
  identifyingColumnFriendlyName: string
  workOrderNumber: string
  startDate: string
  requiredDate: string
  columnFriendlyNames: Array<string>
}
export interface WorkOrder {
  id: string | undefined
  internalNumber: number | undefined
  scaffoldRequestType: ScaffoldRequestTypes | undefined
  scaffoldRequestID: string | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  scaffoldDesignID: string | null | undefined
  scaffoldID: string | null | undefined
  scaffoldNumber: number | null | undefined
  jobID: string | null | undefined
  isoID: string | null | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  workOrderStatusDetail: string | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  assignedContractorID: string | null | undefined
  coordinatorID: string | null | undefined
  generalForemanID: string | null | undefined
  foremanID: string | null | undefined
  priority: number | undefined
  progress: number | undefined
  startDate: Date | null | undefined
  plannedWalkdownStartDate: Date | null | undefined
  plannedWorkStartDate: Date | null | undefined
  completedDate: Date | null | undefined
  isPlanned: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  costCodeID: string | null | undefined
  isClientWorkOrder: boolean | undefined
  clientWorkOrderReferenceNumber: string | undefined
  clientWorkOrderReason: string | undefined
  isChangeOrder: boolean | undefined
  changeOrderReferenceNumber: string | undefined
  changeOrderReason: string | undefined
  isRework: boolean | undefined
  reworkReferenceNumber: string | undefined
  reworkReason: string | undefined
  isServiceOrder: boolean | undefined
  serviceOrderReferenceNumber: string | undefined
  serviceOrderReason: string | undefined
  purchaseOrderID: string | null | undefined
  existingTagNumber: string | undefined
  scaffoldLatitude: number | null | undefined
  scaffoldLongitude: number | null | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderForAllWorkOrdersList {
  tagIDs: Array<string>
  workPackageNames: Array<string>
  lastUrgentDetailChangedDate: Date | null
  lastUrgentDetailChangedByID: string | null
  lastUrgentValueChangedDate: Date | null
  lastUrgentValueChangedByID: string | null
  lastStatusChangeDate: Date | null
  lastStatusChangedByID: string | null
  requestNumber: string
  requestStatus: ScaffoldRequestStatuses
  requestSubType: ScaffoldRequestSubTypes
  requestDate: Date
  requestSubmittedBy: string | null
  requestSubmittedOn: Date | null
  walkdown: Walkdown
  id: string | undefined
  internalNumber: number | undefined
  scaffoldRequestType: ScaffoldRequestTypes | undefined
  scaffoldRequestID: string | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  scaffoldDesignID: string | null | undefined
  scaffoldID: string | null | undefined
  scaffoldNumber: number | null | undefined
  jobID: string | null | undefined
  isoID: string | null | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  workOrderStatusDetail: string | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  assignedContractorID: string | null | undefined
  coordinatorID: string | null | undefined
  generalForemanID: string | null | undefined
  foremanID: string | null | undefined
  priority: number | undefined
  progress: number | undefined
  startDate: Date | null | undefined
  plannedWalkdownStartDate: Date | null | undefined
  plannedWorkStartDate: Date | null | undefined
  completedDate: Date | null | undefined
  isPlanned: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  costCodeID: string | null | undefined
  isClientWorkOrder: boolean | undefined
  clientWorkOrderReferenceNumber: string | undefined
  clientWorkOrderReason: string | undefined
  isChangeOrder: boolean | undefined
  changeOrderReferenceNumber: string | undefined
  changeOrderReason: string | undefined
  isRework: boolean | undefined
  reworkReferenceNumber: string | undefined
  reworkReason: string | undefined
  isServiceOrder: boolean | undefined
  serviceOrderReferenceNumber: string | undefined
  serviceOrderReason: string | undefined
  purchaseOrderID: string | null | undefined
  existingTagNumber: string | undefined
  scaffoldLatitude: number | null | undefined
  scaffoldLongitude: number | null | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderSearchResult {
  workOrderNumber: string
  id: string | undefined
  internalNumber: number | undefined
  scaffoldRequestType: ScaffoldRequestTypes | undefined
  scaffoldRequestID: string | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  scaffoldDesignID: string | null | undefined
  scaffoldID: string | null | undefined
  scaffoldNumber: number | null | undefined
  jobID: string | null | undefined
  isoID: string | null | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  workOrderStatusDetail: string | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  assignedContractorID: string | null | undefined
  coordinatorID: string | null | undefined
  generalForemanID: string | null | undefined
  foremanID: string | null | undefined
  priority: number | undefined
  progress: number | undefined
  startDate: Date | null | undefined
  plannedWalkdownStartDate: Date | null | undefined
  plannedWorkStartDate: Date | null | undefined
  completedDate: Date | null | undefined
  isPlanned: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  costCodeID: string | null | undefined
  isClientWorkOrder: boolean | undefined
  clientWorkOrderReferenceNumber: string | undefined
  clientWorkOrderReason: string | undefined
  isChangeOrder: boolean | undefined
  changeOrderReferenceNumber: string | undefined
  changeOrderReason: string | undefined
  isRework: boolean | undefined
  reworkReferenceNumber: string | undefined
  reworkReason: string | undefined
  isServiceOrder: boolean | undefined
  serviceOrderReferenceNumber: string | undefined
  serviceOrderReason: string | undefined
  purchaseOrderID: string | null | undefined
  existingTagNumber: string | undefined
  scaffoldLatitude: number | null | undefined
  scaffoldLongitude: number | null | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldRequestStatusDetails {
  name: string
  displayName: string
  value: ScaffoldRequestStatuses
}
export interface WorkOrderStatusDetails {
  name: string
  displayName: string
  value: WorkOrderStatuses
}
export interface WorkOrderTakeoffPart {
  partName: string | undefined
  partDescription: string | undefined
  partPublicID: string | undefined
  totalWeight: number | null | undefined
  id: string | undefined
  workOrderID: string | undefined
  workOrderEstimateSnapshotID: string | null | undefined
  workOrderEstimateComponentID: string | null | undefined
  partID: string | undefined
  quantity: number | undefined
  estimatedErectMinutes: number | null | undefined
  estimatedDismantleMinutes: number | null | undefined
  estimatedModifyMinutes: number | null | undefined
  estimatedMobilizationMinutes: number | null | undefined
  estimatedHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderWorkingTakeoff {
  id: string | undefined
  workOrderID: string | undefined
  workOrderWorkingComponentID: string | null | undefined
  partID: string | undefined
  quantity: number | undefined
  totalWeight: number | null | undefined
  estimatedErectMinutes: number | null | undefined
  estimatedDismantleMinutes: number | null | undefined
  estimatedModifyMinutes: number | null | undefined
  estimatedMobilizationMinutes: number | null | undefined
  estimatedHoardingMinutes: number | null | undefined
  estimatedTotalTime: number | null | undefined
  estimatedErectMPP: number | null | undefined
  estimatedDismantleMPP: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkPackagesImportSettings {
  identifyingColumnFriendlyName: string
  activityID: string
  name: string
  startDate: string
  finishDate: string
  completionPercentage: string
  workStatus: string
  purpose: string
  plannedStartDate: string
  plannedFinishDate: string
  cwpPlannedStartDate: string
  cwpPlannedFinishDate: string
  forecastStartDate: string
  forecastFinishDate: string
  discipline: string
  unit: string
  constructionWorkArea: string
  area: string
  longDescription: string
  description: string
  cwp: string
  estimatedHours: string
  earnedHours: string
  statusDate: string
  columnFriendlyNames: Array<string>
}
export interface WorkPackageForList {
  id: string | undefined
  activityID: string | undefined
  name: string | undefined
  startDate: Date | null | undefined
  finishDate: Date | null | undefined
  completionPercentage: number | null | undefined
  workStatus: string | undefined
  archivedDate: Date | null | undefined
}
export interface WorkPackage {
  id: string | undefined
  activityID: string | undefined
  name: string | undefined
  startDate: Date | null | undefined
  finishDate: Date | null | undefined
  completionPercentage: number | null | undefined
  workStatus: string | undefined
  purpose: string | undefined
  plannedStartDate: Date | null | undefined
  plannedFinishDate: Date | null | undefined
  cwpplannedStartDate: Date | null | undefined
  cwpplannedFinishDate: Date | null | undefined
  forecastStartDate: Date | null | undefined
  forecastFinishDate: Date | null | undefined
  discipline: string | undefined
  unit: string | undefined
  constructionWorkArea: string | undefined
  area: string | undefined
  longDescription: string | undefined
  description: string | undefined
  cwp: string | undefined
  estimatedHours: number | null | undefined
  earnedHours: number | null | undefined
  statusDate: Date | null | undefined
  c01: string | undefined
  c02: string | undefined
  c03: string | undefined
  c04: string | undefined
  c05: string | undefined
  c06: string | undefined
  c07: string | undefined
  c08: string | undefined
  c09: string | undefined
  c10: string | undefined
  c11: string | undefined
  c12: string | undefined
  c13: string | undefined
  c14: string | undefined
  c15: string | undefined
  c16: string | undefined
  c17: string | undefined
  c18: string | undefined
  c19: string | undefined
  c20: string | undefined
  c21: string | undefined
  c22: string | undefined
  c23: string | undefined
  c24: string | undefined
  c25: string | undefined
  c26: string | undefined
  c27: string | undefined
  c28: string | undefined
  c29: string | undefined
  c30: string | undefined
  c31: string | undefined
  c32: string | undefined
  c33: string | undefined
  c34: string | undefined
  c35: string | undefined
  c36: string | undefined
  c37: string | undefined
  c38: string | undefined
  c39: string | undefined
  c40: string | undefined
  c41: string | undefined
  c42: string | undefined
  c43: string | undefined
  c44: string | undefined
  c45: string | undefined
  c46: string | undefined
  c47: string | undefined
  c48: string | undefined
  c49: string | undefined
  c50: string | undefined
  c51: string | undefined
  c52: string | undefined
  c53: string | undefined
  c54: string | undefined
  c55: string | undefined
  c56: string | undefined
  c57: string | undefined
  c58: string | undefined
  c59: string | undefined
  c60: string | undefined
  c61: string | undefined
  c62: string | undefined
  c63: string | undefined
  c64: string | undefined
  c65: string | undefined
  c66: string | undefined
  c67: string | undefined
  c68: string | undefined
  c69: string | undefined
  c70: string | undefined
  c71: string | undefined
  c72: string | undefined
  c73: string | undefined
  c74: string | undefined
  c75: string | undefined
  c76: string | undefined
  c77: string | undefined
  c78: string | undefined
  c79: string | undefined
  c80: string | undefined
  c81: string | undefined
  c82: string | undefined
  c83: string | undefined
  c84: string | undefined
  c85: string | undefined
  c86: string | undefined
  c87: string | undefined
  c88: string | undefined
  c89: string | undefined
  c90: string | undefined
  c91: string | undefined
  c92: string | undefined
  c93: string | undefined
  c94: string | undefined
  c95: string | undefined
  c96: string | undefined
  c97: string | undefined
  c98: string | undefined
  c99: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface WorkSubType {
  id: string | undefined
  workTypeID: string | undefined
  parentWorkSubTypeID: string | null | undefined
  isParent: boolean | undefined
  name: string | undefined
  code: string | undefined
  useWorkOrderCostCode: boolean | undefined
  defaultCostCodeID: string | null | undefined
  isWorkOrderRelated: boolean | undefined
  requiresAdditionalDetails: boolean | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkSubTypeWithParentDetails {
  parentOrder: number | null
  effectiveOrder: number
  id: string | undefined
  workTypeID: string | undefined
  parentWorkSubTypeID: string | null | undefined
  isParent: boolean | undefined
  name: string | undefined
  code: string | undefined
  useWorkOrderCostCode: boolean | undefined
  defaultCostCodeID: string | null | undefined
  isWorkOrderRelated: boolean | undefined
  requiresAdditionalDetails: boolean | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkType {
  id: string | undefined
  name: string | undefined
  code: string | undefined
  isPerDiem: boolean | undefined
  isEquipment: boolean | undefined
  isDirect: boolean | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkTypeWithDetails {
  hasMisconfiguredSubTypes: boolean | null
  id: string | undefined
  name: string | undefined
  code: string | undefined
  isPerDiem: boolean | undefined
  isEquipment: boolean | undefined
  isDirect: boolean | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface Yard {
  id: string | undefined
  name: string | undefined
  isSystemYard: boolean | null | undefined
  isReceivingYard: boolean | null | undefined
  yardTypeID: YardType | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface AppStatus {
  appVersion: string
  databaseConnection: string
  notifications: string
}
export interface Version {
  major: number
  minor: number
  build: number
  revision: number
  majorRevision: number
  minorRevision: number
}
export interface Language {
  id: string | undefined
  name: string | undefined
  number: number | undefined
  shortCode: string | undefined
  shortCodeExt: string | undefined
}
export interface PaymentMethodInfo {
  id: string
  description: string
}
export interface SubscriptionInfo {
  id: string
  name: string
  priceSummary: string
  selected: boolean
}
export interface PersonalEmailAddress {
  id: string | undefined
  personID: string | undefined
  emailAddress: string | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
  verifiedDate: Date | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PersonalPhoneNumber {
  id: string | undefined
  personID: string | undefined
  phoneNumber: string | undefined
  type: string | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
  verifiedDate: Date | null | undefined
  isUnsubscribed: boolean | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Tag {
  id: string | undefined
  environmentID: string | null | undefined
  name: string | undefined
  description: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface GridCountSheet {
  id: string | undefined
  workOrderID: string | null | undefined
  activeRequests: number | null
  activeWorkOrders: number | null
  hasActiveDismantle: boolean
  countSheetTypeID: CountSheetType | undefined
  reviewStatusID: CountSheetReviewStatus | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
  scaffoldNumber: number | null | undefined
  workOrderNumber: number | null | undefined
  requestType: ScaffoldRequestTypes | undefined
  workOrderStatusID: WorkOrderStatuses | undefined
  currentUserPermissions: SummarizedCountSheetPermissions
}
export interface GridCountSheetTransfer {
  countSheetID: string
  transferNumber: number
}
export interface WorkOrderWithAllDetails2 {
  requestType: ScaffoldRequestTypes
  requestSubType: ScaffoldRequestSubTypes
  requestSubmitterID: string | null
  lastStatusChangedByID: string | null
  scaffoldRequestSubType: ScaffoldRequestSubTypes | null | undefined
  countSheet: CountSheet
  isAnyTimeEntered: boolean
  isTimeEntryCompleted: boolean
  scaffoldSubmittedRequestIDs: Array<string>
  scaffoldDismantleRequestIDs: Array<string>
  scaffoldActiveWorkOrderIDs: Array<string>
  scaffoldDismantleWorkOrderIDs: Array<string>
  requestDate: Date | null | undefined
  lastStatusChangeDate: Date | null | undefined
  lastStatusChangedBy: string
  lastStatusChangedByCredentialID: string | null | undefined
  urgentChangeLogs: Array<WorkOrderUrgentLogWithDetails>
  lastUrgentValueChangedDate: Date | null | undefined
  lastUrgentValueChangedBy: string | undefined
  lastUrgentDetailChangedDate: Date | null | undefined
  lastUrgentDetailChangedBy: string | undefined
  workPackages: Array<WorkPackage> | undefined
  walkdown: WalkdownWithRequestDetails
  isModifyRequest: boolean
  externalLinks: Array<ExternalLink>
  fileNames: Array<string>
  blobFiles: Array<BlobFile>
  isArchived: boolean | undefined
  tagIDs: Array<string> | undefined
  requestingContractorName: string | undefined
  requestingEmployeeName: string | undefined
  requestSubmittedOn: Date | null | undefined
  requestSubmitterCredentialID: string | null | undefined
  requestSubmitterName: string | undefined
  scaffoldExternalReferenceNumber: string | undefined
  requestNumber: string | undefined
  jobNumber: number | null | undefined
  scaffoldTotalVLF: number | null | undefined
  requestStatus: ScaffoldRequestStatuses | undefined
  workOrderStatusName: string | undefined
  requestTypeName: string | undefined
  requestSubTypeName: string | undefined
  crewSize: string | undefined
  workDays: string | undefined
  modificationHours: string | undefined
  length: string | undefined
  bayLengthName: string | undefined
  lengthBayCount: number | null | undefined
  width: string | undefined
  bayWidthName: string | undefined
  widthBayCount: number | null | undefined
  height: string | undefined
  bayHeightName: string | undefined
  isHardBarricade: boolean | null | undefined
  accessType: number | null | undefined
  barricadeGates: number | null | undefined
  deckLevels: number | null | undefined
  assignedContractorName: string | undefined
  coordinatorName: string | undefined
  generalForemanName: string | undefined
  foremanName: string | undefined
  areaName: string | undefined
  subAreaName: string | undefined
  disciplineName: string | undefined
  currentUserPermissions: SummarizedWorkOrderPermissions
  id: string | undefined
  internalNumber: number | undefined
  scaffoldRequestType: ScaffoldRequestTypes | undefined
  scaffoldRequestID: string | null | undefined
  messageThreadID: string | null | undefined
  noteThreadID: string | null | undefined
  scaffoldDesignID: string | null | undefined
  scaffoldID: string | null | undefined
  scaffoldNumber: number | null | undefined
  jobID: string | null | undefined
  isoID: string | null | undefined
  workOrderStatus: WorkOrderStatuses | undefined
  workOrderStatusDetail: string | undefined
  requestingContractorID: string | null | undefined
  disciplineID: string | null | undefined
  requestingEmployeeID: string | null | undefined
  requiredDate: Date | null | undefined
  approvedRequiredDate: Date | null | undefined
  requiredUntilDate: Date | null | undefined
  areaID: string | null | undefined
  subAreaID: string | null | undefined
  siteContact: string | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  notes: string | undefined
  hasDayShift: boolean | undefined
  hasNightShift: boolean | undefined
  hasEnergizedEquipment: boolean | undefined
  requiresConfinedSpacePermit: boolean | undefined
  requiresLockoutProcedure: boolean | undefined
  assignedContractorID: string | null | undefined
  coordinatorID: string | null | undefined
  generalForemanID: string | null | undefined
  foremanID: string | null | undefined
  priority: number | undefined
  progress: number | undefined
  startDate: Date | null | undefined
  plannedWalkdownStartDate: Date | null | undefined
  plannedWorkStartDate: Date | null | undefined
  completedDate: Date | null | undefined
  isPlanned: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  costCodeID: string | null | undefined
  isClientWorkOrder: boolean | undefined
  clientWorkOrderReferenceNumber: string | undefined
  clientWorkOrderReason: string | undefined
  isChangeOrder: boolean | undefined
  changeOrderReferenceNumber: string | undefined
  changeOrderReason: string | undefined
  isRework: boolean | undefined
  reworkReferenceNumber: string | undefined
  reworkReason: string | undefined
  isServiceOrder: boolean | undefined
  serviceOrderReferenceNumber: string | undefined
  serviceOrderReason: string | undefined
  purchaseOrderID: string | null | undefined
  existingTagNumber: string | undefined
  scaffoldLatitude: number | null | undefined
  scaffoldLongitude: number | null | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderPackageListItem {
  workOrderID: string
  workPackageName: string
}
export interface WorkOrderForSchedulerGrid {
  id: string
  isUrgent: boolean
  requestNumber: string
  priority: number
  progress: number
  internalNumber: number
  scaffoldNumber: number | null
  requestType: ScaffoldRequestTypes
  requestSubType: ScaffoldRequestSubTypes
  workOrderStatus: WorkOrderStatuses
  requiredDate: Date | null
  approvedRequiredDate: Date | null
  disciplineID: string | null
  coordinatorID: string | null
  generalForemanID: string | null
  foremanID: string | null
  assignedContractorID: string | null
  areaID: string | null
  subAreaID: string | null
  clientWorkOrderReferenceNumber: string
  plannedWorkStartDate: Date | null
  plannedWalkdownStartDate: Date | null
  archivedDate: Date | null
  tagIDs: Array<string>
  currentUserPermissions: SummarizedWorkOrderPermissions
  activeRequests: number | null
  activeWorkOrders: number | null
  hasActiveDismantle: boolean
  hasDismantleConflict: boolean
}
export interface WorkOrderExtraDetailsForSchedulerGrid {
  id: string
  isUrgentDetail: string
  lastUrgentDetailChangedDate: Date | null
  lastUrgentDetailChangedBy: string | null
  workOrderStatusDetail: string
  approvalComments: string
  lastUrgentValueChangedDate: Date | null
  lastUrgentValueChangedBy: string | null
  requestSubType: ScaffoldRequestSubTypes
  requiredUntilDate: Date | null
  requestDate: Date
  requestSubmittedOn: Date | null
  requestingContractorID: string | null
  requestingEmployeeID: string | null
  requestSubmitterIDs: Array<string>
  requestStatus: ScaffoldRequestStatuses
  lastStatusChangedDate: Date | null
  lastStatusChangedBy: string | null
  startDate: Date | null
  completedDate: Date | null
  specificWorkLocation: string
  detailedWorkDescription: string
  notes: string
  isClientWorkOrder: boolean
  clientWorkOrderReferenceNumber: string
  isChangeOrder: boolean
  changeOrderReferenceNumber: string
  isRework: boolean
  reworkReferenceNumber: string
  isServiceOrder: boolean
  serviceOrderReferenceNumber: string
  purchaseOrderID: string | null
  existingTagNumber: string
  siteContact: string
  isPlanned: boolean
}
export interface WalkdownExtraDetailsForSchedulerGrid {
  id: string
  isHardBarricade: boolean
  submittedBy: string | null
  notes: string
  crewSize: number | null
  modificationHours: number | null
  length: number | null
  width: number | null
  height: number | null
  scaffoldBayLengthID: string | null
  scaffoldBayWidthID: string | null
  scaffoldBayHeightID: string | null
  lengthBayCount: number
  widthBayCount: number
  accessType: number | null
  deckLevels: number | null
  barricadeGates: number | null
  scaffoldTypeModifierID: string | null
  scaffoldElevationModifierID: string | null
  scaffoldHeightModifierID: string | null
  scaffoldCongestionFactorID: string | null
  internalModifierID: string | null
  hoardingModifierID: string | null
}
export interface WorkOrderPackageForSchedulerGrid {
  workOrderID: string
  workPackageName: string
}
export interface Contractor {
  id: string | undefined
  name: string | undefined
  alias: string | undefined
  description: string | undefined
  tagIDJson: string | undefined
  isScaffoldCompany: boolean | undefined
  isPaintCompany: boolean | undefined
  isMaintenanceCompany: boolean | undefined
  isInsulationCompany: boolean | undefined
  isHeatTraceCompany: boolean | undefined
  isRefractoryCompany: boolean | undefined
  isFireproofingCompany: boolean | undefined
  canActAsProxy: boolean | undefined
  tracksEmployeeTime: boolean | undefined
  allowDirectTimesheets: boolean | undefined
  allowIndirectTimesheets: boolean | undefined
  allowEquipmentTimesheets: boolean | undefined
  employeesReceivePerDiem: boolean | undefined
  automaticallyApplyWeekendPerDiem: boolean | undefined
  perDiemHoursRequirement: number | null | undefined
  includesAllDisciplines: boolean | undefined
  disciplineIDJson: string | undefined
  includesAllAreas: boolean | undefined
  areaIDJson: string | undefined
  includesAllCostCodes: boolean | undefined
  costCodeIDJson: string | undefined
  includesAllWorkTypes: boolean | undefined
  workTypeIDJson: string | undefined
  employeeOvertimeHoursThreshold: number | null | undefined
  employeeDoubletimeHoursThreshold: number | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Project {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  productivity: number | null | undefined
  labourRate: number | null | undefined
  ownerID: string | undefined
  regionID: string | null | undefined
  includesAllParts: boolean | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PartWithYardBalance {
  countsByYardID: { [key: string]: number }
  id: string | undefined
  countSheetGroupID: string | null | undefined
  countSheetGroupDisplayOrder: number | null | undefined
  publicID: string | undefined
  name: string | undefined
  description: string | undefined
  longDescription: string | undefined
  weight: number | null | undefined
  mpp: number | null | undefined
  cleatingMPP: number | null | undefined
  lashingMPP: number | null | undefined
  carpentryMPP: number | null | undefined
  otherMPP: number | null | undefined
  rentalRate: number | null | undefined
  costUsed: number | null | undefined
  costNew: number | null | undefined
  designation: string | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderToPrint {
  internalNumber: number | undefined
  priority: number | undefined
  isPlanned: boolean | undefined
  isUrgent: boolean | undefined
  isUrgentDetail: string | undefined
  coordinatorName: string | undefined
  generalForemanName: string | undefined
  foremanName: string | undefined
  workOrderStatusName: string | undefined
  areaName: string | undefined
  subAreaName: string | undefined
  disciplineName: string | undefined
  requestingEmployeeName: string | undefined
  scaffoldNumber: number | null | undefined
  requiredDate: Date | null | undefined
  startDate: Date | null | undefined
  requestTypeName: string | undefined
  requestSubTypeName: string | undefined
  workPackages: Array<WorkPackage> | undefined
  specificWorkLocation: string | undefined
  detailedWorkDescription: string | undefined
  requestSubmittedOn: Date | null | undefined
  requestSubmitterName: string | undefined
  siteContact: string | undefined
}
export interface WalkdownWithRequestDetails {
  bayLengthName: string | undefined
  lengthBayCount: number | null | undefined
  bayWidthName: string | undefined
  widthBayCount: number | null | undefined
  bayHeightName: string | undefined
  scaffoldTypeName: string | undefined
  elevationModifierName: string | undefined
  heightModifierName: string | undefined
  distanceModifierName: string | undefined
  congestionFactorName: string | undefined
  internalModifierName: string | undefined
  hoardingModifierName: string | undefined
  buildDismantleRatioName: string | undefined
  submitterName: string | undefined
  currentUserPermissions: SummarizedWalkdownPermissions
  id: string | undefined
  workOrderID: string | null | undefined
  scaffoldRequestID: string | null | undefined
  walkdownStatus: WalkdownStatuses | undefined
  notes: string | undefined
  startedOn: Date | null | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  approvedBy: string | null | undefined
  approvalComments: string | undefined
  approvalChangedDate: Date | null | undefined
  length: number | null | undefined
  width: number | null | undefined
  height: number | null | undefined
  scaffoldBayLengthID: string | null | undefined
  scaffoldBayWidthID: string | null | undefined
  scaffoldBayHeightID: string | null | undefined
  deckLevels: number | null | undefined
  accessType: number | null | undefined
  scaffoldTypeModifierID: string | null | undefined
  scaffoldDistanceModifierID: string | null | undefined
  scaffoldElevationModifierID: string | null | undefined
  scaffoldHeightModifierID: string | null | undefined
  buildDismantleRatioID: string | null | undefined
  scaffoldCongestionFactorID: string | null | undefined
  internalModifierID: string | null | undefined
  hoardingModifierID: string | null | undefined
  isHardBarricade: boolean | undefined
  barricadeGates: number | null | undefined
  vlfChangeAmount: number | null | undefined
  crewSize: number | null | undefined
  workDays: number | null | undefined
  modificationHours: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface SummaryTimesheetRow {
  employeeName: string
  employeeCode: string
  employeeBadge: string
  employeeClassification: string
  workOrder: string
  areaName: string
  subAreaName: string
  hoursTotalByWorkSubTypeID: { [key: string]: number }
  total: string
  perDiem: boolean | null
  equipmentDays: number | null
  equipmentQuantity: number | null
}
export interface LaborTimesheetRow {
  employeeName: string
  employeeCode: string
  employeeBadge: string
  employeeClassification: string
  workOrder: string
  areaName: string
  subAreaName: string
  hoursTotalByWorkSubTypeID: { [key: string]: number }
  total: string
  perDiem: boolean | null
  equipmentDays: number | null
  equipmentQuantity: number | null
}
export interface ScaffoldLogRow {
  tagNumber: number | null
  dayStanding: number | null
  area: string
  subArea: string
  location: string
  scaffoldContractor: string
  plannedErectDate: Date | null
  actualErectDate: Date | null
  plannedDismantleDate: Date | null
}
export interface PeopleRow {
  isActivated: boolean | null
  lastName: string
  firstName: string
  contractorID: string | null
  contractorName: string
  employeeNumber: string
  badgeNumber: string
  isForeman: boolean | null
  isGeneralForeman: boolean | null
  isCoordinator: boolean | null
  isPlanner: boolean | null
  isTimeManager: boolean | null
  canSignIn: boolean | null
}
export interface MaterialTransferRow {
  date: Date | null
  transferNumber: string
  transferType: TransferDirection | null
  yardFrom: string
  yardTo: string
  scaffoldFrom: number | null
  scaffoldTo: number | null
  transferFrom: string
  transferTo: string
  name: string
  description: string
  count: number | null
  assignedCount: number | null
  totalCount: number | null
  fromLabel: string
  toLabel: string
}
export interface ScaffoldWithInspectionDetails {
  id: string | null
  daysStanding: number | null
  areaID: string | null
  subAreaID: string | null
  contractorID: string | null
  specificWorkLocation: string
  scaffoldNumber: number | null
  inspectorID: string | null
  lastInspectionDateTime: Date | null
  lastInspectionResult: string
}
export interface ScaffoldInspectionWithHistoryData {
  id: string
  scaffoldID: string
  inspectionDateTime: Date
  inspectedBy: string
  inspectionDidPass: boolean
  inspectionDay: string
  scaffoldNumber: number | null
  timeSegmentName: string
  inspectionResult: string
}
export interface MaterialOrderForReport {
  supplierID: string | null
  parts: Array<MaterialOrderPartForReport>
  attention: string
  specialInstructions: string
  number: string
  date: string
  requiredDate: string
  purchaseOrder: string
  totalPieces: string
  totalWeight: string
}
export interface ContractorClassificationWithName {
  name: string
  contractorID: string | undefined
  classificationID: string | undefined
  regularRate: number | null | undefined
  overtimeRate: number | null | undefined
  doubleTimeRate: number | null | undefined
}
export interface ContractorEquipmentClassificationWithName {
  name: string
  contractorID: string | undefined
  equipmentClassificationID: string | undefined
  costCodeID: string | null | undefined
  dailyRate: number | null | undefined
  weeklyRate: number | null | undefined
  monthlyRate: number | null | undefined
}
export interface ContractorWorkSubTypeWithDetails {
  name: string
  workTypeID: string | null
  contractorID: string | undefined
  workSubTypeID: string | undefined
}
export interface CountSheetPartWithDetails {
  name: string
  description: string
  publicID: string
  countSheetGroupDisplayOrder: number | null
  countSheetGroupName: string
  countSheetGroupOrder: number | null
  standingScaffoldPartCount: number | null
  countSheetID: string | undefined
  partID: string | undefined
  currentAssignedCount: number | null | undefined
  addCount: number | undefined
  addCountOverride: number | null | undefined
  removeCount: number | undefined
  removeCountOverride: number | null | undefined
  overridden: boolean | undefined
  rejected: boolean | undefined
  rejectedReason: string | undefined
}
export interface SummarizedCountSheetPermissions {
  canEditAnything: boolean
  canOverrideCountSheetValues: boolean
  canApprove: boolean
  canCancel: boolean
}
export enum CountSheetType {
  Individual = 0,
  RemoveAll = 1,
  NotApplicable = 2,
}
export enum CountSheetReviewStatus {
  Draft = 0,
  Pending = 1,
  Declined = 2,
  Approved = 3,
  Cancelled = 4,
}
export interface CrewEmployeeWithName {
  name: string
  crewID: string | undefined
  employeeID: string | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface EquipmentClassificationLogWithDetails {
  classificationName: string | undefined
  changedByName: string | undefined
  id: string | undefined
  equipmentID: string | undefined
  equipmentClassificationID: string | null | undefined
  changed: Date | undefined
  changeContext: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface FleetEquipmentWithName {
  name: string
  fleetID: string | undefined
  equipmentID: string | undefined
  order: number | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export enum PartGenerationType {
  LWH = 1,
  Designed = 2,
  Modify = 3,
}
export enum ScaffoldType {
  None = 0,
  Independent = 1,
  Barricade = 2,
  BirdCage = 3,
  Specialty = 4,
  Access = 5,
}
export enum ScaffoldSubType {
  None = 0,
  Independent00_10M = 10,
  Independent10_30M = 11,
  Independent30_50M = 12,
  Independent50PlusM = 13,
  IndependentTower = 14,
  BarricadeSingleRail = 20,
  BarricadeDoubleRail = 21,
  BarricadeTripleRail = 22,
  Birdcage000_050M = 30,
  Birdcage050_200M = 31,
  Birdcage200_500M = 32,
  Birdcage500PlusM = 33,
  SpecialtyCantilever = 40,
  SpecialtySuspended = 41,
  SpecialtyBridgingSpan = 42,
  SpecialtyRamp = 43,
  SpecialtyPlatform = 44,
  SpecialtyShed = 45,
  AccessLadder = 50,
  AccessStairTower = 51,
}
export enum JobStatuses {
  Draft = 0,
  OnHold = 1,
  Ready = 2,
  Released = 3,
  Cancelled = 4,
  Completed = 5,
}
export enum ScaffoldRequestTypes {
  Erect = 1,
  Dismantle = 2,
  Modify = 3,
  Maintenance = 4,
  Paint = 5,
  Insulation = 6,
  HeatTrace = 7,
  Refractory = 8,
  Fireproofing = 9,
}
export enum ScaffoldRequestSubTypes {
  ErectScaffold = 1,
  ErectHardBarricade = 2,
  DismantleFull = 3,
  ModifyAdjustmentOnly = 4,
  ModifyMaterialAdded = 5,
  ModifyMaterialRemoved = 6,
  ModifyModify = 7,
  MaintenanceMaintenance = 8,
  PaintPaint = 9,
  InsulationInsulation = 10,
  InsulationPipe = 11,
  InsulationEquipment = 12,
  MaintenanceCarpentry = 40,
  MaintenanceJanitorial = 41,
  MaintenanceLabour = 42,
  MaintenanceOperations = 43,
  MaintenancePlumbing = 44,
  MaintenancePurchasing = 45,
  HeatTraceHeatTrace = 70,
  RefractoryRefractory = 80,
  FireproofingFireproofing = 90,
}
export interface LEMStatusLogWithDetails {
  changedByName: string
  id: string | undefined
  lemID: string | undefined
  lemStatusID: LemStatus | undefined
  comments: string | undefined
  changed: Date | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface SummarizedLEMPermissions {
  canEditAnything: boolean
  canSubmit: boolean
  canApprove: boolean
  canCancel: boolean
}
export enum LemStatus {
  New = 1,
  Submitted = 2,
  Approved = 3,
  Declined = 4,
  Cancelled = 5,
}
export interface AreaWithSubAreas {
  subAreas: Array<ProjectLocation>
  id: string | undefined
  name: string | undefined
  description: string | undefined
  projectID: string | null | undefined
  parentLocationID: string | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface BackOrderPartOrderDetails {
  materialOrderID: string
  materialOrderNumber: number
  materialOrderDate: Date
  outstandingCount: number
}
export interface MaterialOrderPartWithDetails {
  name: string
  description: string
  publicID: string
  outstanding: number
  fulfilled: number
  countSheetGroupDisplayOrder: number | null
  countSheetGroupID: string | null
  materialOrderID: string | undefined
  partID: string | undefined
  count: number | undefined
  isOverridden: boolean | undefined
  originalCount: number | null | undefined
}
export enum MaterialOrderDirection {
  MaterialRequest = 0,
  MaterialReturn = 1,
}
export enum MaterialOrderStatus {
  New = 0,
  Submitted = 1,
  Declined = 2,
  Approved = 3,
  PartiallyFulfilled = 4,
  Fulfilled = 5,
  Cancelled = 6,
  Abandoned = 7,
}
export interface TransferPartWithDetails {
  name: string
  description: string
  publicID: string
  countSheetGroupDisplayOrder: number | null
  countSheetGroupID: string | null
  transferID: string | undefined
  partID: string | undefined
  count: number | undefined
  currentAssignedCount: number | null | undefined
  isOverridden: boolean | undefined
  originalCount: number | null | undefined
}
export enum TransferDirection {
  ScaffoldDelivery = 0,
  ScaffoldReturn = 1,
  YardTransfer = 2,
  SupplierRequest = 3,
  SupplierReturn = 4,
}
export interface Transfer {
  id: string | undefined
  internalNumber: number | undefined
  transferDate: Date | undefined
  workOrderID: string | null | undefined
  transactionNumber: string | undefined
  fromSupplierID: string | null | undefined
  toSupplierID: string | null | undefined
  fromYardID: string | null | undefined
  toYardID: string | null | undefined
  fromScaffoldID: string | null | undefined
  toScaffoldID: string | null | undefined
  transferTypeID: TransferType | undefined
  transferContextID: string | null | undefined
  isAutomaticReturnToSource: boolean | undefined
  sourceToNewDestinationTransferID: string | null | undefined
  reversedByTransferID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface SummarizedTransferPermissions {
  canEditAnything: boolean
  canOverrideTransferValues: boolean
}
export enum TransferType {
  DeliveryReturnYardTransfer = 0,
  ScaffoldTransfer = 1,
  ScaffoldMerge = 2,
  CountSheet = 3,
  Reversal = 4,
  MaterialOrder = 5,
}
export enum NotificationSeverity {
  Info = 1,
  Warning = 10,
  Critical = 100,
}
export interface PersonClassificationLogWithDetails {
  classificationName: string | undefined
  changedByName: string | undefined
  id: string | undefined
  personID: string | undefined
  classificationID: string | null | undefined
  changed: Date | undefined
  changeContext: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PersonalEmailAddressWithVerified {
  id: string | undefined
  emailAddress: string | undefined
  isVerified: boolean | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
}
export interface PersonalPhoneNumberWithVerified {
  id: string | undefined
  phoneNumber: string | undefined
  type: string | undefined
  isVerified: boolean | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
}
export interface SummarizedPersonPermissions {
  canEditAnything: boolean
  canEditDetails: boolean
  canEditContactInformation: boolean
  canEditProjects: boolean
  canEditContractor: boolean
  canEditDisciplines: boolean
  canEditAreas: boolean
  canEditSecurity: boolean
  canEditAttachments: boolean
  canDelete: boolean
}
export enum ScaffoldStatuses {
  Designed = 0,
  Estimated = 1,
  Erecting = 2,
  Erected = 3,
  Dismantling = 4,
  Dismantled = 5,
  Completed = 6,
  Cancelled = 7,
}
export interface SummarizedScaffoldDesignPermissions {
  canCancel: boolean
  canEditDetails: boolean
  canViewEstimateDetails: boolean
  canEditEstimateDetails: boolean
  canRelease: boolean
  canEditAnything: boolean
}
export enum ScaffoldDesignStatus {
  New = 0,
  Started = 1,
  OnHold = 2,
  Submitted = 3,
  Reviewed = 4,
  Declined = 5,
  Released = 6,
  Cancelled = 7,
}
export enum InspectionStatus {
  Pending = 0,
  Passed = 1,
  Failed = 2,
}
export interface ScaffoldInspectionWithQuestions {
  questionAnswers: Array<QuestionWithAnswer> | undefined
  tagIDs: Array<string> | undefined | null
  id: string | undefined
  scaffoldID: string | undefined
  questionAnswersJson: string | undefined
  additionalInformation: string | undefined
  inspectionDateTime: Date | undefined
  inspectedBy: string | undefined
  inspectionDidPass: boolean | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface ScaffoldInspection {
  id: string | undefined
  scaffoldID: string | undefined
  questionAnswersJson: string | undefined
  additionalInformation: string | undefined
  inspectionDateTime: Date | undefined
  inspectedBy: string | undefined
  inspectionDidPass: boolean | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
  tagIDJson: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface QuestionWithAnswer {
  id: string | null
  displayOrder: number | null
  answer: string
  language: string
  textByLanguageCode: { [key: string]: string }
}
export enum ScaffoldRequestStatuses {
  Draft = 1,
  Submitted = 2,
  Approved = 3,
  Declined = 4,
  Cancelled = 5,
  Completed = 6,
}
export enum WorkOrderStatuses {
  Draft = 1,
  Submitted = 2,
  Approved = 3,
  Declined = 4,
  Walkdown = 5,
  Estimated = 6,
  InScheduling = 7,
  OnHold = 8,
  Started = 9,
  Completed = 10,
  Cancelled = 11,
  Archived = 12,
  CompletionPendingAdministration = 13,
}
export interface SummarizedScaffoldRequestPermissions {
  canUpdateApproval: boolean
  canEditAssignedContractor: boolean
  canCancel: boolean
  canEditAnything: boolean
}
export interface CountSheet {
  id: string | undefined
  workOrderID: string | null | undefined
  countSheetTypeID: CountSheetType | undefined
  reviewStatusID: CountSheetReviewStatus | undefined
  submittedBy: string | null | undefined
  submittedOn: Date | null | undefined
  reviewDeclineReason: string | undefined
  reviewCancelReason: string | undefined
  reviewStatusChangeTime: Date | undefined
  reviewApprovalIsAutomatic: boolean | undefined
  reviewApprovedBy: string | null | undefined
  approvedFromYardID: string | null | undefined
  approvedToYardID: string | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface WorkOrderUrgentLogWithDetails {
  changedByName: string
  id: string | undefined
  workOrderID: string | undefined
  isUrgent: boolean | null | undefined
  isUrgentDetail: string | undefined
  changed: Date | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface SummarizedWorkOrderPermissions {
  canEditAnything: boolean
  canEditArea: boolean
  canEditSiteContact: boolean
  canEditLocation: boolean
  canEditWorkDescription: boolean
  canEditPriority: boolean
  canEditProgress: boolean
  canEditStatus: boolean
  canCancel: boolean
  canEditRequiredDate: boolean
  canPushToToDoList: boolean
  canEditAssignedContractor: boolean
  canEditAssignedCoordinator: boolean
  canEditAssignedGeneralForeman: boolean
  canEditAssignedForeman: boolean
  canEditWorkPackages: boolean
  canViewEstimateDetails: boolean
  canEditEstimateDetails: boolean
  canEditBuildSheetResponses: boolean
}
export interface ScaffoldVerticalLengthFeetLogWithDetails {
  workOrderNumber: string
  id: string | undefined
  workOrderID: string | undefined
  scaffoldID: string | undefined
  changeAmount: number | null | undefined
  currentTotalVLF: number | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
}
export interface SummarizedScaffoldPermissions {
  canEditAnything: boolean
  canEditScaffoldStatus: boolean
  canEditScaffoldErectDate: boolean
  canEditScaffoldDismantleDate: boolean
}
export interface ScaffoldRequestResult {
  id: string | null
  internalRequestNumber: string
  workOrderNumber: number | null
  scaffoldNumber: number | null
  requestingContractorName: string
  requestorFirstName: string
  requestorLastName: string
  requestStatusName: string
  tagIDs: Array<string>
  requestType: ScaffoldRequestTypes | null
  requestSubType: ScaffoldRequestSubTypes | null
}
export interface ScaffoldResult {
  id: string | null
  internalNumber: number
  statusName: string
  actualErectDate: string
  actualDismantleDate: string
  externalReferenceNumber: string
  tagIDs: Array<string>
}
export interface PersonResult {
  id: string | null
  firstName: string
  lastName: string
  contractorName: string
  employeeCode: string
  employeeBadge: string
  tagIDs: Array<string>
  isArchived: boolean | null
}
export interface WorkPackageResult {
  id: string | null
  name: string
  activityID: string
  startDate: string
  finishDate: string
  workStatus: string
  completionPercentage: number | null
}
export interface WorkOrderResult {
  id: string | null
  internalNumber: number | null
  scaffoldNumber: number | null
  startDate: string
  completedDate: string
  assignedContractorName: string
  clientWorkOrderReferenceNumber: string
  workOrderStatusID: WorkOrderStatuses | null
  progress: number | null
  scaffoldRequestType: ScaffoldRequestTypes | null
}
export interface CountSheetResult {
  id: string | null
  workOrderNumber: number | null
  scaffoldNumber: number | null
  countSheetTypeID: CountSheetType | null
  submittedBy: string
  submittedDate: string
  reviewStatusID: CountSheetReviewStatus | null
  reviewStatus: string
  declineReason: string
  cancelReason: string
  lastStatusChangeDate: string
  approvedBy: string
}
export interface DayTimeSummary {
  date: Date
  regularTime: number
  overTime: number
  doubleTime: number
  totalTime: number
  perDiemCount: number
  equipmentHours: number
}
export interface TimesheetStatusLogWithDetails {
  changedByName: string
  id: string | undefined
  timesheetID: string | undefined
  timesheetStatusID: TimesheetStatus | undefined
  comments: string | undefined
  correctionMade: boolean | undefined
  changed: Date | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface TimesheetExplanationWithWorkOrderDetails {
  workOrderNumber: string
  id: string | undefined
  timesheetID: string | undefined
  workOrderID: string | null | undefined
  workSubTypeID: string | null | undefined
  explanation: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface SummarizedTimesheetPermissions {
  canAddNewEntries: boolean
  canEditExistingEntries: boolean
  canRemoveExistingEntries: boolean
  canEditWorkType: boolean
  canViewCostCode: boolean
  canEditCostCode: boolean
  canSubmit: boolean
  canMakeCorrections: boolean
  canApprove: boolean
  canOverrideSubmittedTimesheetValues: boolean
  canCancel: boolean
}
export interface WorkSubTypeTimeSummary {
  workSubTypeID: string | null
  totalRegularTime: number
  totalDoubleTime: number
  totalOverTime: number
  totalUnits: number
}
export interface LemsListTimesheetDetails {
  id: string
  creatorID: string | null
  ownerID: string | null
  timesheetNumber: number
  timesheetTypeID: TimesheetType
  entryCount: number | null
  totalRegularTime: number | null
  totalOverTime: number | null
  totalDoubleTime: number | null
  totalUnits: number | null
  totalDays: number | null
  totalQuantity: number | null
}
export enum WalkdownStatuses {
  Pending = 1,
  Draft = 2,
  Submitted = 3,
  Declined = 4,
  Approved = 5,
  Cancelled = 6,
}
export enum YardType {
  OnRent = 1,
  OffRent = 2,
  Corrections = 3,
  Distribution = 4,
  Damaged = 5,
}
export interface SummarizedWalkdownPermissions {
  canPerformWalkdown: boolean
  canPerformToDoListWalkdown: boolean
  canUnassignWalkdown: boolean
  canEditAnything: boolean
  canEditStatus: boolean
  canCancel: boolean
}
export interface MaterialOrderPartForReport {
  name: string
  description: string
  quantity: string
  weight: string
}
