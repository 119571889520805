import FDVue from "@fd/lib/vue";
import BuildSheetFormBase from "./SP.BuildSheetFormBase";

export default FDVue.extend({
  name: "sp-build-sheet-form-insulation",
  mixins: [BuildSheetFormBase],
  components: {},
  props: {
    exchangerTypes: { type: Array },
    exchangerTypeID: { type: String },
    finishTypes: { type: Array },
    finishTypeID: { type: String },
    insulationTypes: { type: Array },
    insulationTypeID: { type: String },
    metalFinishSizes: { type: Array },
    metalFinishSizeID: { type: String },
    metalTypes: { type: Array },
    metalTypeID: { type: String },
    serviceTypes: { type: Array },
    serviceTypeID: { type: String },
    tankTypes: { type: Array },
    tankTypeID: { type: String },

    equipmentNumber: { type: String },
    insulationTarget: { type: String },
    tempType: { type: String },
    temperature: { type: Number },
    thickness: { type: String },
    layers: { type: String },
    linearFeet: { type: String },
    blanketsOrTestPlug: { type: String }
  },
  data: function() {
    return {};
  },
  computed: {
    insulationTargetValue: {
      get(): string {
        return this.insulationTarget;
      },
      set(val: string) {
        this.$emit("update:insulationTarget", val);
      }
    },
    tempTypeValue: {
      get(): string {
        return this.tempType;
      },
      set(val: string) {
        this.$emit("update:tempType", val);
      }
    },
    blanketsOrTestPlugValue: {
      get(): string {
        return this.blanketsOrTestPlug;
      },
      set(val: string) {
        this.$emit("update:blanketsOrTestPlug", val);
      }
    }
  },
  watch: {},
  methods: {}
});
