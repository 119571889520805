import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import {
  scaffoldRequestService,
  contractorService,
  ScaffoldRequestWithDetails,
  ScaffoldRequestStatuses,
  WorkOrderStatuses,
  ScaffoldRequestTypes,
  ContractorWithTags,
  workOrderService,
  WorkOrderWithAllDetails,
  reportService
} from "../services";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import * as DateUtil from "@fd/lib/client-util/datetime";
import scaffoldRequestList, {
  ScaffoldRequestWithExtraDetails,
  ParseScaffoldRequestWithExtraDetails
} from "../dataMixins/scaffoldRequestList";
import { showRequestApprovalDialog } from "./components/dialogs/SP.RequestApprovalDialog.vue";
import {
  GetDefaultVisibleSoftCraftContexts,
  SoftCraftFilteringContext,
  softCraftFiltering
} from "../dataMixins/softCrafts";
import downloadBlob from "../../../lib/client-util/downloadBlob";
import printBlob from "../../../lib/client-util/printBlob";
import { valueInArray } from "../../../lib/client-util/array";

type FilteringStatusContext = "declined" | "requested";
type FilteringContext = SoftCraftFilteringContext & {
  statusContext: FilteringStatusContext;
};

export default FDVue.extend({
  name: "fd-scaffold-request-approvals-list",

  mixins: [scaffoldRequestList, rules, softCraftFiltering],

  components: {
    "sp-inline-scope-icons": () => import("./components/SP.InlineScopeIcons.vue"),
    "sp-soft-craft-filter-selector": () => import("./components/SP.SoftCraftFilterSelector.vue")
  },

  data: function() {
    return {
      allowAllSelection: false,
      approving: false,
      declining: false,
      cancelling: false
    };
  },

  computed: {
    canFilterRequestType(): boolean {
      return (
        !!this.curUserAccess.canViewMaintenanceJobs ||
        !!this.curUserAccess.canViewPaintJobs ||
        !!this.curUserAccess.canViewInsulationJobs ||
        !!this.curUserAccess.canViewHeatTraceJobs ||
        !!this.curUserAccess.canViewRefractoryJobs ||
        !!this.curUserAccess.canViewFireproofingJobs
      );
    },
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.xs) {
        return this.showArchived ? 5 : 4;
      } else if (this.$vuetify.breakpoint.sm) {
        return this.showArchived ? 11 : 10;
      } else if (this.$vuetify.breakpoint.md) {
        return this.showArchived ? 14 : 13;
      }

      return 18;
    },

    requestedCount(): string {
      var count =
        this.allScaffoldRequests?.filter(x => x.workOrderStatus == WorkOrderStatuses.Submitted)
          ?.length ?? 0;
      return count < 100 ? `${count}` : "99+";
    },

    declinedCount(): string {
      var count =
        this.allScaffoldRequests?.filter(x => x.workOrderStatus == WorkOrderStatuses.Declined)
          ?.length ?? 0;
      return count < 100 ? `${count}` : "99+";
    },

    selectedViewType: {
      get(): FilteringStatusContext {
        return this.filteringContext.statusContext;
      },
      set(val: FilteringStatusContext) {
        let context = this.filteringContext;
        context.statusContext = val;
        this.filteringContext = context;
      }
    },

    scaffoldRequests(): ScaffoldRequestWithExtraDetails[] {
      var selectedRequests = this.filteredScaffoldRequests;
      if (this.selectedViewType == "requested") {
        selectedRequests = selectedRequests.filter(
          x => x.workOrderStatus == WorkOrderStatuses.Submitted
        );
      } else if (this.selectedViewType == "declined") {
        selectedRequests = selectedRequests.filter(
          x => x.workOrderStatus == WorkOrderStatuses.Declined
        );
      }
      if (!this.showScaffoldRequests) {
        selectedRequests = selectedRequests.filter(
          r =>
            r.requestType != ScaffoldRequestTypes.Erect &&
            r.requestType != ScaffoldRequestTypes.Modify &&
            r.requestType != ScaffoldRequestTypes.Dismantle
        );
      }
      if (!this.showMaintenanceRequests) {
        selectedRequests = selectedRequests.filter(
          r => r.requestType != ScaffoldRequestTypes.Maintenance
        );
      }
      if (!this.showPaintRequests) {
        selectedRequests = selectedRequests.filter(
          r => r.requestType != ScaffoldRequestTypes.Paint
        );
      }
      if (!this.showInsulationRequests) {
        selectedRequests = selectedRequests.filter(
          r => r.requestType != ScaffoldRequestTypes.Insulation
        );
      }
      if (!this.showHeatTraceRequests) {
        selectedRequests = selectedRequests.filter(
          r => r.requestType != ScaffoldRequestTypes.HeatTrace
        );
      }
      if (!this.showRefractoryRequests) {
        selectedRequests = selectedRequests.filter(
          r => r.requestType != ScaffoldRequestTypes.Refractory
        );
      }
      if (!this.showFireproofingRequests) {
        selectedRequests = selectedRequests.filter(
          r => r.requestType != ScaffoldRequestTypes.Fireproofing
        );
      }
      return selectedRequests;
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    requestCanBeCancelled(item: ScaffoldRequestWithExtraDetails): boolean {
      return item.currentUserPermissions.canCancel;
    },

    requestCanBeApproved(item: ScaffoldRequestWithExtraDetails): boolean {
      return (
        item.scaffoldRequestStatus == ScaffoldRequestStatuses.Submitted &&
        item.currentUserPermissions.canUpdateApproval
      );
    },

    requestCanBeDeclined(item: ScaffoldRequestWithExtraDetails): boolean {
      return (
        item.scaffoldRequestStatus == ScaffoldRequestStatuses.Submitted &&
        item.currentUserPermissions.canUpdateApproval
      );
    },

    /*** NAVIGATION ***/
    async loadRequests() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      let scaffoldRequests = await scaffoldRequestService.getApprovalsWithDetails(
        this.showArchived,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      this.allScaffoldRequests = scaffoldRequests.map(x => ParseScaffoldRequestWithExtraDetails(x));
      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    // *** ACTIONS ***
    async cancelRequest(request: ScaffoldRequestWithDetails) {
      this.processing = true;
      this.cancelling = true;
      try {
        // get reason
        var title = this.$t("scaffold-request-approvals.cancel-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.cancelling = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.cancelScaffoldRequest(request.id!, reason);

        await this.loadRequests();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.cancel-success", [
            request.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.cancelling = false;
        this.processing = false;
      }
    },

    async approveRequest(request: ScaffoldRequestWithExtraDetails) {
      if (this.scaffoldRequestScaffoldHasOtherDismantleWork(request)) {
        let proceed = await this.openWorkDetailsForScaffoldDialog(request, true);
        if (!proceed) return;
      }
      this.processing = true;
      this.approving = true;
      try {
        var allContractors = await contractorService.getAll(false, null, null);
        var assignableContractors = [] as ContractorWithTags[];
        if (
          request.requestType == ScaffoldRequestTypes.Erect ||
          request.requestType == ScaffoldRequestTypes.Dismantle ||
          request.requestType == ScaffoldRequestTypes.Modify
        ) {
          assignableContractors = allContractors.filter(x => !!x.isScaffoldCompany);
        } else if (request.requestType == ScaffoldRequestTypes.Paint) {
          assignableContractors = allContractors.filter(x => !!x.isPaintCompany);
        } else if (request.requestType == ScaffoldRequestTypes.Insulation) {
          assignableContractors = allContractors.filter(x => !!x.isInsulationCompany);
        } else if (request.requestType == ScaffoldRequestTypes.HeatTrace) {
          assignableContractors = allContractors.filter(x => !!x.isHeatTraceCompany);
        } else if (request.requestType == ScaffoldRequestTypes.Refractory) {
          assignableContractors = allContractors.filter(x => !!x.isRefractoryCompany);
        } else if (request.requestType == ScaffoldRequestTypes.Fireproofing) {
          assignableContractors = allContractors.filter(x => !!x.isFireproofingCompany);
        } else if (request.requestType == ScaffoldRequestTypes.Maintenance) {
          assignableContractors = allContractors.filter(x => !!x.isMaintenanceCompany);
        }

        var requestApprovalDetails = await showRequestApprovalDialog(
          request.internalRequestNumber,
          assignableContractors
        );

        // If details is undefined the dialog was cancelled
        if (!requestApprovalDetails?.contractorID?.length) {
          // Change the value to something else and then back to its current to force a rebind
          this.approving = false;
          this.processing = false;
          return false;
        }

        let newWorkOrderID = await scaffoldRequestService.updateScaffoldRequestApproval(
          request.id!,
          true,
          requestApprovalDetails.contractorID,
          ""
        );

        if (!!newWorkOrderID && !!requestApprovalDetails?.isUrgent) {
          await workOrderService.updateItem(
            newWorkOrderID,
            {
              id: newWorkOrderID,
              isUrgent: true,
              isUrgentDetail: requestApprovalDetails.isUrgentDetail
            } as WorkOrderWithAllDetails,
            "ScaffoldRequestApprovals.approveRequest - Setting isUrgent"
          );
        }

        await this.loadRequests();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.approve-success", [
            request.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },

    async declineRequest(request: ScaffoldRequestWithDetails) {
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("scaffold-request-approvals.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.declining = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.updateScaffoldRequestApproval(
          request.id!,
          false,
          null,
          reason
        );

        await this.loadRequests();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.decline-success", [
            request.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },
    async downloadAndPrintWorkOrderAdminReport(reportType: string = "pdf") {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        var contractorFilterValue = !!this.selectedContractors?.length
          ? this.allContractors
              .filter(x => valueInArray(x.id!, this.selectedContractors))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var disciplineFilterValue = !!this.selectedDisciplines?.length
          ? this.allDisciplines
              .filter(x => valueInArray(x.id!, this.selectedDisciplines))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var areaFilterValue = !!this.selectedAreas?.length
          ? this.allAreas
              .filter(x => valueInArray(x.id!, this.selectedAreas))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var subAreaFilterValue = !!this.selectedSubAreas?.length
          ? this.allSubAreas
              .filter(x => valueInArray(x.id!, this.selectedSubAreas))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var approvalStatus = this.selectedViewType;
        var craftType = this.allSoftCraftOptionsSelected ? "All" : this.softCraftFilter.join(", ");
        var blob = await reportService.getRequestApprovalPrintoutReportContentWithData(
          this.scaffoldRequests,
          reportType,
          contractorFilterValue,
          disciplineFilterValue,
          areaFilterValue,
          subAreaFilterValue,
          approvalStatus,
          craftType,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "request-approval-printout.xlsx");
        } else {
          printBlob(blob, "request-approval-printout.pdf", "application/pdf");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var filteringContext = {
      statusContext: "requested",
      showSoftCraftRequestFilter: GetDefaultVisibleSoftCraftContexts(this.curUserAccess)
    } as FilteringContext;
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "scaffold-request-approvals",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: filteringContext
    });

    this.notifyNewBreadcrumb({
      text: this.$t("scaffold-request-approvals.list-title"),
      to: "/scaffoldrequestapprovals",
      resetHistory: true
    });

    try {
      this.processing = true;
      await Promise.all([
        this.loadRequests(),
        this.loadCurrentUserDisciplines(),
        this.loadDisciplines(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.loadContractors()
      ]);
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});
