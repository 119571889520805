// TODO: Put all mapping stuff into its own control wrapper
import FDVue from "..";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { PropType } from "vue";
const iconOptions = {
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
};
L.Icon.Default.mergeOptions(iconOptions);

export type FPMapLocationColour = "red" | "orange" | "blue" | "green";
export type FPMapLocationPoint = L.LatLngLiteral;
export type FPMapLocation = FPMapLocationPoint & {
  colour: FPMapLocationColour | null;
  groupedLocations?: number;
};

export type MapLayerType = "roads" | "satellite";

// #region Feet<->Degree Conversion
export function ConvertFullDegreesToDecimalDegrees(
  degrees: number,
  minutes: number,
  seconds: number
): number {
  let minutesDecimal = seconds / 60;
  let degreesDecimal = (minutes + minutesDecimal) / 60;
  return degrees + degreesDecimal;
}
const kmCircumference = 40030;
const kmPerDegree = kmCircumference / 360;
const feetPerKm = 3280.84;

export function ConvertDecimalDegreesToKilometers(degrees: number): number {
  return degrees * kmPerDegree;
}
export function ConvertKilometersToDegrees(km: number): number {
  return km / kmPerDegree;
}

export function ConvertKilometersToFeet(km: number): number {
  return km * feetPerKm;
}
export function ConvertFeetToKilometers(feet: number): number {
  return feet / feetPerKm;
}

export function ConvertDecimalDegreesToFeet(degrees: number) {
  return ConvertKilometersToFeet(ConvertDecimalDegreesToKilometers(degrees));
}
export function ConvertFeetToDegrees(feet: number) {
  return ConvertKilometersToDegrees(ConvertFeetToKilometers(feet));
}
// #endregion

const defaultMinZoom = 13,
  defaultZoom = 15,
  defaultMaxZoom = 19;

const defaultRestrictBounds = false;
const defaultBoundsSize = null as number | null;

export default FDVue.extend({
  name: "fp-map",

  props: {
    attribution: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    showComparison: { type: Boolean, default: false },
    height: { type: String, default: "400px" },
    // Updated via `mapDisplayType.sync`
    mapDisplayType: { type: String as PropType<MapLayerType> },

    // Updated via `v-model`
    value: { type: Object as PropType<FPMapLocation> },
    locations: { type: Array as PropType<Array<FPMapLocation>> },
    center: { type: Object as PropType<FPMapLocationPoint> },
    popupHtmlGenerator: { type: Function },

    restrictInBounds: { type: Boolean, default: true },
    boundsSize: { type: Number },

    minZoom: { type: Number },
    zoom: { type: Number },
    maxZoom: { type: Number }
  },

  data: function() {
    return {
      // local store the layer type value, this allows layer switching even if the control's parent doesn't have logic for it
      // For the control's parent to set this, and to have this respond to the app's store value, set the `mapDisplayType` prop
      layerType: "satellite" as MapLayerType,
      baseMap: null as L.Map | null,

      originalValue: null as FPMapLocation | null,
      originalMarker: null as L.Marker | null,
      marker: null as L.Marker | null,

      markers: [] as L.Marker[],

      didAddTileLayer: false,

      roadLayer: null as L.TileLayer | null,
      imageryLayer: null as L.TileLayer | null
    };
  },

  computed: {
    // Logic to check if the parent of the control has passed in a value.  If not, use the locally stored value
    computedLayerType: {
      get(): MapLayerType {
        if (!this.mapDisplayType?.length) return this.layerType;
        return this.mapDisplayType;
      },
      set(val: MapLayerType) {
        this.layerType = val;
        this.$emit("update:mapDisplayType", val);
        this.$nextTick(() => {
          this.updateLayerControl();
        });
      }
    }
  },

  watch: {
    value(newValue, oldValue) {
      console.log(
        `FPMap.value changed: ${JSON.stringify(oldValue)} -> ${JSON.stringify(
          newValue
        )}, originalValue: ${JSON.stringify(this.originalValue)}`
      );
      let location = this.translateLocationInBounds(newValue);
      if (location.lat != newValue.lat || location.lng != newValue.lng) {
        this.$emit("input", location);
      } else {
        this.updateLocationDisplay();
      }
    },
    locations() {
      this.setMaxBounds();
      this.updateLocationsDisplay();
    },
    zoom() {
      this.setZoom();
    },
    mapDisplayType() {
      this.$nextTick(() => {
        this.updateLayerControl();
      });
    }
  },

  methods: {
    buildMap(): L.Map {
      let map = L.map(this.$refs.mapContainer as HTMLDivElement, {
        attributionControl: this.attribution,
        minZoom: this.minZoom ?? defaultMinZoom,
        maxZoom: this.maxZoom ?? defaultMaxZoom
      });
      map.whenReady(() => {
        // console.log(`*** map ready`);
      });
      map.on("click", e => {
        this.mapClicked(e);
      });

      return map;
    },
    setZoom() {
      console.log(`FPMap setZoom`);
      if (!!this.baseMap) {
        let zoom = this.zoom ?? defaultZoom;
        console.log(`\t setting to ${zoom}`);
        this.baseMap.setZoom(zoom);
      } else {
        console.log(`\t !! SKIPPED`);
      }
    },
    addTileLayer() {
      if (!!this.didAddTileLayer || !this.baseMap) return;

      if (!this.roadLayer) {
        let url = `/services/FormidableDesigns.Services.V1.MapService.GetMapRoadTile?zoom={z}&x={x}&y={y}`;
        this.roadLayer = L.tileLayer(url, {
          attribution: `© ${new Date().getFullYear()} TomTom, Microsoft`,
          maxZoom: this.maxZoom ?? defaultMaxZoom
        } as L.TileLayerOptions);
      }
      if (!this.imageryLayer) {
        let url = `/services/FormidableDesigns.Services.V1.MapService.GetMapImageryTile?zoom={z}&x={x}&y={y}`;
        this.imageryLayer = L.tileLayer(url, {
          attribution: `© ${new Date().getFullYear()} TomTom, Microsoft`,
          maxZoom: this.maxZoom ?? defaultMaxZoom
        } as L.TileLayerOptions);
      }

      // Only display one layer at a time, add the one based on the passed-in setting
      if (this.computedLayerType == "roads") {
        this.roadLayer.addTo(this.baseMap);
      } else {
        this.imageryLayer.addTo(this.baseMap);
      }

      this.didAddTileLayer = true;
    },
    updateLayerControl() {
      if (!this.baseMap) return;

      // If the setting is updated to "roads", remove the imageryLayer and add the roads layer
      // By default, show the imagery layer
      if (this.computedLayerType == "roads") {
        this.imageryLayer!.removeFrom(this.baseMap);
        this.roadLayer!.addTo(this.baseMap);
      } else {
        this.roadLayer!.removeFrom(this.baseMap);
        this.imageryLayer!.addTo(this.baseMap);
      }
    },
    setMaxBounds() {
      if (!this.baseMap) return;

      if (!!this.locations?.length) {
        this.baseMap.setMaxBounds(this.calculateBoundsFromLocations());
        return;
      }

      let boundsSize = this.boundsSize ?? defaultBoundsSize;
      if (!boundsSize) return;

      try {
        let center = this.baseMap.getCenter();

        this.baseMap.setMaxBounds([
          [center.lat - boundsSize, center.lng - boundsSize],
          [center.lat + boundsSize, center.lng + boundsSize]
        ]);
      } catch (error) {
        // If the map doesn't have a center, calling `getCenter` throws an exception
        // If the map doesn't have a center, we can't calculate any boundaries as we don't know where the map is
      }
    },
    checkMapCenter() {
      if (!this.baseMap) return;

      let location = undefined;
      if (!!this.center) location = this.center;
      else if (!!this.originalValue) location = this.originalValue;

      if (!!location) {
        this.baseMap.setView(location);
        this.setMaxBounds();
      }
    },
    translateLocationInBounds(l: FPMapLocationPoint): FPMapLocationPoint {
      let boundsSize = this.boundsSize ?? defaultBoundsSize;
      if (!boundsSize) {
        console.log(`\t No bounds size specified.  Return location.`);
        return l;
      }

      let restrictInBounds =
        this.restrictInBounds === undefined || this.restrictInBounds === null
          ? defaultRestrictBounds
          : this.restrictInBounds;
      if (restrictInBounds == false) {
        console.log(`\t Not restricted to bounds.  Return location.`);
        return l;
      }
      if (!this.baseMap) {
        console.log(`\t No map.  Return location.`);
        return l;
      }
      let bounds: L.LatLngBounds | undefined;
      try {
        console.log(`\t Get Bounds`);
        bounds = this.baseMap.getBounds();
      } catch (error) {}
      if (!bounds) {
        console.log(`\t Get bounds failed.  Return location.`);
        return l;
      }

      let location = l;
      if (!!bounds && !bounds.contains(location)) {
        console.log(`\t\t Not in bounds.  Recalculate location.`);
        let min = bounds.getSouthWest();
        let max = bounds.getNorthEast();
        if (location.lat < min.lat) location.lat = min.lat;
        if (location.lat > max.lat) location.lat = max.lat;
        if (location.lng < min.lng) location.lng = min.lng;
        if (location.lng > max.lng) location.lng = max.lng;
      }
      console.log(`\t return location: ${JSON.stringify(location)}`);
      return location;
    },
    mapClicked(e: L.LeafletMouseEvent) {
      if (!this.baseMap || !!this.disabled || (this.locations?.length ?? 0) > 1) return;

      this.$emit("input", this.translateLocationInBounds(e.latlng));
    },
    updateOriginalLocationDisplay() {
      if (!!this.originalMarker) this.originalMarker.remove();
      if (!this.originalValue || !this.baseMap) return;

      // If `showComparison` is false, or if there are more than 1 value then we don't show the original value
      if (!this.showComparison || (this.locations?.length ?? 0) > 1) return;

      // If this map has a center, it will have had its view set to that
      // If not, the center should the original value
      if (!this.center) this.baseMap.setView(this.originalValue);

      if (!!this.originalMarker) this.originalMarker.remove();
      let myIcon = L.divIcon({
        className: "fas fa-location-crosshairs fp-map-original-location-icon",
        iconAnchor: [10, 10.5] // Change this if the font size in the css class changes
      });
      let originalMarker = L.marker(this.originalValue, {
        icon: myIcon
      }).addTo(this.baseMap);
      this.originalMarker = originalMarker;
    },
    updateLocationDisplay() {
      console.log(`FPMap.updateLocationDisplay`);
      if (!!this.marker) this.marker.remove();
      if (!this.value || !this.baseMap) return;

      if (!!this.marker) this.marker.remove();

      this.baseMap.setView(this.value);

      let marker = this.markerForLocation(this.value).addTo(this.baseMap);
      this.marker = marker;
      this.marker.on("click", () => {
        this.$emit("click:marker", marker.getLatLng());
      });
    },

    // *** MULTIPLE LOCATIONS DISPLAY ***
    removeMarkers() {
      if (!this.markers.length) return;

      this.markers.forEach(x => {
        x.remove();
      });
      this.markers = [];
    },
    markerForLocation(location: FPMapLocation) {
      let colour: FPMapLocationColour = "blue";
      if (!!location.colour?.length) colour = location.colour;

      let locationIcon = "fa-location-dot";
      if (!!location.groupedLocations && location.groupedLocations > 1) {
        locationIcon = "fa-location-plus";
      }

      let myIcon = L.divIcon({
        className: `fas ${locationIcon} fp-map-location-icon fp-map-location-${colour}`,
        iconAnchor: new L.Point(12.5, 34) // Change this if the font size in the css class changes
      });
      return L.marker(location, { icon: myIcon });
    },
    addMarker(location: FPMapLocation) {
      if (!location || !this.baseMap) return;

      let marker = this.markerForLocation(location).addTo(this.baseMap);
      if (!!this.popupHtmlGenerator) {
        marker.on("click", () => {
          this.$emit("click:marker", location);
          // Changing the focusedLocation value causes the popup to re-render, and we need to wait for that to complete before we grab its HTML content
          this.$nextTick(() => {
            let html = this.popupHtmlGenerator(location);
            if (!html) return;
            // console.log(`Location ${JSON.stringify(location)} clicked.  html: ${html}`);
            marker
              .bindPopup(html, {
                closeButton: false
              })
              .openPopup();
          });
        });
        marker.on("popupclose", () => {
          this.$emit("close:marker", location);
        });
      }
      this.markers.push(marker);
    },
    calculateBoundsFromLocations(): L.LatLngBoundsExpression {
      let minLat: number | null = null,
        maxLat: number | null = null,
        minLong: number | null = null,
        maxLong: number | null = null;

      this.locations?.forEach(x => {
        let lat = x.lat;
        if (!!lat) {
          if (minLat == null || lat < minLat) minLat = lat;
          if (maxLat == null || lat > maxLat) maxLat = lat;
        }
        let lng = x.lng;
        if (!!lng) {
          if (minLong == null || lng < minLong) minLong = lng;
          if (maxLong == null || lng > maxLong) maxLong = lng;
        }
      });

      if (!minLat) minLat = 0;
      if (!minLong) minLong = 0;
      if (!maxLat) maxLat = 0;
      if (!maxLong) maxLong = 0;

      // Provide some padding to the edge of the map to allow the user to interact with the pins properly
      let offset = 0.0015;
      let minPoint = [minLat - offset, minLong - offset] as L.LatLngTuple;
      let maxPoint = [maxLat + offset * 2, maxLong + offset * 2] as L.LatLngTuple;
      return [minPoint, maxPoint];
    },
    updateLocationsDisplay() {
      this.removeMarkers();
      if (!this.locations?.length || !this.baseMap) return;

      // If this map has a center, it will have had its view set to that
      // If not, set the bounds of the map based on the locations provided
      if (!this.center) this.baseMap.fitBounds(this.calculateBoundsFromLocations());

      this.locations.forEach(x => this.addMarker(x));
    }
  },

  mounted() {
    this.originalValue = this.value;

    this.baseMap = this.buildMap();
    this.addTileLayer();
    this.setZoom();

    this.checkMapCenter();
    if (!!this.originalValue) this.updateOriginalLocationDisplay();
    if (!!this.locations) this.updateLocationsDisplay();
    if (!!this.value) this.updateLocationDisplay();
  },

  beforeDestroy() {}
});

