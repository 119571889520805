import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { ContractorNormGroup } from "../../../services";
import { softCrafts } from "../../../dataMixins/softCrafts";

const ContractorNormGroupNewDialog = FDVue.extend({
  name: "sp-contractor-norm-group-new-dialog",

  mixins: [dialogSupport, rules, softCrafts],

  components: {},

  data: function() {
    return {
      saving: false,
      normGroup: {
        name: "",
        description: ""
      } as ContractorNormGroup
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["open"];
    }
  },

  methods: {
    async open(contractorID: string) {
      this.normGroup.contractorID = contractorID;
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        // If there's only one job enabled, the job selection tab isn't visible therefore the user can't ensure this group is visible
        // As such, automatically enable the only one craft that's visible to ensure this group doesn't disappear.
        if (this.visibleJobCount == 1) {
          if (this.showScaffoldColumn) this.normGroup.isScaffoldGroup = true;
          else if (this.showMaintenanceColumn) this.normGroup.isMaintenanceGroup = true;
          else if (this.showPaintColumn) this.normGroup.isPaintGroup = true;
          else if (this.showInsulationColumn) this.normGroup.isInsulationGroup = true;
          else if (this.showHeatTraceColumn) this.normGroup.isHeatTraceGroup = true;
          else if (this.showRefractoryColumn) this.normGroup.isRefractoryGroup = true;
          else if (this.showFireproofingColumn) this.normGroup.isFireproofingGroup = true;
        }
        await this.addContractorNormGroup({
          ...this.normGroup
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addContractorNormGroup: "ADD_CONTRACTOR_NORM_GROUP"
    })
  }
});

export default ContractorNormGroupNewDialog;

export async function createNewContractorNormGroup(contractorID: string): Promise<boolean> {
  let dialog = createDialog(ContractorNormGroupNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(contractorID);
}
