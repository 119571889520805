var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "addtimesheetentriesdialog" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [
                    _vm.processing ? "breadcrumb-processing-opacity" : "",
                    !_vm.$vuetify.breakpoint.lgAndUp
                      ? "fd-timesheet-small-font-header"
                      : ""
                  ]
                },
                [
                  _vm._v(
                    _vm._s(
                      !!_vm.currentTimesheet &&
                        !!_vm.currentTimesheet.ownerID &&
                        _vm.currentTimesheet.ownerID != _vm.curUserID
                        ? !_vm.$vuetify.breakpoint.lgAndUp
                          ? _vm.$t(
                              "timesheets.entries.timesheet-dialog-title-with-owner-small",
                              [_vm.currentTimesheet.ownerName]
                            )
                          : _vm.$t(
                              "timesheets.entries.timesheet-dialog-title-with-owner",
                              [_vm.currentTimesheet.ownerName]
                            )
                        : _vm.$t("timesheets.entries.timesheet-dialog-title")
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "font-size": "12px", "line-height": "1.5" } },
                [
                  !!_vm.workOrder.internalNumber
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-2 fd-status-chip",
                          attrs: { small: !_vm.$vuetify.breakpoint.lgAndUp }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-wo-number",
                                  [_vm.workOrder.internalNumber]
                                )
                              ) +
                              " -\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-request-type",
                                  [
                                    _vm.workOrder.requestTypeName,
                                    _vm.workOrder.requestSubTypeName
                                  ]
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.workOrder.scaffoldNumber
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-2 fd-status-chip",
                          attrs: { small: !_vm.$vuetify.breakpoint.lgAndUp }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-tag-number",
                                  [_vm.workOrder.scaffoldNumber]
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ml-0 mr-0 fd-primary-context-qualifier-background",
                  attrs: { align: "center", justify: "end" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          cy: "selectedday",
                          disabled: _vm.processing,
                          label: "",
                          items: _vm.workOrderDateList,
                          "hide-details": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function({ item }) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.text) +
                                    "\n              "
                                ),
                                !!_vm.currentTimesheet
                                  ? _c(
                                      "v-chip",
                                      {
                                        attrs: { color: "primary", small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.timesheetStatusChipText
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function({ item, on, attrs }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b({}, "v-list-item", attrs, false),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            style: _vm.dateIsToday(item.value)
                                              ? "font-weight: bold;"
                                              : "",
                                            attrs: { "no-gutters": "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.text) +
                                                "\n                    "
                                            ),
                                            _vm.dateIsToday(item.value)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "0.75rem",
                                                      color:
                                                        "rgba(0, 0, 0, 0.6)"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                       (" +
                                                        _vm._s(
                                                          _vm.$t("common.today")
                                                        ) +
                                                        ")\n                    "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        !item.hasTimesheet
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "fd-item-detail-light mt-1 pb-1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "timesheets.entries.timesheet-missing-for-day-message"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : item.disabled
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-1 pb-1" },
                                              [
                                                item.timesheetIsSubmitted
                                                  ? _c(
                                                      "fp-item-disabled-message",
                                                      {
                                                        attrs: {
                                                          message: _vm.$t(
                                                            "timesheets.entries.timesheet-for-day-not-editable-message-submitted"
                                                          )
                                                        }
                                                      }
                                                    )
                                                  : item.timesheetIsApproved
                                                  ? _c(
                                                      "fp-item-disabled-message",
                                                      {
                                                        attrs: {
                                                          message: _vm.$t(
                                                            "timesheets.entries.timesheet-for-day-not-editable-message-approved"
                                                          )
                                                        }
                                                      }
                                                    )
                                                  : item.timesheetIsCancelled
                                                  ? _c(
                                                      "fp-item-disabled-message",
                                                      {
                                                        attrs: {
                                                          message: _vm.$t(
                                                            "timesheets.entries.timesheet-for-day-not-editable-message-cancelled"
                                                          )
                                                        }
                                                      }
                                                    )
                                                  : item.disabled
                                                  ? _c(
                                                      "fp-item-disabled-message",
                                                      {
                                                        attrs: {
                                                          message: _vm.$t(
                                                            "timesheets.entries.timesheet-for-day-not-editable-message-generic"
                                                          )
                                                        }
                                                      }
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selectedDay,
                          callback: function($$v) {
                            _vm.selectedDay = $$v
                          },
                          expression: "selectedDay"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      !!_vm.currentTimesheet && _vm.currentTimesheet.isNew
                        ? _c("fd-alert", {
                            attrs: {
                              dense: true,
                              label: _vm.$t(
                                "timesheets.entries.timesheet-will-be-created-message"
                              ),
                              type: "info"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "max-height": "calc(100vh - 215px)",
                    overflow: "auto"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-3 py-0", attrs: { cols: "12" } },
                        [
                          _vm.currentTimesheetIsDeclined
                            ? _c("fd-alert", {
                                attrs: {
                                  type: "warning",
                                  label: !!_vm.currentTimesheetDeclineComments
                                    ? _vm.$t("timesheets.declined-with-reason")
                                    : _vm.$t("timesheets.declined"),
                                  comments: _vm.currentTimesheetDeclineComments
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    { ref: "addform", on: { submit: _vm.preventSubmit } },
                    [
                      !_vm.currentTimesheetIsReadonly
                        ? _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              !_vm.currentTimesheetIsReadonly
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "px-3 py-3",
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "6",
                                        lg: "5",
                                        xl: "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          class: {
                                            "d-none":
                                              _vm.currentTimesheetIsReadonly
                                          },
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass:
                                              "fd-select-with-context-actions",
                                            attrs: {
                                              "data-cy": "crews",
                                              dense: "",
                                              outlined: "",
                                              items:
                                                _vm.groupedSelectableResourceGroups,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "timesheets.entries.crew-or-fleet-label"
                                              ),
                                              clearable: "",
                                              disabled:
                                                _vm.processing ||
                                                !!_vm.selectedResourceID ||
                                                _vm.currentTimesheetIsReadonly,
                                              "hide-details": "",
                                              rules: _vm.addTimesheetRowRules()
                                                .selectedResourceGroupID
                                            },
                                            model: {
                                              value:
                                                _vm.selectedResourceGroupID,
                                              callback: function($$v) {
                                                _vm.selectedResourceGroupID = $$v
                                              },
                                              expression:
                                                "selectedResourceGroupID"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "fd-select-context-actions",
                                              attrs: { dark: false, flat: true }
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.$vuetify.breakpoint
                                                        .lgAndUp ||
                                                      _vm.processing,
                                                    top: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "new",
                                                                    fab: "",
                                                                    elevation:
                                                                      "0",
                                                                    "x-small":
                                                                      "",
                                                                    dark: "",
                                                                    disabled:
                                                                      _vm.processing ||
                                                                      _vm.currentTimesheetIsReadonly
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.addNewCrew
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-plus"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3631361034
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.entries.add-crew-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.$vuetify.breakpoint
                                                        .lgAndUp ||
                                                      _vm.processing,
                                                    top: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    dark: "",
                                                                    disabled:
                                                                      _vm.processing ||
                                                                      _vm.currentTimesheetIsReadonly ||
                                                                      !_vm.canModifySelectedCrew
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.editCrew
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-pencil"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    4064610111
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.entries.edit-crew-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.$vuetify.breakpoint
                                                        .lgAndUp ||
                                                      _vm.processing,
                                                    top: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function({ on }) {
                                                          return [
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                              ? _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "",
                                                                        dark:
                                                                          "",
                                                                        disabled:
                                                                          _vm.processing ||
                                                                          _vm.currentTimesheetIsReadonly ||
                                                                          !_vm.canModifySelectedCrew
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.deleteCrew
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-delete"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1893033845
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.entries.delete-crew-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.currentTimesheetIsReadonly
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "px-3 py-3",
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "6",
                                        lg: "5",
                                        xl: "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "fd-position-relative" },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              "data-cy": "employees",
                                              dense: "",
                                              outlined: "",
                                              items:
                                                _vm.groupedSelectableResources,
                                              "item-text": "nameWithCode",
                                              "item-value": "id",
                                              label: _vm.$t(
                                                "timesheets.entries.employee-or-equipment-label"
                                              ),
                                              clearable: "",
                                              disabled:
                                                _vm.processing ||
                                                !!_vm.selectedResourceGroupID ||
                                                _vm.currentTimesheetIsReadonly,
                                              "hide-details": "",
                                              rules: _vm.addTimesheetRowRules()
                                                .selectedResourceID
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function({
                                                    item,
                                                    on,
                                                    attrs
                                                  }) {
                                                    return [
                                                      _c(
                                                        "v-list-item",
                                                        _vm._g(
                                                          _vm._b(
                                                            {},
                                                            "v-list-item",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        item.nameWithCode
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.showMissingCostCodeMessage
                                                                ? _c(
                                                                    "fp-item-disabled-message",
                                                                    {
                                                                      attrs: {
                                                                        message: _vm.$t(
                                                                          "timesheets.entries.missing-cost-code-message"
                                                                        )
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3860282041
                                            ),
                                            model: {
                                              value: _vm.selectedResourceID,
                                              callback: function($$v) {
                                                _vm.selectedResourceID = $$v
                                              },
                                              expression: "selectedResourceID"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fd-timesheet-frozen-columns-swipe-positioning"
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src:
                                                    "/assets/img/swipe-frozen.svg",
                                                  "max-height": "30px",
                                                  height: "30px",
                                                  width: "204px",
                                                  "max-width": "204px"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.currentTimesheetIsReadonly
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "justify-space-between px-3",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "2",
                                        xl: "2"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "py-2",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fd-position-relative"
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticStyle: {
                                                    height: "40px !important"
                                                  },
                                                  attrs: {
                                                    color: "primary",
                                                    large: "",
                                                    loading: _vm.processing,
                                                    disabled: _vm.processing,
                                                    small:
                                                      _vm.$vuetify.breakpoint
                                                        .xsOnly
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addTimesheetRows()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [
                                                      _vm._v(
                                                        "\n                      fa-circle-arrow-down\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$vuetify.breakpoint
                                                          .smAndDown
                                                          ? _vm.$t(
                                                              "timesheets.entries.add-label-short"
                                                            )
                                                          : _vm.$t(
                                                              "timesheets.entries.add-label"
                                                            )
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    ref: "datatable",
                    staticClass:
                      "fd-timesheet-entries-table fixed-header v-table__overflow elevation-1 fd-man-hours-table",
                    staticStyle: {
                      "max-height": "calc(100vh - 415px)",
                      "backface-visibility": "hidden"
                    },
                    attrs: {
                      page: _vm.tablepage,
                      loading: _vm.processing,
                      "loading-text": _vm.$t("common.table-loading-message"),
                      "mobile-breakpoint": "0",
                      "items-per-page": -1,
                      "footer-props": { "items-per-page-options": [-1] },
                      "hide-default-footer": "",
                      items: _vm.currentTimesheetWorkOrderRows,
                      headers: _vm.tableHeaders,
                      "disable-sort": "",
                      "group-by": "workOrderNumber"
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.tablepage = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "group.header",
                          fn: function({ group }) {
                            return [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "fd-table-frozen-column fd-table-group-first-column",
                                  attrs: { colspan: "2" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        "no-gutters": "",
                                        align: "center"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "timesheets.existing.work-order-group-label",
                                              [group]
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.usableWorkSubTypes, function(wst) {
                                return _c(
                                  "td",
                                  {
                                    key: wst.id,
                                    staticClass:
                                      "fd-restrict-table-entry-column-width"
                                  },
                                  [
                                    _c("fd-text-field", {
                                      key: wst.id,
                                      staticClass: "fd-short-table-input",
                                      attrs: {
                                        disabled:
                                          _vm.processing ||
                                          _vm.currentTimesheetIsReadonly,
                                        outlined: false,
                                        "highlight-on-focus": ""
                                      },
                                      on: {
                                        change: v =>
                                          _vm.updateAllValues(group, wst.id, v)
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                  attrs: { colspan: 1 }
                                },
                                [_vm._v("\n               \n              ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                  attrs: { colspan: 1 }
                                },
                                [_vm._v("\n               \n              ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                  attrs: { colspan: 1 }
                                },
                                [_vm._v("\n               ")]
                              ),
                              _vm._v(" "),
                              !!_vm.perDiemSubType
                                ? _c("td", {
                                    staticClass:
                                      "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                    attrs: { colspan: 1 }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: 1 } }, [_vm._v(" ")])
                            ]
                          }
                        },
                        {
                          key: "item.employeeName",
                          fn: function({ item }) {
                            return [
                              _c("div", [_vm._v(_vm._s(item.employeeName))]),
                              _vm._v(" "),
                              !!item.employeeCode
                                ? _c("div", { staticClass: "fd-item-detail" }, [
                                    _vm._v(_vm._s(item.employeeCode))
                                  ])
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "item.classificationDisplayName",
                          fn: function({ item }) {
                            return [
                              _vm.classificationsForRow(item).length > 1
                                ? _c("v-select", {
                                    attrs: {
                                      value: item.classificationID,
                                      items: _vm.classificationsForRow(item),
                                      "item-text": "displayName",
                                      "item-value": "id",
                                      "return-object": ""
                                    },
                                    on: {
                                      change: v =>
                                        _vm.classificationSelected(item, v)
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function() {
                                            return [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    item.classificationDisplayName
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : _c("fd-value-display", {
                                    attrs: {
                                      value: item.classificationDisplayName,
                                      "no-value-text": _vm.$t(
                                        "common.not-available"
                                      )
                                    }
                                  })
                            ]
                          }
                        },
                        {
                          key: "item.equipmentCostCodeDisplayName",
                          fn: function({ item }) {
                            return [
                              _c("fd-value-display", {
                                attrs: {
                                  value: item.equipmentCostCodeDisplayName,
                                  "no-value-text": _vm.$t(
                                    "common.not-available"
                                  )
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.equipmentHours",
                          fn: function({ item }) {
                            return [
                              !_vm.canEditEquipment(item)
                                ? _c("fd-value-display", {
                                    attrs: {
                                      value: _vm.$format.number(
                                        item.equipmentHours
                                      ),
                                      "no-value-key":
                                        "timesheets.list.no-time-entry-value",
                                      "no-value-style": "text-align: center;"
                                    }
                                  })
                                : _c("fd-text-field", {
                                    ref: _vm.getFieldRef(
                                      "equipmentHours",
                                      item
                                    ),
                                    staticClass: "fd-short-table-input",
                                    attrs: {
                                      disabled:
                                        _vm.processing ||
                                        !_vm.rowIsEditable(item),
                                      numeric: "",
                                      outlined: false,
                                      "highlight-on-focus": "",
                                      type: "number",
                                      "hide-spin-buttons": ""
                                    },
                                    on: {
                                      keydown: [
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "up",
                                              38,
                                              $event.key,
                                              ["Up", "ArrowUp"]
                                            )
                                          )
                                            return null
                                          return _vm.selectPreviousField(
                                            "equipmentHours",
                                            item
                                          )
                                        },
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "down",
                                              40,
                                              $event.key,
                                              ["Down", "ArrowDown"]
                                            )
                                          )
                                            return null
                                          return _vm.selectNextField(
                                            "equipmentHours",
                                            item
                                          )
                                        },
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return (e =>
                                            _vm.enterPressed(
                                              e,
                                              "equipmentHours",
                                              item
                                            )).apply(null, arguments)
                                        }
                                      ]
                                    },
                                    model: {
                                      value: item.equipmentHours,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "equipmentHours",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "item.equipmentHours"
                                    }
                                  })
                            ]
                          }
                        },
                        _vm._l(_vm.usableWorkSubTypes, function(wst) {
                          return {
                            key: `item.${wst.id}`,
                            fn: function({ item }) {
                              return [
                                _vm.currentTimesheetIsReadonly ||
                                !_vm.rowCanEditWorkSubType(item, wst.id)
                                  ? _c("sp-timesheet-time-display", {
                                      key: wst.id,
                                      attrs: {
                                        times: _vm.getTimesForItem(
                                          item,
                                          wst.id
                                        ),
                                        "no-value-key":
                                          "timesheets.list.no-time-value",
                                        "single-line": ""
                                      }
                                    })
                                  : _c("fd-text-field", {
                                      key: wst.id,
                                      ref: _vm.getFieldRef(wst.id, item),
                                      refInFor: true,
                                      staticClass: "fd-less-short-table-input",
                                      attrs: {
                                        value: _vm.getTimeValueForItem(
                                          item,
                                          wst.id
                                        ),
                                        disabled:
                                          _vm.processing ||
                                          !_vm.rowIsEditable(item),
                                        outlined: false,
                                        "highlight-on-focus": ""
                                      },
                                      on: {
                                        change: v =>
                                          _vm.workSubTypeHoursValueChanged(
                                            item,
                                            wst.id,
                                            v
                                          ),
                                        keydown: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "up",
                                                38,
                                                $event.key,
                                                ["Up", "ArrowUp"]
                                              )
                                            )
                                              return null
                                            return _vm.selectPreviousField(
                                              wst.id,
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            )
                                              return null
                                            return _vm.selectNextField(
                                              wst.id,
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return (e =>
                                              _vm.enterPressed(
                                                e,
                                                wst.id,
                                                item
                                              )).apply(null, arguments)
                                          }
                                        ]
                                      }
                                    })
                              ]
                            }
                          }
                        }),
                        {
                          key: "item.total",
                          fn: function({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "fd-table-column-text-end-override"
                                },
                                [
                                  _c("sp-timesheet-time-display", {
                                    attrs: {
                                      times: _vm.calculateTotalForItem(item),
                                      "display-zero-value": "",
                                      "text-align": "right"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "item.daytotal",
                          fn: function({ item }) {
                            return [
                              _c("sp-timesheet-time-display", {
                                attrs: {
                                  times:
                                    !!_vm.totalTimeForEmployeeOnDay(item) ||
                                    _vm.employeeHasOtherTime(item.employeeID)
                                      ? _vm.totalTimeForEmployeeOnDay(item)
                                      : undefined,
                                  "no-value-key":
                                    "timesheets.list.no-time-value",
                                  "text-align": "right"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.perdiem",
                          fn: function({ item }) {
                            return [
                              _c("sp-timesheet-perdiem", {
                                attrs: {
                                  item: item,
                                  disabled: _vm.processing,
                                  readonly: _vm.currentTimesheetIsReadonly,
                                  perDiemIsWorkOrderRelated:
                                    _vm.perDiemSubTypeIsWorkOrderRelated,
                                  otherPerDiemTimesheetOwner: _vm.perDiemOwnerFromOtherTimesheet(
                                    item.employeeID
                                  ),
                                  otherRowOnTimesheetHasPerDiem: _vm.hasExistingOtherUncorrectedPerDiemRowForEmployee(
                                    item
                                  ),
                                  otherPerDiemRowWorkOrderNumber: _vm.otherPerDiemRowWorkOrderNumber(
                                    item
                                  )
                                },
                                on: {
                                  "update:hasPerDiem": v => {
                                    item.hasPerDiem = v
                                    _vm.perDiemValueChanged(item)
                                  },
                                  "update:removePerDiem": v => {
                                    item.removePerDiem = v
                                    _vm.perDiemValueChanged(item)
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.action",
                          fn: function({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "table-1-action-column-min-width"
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.$vuetify.breakpoint.lgAndUp ||
                                          _vm.processing,
                                        left: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        disabled:
                                                          _vm.processing ||
                                                          _vm.currentTimesheetIsReadonly
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeTimesheetRow(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      mdi-delete\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("common.remove")))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "body.append",
                          fn: function({ items }) {
                            return [
                              _c(
                                "tr",
                                { staticClass: "fd-table-summary-row" },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-start fd-table-frozen-column fd-table-summary-row-first-column",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("fd-alert", {
                                        staticClass: "pa-0 mt-0",
                                        attrs: {
                                          dense: true,
                                          label: _vm.$t(
                                            "timesheets.existing.group-totals-label"
                                          ),
                                          type: "hint",
                                          white: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.usableWorkSubTypes, function(wst) {
                                    return _c(
                                      "td",
                                      { key: wst.id },
                                      [
                                        _c("sp-timesheet-time-display", {
                                          attrs: {
                                            times: _vm.sumRowTimeValues(
                                              items,
                                              wst.id
                                            ),
                                            "no-value-key":
                                              "timesheets.list.no-time-value",
                                            "no-value-class": "white--text"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { attrs: { colspan: "1" } },
                                    [
                                      _c("fd-value-display", {
                                        staticStyle: { display: "inline" },
                                        attrs: {
                                          value: _vm.sumEquipmentHours(items),
                                          "no-value-key":
                                            "timesheets.list.no-time-value",
                                          "no-value-class": "white--text"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                      attrs: { colspan: 1 }
                                    },
                                    [
                                      _c("sp-timesheet-time-display", {
                                        attrs: {
                                          times: _vm.calculateTotalForItems(
                                            items
                                          ),
                                          "no-value-key":
                                            "timesheets.list.no-time-value",
                                          "no-value-class": "white--text",
                                          "no-value-text-align": "right",
                                          "text-align": "right"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("td", { attrs: { colspan: 1 } }, [
                                    _vm._v(" ")
                                  ]),
                                  _vm._v(" "),
                                  !!_vm.perDiemSubType
                                    ? _c("td", {
                                        staticClass:
                                          "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                        attrs: { colspan: 1 }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("td", { attrs: { colspan: 1 } }, [
                                    _vm._v(" ")
                                  ])
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.workOrderNumbersWithDetailWorkSubTypes.length
                ? _c(
                    "v-form",
                    { ref: "additionaldetailsform" },
                    [
                      _c("v-subheader", { staticClass: "pt-3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheets.existing.additional-details-header"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.workOrderNumbersWithDetailWorkSubTypes,
                        function(woNumber) {
                          return _c(
                            "v-alert",
                            {
                              key: woNumber,
                              staticClass: "mx-3 py-0",
                              attrs: { color: "primary" }
                            },
                            [
                              _c(
                                "v-subheader",
                                { staticClass: "white--text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "timesheets.existing.additional-details-label",
                                        [_vm.formatWorkOrderNumber(woNumber)]
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                _vm._l(
                                  _vm.currentTimesheetExplanationsForWorkOrderNumber(
                                    woNumber
                                  ),
                                  function(explanation) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: `${woNumber}_${explanation.workSubTypeID}`,
                                        staticClass: "fd-alert-container",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c("fd-text-field", {
                                          staticStyle: {
                                            "background-color": "white"
                                          },
                                          attrs: {
                                            label: _vm.workSubTypeNameForExplanation(
                                              explanation
                                            ),
                                            rules: [_vm.rules.required],
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: explanation.explanation,
                                            callback: function($$v) {
                                              _vm.$set(
                                                explanation,
                                                "explanation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "explanation.explanation"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        }
                      )
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentTimesheetIsReadonly
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mx-0", attrs: { cols: "12" } },
                    [
                      _vm.timesheetIsSubmitted
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-submitted"
                              )
                            }
                          })
                        : _vm.timesheetIsApproved
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-approved"
                              )
                            }
                          })
                        : _vm.timesheetIsCancelled
                        ? _c("fd-alert", {
                            attrs: {
                              type: "info",
                              label: _vm.$t(
                                "timesheets.existing.cannot-be-edited-cancelled"
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save-draft",
                    color: "primary",
                    disabled: _vm.processing || !_vm.canSave,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-and-close")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }