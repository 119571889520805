var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: { route: _vm.close }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "py-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "pt-3",
                          attrs: { "icons-and-text": "", "show-arrows": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detailsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [
                                _vm._v(
                                  "fas " +
                                    _vm._s(_vm.$store.getters.icon("details"))
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1",
                                      attrs: { cols: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.workSubType.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workSubType,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "workSubType.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1",
                                      attrs: { cols: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.alias"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.workSubType.code,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workSubType,
                                              "code",
                                              $$v
                                            )
                                          },
                                          expression: "workSubType.code"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "work-types.work-sub-types.is-parent-label"
                                          ),
                                          disabled:
                                            _vm.processing ||
                                            !_vm.subTypeCanHaveChildSubTypes ||
                                            !!_vm.workSubType
                                              .parentWorkSubTypeID ||
                                            (_vm.workSubType.isParent &&
                                              !!_vm.childSubTypes.length),
                                          hint: _vm.isParentDisabledMessage,
                                          "persistent-hint": !!_vm.isParentDisabledMessage
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "message",
                                            fn: function({ message }) {
                                              return [
                                                _c("fp-item-disabled-message", {
                                                  attrs: { message: message }
                                                })
                                              ]
                                            }
                                          }
                                        ]),
                                        model: {
                                          value: _vm.workSubType.isParent,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workSubType,
                                              "isParent",
                                              $$v
                                            )
                                          },
                                          expression: "workSubType.isParent"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t("common.archived"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.workSubType.archived,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workSubType,
                                              "archived",
                                              $$v
                                            )
                                          },
                                          expression: "workSubType.archived"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.timesheetTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.timesheetTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.timesheetTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.timesheetTab.tabname) +
                                  "\n              "
                              ),
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    value: _vm.workSubType.isParent,
                                    color: "primary",
                                    icon: "fa-exclamation"
                                  }
                                },
                                [_c("v-icon", [_vm._v("fas fa-clock")])],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.timesheetTab.key,
                              attrs: { value: `tab-${_vm.timesheetTab.key}` }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  !!_vm.workSubType.isParent
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          !!_vm.workSubType.isParent
                                            ? _c("fd-alert", {
                                                attrs: {
                                                  type: "info",
                                                  label: _vm.$t(
                                                    "work-types.work-sub-types.existing.parent-sub-type-no-time-sheet-info-message"
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pb-md-0 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "work-types.work-sub-types.use-work-order-cost-code-label"
                                          ),
                                          disabled:
                                            _vm.processing ||
                                            _vm.workSubType.isParent ||
                                            (!_vm.workSubType
                                              .useWorkOrderCostCode &&
                                              !_vm.workSubType
                                                .isWorkOrderRelated)
                                        },
                                        model: {
                                          value:
                                            _vm.workSubType
                                              .useWorkOrderCostCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workSubType,
                                              "useWorkOrderCostCode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workSubType.useWorkOrderCostCode"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pb-md-0 pt-md-3",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          outlined: "",
                                          items: _vm.allCostCodes,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label: _vm.$t(
                                            "common.default-cost-code"
                                          ),
                                          disabled:
                                            _vm.processing ||
                                            _vm.workSubType.isParent ||
                                            _vm.workSubType
                                              .useWorkOrderCostCode,
                                          clearable: "",
                                          hint: _vm.workSubType
                                            .useWorkOrderCostCode
                                            ? _vm.$t(
                                                "work-types.work-sub-types.existing.sub-type-uses-work-order-cost-code-not-its-own-message"
                                              )
                                            : "",
                                          "persistent-hint":
                                            _vm.workSubType.useWorkOrderCostCode
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "message",
                                            fn: function({ message }) {
                                              return [
                                                _vm.workSubType
                                                  .useWorkOrderCostCode
                                                  ? _c(
                                                      "fp-item-disabled-message",
                                                      {
                                                        attrs: {
                                                          message: message
                                                        }
                                                      }
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ]),
                                        model: {
                                          value:
                                            _vm.workSubType.defaultCostCodeID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workSubType,
                                              "defaultCostCodeID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workSubType.defaultCostCodeID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "common.work-order-related"
                                          ),
                                          disabled:
                                            _vm.processing ||
                                            _vm.workSubType.isParent
                                        },
                                        model: {
                                          value:
                                            _vm.workSubType.isWorkOrderRelated,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workSubType,
                                              "isWorkOrderRelated",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workSubType.isWorkOrderRelated"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "work-types.work-sub-types.requires-additional-details-label"
                                          ),
                                          disabled:
                                            _vm.processing ||
                                            _vm.workSubType.isParent
                                        },
                                        model: {
                                          value:
                                            _vm.workSubType
                                              .requiresAdditionalDetails,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workSubType,
                                              "requiresAdditionalDetails",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workSubType.requiresAdditionalDetails"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.childTypesTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.childTypesTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.childTypesTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.childTypesTab.tabname) +
                                  "\n              "
                              ),
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    value: !_vm.workSubType.isParent,
                                    color: "primary",
                                    icon: "fa-exclamation"
                                  }
                                },
                                [_c("v-icon", [_vm._v("fas fa-list-tree")])],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.childTypesTab.key,
                              attrs: { value: `tab-${_vm.childTypesTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  !_vm.workSubType.isParent
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          !_vm.workSubType.isParent
                                            ? _c("fd-alert", {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  type: "info",
                                                  label: _vm.$t(
                                                    "work-types.work-sub-types.existing.not-parent-cannot-have-child-sub-types-message"
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c("fd-inline-add-button", {
                                            attrs: {
                                              "data-cy": "addchildsubtype",
                                              disabled: !_vm.workSubType
                                                .isParent
                                            },
                                            on: {
                                              click:
                                                _vm.openNewChildSubTypeDialog
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "work-types.work-sub-types.existing.table-listing-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: "",
                                              disabled: !_vm.workSubType
                                                .isParent
                                            },
                                            model: {
                                              value:
                                                _vm.childSubTypeSearchString,
                                              callback: function($$v) {
                                                _vm.childSubTypeSearchString = $$v
                                              },
                                              expression:
                                                "childSubTypeSearchString"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-table-sortable",
                                            rawName: "v-fd-table-sortable"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:[iconColumnArgument].no-sort.class_fd-sm-table-icon-cell.hide-when-small",
                                            value: "",
                                            expression:
                                              "\n                      ''\n                    ",
                                            arg: _vm.iconColumnArgument,
                                            modifiers: {
                                              "no-sort": true,
                                              "class_fd-sm-table-icon-cell": true,
                                              "hide-when-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name.no-sort",
                                            value: _vm.$t("common.name"),
                                            expression: "$t('common.name')",
                                            arg: "name",
                                            modifiers: { "no-sort": true }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:code",
                                            value: _vm.$t("common.alias"),
                                            expression: "$t('common.alias')",
                                            arg: "code"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:costCode",
                                            value: _vm.$t(
                                              "common.default-cost-code"
                                            ),
                                            expression:
                                              "$t('common.default-cost-code')",
                                            arg: "costCode"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:isWorkOrderRelated",
                                            value: _vm.$t(
                                              "common.work-order-related"
                                            ),
                                            expression:
                                              "$t('common.work-order-related')",
                                            arg: "isWorkOrderRelated"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:requiresAdditionalDetails",
                                            value: _vm.$t(
                                              "work-types.work-sub-types.requires-additional-details-label"
                                            ),
                                            expression:
                                              "\n                      $t('work-types.work-sub-types.requires-additional-details-label')\n                    ",
                                            arg: "requiresAdditionalDetails"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:archived.no-sort.hide-when-extra-small.hide-when-header-text-empty",
                                            value: _vm.showArchived
                                              ? _vm.$t("common.archived")
                                              : "",
                                            expression:
                                              "\n                      showArchived ? $t('common.archived') : ''\n                    ",
                                            arg: "archived",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "hide-when-header-text-empty": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.no-sort.show-when-extra-small",
                                            value: item =>
                                              _vm.$router.push(
                                                `/worktypes/${_vm.$route.params.worktypeid}/worksubtypes/${_vm.$route.params.id}/worksubsubtypes/${item.id}`
                                              ),
                                            expression:
                                              "\n                      item =>\n                        $router.push(\n                          `/worktypes/${$route.params.worktypeid}/worksubtypes/${$route.params.id}/worksubsubtypes/${item.id}`\n                        )\n                    ",
                                            modifiers: {
                                              "no-sort": true,
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "fd-actions-table fd-archiving-table",
                                        class: [
                                          {
                                            "fd-archive-bonus-padding-bottom":
                                              _vm.showArchived
                                          }
                                        ],
                                        attrs: {
                                          disabled: !_vm.workSubType.isParent,
                                          "disable-sort": "",
                                          items: _vm.childSubTypes,
                                          search: _vm.childSubTypeSearchString,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0",
                                          "items-per-page": 100,
                                          "footer-props": {
                                            "items-per-page-options": [
                                              5,
                                              25,
                                              50,
                                              100,
                                              -1
                                            ]
                                          }
                                        },
                                        on: { "sort:end": _vm.dragEnded },
                                        scopedSlots: _vm._u([
                                          {
                                            key:
                                              "item.hasMisconfiguredSubTypes",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { right: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              item.misconfigured
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "error"
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "fas fa-exclamation-circle"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "work-types.work-sub-types.cost-code-misconfiguration-error-message"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.fd-drag",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("far fa-grip-lines")]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "footer.prepend",
                                            fn: function() {
                                              return [
                                                _c("fd-archived-data-loader", {
                                                  staticClass: "ml-2 mr-5",
                                                  attrs: {
                                                    showArchived:
                                                      _vm.showArchived,
                                                    showArchivedDateRange:
                                                      _vm.showArchivedDateRange,
                                                    showArchivedMinDate:
                                                      _vm.showArchivedMinDate,
                                                    showArchivedMaxDate:
                                                      _vm.showArchivedMaxDate,
                                                    loading:
                                                      _vm.archivedLoading,
                                                    disabled: _vm.processing,
                                                    "hide-date-range-picker": ""
                                                  },
                                                  on: {
                                                    "update:showArchived": function(
                                                      $event
                                                    ) {
                                                      _vm.showArchived = $event
                                                    },
                                                    "update:show-archived": function(
                                                      $event
                                                    ) {
                                                      _vm.showArchived = $event
                                                    },
                                                    "update:showArchivedDateRange": function(
                                                      $event
                                                    ) {
                                                      _vm.showArchivedDateRange = $event
                                                    },
                                                    "update:show-archived-date-range": function(
                                                      $event
                                                    ) {
                                                      _vm.showArchivedDateRange = $event
                                                    }
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.fd-nav",
                                            fn: function() {
                                              return [
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-right")
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.isWorkOrderRelated",
                                            fn: function({ item }) {
                                              return [
                                                item.isParent
                                                  ? _c("span")
                                                  : _c("fd-checkbox", {
                                                      attrs: {
                                                        value:
                                                          item.isWorkOrderRelated,
                                                        disabled:
                                                          _vm.processing,
                                                        readonly: true
                                                      }
                                                    })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.costCode",
                                            fn: function({ item }) {
                                              return [
                                                item.isParent
                                                  ? _c("span")
                                                  : item.useWorkOrderCostCode
                                                  ? _c("fd-checkbox", {
                                                      attrs: {
                                                        value:
                                                          item.useWorkOrderCostCode,
                                                        disabled:
                                                          _vm.processing,
                                                        readonly: true,
                                                        "on-text": _vm.$t(
                                                          "work-types.work-sub-types.use-work-order-cost-code-label"
                                                        )
                                                      }
                                                    })
                                                  : _c("fd-value-display", {
                                                      attrs: {
                                                        value: _vm.getCostCodeName(
                                                          item
                                                        )
                                                      }
                                                    })
                                              ]
                                            }
                                          },
                                          {
                                            key:
                                              "item.requiresAdditionalDetails",
                                            fn: function({ item }) {
                                              return [
                                                item.isParent
                                                  ? _c("span")
                                                  : _c("fd-checkbox", {
                                                      attrs: {
                                                        value:
                                                          item.requiresAdditionalDetails,
                                                        disabled:
                                                          _vm.processing,
                                                        readonly: true
                                                      }
                                                    })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.archived",
                                            fn: function({ item }) {
                                              return [
                                                _c("v-simple-checkbox", {
                                                  attrs: {
                                                    value: item.archived,
                                                    disabled: _vm.processing
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.flipArchived(
                                                        item
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to: `/worktypes/${_vm.$route.params.worktypeid}/worksubtypes/${_vm.$route.params.id}/worksubsubtypes/${item.id}`
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteTableItem(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !!_vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mr-0",
                                      attrs: { type: _vm.inlineMessage.type }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.workSubTypeIsMisconfigured
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.workSubTypeIsMisconfigured
                                ? _c("v-alert", { attrs: { type: "error" } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "work-types.work-sub-types.cost-code-required-to-show-on-timesheet-error"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mx-1" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            link: "",
                            disabled: _vm.processing,
                            color: "error",
                            outlined: "",
                            small:
                              _vm.$vuetify.breakpoint.xsOnly &&
                              _vm.$vuetify.breakpoint.width >= 360,
                            "x-small": _vm.$vuetify.breakpoint.width < 360
                          },
                          on: { click: _vm.deleteItem }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.delete")))]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            color: "primary",
                            disabled: _vm.processing || !_vm.canMoveToNewParent
                          },
                          on: { click: _vm.moveToParent }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.breakpoint.smAndDown
                                ? _vm.$t(
                                    "work-types.work-sub-types.existing.move-to-parent-action-short"
                                  )
                                : _vm.$t(
                                    "work-types.work-sub-types.existing.move-to-parent-action"
                                  )
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            text: "",
                            link: "",
                            disabled: _vm.processing,
                            small:
                              _vm.$vuetify.breakpoint.xsOnly &&
                              _vm.$vuetify.breakpoint.width >= 360,
                            "x-small": _vm.$vuetify.breakpoint.width < 360
                          },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _vm._v(" "),
                      _c("fd-menu-save", {
                        attrs: {
                          disabled: _vm.processing,
                          loading: _vm.saving,
                          small: _vm.$vuetify.breakpoint.xsOnly
                        },
                        on: {
                          "click:save": function($event) {
                            return _vm.save(false)
                          },
                          "click:save-and-close": function($event) {
                            return _vm.save(true)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }