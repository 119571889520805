var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "mx-0", staticStyle: { width: "100%" } },
    [
      _c(
        "v-col",
        {
          staticClass: "fd-remove-left-padding-cols-below pl-0",
          attrs: { cols: "12" }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "mx-0 px-0 fd-subheader-with-control",
              attrs: { align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  attrs: { "align-self": "end", cols: "12", sm: "4" }
                },
                [
                  _c(
                    "v-subheader",
                    {
                      staticClass: "px-0",
                      staticStyle: { "font-size": "1em", height: "32px" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("scheduler.scaffold-request-request-details")
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.showEditControls
                ? _c(
                    "v-col",
                    {
                      staticClass: "pt-1 pb-1 align-end",
                      attrs: { align: "center", cols: "12", sm: "8" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          class: [
                            _vm.workOrder.isUrgent
                              ? "fd-hot-job-latest-stamp-container"
                              : "",
                            "d-flex",
                            "justify-sm-end"
                          ]
                        },
                        [
                          _c("v-switch", {
                            staticClass: "fd-remove-messages",
                            attrs: {
                              label: _vm.$t("scheduler.is-urgent"),
                              "input-value": _vm.workOrder.isUrgent,
                              disabled: _vm.editControlsDisabled
                            },
                            on: {
                              change: v => _vm.$emit("change:isurgent", v)
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("scheduler.is-urgent")
                                          ) +
                                          "\n              "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "padding-left": "6px !important"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                fas fa-fire-alt\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3724509530
                            )
                          }),
                          _vm._v(" "),
                          _vm.workOrder.isUrgent
                            ? _c(
                                "div",
                                { staticClass: "fd-hot-job-latest-stamp" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.lastUrgentValueChangedSummaryString
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 pl-0", attrs: { align: "center" } },
            [_c("v-divider", { staticClass: "mx-0 mt-0 px-0" })],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 pl-0 pt-2 pb-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.request-type-column-header"),
                      value: _vm.$format.uppercase(
                        _vm.$lookup.requestType(_vm.workOrder.requestType)
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.request-sub-type"),
                      value: _vm.$format.uppercase(
                        _vm.$lookup.requestSubType(_vm.workOrder.requestSubType)
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.request-number-column-header"),
                      value: _vm.workOrder.requestNumber
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.wo-number-column-header"),
                      value: _vm.workOrder.internalNumber
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.scaffold-number"),
                      value: _vm.workOrder.scaffoldNumber
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value-gap"),
                  _vm._v(" "),
                  _c("fd-work-order-detail-date-value", {
                    attrs: {
                      label: _vm.$t("scheduler.required-date-column-header"),
                      value: _vm.workOrder.requiredDate
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-date-value", {
                    attrs: {
                      label: _vm.$t(
                        "scheduler.approved-required-date-column-header"
                      ),
                      value: _vm.workOrder.approvedRequiredDate,
                      differentialValue: _vm.workOrder.requiredDate
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-date-value", {
                    attrs: {
                      label: _vm.$t(
                        "scheduler.required-until-date-column-header"
                      ),
                      value: _vm.workOrder.requiredUntilDate
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-date-value", {
                    attrs: {
                      label: _vm.$t("scheduler.requested-date-column-header"),
                      value: _vm.workOrder.requestDate
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-date-time-value", {
                    attrs: {
                      label: _vm.$t("scheduler.submitted-date-column-header"),
                      value: _vm.workOrder.requestSubmittedOn
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value-gap"),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t(
                        "scheduler.requesting-contractor-column-header"
                      ),
                      value: _vm.$lookup.contractor(
                        _vm.workOrder.requestingContractorID
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.requesting-employee"),
                      value: _vm.$lookup.person(
                        _vm.workOrder.requestingEmployeeID
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t(
                        "scheduler.request-submitted-by-column-header"
                      ),
                      value: _vm.workOrder.requestSubmitterIDs
                        .map(x => _vm.$lookup.personBySecurityID(x))
                        .join(", ")
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.discipline"),
                      value: _vm.$lookup.discipline(_vm.workOrder.disciplineID)
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-string-list-value", {
                    attrs: {
                      label: _vm.$t("scheduler.iwp"),
                      value: _vm.workOrder.workPackageNames,
                      loading: _vm.workOrder.workPackageNames.isLoading
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("fd-work-order-detail-enum-value", {
                    attrs: {
                      label: _vm.$t("scheduler.request-status-column-header"),
                      value: _vm.workOrder.requestStatus,
                      enum: _vm.ScaffoldRequestStatuses
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t(
                        "scheduler.work-order-status-column-header"
                      ),
                      value: _vm.$format.uppercase(
                        _vm.$lookup.workOrderStatus(
                          _vm.workOrder.workOrderStatus
                        )
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-date-time-value", {
                    attrs: {
                      label: _vm.$t("scheduler.status-change-date"),
                      value: _vm.workOrder.lastStatusChangedDate
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.status-changed-by"),
                      value: _vm.$lookup.personBySecurityID(
                        _vm.workOrder.lastStatusChangedBy
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.assigned-contractor-long"),
                      value: _vm.$lookup.contractor(
                        _vm.workOrder.assignedContractorID
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.coordinator"),
                      value: _vm.$lookup.person(_vm.workOrder.coordinatorID)
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.general-foreman"),
                      value: _vm.$lookup.person(_vm.workOrder.generalForemanID)
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.foreman"),
                      value: _vm.$lookup.person(_vm.workOrder.foremanID)
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value-gap"),
                  _vm._v(" "),
                  _c("fd-work-order-detail-date-value", {
                    attrs: {
                      label: _vm.$t("scheduler.start-date-column-header"),
                      value: _vm.workOrder.startDate
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-date-value", {
                    attrs: {
                      label: _vm.$t("scheduler.completed-date-column-header"),
                      value: _vm.workOrder.completedDate
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.priority"),
                      value: _vm.workOrder.priority
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.planned"),
                      value: _vm.workOrder.isPlanned
                        ? _vm.$t("scheduler.planned")
                        : _vm.$t("scheduler.not-planned")
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.progress"),
                      value: _vm.workOrder.progress
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }