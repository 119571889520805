import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  FDColumnDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import {
  Classification,
  Contractor,
  FleetEquipmentWithName,
  FleetWithEquipment,
  EquipmentWithDetails,
  Person,
  PersonWithDetails
} from "../services";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { mapActions, mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import userAccess from "../dataMixins/userAccess";
import { SortFleetEquipment } from "./components/dialogs/SP.FleetDetailsBottomDialog.vue";
import { GetPersonName, HasName, SortItemsWithName } from "../utils/person";

type FleetWithEquipmentAndArchived = FleetWithEquipment & { isArchived: boolean };
type EquipmentWithDetailsAndClassificationName = EquipmentWithDetails & {
  classificationName: string | undefined;
};

export default FDVue.extend({
  name: "fd-fleet-existing",

  mixins: [serviceErrorHandling, tabbedView, rules, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      slidein: false,
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,
      loaded: false,
      contractor: {} as Contractor,
      fleet: {} as FleetWithEquipmentAndArchived,

      detailserror: false,
      firstTabKey: `0`,
      detailsTab: {
        tabname: this.$t("contractors.fleets.new-fleet.steps.details"),
        key: "0",
        visible: true
      } as Tab,
      equipmentTab: {
        tabname: this.$t("contractors.fleets.new-fleet.steps.equipment"),
        key: "1",
        visible: false
      } as Tab,
      equipmentOrderingTab: {
        tabname: this.$t("contractors.fleets.new-fleet.steps.equipment-ordering"),
        key: "2",
        visible: false
      } as Tab,

      // *** EQUIPMENT ***
      showOnlyIncludedEquipment: false,
      // Used for "Include" header checkbox to determine "checked" state
      allSearchedEquipmentSelected: false,
      tablesearchequipment: "",
      selectableEquipment: [] as Array<EquipmentWithDetailsAndClassificationName>
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.equipmentTab] as Tab[];
    },
    // List of people available to be selected as Crew Owner
    visiblePeople(): (Person & HasName)[] {
      let visiblePeople = SortItemsWithName(
        (this.$store.state.users.fullList as PersonWithDetails[])
          .filter(x => x.contractorID == this.fleet.contractorID)
          .map(x => ({ ...x, name: GetPersonName(x) }))
      );
      return visiblePeople;
    },

    // *** EQUIPMENT ***
    equipment: {
      cache: false,
      get(): Array<EquipmentWithDetailsAndClassificationName> {
        let returnValue = this.selectableEquipment;
        if (this.showOnlyIncludedEquipment)
          returnValue = returnValue.filter(x => this.selectedEquipmentIDs.includes(x.id!));
        return returnValue;
      }
    },

    selectedEquipmentIDs(): string[] {
      return this.fleet?.equipment?.map(e => e.equipmentID!) ?? [];
    },

    selectedEquipment(): EquipmentWithDetailsAndClassificationName[] {
      return this.selectedEquipmentIDs
        .filter(x => !!this.selectableEquipment.find(e => e.id == x))
        .map(x => this.selectableEquipment.find(e => e.id == x)!);
    },

    searchedEquipment(): Array<EquipmentWithDetailsAndClassificationName> {
      // This is a hack because the equipment list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .equipmentDataTable as any)?.customFilter;
      if (this.tablesearchequipment && customFilter) {
        return this.equipment.filter(
          x =>
            customFilter(x.name!, this.tablesearchequipment, x) ||
            customFilter(x.serialNumber!, this.tablesearchequipment, x)
        );
      } else {
        return this.equipment;
      }
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedEquipmentSelected(): boolean {
      var searchedEquipment = this.searchedEquipment;
      return (
        searchedEquipment.findIndex(x => this.selectedEquipmentIDs.includes(x.id!)) !== -1 &&
        searchedEquipment.findIndex(x => !this.selectedEquipmentIDs.includes(x.id!)) !== -1
      );
    }
  },

  methods: {
    updateBreadCrumbs() {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/contractors") {
        this.notifyNewBreadcrumb({
          text: this.$t("contractors.list-title"),
          to: "/contractors",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.contractor.name,
          to: `/contractors/${this.$route.params.contractorid}`
        });
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      if (!!this.fleet?.id) {
        this.notifyNewBreadcrumb({
          text: this.fleet.name,
          to: `/contractors/${this.$route.params.contractorid}/fleets/${this.$route.params.id}`
        });
      } else {
        if (this.loaded) {
          this.notifyNewBreadcrumb({
            text: this.$t("common.unknown"),
            disabled: true
          });
        } else {
          this.notifyNewBreadcrumb({
            text: this.$t("loading-dot-dot-dot"),
            disabled: true
          });
        }
      }
    },
    // *** GLOBAL ***
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setSelectedTab: "SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractor: "LOAD_CONTRACTOR",
      loadFleet: "LOAD_FLEET",
      updateFleet: "UPDATE_FLEET",
      deleteFleet: "DELETE_FLEET",
      loadClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS",
      loadEquipmentList: "LOAD_EQUIPMENTS",
      loadEmployees: "LOAD_USERS"
    }),
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // DOES NOT manage processing or error message logic

    validate(): boolean {
      this.detailserror = !((this.$refs.detailsform as HTMLFormElement)?.validate() ?? true);
      return !this.detailserror;
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      this.inlineMessage.message = null;
      if (!this.fleet) return;
      // if (!!this.fleet.contractorID && !this.fleet.contractorIDs!.includes(this.fleet.contractorID)) {
      //   this.fleet.contractorIDs!.push(this.fleet.contractorID);
      // }

      if (!this.validate()) {
        var message = this.$t("contractors.fleets.new-fleet.error-message");
        if (this.detailserror)
          message += "\n\t- " + this.$t("contractors.fleets.new-fleet.steps.details");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        var archivedDate = null;
        if (this.fleet.isArchived && !this.fleet.archivedDate) {
          archivedDate = new Date(new Date().toUTCString());
        }
        this.fleet.archivedDate = archivedDate;

        await this.$store.dispatch("UPDATE_FLEET", {
          ...this.fleet
        });
        if (closeOnComplete) {
          // this.$router.push(`/contractors/${this.fleet.contractorID}`);
          this.$router.back();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async deleteItem() {
      this.inlineMessage.message = null;
      if (!this.fleet) return;

      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteFleet(this.fleet);
        this.$router.push(`/contractors/${this.fleet.contractorID}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // this.$router.push(`/contractors/${this.fleet.contractorID}`);
      this.$router.back();
    },

    // *** EQUIPMENT ***
    orderForItem(item: EquipmentWithDetails) {
      return this.fleet?.equipment?.find(x => x.equipmentID == item.id)?.order;
    },
    itemIsSelected(item: EquipmentWithDetails) {
      return this.selectedEquipmentIDs.includes(item.id!);
    },
    flipEquipmentSelected(item: EquipmentWithDetailsAndClassificationName) {
      if (!this.fleet) return;

      if (!this.fleet.equipment) this.fleet.equipment = [];

      let equipmentSelected = this.itemIsSelected(item);
      if (equipmentSelected) {
        let index = this.selectedEquipmentIDs.indexOf(item.id!);
        if (index > -1) {
          this.fleet.equipment!.splice(index, 1);
        }
      } else {
        if (!this.fleet.equipment) this.fleet.equipment = [];
        this.fleet.equipment.push({
          name: item.name,
          fleetID: this.fleet.id,
          equipmentID: item.id,
          order: this.fleet.equipment.length + 1
        } as FleetEquipmentWithName);
      }
      this.allSearchedEquipmentSelected =
        this.searchedEquipment.findIndex(x => !this.selectedEquipmentIDs.includes(x.id!)) === -1;

      this.fleet.equipment.forEach((item, index) => {
        let newOrder = index + 1;
        item.order = newOrder;
      });
    },

    flipSearchedEquipmentelected() {
      if (!this.fleet) return;
      if (!this.fleet.equipment) {
        this.fleet.equipment = [];
        this.allSearchedEquipmentSelected = false;
      }

      let selected = !this.allSearchedEquipmentSelected;
      for (let equipment of this.searchedEquipment) {
        let equipmentSelected = this.selectedEquipmentIDs.includes(equipment.id!);
        if (equipmentSelected === selected) {
          continue;
        }

        if (!!selected) {
          this.fleet.equipment.push({
            name: equipment.name,
            fleetID: this.fleet.id,
            equipmentID: equipment.id,
            order: this.fleet.equipment.length + 1
          } as FleetEquipmentWithName);
        } else {
          let index = this.selectedEquipmentIDs.indexOf(equipment.id!);
          if (index > -1) {
            this.fleet.equipment!.splice(index, 1);
          }
        }
      }
      this.allSearchedEquipmentSelected =
        this.searchedEquipment.findIndex(x => !this.selectedEquipmentIDs.includes(x.id!)) === -1;
      this.fleet.equipment.forEach((item, index) => {
        let newOrder = index + 1;
        item.order = newOrder;
      });
    },
    async dragEnded(e: SortableEvent) {
      if (!this.fleet) return;
      if (!this.fleet.equipment?.length) return;

      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.fleet.equipment.splice(newIndex, 0, ...this.fleet.equipment.splice(oldIndex, 1));
      this.fleet.equipment.forEach((item, index) => {
        let newOrder = index + 1;
        item.order = newOrder;
      });
    },
    classificationNameForEquipment(equipment: EquipmentWithDetails) {
      let allClassifications = this.$store.state.equipmentClassifications
        .fullList as Classification[];
      return allClassifications.find(x => x.id == equipment.equipmentClassificationID)?.name;
    }
  },

  watch: {
    fleet: function() {
      this.updateBreadCrumbs();
    }
  },

  created: async function() {
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    this.setFilteringContext({
      context: "fleets",
      parentalContext: "contractors-existing",
      searchStringForFiltering: "",
      selectedTab: this.firstTabKey
    });
    this.contractor = this.$store.state.contractors.fullList.find(
      (x: any) => x.id == this.$route.params.contractorid
    );
    if (!this.contractor) {
      await this.loadContractor(this.$route.params.contractorid);
      this.contractor = this.$store.state.contractors.fullList.find(
        (x: any) => x.id == this.$route.params.contractorid
      );
    }
    this.updateBreadCrumbs();

    try {
      await Promise.all([
        this.loadClassifications(),
        this.loadEquipmentList(),
        this.loadEmployees(),
        this.loadFleet(this.$route.params.id)
      ]);
      var fleet = this.$store.state.fleets.fullList.find(
        (x: any) => x.id == this.$route.params.id
      ) as FleetWithEquipment;

      // set loaded to true so that when the fleet prop is updated the screen knows it's updated with loaded data
      this.loaded = true;
      this.fleet = {
        ...fleet,
        isArchived: !!fleet?.archivedDate
      } as FleetWithEquipmentAndArchived;

      let allEquipment = this.$store.state.equipment.fullList as EquipmentWithDetails[];
      this.selectableEquipment = allEquipment
        .filter(x => x.contractorID == this.fleet?.contractorID)
        .map(
          x =>
            ({
              ...x,
              name: x.name,
              classificationName: this.classificationNameForEquipment(x)
            } as EquipmentWithDetailsAndClassificationName)
        );
      this.fleet.equipment = this.fleet.equipment?.filter(
        x => allEquipment.findIndex(e => e.id == x.equipmentID) !== -1
      );
      this.allSearchedEquipmentSelected =
        this.searchedEquipment.findIndex(x => !this.selectedEquipmentIDs.includes(x.id!)) === -1;

      SortFleetEquipment(this.fleet.equipment);
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});
