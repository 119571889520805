import { equipmentService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

export type LoadEquipmentForContractorParameter = {
  contractorID: string;
  forcedArchivedState: boolean;
  archivedFromDate: Date | null;
  archivedToDate: Date | null;
};

var storeModule = createAutomaticCrudStoreModule({
  crudService: equipmentService,
  singularStoreName: "EQUIPMENT",
  pluralStoreName: "EQUIPMENTS",
  localizationPrefix: "equipment",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "equipmentID",
    type: "string"
  },
  storeExtensions: {
    actions: {
      async LOAD_EQUIPMENT_FOR_CONTRACTOR(context, payload: LoadEquipmentForContractorParameter) {
        var response = await equipmentService.getAllForContractor(
          payload.contractorID,
          payload.forcedArchivedState,
          payload.archivedFromDate,
          payload.archivedToDate
        );
        context.commit("SET_EQUIPMENTS", response);
        return response;
      }
    }
  }
});

export default storeModule;
