var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "addform", on: { submit: _vm.addFormOnSubmit } },
        [
          _c(
            "v-container",
            {
              staticClass: "pt-0",
              class: {
                "d-none":
                  !!_vm.timesheet &&
                  !!_vm.timesheet.id &&
                  _vm.timesheetIsReadonly &&
                  !_vm.makeCorrections
              },
              attrs: { fluid: "" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-0 pt-3" },
                [
                  _vm.showResourceGroupSelection
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-0 pt-2",
                          attrs: { cols: _vm.resourceGroupSelectionCols }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              class: {
                                "d-none": !_vm.showResourceGroupSelection
                              },
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c("v-autocomplete", {
                                staticClass: "fd-select-with-context-actions",
                                attrs: {
                                  "data-cy": "crews",
                                  dense: "",
                                  outlined: "",
                                  items: _vm.groupedSelectableResourceGroups,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: _vm.$t(
                                    "timesheets.entries.crew-or-fleet-label"
                                  ),
                                  clearable: "",
                                  disabled:
                                    _vm.isProcessing ||
                                    !!_vm.selectedResourceID ||
                                    !_vm.showResourceGroupSelection ||
                                    (_vm.timesheetIsReadonly &&
                                      !_vm.makeCorrections),
                                  "hide-details": "",
                                  rules: _vm.addTimesheetRowRules()
                                    .selectedCrewID
                                },
                                model: {
                                  value: _vm.selectedResourceGroupID,
                                  callback: function($$v) {
                                    _vm.selectedResourceGroupID = $$v
                                  },
                                  expression: "selectedResourceGroupID"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                {
                                  staticClass: "fd-select-context-actions",
                                  attrs: { dark: false, flat: true }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.$vuetify.breakpoint.lgAndUp ||
                                          _vm.isProcessing,
                                        top: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on: toolTipOn }) {
                                              return [
                                                !!_vm.$listeners[
                                                  "click:add-crew"
                                                ] ||
                                                !!_vm.$listeners[
                                                  "click:add-fleet"
                                                ]
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: "new",
                                                            fab: "",
                                                            elevation: "0",
                                                            "x-small": "",
                                                            dark: "",
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              (_vm.timesheetIsReadonly &&
                                                                !_vm.makeCorrections)
                                                          }
                                                        },
                                                        toolTipOn
                                                      ),
                                                      [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on: menuOn
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          {},
                                                                          menuOn
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-list",
                                                              [
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$emit(
                                                                          "click:add-crew",
                                                                          _vm.selectedFleet
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Crew"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$emit(
                                                                          "click:add-fleet",
                                                                          _vm.selectedFleet
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Fleet"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4007333079
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "timesheets.entries.add-resource-group-label"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.$vuetify.breakpoint.lgAndUp ||
                                          _vm.isProcessing,
                                        top: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on }) {
                                              return [
                                                !!_vm.$listeners[
                                                  "click:edit-fleet"
                                                ] && !!_vm.selectedFleet
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              (_vm.timesheetIsReadonly &&
                                                                !_vm.makeCorrections) ||
                                                              !_vm.canModifySelectedFleet
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "click:edit-fleet",
                                                                _vm.selectedFleet
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-pencil")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : !!_vm.$listeners[
                                                      "click:edit-crew"
                                                    ]
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              (_vm.timesheetIsReadonly &&
                                                                !_vm.makeCorrections) ||
                                                              !_vm.canModifySelectedCrew
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "click:edit-crew",
                                                                _vm.selectedCrew
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-pencil")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1812123699
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      !!_vm.selectedFleet
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.entries.edit-fleet-label"
                                                )
                                              )
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.entries.edit-crew-label"
                                                )
                                              )
                                            )
                                          ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.$vuetify.breakpoint.lgAndUp ||
                                          _vm.isProcessing,
                                        top: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on }) {
                                              return [
                                                !!_vm.$listeners[
                                                  "click:delete-fleet"
                                                ] &&
                                                !!_vm.selectedFleet &&
                                                _vm.$vuetify.breakpoint.mdAndUp
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              (_vm.timesheetIsReadonly &&
                                                                !_vm.makeCorrections) ||
                                                              !_vm.canModifySelectedFleet
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "click:delete-fleet",
                                                                _vm.selectedFleet
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-delete")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : !!_vm.$listeners[
                                                      "click:delete-crew"
                                                    ] &&
                                                    _vm.$vuetify.breakpoint
                                                      .mdAndUp
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                            disabled:
                                                              _vm.isProcessing ||
                                                              (_vm.timesheetIsReadonly &&
                                                                !_vm.makeCorrections) ||
                                                              !_vm.canModifySelectedCrew
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "click:delete-crew",
                                                                _vm.selectedCrew
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-delete")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1262944659
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      !!_vm.selectedFleet
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.entries.delete-fleet-label"
                                                )
                                              )
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.entries.delete-crew-label"
                                                )
                                              )
                                            )
                                          ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "align-center justify-space-between pb-0 pt-2",
                      attrs: { cols: _vm.resourceSelectionCols }
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "data-cy": "employees",
                          dense: "",
                          outlined: "",
                          items: _vm.groupedSelectableResources,
                          "item-text": "nameWithCode",
                          "item-value": "id",
                          label: _vm.$t(
                            "timesheets.entries.employee-or-equipment-label"
                          ),
                          clearable: "",
                          disabled:
                            _vm.isProcessing ||
                            !!_vm.selectedResourceGroupID ||
                            (_vm.timesheetIsReadonly && !_vm.makeCorrections),
                          "hide-details": "",
                          rules: _vm.addTimesheetRowRules().selectedEmployeeID
                        },
                        model: {
                          value: _vm.selectedResourceID,
                          callback: function($$v) {
                            _vm.selectedResourceID = $$v
                          },
                          expression: "selectedResourceID"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showWorkOrderSelection
                    ? _c(
                        "v-col",
                        {
                          class: [
                            _vm.$vuetify.breakpoint.smAndUp
                              ? ""
                              : "fd-remove-validation-message-area",
                            "align-center",
                            "justify-space-between",
                            "pb-0",
                            "pt-2"
                          ],
                          attrs: { cols: _vm.workOrderSelectionCols }
                        },
                        [
                          _c("v-autocomplete", {
                            staticClass:
                              "fd-select-chip-colors fd-select-include-bottom-margin",
                            attrs: {
                              outlined: true,
                              dense: "",
                              "hide-details": "",
                              "data-cy": "workorders",
                              "search-input": _vm.workOrderSearch,
                              items: _vm.availableWorkOrders,
                              "item-text": "description",
                              label: _vm.$t("transfers.work-order-label"),
                              multiple: true,
                              "return-object": "",
                              loading: _vm.workOrderSearching,
                              chips: "",
                              "small-chips": "",
                              "deletable-chips": "",
                              disabled: _vm.isProcessing,
                              placeholder: _vm.$t(
                                "transfers.work-order-select-placeholder"
                              ),
                              "no-data-text": _vm.workOrderSelectorNoDataText,
                              rules: _vm.addTimesheetRowRules()
                                .selectedWorkOrders
                            },
                            on: {
                              "update:searchInput": function($event) {
                                _vm.workOrderSearch = $event
                              },
                              "update:search-input": function($event) {
                                _vm.workOrderSearch = $event
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-item",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-list-item-group",
                                        {
                                          staticClass:
                                            "fd-timesheet-work-order-selection-non-work-order",
                                          model: {
                                            value: _vm.isNonWorkOrderSelected,
                                            callback: function($$v) {
                                              _vm.isNonWorkOrderSelected = $$v
                                            },
                                            expression: "isNonWorkOrderSelected"
                                          }
                                        },
                                        [
                                          _c("v-list-item", {
                                            attrs: {
                                              dense: "",
                                              link: "",
                                              disabled: !_vm.selectedWorkOrders
                                                .length
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function({ active }) {
                                                    return [
                                                      _c(
                                                        "v-list-item-action",
                                                        {
                                                          staticClass:
                                                            "fd-counter-select",
                                                          attrs: {
                                                            disabled: !_vm
                                                              .selectedWorkOrders
                                                              .length
                                                          }
                                                        },
                                                        [
                                                          _c("v-checkbox", {
                                                            attrs: {
                                                              "input-value": active,
                                                              disabled:
                                                                _vm.processing ||
                                                                !_vm
                                                                  .selectedWorkOrders
                                                                  .length
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.generalizedDirectLabel
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              861990298
                                            )
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.showPreviouslySelectedWorkOrders
                                        ? _c("v-divider")
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showPreviouslySelectedWorkOrders
                                        ? _c(
                                            "v-subheader",
                                            {
                                              staticClass: "px-4",
                                              attrs: { inset: "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "timesheets.existing.recent-work-orders-header"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showPreviouslySelectedWorkOrders
                                        ? _c(
                                            "v-list",
                                            {
                                              staticClass: "pt-0",
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    multiple: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedPreviouslySelectedWorkOrders,
                                                    callback: function($$v) {
                                                      _vm.selectedPreviouslySelectedWorkOrders = $$v
                                                    },
                                                    expression:
                                                      "selectedPreviouslySelectedWorkOrders"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.previouslySelectedWorkOrders,
                                                  function(item) {
                                                    return _c("v-list-item", {
                                                      key: item.id,
                                                      staticClass:
                                                        "primary--text",
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function({
                                                              active
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-list-item-action",
                                                                  {
                                                                    staticClass:
                                                                      "fd-counter-select"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          "input-value": active
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          dense:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "shrink"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle: {
                                                                                  "white-space":
                                                                                    "nowrap"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      item.workOrderNumber
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "shrink"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "fd-item-detail-dense"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "(" +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        `scaffold-requests.types.${item.scaffoldRequestType}`
                                                                                      )
                                                                                    ) +
                                                                                    ")"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "grow"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                class: [
                                                                                  "py-1",
                                                                                  {
                                                                                    "px-3":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .mdAndUp
                                                                                  },
                                                                                  {
                                                                                    "px-2":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .smAndDown
                                                                                  },
                                                                                  {
                                                                                    "mt-0":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .mdAndUp
                                                                                  },
                                                                                  {
                                                                                    "mt-1":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .smAndDown
                                                                                  },
                                                                                  {
                                                                                    "mb-2":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .smAndDown
                                                                                  },
                                                                                  {
                                                                                    "ml-1":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .xsOnly
                                                                                  },
                                                                                  "fd-status-indicator",
                                                                                  {
                                                                                    "fd-item-detail":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .md
                                                                                  },
                                                                                  {
                                                                                    "fd-item-detail-dense":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .lgAndUp
                                                                                  },
                                                                                  {
                                                                                    "fd-item-detail-dense":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .smAndDown
                                                                                  }
                                                                                ],
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "fit-content"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        `workorders.status.${item.workOrderStatus}`
                                                                                      )
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showAvailableWorkOrders
                                        ? _c("v-divider")
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showAvailableWorkOrders
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "mx-4 pl-14",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c("v-col", {
                                                attrs: { cols: "6" }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "fd-item-detail-light"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.work-order-start-date-label"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "fd-item-detail-light"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.work-order-completed-date-label"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "item",
                                  fn: function({ item, on, attrs }) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function({ active }) {
                                                      return [
                                                        _c(
                                                          "v-list-item-action",
                                                          {
                                                            staticClass:
                                                              "fd-counter-select"
                                                          },
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                "input-value": active
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  "no-gutters":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        staticClass:
                                                                          "pr-2",
                                                                        attrs: {
                                                                          "no-gutters":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              md:
                                                                                "6",
                                                                              lg:
                                                                                "12",
                                                                              xl:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  item.workOrderNumber
                                                                                ) +
                                                                                "\n                          "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "fd-item-detail"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "(" +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        `scaffold-requests.types.${item.scaffoldRequestType}`
                                                                                      )
                                                                                    ) +
                                                                                    ")"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              md:
                                                                                "6",
                                                                              lg:
                                                                                "12",
                                                                              xl:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                class: [
                                                                                  "py-1",
                                                                                  {
                                                                                    "px-3":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .mdAndUp
                                                                                  },
                                                                                  {
                                                                                    "px-2":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .smAndDown
                                                                                  },
                                                                                  {
                                                                                    "mt-0":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .mdAndUp
                                                                                  },
                                                                                  {
                                                                                    "mt-1":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .smAndDown
                                                                                  },
                                                                                  {
                                                                                    "mb-2":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .smAndDown
                                                                                  },
                                                                                  {
                                                                                    "ml-1":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .xsOnly
                                                                                  },
                                                                                  "fd-status-indicator",
                                                                                  {
                                                                                    "fd-item-detail":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .md
                                                                                  },
                                                                                  {
                                                                                    "fd-item-detail-dense":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .lgAndUp
                                                                                  },
                                                                                  {
                                                                                    "fd-item-detail-dense":
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .smAndDown
                                                                                  }
                                                                                ],
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "fit-content"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        `workorders.status.${item.workOrderStatus}`
                                                                                      )
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "3"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "fd-item-detail"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              item.startDateString
                                                                            ) +
                                                                            "\n                      "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "3"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "fd-item-detail"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              item.completedDateString
                                                                            ) +
                                                                            "\n                      "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            "v-list-item",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2934365645
                            ),
                            model: {
                              value: _vm.selectedWorkOrders,
                              callback: function($$v) {
                                _vm.selectedWorkOrders = $$v
                              },
                              expression: "selectedWorkOrders"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pb-0 pt-0",
                      attrs: { align: "center", cols: _vm.addEntriesButtonCols }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "py-2", attrs: { "no-gutters": "" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticStyle: { height: "40px !important" },
                              attrs: {
                                color: "primary",
                                loading: _vm.isProcessing,
                                disabled:
                                  _vm.isProcessing ||
                                  (false &&
                                    !_vm.selectedResourceID &&
                                    !_vm.selectedResourceGroupID) ||
                                  (false &&
                                    _vm.timesheetIsReadonly &&
                                    !_vm.makeCorrections) ||
                                  (false &&
                                    _vm.selectedEntryType == "workorder" &&
                                    !_vm.selectedWorkOrders.length),
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addTimesheetRows(
                                    _vm.makeCorrections
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(
                                  "\n                fa-circle-arrow-down\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.makeCorrections
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$vuetify.breakpoint.smAndDown
                                          ? _vm.$t(
                                              "timesheets.entries.add-corrections-label-short"
                                            )
                                          : _vm.$t(
                                              "timesheets.entries.add-corrections-label"
                                            )
                                      )
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$vuetify.breakpoint.smAndDown
                                          ? _vm.$t(
                                              "timesheets.entries.add-label-short"
                                            )
                                          : _vm.$t(
                                              "timesheets.entries.add-label"
                                            )
                                      )
                                    )
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "px-3 py-0", attrs: { cols: "12" } },
            [
              _vm.timesheetIsDeclined
                ? _c("fd-alert", {
                    attrs: {
                      type: "warning",
                      label: !!_vm.timesheetDeclineComments
                        ? _vm.$t("timesheets.declined-with-reason")
                        : _vm.$t("timesheets.declined"),
                      comments: _vm.timesheetDeclineComments
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticStyle: { position: "relative" }, attrs: { "no-gutters": "" } },
        [
          _c("v-spacer"),
          _vm._v(" "),
          !!_vm.timesheet
            ? _c("v-switch", {
                staticClass: "pr-3",
                staticStyle: {
                  position: "absolute",
                  right: "4px",
                  top: "10px",
                  "z-index": "2"
                },
                attrs: {
                  "data-cy": "isnightshiftswitch",
                  label: _vm.$vuetify.breakpoint.smAndUp
                    ? _vm.$t("timesheets.is-night-shift-label")
                    : _vm.$t("timesheets.is-night-shift-label-short"),
                  disabled: _vm.isProcessing || _vm.timesheetIsReadonly
                },
                model: {
                  value: _vm.timesheet.isNightShift,
                  callback: function($$v) {
                    _vm.$set(_vm.timesheet, "isNightShift", $$v)
                  },
                  expression: "timesheet.isNightShift"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "timesheetform", on: { submit: _vm.preventSubmit } },
        [
          _c(
            "v-tabs",
            {
              ref: "tabs",
              class: _vm.timesheetIsReadonly ? "mt-3" : "mt-0",
              attrs: {
                "icons-and-text": "",
                "show-arrows": !_vm.$vuetify.breakpoint.xsOnly,
                "next-icon": "fa-arrow-circle-right",
                "prev-icon": "fa-arrow-circle-left"
              },
              model: {
                value: _vm.active_tab,
                callback: function($$v) {
                  _vm.active_tab = $$v
                },
                expression: "active_tab"
              }
            },
            [
              _c(
                "v-tab",
                {
                  key: _vm.workspaceTab.key,
                  ref: "tab",
                  attrs: {
                    "data-cy": "workspace",
                    href: `#tab-${_vm.workspaceTab.key}`
                  },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.workspaceTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.workspaceTab.tabname) +
                      "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-edit")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  key: _vm.summaryTab.key,
                  attrs: {
                    "data-cy": "summary",
                    href: `#tab-${_vm.summaryTab.key}`
                  },
                  on: {
                    click: function($event) {
                      return _vm.tabSelected(_vm.summaryTab)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.summaryTab.tabname) + "\n        "
                  ),
                  _c("v-icon", [_vm._v("fas fa-sigma")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              attrs: { touchless: "" },
              model: {
                value: _vm.active_tab,
                callback: function($$v) {
                  _vm.active_tab = $$v
                },
                expression: "active_tab"
              }
            },
            [
              _c(
                "v-tab-item",
                {
                  key: _vm.workspaceTab.key,
                  attrs: { value: `tab-${_vm.workspaceTab.key}` }
                },
                [
                  !_vm.allTimesheetRows.length
                    ? _c("fd-alert", {
                        class: "mx-3",
                        attrs: {
                          label: _vm.$t(
                            "timesheets.existing.no-workspace-rows"
                          ),
                          type: "info",
                          color: "primary"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { accordion: "" },
                      model: {
                        value: _vm.panel,
                        callback: function($$v) {
                          _vm.panel = $$v
                        },
                        expression: "panel"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        {
                          staticClass: "secondary-section-style",
                          class: [
                            { "d-none": !_vm.hasWorkOrderDirectRows },
                            "fd-timesheet-grouping-color-work-order"
                          ],
                          attrs: { disabled: _vm.isProcessing }
                        },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(_vm._s(_vm.workOrderTableHeader))
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-container",
                                { staticClass: "pa-0", attrs: { fluid: "" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-data-table", {
                                        ref: "workOrderdatatable",
                                        staticClass:
                                          "fd-timesheet-entries-table fixed-header v-table__overflow",
                                        staticStyle: {
                                          "backface-visibility": "hidden"
                                        },
                                        attrs: {
                                          loading: _vm.isProcessing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0",
                                          "item-class":
                                            _vm.timesheetRowClassName,
                                          "items-per-page": -1,
                                          "footer-props": {
                                            "items-per-page-options": [-1]
                                          },
                                          "hide-default-footer": "",
                                          items: _vm.workOrderTimesheetRows,
                                          headers: _vm.workOrderTableHeaders,
                                          "disable-sort": "",
                                          "group-by": "workOrderNumber"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header.empty",
                                              fn: function() {
                                                return [
                                                  !!_vm.timesheet &&
                                                  !!_vm.timesheet
                                                    .timesheetRows &&
                                                  _vm.timesheet.timesheetRows
                                                    .length > 0
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.$vuetify
                                                                    .breakpoint
                                                                    .lgAndUp ||
                                                                  _vm.isProcessing,
                                                                left: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function({
                                                                      on
                                                                    }) {
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                tile:
                                                                                  "",
                                                                                color:
                                                                                  "primary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.toggleTableGroups(
                                                                                    "workOrder",
                                                                                    _vm.allGroupsExpanded(
                                                                                      "workOrder"
                                                                                    )
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                domProps: {
                                                                                  textContent: _vm._s(
                                                                                    !_vm.allGroupsExpanded(
                                                                                      "workOrder"
                                                                                    )
                                                                                      ? "mdi-plus-box-multiple-outline"
                                                                                      : "mdi-minus-box-multiple-outline"
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                950123080
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    !_vm.allGroupsExpanded(
                                                                      "workOrder"
                                                                    )
                                                                      ? _vm.$t(
                                                                          "common.expand-all"
                                                                        )
                                                                      : _vm.$t(
                                                                          "common.collapse-all"
                                                                        )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "item.empty",
                                              fn: function({ item }) {
                                                return [
                                                  item.isCorrectionRow
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small":
                                                              _vm.$vuetify
                                                                .breakpoint.sm,
                                                            small:
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-highlighter-line"
                                                          )
                                                        ]
                                                      )
                                                    : _c("div", [_vm._v(" ")])
                                                ]
                                              }
                                            },
                                            {
                                              key:
                                                "item.classificationDisplayName",
                                              fn: function({ item }) {
                                                return [
                                                  _vm.classificationsForRow(
                                                    item
                                                  ).length > 1
                                                    ? _c("v-select", {
                                                        attrs: {
                                                          value:
                                                            item.classificationID,
                                                          items: _vm.groupedClassificationsForRow(
                                                            item
                                                          ),
                                                          "item-text":
                                                            "displayName",
                                                          "item-value": "id",
                                                          "return-object": "",
                                                          disabled:
                                                            _vm.isProcessing ||
                                                            _vm.timesheetIsReadonly
                                                        },
                                                        on: {
                                                          change: v =>
                                                            _vm.classificationSelected(
                                                              item,
                                                              v
                                                            )
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "selection",
                                                              fn: function() {
                                                                return [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        item.classificationDisplayName
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    : _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.classificationDisplayName
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.workOrderNumber",
                                              fn: function({ item }) {
                                                return [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: _vm.workOrderNumberTextForRow(
                                                        item
                                                      ),
                                                      "no-value-text": _vm.workOrderPlaceholderTextForRow(
                                                        item
                                                      )
                                                    }
                                                  })
                                                ]
                                              }
                                            },
                                            {
                                              key: "group.header",
                                              fn: function({
                                                group,
                                                items,
                                                isOpen,
                                                toggle
                                              }) {
                                                return [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fd-table-group-first-column fd-table-frozen-column",
                                                      attrs: { colspan: "4" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              ref: `workOrdergrouptoggle${group}`,
                                                              attrs: {
                                                                icon: ""
                                                              },
                                                              on: {
                                                                click: toggle
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    isOpen
                                                                      ? "mdi-minus"
                                                                      : "mdi-plus"
                                                                  )
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "timesheets.existing.person-group-label",
                                                                  [group]
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _vm._v(" "),
                                                          !!_vm.scaffoldNumberForGroup(
                                                            group
                                                          ).length &&
                                                          _vm.$vuetify
                                                            .breakpoint.smAndUp
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2",
                                                                      class: {
                                                                        "px-1":
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .sm
                                                                      },
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                        "x-small":
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .sm,
                                                                        small:
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .mdAndUp
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.scaffoldNumberForGroup(
                                                                              group
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          !!_vm.clientWorkOrderNumberForGroup(
                                                            group
                                                          ).length &&
                                                          _vm.$vuetify
                                                            .breakpoint.smAndUp
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2",
                                                                      class: {
                                                                        "px-1":
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .sm
                                                                      },
                                                                      attrs: {
                                                                        color: _vm.$store.getters.colour(
                                                                          "client-work-order-highlight"
                                                                        ),
                                                                        "x-small":
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .sm,
                                                                        small:
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .mdAndUp
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1",
                                                                          attrs: {
                                                                            "x-small":
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .sm,
                                                                            small:
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .mdAndUp,
                                                                            color: _vm.$store.getters.colour(
                                                                              "client-work-order"
                                                                            )
                                                                          },
                                                                          domProps: {
                                                                            textContent: _vm._s(
                                                                              `fas ${_vm.$store.getters.icon(
                                                                                "client-work-order"
                                                                              )}`
                                                                            )
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.clientWorkOrderNumberForGroup(
                                                                              group
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          !!_vm.serviceOrderNumberForGroup(
                                                            group
                                                          ).length &&
                                                          _vm.$vuetify
                                                            .breakpoint.smAndUp
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2",
                                                                      class: {
                                                                        "px-1":
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .sm
                                                                      },
                                                                      attrs: {
                                                                        color: _vm.$store.getters.colour(
                                                                          "service-order-highlight"
                                                                        ),
                                                                        "x-small":
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .sm,
                                                                        small:
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .mdAndUp
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1",
                                                                          attrs: {
                                                                            "x-small":
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .sm,
                                                                            small:
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .mdAndUp,
                                                                            color: _vm.$store.getters.colour(
                                                                              "service-order"
                                                                            )
                                                                          },
                                                                          domProps: {
                                                                            textContent: _vm._s(
                                                                              `fas ${_vm.$store.getters.icon(
                                                                                "service-order"
                                                                              )}`
                                                                            )
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.serviceOrderNumberForGroup(
                                                                              group
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          !!_vm.purchaseOrderNumberForGroup(
                                                            group
                                                          ).length &&
                                                          _vm.$vuetify
                                                            .breakpoint.smAndUp
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2",
                                                                      class: {
                                                                        "px-1":
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .sm
                                                                      },
                                                                      attrs: {
                                                                        outlined:
                                                                          "",
                                                                        "x-small":
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .sm,
                                                                        small:
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .mdAndUp
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1",
                                                                          attrs: {
                                                                            "x-small":
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .sm,
                                                                            small:
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .mdAndUp
                                                                          },
                                                                          domProps: {
                                                                            textContent: _vm._s(
                                                                              `fas fa-dollar-sign`
                                                                            )
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.purchaseOrderNumberForGroup(
                                                                              group
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.workOrderWorkSubTypes,
                                                    function(wst) {
                                                      return _c(
                                                        "td",
                                                        {
                                                          key: wst.id,
                                                          staticClass:
                                                            "fd-restrict-table-entry-column-width"
                                                        },
                                                        [
                                                          !isOpen
                                                            ? _c(
                                                                "sp-timesheet-time-display",
                                                                {
                                                                  attrs: {
                                                                    times: _vm.sumRowTimeValues(
                                                                      items,
                                                                      wst.id
                                                                    ),
                                                                    "no-value-key":
                                                                      "timesheets.list.no-time-value"
                                                                  }
                                                                }
                                                              )
                                                            : _c(
                                                                "v-text-field",
                                                                {
                                                                  key: wst.id,
                                                                  staticClass:
                                                                    "fd-less-short-table-input",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isProcessing ||
                                                                      _vm.timesheetIsReadonly,
                                                                    outlined: false,
                                                                    "highlight-on-focus":
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: v =>
                                                                      _vm.updateAllWorkOrderItemValues(
                                                                        group,
                                                                        wst.id,
                                                                        v
                                                                      )
                                                                  }
                                                                }
                                                              )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.hasWorkOrderDirectEquipmentRows
                                                    ? _c("td", {
                                                        attrs: { colspan: "1" }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                                      attrs: { colspan: 1 }
                                                    },
                                                    [
                                                      !isOpen
                                                        ? _c(
                                                            "sp-timesheet-time-display",
                                                            {
                                                              attrs: {
                                                                times: _vm.calculateTotalForRows(
                                                                  items
                                                                ),
                                                                "no-value-key":
                                                                  "timesheets.list.no-time-value",
                                                                "no-value-text-align":
                                                                  "right",
                                                                "text-align":
                                                                  "right"
                                                              }
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  !!_vm.perDiemSubTypeIsWorkOrderRelated
                                                    ? _c(
                                                        "td",
                                                        {
                                                          attrs: { colspan: 1 }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " \n                      "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    { attrs: { colspan: 1 } },
                                                    [_vm._v(" ")]
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.equipmentHours",
                                              fn: function({ item }) {
                                                return [
                                                  !_vm.canEditEquipment(item)
                                                    ? _c("fd-value-display", {
                                                        attrs: {
                                                          value: _vm.$format.number(
                                                            item.equipmentHours
                                                          ),
                                                          "no-value-key":
                                                            "timesheets.list.no-time-entry-value",
                                                          "no-value-style":
                                                            "text-align: center;"
                                                        }
                                                      })
                                                    : _c("fd-text-field", {
                                                        ref: _vm.getFieldRef(
                                                          "workOrder",
                                                          "equipmentHours",
                                                          item
                                                        ),
                                                        staticClass:
                                                          "fd-short-table-input",
                                                        attrs: {
                                                          disabled:
                                                            _vm.isProcessing ||
                                                            !_vm.rowIsEditable(
                                                              item
                                                            ),
                                                          numeric: "",
                                                          outlined: false,
                                                          "highlight-on-focus":
                                                            "",
                                                          rules: _vm.timesheetRowRules(
                                                            item
                                                          ),
                                                          type: "number",
                                                          "hide-spin-buttons":
                                                            ""
                                                        },
                                                        on: {
                                                          keydown: [
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "up",
                                                                  38,
                                                                  $event.key,
                                                                  [
                                                                    "Up",
                                                                    "ArrowUp"
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return (e =>
                                                                _vm.selectPreviousField(
                                                                  e,
                                                                  "workOrder",
                                                                  "equipmentHours",
                                                                  item
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "down",
                                                                  40,
                                                                  $event.key,
                                                                  [
                                                                    "Down",
                                                                    "ArrowDown"
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return (e =>
                                                                _vm.selectNextField(
                                                                  e,
                                                                  "workOrder",
                                                                  "equipmentHours",
                                                                  item
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return (e =>
                                                                _vm.enterPressed(
                                                                  e,
                                                                  "workOrder",
                                                                  "equipmentHours",
                                                                  item
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            }
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            item.equipmentHours,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "equipmentHours",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.equipmentHours"
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            _vm._l(
                                              _vm.workOrderWorkSubTypes,
                                              function(wst) {
                                                return {
                                                  key: `item.${wst.id}`,
                                                  fn: function({ item }) {
                                                    return [
                                                      !!_vm.rowCanUseWorkSubType(
                                                        item,
                                                        wst.id
                                                      ) &&
                                                      wst.isParent &&
                                                      !!wst
                                                        .workOrderRelatedChildSubTypes
                                                        .length
                                                        ? _c(
                                                            "sp-timesheet-work-sub-type-hours-entry",
                                                            {
                                                              ref: _vm.getFieldRef(
                                                                "workOrder",
                                                                wst.id,
                                                                item
                                                              ),
                                                              refInFor: true,
                                                              attrs: {
                                                                disabled:
                                                                  _vm.processing,
                                                                processing:
                                                                  _vm.processing,
                                                                readonly: !_vm.rowIsEditable(
                                                                  item
                                                                ),
                                                                value: _vm.getTimeSummaryStringForWorkSubTypeInRow(
                                                                  item,
                                                                  wst.id
                                                                ),
                                                                row: item,
                                                                workSubType: wst,
                                                                childSubTypes:
                                                                  wst.workOrderRelatedChildSubTypes,
                                                                workOrderNumber:
                                                                  item.workOrderNumber
                                                              },
                                                              on: {
                                                                "change:hours": (
                                                                  wstid,
                                                                  v
                                                                ) =>
                                                                  _vm.workSubTypeHoursValueChanged(
                                                                    item,
                                                                    wstid,
                                                                    v
                                                                  ),
                                                                "navigate:back": wstid =>
                                                                  _vm.navigateBackFromMultiEntryControl(
                                                                    "workOrder",
                                                                    wstid,
                                                                    item
                                                                  ),
                                                                "navigate:forward": wstid =>
                                                                  _vm.navigateForwardFromMultiEntryControl(
                                                                    "workOrder",
                                                                    wstid,
                                                                    item
                                                                  )
                                                              }
                                                            }
                                                          )
                                                        : !_vm.rowCanEditWorkSubType(
                                                            item,
                                                            wst.id
                                                          )
                                                        ? _c(
                                                            "sp-timesheet-time-display",
                                                            {
                                                              attrs: {
                                                                times: _vm.getTimesForWorkSubTypeInRow(
                                                                  item,
                                                                  wst.id
                                                                ),
                                                                "single-line":
                                                                  ""
                                                              }
                                                            }
                                                          )
                                                        : _c("v-text-field", {
                                                            key: wst.id,
                                                            ref: _vm.getFieldRef(
                                                              "workOrder",
                                                              wst.id,
                                                              item
                                                            ),
                                                            refInFor: true,
                                                            staticClass:
                                                              "fd-less-short-table-input",
                                                            attrs: {
                                                              value: _vm.getTimeSummaryStringForWorkSubTypeInRow(
                                                                item,
                                                                wst.id
                                                              ),
                                                              disabled:
                                                                _vm.isProcessing ||
                                                                !_vm.rowIsEditable(
                                                                  item
                                                                ),
                                                              outlined: false,
                                                              "highlight-on-focus":
                                                                "",
                                                              rules: _vm.timesheetRowRules(
                                                                item
                                                              )
                                                            },
                                                            on: {
                                                              change: v =>
                                                                _vm.workSubTypeHoursValueChanged(
                                                                  item,
                                                                  wst.id,
                                                                  v
                                                                ),
                                                              keydown: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp"
                                                                      ]
                                                                    )
                                                                  )
                                                                    return null
                                                                  return (e =>
                                                                    _vm.selectPreviousField(
                                                                      e,
                                                                      "workOrder",
                                                                      wst.id,
                                                                      item
                                                                    )).apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown"
                                                                      ]
                                                                    )
                                                                  )
                                                                    return null
                                                                  return (e =>
                                                                    _vm.selectNextField(
                                                                      e,
                                                                      "workOrder",
                                                                      wst.id,
                                                                      item
                                                                    )).apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  )
                                                                    return null
                                                                  return (e =>
                                                                    _vm.enterPressed(
                                                                      e,
                                                                      "workOrder",
                                                                      wst.id,
                                                                      item
                                                                    )).apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                }
                                                              ]
                                                            }
                                                          })
                                                    ]
                                                  }
                                                }
                                              }
                                            ),
                                            {
                                              key: "item.total",
                                              fn: function({ item }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fd-table-column-text-end-override",
                                                      staticStyle: {
                                                        position: "relative"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "ml-1",
                                                                          class: {
                                                                            "d-none": !item
                                                                              .errorMessage
                                                                              .length
                                                                          },
                                                                          staticStyle: {
                                                                            position:
                                                                              "absolute",
                                                                            left:
                                                                              "-15px"
                                                                          },
                                                                          attrs: {
                                                                            color:
                                                                              "error",
                                                                            dark:
                                                                              "",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              info\n                            "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.errorMessage
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "sp-timesheet-time-display",
                                                        {
                                                          attrs: {
                                                            times: _vm.calculateTotalForRow(
                                                              item
                                                            ),
                                                            "display-zero-value":
                                                              ""
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.perdiem",
                                              fn: function({ item }) {
                                                return [
                                                  _c("sp-timesheet-perdiem", {
                                                    attrs: {
                                                      item: item,
                                                      disabled: _vm.processing,
                                                      readonly:
                                                        _vm.timesheetIsReadonly,
                                                      perDiemIsWorkOrderRelated:
                                                        _vm.perDiemSubTypeIsWorkOrderRelated,
                                                      otherPerDiemTimesheetOwner: _vm.perDiemOwnerFromOtherTimesheet(
                                                        item.employeeID
                                                      ),
                                                      otherRowOnTimesheetHasPerDiem: _vm.hasExistingOtherUncorrectedPerDiemRowForEmployee(
                                                        item
                                                      ),
                                                      otherPerDiemRowWorkOrderNumber: _vm.otherPerDiemRowWorkOrderNumber(
                                                        item
                                                      )
                                                    },
                                                    on: {
                                                      "update:hasPerDiem": v => {
                                                        item.hasPerDiem = v
                                                        _vm.perDiemValueChanged(
                                                          item
                                                        )
                                                      },
                                                      "update:removePerDiem": v => {
                                                        item.removePerDiem = v
                                                        _vm.perDiemValueChanged(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              }
                                            },
                                            {
                                              key: "body.append",
                                              fn: function({ items }) {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "fd-table-summary-row"
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-start fd-table-frozen-column fd-table-summary-row-first-column",
                                                          attrs: {
                                                            colspan: "4"
                                                          }
                                                        },
                                                        [
                                                          _c("fd-alert", {
                                                            staticClass:
                                                              "pa-0 mt-0",
                                                            attrs: {
                                                              dense: true,
                                                              label: _vm.$t(
                                                                "timesheets.existing.group-totals-label"
                                                              ),
                                                              type: "hint",
                                                              white: ""
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.workOrderWorkSubTypes,
                                                        function(wst) {
                                                          return _c(
                                                            "td",
                                                            { key: wst.id },
                                                            [
                                                              _c(
                                                                "sp-timesheet-time-display",
                                                                {
                                                                  attrs: {
                                                                    times: _vm.sumRowTimeValues(
                                                                      items,
                                                                      wst.id
                                                                    ),
                                                                    "no-value-key":
                                                                      "timesheets.list.no-time-value",
                                                                    "no-value-class":
                                                                      "white--text"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasWorkOrderDirectEquipmentRows
                                                        ? _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: "1"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline"
                                                                  },
                                                                  attrs: {
                                                                    value: _vm.sumEquipmentHours(
                                                                      items
                                                                    ),
                                                                    "no-value-key":
                                                                      "timesheets.list.no-time-value",
                                                                    "no-value-class":
                                                                      "white--text"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                                          attrs: { colspan: 1 }
                                                        },
                                                        [
                                                          _c(
                                                            "sp-timesheet-time-display",
                                                            {
                                                              attrs: {
                                                                times: _vm.calculateTotalForRows(
                                                                  items
                                                                ),
                                                                "no-value-key":
                                                                  "timesheets.list.no-time-value",
                                                                "no-value-class":
                                                                  "white--text",
                                                                "no-value-text-align":
                                                                  "right",
                                                                "text-align":
                                                                  "right"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      !!_vm.perDiemSubTypeIsWorkOrderRelated
                                                        ? _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: 1
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " \n                        "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: { colspan: 1 }
                                                        },
                                                        [_vm._v(" ")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.actions",
                                              fn: function({ item }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "table-2-actions-column-min-width"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.$vuetify
                                                                .breakpoint
                                                                .lgAndUp ||
                                                              _vm.isProcessing,
                                                            top: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-badge",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "red",
                                                                          dot:
                                                                            "",
                                                                          value: !!item.notesCount
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                disabled:
                                                                                  _vm.processing
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  small:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openNotesDialog(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                  mdi-note\n                                "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.notes"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.$vuetify
                                                                .breakpoint
                                                                .lgAndUp ||
                                                              _vm.isProcessing,
                                                            left: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              "",
                                                                            disabled:
                                                                              _vm.isProcessing ||
                                                                              !_vm.rowIsEditable(
                                                                                item
                                                                              )
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeTimesheetRow(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                mdi-delete\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.remove"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        {
                          staticClass: "secondary-section-style",
                          class: [
                            { "d-none": !_vm.hasGeneralDirectRows },
                            "fd-timesheet-grouping-color-non-work-order"
                          ],
                          attrs: { disabled: _vm.isProcessing }
                        },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              _vm._s(
                                `${_vm.$t(
                                  "timesheets.existing.generalized-direct-table-header"
                                )}`.toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-container",
                                { staticClass: "pa-0", attrs: { fluid: "" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-data-table", {
                                        ref: "generalizeddirectdatatable",
                                        staticClass:
                                          "fd-timesheet-entries-table fixed-header v-table__overflow",
                                        staticStyle: {
                                          "backface-visibility": "hidden"
                                        },
                                        attrs: {
                                          loading: _vm.isProcessing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0",
                                          "item-class":
                                            _vm.timesheetRowClassName,
                                          "items-per-page": -1,
                                          "footer-props": {
                                            "items-per-page-options": [-1]
                                          },
                                          "hide-default-footer": "",
                                          items:
                                            _vm.generalizedDirectTimesheetRows,
                                          headers:
                                            _vm.generalizedDirectTableHeaders,
                                          "disable-sort": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header.empty",
                                              fn: function() {
                                                return [
                                                  !!_vm.timesheet &&
                                                  !!_vm.timesheet
                                                    .timesheetRows &&
                                                  _vm.timesheet.timesheetRows
                                                    .length > 0
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.$vuetify
                                                                    .breakpoint
                                                                    .lgAndUp ||
                                                                  _vm.isProcessing,
                                                                left: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function({
                                                                      on
                                                                    }) {
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                tile:
                                                                                  "",
                                                                                color:
                                                                                  "primary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.toggleTableGroups(
                                                                                    "generalizeddirect",
                                                                                    _vm.allGroupsExpanded(
                                                                                      "generalizeddirect"
                                                                                    )
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                domProps: {
                                                                                  textContent: _vm._s(
                                                                                    !_vm.allGroupsExpanded(
                                                                                      "generalizeddirect"
                                                                                    )
                                                                                      ? "mdi-plus-box-multiple-outline"
                                                                                      : "mdi-minus-box-multiple-outline"
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                3611213444
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    !_vm.allGroupsExpanded(
                                                                      "generalizeddirect"
                                                                    )
                                                                      ? _vm.$t(
                                                                          "common.expand-all"
                                                                        )
                                                                      : _vm.$t(
                                                                          "common.collapse-all"
                                                                        )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "item.icon",
                                              fn: function({ item }) {
                                                return [
                                                  item.isCorrectionRow
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small":
                                                              _vm.$vuetify
                                                                .breakpoint.sm,
                                                            small:
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-highlighter-line"
                                                          )
                                                        ]
                                                      )
                                                    : _c("div", [_vm._v(" ")])
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.perdiem",
                                              fn: function({ item }) {
                                                return [
                                                  _c("sp-timesheet-perdiem", {
                                                    attrs: {
                                                      item: item,
                                                      disabled: _vm.processing,
                                                      readonly:
                                                        _vm.timesheetIsReadonly,
                                                      perDiemIsWorkOrderRelated:
                                                        _vm.perDiemSubTypeIsWorkOrderRelated,
                                                      otherPerDiemTimesheetOwner: _vm.perDiemOwnerFromOtherTimesheet(
                                                        item.employeeID
                                                      ),
                                                      otherRowOnTimesheetHasPerDiem: _vm.hasExistingOtherUncorrectedPerDiemRowForEmployee(
                                                        item
                                                      ),
                                                      otherPerDiemRowWorkOrderNumber: _vm.otherPerDiemRowWorkOrderNumber(
                                                        item
                                                      )
                                                    },
                                                    on: {
                                                      "update:hasPerDiem": v => {
                                                        item.hasPerDiem = v
                                                        _vm.perDiemValueChanged(
                                                          item
                                                        )
                                                      },
                                                      "update:removePerDiem": v => {
                                                        item.removePerDiem = v
                                                        _vm.perDiemValueChanged(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              }
                                            },
                                            {
                                              key:
                                                "item.classificationDisplayName",
                                              fn: function({ item }) {
                                                return [
                                                  _vm.classificationsForRow(
                                                    item
                                                  ).length > 1
                                                    ? _c("v-select", {
                                                        attrs: {
                                                          value:
                                                            item.classificationID,
                                                          items: _vm.groupedClassificationsForRow(
                                                            item
                                                          ),
                                                          "item-text":
                                                            "displayName",
                                                          "item-value": "id",
                                                          "return-object": "",
                                                          disabled:
                                                            _vm.isProcessing ||
                                                            _vm.timesheetIsReadonly
                                                        },
                                                        on: {
                                                          change: v =>
                                                            _vm.classificationSelected(
                                                              item,
                                                              v
                                                            )
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "selection",
                                                              fn: function() {
                                                                return [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        item.classificationDisplayName
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    : _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.classificationDisplayName
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.areaName",
                                              fn: function({ item }) {
                                                return [
                                                  _vm.rowIsEditable(item)
                                                    ? _c("v-select", {
                                                        staticClass:
                                                          "fd-table-select",
                                                        attrs: {
                                                          items:
                                                            _vm.selectableAreas,
                                                          "item-text": "name",
                                                          "item-value": "id"
                                                        },
                                                        model: {
                                                          value: item.areaID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "areaID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.areaID"
                                                        }
                                                      })
                                                    : _c("fd-value-display", {
                                                        attrs: {
                                                          value: item.areaName
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.subAreaName",
                                              fn: function({ item }) {
                                                return [
                                                  _vm.rowIsEditable(item)
                                                    ? _c("v-select", {
                                                        staticClass:
                                                          "fd-table-select",
                                                        attrs: {
                                                          items: _vm.selectableSubAreasForRow(
                                                            item
                                                          ),
                                                          "item-text": "name",
                                                          "item-value": "id"
                                                        },
                                                        model: {
                                                          value: item.subAreaID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "subAreaID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.subAreaID"
                                                        }
                                                      })
                                                    : _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.subAreaName
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.equipmentHours",
                                              fn: function({ item }) {
                                                return [
                                                  !_vm.canEditEquipment(item)
                                                    ? _c("fd-value-display", {
                                                        attrs: {
                                                          value: _vm.$format.number(
                                                            item.equipmentHours
                                                          ),
                                                          "no-value-key":
                                                            "timesheets.list.no-time-entry-value",
                                                          "no-value-style":
                                                            "text-align: center;"
                                                        }
                                                      })
                                                    : _c("fd-text-field", {
                                                        ref: _vm.getFieldRef(
                                                          "summary",
                                                          "equipmentHours",
                                                          item
                                                        ),
                                                        staticClass:
                                                          "fd-short-table-input",
                                                        attrs: {
                                                          disabled:
                                                            _vm.isProcessing ||
                                                            !_vm.rowIsEditable(
                                                              item
                                                            ),
                                                          numeric: "",
                                                          outlined: false,
                                                          "highlight-on-focus":
                                                            "",
                                                          rules: _vm.timesheetRowRules(
                                                            item
                                                          ),
                                                          type: "number",
                                                          "hide-spin-buttons":
                                                            ""
                                                        },
                                                        on: {
                                                          keydown: [
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "up",
                                                                  38,
                                                                  $event.key,
                                                                  [
                                                                    "Up",
                                                                    "ArrowUp"
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return (e =>
                                                                _vm.selectPreviousField(
                                                                  e,
                                                                  "summary",
                                                                  "equipmentHours",
                                                                  item
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "down",
                                                                  40,
                                                                  $event.key,
                                                                  [
                                                                    "Down",
                                                                    "ArrowDown"
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return (e =>
                                                                _vm.selectNextField(
                                                                  e,
                                                                  "summary",
                                                                  "equipmentHours",
                                                                  item
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return (e =>
                                                                _vm.enterPressed(
                                                                  e,
                                                                  "summary",
                                                                  "equipmentHours",
                                                                  item
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            }
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            item.equipmentHours,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "equipmentHours",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.equipmentHours"
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            _vm._l(
                                              _vm.generalizedDirectWorkSubTypes,
                                              function(wst) {
                                                return {
                                                  key: `item.${wst.id}`,
                                                  fn: function({ item }) {
                                                    return [
                                                      !!_vm.rowCanUseWorkSubType(
                                                        item,
                                                        wst.id
                                                      ) &&
                                                      wst.isParent &&
                                                      !!wst
                                                        .nonWorkOrderRelatedChildSubTypes
                                                        .length
                                                        ? _c(
                                                            "sp-timesheet-work-sub-type-hours-entry",
                                                            {
                                                              ref: _vm.getFieldRef(
                                                                "generalizeddirect",
                                                                wst.id,
                                                                item
                                                              ),
                                                              refInFor: true,
                                                              attrs: {
                                                                disabled:
                                                                  _vm.processing,
                                                                processing:
                                                                  _vm.processing,
                                                                readonly: !_vm.rowIsEditable(
                                                                  item
                                                                ),
                                                                value: _vm.getTimeSummaryStringForWorkSubTypeInRow(
                                                                  item,
                                                                  wst.id
                                                                ),
                                                                row: item,
                                                                workSubType: wst,
                                                                childSubTypes:
                                                                  wst.nonWorkOrderRelatedChildSubTypes,
                                                                workOrderNumber:
                                                                  item.workOrderNumber
                                                              },
                                                              on: {
                                                                "change:hours": (
                                                                  wstid,
                                                                  v
                                                                ) =>
                                                                  _vm.workSubTypeHoursValueChanged(
                                                                    item,
                                                                    wstid,
                                                                    v
                                                                  ),
                                                                "navigate:back": wstid =>
                                                                  _vm.navigateBackFromMultiEntryControl(
                                                                    "generalizeddirect",
                                                                    wstid,
                                                                    item
                                                                  ),
                                                                "navigate:forward": wstid =>
                                                                  _vm.navigateForwardFromMultiEntryControl(
                                                                    "generalizeddirect",
                                                                    wstid,
                                                                    item
                                                                  )
                                                              }
                                                            }
                                                          )
                                                        : !_vm.rowCanEditWorkSubType(
                                                            item,
                                                            wst.id
                                                          )
                                                        ? _c(
                                                            "sp-timesheet-time-display",
                                                            {
                                                              key: wst.id,
                                                              attrs: {
                                                                times: _vm.getTimesForWorkSubTypeInRow(
                                                                  item,
                                                                  wst.id
                                                                ),
                                                                "single-line":
                                                                  ""
                                                              }
                                                            }
                                                          )
                                                        : _c("v-text-field", {
                                                            ref: _vm.getFieldRef(
                                                              "generalizeddirect",
                                                              wst.id,
                                                              item
                                                            ),
                                                            refInFor: true,
                                                            staticClass:
                                                              "fd-less-short-table-input",
                                                            attrs: {
                                                              value: _vm.getTimeSummaryStringForWorkSubTypeInRow(
                                                                item,
                                                                wst.id
                                                              ),
                                                              disabled:
                                                                _vm.isProcessing ||
                                                                !_vm.rowIsEditable(
                                                                  item
                                                                ),
                                                              outlined: false,
                                                              "highlight-on-focus":
                                                                "",
                                                              rules: _vm.timesheetRowRules(
                                                                item
                                                              )
                                                            },
                                                            on: {
                                                              change: v =>
                                                                _vm.workSubTypeHoursValueChanged(
                                                                  item,
                                                                  wst.id,
                                                                  v
                                                                ),
                                                              keydown: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp"
                                                                      ]
                                                                    )
                                                                  )
                                                                    return null
                                                                  return (e =>
                                                                    _vm.selectPreviousField(
                                                                      e,
                                                                      "generalizeddirect",
                                                                      wst.id,
                                                                      item
                                                                    )).apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown"
                                                                      ]
                                                                    )
                                                                  )
                                                                    return null
                                                                  return (e =>
                                                                    _vm.selectNextField(
                                                                      e,
                                                                      "generalizeddirect",
                                                                      wst.id,
                                                                      item
                                                                    )).apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  )
                                                                    return null
                                                                  return (e =>
                                                                    _vm.enterPressed(
                                                                      e,
                                                                      "generalizeddirect",
                                                                      wst.id,
                                                                      item
                                                                    )).apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                }
                                                              ]
                                                            }
                                                          })
                                                    ]
                                                  }
                                                }
                                              }
                                            ),
                                            {
                                              key: "item.total",
                                              fn: function({ item }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fd-table-column-text-end-override",
                                                      staticStyle: {
                                                        position: "relative"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "ml-1",
                                                                          class: {
                                                                            "d-none": !item
                                                                              .errorMessage
                                                                              .length
                                                                          },
                                                                          staticStyle: {
                                                                            position:
                                                                              "absolute",
                                                                            left:
                                                                              "-15px"
                                                                          },
                                                                          attrs: {
                                                                            color:
                                                                              "error",
                                                                            dark:
                                                                              "",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              info\n                            "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.errorMessage
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "sp-timesheet-time-display",
                                                        {
                                                          attrs: {
                                                            times: _vm.calculateTotalForRow(
                                                              item
                                                            ),
                                                            "display-zero-value":
                                                              ""
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "body.prepend",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "fd-table-header-row"
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "fd-table-group-first-column fd-table-frozen-column",
                                                          attrs: {
                                                            colspan: "6"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                           "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.generalizedDirectWorkSubTypes,
                                                        function(wst) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              key: wst.id,
                                                              staticClass:
                                                                "fd-restrict-table-entry-column-width"
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  key: wst.id,
                                                                  staticClass:
                                                                    "fd-less-short-table-input",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isProcessing ||
                                                                      _vm.timesheetIsReadonly,
                                                                    outlined: false,
                                                                    "highlight-on-focus":
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: v =>
                                                                      _vm.updateAllNonWorkOrderItemValues(
                                                                        wst.id,
                                                                        v
                                                                      )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasGeneralDirectEquipmentRows
                                                        ? _c("td", {
                                                            attrs: {
                                                              colspan: "1"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: { colspan: 3 }
                                                        },
                                                        [_vm._v(" ")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "body.append",
                                              fn: function({ items }) {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "fd-table-summary-row"
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-start fd-table-frozen-column fd-table-summary-row-first-column",
                                                          attrs: {
                                                            colspan: "6"
                                                          }
                                                        },
                                                        [
                                                          _c("fd-alert", {
                                                            staticClass:
                                                              "pa-0 mt-0",
                                                            attrs: {
                                                              dense: true,
                                                              label: _vm.$t(
                                                                "timesheets.existing.group-totals-label"
                                                              ),
                                                              type: "hint",
                                                              white: ""
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.generalizedDirectWorkSubTypes,
                                                        function(wst) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              key: wst.id,
                                                              staticClass:
                                                                "fd-restrict-table-entry-column-width"
                                                            },
                                                            [
                                                              _c(
                                                                "sp-timesheet-time-display",
                                                                {
                                                                  attrs: {
                                                                    times: _vm.sumRowTimeValues(
                                                                      items,
                                                                      wst.id
                                                                    ),
                                                                    "no-value-key":
                                                                      "timesheets.list.no-time-value",
                                                                    "no-value-class":
                                                                      "white--text"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasGeneralDirectEquipmentRows
                                                        ? _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: "1"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline"
                                                                  },
                                                                  attrs: {
                                                                    value: _vm.sumEquipmentHours(
                                                                      items
                                                                    ),
                                                                    "no-value-key":
                                                                      "timesheets.list.no-time-value",
                                                                    "no-value-class":
                                                                      "white--text"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                                          attrs: { colspan: 1 }
                                                        },
                                                        [
                                                          _c(
                                                            "sp-timesheet-time-display",
                                                            {
                                                              attrs: {
                                                                times: _vm.calculateTotalForRows(
                                                                  items
                                                                ),
                                                                "no-value-key":
                                                                  "timesheets.list.no-time-value",
                                                                "no-value-class":
                                                                  "white--text",
                                                                "no-value-text-align":
                                                                  "right",
                                                                "text-align":
                                                                  "right"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      !!_vm.perDiemSubType
                                                        ? _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                                              attrs: {
                                                                colspan: 1
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline"
                                                                  },
                                                                  attrs: {
                                                                    value: _vm.countPerDiems(
                                                                      items
                                                                    ),
                                                                    "no-value-key":
                                                                      "timesheets.list.no-entries-value"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: { colspan: 1 }
                                                        },
                                                        [_vm._v(" ")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.actions",
                                              fn: function({ item }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "table-3-actions-column-min-width"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.$vuetify
                                                                .breakpoint
                                                                .lgAndUp ||
                                                              _vm.isProcessing ||
                                                              !_vm.canAddNewRowFromRow(
                                                                item
                                                              ),
                                                            top: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              "",
                                                                            disabled:
                                                                              _vm.isProcessing ||
                                                                              !_vm.canAddNewRowFromRow(
                                                                                item
                                                                              ) ||
                                                                              !_vm.rowIsEditable(
                                                                                item
                                                                              )
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.addIndirectRowRelatedToExistingRow(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                mdi-plus-circle\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.add"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.$vuetify
                                                                .breakpoint
                                                                .lgAndUp ||
                                                              _vm.isProcessing,
                                                            top: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-badge",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "red",
                                                                          dot:
                                                                            "",
                                                                          value: !!item.notesCount
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                disabled:
                                                                                  _vm.processing
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  small:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openNotesDialog(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                  mdi-note\n                                "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.notes"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.$vuetify
                                                                .breakpoint
                                                                .lgAndUp ||
                                                              _vm.isProcessing,
                                                            top: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              "",
                                                                            disabled:
                                                                              _vm.isProcessing ||
                                                                              !_vm.rowIsEditable(
                                                                                item
                                                                              )
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeTimesheetRow(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                mdi-delete\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.remove"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        {
                          staticClass: "secondary-section-style",
                          class: { "d-none": !_vm.hasIndirectRows },
                          attrs: { disabled: _vm.isProcessing }
                        },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(_vm._s(_vm.indirectTableHeader))
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-container",
                                { staticClass: "pa-0", attrs: { fluid: "" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-data-table", {
                                        ref: "indirectdatatable",
                                        staticClass:
                                          "fd-timesheet-entries-table fixed-header v-table__overflow",
                                        staticStyle: {
                                          "backface-visibility": "hidden"
                                        },
                                        attrs: {
                                          loading: _vm.isProcessing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0",
                                          "item-class":
                                            _vm.timesheetRowClassName,
                                          "items-per-page": -1,
                                          "footer-props": {
                                            "items-per-page-options": [-1]
                                          },
                                          "hide-default-footer": "",
                                          items: _vm.indirectTimesheetRows,
                                          headers: _vm.indirectTableHeaders,
                                          "disable-sort": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header.empty",
                                              fn: function() {
                                                return [
                                                  !!_vm.timesheet &&
                                                  !!_vm.timesheet
                                                    .timesheetRows &&
                                                  _vm.timesheet.timesheetRows
                                                    .length > 0
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.$vuetify
                                                                    .breakpoint
                                                                    .lgAndUp ||
                                                                  _vm.isProcessing,
                                                                left: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function({
                                                                      on
                                                                    }) {
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                tile:
                                                                                  "",
                                                                                color:
                                                                                  "primary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.toggleTableGroups(
                                                                                    "indirect",
                                                                                    _vm.allGroupsExpanded(
                                                                                      "indirect"
                                                                                    )
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                domProps: {
                                                                                  textContent: _vm._s(
                                                                                    !_vm.allGroupsExpanded(
                                                                                      "indirect"
                                                                                    )
                                                                                      ? "mdi-plus-box-multiple-outline"
                                                                                      : "mdi-minus-box-multiple-outline"
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                857049197
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    !_vm.allGroupsExpanded(
                                                                      "indirect"
                                                                    )
                                                                      ? _vm.$t(
                                                                          "common.expand-all"
                                                                        )
                                                                      : _vm.$t(
                                                                          "common.collapse-all"
                                                                        )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "item.empty",
                                              fn: function({ item }) {
                                                return [
                                                  item.isCorrectionRow
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small":
                                                              _vm.$vuetify
                                                                .breakpoint.sm,
                                                            small:
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-highlighter-line"
                                                          )
                                                        ]
                                                      )
                                                    : _c("div", [_vm._v(" ")])
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.icon",
                                              fn: function({ item }) {
                                                return [
                                                  item.isCorrectionRow
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small":
                                                              _vm.$vuetify
                                                                .breakpoint.sm,
                                                            small:
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-highlighter-line"
                                                          )
                                                        ]
                                                      )
                                                    : _c("div", [_vm._v(" ")])
                                                ]
                                              }
                                            },
                                            {
                                              key:
                                                "item.classificationDisplayName",
                                              fn: function({ item }) {
                                                return [
                                                  _vm.classificationsForRow(
                                                    item
                                                  ).length > 1
                                                    ? _c("v-select", {
                                                        attrs: {
                                                          value:
                                                            item.classificationID,
                                                          items: _vm.groupedClassificationsForRow(
                                                            item
                                                          ),
                                                          "item-text":
                                                            "displayName",
                                                          "item-value": "id",
                                                          "return-object": "",
                                                          disabled:
                                                            _vm.isProcessing ||
                                                            _vm.timesheetIsReadonly
                                                        },
                                                        on: {
                                                          change: v =>
                                                            _vm.classificationSelected(
                                                              item,
                                                              v
                                                            )
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "selection",
                                                              fn: function() {
                                                                return [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        item.classificationDisplayName
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    : _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.classificationDisplayName
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.perdiem",
                                              fn: function({ item }) {
                                                return [
                                                  _c("sp-timesheet-perdiem", {
                                                    attrs: {
                                                      item: item,
                                                      disabled: _vm.processing,
                                                      readonly:
                                                        _vm.timesheetIsReadonly,
                                                      perDiemIsWorkOrderRelated:
                                                        _vm.perDiemSubTypeIsWorkOrderRelated,
                                                      otherPerDiemTimesheetOwner: _vm.perDiemOwnerFromOtherTimesheet(
                                                        item.employeeID
                                                      ),
                                                      otherRowOnTimesheetHasPerDiem: _vm.hasExistingOtherUncorrectedPerDiemRowForEmployee(
                                                        item
                                                      ),
                                                      otherPerDiemRowWorkOrderNumber: _vm.otherPerDiemRowWorkOrderNumber(
                                                        item
                                                      )
                                                    },
                                                    on: {
                                                      "update:hasPerDiem": v => {
                                                        item.hasPerDiem = v
                                                        _vm.perDiemValueChanged(
                                                          item
                                                        )
                                                      },
                                                      "update:removePerDiem": v => {
                                                        item.removePerDiem = v
                                                        _vm.perDiemValueChanged(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.areaName",
                                              fn: function({ item }) {
                                                return [
                                                  _vm.rowIsEditable(item)
                                                    ? _c("v-select", {
                                                        staticClass:
                                                          "fd-table-select",
                                                        attrs: {
                                                          items:
                                                            _vm.selectableAreas,
                                                          "item-text": "name",
                                                          "item-value": "id"
                                                        },
                                                        model: {
                                                          value: item.areaID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "areaID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.areaID"
                                                        }
                                                      })
                                                    : _c("fd-value-display", {
                                                        attrs: {
                                                          value: item.areaName
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.subAreaName",
                                              fn: function({ item }) {
                                                return [
                                                  _vm.rowIsEditable(item)
                                                    ? _c("v-select", {
                                                        staticClass:
                                                          "fd-table-select",
                                                        attrs: {
                                                          items: _vm.selectableSubAreasForRow(
                                                            item
                                                          ),
                                                          "item-text": "name",
                                                          "item-value": "id"
                                                        },
                                                        model: {
                                                          value: item.subAreaID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "subAreaID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.subAreaID"
                                                        }
                                                      })
                                                    : _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.subAreaName
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.equipmentHours",
                                              fn: function({ item }) {
                                                return [
                                                  !_vm.canEditEquipment(item)
                                                    ? _c("fd-value-display", {
                                                        attrs: {
                                                          value: _vm.$format.number(
                                                            item.equipmentHours
                                                          ),
                                                          "no-value-key":
                                                            "timesheets.list.no-time-entry-value",
                                                          "no-value-style":
                                                            "text-align: center;"
                                                        }
                                                      })
                                                    : _c("fd-text-field", {
                                                        ref: _vm.getFieldRef(
                                                          "summary",
                                                          "equipmentHours",
                                                          item
                                                        ),
                                                        staticClass:
                                                          "fd-short-table-input",
                                                        attrs: {
                                                          disabled:
                                                            _vm.isProcessing ||
                                                            !_vm.rowIsEditable(
                                                              item
                                                            ),
                                                          numeric: "",
                                                          outlined: false,
                                                          "highlight-on-focus":
                                                            "",
                                                          rules: _vm.timesheetRowRules(
                                                            item
                                                          ),
                                                          type: "number",
                                                          "hide-spin-buttons":
                                                            ""
                                                        },
                                                        on: {
                                                          keydown: [
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "up",
                                                                  38,
                                                                  $event.key,
                                                                  [
                                                                    "Up",
                                                                    "ArrowUp"
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return (e =>
                                                                _vm.selectPreviousField(
                                                                  e,
                                                                  "summary",
                                                                  "equipmentHours",
                                                                  item
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "down",
                                                                  40,
                                                                  $event.key,
                                                                  [
                                                                    "Down",
                                                                    "ArrowDown"
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return (e =>
                                                                _vm.selectNextField(
                                                                  e,
                                                                  "summary",
                                                                  "equipmentHours",
                                                                  item
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return (e =>
                                                                _vm.enterPressed(
                                                                  e,
                                                                  "summary",
                                                                  "equipmentHours",
                                                                  item
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            }
                                                          ]
                                                        },
                                                        model: {
                                                          value:
                                                            item.equipmentHours,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "equipmentHours",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.equipmentHours"
                                                        }
                                                      })
                                                ]
                                              }
                                            },
                                            _vm._l(
                                              _vm.indirectWorkSubTypes,
                                              function(wst) {
                                                return {
                                                  key: `item.${wst.id}`,
                                                  fn: function({ item }) {
                                                    return [
                                                      !!_vm.rowCanUseWorkSubType(
                                                        item,
                                                        wst.id
                                                      ) &&
                                                      wst.isParent &&
                                                      !!wst.allChildSubTypes
                                                        .length
                                                        ? _c(
                                                            "sp-timesheet-work-sub-type-hours-entry",
                                                            {
                                                              ref: _vm.getFieldRef(
                                                                "indirect",
                                                                wst.id,
                                                                item
                                                              ),
                                                              refInFor: true,
                                                              attrs: {
                                                                disabled:
                                                                  _vm.processing,
                                                                processing:
                                                                  _vm.processing,
                                                                readonly: !_vm.rowIsEditable(
                                                                  item
                                                                ),
                                                                value: _vm.getTimeSummaryStringForWorkSubTypeInRow(
                                                                  item,
                                                                  wst.id
                                                                ),
                                                                row: item,
                                                                workSubType: wst,
                                                                childSubTypes:
                                                                  wst.allChildSubTypes,
                                                                workOrderNumber:
                                                                  item.workOrderNumber
                                                              },
                                                              on: {
                                                                "change:hours": (
                                                                  wstid,
                                                                  v
                                                                ) =>
                                                                  _vm.workSubTypeHoursValueChanged(
                                                                    item,
                                                                    wstid,
                                                                    v
                                                                  ),
                                                                "navigate:back": wstid =>
                                                                  _vm.navigateBackFromMultiEntryControl(
                                                                    "indirect",
                                                                    wstid,
                                                                    item
                                                                  ),
                                                                "navigate:forward": wstid =>
                                                                  _vm.navigateForwardFromMultiEntryControl(
                                                                    "indirect",
                                                                    wstid,
                                                                    item
                                                                  )
                                                              }
                                                            }
                                                          )
                                                        : !_vm.rowCanEditWorkSubType(
                                                            item,
                                                            wst.id
                                                          )
                                                        ? _c(
                                                            "sp-timesheet-time-display",
                                                            {
                                                              key: wst.id,
                                                              attrs: {
                                                                times: _vm.getTimesForWorkSubTypeInRow(
                                                                  item,
                                                                  wst.id
                                                                ),
                                                                "single-line":
                                                                  ""
                                                              }
                                                            }
                                                          )
                                                        : _c("v-text-field", {
                                                            ref: _vm.getFieldRef(
                                                              "indirect",
                                                              wst.id,
                                                              item
                                                            ),
                                                            refInFor: true,
                                                            staticClass:
                                                              "fd-less-short-table-input",
                                                            attrs: {
                                                              value: _vm.getTimeSummaryStringForWorkSubTypeInRow(
                                                                item,
                                                                wst.id
                                                              ),
                                                              disabled:
                                                                _vm.isProcessing ||
                                                                !_vm.rowIsEditable(
                                                                  item
                                                                ),
                                                              outlined: false,
                                                              "highlight-on-focus":
                                                                "",
                                                              rules: _vm.timesheetRowRules(
                                                                item
                                                              )
                                                            },
                                                            on: {
                                                              change: v =>
                                                                _vm.workSubTypeHoursValueChanged(
                                                                  item,
                                                                  wst.id,
                                                                  v
                                                                ),
                                                              keydown: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp"
                                                                      ]
                                                                    )
                                                                  )
                                                                    return null
                                                                  return (e =>
                                                                    _vm.selectPreviousField(
                                                                      e,
                                                                      "indirect",
                                                                      wst.id,
                                                                      item
                                                                    )).apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown"
                                                                      ]
                                                                    )
                                                                  )
                                                                    return null
                                                                  return (e =>
                                                                    _vm.selectNextField(
                                                                      e,
                                                                      "indirect",
                                                                      wst.id,
                                                                      item
                                                                    )).apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  )
                                                                    return null
                                                                  return (e =>
                                                                    _vm.enterPressed(
                                                                      e,
                                                                      "indirect",
                                                                      wst.id,
                                                                      item
                                                                    )).apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                }
                                                              ]
                                                            }
                                                          })
                                                    ]
                                                  }
                                                }
                                              }
                                            ),
                                            {
                                              key: "item.total",
                                              fn: function({ item }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fd-table-column-text-end-override",
                                                      staticStyle: {
                                                        position: "relative"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "ml-1",
                                                                          class: {
                                                                            "d-none": !item
                                                                              .errorMessage
                                                                              .length
                                                                          },
                                                                          staticStyle: {
                                                                            position:
                                                                              "absolute",
                                                                            left:
                                                                              "-15px"
                                                                          },
                                                                          attrs: {
                                                                            color:
                                                                              "error",
                                                                            dark:
                                                                              "",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              info\n                            "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.errorMessage
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "sp-timesheet-time-display",
                                                        {
                                                          attrs: {
                                                            times: _vm.calculateTotalForRow(
                                                              item
                                                            ),
                                                            "display-zero-value":
                                                              ""
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "body.prepend",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "fd-table-header-row"
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "fd-table-group-first-column fd-table-frozen-column",
                                                          attrs: {
                                                            colspan: "6"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                           "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.indirectWorkSubTypes,
                                                        function(wst) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              key: wst.id,
                                                              staticClass:
                                                                "fd-restrict-table-entry-column-width"
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  key: wst.id,
                                                                  staticClass:
                                                                    "fd-less-short-table-input",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isProcessing ||
                                                                      _vm.timesheetIsReadonly,
                                                                    outlined: false,
                                                                    "highlight-on-focus":
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: v =>
                                                                      _vm.updateAllIndirectItemValues(
                                                                        wst.id,
                                                                        v
                                                                      )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasIndirectEquipmentRows
                                                        ? _c("td", {
                                                            attrs: {
                                                              colspan: "1"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: { colspan: 3 }
                                                        },
                                                        [_vm._v(" ")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "body.append",
                                              fn: function({ items }) {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "fd-table-summary-row"
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-start fd-table-frozen-column fd-table-summary-row-first-column",
                                                          attrs: {
                                                            colspan: "6"
                                                          }
                                                        },
                                                        [
                                                          _c("fd-alert", {
                                                            staticClass:
                                                              "pa-0 mt-0",
                                                            attrs: {
                                                              dense: true,
                                                              label: _vm.$t(
                                                                "timesheets.existing.group-totals-label"
                                                              ),
                                                              type: "hint",
                                                              white: ""
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.indirectWorkSubTypes,
                                                        function(wst) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              key: wst.id,
                                                              staticClass:
                                                                "fd-restrict-table-entry-column-width"
                                                            },
                                                            [
                                                              _c(
                                                                "sp-timesheet-time-display",
                                                                {
                                                                  attrs: {
                                                                    times: _vm.sumRowTimeValues(
                                                                      items,
                                                                      wst.id
                                                                    ),
                                                                    "no-value-key":
                                                                      "timesheets.list.no-time-value",
                                                                    "no-value-class":
                                                                      "white--text"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasIndirectEquipmentRows
                                                        ? _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: "1"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline"
                                                                  },
                                                                  attrs: {
                                                                    value: _vm.sumEquipmentHours(
                                                                      items
                                                                    ),
                                                                    "no-value-key":
                                                                      "timesheets.list.no-time-value",
                                                                    "no-value-class":
                                                                      "white--text"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                                          attrs: { colspan: 1 }
                                                        },
                                                        [
                                                          _c(
                                                            "sp-timesheet-time-display",
                                                            {
                                                              attrs: {
                                                                times: _vm.calculateTotalForRows(
                                                                  items
                                                                ),
                                                                "no-value-key":
                                                                  "timesheets.list.no-time-value",
                                                                "no-value-class":
                                                                  "white--text",
                                                                "no-value-text-align":
                                                                  "right",
                                                                "text-align":
                                                                  "right"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      !!_vm.perDiemSubType
                                                        ? _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                                              attrs: {
                                                                colspan: 1
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-value-display",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline"
                                                                  },
                                                                  attrs: {
                                                                    value: _vm.countPerDiems(
                                                                      items
                                                                    ),
                                                                    "no-value-key":
                                                                      "timesheets.list.no-entries-value"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: { colspan: 1 }
                                                        },
                                                        [_vm._v(" ")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.actions",
                                              fn: function({ item }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "table-3-actions-column-min-width"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.$vuetify
                                                                .breakpoint
                                                                .lgAndUp ||
                                                              _vm.isProcessing ||
                                                              !_vm.canAddNewRowFromRow(
                                                                item
                                                              ),
                                                            top: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              "",
                                                                            disabled:
                                                                              _vm.isProcessing ||
                                                                              !_vm.canAddNewRowFromRow(
                                                                                item
                                                                              ) ||
                                                                              !_vm.rowIsEditable(
                                                                                item
                                                                              )
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.addIndirectRowRelatedToExistingRow(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                mdi-plus-circle\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.add"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.$vuetify
                                                                .breakpoint
                                                                .lgAndUp ||
                                                              _vm.isProcessing,
                                                            top: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-badge",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "red",
                                                                          dot:
                                                                            "",
                                                                          value: !!item.notesCount
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                disabled:
                                                                                  _vm.processing
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  small:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openNotesDialog(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                  mdi-note\n                                "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.notes"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.$vuetify
                                                                .breakpoint
                                                                .lgAndUp ||
                                                              _vm.isProcessing,
                                                            top: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function({
                                                                  on
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              "",
                                                                            disabled:
                                                                              _vm.isProcessing ||
                                                                              !_vm.rowIsEditable(
                                                                                item
                                                                              )
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeTimesheetRow(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                mdi-delete\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.remove"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.summaryTab.key,
                  attrs: { value: `tab-${_vm.summaryTab.key}` }
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "div",
                        [
                          _c("v-data-table", {
                            ref: "summarydatatable",
                            staticClass:
                              "fd-timesheet-entries-table fixed-header v-table__overflow",
                            staticStyle: { "backface-visibility": "hidden" },
                            attrs: {
                              loading: _vm.isProcessing,
                              "loading-text": _vm.$t(
                                "common.table-loading-message"
                              ),
                              "mobile-breakpoint": "0",
                              "item-class": _vm.timesheetRowClassName,
                              "items-per-page": -1,
                              "footer-props": {
                                "items-per-page-options": [-1]
                              },
                              "hide-default-footer": "",
                              "group-by": "employeeName",
                              items: _vm.allTimesheetRows,
                              headers: _vm.summaryTableHeaders,
                              "disable-sort": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header.empty",
                                  fn: function() {
                                    return [
                                      !!_vm.timesheet &&
                                      !!_vm.timesheet.timesheetRows &&
                                      _vm.timesheet.timesheetRows.length > 0
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.$vuetify.breakpoint
                                                        .lgAndUp ||
                                                      _vm.isProcessing,
                                                    left: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    tile: "",
                                                                    color:
                                                                      "primary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.toggleTableGroups(
                                                                        "summary",
                                                                        _vm.allGroupsExpanded(
                                                                          "summary"
                                                                        )
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", {
                                                                  domProps: {
                                                                    textContent: _vm._s(
                                                                      !_vm.allGroupsExpanded(
                                                                        "summary"
                                                                      )
                                                                        ? "mdi-plus-box-multiple-outline"
                                                                        : "mdi-minus-box-multiple-outline"
                                                                    )
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1436580683
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        !_vm.allGroupsExpanded(
                                                          "summary"
                                                        )
                                                          ? _vm.$t(
                                                              "common.expand-all"
                                                            )
                                                          : _vm.$t(
                                                              "common.collapse-all"
                                                            )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "item.empty",
                                  fn: function({ item }) {
                                    return [
                                      item.isCorrectionRow
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small":
                                                  _vm.$vuetify.breakpoint.sm,
                                                small:
                                                  _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                              }
                                            },
                                            [_vm._v("fas fa-highlighter-line")]
                                          )
                                        : _c("div", [_vm._v(" ")])
                                    ]
                                  }
                                },
                                {
                                  key: "item.classificationDisplayName",
                                  fn: function({ item }) {
                                    return [
                                      _vm.classificationsForRow(item).length > 1
                                        ? _c("v-select", {
                                            attrs: {
                                              value: item.classificationID,
                                              items: _vm.groupedClassificationsForRow(
                                                item
                                              ),
                                              "item-text": "displayName",
                                              "item-value": "id",
                                              "return-object": "",
                                              disabled:
                                                _vm.isProcessing ||
                                                _vm.timesheetIsReadonly
                                            },
                                            on: {
                                              change: v =>
                                                _vm.classificationSelected(
                                                  item,
                                                  v
                                                )
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "selection",
                                                  fn: function() {
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            item.classificationDisplayName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value:
                                                item.classificationDisplayName
                                            }
                                          })
                                    ]
                                  }
                                },
                                {
                                  key: "item.workOrderNumber",
                                  fn: function({ item }) {
                                    return [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.workOrderNumberTextForRow(
                                            item
                                          ),
                                          "no-value-text": _vm.workOrderPlaceholderTextForRow(
                                            item
                                          )
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "item.perdiem",
                                  fn: function({ item }) {
                                    return [
                                      _c("sp-timesheet-perdiem", {
                                        attrs: {
                                          item: item,
                                          disabled: _vm.processing,
                                          readonly: _vm.timesheetIsReadonly,
                                          perDiemIsWorkOrderRelated:
                                            _vm.perDiemSubTypeIsWorkOrderRelated,
                                          otherPerDiemTimesheetOwner: _vm.perDiemOwnerFromOtherTimesheet(
                                            item.employeeID
                                          ),
                                          otherRowOnTimesheetHasPerDiem: _vm.hasExistingOtherUncorrectedPerDiemRowForEmployee(
                                            item
                                          ),
                                          otherPerDiemRowWorkOrderNumber: _vm.otherPerDiemRowWorkOrderNumber(
                                            item
                                          )
                                        },
                                        on: {
                                          "update:hasPerDiem": v => {
                                            item.hasPerDiem = v
                                            _vm.perDiemValueChanged(item)
                                          },
                                          "update:removePerDiem": v => {
                                            item.removePerDiem = v
                                            _vm.perDiemValueChanged(item)
                                          }
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "item.areaName",
                                  fn: function({ item }) {
                                    return [
                                      !item.workOrderNumber &&
                                      _vm.rowIsEditable(item)
                                        ? _c("v-select", {
                                            staticClass: "fd-table-select",
                                            attrs: {
                                              items: _vm.selectableAreas,
                                              "item-text": "name",
                                              "item-value": "id"
                                            },
                                            model: {
                                              value: item.areaID,
                                              callback: function($$v) {
                                                _vm.$set(item, "areaID", $$v)
                                              },
                                              expression: "item.areaID"
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: { value: item.areaName }
                                          })
                                    ]
                                  }
                                },
                                {
                                  key: "item.subAreaName",
                                  fn: function({ item }) {
                                    return [
                                      !item.workOrderNumber &&
                                      _vm.rowIsEditable(item)
                                        ? _c("v-select", {
                                            staticClass: "fd-table-select",
                                            attrs: {
                                              items: _vm.selectableSubAreasForRow(
                                                item
                                              ),
                                              "item-text": "name",
                                              "item-value": "id"
                                            },
                                            model: {
                                              value: item.subAreaID,
                                              callback: function($$v) {
                                                _vm.$set(item, "subAreaID", $$v)
                                              },
                                              expression: "item.subAreaID"
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: { value: item.subAreaName }
                                          })
                                    ]
                                  }
                                },
                                {
                                  key: "group.header",
                                  fn: function({
                                    group,
                                    items,
                                    isOpen,
                                    toggle
                                  }) {
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "fd-table-group-first-column fd-table-frozen-column",
                                          attrs: { colspan: "6" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              ref: `summarygrouptoggle${group}`,
                                              attrs: { icon: "" },
                                              on: { click: toggle }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(
                                                    isOpen
                                                      ? "mdi-minus"
                                                      : "mdi-plus"
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.existing.person-group-label",
                                                  [group]
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm._l(_vm.summaryWorkSubTypes, function(
                                        wst
                                      ) {
                                        return _c(
                                          "td",
                                          { key: wst.id },
                                          [
                                            _c("sp-timesheet-time-display", {
                                              attrs: {
                                                times: _vm.sumRowTimeValues(
                                                  items,
                                                  wst.id
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      _vm._v(" "),
                                      _vm.hasAnyEquipmentRows
                                        ? _c("td", { attrs: { colspan: "1" } })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                          attrs: { colspan: 1 }
                                        },
                                        [
                                          _c("sp-timesheet-time-display", {
                                            attrs: {
                                              times: _vm.calculateTotalForRows(
                                                items
                                              ),
                                              "no-value-key":
                                                "timesheets.list.no-time-value"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !!_vm.perDiemSubType
                                        ? _c("td", { attrs: { colspan: 1 } }, [
                                            _vm._v(" ")
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("td", { attrs: { colspan: 1 } }, [
                                        _vm._v(" ")
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "item.equipmentHours",
                                  fn: function({ item }) {
                                    return [
                                      !_vm.canEditEquipment(item)
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$format.number(
                                                item.equipmentHours
                                              ),
                                              "no-value-key":
                                                "timesheets.list.no-time-entry-value",
                                              "no-value-style":
                                                "text-align: center;"
                                            }
                                          })
                                        : _c("fd-text-field", {
                                            ref: _vm.getFieldRef(
                                              "summary",
                                              "equipmentHours",
                                              item
                                            ),
                                            staticClass: "fd-short-table-input",
                                            attrs: {
                                              disabled:
                                                _vm.isProcessing ||
                                                !_vm.rowIsEditable(item),
                                              numeric: "",
                                              outlined: false,
                                              "highlight-on-focus": "",
                                              rules: _vm.timesheetRowRules(
                                                item
                                              ),
                                              type: "number",
                                              "hide-spin-buttons": ""
                                            },
                                            on: {
                                              keydown: [
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "up",
                                                      38,
                                                      $event.key,
                                                      ["Up", "ArrowUp"]
                                                    )
                                                  )
                                                    return null
                                                  return (e =>
                                                    _vm.selectPreviousField(
                                                      e,
                                                      "summary",
                                                      "equipmentHours",
                                                      item
                                                    )).apply(null, arguments)
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "down",
                                                      40,
                                                      $event.key,
                                                      ["Down", "ArrowDown"]
                                                    )
                                                  )
                                                    return null
                                                  return (e =>
                                                    _vm.selectNextField(
                                                      e,
                                                      "summary",
                                                      "equipmentHours",
                                                      item
                                                    )).apply(null, arguments)
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return (e =>
                                                    _vm.enterPressed(
                                                      e,
                                                      "summary",
                                                      "equipmentHours",
                                                      item
                                                    )).apply(null, arguments)
                                                }
                                              ]
                                            },
                                            model: {
                                              value: item.equipmentHours,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "equipmentHours",
                                                  $$v
                                                )
                                              },
                                              expression: "item.equipmentHours"
                                            }
                                          })
                                    ]
                                  }
                                },
                                _vm._l(_vm.summaryWorkSubTypes, function(wst) {
                                  return {
                                    key: `item.${wst.id}`,
                                    fn: function({ item }) {
                                      return [
                                        !!_vm.rowCanUseWorkSubType(
                                          item,
                                          wst.id
                                        ) &&
                                        wst.isParent &&
                                        !!_vm.childWorkSubTypesInTopLevelSubType(
                                          wst,
                                          item
                                        ).length
                                          ? _c(
                                              "sp-timesheet-work-sub-type-hours-entry",
                                              {
                                                ref: _vm.getFieldRef(
                                                  "summary",
                                                  wst.id,
                                                  item
                                                ),
                                                refInFor: true,
                                                attrs: {
                                                  disabled: _vm.processing,
                                                  processing: _vm.processing,
                                                  readonly: !_vm.rowIsEditable(
                                                    item
                                                  ),
                                                  value: _vm.getTimeSummaryStringForWorkSubTypeInRow(
                                                    item,
                                                    wst.id
                                                  ),
                                                  row: item,
                                                  workSubType: wst,
                                                  childSubTypes: _vm.childWorkSubTypesInTopLevelSubType(
                                                    wst,
                                                    item
                                                  ),
                                                  workOrderNumber:
                                                    item.workOrderNumber
                                                },
                                                on: {
                                                  "change:hours": (wstid, v) =>
                                                    _vm.workSubTypeHoursValueChanged(
                                                      item,
                                                      wstid,
                                                      v
                                                    ),
                                                  "navigate:back": wstid =>
                                                    _vm.navigateBackFromMultiEntryControl(
                                                      "summary",
                                                      wstid,
                                                      item
                                                    ),
                                                  "navigate:forward": wstid =>
                                                    _vm.navigateForwardFromMultiEntryControl(
                                                      "summary",
                                                      wstid,
                                                      item
                                                    )
                                                }
                                              }
                                            )
                                          : !_vm.rowCanEditWorkSubType(
                                              item,
                                              wst.id
                                            )
                                          ? _c("sp-timesheet-time-display", {
                                              key: wst.id,
                                              attrs: {
                                                times: _vm.getTimesForWorkSubTypeInRow(
                                                  item,
                                                  wst.id
                                                ),
                                                "single-line": ""
                                              }
                                            })
                                          : _c("v-text-field", {
                                              ref: _vm.getFieldRef(
                                                "summary",
                                                wst.id,
                                                item
                                              ),
                                              refInFor: true,
                                              staticClass:
                                                "fd-less-short-table-input",
                                              attrs: {
                                                value: _vm.getTimeSummaryStringForWorkSubTypeInRow(
                                                  item,
                                                  wst.id
                                                ),
                                                disabled:
                                                  _vm.isProcessing ||
                                                  !_vm.rowIsEditable(item),
                                                outlined: false,
                                                "highlight-on-focus": "",
                                                rules: _vm.timesheetRowRules(
                                                  item
                                                )
                                              },
                                              on: {
                                                change: v =>
                                                  _vm.workSubTypeHoursValueChanged(
                                                    item,
                                                    wst.id,
                                                    v
                                                  ),
                                                keydown: [
                                                  function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "up",
                                                        38,
                                                        $event.key,
                                                        ["Up", "ArrowUp"]
                                                      )
                                                    )
                                                      return null
                                                    return (e =>
                                                      _vm.selectPreviousField(
                                                        e,
                                                        "summary",
                                                        wst.id,
                                                        item
                                                      )).apply(null, arguments)
                                                  },
                                                  function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "down",
                                                        40,
                                                        $event.key,
                                                        ["Down", "ArrowDown"]
                                                      )
                                                    )
                                                      return null
                                                    return (e =>
                                                      _vm.selectNextField(
                                                        e,
                                                        "summary",
                                                        wst.id,
                                                        item
                                                      )).apply(null, arguments)
                                                  },
                                                  function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return (e =>
                                                      _vm.enterPressed(
                                                        e,
                                                        "summary",
                                                        wst.id,
                                                        item
                                                      )).apply(null, arguments)
                                                  }
                                                ]
                                              }
                                            })
                                      ]
                                    }
                                  }
                                }),
                                {
                                  key: "item.total",
                                  fn: function({ item }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-table-column-text-end-override",
                                          staticStyle: { position: "relative" }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({ on }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "ml-1",
                                                              class: {
                                                                "d-none": !item
                                                                  .errorMessage
                                                                  .length
                                                              },
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                left: "-15px"
                                                              },
                                                              attrs: {
                                                                color: "error",
                                                                dark: "",
                                                                small: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                        info\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.errorMessage)
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("sp-timesheet-time-display", {
                                            attrs: {
                                              times: _vm.calculateTotalForRow(
                                                item
                                              ),
                                              "display-zero-value": ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "body.append",
                                  fn: function({ items }) {
                                    return [
                                      _c(
                                        "tr",
                                        { staticClass: "fd-table-summary-row" },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-start fd-table-frozen-column fd-table-summary-row-first-column",
                                              attrs: { colspan: "6" }
                                            },
                                            [
                                              _c("fd-alert", {
                                                staticClass: "pa-0 mt-0",
                                                attrs: {
                                                  dense: true,
                                                  label: _vm.$t(
                                                    "timesheets.existing.grand-total-label"
                                                  ),
                                                  type: "hint",
                                                  white: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.summaryWorkSubTypes,
                                            function(wst) {
                                              return _c(
                                                "td",
                                                { key: wst.id },
                                                [
                                                  _c(
                                                    "sp-timesheet-time-display",
                                                    {
                                                      attrs: {
                                                        times: _vm.sumRowTimeValues(
                                                          items,
                                                          wst.id
                                                        ),
                                                        "no-value-key":
                                                          "timesheets.list.no-time-value",
                                                        "no-value-class":
                                                          "white--text"
                                                      }
                                                    }
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm.hasAnyEquipmentRows
                                            ? _c(
                                                "td",
                                                { attrs: { colspan: "1" } },
                                                [
                                                  _c("fd-value-display", {
                                                    staticStyle: {
                                                      display: "inline"
                                                    },
                                                    attrs: {
                                                      value: _vm.sumEquipmentHours(
                                                        items
                                                      ),
                                                      "no-value-key":
                                                        "timesheets.list.no-time-value",
                                                      "no-value-class":
                                                        "white--text"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                              attrs: { colspan: 1 }
                                            },
                                            [
                                              _c("sp-timesheet-time-display", {
                                                attrs: {
                                                  times: _vm.calculateTotalForRows(
                                                    items
                                                  ),
                                                  "no-value-key":
                                                    "timesheets.list.no-time-value",
                                                  "no-value-class":
                                                    "white--text"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          !!_vm.perDiemSubType
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "fd-restrict-table-entry-column-width-total fd-table-column-text-end-override",
                                                  attrs: { colspan: 1 }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    staticStyle: {
                                                      display: "inline"
                                                    },
                                                    attrs: {
                                                      value: _vm.countPerDiems(
                                                        items
                                                      ),
                                                      "no-value-key":
                                                        "timesheets.list.no-entries-value"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("td", { attrs: { colspan: 1 } }, [
                                            _vm._v(" ")
                                          ])
                                        ],
                                        2
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.actions",
                                  fn: function({ item }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "table-2-actions-column-min-width"
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                disabled:
                                                  !_vm.$vuetify.breakpoint
                                                    .lgAndUp ||
                                                  _vm.isProcessing,
                                                top: ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({ on }) {
                                                      return [
                                                        _c(
                                                          "v-badge",
                                                          {
                                                            attrs: {
                                                              color: "red",
                                                              dot: "",
                                                              value: !!item.notesCount
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    disabled:
                                                                      _vm.processing
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.openNotesDialog(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            mdi-note\n                          "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("common.notes"))
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                disabled:
                                                  !_vm.$vuetify.breakpoint
                                                    .lgAndUp ||
                                                  _vm.isProcessing,
                                                left: ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                disabled:
                                                                  _vm.isProcessing ||
                                                                  !_vm.rowIsEditable(
                                                                    item
                                                                  )
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeTimesheetRow(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          mdi-delete\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("common.remove")
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.workOrderNumbersWithDetailWorkSubTypes.length
        ? _c(
            "v-form",
            { ref: "additionaldetailsform" },
            [
              _c("v-subheader", { staticClass: "pt-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("timesheets.existing.additional-details-header")
                  )
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.workOrderNumbersWithDetailWorkSubTypes, function(
                woNumber
              ) {
                return _c(
                  "v-alert",
                  {
                    key: woNumber,
                    staticClass: "mx-3 py-0",
                    attrs: { color: "primary" }
                  },
                  [
                    _c("v-subheader", { staticClass: "white--text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "timesheets.existing.additional-details-label",
                            [`WO#${woNumber}`]
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      _vm._l(
                        _vm.currentTimesheetExplanationsForWorkOrderNumber(
                          woNumber
                        ),
                        function(explanation) {
                          return _c(
                            "v-col",
                            {
                              key: `${woNumber}_${explanation.workSubTypeID}`,
                              staticClass: "fd-alert-container",
                              attrs: { cols: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                staticStyle: { "background-color": "white" },
                                attrs: {
                                  label: _vm.workSubTypeNameForExplanation(
                                    explanation
                                  ),
                                  rules: [_vm.rules.required],
                                  "hide-details": ""
                                },
                                model: {
                                  value: explanation.explanation,
                                  callback: function($$v) {
                                    _vm.$set(explanation, "explanation", $$v)
                                  },
                                  expression: "explanation.explanation"
                                }
                              })
                            ],
                            1
                          )
                        }
                      ),
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }