import { fleetService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: fleetService,
  singularStoreName: "FLEET",
  pluralStoreName: "FLEETS",
  localizationPrefix: "contractors.fleets",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "fleetID",
    type: "string"
  },
  storeExtensions: {
    actions: {
      async LOAD_FLEETS_FOR_CONTRACTOR(context, payload: string) {
        var response = await fleetService.getByContractorId(payload);
        context.commit("SET_FLEETS", response);
        return response;
      }
    }
  }
});

export default storeModule;
