import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import { Classification, ContractorWithTags } from "../../services";
import { VDataTable } from "@fd/lib/vue/types";

export type ClassificationWithSelectedAndCustomRates = Classification & {
  selected: boolean;
  contractorRegularRate: number;
  contractorOvertimeRate: number;
  contractorDoubleTimeRate: number;
};

const classificationSelection = FDVue.extend({
  data: function() {
    return {
      contractor: {} as ContractorWithTags,
      // Used to change pages in the datatable programmatically
      classificationstablepage: 1,
      // *** Classifications ***
      showOnlyIncludedClassifications: false,
      classificationsTableSearch: "",
      selectableClassifications: [] as Array<ClassificationWithSelectedAndCustomRates>
    };
  },
  computed: {
    // *** CLASSIFICATIONS ***
    classifications(): Array<ClassificationWithSelectedAndCustomRates> {
      let returnValue = this.selectableClassifications;
      if (this.showOnlyIncludedClassifications) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedClassifications(): ClassificationWithSelectedAndCustomRates[] {
      return this.selectableClassifications.filter(x => x.selected);
    },
    selectedClassificationIDs(): string[] {
      return this.selectedClassifications.map(x => x.id!);
    },

    searchedClassifications(): Array<ClassificationWithSelectedAndCustomRates> {
      // This is a hack because the classifications list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .classificationsDataTable as any)?.customFilter;
      if (this.classificationsTableSearch && customFilter) {
        return this.classifications.filter(
          x =>
            customFilter(x.name!, this.classificationsTableSearch, x) ||
            customFilter(x.description!, this.classificationsTableSearch, x)
        );
      } else {
        return this.classifications;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedClassificationsSelected(): boolean {
      return this.searchedClassifications.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedClassificationsSelected(): boolean {
      var searchedClassifications = this.searchedClassifications;
      return (
        searchedClassifications.findIndex(x => x.selected) !== -1 &&
        searchedClassifications.findIndex(x => !x.selected) !== -1
      );
    }
  },
  methods: {
    // *** GLOBAL ***
    ...mapActions({
      loadClassifications: "LOAD_CLASSIFICATIONS"
    }),

    // *** INLINE NAVIGATION ***
    getContractorClassificationFieldRef(
      fieldName: string,
      item: ClassificationWithSelectedAndCustomRates
    ) {
      let id = item.id!.replace("-", "").replace("-", "");
      return `${fieldName}_${id}`;
    },
    focusFieldForVisibleClassificationsItemAtIndex(fieldName: string, index: number) {
      let visibleItems = (this.$refs.classificationsDataTable as VDataTable).internalCurrentItems;
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getContractorClassificationFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousClassificationsField(
      fieldName: string,
      item: ClassificationWithSelectedAndCustomRates
    ) {
      let datatable = this.$refs.classificationsDataTable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.classificationstablepage <= 1) return;
        this.classificationstablepage -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleClassificationsItemAtIndex(
            fieldName,
            datatable.computedItemsPerPage
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleClassificationsItemAtIndex(fieldName, previousIndex);
    },
    async selectNextClassificationsField(
      fieldName: string,
      item: ClassificationWithSelectedAndCustomRates
    ) {
      let datatable = this.$refs.classificationsDataTable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex >= visibleParts.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(this.classifications.length / datatable.computedItemsPerPage);
        if (this.classificationstablepage >= maxPage) return;

        this.classificationstablepage += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleClassificationsItemAtIndex(fieldName, 0);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleClassificationsItemAtIndex(fieldName, nextIndex);
    },
    async classificationsEnterPressed(
      e: KeyboardEvent,
      fieldName: string,
      item: ClassificationWithSelectedAndCustomRates
    ) {
      if (e.shiftKey) await this.selectPreviousClassificationsField(fieldName, item);
      else await this.selectNextClassificationsField(fieldName, item);
    },

    // *** CLASSIFICATIONS ***
    flipClassificationSelected(
      item: ClassificationWithSelectedAndCustomRates & { selected: boolean }
    ) {
      item.selected = !item.selected;
    },

    flipSearchedClassificationsSelected() {
      let selected = !this.allSearchedClassificationsSelected;
      for (let classification of this.searchedClassifications) {
        if (classification.selected !== selected) {
          classification.selected = selected;
        }
      }
    }
  }
});

export default classificationSelection;

