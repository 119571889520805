import { ScaffoldRequestSubTypes, ScaffoldRequestTypes, WorkOrderStatuses } from "../../services";

// TODO: Use i18n for labels

export const ScaffoldRequestTypeLabels = {
  [ScaffoldRequestTypes.Erect]: "Erect",
  [ScaffoldRequestTypes.Dismantle]: "Dismantle",
  [ScaffoldRequestTypes.Modify]: "Modify",
  [ScaffoldRequestTypes.Maintenance]: "Maintenance",
  [ScaffoldRequestTypes.Paint]: "Paint",
  [ScaffoldRequestTypes.Insulation]: "Insulation",
  [ScaffoldRequestTypes.HeatTrace]: "Heat Trace",
  [ScaffoldRequestTypes.Refractory]: "Refractory",
  [ScaffoldRequestTypes.Fireproofing]: "Fireproofing"
};

export const ScaffoldRequestSubTypeLabels = {
  [ScaffoldRequestSubTypes.ErectScaffold]: "Erect Scaffold",
  [ScaffoldRequestSubTypes.ErectHardBarricade]: "Erect Hard Barricade",
  [ScaffoldRequestSubTypes.DismantleFull]: "Full Dismantle",
  [ScaffoldRequestSubTypes.ModifyAdjustmentOnly]: "Adjustment Only",
  [ScaffoldRequestSubTypes.ModifyMaterialAdded]: "Material Added",
  [ScaffoldRequestSubTypes.ModifyMaterialRemoved]: "Material Removed",
  [ScaffoldRequestSubTypes.ModifyModify]: "Modify",
  [ScaffoldRequestSubTypes.MaintenanceMaintenance]: "Maintenance",
  [ScaffoldRequestSubTypes.MaintenanceCarpentry]: "Carpenters",
  [ScaffoldRequestSubTypes.MaintenanceJanitorial]: "Janitors",
  [ScaffoldRequestSubTypes.MaintenanceLabour]: "Laborers",
  [ScaffoldRequestSubTypes.MaintenanceOperations]: "Operators",
  [ScaffoldRequestSubTypes.MaintenancePlumbing]: "Plumbers",
  [ScaffoldRequestSubTypes.MaintenancePurchasing]: "Purchasers",
  [ScaffoldRequestSubTypes.PaintPaint]: "Paint",
  [ScaffoldRequestSubTypes.InsulationInsulation]: "Insulation",
  [ScaffoldRequestSubTypes.InsulationPipe]: "Pipe",
  [ScaffoldRequestSubTypes.InsulationEquipment]: "Equipment",
  [ScaffoldRequestSubTypes.HeatTraceHeatTrace]: "Heat Trace",
  [ScaffoldRequestSubTypes.RefractoryRefractory]: "Refractory",
  [ScaffoldRequestSubTypes.FireproofingFireproofing]: "Fireproofing"
};

export const WorkOrderStatusLabels = {
  [WorkOrderStatuses.Draft]: "Draft",
  [WorkOrderStatuses.Submitted]: "Submitted",
  [WorkOrderStatuses.Approved]: "Approved",
  [WorkOrderStatuses.Declined]: "Declined",
  [WorkOrderStatuses.Walkdown]: "Walkdown",
  [WorkOrderStatuses.Estimated]: "Estimated",
  [WorkOrderStatuses.InScheduling]: "In Scheduling",
  [WorkOrderStatuses.OnHold]: "On Hold",
  [WorkOrderStatuses.Started]: "Started",
  [WorkOrderStatuses.Completed]: "Completed",
  [WorkOrderStatuses.Cancelled]: "Cancelled",
  [WorkOrderStatuses.Archived]: "Archived",
  [WorkOrderStatuses.CompletionPendingAdministration]: "Completion Pending Administration"
};
