var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "lemslist" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c("fd-add-button", {
                        attrs: { action: _vm.openNewDialog }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "d-flex align-center justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background"
            },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "fd-badge-radio-group",
                  attrs: { row: "" },
                  model: {
                    value: _vm.selectionType,
                    callback: function($$v) {
                      _vm.selectionType = $$v
                    },
                    expression: "selectionType"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("common.all"),
                      value: "all",
                      "data-cy": "radioall"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "primary",
                        value: _vm.allPendingLEMs.length > 0,
                        content: _vm.allPendingLEMs.length
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t("lems.approval.pending"),
                          value: "pending",
                          "data-cy": "radiopending"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticClass: "mt-0 mt-sm-4" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "pt-0 pb-0 fd-remove-validation-message-area",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-3",
                        attrs: {
                          items: _vm.contractorsInUse,
                          label: _vm.$t("users.filter-by-contractor-label"),
                          "item-text": "alias",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.contractorsSelectedForFiltering,
                          callback: function($$v) {
                            _vm.contractorsSelectedForFiltering = $$v
                          },
                          expression: "contractorsSelectedForFiltering"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("fd-date-range-picker", {
                        staticClass: "pt-3",
                        staticStyle: { "margin-top": "6px" },
                        attrs: {
                          dense: "",
                          min: _vm.minDate,
                          max: _vm.maxDate,
                          loading: _vm.processing,
                          disabled: _vm.processing,
                          "hide-details": "",
                          outlined: false,
                          presets: _vm.dateRangePresetOptions
                        },
                        on: {
                          "input:fromDate": _vm.fromDateChanged,
                          "input:toDate": _vm.toDateChanged
                        },
                        model: {
                          value: _vm.showArchivedDateRange,
                          callback: function($$v) {
                            _vm.showArchivedDateRange = $$v
                          },
                          expression: "showArchivedDateRange"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-5 mr-5 ml-1 ml-sm-0",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: _vm.$vuetify.breakpoint.smAndUp
                                  }
                                },
                                [_vm._v("mdi-reload")]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            staticClass: "mt-1",
                            attrs: {
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:internalNumber",
                value: _vm.$t("lems.list.lem-number-column-label"),
                expression: "$t('lems.list.lem-number-column-label')",
                arg: "internalNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:day",
                value: _vm.$t("lems.day-label"),
                expression: "$t('lems.day-label')",
                arg: "day"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:lemStatus",
                value: _vm.$t("lems.list.status-column-label"),
                expression: "$t('lems.list.status-column-label')",
                arg: "lemStatus"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:contractorName.hide-when-extra-small",
                value: _vm.$t("lems.contractor-label"),
                expression: "$t('lems.contractor-label')",
                arg: "contractorName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:entryCount.hide-when-extra-small",
                value: _vm.$t("lems.list.entry-count-column-label"),
                expression: "$t('lems.list.entry-count-column-label')",
                arg: "entryCount",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalRegularTime.hide-when-extra-small",
                value: _vm.$t("lems.list.total-regular-time-column-label"),
                expression:
                  "\n        $t('lems.list.total-regular-time-column-label')\n      ",
                arg: "totalRegularTime",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalOverTime.hide-when-extra-small",
                value: _vm.$t("lems.list.total-over-time-column-label"),
                expression: "$t('lems.list.total-over-time-column-label')",
                arg: "totalOverTime",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalDoubleTime.hide-when-extra-small",
                value: _vm.$t("lems.list.total-double-time-column-label"),
                expression: "$t('lems.list.total-double-time-column-label')",
                arg: "totalDoubleTime",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalUnits.hide-when-extra-small",
                value: _vm.$t("lems.list.total-units-column-label"),
                expression: "$t('lems.list.total-units-column-label')",
                arg: "totalUnits",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalDays.hide-when-extra-small",
                value: _vm.$t("lems.list.total-days-column-label"),
                expression: "$t('lems.list.total-days-column-label')",
                arg: "totalDays",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalQuantity.hide-when-extra-small",
                value: _vm.$t("lems.list.total-quantity-column-label"),
                expression:
                  "\n        $t('lems.list.total-quantity-column-label')\n      ",
                arg: "totalQuantity",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-action-cell",
                arg: "actions",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-action-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: item => _vm.$router.push(`/lems/${item.id}`),
                expression: "item => $router.push(`/lems/${item.id}`)",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            staticClass: "fd-lems-list",
            attrs: {
              "data-cy": "list",
              items: _vm.lems,
              "sort-by": "day",
              "sort-desc": true,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "mobile-breakpoint": "0",
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              },
              "show-expand": ""
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function({ item: lem }) {
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "fd-embedded-table-container",
                        attrs: { colspan: "99" }
                      },
                      [
                        _c("v-subheader", { staticClass: "white--text" }, [
                          _vm._v(_vm._s(_vm.$t("timesheets.list.title")))
                        ]),
                        _vm._v(" "),
                        _c("v-data-table", {
                          directives: [
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:ownerID.hidden",
                              value: "",
                              expression: "''",
                              arg: "ownerID",
                              modifiers: { hidden: true }
                            },
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:timesheetNumber",
                              value: _vm.$t("timesheets.number-label"),
                              expression: "$t('timesheets.number-label')",
                              arg: "timesheetNumber"
                            },
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:ownerName",
                              value: _vm.$t("timesheets.owner-label"),
                              expression: "$t('timesheets.owner-label')",
                              arg: "ownerName"
                            },
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:timesheetType",
                              value: _vm.$t("timesheets.timesheet-type-label"),
                              expression:
                                "$t('timesheets.timesheet-type-label')",
                              arg: "timesheetType"
                            },
                            {
                              name: "fd-column",
                              rawName:
                                "v-fd-column:entryCount.hide-when-medium",
                              value: _vm.$t(
                                "timesheets.list.entry-count-column-label"
                              ),
                              expression:
                                "\n              $t('timesheets.list.entry-count-column-label')\n            ",
                              arg: "entryCount",
                              modifiers: { "hide-when-medium": true }
                            },
                            {
                              name: "fd-column",
                              rawName:
                                "v-fd-column:totalRegularTime.hide-when-extra-small",
                              value: _vm.$t(
                                "timesheets.list.total-regular-time-column-label"
                              ),
                              expression:
                                "\n              $t('timesheets.list.total-regular-time-column-label')\n            ",
                              arg: "totalRegularTime",
                              modifiers: { "hide-when-extra-small": true }
                            },
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:totalOverTime",
                              value: _vm.$t(
                                "timesheets.list.total-over-time-column-label"
                              ),
                              expression:
                                "$t('timesheets.list.total-over-time-column-label')",
                              arg: "totalOverTime"
                            },
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:totalDoubleTime",
                              value: _vm.$t(
                                "timesheets.list.total-double-time-column-label"
                              ),
                              expression:
                                "$t('timesheets.list.total-double-time-column-label')",
                              arg: "totalDoubleTime"
                            },
                            {
                              name: "fd-column",
                              rawName:
                                "v-fd-column:totalUnits.hide-when-extra-small",
                              value: _vm.$t(
                                "timesheets.list.total-units-column-label"
                              ),
                              expression:
                                "\n              $t('timesheets.list.total-units-column-label')\n            ",
                              arg: "totalUnits",
                              modifiers: { "hide-when-extra-small": true }
                            },
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:totalDays",
                              value: _vm.$t(
                                "timesheets.list.total-days-column-label"
                              ),
                              expression:
                                "$t('timesheets.list.total-days-column-label')",
                              arg: "totalDays"
                            },
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:totalQuantity",
                              value: _vm.$t(
                                "timesheets.list.total-quantity-column-label"
                              ),
                              expression:
                                "$t('timesheets.list.total-quantity-column-label')",
                              arg: "totalQuantity"
                            }
                          ],
                          staticClass: "fd-timesheets-list",
                          attrs: {
                            items: lem.importedTimesheets,
                            "sort-by": "day",
                            "sort-desc": true,
                            "mobile-breakpoint": "0",
                            "items-per-page": -1,
                            "footer-props": { "items-per-page-options": [-1] },
                            "hide-default-footer": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.timesheetNumber",
                                fn: function({ item }) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          `00000${item.timesheetNumber}`.slice(
                                            -5
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.ownerName",
                                fn: function({ item }) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$lookup.person(item.ownerID)
                                        ) +
                                        "\n              "
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  !!item.creatorID &&
                                                  item.creatorID != item.ownerID
                                                    ? _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              small: "",
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "timesheets.list.created-by-message",
                                                [
                                                  _vm.$lookup.person(
                                                    item.creatorID
                                                  )
                                                ]
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.timesheetType",
                                fn: function({ item }) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            `timesheets.types.${item.timesheetTypeID}`
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.entryCount",
                                fn: function({ item }) {
                                  return [
                                    _c("fd-value-display", {
                                      attrs: {
                                        value: item.entryCount,
                                        "no-value-key":
                                          "timesheets.list.no-entries-value"
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.totalRegularTime",
                                fn: function({ item }) {
                                  return [
                                    item.timesheetTypeID != 2
                                      ? _c("fd-value-display", {
                                          attrs: {
                                            value:
                                              !!item.totalRegularTime &&
                                              item.totalRegularTime > 0
                                                ? item.totalRegularTime.toFixed(
                                                    2
                                                  )
                                                : undefined,
                                            "no-value-key":
                                              "timesheets.list.no-time-value"
                                          }
                                        })
                                      : _c("fd-value-display", {
                                          attrs: {
                                            "no-value-key":
                                              "timesheets.list.no-time-entry-value"
                                          }
                                        })
                                  ]
                                }
                              },
                              {
                                key: "item.totalOverTime",
                                fn: function({ item }) {
                                  return [
                                    item.timesheetTypeID != 2
                                      ? _c("fd-value-display", {
                                          attrs: {
                                            value:
                                              !!item.totalOverTime &&
                                              item.totalOverTime > 0
                                                ? item.totalOverTime.toFixed(2)
                                                : undefined,
                                            "no-value-key":
                                              "timesheets.list.no-time-value"
                                          }
                                        })
                                      : _c("fd-value-display", {
                                          attrs: {
                                            "no-value-key":
                                              "timesheets.list.no-time-entry-value"
                                          }
                                        })
                                  ]
                                }
                              },
                              {
                                key: "item.totalDoubleTime",
                                fn: function({ item }) {
                                  return [
                                    item.timesheetTypeID != 2
                                      ? _c("fd-value-display", {
                                          attrs: {
                                            value:
                                              !!item.totalDoubleTime &&
                                              item.totalDoubleTime > 0
                                                ? item.totalDoubleTime.toFixed(
                                                    2
                                                  )
                                                : undefined,
                                            "no-value-key":
                                              "timesheets.list.no-time-value"
                                          }
                                        })
                                      : _c("fd-value-display", {
                                          attrs: {
                                            "no-value-key":
                                              "timesheets.list.no-time-entry-value"
                                          }
                                        })
                                  ]
                                }
                              },
                              {
                                key: "item.totalUnits",
                                fn: function({ item }) {
                                  return [
                                    item.timesheetTypeID != 2
                                      ? _c("fd-value-display", {
                                          attrs: {
                                            value:
                                              !!item.totalUnits &&
                                              item.totalUnits > 0
                                                ? item.totalUnits.toFixed(2)
                                                : undefined,
                                            "no-value-key":
                                              "timesheets.list.no-time-value"
                                          }
                                        })
                                      : _c("fd-value-display", {
                                          attrs: {
                                            "no-value-key":
                                              "timesheets.list.no-time-entry-value"
                                          }
                                        })
                                  ]
                                }
                              },
                              {
                                key: "item.totalDays",
                                fn: function({ item }) {
                                  return [
                                    item.timesheetTypeID == 2
                                      ? _c("fd-value-display", {
                                          attrs: {
                                            value:
                                              !!item.totalDays &&
                                              item.totalDays > 0
                                                ? item.totalDays.toFixed(2)
                                                : undefined,
                                            "no-value-key":
                                              "timesheets.list.no-time-value"
                                          }
                                        })
                                      : _c("fd-value-display", {
                                          attrs: {
                                            "no-value-key":
                                              "timesheets.list.no-time-entry-value"
                                          }
                                        })
                                  ]
                                }
                              },
                              {
                                key: "item.totalQuantity",
                                fn: function({ item }) {
                                  return [
                                    item.timesheetTypeID == 2
                                      ? _c("fd-value-display", {
                                          attrs: {
                                            value:
                                              !!item.totalQuantity &&
                                              item.totalQuantity > 0
                                                ? item.totalQuantity.toFixed(2)
                                                : undefined,
                                            "no-value-key":
                                              "timesheets.list.no-time-value"
                                          }
                                        })
                                      : _c("fd-value-display", {
                                          attrs: {
                                            "no-value-key":
                                              "timesheets.list.no-time-entry-value"
                                          }
                                        })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.lemStatus",
                fn: function({ item }) {
                  return [
                    _c(
                      "v-row",
                      {
                        staticClass: "mx-0",
                        staticStyle: { "min-width": "75px" }
                      },
                      [
                        _vm.lemIsDeclined(item)
                          ? _c(
                              "v-icon",
                              { staticClass: "fd-table-cell-warning-icon" },
                              [_vm._v("fas fa-exclamation-circle")]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n          " + _vm._s(item.lemStatus) + "\n        "
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.day",
                fn: function({ item }) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(item.formattedDay) + "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.contractorName",
                fn: function({ item }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$lookup.contractor(item.contractorID)) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.entryCount",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: item.entryCount,
                        "no-value-key": "lems.list.no-entries-value"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.totalRegularTime",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: item.formattedTotalRegularTime,
                        "no-value-key": "lems.list.no-time-value"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.totalOverTime",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: item.formattedTotalOverTime,
                        "no-value-key": "lems.list.no-time-value"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.totalDoubleTime",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: item.formattedTotalDoubleTime,
                        "no-value-key": "lems.list.no-time-value"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.totalUnits",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: item.formattedTotalUnits,
                        "no-value-key": "lems.list.no-time-value"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.totalDays",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: item.formattedTotalDays,
                        "no-value-key": "lems.list.no-time-value"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.totalQuantity",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: {
                        value: item.formattedTotalQuantity,
                        "no-value-key": "lems.list.no-time-value"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        class: `table-${_vm.numAllowedActions(
                          item
                        )}-actions-column-min-width`
                      },
                      [
                        item.currentUserPermissions.canSubmit
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.$vuetify.breakpoint.lgAndUp ||
                                    _vm.processing,
                                  left: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  link: "",
                                                  disabled:
                                                    !_vm.lemCanBeSubmitted(
                                                      item
                                                    ) || _vm.processing,
                                                  loading: _vm.submitting
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.submitLEM(item)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  far fa-paper-plane\n                "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("common.submit")))
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.currentUserPermissions.canApprove
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.$vuetify.breakpoint.lgAndUp ||
                                    _vm.processing,
                                  left: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  "data-cy": "approve",
                                                  icon: "",
                                                  link: "",
                                                  disabled:
                                                    !_vm.lemCanBeApproved(
                                                      item
                                                    ) || _vm.processing,
                                                  loading: _vm.approving
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.approveLEM(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  far fa-check\n                "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("common.approve")))
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.currentUserPermissions.canApprove
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.$vuetify.breakpoint.lgAndUp ||
                                    _vm.processing,
                                  left: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  "data-cy": "decline",
                                                  icon: "",
                                                  link: "",
                                                  disabled:
                                                    !_vm.lemCanBeDeclined(
                                                      item
                                                    ) || _vm.processing,
                                                  loading: _vm.declining
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.declineLEM(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  far fa-times\n                "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("common.decline")))
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "data-cy": "edit",
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: `/lems/${item.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }