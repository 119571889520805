var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1024px" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("to-do-list.work-order-details.title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("fd-work-order-details", {
            staticClass: "pl-3 pr-3",
            attrs: {
              workOrder: _vm.workOrder,
              walkdown: _vm.walkdown,
              "hide-scope-values": _vm.hideScopeValues
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                class: [
                                  "mr-3",
                                  {
                                    "d-none": _vm.$vuetify.breakpoint.smAndDown,
                                    "d-flex": _vm.$vuetify.breakpoint.lgAndUp
                                  },
                                  "fd-existing-details-print-button"
                                ],
                                attrs: {
                                  outlined: "",
                                  disabled: _vm.processing,
                                  color: "secondary"
                                }
                              },
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: _vm.$vuetify.breakpoint.smAndUp
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              mdi-printer\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("common.print")) +
                                        "\n            "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "margin-left": "2px !important"
                                  },
                                  attrs: { right: "" }
                                },
                                [_vm._v("mdi-menu-down")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item-group",
                        { staticClass: "fd-drop-down-item-group" },
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.downloadAndPrintPlannerReport(
                                    "pdf"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("scheduler.printing.pdf")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.downloadAndPrintPlannerReport(
                                    "xls"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("scheduler.printing.excel")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.close")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }