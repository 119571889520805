import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { ProjectCostCode, WorkSubType, workSubTypeService, WorkType } from "../services";
import rules from "@fd/lib/vue/rules";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { TranslateResult } from "vue-i18n";
import { createNewWorkSubType } from "./components/dialogs/WorkSubTypeNewDialog.vue";
import archivedDataList from "../dataMixins/archivedDataList";

type WorkTypeWithArchived = WorkType & {
  archived: boolean;
};
type WorkSubTypeWithArchived = WorkSubType & {
  childTypes: Array<WorkSubType>;
  archived: boolean;
  misconfigured: boolean;
};

export default FDVue.extend({
  mixins: [errorHandling, rules, tabbedView, archivedDataList],

  name: "sp-work-type-existing",

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      firstTabKey: `0`,
      detailsTab: {
        tabname: this.$t("common.details"),
        key: "0",
        visible: true
      } as Tab,
      subTypeTab: {
        tabname: this.$t("work-types.existing.tabs.work-sub-types"),
        key: "1",
        visible: false
      } as Tab,

      worktype: {} as WorkTypeWithArchived,

      archivedLoading: false
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["getCostCodeName"];
    },
    anyMisconfiguredTopLevelSubTypes(): boolean {
      return this.topLevelWorkSubTypes.findIndex(x => !!x.misconfigured) !== -1;
    },
    iconColumnArgument(): string {
      return this.anyMisconfiguredTopLevelSubTypes
        ? "hasMisconfiguredSubTypes"
        : FDHiddenArgumentName;
    },
    anyMisconfiguredChildSubTypes(): boolean {
      return this.childWorkSubTypes.findIndex(x => !!x.misconfigured) !== -1;
    },
    childTableIconColumnArgument(): string {
      return this.anyMisconfiguredChildSubTypes ? "hasMisconfiguredSubTypes" : FDHiddenArgumentName;
    },
    hasAnyParentTypesWithChildren(): boolean {
      return !!this.childWorkSubTypes.length;
    },
    category: {
      get(): "direct" | "perdiem" | "indirect" | "equipment" {
        if (this.worktype.isDirect) return "direct";
        else if (this.worktype.isPerDiem) return "perdiem";
        else if (this.worktype.isEquipment) return "equipment";
        else return "indirect";
      },
      set(val: "direct" | "perdiem" | "indirect" | "equipment") {
        this.worktype.isDirect = val == "direct";
        this.worktype.isEquipment = val == "equipment";
        this.worktype.isPerDiem = val == "perdiem";
      }
    },
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.subTypeTab] as Tab[];
    },

    allCostCodes(): ProjectCostCode[] {
      return this.$store.state.projectCostCodes.fullList as ProjectCostCode[];
    },

    allWorkSubTypes(): WorkSubTypeWithArchived[] {
      let workTypeSubTypes = (this.$store.state.workSubTypes.fullList as WorkSubType[])
        .filter(x => x.workTypeID == this.$route.params.id)
        .map(
          x =>
            ({
              ...x,
              archived: !!x.archivedDate,
              misconfigured: !x.useWorkOrderCostCode && !x.defaultCostCodeID && !x.isParent,
              childTypes: []
            } as WorkSubTypeWithArchived)
        );
      return workTypeSubTypes.sort((a, b) => {
        let aOrder = a.order ?? 0;
        let bOrder = b.order ?? 0;
        if (aOrder != bOrder) return aOrder - bOrder;

        let aName = a.name!.toLocaleLowerCase();
        let bName = b.name!.toLocaleLowerCase();
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      });
    },
    topLevelWorkSubTypes(): WorkSubTypeWithArchived[] {
      return this.allWorkSubTypes.filter(x => !x.parentWorkSubTypeID);
    },
    childWorkSubTypes(): WorkSubTypeWithArchived[] {
      return this.allWorkSubTypes.filter(x => !!x.parentWorkSubTypeID);
    },

    workSubTypes(): WorkSubTypeWithArchived[] {
      let topLevelSubTypes = this.topLevelWorkSubTypes;
      let childSubTypes = this.childWorkSubTypes;
      if (!childSubTypes) return topLevelSubTypes;

      return topLevelSubTypes.map(x => ({
        ...x,
        childTypes: childSubTypes.filter(c => c.parentWorkSubTypeID == x.id)
      }));
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    getCostCodeName(item: WorkSubType): string | undefined {
      if (!!item.useWorkOrderCostCode || !item.defaultCostCodeID?.length) return undefined;

      return this.allCostCodes.find(c => c.id == item.defaultCostCodeID)?.name;
    },
    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.worktype.archived) {
          this.worktype.archivedDate = null;
        } else if (this.worktype.archived && !this.worktype.archivedDate) {
          this.worktype.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateWorkType(this.worktype);
        if (closeOnComplete) {
          this.$router.push("/worktypes");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteWorkType({ id: this.$route.params.id, name: this.worktype.name });
        this.$router.push("/worktypes");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async openNewWorkSubTypeDialog() {
      if (!this.worktype.id) return;
      this.optOutOfErrorHandling();
      await createNewWorkSubType(this.worktype.id, this.workSubTypes?.length ?? 0);
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push("/worktypes");
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      await this.$store.dispatch("DELETE_WORK_SUB_TYPE", item);
      await this.reloadWorkSubTypes();
    },

    async flipArchived(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateWorkSubType({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
        this.reloadWorkSubTypes();
      } catch (error) {
        this.handleError(error as Error, "work-types.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async reloadWorkSubTypes() {
      await this.loadWorkSubTypes({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
    },
    async loadData() {
      await this.reloadWorkSubTypes();
    },
    async dragEnded(e: SortableEvent) {
      console.log(`dragEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.workSubTypes.splice(newIndex, 0, ...this.workSubTypes.splice(oldIndex, 1));
      this.workSubTypes.forEach((item, index) => {
        item.order = index + 1;
      });

      await this.updateWorkSubTypeOrdering(
        this.$t("work-types.work-sub-types.snack-bar-order-updated-message"),
        null
      );
    },

    async confirmItemOrdering() {
      console.log(`confirmItemOrdering total SubTypes: ${this.workSubTypes.length}`);

      let itemsToUpdate = [] as WorkSubType[];

      this.workSubTypes.forEach((subType, index) => {
        let newOrder = index + 1;
        if (!subType.order || subType.order != newOrder) {
          console.log(`    ${subType.order} --> ${newOrder}`);
          subType.order = newOrder;
          itemsToUpdate.push(subType);
        }
      });

      if (itemsToUpdate.length > 0) {
        console.log(` updating ${itemsToUpdate.length} subTypes' ordering`);
        await workSubTypeService.updateWorkSubTypeOrders(itemsToUpdate);

        var snackbarPayload = {
          text: this.$t("work-types.work-sub-types.snack-bar-order-updated-message"),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
    },

    async updateWorkSubTypeOrdering(
      message: string | TranslateResult,
      undoCallback: Function | null
    ) {
      if (!this.worktype.id) return;

      this.processing = true;
      this.saving = true;
      try {
        let selectedWorkSubTypes = this.workSubTypes;
        // Just in case we've added or removed a sub type, or if they load with incorrect orders, re-set them based on location
        selectedWorkSubTypes.forEach((subType, index) => {
          subType.order = index + 1;
        });
        await workSubTypeService.updateWorkSubTypeOrders(selectedWorkSubTypes);

        var snackbarPayload = {
          text: message,
          type: "success",
          undoCallback: undoCallback
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadWorkType: "LOAD_WORK_TYPE",
      updateWorkType: "UPDATE_WORK_TYPE",
      deleteWorkType: "DELETE_WORK_TYPE",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      updateWorkSubType: "UPDATE_WORK_SUB_TYPE",
      loadCostCodes: "LOAD_PROJECT_COST_CODES"
    })
  },

  watch: {
    worktype(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/worktypes") {
        this.notifyNewBreadcrumb({
          text: this.$t("work-types.list.title"),
          to: "/worktypes",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/worktypes/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "work-type-existing",
      parentalContext: "worktypes",
      searchStringForFiltering: "",
      selectedTab: this.firstTabKey,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });

    this.processing = true;
    try {
      await Promise.all([
        this.loadCostCodes(),
        this.loadWorkType(this.$route.params.id),
        this.reloadWorkSubTypes()
      ]);

      let worktype = this.$store.state.workTypes.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );
      this.worktype = { ...worktype, archived: !!worktype.archivedDate };

      await this.confirmItemOrdering();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});
