import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import i18n from "../../../i18n";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  Classification,
  FleetEquipmentWithName,
  FleetWithEquipment,
  EquipmentWithDetails,
  Person,
  PersonWithDetails
} from "../../../services";
import { VForm } from "@fd/lib/vue/types";
import {
  FDColumnDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import rules from "@fd/lib/vue/rules";
import { GetPersonName, HasName, SortItemsWithName } from "../../../utils/person";
import userAccess from "../../../dataMixins/userAccess";
import { SortFleetEquipment } from "./SP.FleetDetailsBottomDialog.vue";

type EquipmentWithDetailsAndClassificationName = EquipmentWithDetails & {
  classificationName: string | undefined;
};

const FleetDetailsDialog = FDVue.extend({
  name: "fd-fleet-details-dialog",

  mixins: [dialogSupport, rules, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdTableSortable: FDTableSortableDirective
  },

  components: {},

  data: () => ({
    // *** GLOBAL ***
    step: 1,
    lastStep: 3,

    detailsStep: 1,
    equipmentStep: 2,
    equipmentOrderingStep: 3,

    detailserror: false,
    equipmenterror: false,
    equipmentordererror: false,

    // The following will control whether or not the save button shows the processing/loading indicator
    saving: false,

    canChangeOwner: true,
    showCannotCreateForOthersMessage: false,

    // *** EQUIPMENT ***
    showOnlyIncludedEquipment: false,
    tablesearchequipment: "",
    selectableEquipment: [] as Array<EquipmentWithDetailsAndClassificationName>,

    sourceFleet: undefined as FleetWithEquipment | undefined,
    fleet: {
      name: "",
      description: "",
      ownerID: null,
      equipment: [] as FleetEquipmentWithName[]
    } as FleetWithEquipment
  }),

  computed: {
    unwatchedMethodNames(): string[] {
      return ["splitFilter", "itemIsSelected", "orderForItem"];
    },
    // List of people available to be selected as fleet Owner
    visiblePeople(): (Person & HasName)[] {
      let visiblePeople = SortItemsWithName(
        (this.$store.state.users.fullList as PersonWithDetails[])
          .filter(
            x =>
              x.contractorID == this.fleet.contractorID ||
              // Allow the current user to be the owner of a crew, and we always want the current owner to be visible even if from a different contractor than the crew
              x.id == this.curUserID ||
              x.id == this.fleet.ownerID
          )
          .map(x => ({ ...x, name: GetPersonName(x) }))
      );
      return visiblePeople;
    },
    // List of equipment available to be selected as Fleet Owner
    visibleEquipment(): EquipmentWithDetails[] {
      let visibleEquipment = SortItemsWithName(
        (this.$store.state.equipment.fullList as EquipmentWithDetails[]).filter(
          x => x.contractorID == this.fleet.contractorID
        )
      );
      return visibleEquipment;
    },

    equipment: {
      cache: false,
      get(): Array<EquipmentWithDetailsAndClassificationName> {
        let returnValue = this.selectableEquipment;
        // if (this.showOnlyIncludedEquipment) returnValue = returnValue.filter(x => x.selected);
        if (this.showOnlyIncludedEquipment) {
          returnValue = returnValue.filter(x => this.selectedEquipmentIDs.includes(x.id!));
        }
        return returnValue;
      }
    },

    selectedEquipmentIDs(): string[] {
      return this.fleet.equipment?.map(e => e.equipmentID!) ?? [];
    },
    selectedEquipment(): EquipmentWithDetailsAndClassificationName[] {
      return this.selectedEquipmentIDs
        .filter(x => !!this.selectableEquipment.find(e => e.id == x))
        .map(x => this.selectableEquipment.find(e => e.id == x)!);
    },

    searchedEquipment(): Array<EquipmentWithDetailsAndClassificationName> {
      // This is a hack because the equipment list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .equipmentDataTable as any)?.customFilter;
      if (this.tablesearchequipment && customFilter) {
        return this.equipment.filter(
          x =>
            customFilter(x.name!, this.tablesearchequipment, x) ||
            customFilter(x.serialNumber!, this.tablesearchequipment, x) ||
            customFilter(x.modelNumber!, this.tablesearchequipment, x)
        );
      } else {
        return this.equipment;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedEquipmentSelected(): boolean {
      return (
        this.searchedEquipment.findIndex(x => !this.selectedEquipmentIDs.includes(x.id!)) === -1
      );
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedEquipmentSelected(): boolean {
      var searchedEquipment = this.searchedEquipment;
      return (
        searchedEquipment.findIndex(x => this.selectedEquipmentIDs.includes(x.id!)) !== -1 &&
        searchedEquipment.findIndex(x => !this.selectedEquipmentIDs.includes(x.id!)) !== -1
      );
    }
  },

  methods: {
    itemIsSelected(item: EquipmentWithDetails) {
      return this.selectedEquipmentIDs.includes(item.id!);
    },
    splitFilter(value: any, search: string | null, item: any): boolean {
      if (value == null || search == null || typeof value === "boolean") return false;

      value = value.toLocaleLowerCase();
      let searchArray = search.split(" ").map(x => x.toLocaleLowerCase());

      // If any search terms are NOT found in the value, return false
      return !(searchArray.findIndex(x => value.indexOf(x) === -1) !== -1);
    },
    async openForNew(
      contractorID: string,
      ownerID: string | null | undefined,
      defaultName: string | undefined = undefined
    ) {
      // If the fleet is being created directly for a specific owner, force it to stay that way
      // If the fleet is not being created for an owner, but the current user doesn't have "configureSettings" privilege
      //   force the fleet to be owned by the current user.
      if (!!ownerID) this.canChangeOwner = false;
      else {
        if (!this.currentUserCanConfigureSettings) {
          ownerID = this.curUserID;
          this.canChangeOwner = false;
          this.showCannotCreateForOthersMessage = true;
        }
      }

      this.fleet.contractorID = contractorID;
      this.fleet.ownerID = ownerID;
      this.fleet.name = defaultName;

      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    filterInactiveEquipment() {
      if (!this.visibleEquipment?.length || !this.fleet) return;

      this.fleet.equipment = this.fleet.equipment?.filter(
        x => this.visibleEquipment.findIndex(e => e.id == x.equipmentID) !== -1
      );
    },
    async openExisting(fleet: FleetWithEquipment) {
      this.sourceFleet = fleet;
      this.fleet = { ...fleet } as FleetWithEquipment;
      this.filterInactiveEquipment();
      SortFleetEquipment(this.fleet.equipment);

      this.canChangeOwner = false;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailserror = !(this.$refs.detailsform as VForm).validate();
      this.equipmenterror = !(this.$refs.equipmentform as VForm).validate();

      return !(this.detailserror || this.equipmenterror);
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with dialog.
    async saveDialog() {
      if (!this.validate()) {
        var message = i18n.t("contractors.fleets.new-fleet.error-message");
        if (this.detailserror)
          message += "\n\t- " + i18n.t("contractors.fleets.new-fleet.steps.details");
        if (this.equipmenterror)
          message += "\n\t- " + i18n.t("contractors.fleets.new-fleet.steps.equipment");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }

      this.saving = true;
      this.processing = true;

      try {
        if (!this.fleet.id) {
          var newFleetID = await this.$store.dispatch("ADD_FLEET", {
            ...this.fleet
          } as FleetWithEquipment);
          this.fleet.id = newFleetID;
          this.fleet.equipment?.forEach(x => (x.fleetID = newFleetID));
        } else {
          await this.$store.dispatch("UPDATE_FLEET", this.fleet);
        }
        if (!!this.sourceFleet) {
          this.sourceFleet.name = this.fleet.name;
          this.sourceFleet.description = this.fleet.description;
          this.sourceFleet.ownerID = this.fleet.ownerID;
          this.sourceFleet.ownerName = this.fleet.ownerName;
          this.sourceFleet.equipment = this.fleet.equipment;
        }
        this.closeDialog(newFleetID);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    ...mapActions({
      loadClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS",
      loadEquipmentList: "LOAD_EQUIPMENTS",
      loadEmployees: "LOAD_USERS"
    }),

    // *** EQUIPMENT ***
    orderForItem(item: EquipmentWithDetails) {
      return this.fleet.equipment?.find(x => x.equipmentID == item.id)?.order;
    },
    flipEquipmentSelected(item: EquipmentWithDetailsAndClassificationName) {
      if (!this.fleet.equipment) this.fleet.equipment = [];

      let equipmentSelected = this.selectedEquipmentIDs.includes(item.id!);
      if (equipmentSelected) {
        let index = this.selectedEquipmentIDs.indexOf(item.id!);
        if (index > -1) {
          this.fleet.equipment!.splice(index, 1);
        }
      } else {
        if (!this.fleet.equipment) this.fleet.equipment = [];
        this.fleet.equipment.push({
          name: item.name,
          fleetID: this.fleet.id,
          equipmentID: item.id,
          order: this.fleet.equipment.length
        } as FleetEquipmentWithName);
      }
      this.fleet.equipment.forEach((item, index) => {
        let newOrder = index + 1;
        console.log(`  ${item.name}: ${item.order} --> ${newOrder}`);
        item.order = newOrder;
      });
    },

    flipSearchedEquipmentSelected() {
      if (!this.fleet.equipment) this.fleet.equipment = [];

      let selected = !this.allSearchedEquipmentSelected;
      // let changedEquipmentIDs = [] as string[];
      for (let equipment of this.searchedEquipment) {
        let equipmentSelected = this.selectedEquipmentIDs.includes(equipment.id!);
        if (equipmentSelected !== selected) {
          if (!!selected) {
            if (!this.fleet.equipment) this.fleet.equipment = [];
            this.fleet.equipment.push({
              name: equipment.name,
              fleetID: this.fleet.id,
              equipmentID: equipment.id,
              order: this.fleet.equipment.length
            } as FleetEquipmentWithName);
          } else {
            let index = this.selectedEquipmentIDs.indexOf(equipment.id!);
            if (index > -1) {
              this.fleet.equipment!.splice(index, 1);
            }
          }
        }
      }
      this.fleet.equipment.forEach((item, index) => {
        let newOrder = index + 1;
        console.log(`  ${item.name}: ${item.order} --> ${newOrder}`);
        item.order = newOrder;
      });
    },
    async dragEnded(e: SortableEvent) {
      if (!this.fleet.equipment?.length) return;

      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.fleet.equipment.splice(newIndex, 0, ...this.fleet.equipment.splice(oldIndex, 1));
      console.log(` Equipment Indexes`);
      this.fleet.equipment.forEach((item, index) => {
        let newOrder = index + 1;
        console.log(`  ${item.name}: ${item.order} --> ${newOrder}`);
        item.order = newOrder;
      });
    },
    classificationNameForEquipment(equipment: EquipmentWithDetails) {
      let allClassifications = this.$store.state.equipmentClassifications
        .fullList as Classification[];
      return allClassifications.find(x => x.id == equipment.equipmentClassificationID)?.name;
    }
  },

  created: async function() {
    this.processing = true;

    // *** EQUIPMENT ***
    await Promise.all([this.loadClassifications(), this.loadEquipmentList(), this.loadEmployees()]);

    this.selectableEquipment = (this.$store.state.equipment.fullList as EquipmentWithDetails[])
      .filter(x => x.contractorID == this.fleet.contractorID)
      .map(
        x =>
          ({
            ...x,
            classificationName: this.classificationNameForEquipment(x)
          } as EquipmentWithDetailsAndClassificationName)
      );

    this.filterInactiveEquipment();

    this.processing = false;
  }
});

export default FleetDetailsDialog;

export async function createNewFleet(
  contractorID: string,
  ownerID: string | undefined | null = undefined,
  defaultName: string | undefined = undefined
): Promise<string | boolean> {
  let dialog = createDialog(FleetDetailsDialog);
  return await dialog.openForNew(contractorID, ownerID, defaultName);
}

export async function updateExistingFleet(fleet: FleetWithEquipment): Promise<string | boolean> {
  let dialog = createDialog(FleetDetailsDialog);
  return await dialog.openExisting(fleet);
}
