var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "scaffolds" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view" },
        [
          _c(
            "fd-toolbar",
            { attrs: { loading: _vm.processing } },
            [
              _c(
                "v-row",
                { staticClass: "pb-5 pb-sm-0" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : ""
                        ],
                        staticStyle: {
                          "font-size": "1.40rem !important",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0px",
                              left: "0px"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("scaffolds.list.title")) +
                                    "\n                "
                                ),
                                !!_vm.curUserContractorID
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "hide-when-no-contractor-class"
                                        },
                                        [_vm._v("|")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-label"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.contractor-select"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-contractor-detail-positioning"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.$vuetify.breakpoint.xs
                                                  ? "fd-view-header-contractor-qualifier-contractor-detail-xs-width-restrictor"
                                                  : "",
                                                "fd-view-header-contractor-qualifier-contractor-detail"
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.curUserContractorName
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background fd-scaffolds-switches"
            },
            [
              _c("v-switch", {
                staticClass: "pr-6",
                attrs: {
                  "data-cy": "standingswitch",
                  label: _vm.$t("scaffolds.list.selection-type-standing")
                },
                model: {
                  value: _vm.standingContextSelected,
                  callback: function($$v) {
                    _vm.standingContextSelected = $$v
                  },
                  expression: "standingContextSelected"
                }
              }),
              _vm._v(" "),
              _c("v-switch", {
                staticClass: "pr-3",
                attrs: {
                  "data-cy": "dismantledswitch",
                  label: _vm.$t("scaffolds.list.selection-type-dismantled")
                },
                model: {
                  value: _vm.dismantledContextSelected,
                  callback: function($$v) {
                    _vm.dismantledContextSelected = $$v
                  },
                  expression: "dismantledContextSelected"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { class: [!_vm.canViewContractorFilter ? "mt-3" : "", "pb-0"] },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scaffolds.filter-by-area-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedAreaIDs,
                          callback: function($$v) {
                            _vm.selectedAreaIDs = $$v
                          },
                          expression: "selectedAreaIDs"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scaffolds.filter-by-sub-area-label"),
                          disabled:
                            !_vm.selectableSubAreas.length || _vm.processing,
                          items: _vm.selectableSubAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedSubAreaIDs,
                          callback: function($$v) {
                            _vm.selectedSubAreaIDs = $$v
                          },
                          expression: "selectedSubAreaIDs"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class: [
                        "pt-0",
                        {
                          "pb-5": !_vm.canViewContractorFilter,
                          "pb-0": _vm.canViewContractorFilter
                        },
                        "order-sm-1",
                        "order-2"
                      ],
                      attrs: {
                        cols: "12",
                        sm: _vm.canViewContractorFilter ? 6 : 12
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _vm.$vuetify.breakpoint.lgAndUp
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mr-3",
                                                staticStyle: {
                                                  "min-width": "110px"
                                                },
                                                attrs: {
                                                  small: "",
                                                  rounded: "",
                                                  outlined: "",
                                                  disabled: _vm.processing,
                                                  color: "#7a7a7a"
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    left:
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-printer\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.$vuetify.breakpoint.smAndUp
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t("common.print")
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-left":
                                                      "2px !important"
                                                  },
                                                  attrs: { right: "" }
                                                },
                                                [_vm._v("mdi-menu-down")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadAndPrintWorkOrderAdminReport(
                                            "pdf"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "work-order-admin.printing.pdf"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadAndPrintWorkOrderAdminReport(
                                            "xls"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "work-order-admin.printing.excel"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                "data-cy": "reload",
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.canViewContractorFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0 order-sm-2 order-1",
                          attrs: { cols: "12", xs: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scaffolds.filter-by-contractor-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableContractors,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.selectedContractorIDs,
                              callback: function($$v) {
                                _vm.selectedContractorIDs = $$v
                              },
                              expression: "selectedContractorIDs"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.enableScaffoldLocation
            ? _c(
                "v-tabs",
                {
                  ref: "tabs",
                  staticClass: "pt-3",
                  attrs: {
                    "icons-and-text": "",
                    "show-arrows": _vm.$vuetify.breakpoint.smAndUp,
                    "next-icon": "fa-arrow-circle-right",
                    "prev-icon": "fa-arrow-circle-left"
                  },
                  model: {
                    value: _vm.active_tab,
                    callback: function($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      key: _vm.listTab.key,
                      attrs: { href: `#tab-${_vm.listTab.key}` },
                      on: {
                        click: function($event) {
                          return _vm.tabSelected(_vm.listTab)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.listTab.tabname) +
                          "\n        "
                      ),
                      _c("v-icon", [_vm._v("fas fa-file-alt")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.listTab.key,
                      attrs: { value: `tab-${_vm.listTab.key}` }
                    },
                    [
                      _c("sp-scaffolds-list", {
                        ref: "scaffoldslist",
                        staticClass: "mt-2",
                        attrs: {
                          scaffolds: _vm.scaffolds,
                          processing: _vm.processing,
                          showArchived: _vm.showArchived,
                          showArchivedMinDate: _vm.showArchivedMinDate,
                          showArchivedMaxDate: _vm.showArchivedMaxDate,
                          showArchivedDateRange: _vm.showArchivedDateRange,
                          archivedLoading: _vm.archivedLoading
                        },
                        on: {
                          "update:showArchived": function($event) {
                            _vm.showArchived = $event
                          },
                          "update:show-archived": function($event) {
                            _vm.showArchived = $event
                          },
                          "update:showArchivedDateRange": function($event) {
                            _vm.showArchivedDateRange = $event
                          },
                          "update:show-archived-date-range": function($event) {
                            _vm.showArchivedDateRange = $event
                          },
                          navigate: id => _vm.$router.push(`/scaffolds/${id}`)
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab",
                    {
                      key: _vm.mapTab.key,
                      attrs: { href: `#tab-${_vm.mapTab.key}` },
                      on: {
                        click: function($event) {
                          return _vm.tabSelected(_vm.mapTab)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.mapTab.tabname) + "\n        "
                      ),
                      _c("v-icon", [
                        _vm._v(
                          "fas " +
                            _vm._s(_vm.$store.getters.icon("scaffolds-map")) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      key: _vm.mapTab.key,
                      attrs: { value: `tab-${_vm.mapTab.key}` }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _vm.processing
                            ? _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c("v-progress-linear", {
                                    attrs: { indeterminate: "" }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showScaffoldsOnMap
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "px-0 pt-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("sp-scaffold-locations", {
                                    attrs: {
                                      scaffolds: _vm.scaffolds,
                                      height: "calc(100vh - 525px)",
                                      zoom: 15,
                                      "min-zoom": 13,
                                      "max-zoom": 19
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.showScaffoldsOnMap
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: {
                                        "justify-content": "center"
                                      }
                                    },
                                    [
                                      _c("fd-alert", {
                                        staticClass: "mb-5",
                                        staticStyle: {
                                          "border-width": "0px !important"
                                        },
                                        attrs: {
                                          label: _vm.$t(
                                            "scaffolds.list.select-map-filters-message"
                                          ),
                                          outlined: "",
                                          color: "primary"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c("sp-scaffolds-list", {
                ref: "scaffoldslist",
                attrs: {
                  scaffolds: _vm.scaffolds,
                  processing: _vm.processing,
                  showArchived: _vm.showArchived,
                  showArchivedMinDate: _vm.showArchivedMinDate,
                  showArchivedMaxDate: _vm.showArchivedMaxDate,
                  showArchivedDateRange: _vm.showArchivedDateRange,
                  archivedLoading: _vm.archivedLoading
                },
                on: {
                  "update:showArchived": function($event) {
                    _vm.showArchived = $event
                  },
                  "update:show-archived": function($event) {
                    _vm.showArchived = $event
                  },
                  "update:showArchivedDateRange": function($event) {
                    _vm.showArchivedDateRange = $event
                  },
                  "update:show-archived-date-range": function($event) {
                    _vm.showArchivedDateRange = $event
                  },
                  navigate: id => _vm.$router.push(`/scaffolds/${id}`)
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }