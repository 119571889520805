import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import rules from "@fd/lib/vue/rules";
import {
  Contractor,
  ContractorNorm,
  ContractorNormGroup,
  contractorNormService,
  contractorService
} from "../services";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import archivedDataList from "../dataMixins/archivedDataList";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import { truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import { softCrafts } from "../dataMixins/softCrafts";

type FormattedContractorNormGroup = ContractorNormGroup & {
  archived: boolean;
};
type FormattedContractorNorm = ContractorNorm & {
  name: string;
  archived: boolean;
};

export default FDVue.extend({
  name: "sp-contractor-norm-group-existing",
  mixins: [rules, tabbedView, archivedDataList, softCrafts],
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },
  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      slidein: false,
      normSearchString: "",

      firstTabKey: `0`,
      detailsTab: new PageTab({
        nameKey: "contractors.norm-groups.existing.tabs.details",
        key: "0",
        visible: true
      }),
      jobsTab: new PageTab({
        nameKey: "contractors.norm-groups.existing.tabs.jobs",
        key: "1",
        visible: true
      }),
      normsTab: new PageTab({
        nameKey: "contractors.norm-groups.existing.tabs.norms",
        key: "2",
        visible: false
      }),

      contractorID: "",
      contractor: {} as Contractor,
      normGroup: {} as FormattedContractorNormGroup,
      norms: [] as FormattedContractorNorm[]
    };
  },
  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      var tabs = [];

      if (this.showCraftOptions) tabs.push(this.jobsTab);

      tabs.push(this.normsTab);

      return tabs;
    }
  },
  watch: {
    normGroup() {
      this.updateBreadCrumbs();
    }
  },
  methods: {
    updateBreadCrumbs() {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/contractors") {
        this.notifyNewBreadcrumb({
          text: this.$t("contractors.list-title"),
          to: "/contractors",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.contractor.name,
          to: `/contractors/${this.$route.params.contractorid}`
        });
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      if (!!this.normGroup) {
        this.notifyNewBreadcrumb({
          text: this.normGroup.name,
          to: `/contractors/${this.$route.params.contractorid}/normgroups/${this.$route.params.normgroupid}`
        });
      } else {
        this.notifyNewBreadcrumb({
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        });
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadStoreNorms: "LOAD_CONTRACTOR_NORMS",
      addNorm: "ADD_CONTRACTOR_NORM",
      updateNorm: "UPDATE_CONTRACTOR_NORM",
      deleteNorm: "DELETE_CONTRACTOR_NORM",
      loadNormGroup: "LOAD_CONTRACTOR_NORM_GROUP",
      updateNormGroup: "UPDATE_CONTRACTOR_NORM_GROUP",
      deleteNormGroup: "DELETE_CONTRACTOR_NORM_GROUP"
    }),
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    backButtonClicked() {
      this.close();
    },
    close() {
      this.$router.push(
        this.$store.getters.backBreadcrumb.to || `/contractors/${this.$route.params.contractorid}`
      );
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    cancel() {
      this.close();
    },
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteNormGroup({
          id: this.$route.params.id,
          name: this.normGroup.name
        });
        this.close();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.saving = true;
      try {
        if (!this.normGroup.archived) {
          this.normGroup.archivedDate = null;
        } else if (this.normGroup.archived && !this.normGroup.archivedDate) {
          this.normGroup.archivedDate = new Date(new Date().toUTCString());
        }

        // If there's only one job enabled, the job selection tab isn't visible therefore the user can't ensure this group is visible
        // As such, automatically enable the only one craft that's visible to ensure this group doesn't disappear.
        if (this.visibleJobCount == 1) {
          if (this.showScaffoldColumn) this.normGroup.isScaffoldGroup = true;
          else if (this.showMaintenanceColumn) this.normGroup.isMaintenanceGroup = true;
          else if (this.showPaintColumn) this.normGroup.isPaintGroup = true;
          else if (this.showInsulationColumn) this.normGroup.isInsulationGroup = true;
          else if (this.showHeatTraceColumn) this.normGroup.isHeatTraceGroup = true;
          else if (this.showRefractoryColumn) this.normGroup.isRefractoryGroup = true;
          else if (this.showFireproofingColumn) this.normGroup.isFireproofingGroup = true;
        }

        await this.updateNormGroup({
          ...this.normGroup
        });

        if (closeOnComplete) {
          this.close();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    //#region Norms
    async loadData() {
      await this.loadNorms();
    },
    async loadNorms() {
      if (!this.$store.state.curEnvironment.enableNorms) return;

      await this.loadStoreNorms({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
      let contractorNormGroupId = this.$route.params.id;
      let allNorms = this.$store.state.contractorNorms.fullList as ContractorNorm[];
      let formattedNorms = allNorms
        .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0))
        .map(
          x =>
            ({
              ...x,
              name: truncateWithEllipsis(x.description, 30),
              archived: !!x.archivedDate
            } as FormattedContractorNorm)
        );
      this.norms = formattedNorms.filter(x => x.contractorNormGroupID == contractorNormGroupId);
    },
    async openNewNormDialog() {
      let text = await showTextPromptDialog({
        title: this.$t("contractors.norms.new.dialog-title"),
        label: this.$t("contractors.norms.new.text-label"),
        rules: [this.rules.required]
      });
      if (!!text?.length) {
        this.processing = true;
        try {
          let lastOrder = 0;
          if (this.norms.length) lastOrder = this.norms.slice(-1)[0].displayOrder ?? 0;
          let newNorm = {
            contractorID: this.$route.params.contractorid,
            contractorNormGroupID: this.$route.params.id,
            name: truncateWithEllipsis(text, 30),
            description: text,
            displayOrder: lastOrder + 1,
            isActive: true,
            archived: false
          } as FormattedContractorNorm;
          newNorm.id = await this.addNorm(newNorm);
          this.norms.push(newNorm);
        } catch (error) {
          this.handleError(error);
        } finally {
          this.processing = false;
        }
      }
    },
    normNavigationPath(item: ContractorNorm): string {
      return `/contractors/${this.$route.params.contractorid}/normgroups/${this.$route.params.id}/norms/${item.id}`;
    },
    navigateToNorm(item: ContractorNorm) {
      this.$router.push(this.normNavigationPath(item));
    },
    async flipNormArchived(item: FormattedContractorNorm) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateNorm({
          id: item.id,
          archivedDate: archivedDate,
          name: truncateWithEllipsis(item.description, 30)
        });
        item.archived = !!archivedDate;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async deleteNormsTableItem(item: FormattedContractorNorm) {
      if (!this.$store.state.curEnvironment.enableNorms) return;

      await this.deleteNorm(item);
      await this.loadNorms();
    },
    // *** SORTING ***
    async confirmNormItemOrdering() {
      console.log(`confirmNormItemOrdering total Norms: ${this.norms.length}`);

      let itemsToUpdate = [] as ContractorNorm[];

      this.norms.forEach((norm, index) => {
        let newOrder = index + 1;
        if (!norm.displayOrder || norm.displayOrder != newOrder) {
          console.log(`    ${norm.displayOrder} --> ${newOrder}`);
          norm.displayOrder = newOrder;
          itemsToUpdate.push(norm);
        }
      });

      if (itemsToUpdate.length > 0) {
        console.log(` updating ${itemsToUpdate.length} norms' ordering`);
        await contractorNormService.updateNormOrders(itemsToUpdate);

        var snackbarPayload = {
          text: this.$t("contractors.norms.snack-bar-order-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
    },
    async dragNormEnded(e: SortableEvent) {
      console.log(`dragNormEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.norms.splice(newIndex, 0, ...this.norms.splice(oldIndex, 1));

      this.processing = true;
      try {
        await this.confirmNormItemOrdering();
      } catch (error) {
        this.handleError(error as Error);
        await this.loadData();
      } finally {
        this.processing = false;
      }
    }

    //#endregion
  },
  created: async function() {
    this.processing = true;
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "contractor-norm-group-existing",
      parentalContext: "contractors-existing",
      selectedTab: this.firstTabKey,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });

    this.contractorID = this.$route.params.contractorid;
    this.contractor = this.$store.state.contractors.fullList.find(
      (x: any) => x.id == this.$route.params.contractorid
    );
    if (!this.contractor) {
      this.contractor = await contractorService.getByID(this.contractorID);
    }

    this.updateBreadCrumbs();

    try {
      await Promise.all([this.loadNormGroup(this.$route.params.id)]);

      let normGroup = this.$store.state.contractorNormGroups.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );
      this.normGroup = {
        ...normGroup,
        archived: !!normGroup?.archivedDate
      };
      await this.loadNorms();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});
