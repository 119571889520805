var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "equipmentexisting" } },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/equipment`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "mx-0 px-2 mb-3 fd-inline-request-tags-qualifier",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("fd-chip-selector", {
                                attrs: {
                                  "available-items": _vm.availableTags,
                                  "selected-items": _vm.selectedTags,
                                  "item-label": "name",
                                  disabled: _vm.processing,
                                  "control-label": _vm.$t("common.add-tags"),
                                  "chip-class":
                                    "keywords-selection-chip-colors",
                                  outlined: false
                                },
                                on: {
                                  "update:selectedItems": function($event) {
                                    _vm.selectedTags = $event
                                  },
                                  "update:selected-items": function($event) {
                                    _vm.selectedTags = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pa-3", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("common.name"),
                                  disabled: _vm.processing,
                                  rules: _vm.equipmentRules.name
                                },
                                model: {
                                  value: _vm.equipment.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.equipment, "name", $$v)
                                  },
                                  expression: "equipment.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("equipment.serial-number"),
                                  disabled: _vm.processing,
                                  rules: _vm.equipmentRules.serialNumber
                                },
                                model: {
                                  value: _vm.equipment.serialNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.equipment, "serialNumber", $$v)
                                  },
                                  expression: "equipment.serialNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-select", {
                                class: {
                                  "fd-readonly": !_vm.canEditContractor
                                },
                                attrs: {
                                  outlined: "",
                                  disabled:
                                    !_vm.canEditContractor || _vm.processing,
                                  readonly: !_vm.canEditContractor,
                                  label: _vm.$t("equipment.contractor"),
                                  items: _vm.contractors,
                                  "item-text": "name",
                                  "item-value": "id",
                                  rules: _vm.equipmentRules.contractorID,
                                  dense: _vm.$vuetify.breakpoint.xsOnly
                                },
                                model: {
                                  value: _vm.equipment.contractorID,
                                  callback: function($$v) {
                                    _vm.$set(_vm.equipment, "contractorID", $$v)
                                  },
                                  expression: "equipment.contractorID"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _c("v-select", {
                                  class: {
                                    "fd-readonly": !_vm.canEditEquipmentClassification
                                  },
                                  attrs: {
                                    outlined: "",
                                    disabled:
                                      !_vm.canEditEquipmentClassification ||
                                      _vm.processing,
                                    readonly: !_vm.canEditEquipmentClassification,
                                    label: _vm.$t(
                                      "equipment.equipment-classification"
                                    ),
                                    items: _vm.equipmentClassifications,
                                    "item-text": "name",
                                    "item-value": "id",
                                    rules:
                                      _vm.equipmentRules
                                        .equipmentClassificationID,
                                    dense: _vm.$vuetify.breakpoint.xsOnly
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-row",
                                            {
                                              style: _vm.$vuetify.breakpoint
                                                .smAndUp
                                                ? "width: 110px;"
                                                : "width: 40px;",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "v-input__icon v-input__icon--append",
                                                  staticStyle: {
                                                    "flex-grow": "0"
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "v-icon notranslate mdi mdi-menu-down theme--light",
                                                    attrs: {
                                                      "aria-hidden": "true"
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ]),
                                  model: {
                                    value:
                                      _vm.equipment.equipmentClassificationID,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.equipment,
                                        "equipmentClassificationID",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "equipment.equipmentClassificationID"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    class: [
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? ""
                                        : "fd-small-history",
                                      "white--text"
                                    ],
                                    staticStyle: {
                                      position: "absolute",
                                      right: "40px"
                                    },
                                    style: _vm.$vuetify.breakpoint.xsOnly
                                      ? "top: 6px;"
                                      : "top: 14px;",
                                    attrs: { color: "blue-grey", small: "" },
                                    on: {
                                      click: _vm.classificationHistoryClicked
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        class: [
                                          _vm.$vuetify.breakpoint.smAndUp
                                            ? ""
                                            : "mr-0"
                                        ],
                                        attrs: { left: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    mdi-history\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.$vuetify.breakpoint.smAndUp
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("common.history"))
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("fd-rich-textarea", {
                                attrs: {
                                  label: _vm.$t("common.description"),
                                  disabled: _vm.processing,
                                  rules: _vm.equipmentRules.description
                                },
                                model: {
                                  value: _vm.equipment.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.equipment, "description", $$v)
                                  },
                                  expression: "equipment.description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("equipment.manufacturer"),
                                  disabled: _vm.processing,
                                  rules: _vm.equipmentRules.manufacturer,
                                  "persistent-hint": ""
                                },
                                model: {
                                  value: _vm.equipment.manufacturer,
                                  callback: function($$v) {
                                    _vm.$set(_vm.equipment, "manufacturer", $$v)
                                  },
                                  expression: "equipment.manufacturer"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("equipment.model-number"),
                                  disabled: _vm.processing,
                                  rules: _vm.equipmentRules.modelNumber,
                                  "persistent-hint": ""
                                },
                                model: {
                                  value: _vm.equipment.modelNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.equipment, "modelNumber", $$v)
                                  },
                                  expression: "equipment.modelNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("equipment.model-year"),
                                  disabled: _vm.processing,
                                  rules: _vm.equipmentRules.modelYear,
                                  "persistent-hint": ""
                                },
                                model: {
                                  value: _vm.equipment.modelYear,
                                  callback: function($$v) {
                                    _vm.$set(_vm.equipment, "modelYear", $$v)
                                  },
                                  expression: "equipment.modelYear"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("equipment.unit-color"),
                                  disabled: _vm.processing,
                                  rules: _vm.equipmentRules.unitColor,
                                  "persistent-hint": ""
                                },
                                model: {
                                  value: _vm.equipment.unitColor,
                                  callback: function($$v) {
                                    _vm.$set(_vm.equipment, "unitColor", $$v)
                                  },
                                  expression: "equipment.unitColor"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12" }
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  "data-cy": "archive",
                                  label: _vm.$t("common.archived"),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.equipment.isArchived,
                                  callback: function($$v) {
                                    _vm.$set(_vm.equipment, "isArchived", $$v)
                                  },
                                  expression: "equipment.isArchived"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.inlineMessage.message
                        ? _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        { staticClass: "mx-2" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                disabled: _vm.processing,
                                loading: _vm.deleting,
                                color: "error",
                                outlined: "",
                                small: _vm.$vuetify.breakpoint.name == "xs"
                              },
                              on: { click: _vm.deleteItem }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.delete")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "data-cy": "close",
                                text: "",
                                link: "",
                                disabled: _vm.processing,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.cancel }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.cancel")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("fd-menu-save", {
                            attrs: {
                              disabled: _vm.processing,
                              loading: _vm.saving,
                              small: _vm.$vuetify.breakpoint.xsOnly
                            },
                            on: {
                              "click:save": function($event) {
                                return _vm.save(false)
                              },
                              "click:save-and-close": function($event) {
                                return _vm.save(true)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }