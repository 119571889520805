import { equipmentClassificationService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: equipmentClassificationService,
  singularStoreName: "EQUIPMENT_CLASSIFICATION",
  pluralStoreName: "EQUIPMENT_CLASSIFICATIONS",
  localizationPrefix: "equipment-classifications",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "equipmentClassificationID",
    type: "string"
  }
});

export default storeModule;

