import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { EquipmentClassification } from "../../../services";

const EquipmentClassificationNewDialog = FDVue.extend({
  name: "fd-equipment-classification-new-dialog",

  mixins: [dialogSupport, rules],

  data: function() {
    return {
      saving: false,
      equipmentClassification: {
        name: "",
        description: "",
        dailyRate: 0,
        weeklyRate: 0,
        monthlyRate: 0
      } as EquipmentClassification
    };
  },

  computed: {
    equipmentClassificationRules() {
      return {
        name: [this.rules.required],
        description: [],
        dailyRate: [],
        weeklyRate: [],
        monthlyRate: []
      };
    }
  },

  methods: {
    async open() {
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.saving = true;
      this.processing = true;
      try {
        await this.addEquipmentClassification({
          ...this.equipmentClassification
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    ...mapActions({
      addEquipmentClassification: "ADD_EQUIPMENT_CLASSIFICATION"
    })
  }
});

export default EquipmentClassificationNewDialog;

export async function showEquipmentClassificationNewDialog(): Promise<boolean> {
  let dialog = createDialog(EquipmentClassificationNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open();
}

