var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "jobexisting" } },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: { name: _vm.imageName },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: { route: _vm.backButtonClicked }
                                  }),
                                  _vm._v(" "),
                                  _vm.job.jobStatusID != undefined
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            {
                                              "ml-2":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            "py-1",
                                            "px-3",
                                            "fd-status-indicator"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  `job.statuses.${_vm.job.jobStatusID}`
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _vm.jobIsOnHold
                            ? _c("fd-alert", {
                                attrs: {
                                  type: "warning",
                                  label:
                                    !!_vm.job.jobStatusDetail &&
                                    _vm.job.jobStatusDetail.length > 0
                                      ? _vm.$t("job.job-is-on-hold-with-reason")
                                      : _vm.$t("job.job-is-on-hold"),
                                  comments:
                                    !!_vm.job.jobStatusDetail &&
                                    _vm.job.jobStatusDetail.length > 0
                                      ? _vm.job.jobStatusDetail
                                      : ""
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.jobIsCancelled
                            ? _c("fd-alert", {
                                attrs: {
                                  type: "error",
                                  label:
                                    !!_vm.job.jobStatusDetail &&
                                    _vm.job.jobStatusDetail.length > 0
                                      ? _vm.$t(
                                          "job.job-is-cancelled-with-reason"
                                        )
                                      : _vm.$t("job.job-is-cancelled"),
                                  comments:
                                    !!_vm.job.jobStatusDetail &&
                                    _vm.job.jobStatusDetail.length > 0
                                      ? _vm.job.jobStatusDetail
                                      : ""
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "pt-3",
                          attrs: {
                            "icons-and-text": "",
                            "show-arrows": _vm.$vuetify.breakpoint.smAndUp,
                            "next-icon": "fa-arrow-circle-right",
                            "prev-icon": "fa-arrow-circle-left"
                          },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detailsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.scopeTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.scopeTab.key,
                                  attrs: {
                                    "data-cy": "scopetab",
                                    href: `#tab-${_vm.scopeTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.scopeTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.scopeTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-chart-gantt")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.photosTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.photosTab.key,
                                  attrs: { href: `#tab-${_vm.photosTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.photosTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.photosTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-camera")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.notesTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.notesTab.key,
                                  attrs: { href: `#tab-${_vm.notesTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.notesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.notesTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-clipboard")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.messagingTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.messagingTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.messagingTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.messagingTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.messagingTab.tabname) +
                                      "\n              "
                                  ),
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        value: _vm.messages.length > 0,
                                        color: "primary",
                                        content: _vm.messages.length
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("fas fa-comments")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.attachmentsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.attachmentsTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.attachmentsTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.attachmentsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.attachmentsTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-paperclip")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.optionsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.optionsTab.key,
                                  attrs: { href: `#tab-${_vm.optionsTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.optionsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.optionsTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v(
                                      "fas " +
                                        _vm._s(
                                          _vm.$store.getters.icon("options")
                                        )
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isScaffoldJob &&
                          (_vm.estimatesTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.estimatesTab.key,
                                  attrs: {
                                    "data-cy": "estimates",
                                    href: `#tab-${_vm.estimatesTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.estimatesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.estimatesTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-pen-ruler")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.workOrdersTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.workOrdersTab.key,
                                  ref: "tab",
                                  attrs: {
                                    href: `#tab-${_vm.workOrdersTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.workOrdersTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.workOrdersTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-file-alt")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isScaffoldJob &&
                          (_vm.takeoffTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.takeoffTab.key,
                                  attrs: { href: `#tab-${_vm.takeoffTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.takeoffTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.takeoffTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v(
                                      "fas " +
                                        _vm._s(
                                          _vm.$store.getters.icon("takeoff")
                                        )
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              attrs: { text: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("common.more")) +
                                              "\n\n                  "
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-ellipsis-h")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "grey lighten-3" },
                                _vm._l(_vm.hiddenTabDefinitions, function(
                                  item
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.tabname,
                                      on: {
                                        click: function($event) {
                                          return _vm.showNewTabFromMoreMenu(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.tabname) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "detailsform",
                                  class: { "fd-readonly": _vm.detailsReadonly },
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mx-0 px-2 fd-inline-request-tags-qualifier",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-chip-selector", {
                                            attrs: {
                                              "available-items":
                                                _vm.availableKeywords,
                                              "selected-items":
                                                _vm.selectedKeywords,
                                              "item-label": "name",
                                              disabled: _vm.processing,
                                              "control-label": _vm.$t(
                                                "common.add-tags"
                                              ),
                                              "chip-class":
                                                "keywords-selection-chip-colors",
                                              outlined: false
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                _vm.selectedKeywords = $event
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                _vm.selectedKeywords = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-2 pt-5",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              "data-cy": "request-type",
                                              label: _vm.$t("job.request-type"),
                                              disabled: _vm.processing,
                                              readonly: _vm.detailsReadonly,
                                              items: _vm.requestTypes,
                                              rules: _vm.jobRules.requestType
                                            },
                                            model: {
                                              value: _vm.job.requestType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "requestType",
                                                  $$v
                                                )
                                              },
                                              expression: "job.requestType"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              "data-cy": "request-sub-type",
                                              label: _vm.$t(
                                                "job.request-sub-type"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: _vm.detailsReadonly,
                                              items: _vm.requestSubTypes,
                                              rules: _vm.jobRules.requestSubType
                                            },
                                            model: {
                                              value: _vm.job.requestSubType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "requestSubType",
                                                  $$v
                                                )
                                              },
                                              expression: "job.requestSubType"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fp-date-picker", {
                                            attrs: {
                                              outlined: "",
                                              cy: "requireddate",
                                              value: _vm.job.requiredDate,
                                              disabled: _vm.processing,
                                              readonly: _vm.detailsReadonly,
                                              label: _vm.$t(
                                                "job.required-date"
                                              ),
                                              rules: _vm.jobRules.requiredDate,
                                              clearable: "",
                                              min: _vm.currentUserCanEnterHistoricalData
                                                ? undefined
                                                : "today"
                                            },
                                            on: {
                                              "update:value": function($event) {
                                                return _vm.$set(
                                                  _vm.job,
                                                  "requiredDate",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.showBlock4
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.canEnterRequiredUntilDate
                                                ? _c("fp-date-picker", {
                                                    attrs: {
                                                      cy: "requireduntildate",
                                                      value:
                                                        _vm.job
                                                          .requiredUntilDate,
                                                      disabled: _vm.processing,
                                                      readonly:
                                                        _vm.detailsReadonly,
                                                      label: _vm.$t(
                                                        "job.required-until-date"
                                                      ),
                                                      rules:
                                                        _vm.jobRules
                                                          .requiredUntilDate,
                                                      clearable: "",
                                                      min: !!_vm.job
                                                        .requiredDate
                                                        ? _vm.job.requiredDate
                                                        : "today"
                                                    },
                                                    on: {
                                                      "update:value": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.job,
                                                          "requiredUntilDate",
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm.canSelectScaffold
                                                ? _c("fd-async-search-box", {
                                                    attrs: {
                                                      "data-cy": "tagnumber",
                                                      refreshItems:
                                                        _vm.loadScaffolds,
                                                      items:
                                                        _vm.availableScaffolds,
                                                      selectedItems:
                                                        _vm.selectedScaffold,
                                                      label: _vm.$t(
                                                        "job.tag-number"
                                                      ),
                                                      multiple: false,
                                                      itemText: "search",
                                                      itemDetail: "details",
                                                      chipClass:
                                                        "keywords-selection-chip-colors-secondary",
                                                      disabled:
                                                        _vm.job.requestType ==
                                                          undefined ||
                                                        _vm.isScaffoldErectJob ||
                                                        _vm.detailsReadonly ||
                                                        _vm.processing,
                                                      placeholder: _vm.$t(
                                                        "job.tag-select-placeholder"
                                                      ),
                                                      rules:
                                                        _vm.jobRules
                                                          .scaffoldNumber
                                                    },
                                                    on: {
                                                      "update:selectedItems": function(
                                                        $event
                                                      ) {
                                                        _vm.selectedScaffold = $event
                                                      },
                                                      "update:selected-items": function(
                                                        $event
                                                      ) {
                                                        _vm.selectedScaffold = $event
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "selection",
                                                          fn: function({
                                                            item
                                                          }) {
                                                            return [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      item.description
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ])
                                                            ]
                                                          }
                                                        },
                                                        {
                                                          key: "item",
                                                          fn: function({
                                                            item
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        "no-gutters":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            item.description
                                                                          ) +
                                                                          "\n                            "
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      !!item.externalReferenceNumber
                                                                        ? _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 white--text",
                                                                              attrs: {
                                                                                small: !_vm
                                                                                  .$vuetify
                                                                                  .breakpoint
                                                                                  .sm,
                                                                                "x-small":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .sm,
                                                                                color:
                                                                                  "#A9A9A9"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.externalReferenceNumber
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-chip",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2",
                                                                          attrs: {
                                                                            small: !_vm
                                                                              .$vuetify
                                                                              .breakpoint
                                                                              .sm,
                                                                            "x-small":
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .sm
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                `scaffolds.status.${item.scaffoldStatus}`
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  !!item.details
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "fd-item-detail-light pb-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                item.details
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3786668002
                                                    )
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showBlock5
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.canSelectContractor
                                                ? _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      "data-cy": "contractor",
                                                      label: _vm.$t(
                                                        "job.contractor"
                                                      ),
                                                      disabled: _vm.processing,
                                                      readonly:
                                                        _vm.detailsReadonly,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      items: _vm.contractors,
                                                      rules:
                                                        _vm.jobRules
                                                          .contractorID,
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.job.contractorID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.job,
                                                          "contractorID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "job.contractorID"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showBlock6
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-0",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.canSelectScaffoldDesign
                                                ? _c("fd-async-search-box", {
                                                    attrs: {
                                                      refreshItems:
                                                        _vm.loadScaffoldDesigns,
                                                      items:
                                                        _vm.availableDesigns,
                                                      selectedItems:
                                                        _vm.selectedDesign,
                                                      label: _vm.$t(
                                                        "job.designed-tag-number"
                                                      ),
                                                      multiple: false,
                                                      itemText:
                                                        "formattedScaffoldNumber",
                                                      chipClass:
                                                        "keywords-selection-chip-colors-secondary",
                                                      disabled: _vm.processing
                                                    },
                                                    on: {
                                                      "update:selectedItems": function(
                                                        $event
                                                      ) {
                                                        _vm.selectedDesign = $event
                                                      },
                                                      "update:selected-items": function(
                                                        $event
                                                      ) {
                                                        _vm.selectedDesign = $event
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              "data-cy": "discipline",
                                              label: _vm.$t("job.discipline"),
                                              disabled:
                                                !_vm.job.contractorID ||
                                                _vm.detailsReadonly ||
                                                _vm.processing,
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.disciplines,
                                              rules: _vm.jobRules.disciplineID,
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.job.disciplineID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "disciplineID",
                                                  $$v
                                                )
                                              },
                                              expression: "job.disciplineID"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              outlined: "",
                                              "data-cy": "requestor",
                                              label: _vm.$t("job.requestor"),
                                              disabled:
                                                !_vm.job.disciplineID ||
                                                _vm.detailsReadonly ||
                                                _vm.processing,
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.requestors,
                                              rules:
                                                _vm.jobRules
                                                  .requestingEmployeeID,
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.job.requestingEmployeeID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "requestingEmployeeID",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "job.requestingEmployeeID"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              "data-cy": "area",
                                              label: _vm.$t("job.area"),
                                              disabled:
                                                _vm.detailsReadonly ||
                                                _vm.dataSetByScaffold ||
                                                _vm.dataSetByDesign ||
                                                !_vm.job.contractorID ||
                                                _vm.processing,
                                              readonly:
                                                _vm.dataSetByScaffold ||
                                                _vm.dataSetByDesign,
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.areas,
                                              rules: _vm.jobRules.areaID,
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.job.areaID,
                                              callback: function($$v) {
                                                _vm.$set(_vm.job, "areaID", $$v)
                                              },
                                              expression: "job.areaID"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              "data-cy": "subarea",
                                              label: _vm.$t("job.sub-area"),
                                              disabled:
                                                !_vm.job.areaID ||
                                                _vm.detailsReadonly ||
                                                _vm.dataSetByScaffold ||
                                                _vm.dataSetByDesign ||
                                                _vm.processing,
                                              readonly:
                                                _vm.dataSetByScaffold ||
                                                _vm.dataSetByDesign,
                                              "item-text": "name",
                                              "item-value": "id",
                                              items: _vm.subAreas,
                                              rules: _vm.jobRules.subAreaID,
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.job.subAreaID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "subAreaID",
                                                  $$v
                                                )
                                              },
                                              expression: "job.subAreaID"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1 reduce-underlying-control-padding",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              outlined: "",
                                              label: _vm.$t("job.site-contact"),
                                              disabled:
                                                _vm.processing ||
                                                _vm.detailsReadonly,
                                              rules: _vm.jobRules.siteContact
                                            },
                                            model: {
                                              value: _vm.job.siteContact,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "siteContact",
                                                  $$v
                                                )
                                              },
                                              expression: "job.siteContact"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1 reduce-underlying-control-padding",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-textarea", {
                                            attrs: {
                                              outlined: "",
                                              cy: "location",
                                              label: _vm.$t("job.location"),
                                              disabled: _vm.processing,
                                              readonly: _vm.detailsReadonly,
                                              rules: _vm.jobRules.location,
                                              rows: "1",
                                              "auto-grow": ""
                                            },
                                            model: {
                                              value:
                                                _vm.job.specificWorkLocation,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "specificWorkLocation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "job.specificWorkLocation"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1 reduce-underlying-control-padding",
                                          attrs: { align: "center", cols: "12" }
                                        },
                                        [
                                          _c("fd-textarea", {
                                            attrs: {
                                              outlined: "",
                                              cy: "description",
                                              label: _vm.$t("job.description"),
                                              disabled: _vm.processing,
                                              readonly: _vm.detailsReadonly,
                                              rules: _vm.jobRules.description,
                                              rows: "1",
                                              "auto-grow": ""
                                            },
                                            model: {
                                              value:
                                                _vm.job.detailedWorkDescription,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "detailedWorkDescription",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "job.detailedWorkDescription"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-3 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("fd-work-package-selector", {
                                            attrs: {
                                              loading: _vm.processing,
                                              refreshItems:
                                                _vm.loadWorkPackages,
                                              items: _vm.availableIWPs,
                                              disabled:
                                                _vm.detailsReadonly ||
                                                _vm.processing,
                                              rules: _vm.jobRules.workPackageIDs
                                            },
                                            on: {
                                              focused: function($event) {
                                                {
                                                  _vm.hideActionButtons = true
                                                }
                                              },
                                              unfocused: function($event) {
                                                {
                                                  _vm.hideActionButtons = false
                                                }
                                              }
                                            },
                                            model: {
                                              value: _vm.selectedIWPs,
                                              callback: function($$v) {
                                                _vm.selectedIWPs = $$v
                                              },
                                              expression: "selectedIWPs"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !_vm.isScaffoldJob
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-3 pb-7",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                md: "12"
                                              }
                                            },
                                            [
                                              _c("fd-async-search-box", {
                                                attrs: {
                                                  "data-cy": "iso",
                                                  refreshItems: _vm.loadIsos,
                                                  items: _vm.availableIsos,
                                                  selectedItems:
                                                    _vm.selectedIso,
                                                  label: _vm.$t("job.iso"),
                                                  multiple: false,
                                                  itemText: "search",
                                                  itemDetail: "details",
                                                  chipClass:
                                                    "keywords-selection-chip-colors-secondary",
                                                  disabled:
                                                    _vm.detailsReadonly ||
                                                    _vm.processing,
                                                  placeholder: _vm.$t(
                                                    "job.iso-select-placeholder"
                                                  ),
                                                  rules: _vm.jobRules.iso
                                                },
                                                on: {
                                                  "update:selectedItems": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedIso = $event
                                                  },
                                                  "update:selected-items": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedIso = $event
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function({ item }) {
                                                        return [
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  item.description
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item",
                                                      fn: function({ item }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        item.description
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              !!item.details
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fd-item-detail-light pb-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            item.details
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2755498417
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.notesTab.key,
                              attrs: { value: `tab-${_vm.notesTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "notesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "12", lg: "8" }
                                        },
                                        [
                                          _vm.currentUserCanAddNote ||
                                          (!!_vm.notes && _vm.notes.length > 0)
                                            ? _c(
                                                "v-timeline",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "fd-new-note-timeline-item",
                                                    {
                                                      attrs: {
                                                        loading: _vm.saving,
                                                        disabled:
                                                          !_vm.currentUserCanAddNote ||
                                                          _vm.processing
                                                      },
                                                      on: {
                                                        addNewNote:
                                                          _vm.addNewNote
                                                      },
                                                      model: {
                                                        value: _vm.newNoteText,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.newNoteText = $$v
                                                        },
                                                        expression:
                                                          "newNoteText"
                                                      }
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(_vm.notes, function(
                                                    item,
                                                    i
                                                  ) {
                                                    return _c(
                                                      "fd-history-note-timeline-item",
                                                      {
                                                        key: i,
                                                        attrs: { note: item }
                                                      }
                                                    )
                                                  })
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.messagingTab.key,
                              attrs: { value: `tab-${_vm.messagingTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "messagingform",
                                  class: ["mr-5"],
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "12", lg: "8" }
                                        },
                                        [
                                          _vm.currentUserCanAddMessage ||
                                          (!!_vm.messages &&
                                            _vm.messages.length > 0)
                                            ? _c(
                                                "v-timeline",
                                                { attrs: { dense: "" } },
                                                [
                                                  _vm.currentUserCanAddMessage
                                                    ? _c(
                                                        "v-timeline-item",
                                                        {
                                                          staticClass:
                                                            "fd-new-messaging-avatar",
                                                          attrs: {
                                                            large:
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .smAndUp
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "icon",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-avatar",
                                                                      {
                                                                        staticClass:
                                                                          "profile-avatar-background-color",
                                                                        attrs: {
                                                                          size: _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .smAndUp
                                                                            ? "48"
                                                                            : "34"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class: [
                                                                              "white--text",
                                                                              "text-h5",
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .smAndUp
                                                                                ? ""
                                                                                : "fd-work-order-message-avatar-initials"
                                                                            ]
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.avatarInitials
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            813896479
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                position:
                                                                  "relative"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-textarea",
                                                                {
                                                                  attrs: {
                                                                    rows: "1",
                                                                    dense:
                                                                      _vm
                                                                        .$vuetify
                                                                        .breakpoint
                                                                        .xsOnly,
                                                                    loading:
                                                                      _vm.saving,
                                                                    disabled:
                                                                      !_vm.currentUserCanAddMessage ||
                                                                      _vm.processing,
                                                                    "auto-grow":
                                                                      "",
                                                                    label: _vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .xs
                                                                      ? _vm.$t(
                                                                          "job.messaging-new-input-placeholder-short"
                                                                        )
                                                                      : _vm.$t(
                                                                          "job.messaging-new-input-placeholder"
                                                                        )
                                                                  },
                                                                  on: {
                                                                    keydown: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      )
                                                                        return null
                                                                      return _vm.comment.apply(
                                                                        null,
                                                                        arguments
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.newMessageText,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.newMessageText = $$v
                                                                    },
                                                                    expression:
                                                                      "newMessageText"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  class: _vm
                                                                    .$vuetify
                                                                    .breakpoint
                                                                    .xs
                                                                    ? "fd-new-messaging-btn-small"
                                                                    : "fd-new-messaging-btn",
                                                                  attrs: {
                                                                    "data-cy":
                                                                      "post",
                                                                    loading:
                                                                      _vm.saving,
                                                                    small:
                                                                      _vm
                                                                        .$vuetify
                                                                        .breakpoint
                                                                        .xsOnly,
                                                                    disabled:
                                                                      !_vm.currentUserCanAddMessage ||
                                                                      _vm.processing
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.addNewMessage
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "common.post"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm._l(_vm.messages, function(
                                                    item,
                                                    i
                                                  ) {
                                                    return _c(
                                                      "fd-history-message-timeline-item",
                                                      {
                                                        key: i,
                                                        attrs: { message: item }
                                                      }
                                                    )
                                                  })
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.scopeTab.key,
                              attrs: { value: `tab-${_vm.scopeTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { staticStyle: { "padding-top": "1px" } },
                                [
                                  _c("sp-work-order-scope-details-form", {
                                    ref: "scopechangeform",
                                    attrs: {
                                      cy: "scopechangeform",
                                      disabled: _vm.processing,
                                      isClientWorkOrder:
                                        _vm.job.isClientWorkOrder,
                                      clientWorkOrderReferenceNumber:
                                        _vm.job.clientWorkOrderReferenceNumber,
                                      clientWorkOrderReferenceNumberRules:
                                        _vm.jobRules
                                          .clientWorkOrderReferenceNumber,
                                      clientWorkOrderReason:
                                        _vm.job.clientWorkOrderReason,
                                      clientWorkOrderReasonRules:
                                        _vm.jobRules.clientWorkOrderReason,
                                      isChangeOrder: _vm.job.isChangeOrder,
                                      changeOrderReferenceNumber:
                                        _vm.job.changeOrderReferenceNumber,
                                      changeOrderReferenceNumberRules:
                                        _vm.jobRules.changeOrderReferenceNumber,
                                      changeOrderReason:
                                        _vm.job.changeOrderReason,
                                      changeOrderReasonRules:
                                        _vm.jobRules.changeOrderReason,
                                      isRework: _vm.job.isRework,
                                      reworkReferenceNumber:
                                        _vm.job.reworkReferenceNumber,
                                      reworkReferenceNumberRules:
                                        _vm.jobRules.reworkReferenceNumber,
                                      reworkReason: _vm.job.reworkReason,
                                      reworkReasonRules:
                                        _vm.jobRules.reworkReason,
                                      isServiceOrder: _vm.job.isServiceOrder,
                                      serviceOrderReferenceNumber:
                                        _vm.job.serviceOrderReferenceNumber,
                                      serviceOrderReferenceNumberRules:
                                        _vm.jobRules
                                          .serviceOrderReferenceNumber,
                                      serviceOrderReason:
                                        _vm.job.serviceOrderReason,
                                      serviceOrderReasonRules:
                                        _vm.jobRules.serviceOrderReason,
                                      "purchase-orders":
                                        _vm.$store.state.curEnvironment
                                          .enablePurchaseOrders,
                                      purchaseOrderID: _vm.job.purchaseOrderID,
                                      purchaseOrderIDRules:
                                        _vm.jobRules.purchaseOrderNumber,
                                      purchaseOrderDisabled: !_vm.job
                                        .contractorID,
                                      selectablePurchaseOrders:
                                        _vm.selectablePurchaseOrders,
                                      showExistingTagNumber:
                                        _vm.jobCanHaveExistingTagNumber,
                                      existingTagNumber:
                                        _vm.job.existingTagNumber
                                    },
                                    on: {
                                      "update:isClientWorkOrder": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "isClientWorkOrder",
                                          $event
                                        )
                                      },
                                      "update:is-client-work-order": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "isClientWorkOrder",
                                          $event
                                        )
                                      },
                                      "update:clientWorkOrderReferenceNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "clientWorkOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:client-work-order-reference-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "clientWorkOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:clientWorkOrderReason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "clientWorkOrderReason",
                                          $event
                                        )
                                      },
                                      "update:client-work-order-reason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "clientWorkOrderReason",
                                          $event
                                        )
                                      },
                                      "update:isChangeOrder": function($event) {
                                        return _vm.$set(
                                          _vm.job,
                                          "isChangeOrder",
                                          $event
                                        )
                                      },
                                      "update:is-change-order": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "isChangeOrder",
                                          $event
                                        )
                                      },
                                      "update:changeOrderReferenceNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "changeOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:change-order-reference-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "changeOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:changeOrderReason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "changeOrderReason",
                                          $event
                                        )
                                      },
                                      "update:change-order-reason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "changeOrderReason",
                                          $event
                                        )
                                      },
                                      "update:isRework": function($event) {
                                        return _vm.$set(
                                          _vm.job,
                                          "isRework",
                                          $event
                                        )
                                      },
                                      "update:is-rework": function($event) {
                                        return _vm.$set(
                                          _vm.job,
                                          "isRework",
                                          $event
                                        )
                                      },
                                      "update:reworkReferenceNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "reworkReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:rework-reference-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "reworkReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:reworkReason": function($event) {
                                        return _vm.$set(
                                          _vm.job,
                                          "reworkReason",
                                          $event
                                        )
                                      },
                                      "update:rework-reason": function($event) {
                                        return _vm.$set(
                                          _vm.job,
                                          "reworkReason",
                                          $event
                                        )
                                      },
                                      "update:isServiceOrder": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "isServiceOrder",
                                          $event
                                        )
                                      },
                                      "update:is-service-order": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "isServiceOrder",
                                          $event
                                        )
                                      },
                                      "update:serviceOrderReferenceNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "serviceOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:service-order-reference-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "serviceOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:serviceOrderReason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "serviceOrderReason",
                                          $event
                                        )
                                      },
                                      "update:service-order-reason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "serviceOrderReason",
                                          $event
                                        )
                                      },
                                      "update:purchaseOrderID": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "purchaseOrderID",
                                          $event
                                        )
                                      },
                                      "update:purchase-order-i-d": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "purchaseOrderID",
                                          $event
                                        )
                                      },
                                      "update:existingTagNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "existingTagNumber",
                                          $event
                                        )
                                      },
                                      "update:existing-tag-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.job,
                                          "existingTagNumber",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.photosTab.key,
                              attrs: { value: `tab-${_vm.photosTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "filesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c("fd-add-file-button", {
                                                attrs: {
                                                  disabled: _vm.processing
                                                },
                                                on: {
                                                  change: _vm.selectNewFile
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-divider", {
                                                staticClass: "mx-4",
                                                attrs: {
                                                  inset: "",
                                                  vertical: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-1 mr-3",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "job.photos-table-listing-tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.tablesearchfiles,
                                                  callback: function($$v) {
                                                    _vm.tablesearchfiles = $$v
                                                  },
                                                  expression: "tablesearchfiles"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("fd-alert", {
                                            attrs: {
                                              type: "info",
                                              label: _vm.$t(
                                                "attachments.file-not-photo-alert-message",
                                                [
                                                  _vm.touchedFileName.toUpperCase()
                                                ]
                                              ),
                                              dismissible: ""
                                            },
                                            model: {
                                              value:
                                                _vm.showPhotoTabAttachmentAlert,
                                              callback: function($$v) {
                                                _vm.showPhotoTabAttachmentAlert = $$v
                                              },
                                              expression:
                                                "showPhotoTabAttachmentAlert"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:[photosIconColumnArgument].no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                                value: "",
                                                expression:
                                                  "\n                        ''\n                      ",
                                                arg:
                                                  _vm.photosIconColumnArgument,
                                                modifiers: {
                                                  "no-sort": true,
                                                  "hide-when-extra-small": true,
                                                  "class_fd-table-icon-cell": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                arg: "actions",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "hide-when-extra-small": true,
                                                  "class_fd-actions-cell": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                                arg: "action",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "show-when-extra-small": true,
                                                  "class_fd-actions-cell-edit-only": true
                                                }
                                              }
                                            ],
                                            class: _vm.$vuetify.breakpoint
                                              .xsOnly
                                              ? "fd-action-table"
                                              : "fd-actions-table",
                                            attrs: {
                                              items: _vm.photoFiles,
                                              search: _vm.tablesearchfiles,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "item-class":
                                                _vm.fileRowClassName,
                                              "mobile-breakpoint": "0"
                                            },
                                            on: {
                                              "dblclick:row":
                                                _vm.photosRowClicked
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.icon",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  !!item.isIsoFile
                                                                    ? _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$store.getters.icon(
                                                                                "isos"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "job.existing.file-comes-from-iso"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.action",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewPhoto(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.actions",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        class: [
                                                          {
                                                            "table-5-actions-column-min-width":
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp,
                                                            "table-4-actions-column-min-width": !_vm
                                                              .$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openFileInNewWindow(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-open-in-new\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.open-in-new-tab"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.isPreviewable ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.viewPhoto(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-eye\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.view"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.isPreviewable ||
                                                                                item.isIsoFile ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-pencil\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.edit"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.$vuetify.breakpoint
                                                          .mdAndUp
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.downloadFile(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  mdi-cloud-download\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.download"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing ||
                                                                                item.isIsoFile
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-delete\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.remove"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.attachmentsTab.key,
                              attrs: { value: `tab-${_vm.attachmentsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "attachmentsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-2",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c("input", {
                                                    ref: "addFileButton",
                                                    staticClass: "d-none",
                                                    attrs: {
                                                      type: "file",
                                                      onclick:
                                                        "this.value=null;"
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.fileInputChanged
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "add-fab-button-primary",
                                                                    attrs: {
                                                                      fab: "",
                                                                      elevation:
                                                                        "5",
                                                                      depressed:
                                                                        _vm.detailsReadonly ||
                                                                        _vm.processing,
                                                                      disabled:
                                                                        _vm.processing,
                                                                      color:
                                                                        "new"
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.openNewExternalLinkDialog
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "attachments.external-link.menu-title"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.selectFile
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.local-file"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-divider", {
                                                    staticClass: "mx-4",
                                                    attrs: {
                                                      inset: "",
                                                      vertical: ""
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      color:
                                                                        "black",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v("info")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "job.files-table-listing-tooltip"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-0",
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: _vm.$t(
                                                        "common.search"
                                                      ),
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tablesearchfiles,
                                                      callback: function($$v) {
                                                        _vm.tablesearchfiles = $$v
                                                      },
                                                      expression:
                                                        "tablesearchfiles"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("fd-alert", {
                                                attrs: {
                                                  type: "info",
                                                  label: _vm.$t(
                                                    "attachments.file-is-photo-alert-message",
                                                    [
                                                      _vm.touchedFileName.toUpperCase()
                                                    ]
                                                  ),
                                                  dismissible: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.showAttachmentTabPhotoAlert,
                                                  callback: function($$v) {
                                                    _vm.showAttachmentTabPhotoAlert = $$v
                                                  },
                                                  expression:
                                                    "showAttachmentTabPhotoAlert"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-data-table", {
                                                directives: [
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                                    value: "",
                                                    expression:
                                                      "\n                          ''\n                        ",
                                                    arg: "icon",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-table-icon-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:[nonPhotosIconColumnArgument].no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                                    value: "",
                                                    expression:
                                                      "\n                          ''\n                        ",
                                                    arg:
                                                      _vm.nonPhotosIconColumnArgument,
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-table-icon-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName: "v-fd-column:name",
                                                    value: _vm.$t(
                                                      "common.name"
                                                    ),
                                                    expression:
                                                      "$t('common.name')",
                                                    arg: "name"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                    arg: "actions",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-actions-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                                    arg: "action",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "show-when-extra-small": true,
                                                      "class_fd-actions-cell-edit-only": true
                                                    }
                                                  }
                                                ],
                                                class: _vm.$vuetify.breakpoint
                                                  .xsOnly
                                                  ? "fd-action-table"
                                                  : "fd-actions-table",
                                                attrs: {
                                                  items:
                                                    _vm.nonPhotoAttachments,
                                                  search: _vm.tablesearchfiles,
                                                  loading: _vm.processing,
                                                  "loading-text": _vm.$t(
                                                    "common.table-loading-message"
                                                  ),
                                                  "sort-by": ["name"],
                                                  "item-class":
                                                    _vm.fileRowClassName,
                                                  "mobile-breakpoint": "0"
                                                },
                                                on: {
                                                  "dblclick:row":
                                                    _vm.attachmentRowClicked
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item.isoIcon",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      !!item.blobFile &&
                                                                      item
                                                                        .blobFile
                                                                        .isIsoFile
                                                                        ? _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {},
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$store.getters.icon(
                                                                                    "isos"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "job.existing.file-comes-from-iso"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.icon",
                                                    fn: function({ item }) {
                                                      return [
                                                        item.type == "link"
                                                          ? _c("v-icon", [
                                                              _vm._v("fa-link")
                                                            ])
                                                          : item.type ==
                                                              "file" &&
                                                            item.isPhoto
                                                          ? _c("v-icon", [
                                                              _vm._v(
                                                                "fa-camera"
                                                              )
                                                            ])
                                                          : _c("v-icon", [
                                                              _vm._v("fa-file")
                                                            ])
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.action",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.canOpenInNew ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openAttachment(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-open-in-new\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.open-in-new-tab"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.actions",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            class: [
                                                              {
                                                                "table-5-actions-column-min-width":
                                                                  _vm.$vuetify
                                                                    .breakpoint
                                                                    .mdAndUp,
                                                                "table-4-actions-column-min-width": !_vm
                                                                  .$vuetify
                                                                  .breakpoint
                                                                  .mdAndUp
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.canOpenInNew ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-open-in-new\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.open-in-new-tab"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.isPreviewable ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.viewPhoto(
                                                                                        item.file
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-eye\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.view"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing ||
                                                                                    (!!item.blobFile &&
                                                                                      item
                                                                                        .blobFile
                                                                                        .isIsoFile)
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-pencil\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.edit"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        !_vm
                                                                          .$vuetify
                                                                          .breakpoint
                                                                          .lgAndUp ||
                                                                        _vm.processing,
                                                                      top: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function({
                                                                            on
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      link:
                                                                                        "",
                                                                                      disabled:
                                                                                        (!item.file &&
                                                                                          !item.blobFile) ||
                                                                                        _vm.processing
                                                                                    }
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      attrs: {
                                                                                        small:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.downloadAttachment(
                                                                                            item
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                    mdi-cloud-download\n                                  "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "common.download"
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing ||
                                                                                    (!!item.blobFile &&
                                                                                      item
                                                                                        .blobFile
                                                                                        .isIsoFile)
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-delete\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.remove"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.optionsTab.key,
                              attrs: { value: `tab-${_vm.optionsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "optionsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-2" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "job.options.work-shift-days"
                                              ),
                                              disabled:
                                                _vm.detailsReadonly ||
                                                _vm.processing
                                            },
                                            model: {
                                              value: _vm.job.hasDayShift,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "hasDayShift",
                                                  $$v
                                                )
                                              },
                                              expression: "job.hasDayShift"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "job.options.work-shift-nights"
                                              ),
                                              disabled:
                                                _vm.detailsReadonly ||
                                                _vm.processing
                                            },
                                            model: {
                                              value: _vm.job.hasNightShift,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "hasNightShift",
                                                  $$v
                                                )
                                              },
                                              expression: "job.hasNightShift"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "job.options.electricity"
                                              ),
                                              disabled:
                                                _vm.detailsReadonly ||
                                                _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.job.hasEnergizedEquipment,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "hasEnergizedEquipment",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "job.hasEnergizedEquipment"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "job.options.confined-space"
                                              ),
                                              disabled:
                                                _vm.detailsReadonly ||
                                                _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.job
                                                  .requiresConfinedSpacePermit,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "requiresConfinedSpacePermit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "job.requiresConfinedSpacePermit"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "job.options.lockout"
                                              ),
                                              disabled:
                                                _vm.detailsReadonly ||
                                                _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.job
                                                  .requiresLockoutProcedure,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.job,
                                                  "requiresLockoutProcedure",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "job.requiresLockoutProcedure"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.estimatesTab.key,
                              attrs: { value: `tab-${_vm.estimatesTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "estimatesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "add-fab-button-primary",
                                                        attrs: {
                                                          fab: "",
                                                          elevation: "5",
                                                          depressed:
                                                            _vm.processing,
                                                          disabled:
                                                            _vm.processing,
                                                          color: "new"
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-plus")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-list",
                                            { attrs: { subheader: "" } },
                                            [
                                              !_vm.scaffoldTypes.length
                                                ? _c(
                                                    "v-list-item-group",
                                                    [
                                                      _c(
                                                        "v-subheader",
                                                        {
                                                          staticClass:
                                                            "pt-3 pb-0",
                                                          staticStyle: {
                                                            height:
                                                              "auto !important"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.no-scaffold-types-configured-message"
                                                                )
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.scaffoldTypes,
                                                function(scaffoldType) {
                                                  return _c(
                                                    "v-list-item-group",
                                                    { key: scaffoldType.value },
                                                    [
                                                      _c(
                                                        "v-subheader",
                                                        {
                                                          staticClass:
                                                            "pt-3 pb-0",
                                                          staticStyle: {
                                                            height:
                                                              "auto !important"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                scaffoldType.text
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-divider", {
                                                        staticClass: "my-1"
                                                      }),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        scaffoldType.subTypes,
                                                        function(subType) {
                                                          return _c(
                                                            "v-list-item",
                                                            {
                                                              key:
                                                                subType.value,
                                                              staticClass:
                                                                "py-1",
                                                              staticStyle: {
                                                                "min-height":
                                                                  "0px !important"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openNewEstimateDialog(
                                                                    scaffoldType.value,
                                                                    subType.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  subType.text
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider", {
                                        staticClass: "mx-4",
                                        attrs: { inset: "", vertical: "" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "ml-1 mr-3",
                                                        attrs: {
                                                          color: "black",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("info")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.estimates.table-listing-tooltip"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer")
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      attrs: { multiple: "", accordion: "" },
                                      model: {
                                        value: _vm.estimatePanel,
                                        callback: function($$v) {
                                          _vm.estimatePanel = $$v
                                        },
                                        expression: "estimatePanel"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          staticClass: "secondary-section-style"
                                        },
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.estimates.summary-panel.title"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-expansion-panel-content",
                                            {
                                              staticClass:
                                                "no-left-right-bottom-padding",
                                              staticStyle: {
                                                background: "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "justify-end ml-0 mr-0 fd-secondary-context-qualifier-background"
                                                },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      attrs: { row: "" },
                                                      model: {
                                                        value:
                                                          _vm.summaryPanelTimeUnitDivider,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.summaryPanelTimeUnitDivider = $$v
                                                        },
                                                        expression:
                                                          "summaryPanelTimeUnitDivider"
                                                      }
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          value: 1,
                                                          label: _vm.$t(
                                                            "common.minutes-short"
                                                          )
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          value: 60,
                                                          label: _vm.$t(
                                                            "common.hours"
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-container",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { fluid: "" }
                                                },
                                                [
                                                  _c(
                                                    "sp-estimate-summary-table",
                                                    {
                                                      attrs: {
                                                        "estimate-components":
                                                          _vm.workingComponents,
                                                        summaryPanelTimeUnitDivider:
                                                          _vm.summaryPanelTimeUnitDivider
                                                      },
                                                      on: {
                                                        edit: _vm.editItem,
                                                        delete: _vm.deleteItem,
                                                        undelete: _vm.reAddItem
                                                      }
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            outlined: "",
                                                            disabled:
                                                              _vm.processing ||
                                                              !_vm
                                                                .workingComponents
                                                                .length
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openWorkingTakeoffDialog
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.components-panel.takeoff"
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            disabled:
                                                              _vm.processing ||
                                                              !_vm.canGenerateSnapshot,
                                                            loading:
                                                              _vm.generatingSnapshot
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.generateSnapshot
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.components-panel.generate-snapshot-button-label"
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          staticClass: "secondary-section-style"
                                        },
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.estimates.estimates-panel.title"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-expansion-panel-content",
                                            {
                                              staticClass:
                                                "no-left-right-bottom-padding",
                                              staticStyle: {
                                                background: "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-1 mr-3",
                                                                        attrs: {
                                                                          color:
                                                                            "black",
                                                                          dark:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "info"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ])
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.table-listing-tooltip"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "pl-0",
                                                        attrs: {
                                                          "append-icon":
                                                            "search",
                                                          label: _vm.$t(
                                                            "common.search"
                                                          ),
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tablesearchestimates,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.tablesearchestimates = $$v
                                                          },
                                                          expression:
                                                            "tablesearchestimates"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { attrs: { align: "center" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("sp-estimates-list", {
                                                        attrs: {
                                                          estimates:
                                                            _vm.estimatesList,
                                                          loading:
                                                            _vm.processing,
                                                          tablesearchestimates:
                                                            _vm.tablesearchestimates,
                                                          summaryPanelTimeUnitDivider:
                                                            _vm.summaryPanelTimeUnitDivider
                                                        },
                                                        on: {
                                                          download:
                                                            _vm.downloadEstimateExcelFile
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.workOrdersTab.key,
                              attrs: { value: `tab-${_vm.workOrdersTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "workordersform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "", tile: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "job.existing.work-orders.table-listing-information"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrderTableSearch,
                                                  callback: function($$v) {
                                                    _vm.workOrderTableSearch = $$v
                                                  },
                                                  expression:
                                                    "workOrderTableSearch"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:internalNumber",
                                                value: _vm.$vuetify.breakpoint
                                                  .xs
                                                  ? _vm.$t(
                                                      "scaffolds.existing-scaffold.work-orders.number-label-short"
                                                    )
                                                  : _vm.$t(
                                                      "scaffolds.existing-scaffold.work-orders.number-label"
                                                    ),
                                                expression:
                                                  "\n                        $vuetify.breakpoint.xs\n                          ? $t('scaffolds.existing-scaffold.work-orders.number-label-short')\n                          : $t('scaffolds.existing-scaffold.work-orders.number-label')\n                      ",
                                                arg: "internalNumber"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:workOrderStatusName",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.status-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.status-label')\n                      ",
                                                arg: "workOrderStatusName"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:assignedContractorName.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.contractor-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.contractor-label')\n                      ",
                                                arg: "assignedContractorName",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:areaName.hide-when-medium",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.area-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.area-label')\n                      ",
                                                arg: "areaName",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:subAreaName.hide-when-medium",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.sub-area-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.sub-area-label')\n                      ",
                                                arg: "subAreaName",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:requestTypeName",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.type-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.type-label')\n                      ",
                                                arg: "requestTypeName"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:formattedRequestDate.hide-when-medium",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.requested-on-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.requested-on-label')\n                      ",
                                                arg: "formattedRequestDate",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:formattedCompletedDate.hide-when-small",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.completed-on-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.completed-on-label')\n                      ",
                                                arg: "formattedCompletedDate",
                                                modifiers: {
                                                  "hide-when-small": true
                                                }
                                              }
                                            ],
                                            attrs: {
                                              items: _vm.job.relatedWorkOrders,
                                              search: _vm.workOrderTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": [
                                                "formattedCompletedDate"
                                              ],
                                              "sort-desc": true,
                                              "mobile-breakpoint": "0",
                                              "show-expand": false
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.workOrderStatusName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value: _vm.$t(
                                                          `workorders.status.${item.workOrderStatus}`
                                                        )
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key:
                                                  "item.assignedContractorName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value:
                                                          item.assignedContractorName
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.areaName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value: item.areaName
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.subAreaName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value: item.subAreaName
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.requestTypeName",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("fd-value-display", {
                                                      attrs: {
                                                        value: _vm.$t(
                                                          `scaffold-requests.types.${item.scaffoldRequestType}`
                                                        )
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "expanded-item",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "fd-expanding-table-single-details-section",
                                                        attrs: {
                                                          colspan:
                                                            _vm.expanderColSpan
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "fd-work-order-details",
                                                          {
                                                            staticClass:
                                                              "fd-expanding-table-single-details-section",
                                                            attrs: {
                                                              workOrder: item
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.takeoffTab.key,
                              attrs: { value: `tab-${_vm.takeoffTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.partstablesearch,
                                              callback: function($$v) {
                                                _vm.partstablesearch = $$v
                                              },
                                              expression: "partstablesearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        attrs: {
                                          "data-cy": "isotakeoff",
                                          search: _vm.partstablesearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0",
                                          "items-per-page":
                                            _vm.partsItemsPerPage,
                                          "footer-props": {
                                            "items-per-page-options":
                                              _vm.partsItemsPerPageOptions
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.inlineMessage.message || _vm.detailsReadonly
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-0", attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: _vm.inlineMessage.type,
                                      label: _vm.inlineMessage.message,
                                      "label-style": "white-space: pre-line;"
                                    }
                                  })
                                : _vm.detailsReadonly && _vm.jobIsCancelled
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "job.existing.job-cannot-be-edited-cancelled"
                                      )
                                    }
                                  })
                                : _vm.detailsReadonly
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "job.existing.job-cannot-be-edited-released"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-2 mb-3 d-flex d-md-none justify-end align-center v-card__actions",
                      class: { "fd-actions-hidden": _vm.hideActionButtons }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "cancel-small",
                            text: "",
                            link: "",
                            loading: _vm.cancelling,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanCancelJob ||
                              !_vm.jobStatusCanBeSetToCancelled,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelJob }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("job.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.jobStatusCanBeSetToDraft
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-cy": "resume-small",
                                outlined: "",
                                color: "success",
                                loading: _vm.resuming,
                                disabled: _vm.processing,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.resumeJob }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("job.resume")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-cy": "hold-small",
                                outlined: "",
                                color: "warning",
                                loading: _vm.holding,
                                disabled:
                                  _vm.processing ||
                                  !_vm.jobStatusCanBeSetToOnHold,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.putJobOnHold }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("job.put-on-hold-short")) +
                                  "\n          "
                              )
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "ready-small",
                            outlined: "",
                            color: "success",
                            loading: _vm.readying,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanMarkAsReady ||
                              !_vm.jobStatusCanBeSetToReady,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.markJobAsReady }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("job.ready")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2 mt-sm-0",
                          attrs: {
                            "data-cy": "release-small",
                            outlined: "",
                            color: "primary",
                            loading: _vm.releasing,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanReleaseJob ||
                              !_vm.jobStatusCanBeSetToReleased,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.releaseJob }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("job.save-and-release-short")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    {
                      staticClass: "mx-2",
                      class: { "fd-actions-hidden": _vm.hideActionButtons }
                    },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            text: "",
                            link: "",
                            "data-cy": "cancel",
                            loading: _vm.cancelling,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanCancelJob ||
                              !_vm.jobStatusCanBeSetToCancelled,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelJob }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("job.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.jobStatusCanBeSetToDraft
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                "data-cy": "resume",
                                outlined: "",
                                color: "success",
                                loading: _vm.resuming,
                                disabled: _vm.processing,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.resumeJob }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("job.resume")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                "data-cy": "hold",
                                outlined: "",
                                color: "warning",
                                loading: _vm.holding,
                                disabled:
                                  _vm.processing ||
                                  !_vm.jobStatusCanBeSetToOnHold,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.putJobOnHold }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("job.put-on-hold")) +
                                  "\n          "
                              )
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "ready",
                            outlined: "",
                            color: "success",
                            loading: _vm.readying,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanMarkAsReady ||
                              !_vm.jobStatusCanBeSetToReady,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.markJobAsReady }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("job.ready")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "release",
                            outlined: "",
                            color: "primary",
                            loading: _vm.releasing,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanReleaseJob ||
                              !_vm.jobStatusCanBeSetToReleased,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.releaseJob }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("job.save-and-release")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            "data-cy": "close",
                            text: "",
                            link: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel-edits")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("fd-menu-save", {
                        attrs: {
                          disabled: !_vm.jobCanBeSaved || _vm.processing,
                          loading: _vm.saving,
                          small: _vm.$vuetify.breakpoint.xsOnly
                        },
                        on: {
                          "click:save": function($event) {
                            return _vm.save(false)
                          },
                          "click:save-and-close": function($event) {
                            return _vm.save(true)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }