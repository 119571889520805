import Vue from "vue";
import Router from "vue-router";

import store from "./store";
import { lastRetrievedLogin, checkLogin } from "./login";
// Login Services
import { loginService } from "./services";

const listOfBottomBarButtons = [
  "scaffoldrequests",
  "scaffoldrequestapprovals",
  "estimatedworkorders",
  "scheduler",
  "scaffolds",
  "inspections",
  "allworkorders",
  "todolist"
];

Vue.use(Router);

var router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "LEMs",
      path: "/lems",
      component: () => import("./views/LEMs.vue")
    },
    {
      name: "LemExisting",
      path: "/lems/:id",
      component: () => import("./views/LemExisting.vue")
    },
    {
      name: "Timesheets",
      path: "/foremantimesheets",
      component: () => import("./views/ForemanTimesheets.vue")
    },
    {
      name: "TimesheetExisting",
      path: "/foremantimesheets/:id",
      component: () => import("./views/ForemanTimesheetExisting.vue")
    },
    {
      name: "TimesheetApproval",
      path: "/timesheetapproval",
      component: () => import("./views/TimesheetApprovals.vue")
    },
    {
      name: "TimesheetApprovalExisting",
      path: "/timesheetapproval/:id",
      component: () => import("./views/TimesheetExisting.vue")
    },
    {
      name: "TimesheetCorrections",
      path: "/timesheetcorrections",
      component: () => import("./views/CorrectedTimesheets.vue")
    },
    {
      name: "TimesheetCorrectionExisting",
      path: "/timesheetcorrections/:id",
      component: () => import("./views/ForemanTimesheetExisting.vue")
    },
    {
      name: "TimesheetSummary",
      path: "/timesheetsummary",
      component: () => import("./views/ForemanTimesheetSummary.vue")
    },
    {
      name: "TimesheetWeekendingSummary",
      path: "/timesheetweekendingsummary",
      component: () => import("./views/ForemanTimesheetWeekendingSummary.vue")
    },
    {
      name: "DropoffLocations",
      path: "/dropofflocations",
      component: () => import("./views/DropoffLocations.vue")
    },
    {
      name: "DropoffLocationExisting",
      path: "/dropofflocations/:id",
      component: () => import("./views/DropoffLocationExisting.vue")
    },
    {
      name: "Yards",
      path: "/yards",
      component: () => import("./views/Yards.vue")
    },
    {
      name: "YardExisting",
      path: "/yards/:id",
      component: () => import("./views/YardExisting.vue")
    },
    {
      name: "Configuration",
      path: "/configuration",
      component: () => import("./views/SiteConfiguration.vue")
    },
    {
      name: "CountSheets",
      path: "/countsheets",
      component: () => import("./views/CountSheets.vue")
    },
    {
      name: "CountSheetExisting",
      path: "/countsheets/:id",
      component: () => import("./views/CountSheetExisting.vue")
    },
    {
      name: "YardBalances",
      path: "/yardbalances",
      component: () => import("./views/PartsYardBalance.vue")
    },
    {
      name: "Transfers",
      path: "/transfers",
      component: () => import("./views/SP.Transfers.vue")
    },
    {
      name: "TransferExisting",
      path: "/transfers/:id",
      component: () => import("./views/SP.TransferExisting.vue")
    },
    {
      name: "MaterialOrders",
      path: "/materialorders",
      component: () => import("./views/SP.MaterialOrders.vue")
    },
    {
      name: "MaterialOrderExisting",
      path: "/materialorders/:id",
      component: () => import("./views/SP.MaterialOrderExisting.vue")
    },
    {
      name: "Suppliers",
      path: "/suppliers",
      component: () => import("./views/SP.Suppliers.vue")
    },
    {
      name: "SupplierExisting",
      path: "/suppliers/:id",
      component: () => import("./views/SP.SupplierExisting.vue")
    },
    {
      name: "Tags",
      path: "/tags",
      component: () => import("../../common/client/views/Tags.vue")
    },
    {
      name: "TagExisting",
      path: "/tags/:id",
      component: () => import("../../common/client/views/TagExisting.vue")
    },
    {
      name: "PartsCatalog",
      path: "/parts",
      component: () => import("./views/PartsCatalog.vue")
    },
    {
      name: "PartExisting",
      path: "/parts/:id",
      component: () => import("./views/PartExisting.vue")
    },
    {
      name: "WorkOrders",
      path: "/workorders",
      component: () => import("./views/WorkOrders.vue")
    },
    // {
    //   name: "WorkOrderExisting",
    //   path: "/workorders/:id",
    //   component: () => import("./views/WorkOrderExisting.vue")
    // },
    {
      name: "Projects",
      path: "/projects",
      component: () => import("./views/Projects.vue")
    },
    {
      name: "ProjectExisting",
      path: "/projects/:projectID",
      component: () => import("./views/ProjectExisting.vue")
    },
    {
      name: "Questions",
      path: "/questions",
      component: () => import("./views/SP.Questions.vue")
    },
    {
      name: "QuestionExisting",
      path: "/questions/:id",
      component: () => import("./views/SP.QuestionExisting.vue")
    },
    {
      name: "Systems",
      path: "/systems",
      component: () => import("./views/SP.Systems.vue")
    },
    {
      name: "SystemExisting",
      path: "/systems/:id",
      component: () => import("./views/SP.SystemExisting.vue")
    },
    {
      name: "SystemTestPackageExisting",
      path: "/system-testpackages/:id",
      component: () => import("./views/SP.TestPackageExisting.vue")
    },
    {
      name: "SystemTestPackageISOExisting",
      path: "/system-testpackage-isos/:id",
      component: () => import("./views/SP.ISOExisting.vue")
    },
    {
      name: "TestPackages",
      path: "/testpackages",
      component: () => import("./views/SP.TestPackages.vue")
    },
    {
      name: "TestPackageExisting",
      path: "/testpackages/:id",
      component: () => import("./views/SP.TestPackageExisting.vue")
    },
    {
      name: "TestPackageISOExisting",
      path: "/testpackage-isos/:id",
      component: () => import("./views/SP.ISOExisting.vue")
    },
    {
      name: "ISOs",
      path: "/isos",
      component: () => import("./views/SP.Isos.vue")
    },
    {
      name: "ISOExisting",
      path: "/isos/:id",
      component: () => import("./views/SP.ISOExisting.vue")
    },
    {
      name: "CountSheetGroups",
      path: "/countsheetgroups",
      component: () => import("./views/CountSheetGroups.vue")
    },
    {
      name: "CountSheetGroupExisting",
      path: "/countsheetgroups/:id",
      component: () => import("./views/CountSheetGroupExisting.vue")
    },
    {
      name: "Owners",
      path: "/owners",
      component: () => import("./views/Owners.vue")
    },
    {
      name: "OwnerExisting",
      path: "/owners/:id",
      component: () => import("./views/OwnerExisting.vue")
    },
    {
      name: "Contractors",
      path: "/contractors",
      component: () => import("./views/SP.Contractors.vue")
    },
    {
      name: "ContractorExisting",
      path: "/contractors/:id",
      component: () => import("./views/SP.ContractorExisting.vue")
    },
    {
      name: "NormGroupExisting",
      path: "/contractors/:contractorid/normgroups/:id",
      component: () => import("./views/SP.ContractorNormGroupExisting.vue")
    },
    {
      name: "NormExisting",
      path: "/contractors/:contractorid/normgroups/:normgroupid/norms/:id",
      component: () => import("./views/SP.ContractorNormExisting.vue")
    },
    {
      name: "PurchaseOrderExisting",
      path: "/contractors/:contractorid/purchaseorders/:id",
      component: () => import("./views/SP.ContractorPurchaseOrderExisting.vue")
    },
    {
      name: "CrewExisting",
      path: "/crews/:id",
      component: () => import("./views/CrewExisting.vue")
    },
    {
      name: "FleetExisting",
      path: "/contractors/:contractorid/fleets/:id",
      component: () => import("./views/SP.FleetExisting.vue")
    },
    {
      name: "Equipment",
      path: "/equipment",
      component: () => import("./views/SP.Equipment.vue")
    },
    {
      name: "EquipmentExisting",
      path: "/equipment/:id",
      component: () => import("./views/SP.EquipmentExisting.vue")
    },
    {
      name: "EquipmentClassifications",
      path: "/equipmentclassifications",
      component: () => import("./views/SP.EquipmentClassifications.vue")
    },
    {
      name: "EquipmentClassificationExisting",
      path: "/equipmentclassifications/:id",
      component: () => import("./views/SP.EquipmentClassificationExisting.vue")
    },
    {
      name: "EstimateSetup",
      path: "/estimatesetup",
      component: () => import("./views/EstimateSetup.vue")
    },
    {
      name: "ScaffoldTypeModifierExisting",
      path: "/estimatesetup/scaffoldtypemodifier/:id",
      component: () => import("./views/SP.ScaffoldTypeModifierExisting.vue")
    },
    {
      name: "ScaffoldDistanceModifierExisting",
      path: "/estimatesetup/scaffolddistancemodifier/:id",
      component: () => import("./views/SP.ScaffoldDistanceModifierExisting.vue")
    },
    {
      name: "ScaffoldHeightModifierExisting",
      path: "/estimatesetup/scaffoldheightmodifier/:id",
      component: () => import("./views/SP.ScaffoldHeightModifierExisting.vue")
    },
    {
      name: "ScaffoldElevationModifierExisting",
      path: "/estimatesetup/scaffoldelevationmodifier/:id",
      component: () => import("./views/SP.ScaffoldElevationModifierExisting.vue")
    },
    {
      name: "ScaffoldCongestionFactorExisting",
      path: "/estimatesetup/scaffoldcongestionfactor/:id",
      component: () => import("./views/SP.ScaffoldCongestionFactorExisting.vue")
    },
    {
      name: "BuildDismantleRatioExisting",
      path: "/estimatesetup/builddismantleratio/:id",
      component: () => import("./views/SP.BuildDismantleRatioExisting.vue")
    },
    {
      name: "HoardingModifierExisting",
      path: "/estimatesetup/hoardingmodifier/:id",
      component: () => import("./views/HoardingModifierExisting.vue")
    },
    {
      name: "InternalModifierExisting",
      path: "/estimatesetup/internalmodifier/:id",
      component: () => import("./views/InternalModifierExisting.vue")
    },
    {
      name: "ScaffoldBayLengthExisting",
      path: "/estimatesetup/scaffoldbaylength/:id",
      component: () => import("./views/SP.ScaffoldBayLengthExisting.vue")
    },
    {
      name: "ScaffoldBayWidthExisting",
      path: "/estimatesetup/scaffoldbaywidth/:id",
      component: () => import("./views/ScaffoldBayWidthExisting.vue")
    },
    {
      name: "ScaffoldBayHeightExisting",
      path: "/estimatesetup/scaffoldbayheight/:id",
      component: () => import("./views/SP.ScaffoldBayHeightExisting.vue")
    },
    {
      name: "BuildSheetSetup",
      path: "/buildsheetsetup",
      component: () => import("./views/SP.BuildSheetSetup.vue")
    },
    {
      name: "ApplicationTypeExisting",
      path: "/buildsheetsetup/applicationtypes/:id",
      component: () => import("./views/SP.ApplicationTypeExisting.vue")
    },
    {
      name: "PaintCodeExisting",
      path: "/buildsheetsetup/paintcodes/:id",
      component: () => import("./views/SP.PaintCodeExisting.vue")
    },
    {
      name: "SetUpTypeExisting",
      path: "/buildsheetsetup/setuptypes/:id",
      component: () => import("./views/SP.SetUpTypeExisting.vue")
    },
    {
      name: "TsaExisting",
      path: "/buildsheetsetup/tsas/:id",
      component: () => import("./views/SP.TsaExisting.vue")
    },
    {
      name: "WorkCategoryExisting",
      path: "/buildsheetsetup/workcategories/:id",
      component: () => import("./views/SP.WorkCategoryExisting.vue")
    },
    {
      name: "Disciplines",
      path: "/disciplines",
      component: () => import("./views/Disciplines.vue")
    },
    {
      name: "DisciplineExisting",
      path: "/disciplines/:id",
      component: () => import("./views/DisciplineExisting.vue")
    },
    {
      name: "Classifications",
      path: "/classifications",
      component: () => import("./views/Classifications.vue")
    },
    {
      name: "ClassificationExisting",
      path: "/classifications/:id",
      component: () => import("./views/ClassificationExisting.vue")
    },
    {
      name: "IWPs",
      path: "/iwps",
      component: () => import("./views/WorkPackages.vue")
    },
    {
      name: "IWPExisting",
      path: "/iwps/:id",
      component: () => import("./views/WorkPackageExisting.vue")
    },
    {
      name: "Users",
      path: "/people",
      component: () => import("./views/People.vue")
    },
    {
      name: "PersonExisting",
      path: "/people/:id",
      component: () => import("./views/PersonExisting.vue")
    },
    {
      name: "PersonSearchResult",
      path: "/person/:id",
      component: () => import("./views/PersonExisting.vue")
    },
    {
      name: "PersonalProfile",
      path: "/personalprofile/",
      component: () => import("./views/PersonExisting.vue")
    },
    {
      name: "Regions",
      path: "/regions",
      component: () => import("./views/Regions.vue")
    },
    {
      name: "RegionExisting",
      path: "/regions/:id",
      component: () => import("./views/RegionExisting.vue")
    },
    {
      name: "Modifiers",
      path: "/modifiers",
      component: () => import("./views/Modifiers.vue")
    },
    {
      name: "ModifierExisting",
      path: "/modifiers/:modifierID",
      component: () => import("./views/ModifierExisting.vue")
    },
    {
      name: "ModifierValueExisting",
      path: "/modifiervalues/:modifiervalueID",
      component: () => import("./views/ModifierValueExisting.vue")
    },
    {
      name: "Default",
      path: "/",
      component: () => import("./views/LandingPage.vue")
    },
    {
      name: "LandingPage",
      path: "/landingpage",
      component: () => import("./views/LandingPage.vue")
    },
    {
      name: "AreaExisting",
      path: "/areas/:areaID",
      component: () => import("./views/AreaExisting.vue")
    },
    {
      name: "SubAreaExisting",
      path: "/subareas/:subAreaID",
      component: () => import("./views/SubAreaExisting.vue")
    },
    {
      name: "ProjectCostCodeExisting",
      path: "/costcodes/:costCodeID",
      component: () => import("./views/ProjectCostCodeExisting.vue")
    },
    {
      name: "EmailAddressExisting",
      path: "/emailaddress/:emailAddressID",
      component: () => import("../../common/client/views/EmailAddressExisting.vue")
    },
    {
      name: "PhoneNumberExisting",
      path: "/phonenumber/:phoneNumberID",
      component: () => import("../../common/client/views/PhoneNumberExisting.vue")
    },
    {
      name: "MobileMore",
      path: "/mobilemore",
      component: () => import("./views/MobileMore.vue")
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("../../common/client/views/Login.vue")
    },
    {
      name: "Signup",
      path: "/signup/:id",
      component: () => import("./views/SP.Signup.vue")
    },
    {
      name: "SignupPersonal",
      path: "/signup",
      component: () => import("./views/SP.Signup.vue")
    },
    {
      name: "PinturaTest",
      path: "/pintura",
      component: () => import("./views/experimental/PinturaTest.vue")
    },
    {
      name: "DraggableTableTest",
      path: "/draggabletable",
      component: () => import("./views/experimental/DraggableTableTest.vue")
    },
    {
      name: "TimesheetSearchTest",
      path: "/timesheetsearchtest",
      component: () => import("./views/experimental/TimesheetSearchTest.vue")
    },
    {
      name: "LabourEntryTest",
      path: "/labourentrytest",
      component: () => import("./views/experimental/LabourEntryTest.vue")
    },
    {
      name: "Requests",
      path: "/scaffoldrequests",
      component: () => import("./views/SP.ScaffoldRequestList.vue")
    },
    {
      name: "RequestExisting",
      path: "/scaffoldrequests/:id",
      component: () => import("./views/SP.ScaffoldRequestExisting.vue")
    },
    {
      name: "RequestApprovals",
      path: "/scaffoldrequestapprovals",
      component: () => import("./views/ScaffoldRequestApprovals.vue")
    },
    {
      name: "RequestApprovalExisting",
      path: "/scaffoldrequestapprovals/:id",
      component: () => import("./views/SP.ScaffoldRequestExisting.vue")
    },
    {
      name: "ScaffoldDesigns",
      path: "/scaffolddesigns",
      component: () => import("./views/SP.ScaffoldDesigns.vue")
    },
    {
      name: "ScaffoldDesignExisting",
      path: "/scaffolddesigns/:id",
      component: () => import("./views/SP.ScaffoldDesignExisting.vue")
    },
    {
      name: "Scaffolds",
      path: "/scaffolds",
      component: () => import("./views/Scaffolds.vue")
    },
    {
      name: "ScaffoldExisting",
      path: "/scaffolds/:id",
      component: () => import("./views/ScaffoldsExisting.vue")
    },
    {
      name: "Inspections",
      path: "/inspections",
      component: () => import("./views/SP.ScaffoldInspections.vue")
    },
    {
      name: "InspectionExisting",
      path: "/inspections/:id",
      component: () => import("./views/SP.ScaffoldInspectionExisting.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobsList.vue
      name: "ScaffoldJobs",
      path: "/scaffold",
      component: () => import("./views/SP.JobsList.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobExisting.vue
      name: "ScaffoldJobExisting",
      path: "/scaffold/:id",
      component: () => import("./views/SP.JobExisting.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobsList.vue
      name: "MaintenanceJobs",
      path: "/maintenance",
      component: () => import("./views/SP.JobsList.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobExisting.vue
      name: "MaintenanceJobExisting",
      path: "/maintenance/:id",
      component: () => import("./views/SP.JobExisting.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobsList.vue
      name: "PaintJobs",
      path: "/paint",
      component: () => import("./views/SP.JobsList.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobExisting.vue
      name: "PaintJobExisting",
      path: "/paint/:id",
      component: () => import("./views/SP.JobExisting.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobsList.vue
      name: "InsulationJobs",
      path: "/insulation",
      component: () => import("./views/SP.JobsList.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobExisting.vue
      name: "InsulationJobExisting",
      path: "/insulation/:id",
      component: () => import("./views/SP.JobExisting.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobsList.vue
      name: "HeatTraceJobs",
      path: "/heattrace",
      component: () => import("./views/SP.JobsList.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobExisting.vue
      name: "HeatTraceJobExisting",
      path: "/heattrace/:id",
      component: () => import("./views/SP.JobExisting.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobsList.vue
      name: "RefractoryJobs",
      path: "/refractory",
      component: () => import("./views/SP.JobsList.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobExisting.vue
      name: "RefractoryJobExisting",
      path: "/refractory/:id",
      component: () => import("./views/SP.JobExisting.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobsList.vue
      name: "FireproofingJobs",
      path: "/fireproofing",
      component: () => import("./views/SP.JobsList.vue")
    },
    {
      // Name cannot be changed without also changing job type determination logic in SP.JobExisting.vue
      name: "FireproofingJobExisting",
      path: "/fireproofing/:id",
      component: () => import("./views/SP.JobExisting.vue")
    },
    {
      name: "EstimatedWorkOrders",
      path: "/estimatedworkorders",
      component: () => import("./views/WorkOrderEstimates.vue")
    },
    {
      name: "EstimatedWorkOrderExisting",
      path: "/estimatedworkorders/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "AllWorkOrders",
      path: "/allworkorders",
      component: () => import("./views/SP.AllWorkOrders.vue")
    },
    {
      name: "AllWorkOrdersExisting",
      path: "/allworkorders/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "Administration",
      path: "/administration",
      component: () => import("./views/WorkOrderAdministration.vue")
    },
    {
      name: "AdministrationExisting",
      path: "/administration/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "CountSheetAdministration",
      path: "/countsheetadministration",
      component: () => import("./views/WorkOrderAdministration.vue")
    },
    {
      name: "CountSheetAdministrationExisting",
      path: "/countsheetadministration/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "Scheduler",
      path: "/scheduler",
      component: () => import("./views/WorkOrderScheduler.vue")
    },
    {
      name: "SchedulerExisting",
      path: "/scheduler/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "ToDoList",
      path: "/todolist",
      component: () => import("./views/ToDoList.vue")
    },
    {
      name: "WorkOrderExisting",
      path: "/workorder/:id",
      component: () => import("./views/WorkOrderSchedulerExisting.vue")
    },
    {
      name: "WorkTypes",
      path: "/worktypes",
      component: () => import("./views/WorkTypes.vue")
    },
    {
      name: "WorkTypeExisting",
      path: "/worktypes/:id",
      component: () => import("./views/WorkTypeExisting.vue")
    },
    {
      name: "WorkSubTypeExisting",
      path: "/worktypes/:worktypeid/worksubtypes/:id",
      component: () => import("./views/SP.WorkSubTypeExisting.vue")
    },
    {
      name: "WorkSubSubTypeExisting",
      path: "/worktypes/:worktypeid/worksubtypes/:parentsubtypeid/worksubsubtypes/:id",
      component: () => import("./views/WorkSubSubTypeExisting.vue")
    }
  ]
});

router.beforeResolve(async function(to, from, next) {
  let targetRoute = to.fullPath;
  //First check to see if the call is attempting to exercise the "forgot password" workflow and DO NOT check the authentication
  //status of the user since they obviously cannot be signed in if they are attempting to reset their password.
  if (targetRoute == "/login" || targetRoute.includes("/signup")) {
    loginService.logout();
    // Just in case the logout call hasn't completed by the time other service calls are made
    // clear out the cookie locally to ensure it doesn't get refreshed
    document.cookie = "FDToken=";
    store.commit("NOTIFY_NAVIGATION_STARTED");
    next();
  } else if (targetRoute != "/login" && !(await checkLogin())) {
    // If we don't have a login prior to running, do that first
    store.commit("SET_LOGIN_RETURN_PATH", targetRoute);
    next("/login");
  } else {
    // if (targetRoute == "/consent") {
    //   store.commit("NOTIFY_NAVIGATION_STARTED");
    //   next();
    // } else

    // Set the bottom navigation bar as appropriate.
    if (listOfBottomBarButtons.includes(to.path)) {
      store.commit("SET_CUR_BOTTOMBARVALUE", to);
    } else {
      store.commit("SET_CUR_BOTTOMBARVALUE", undefined);
    }

    if (
      !!lastRetrievedLogin?.requiresNewTermsAndConditions ||
      !lastRetrievedLogin?.hasAcceptedTermsAndConditions
    ) {
      console.log(`requires terms and conditions acceptance`);
      store.commit("SET_LOGIN_RETURN_PATH", targetRoute);
      next("/login");
    } else {
      // We've confirmed the user is logged in and navigating to a path "inside" the application
      // As such, we can show the UI bars
      store.commit("SET_SHOW_APP_BAR", true);
      store.commit("SET_SHOW_DRAWER", true);
      store.commit("SET_SHOW_FOOTER", true);
      store.commit("SET_SHOW_BOTTOM_BAR", true);

      // Before committing to navigation, reset the breadcrumb state to nothing; when a new page comes
      // up that wants to use a breadcrumb it will announce itself, which will replace the breadcrumb
      // state
      store.commit("NOTIFY_NAVIGATION_STARTED");
      next();
    }
  }
});

export default router;
