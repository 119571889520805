var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", attrs: { "data-cy": "timesheetexisting" } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/timesheetapproval`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  !!_vm.timesheet.id &&
                                  _vm.$vuetify.breakpoint.xsOnly
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            {
                                              "ml-2":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            "py-1",
                                            "px-3",
                                            "fd-status-indicator"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.formattedDay) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !!_vm.timesheet.id
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            "py-1",
                                            "px-3",
                                            "fd-status-indicator",
                                            {
                                              "ml-1":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  `timesheets.status.${_vm.timesheet.timesheetStatusID}`
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "d-flex align-center justify-end ml-0 mr-0 fd-primary-context-qualifier-background"
                    },
                    [
                      _c("div", { staticClass: "pl-3" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("timesheets.existing.group-by-label")
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ml-4 mr-3 fd-button-context-separator"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _vm.$vuetify.breakpoint.mdAndDown
                        ? _c("v-select", {
                            staticClass: "py-2 pr-3",
                            staticStyle: { "max-width": "250px" },
                            attrs: {
                              outlined: "",
                              dense: _vm.$vuetify.breakpoint.xsOnly,
                              items: _vm.entryGroupingTypeOptions,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.selectedEntryGroupingType,
                              callback: function($$v) {
                                _vm.selectedEntryGroupingType = $$v
                              },
                              expression: "selectedEntryGroupingType"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$vuetify.breakpoint.lgAndUp
                        ? _c(
                            "v-radio-group",
                            {
                              staticClass: "fd-badge-radio-group",
                              attrs: { row: "" },
                              model: {
                                value: _vm.selectedEntryGroupingType,
                                callback: function($$v) {
                                  _vm.selectedEntryGroupingType = $$v
                                },
                                expression: "selectedEntryGroupingType"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: _vm.$t(
                                    "timesheets.existing.group-by-none-radio"
                                  ),
                                  value: "groupnone",
                                  "data-cy": "radionone"
                                }
                              }),
                              _vm._v(" "),
                              !_vm.timesheetIsEquipment
                                ? _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "timesheets.existing.group-by-person-radio"
                                      ),
                                      value: "groupperson",
                                      "data-cy": "radioperson"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.timesheetIsEquipment
                                ? _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "timesheets.existing.group-by-equipment-radio"
                                      ),
                                      value: "groupequipment",
                                      "data-cy": "radioequipment"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.timesheetIsEquipment
                                ? _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "timesheets.existing.group-by-work-order-radio"
                                      ),
                                      value: "groupworkorder",
                                      "data-cy": "radioworkorder"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.timesheetIsEquipment
                                ? _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "timesheets.existing.group-by-person-work-order-radio"
                                      ),
                                      value: "groupemployeeworkorder",
                                      "data-cy": "groupemployeeworkorder"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.timesheet.currentUserPermissions
                                .canViewCostCode
                                ? _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "timesheets.existing.group-by-cost-code-radio"
                                      ),
                                      value: "groupcostcode",
                                      "data-cy": "groupcostcode"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-3 py-0", attrs: { cols: "12" } },
                        [
                          _vm.timesheetIsDeclined
                            ? _c("fd-alert", {
                                attrs: {
                                  type: "warning",
                                  label: !!_vm.timesheetDeclineComments
                                    ? _vm.$t("timesheets.declined-with-reason")
                                    : _vm.$t("timesheets.declined"),
                                  comments: _vm.timesheetDeclineComments
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-title",
                    [
                      _vm.timesheetIsEquipment
                        ? _c("fd-inline-add-button", {
                            staticClass: "fd-inner-table-fab-button",
                            attrs: {
                              disabled:
                                _vm.isReadonly ||
                                !_vm.timesheet.currentUserPermissions
                                  .canAddNewEntries ||
                                _vm.processing
                            },
                            on: {
                              click: function($event) {
                                return _vm.openNewTimesheetEntryDialog()
                              }
                            }
                          })
                        : _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "add-fab-button-primary",
                                            attrs: {
                                              fab: "",
                                              elevation: "5",
                                              "data-cy": "addbutton",
                                              depressed:
                                                _vm.isReadonly ||
                                                !_vm.timesheet
                                                  .currentUserPermissions
                                                  .canAddNewEntries ||
                                                _vm.processing,
                                              disabled:
                                                _vm.isReadonly ||
                                                !_vm.timesheet
                                                  .currentUserPermissions
                                                  .canAddNewEntries ||
                                                _vm.processing,
                                              color: "new"
                                            }
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-plus")])],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item-group",
                                    { staticClass: "fd-drop-down-item-group" },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              on: {
                                                click:
                                                  _vm.openNewEmployeeTimesheetEntryDialog
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "timesheets.entries.employee-label"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              on: {
                                                click:
                                                  _vm.openNewEquipmentTimesheetEntryDialog
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "timesheets.entries.equipment-label"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "ml-1",
                                        attrs: { color: "black", dark: "" }
                                      },
                                      on
                                    ),
                                    [_vm._v("info")]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "timesheets.existing.header-additional-info"
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        staticClass: "pl-0",
                        attrs: {
                          "append-icon": "search",
                          label: "Search",
                          "single-line": "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.tablesearch,
                          callback: function($$v) {
                            _vm.tablesearch = $$v
                          },
                          expression: "tablesearch"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.timesheetHasEntriesMissingCostCode
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "12" } },
                            [
                              _c("fd-alert", {
                                attrs: {
                                  color: "error",
                                  icon: "fas fa-exclamation-circle",
                                  label: _vm.$t(
                                    "timesheets.existing.has-entries-missing-cost-code-label"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.timesheetIsEquipment
                    ? _c("v-data-table", {
                        directives: [
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:equipmentName",
                            value: _vm.$t(
                              "timesheets.existing.equipment-column-label"
                            ),
                            expression:
                              "$t('timesheets.existing.equipment-column-label')",
                            arg: "equipmentName"
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:equipmentSerialNumber",
                            value: _vm.$t(
                              "timesheets.existing.equipment-serial-number-column-label"
                            ),
                            expression:
                              "\n            $t('timesheets.existing.equipment-serial-number-column-label')\n          ",
                            arg: "equipmentSerialNumber"
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:classificationName.hide-when-header-text-empty",
                            value: _vm.showClassificationNameColumn
                              ? _vm.$t(
                                  "timesheets.existing.classification-column-label"
                                )
                              : "",
                            expression:
                              "\n            showClassificationNameColumn\n              ? $t('timesheets.existing.classification-column-label')\n              : ''\n          ",
                            arg: "classificationName",
                            modifiers: { "hide-when-header-text-empty": true }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:costCodeName.hide-when-header-text-empty",
                            value: _vm.showCostCodeNameColumn
                              ? _vm.$t(
                                  "timesheets.existing.cost-code-column-label"
                                )
                              : "",
                            expression:
                              "\n            showCostCodeNameColumn ? $t('timesheets.existing.cost-code-column-label') : ''\n          ",
                            arg: "costCodeName",
                            modifiers: { "hide-when-header-text-empty": true }
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:days",
                            value: _vm.isGroupedByEmployee
                              ? _vm.$t(
                                  "timesheets.existing.days-with-day-column-label"
                                )
                              : _vm.$t("timesheets.existing.days-column-label"),
                            expression:
                              "\n            isGroupedByEmployee\n              ? $t('timesheets.existing.days-with-day-column-label')\n              : $t('timesheets.existing.days-column-label')\n          ",
                            arg: "days"
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:quantity",
                            value: _vm.isGroupedByEmployee
                              ? _vm.$t(
                                  "timesheets.existing.quantity-with-day-column-label"
                                )
                              : _vm.$t(
                                  "timesheets.existing.quantity-column-label"
                                ),
                            expression:
                              "\n            isGroupedByEmployee\n              ? $t('timesheets.existing.quantity-with-day-column-label')\n              : $t('timesheets.existing.quantity-column-label')\n          ",
                            arg: "quantity"
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:[overrideColumnArgument].class_fd-override-action-cell.no-sort",
                            value: "",
                            expression: "''",
                            arg: _vm.overrideColumnArgument,
                            modifiers: {
                              "class_fd-override-action-cell": true,
                              "no-sort": true
                            }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:action.class_fd-action-cell.no-sort",
                            arg: "action",
                            modifiers: {
                              "class_fd-action-cell": true,
                              "no-sort": true
                            }
                          }
                        ],
                        ref: "datatable",
                        staticClass: "fd-timesheet-entries-table",
                        attrs: {
                          page: _vm.tablepage,
                          search: _vm.tablesearch,
                          loading: _vm.processing,
                          "loading-text": _vm.$t(
                            "common.table-loading-message"
                          ),
                          "mobile-breakpoint": "0",
                          "items-per-page": _vm.itemsPerPage,
                          "footer-props": {
                            "items-per-page-options": _vm.itemsPerPageOptions
                          },
                          items: _vm.timesheet.equipmentEntries,
                          "item-class": _vm.timesheetRowClassName,
                          "group-by": _vm.groupColumn,
                          "sort-by": ["equipmentName", "equipmentSerialNumber"],
                          "show-expand": ""
                        },
                        on: {
                          "update:page": function($event) {
                            _vm.tablepage = $event
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header.data-table-expand",
                              fn: function() {
                                return [
                                  _vm.isGrouped &&
                                  !!_vm.timesheet.entries &&
                                  _vm.timesheet.entries.length > 0
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                disabled:
                                                  !_vm.$vuetify.breakpoint
                                                    .lgAndUp || _vm.processing,
                                                left: ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({ on }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                color: "primary"
                                                              },
                                                              domProps: {
                                                                textContent: _vm._s(
                                                                  !_vm.allGroupsExpanded
                                                                    ? "mdi-plus-box-multiple-outline"
                                                                    : "mdi-minus-box-multiple-outline"
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.toggleGroups(
                                                                    _vm.allGroupsExpanded
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1986823439
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    !_vm.allGroupsExpanded
                                                      ? _vm.$t(
                                                          "common.expand-all"
                                                        )
                                                      : _vm.$t(
                                                          "common.collapse-all"
                                                        )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "expanded-item",
                              fn: function({ item }) {
                                return [
                                  _c("td", { attrs: { colspan: "99" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fd-details-section pt-3"
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mx-0",
                                            staticStyle: { width: "100%" }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "pl-0 pr-0",
                                                attrs: { cols: "12" }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass: "mx-0",
                                                    staticStyle: {
                                                      width: "100%"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheets.existing.employee-column-label"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          attrs: {
                                                            value:
                                                              item.resourceName
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheets.existing.classification-column-label"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          attrs: {
                                                            value:
                                                              item.classificationName
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheets.existing.work-type-column-label"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          attrs: {
                                                            value:
                                                              item.workTypeName
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheets.existing.work-sub-type-column-label"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          attrs: {
                                                            value:
                                                              item.workSubTypeName
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheets.existing.work-order-column-label"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          attrs: {
                                                            value:
                                                              item.workOrderNumber
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheets.existing.work-order-type-column-label"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          attrs: {
                                                            value: !item.workOrderType
                                                              ? undefined
                                                              : _vm.$t(
                                                                  `scaffold-requests.types.${item.workOrderType}`
                                                                )
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheets.existing.scaffold-column-label"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          attrs: {
                                                            value:
                                                              item.scaffoldNumber
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-col", {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheets.existing.area-column-label"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          attrs: {
                                                            value: item.areaName
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheets.existing.sub-area-column-label"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-value-display", {
                                                          attrs: {
                                                            value:
                                                              item.subAreaName
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.timesheet
                                                      .currentUserPermissions
                                                      .canViewCostCode
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "6",
                                                              sm: "3"
                                                            }
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.overridden
                                                                    ? _vm.$t(
                                                                        "timesheets.existing.cost-code-override-column-label"
                                                                      )
                                                                    : _vm.$t(
                                                                        "timesheets.existing.cost-code-column-label"
                                                                      )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.timesheet
                                                      .currentUserPermissions
                                                      .canViewCostCode
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "6",
                                                              sm: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "fd-value-display",
                                                              {
                                                                attrs: {
                                                                  value: item.overridden
                                                                    ? item.costCodeOverrideName
                                                                    : item.costCodeName
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "item.resourceName",
                              fn: function({ item }) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.resourceName) +
                                      "\n            "
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "fd-item-detail-light",
                                      attrs: { dense: "", "no-gutters": "" }
                                    },
                                    [_vm._v(_vm._s(item.resourceCode))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.costCodeName",
                              fn: function({ item }) {
                                return [
                                  (!item.overridden && !item.costCodeID) ||
                                  (!!item.overridden &&
                                    !item.costCodeIDOverride)
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { position: "relative" }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({ on }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "ml-1",
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                left: "-22px"
                                                              },
                                                              attrs: {
                                                                color: "error",
                                                                dark: "",
                                                                small: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                    fas fa-exclamation-circle\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheets.existing.entry-missing-cost-code-message"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.timesheetCanBeOverridden &&
                                  item.overridden
                                    ? _c("v-autocomplete", {
                                        staticClass:
                                          "fd-inline-table-overridden-select",
                                        attrs: {
                                          dense: "",
                                          items: _vm.selectableCostCodesForEquipmentEntry(
                                            item
                                          ),
                                          "item-text": "name",
                                          "item-value": "id",
                                          "no-data-text": _vm.$t(
                                            "timesheets.existing.no-selectable-cost-codes-for-equipment-message"
                                          ),
                                          hint: _vm.$t(
                                            "timesheets.existing.original-value",
                                            [item.costCodeName]
                                          ),
                                          "persistent-hint": ""
                                        },
                                        model: {
                                          value: item.costCodeIDOverride,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "costCodeIDOverride",
                                              $$v
                                            )
                                          },
                                          expression: "item.costCodeIDOverride"
                                        }
                                      })
                                    : !_vm.isReadonly &&
                                      _vm.timesheet.currentUserPermissions
                                        .canEditCostCode
                                    ? _c("v-autocomplete", {
                                        staticClass: "fd-inline-table-select",
                                        attrs: {
                                          dense: "",
                                          items: _vm.selectableCostCodesForEquipmentEntry(
                                            item
                                          ),
                                          "item-text": "name",
                                          "item-value": "id"
                                        },
                                        model: {
                                          value: item.costCodeID,
                                          callback: function($$v) {
                                            _vm.$set(item, "costCodeID", $$v)
                                          },
                                          expression: "item.costCodeID"
                                        }
                                      })
                                    : _c("fd-value-display", {
                                        attrs: {
                                          value: item.overridden
                                            ? item.costCodeOverrideName
                                            : item.costCodeName
                                        }
                                      })
                                ]
                              }
                            },
                            {
                              key: "item.days",
                              fn: function({ item }) {
                                return [
                                  _vm.canEditDaysForItem(item)
                                    ? _c("fd-text-field", {
                                        ref: _vm.getFieldRef("days", item),
                                        staticClass:
                                          "fd-time-entry-table-input",
                                        class: {
                                          "fd-show-details": item.overridden
                                        },
                                        attrs: {
                                          "data-id": item.id,
                                          "data-cy": "days",
                                          disabled: _vm.processing,
                                          type: "number",
                                          "hide-spin-buttons": "",
                                          outlined: false,
                                          "highlight-on-focus": "",
                                          hint: _vm.$t(
                                            "timesheets.existing.original-value",
                                            [item.originalDays]
                                          ),
                                          "persistent-hint": item.overridden
                                        },
                                        on: {
                                          keydown: [
                                            function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "up",
                                                  38,
                                                  $event.key,
                                                  ["Up", "ArrowUp"]
                                                )
                                              )
                                                return null
                                              return _vm.selectPreviousField(
                                                "days",
                                                item
                                              )
                                            },
                                            function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "down",
                                                  40,
                                                  $event.key,
                                                  ["Down", "ArrowDown"]
                                                )
                                              )
                                                return null
                                              return _vm.selectNextField(
                                                "days",
                                                item
                                              )
                                            },
                                            function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return (e =>
                                                _vm.enterPressed(
                                                  e,
                                                  "days",
                                                  item
                                                )).apply(null, arguments)
                                            }
                                          ]
                                        },
                                        model: {
                                          value: item.days,
                                          callback: function($$v) {
                                            _vm.$set(item, "days", _vm._n($$v))
                                          },
                                          expression: "item.days"
                                        }
                                      })
                                    : _c("fd-value-display", {
                                        attrs: {
                                          value: item.formattedDays,
                                          "no-value-key":
                                            "timesheets.list.no-time-value"
                                        }
                                      })
                                ]
                              }
                            },
                            {
                              key: "item.quantity",
                              fn: function({ item }) {
                                return [
                                  _vm.canEditQuantityForItem(item)
                                    ? _c("fd-text-field", {
                                        ref: _vm.getFieldRef("quantity", item),
                                        staticClass:
                                          "fd-time-entry-table-input",
                                        class: {
                                          "fd-show-details": item.overridden
                                        },
                                        attrs: {
                                          "data-id": item.id,
                                          "data-cy": "quantity",
                                          disabled: _vm.processing,
                                          type: "number",
                                          "hide-spin-buttons": "",
                                          outlined: false,
                                          "highlight-on-focus": "",
                                          hint: _vm.$t(
                                            "timesheets.existing.original-value",
                                            [item.originalQuantity]
                                          ),
                                          "persistent-hint": item.overridden
                                        },
                                        on: {
                                          keydown: [
                                            function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "up",
                                                  38,
                                                  $event.key,
                                                  ["Up", "ArrowUp"]
                                                )
                                              )
                                                return null
                                              return _vm.selectPreviousField(
                                                "quantity",
                                                item
                                              )
                                            },
                                            function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "down",
                                                  40,
                                                  $event.key,
                                                  ["Down", "ArrowDown"]
                                                )
                                              )
                                                return null
                                              return _vm.selectNextField(
                                                "quantity",
                                                item
                                              )
                                            },
                                            function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return (e =>
                                                _vm.enterPressed(
                                                  e,
                                                  "quantity",
                                                  item
                                                )).apply(null, arguments)
                                            }
                                          ]
                                        },
                                        model: {
                                          value: item.quantity,
                                          callback: function($$v) {
                                            _vm.$set(item, "quantity", $$v)
                                          },
                                          expression: "item.quantity"
                                        }
                                      })
                                    : _c("fd-value-display", {
                                        attrs: {
                                          value: item.formattedQuantity,
                                          "no-value-key":
                                            "timesheets.list.no-time-value"
                                        }
                                      })
                                ]
                              }
                            },
                            {
                              key: "body.append",
                              fn: function({ items }) {
                                return [
                                  _c(
                                    "tr",
                                    { staticClass: "fd-table-summary-row" },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-start",
                                          attrs: {
                                            colspan: _vm.preTimeNumColsEquipment
                                          }
                                        },
                                        [
                                          _c("fd-alert", {
                                            staticClass: "pa-0 mt-0",
                                            attrs: {
                                              dense: true,
                                              label: _vm.$t(
                                                "timesheets.existing.grand-total-label"
                                              ),
                                              type: "hint",
                                              white: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { fluid: "" }
                                            },
                                            [
                                              _c("fd-value-display", {
                                                staticStyle: {
                                                  display: "inline"
                                                },
                                                attrs: {
                                                  value: _vm.sum(items, "days"),
                                                  "no-value-key":
                                                    "timesheets.list.no-time-value",
                                                  "no-value-class":
                                                    "white--text"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { fluid: "" }
                                            },
                                            [
                                              _c("fd-value-display", {
                                                staticStyle: {
                                                  display: "inline"
                                                },
                                                attrs: {
                                                  value: _vm.sum(
                                                    items,
                                                    "quantity"
                                                  ),
                                                  "no-value-key":
                                                    "timesheets.list.no-time-value",
                                                  "no-value-class":
                                                    "white--text"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            colspan: _vm.postTimeNumCols
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                 \n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "group.header",
                              fn: function({ group, items, isOpen, toggle }) {
                                return [
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        colspan: isOpen
                                          ? _vm.numColsEquipment
                                          : _vm.preTimeNumColsEquipment
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          ref: `grouptoggle${group}`,
                                          on: { click: toggle }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              isOpen ? "mdi-minus" : "mdi-plus"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ml-1" }, [
                                        _vm._v(_vm._s(_vm.labelForGroup(group)))
                                      ]),
                                      _vm._v(" "),
                                      _vm.anyItemsMissingCostCode(items) &&
                                      !isOpen
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function({ on }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "ml-1",
                                                                  attrs: {
                                                                    color:
                                                                      "error",
                                                                    dark: "",
                                                                    small: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                      fas fa-exclamation-circle\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.existing.entries-missing-cost-code-message"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !isOpen
                                    ? _c(
                                        "td",
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { fluid: "" }
                                            },
                                            [
                                              _c("fd-value-display", {
                                                staticStyle: {
                                                  display: "inline"
                                                },
                                                attrs: {
                                                  value: _vm.sum(items, "days"),
                                                  "no-value-key":
                                                    "timesheets.list.no-time-value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !isOpen
                                    ? _c(
                                        "td",
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { fluid: "" }
                                            },
                                            [
                                              _c("fd-value-display", {
                                                staticStyle: {
                                                  display: "inline"
                                                },
                                                attrs: {
                                                  value: _vm.sum(
                                                    items,
                                                    "quantity"
                                                  ),
                                                  "no-value-key":
                                                    "timesheets.list.no-time-value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !isOpen
                                    ? _c(
                                        "td",
                                        {
                                          attrs: {
                                            colspan: _vm.postTimeNumCols
                                          }
                                        },
                                        [_vm._v(" ")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "group.summary",
                              fn: function({ group, items, isOpen }) {
                                return [
                                  isOpen
                                    ? _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-end fd-table-group-footer-cell",
                                          attrs: { colspan: "2" }
                                        },
                                        [
                                          _c("fd-alert", {
                                            staticClass:
                                              "pa-0 mt-0 fd-timesheet-entry-group-summary",
                                            attrs: {
                                              dense: true,
                                              label: _vm.$t(
                                                "timesheets.existing.group-totals-label"
                                              ),
                                              type: "hint"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  isOpen
                                    ? _c("td", {
                                        staticClass:
                                          "fd-table-group-footer-cell",
                                        attrs: {
                                          colspan:
                                            _vm.preTimeNumColsEquipment - 2
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  isOpen
                                    ? _c(
                                        "td",
                                        {
                                          staticClass:
                                            "fd-table-group-footer-cell"
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { fluid: "" }
                                            },
                                            [
                                              _c("fd-value-display", {
                                                staticStyle: {
                                                  display: "inline"
                                                },
                                                attrs: {
                                                  value: _vm.sum(items, "days"),
                                                  "no-value-key":
                                                    "timesheets.list.no-time-value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  isOpen
                                    ? _c(
                                        "td",
                                        {
                                          staticClass:
                                            "fd-table-group-footer-cell"
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { fluid: "" }
                                            },
                                            [
                                              _c("fd-value-display", {
                                                staticStyle: {
                                                  display: "inline"
                                                },
                                                attrs: {
                                                  value: _vm.sum(
                                                    items,
                                                    "quantity"
                                                  ),
                                                  "no-value-key":
                                                    "timesheets.list.no-time-value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  isOpen
                                    ? _c(
                                        "td",
                                        {
                                          staticClass:
                                            "fd-table-group-footer-cell",
                                          attrs: {
                                            colspan: _vm.postTimeNumCols
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n               \n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item.override",
                              fn: function({ item }) {
                                return [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.processing ||
                                                  !_vm.timesheetCanBeOverridden ||
                                                  item.overridden,
                                                text: "",
                                                "x-small": ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.overrideEquipmentEntryValues(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "timesheets.existing.override"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          item.overridden
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.processing ||
                                                      !_vm.timesheetCanBeOverridden ||
                                                      !item.overridden,
                                                    text: "",
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.cancelOverrideEquipmentEntryValues(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "timesheets.existing.cancel-override"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.action",
                              fn: function({ item }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "table-1-action-column-min-width"
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.$vuetify.breakpoint
                                                .lgAndUp || _vm.processing,
                                            left: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function({ on }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              _vm.processing ||
                                                              _vm.isReadonly ||
                                                              !_vm.timesheet
                                                                .currentUserPermissions
                                                                .canRemoveExistingEntries
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.removeExistingEquipmentEntry(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      mdi-delete\n                    "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("common.remove"))
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3304567325
                        )
                      })
                    : !!_vm.timesheet.id && !_vm.timesheetIsEquipment
                    ? _c("v-data-table", {
                        directives: [
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:employeeWorkOrder.hidden",
                            value: "",
                            expression: "''",
                            arg: "employeeWorkOrder",
                            modifiers: { hidden: true }
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:scaffoldNumber.hidden",
                            value: _vm.$t(
                              "timesheets.existing.scaffold-column-label"
                            ),
                            expression:
                              "$t('timesheets.existing.scaffold-column-label')",
                            arg: "scaffoldNumber",
                            modifiers: { hidden: true }
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:subAreaName.hidden",
                            value: _vm.$t(
                              "timesheets.existing.sub-area-column-label"
                            ),
                            expression:
                              "$t('timesheets.existing.sub-area-column-label')",
                            arg: "subAreaName",
                            modifiers: { hidden: true }
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:resourceNameCode",
                            value: _vm.$t(
                              "timesheets.existing.employee-column-label"
                            ),
                            expression:
                              "$t('timesheets.existing.employee-column-label')",
                            arg: "resourceNameCode"
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:resourceCode.hidden",
                            value: "",
                            expression: "''",
                            arg: "resourceCode",
                            modifiers: { hidden: true }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:classificationName.hide-when-small",
                            value: _vm.$t(
                              "timesheets.existing.classification-column-label"
                            ),
                            expression:
                              "\n            $t('timesheets.existing.classification-column-label')\n          ",
                            arg: "classificationName",
                            modifiers: { "hide-when-small": true }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:workTypeName.hide-when-medium",
                            value: _vm.$t(
                              "timesheets.existing.work-type-column-label"
                            ),
                            expression:
                              "\n            $t('timesheets.existing.work-type-column-label')\n          ",
                            arg: "workTypeName",
                            modifiers: { "hide-when-medium": true }
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:workSubTypeName",
                            value: _vm.$t(
                              "timesheets.existing.work-sub-type-column-label"
                            ),
                            expression:
                              "$t('timesheets.existing.work-sub-type-column-label')",
                            arg: "workSubTypeName"
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:costCodeName.hide-when-header-text-empty",
                            value: _vm.timesheet.currentUserPermissions
                              .canViewCostCode
                              ? _vm.$t(
                                  "timesheets.existing.cost-code-column-label"
                                )
                              : "",
                            expression:
                              "\n            timesheet.currentUserPermissions.canViewCostCode\n              ? $t('timesheets.existing.cost-code-column-label')\n              : ''\n          ",
                            arg: "costCodeName",
                            modifiers: { "hide-when-header-text-empty": true }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:workOrderNumber.hide-when-header-text-empty",
                            value: _vm.$t(
                              "timesheets.existing.work-order-column-label"
                            ),
                            expression:
                              "\n            $t('timesheets.existing.work-order-column-label')\n          ",
                            arg: "workOrderNumber",
                            modifiers: { "hide-when-header-text-empty": true }
                          },
                          {
                            name: "fd-column",
                            rawName: "v-fd-column:areaName.hide-when-medium",
                            value: _vm.$t(
                              "timesheets.existing.area-column-label"
                            ),
                            expression:
                              "$t('timesheets.existing.area-column-label')",
                            arg: "areaName",
                            modifiers: { "hide-when-medium": true }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:regularTime.hide-when-header-text-empty",
                            value: _vm.isGroupedByEmployee
                              ? _vm.$t(
                                  "timesheets.existing.regular-time-with-day-column-label"
                                )
                              : _vm.$t(
                                  "timesheets.existing.regular-time-column-label"
                                ),
                            expression:
                              "\n            isGroupedByEmployee\n              ? $t('timesheets.existing.regular-time-with-day-column-label')\n              : $t('timesheets.existing.regular-time-column-label')\n          ",
                            arg: "regularTime",
                            modifiers: { "hide-when-header-text-empty": true }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:overTime.hide-when-header-text-empty",
                            value: _vm.isGroupedByEmployee
                              ? _vm.$t(
                                  "timesheets.existing.over-time-with-day-column-label"
                                )
                              : _vm.$t(
                                  "timesheets.existing.over-time-column-label"
                                ),
                            expression:
                              "\n            isGroupedByEmployee\n              ? $t('timesheets.existing.over-time-with-day-column-label')\n              : $t('timesheets.existing.over-time-column-label')\n          ",
                            arg: "overTime",
                            modifiers: { "hide-when-header-text-empty": true }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:doubleTime.hide-when-header-text-empty",
                            value: _vm.isGroupedByEmployee
                              ? _vm.$t(
                                  "timesheets.existing.double-time-with-day-column-label"
                                )
                              : _vm.$t(
                                  "timesheets.existing.double-time-column-label"
                                ),
                            expression:
                              "\n            isGroupedByEmployee\n              ? $t('timesheets.existing.double-time-with-day-column-label')\n              : $t('timesheets.existing.double-time-column-label')\n          ",
                            arg: "doubleTime",
                            modifiers: { "hide-when-header-text-empty": true }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:units.hide-when-header-text-empty",
                            value: _vm.isGroupedByEmployee
                              ? _vm.$t(
                                  "timesheets.existing.units-with-day-column-label"
                                )
                              : _vm.$t(
                                  "timesheets.existing.units-column-label"
                                ),
                            expression:
                              "\n            isGroupedByEmployee\n              ? $t('timesheets.existing.units-with-day-column-label')\n              : $t('timesheets.existing.units-column-label')\n          ",
                            arg: "units",
                            modifiers: { "hide-when-header-text-empty": true }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:[overrideColumnArgument].class_fd-override-action-cell.no-sort",
                            value: "",
                            expression: "''",
                            arg: _vm.overrideColumnArgument,
                            modifiers: {
                              "class_fd-override-action-cell": true,
                              "no-sort": true
                            }
                          },
                          {
                            name: "fd-column",
                            rawName:
                              "v-fd-column:action.class_fd-action-cell.no-sort",
                            arg: "action",
                            modifiers: {
                              "class_fd-action-cell": true,
                              "no-sort": true
                            }
                          }
                        ],
                        ref: "datatable",
                        staticClass: "fd-timesheet-entries-table",
                        attrs: {
                          page: _vm.tablepage,
                          search: _vm.tablesearch,
                          loading: _vm.processing,
                          "loading-text": _vm.$t(
                            "common.table-loading-message"
                          ),
                          "mobile-breakpoint": "0",
                          "items-per-page": _vm.itemsPerPage,
                          "footer-props": {
                            "items-per-page-options": _vm.itemsPerPageOptions
                          },
                          items: _vm.allTimesheetEntries,
                          "item-class": _vm.timesheetRowClassName,
                          "group-by": _vm.groupColumn,
                          "sort-by": "resourceNameCode",
                          "show-expand": ""
                        },
                        on: {
                          "update:page": function($event) {
                            _vm.tablepage = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header.data-table-expand",
                            fn: function() {
                              return [
                                _vm.isGrouped &&
                                !!_vm.timesheet.entries &&
                                _vm.timesheet.entries.length > 0
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              disabled:
                                                !_vm.$vuetify.breakpoint
                                                  .lgAndUp || _vm.processing,
                                              left: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              color: "primary"
                                                            },
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                !_vm.allGroupsExpanded
                                                                  ? "mdi-plus-box-multiple-outline"
                                                                  : "mdi-minus-box-multiple-outline"
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.toggleGroups(
                                                                  _vm.allGroupsExpanded
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        )
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1986823439
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  !_vm.allGroupsExpanded
                                                    ? _vm.$t(
                                                        "common.expand-all"
                                                      )
                                                    : _vm.$t(
                                                        "common.collapse-all"
                                                      )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "expanded-item",
                            fn: function({ item }) {
                              return [
                                _c("td", { attrs: { colspan: "99" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "fd-details-section pt-3" },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-0",
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pl-0 pr-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mx-0",
                                                  staticStyle: { width: "100%" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.employee-column-label"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.resourceName
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.classification-column-label"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.classificationName
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.work-type-column-label"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.workTypeName
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.work-sub-type-column-label"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.workSubTypeName
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.work-order-column-label"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.workOrderNumber
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.work-order-type-column-label"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-value-display", {
                                                        attrs: {
                                                          value: !item.workOrderType
                                                            ? undefined
                                                            : _vm.$t(
                                                                `scaffold-requests.types.${item.workOrderType}`
                                                              )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.scaffold-column-label"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.scaffoldNumber
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-col", {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.area-column-label"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-value-display", {
                                                        attrs: {
                                                          value: item.areaName
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheets.existing.sub-area-column-label"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            item.subAreaName
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.timesheet
                                                    .currentUserPermissions
                                                    .canViewCostCode
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "3"
                                                          }
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.overridden
                                                                  ? _vm.$t(
                                                                      "timesheets.existing.cost-code-override-column-label"
                                                                    )
                                                                  : _vm.$t(
                                                                      "timesheets.existing.cost-code-column-label"
                                                                    )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.timesheet
                                                    .currentUserPermissions
                                                    .canViewCostCode
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "3"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "fd-value-display",
                                                            {
                                                              attrs: {
                                                                value: item.overridden
                                                                  ? item.costCodeOverrideName
                                                                  : item.costCodeName
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "item.workOrderNumber",
                            fn: function({ item }) {
                              return [
                                _c("fd-value-display", {
                                  attrs: {
                                    value: item.workOrderNumber,
                                    "no-value-text": _vm.workOrderPlaceholderTextForEntry(
                                      item
                                    )
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "item.resourceNameCode",
                            fn: function({ item }) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.resourceName) +
                                    "\n            "
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "fd-item-detail-light",
                                    attrs: { dense: "", "no-gutters": "" }
                                  },
                                  [_vm._v(_vm._s(item.resourceCode))]
                                )
                              ]
                            }
                          },
                          {
                            key: "item.workTypeName",
                            fn: function({ item }) {
                              return [
                                !!item.equipmentID
                                  ? _c("fd-value-display")
                                  : !_vm.isReadonly &&
                                    _vm.timesheet.currentUserPermissions
                                      .canEditWorkType
                                  ? _c("v-select", {
                                      attrs: {
                                        value: item.workTypeID,
                                        items: _vm.selectableWorkTypes(item),
                                        "item-text": "name",
                                        "item-value": "id",
                                        rules: [_vm.rules.required]
                                      },
                                      on: {
                                        input: v =>
                                          _vm.workTypeChangedForItem(item, v)
                                      }
                                    })
                                  : _c("fd-value-display", {
                                      attrs: { value: item.workTypeName }
                                    })
                              ]
                            }
                          },
                          {
                            key: "item.workSubTypeName",
                            fn: function({ item }) {
                              return [
                                !!item.equipmentID
                                  ? _c("fd-value-display")
                                  : !_vm.isReadonly &&
                                    _vm.timesheet.currentUserPermissions
                                      .canEditWorkType
                                  ? _c("v-select", {
                                      attrs: {
                                        value: item.workSubTypeID,
                                        items: _vm.selectableWorkSubTypes(item),
                                        "item-text": "name",
                                        "item-value": "id",
                                        rules: [_vm.rules.required]
                                      },
                                      on: {
                                        input: v =>
                                          _vm.workSubTypeChangedForItem(item, v)
                                      }
                                    })
                                  : _c("fd-value-display", {
                                      attrs: { value: item.workSubTypeName }
                                    })
                              ]
                            }
                          },
                          {
                            key: "item.costCodeName",
                            fn: function({ item }) {
                              return [
                                (!item.overridden && !item.costCodeID) ||
                                (!!item.overridden && !item.costCodeIDOverride)
                                  ? _c(
                                      "div",
                                      { staticStyle: { position: "relative" } },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            staticStyle: {
                                                              position:
                                                                "absolute",
                                                              left: "-22px"
                                                            },
                                                            attrs: {
                                                              color: "error",
                                                              dark: "",
                                                              small: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                    fas fa-exclamation-circle\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "timesheets.existing.entry-missing-cost-code-message"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.timesheetCanBeOverridden && item.overridden
                                  ? _c("v-autocomplete", {
                                      staticClass:
                                        "fd-inline-table-overridden-select",
                                      attrs: {
                                        dense: "",
                                        items: _vm.selectableCostCodesForEntry(
                                          item
                                        ),
                                        "item-text": "name",
                                        "item-value": "id",
                                        "no-data-text": _vm.$t(
                                          "timesheets.existing.no-selectable-cost-codes-for-work-sub-type-message"
                                        ),
                                        hint: _vm.$t(
                                          "timesheets.existing.original-value",
                                          [
                                            _vm.nameForCostCodeID(
                                              item.costCodeID
                                            )
                                          ]
                                        ),
                                        "persistent-hint": ""
                                      },
                                      model: {
                                        value: item.costCodeIDOverride,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "costCodeIDOverride",
                                            $$v
                                          )
                                        },
                                        expression: "item.costCodeIDOverride"
                                      }
                                    })
                                  : !_vm.isReadonly &&
                                    _vm.timesheet.currentUserPermissions
                                      .canEditCostCode
                                  ? _c("v-autocomplete", {
                                      staticClass: "fd-inline-table-select",
                                      attrs: {
                                        dense: "",
                                        items: _vm.selectableCostCodesForEntry(
                                          item
                                        ),
                                        "item-text": "name",
                                        "item-value": "id"
                                      },
                                      model: {
                                        value: item.costCodeID,
                                        callback: function($$v) {
                                          _vm.$set(item, "costCodeID", $$v)
                                        },
                                        expression: "item.costCodeID"
                                      }
                                    })
                                  : _c("fd-value-display", {
                                      attrs: {
                                        value: item.overridden
                                          ? item.costCodeOverrideName
                                          : item.costCodeName
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "item.areaName",
                            fn: function({ item }) {
                              return [
                                _c("fd-value-display", {
                                  attrs: { value: item.areaName }
                                })
                              ]
                            }
                          },
                          {
                            key: "item.regularTime",
                            fn: function({ item }) {
                              return [
                                _vm.canEditRegularTimeHoursForItem(item)
                                  ? _c("fd-text-field", {
                                      ref: _vm.getFieldRef("regularTime", item),
                                      staticClass: "fd-time-entry-table-input",
                                      class: {
                                        "fd-show-details": item.overridden
                                      },
                                      attrs: {
                                        "data-id": item.id,
                                        "data-cy": "regularTime",
                                        disabled: _vm.processing,
                                        type: "number",
                                        "hide-spin-buttons": "",
                                        outlined: false,
                                        "highlight-on-focus": "",
                                        hint: _vm.$t(
                                          "timesheets.existing.original-value",
                                          [item.originalRegularTime]
                                        ),
                                        "persistent-hint": item.overridden
                                      },
                                      on: {
                                        input: v =>
                                          _vm.regularTimeChanged(item, v),
                                        keydown: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "up",
                                                38,
                                                $event.key,
                                                ["Up", "ArrowUp"]
                                              )
                                            )
                                              return null
                                            return _vm.selectPreviousField(
                                              "regularTime",
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            )
                                              return null
                                            return _vm.selectNextField(
                                              "regularTime",
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return (e =>
                                              _vm.enterPressed(
                                                e,
                                                "regularTime",
                                                item
                                              )).apply(null, arguments)
                                          }
                                        ]
                                      },
                                      model: {
                                        value: item.regularTime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "regularTime",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "item.regularTime"
                                      }
                                    })
                                  : _c("fd-value-display", {
                                      attrs: {
                                        value: item.formattedRegularTime,
                                        "no-value-key":
                                          "timesheets.list.no-time-value"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "item.overTime",
                            fn: function({ item }) {
                              return [
                                _vm.canEditOverTimeHoursForItem(item)
                                  ? _c("fd-text-field", {
                                      ref: _vm.getFieldRef("overTime", item),
                                      staticClass: "fd-time-entry-table-input",
                                      class: {
                                        "fd-show-details": item.overridden
                                      },
                                      attrs: {
                                        "data-id": item.id,
                                        "data-cy": "overTime",
                                        disabled: _vm.processing,
                                        type: "number",
                                        "hide-spin-buttons": "",
                                        outlined: false,
                                        "highlight-on-focus": "",
                                        hint: _vm.$t(
                                          "timesheets.existing.original-value",
                                          [item.originalOverTime]
                                        ),
                                        "persistent-hint": item.overridden
                                      },
                                      on: {
                                        input: v =>
                                          _vm.overTimeChanged(item, v),
                                        keydown: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "up",
                                                38,
                                                $event.key,
                                                ["Up", "ArrowUp"]
                                              )
                                            )
                                              return null
                                            return _vm.selectPreviousField(
                                              "overTime",
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            )
                                              return null
                                            return _vm.selectNextField(
                                              "overTime",
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return (e =>
                                              _vm.enterPressed(
                                                e,
                                                "overTime",
                                                item
                                              )).apply(null, arguments)
                                          }
                                        ]
                                      },
                                      model: {
                                        value: item.overTime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "overTime",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "item.overTime"
                                      }
                                    })
                                  : _c("fd-value-display", {
                                      attrs: {
                                        value: item.formattedOverTime,
                                        "no-value-key":
                                          "timesheets.list.no-time-value"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "item.doubleTime",
                            fn: function({ item }) {
                              return [
                                _vm.canEditDoubleTimeHoursForItem(item)
                                  ? _c("fd-text-field", {
                                      ref: _vm.getFieldRef("doubleTime", item),
                                      staticClass: "fd-time-entry-table-input",
                                      class: {
                                        "fd-show-details": item.overridden
                                      },
                                      attrs: {
                                        "data-id": item.id,
                                        "data-cy": "doubleTime",
                                        disabled: _vm.processing,
                                        type: "number",
                                        "hide-spin-buttons": "",
                                        outlined: false,
                                        "highlight-on-focus": "",
                                        hint: _vm.$t(
                                          "timesheets.existing.original-value",
                                          [item.originalDoubleTime]
                                        ),
                                        "persistent-hint": item.overridden
                                      },
                                      on: {
                                        input: v =>
                                          _vm.doubleTimeChanged(item, v),
                                        keydown: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "up",
                                                38,
                                                $event.key,
                                                ["Up", "ArrowUp"]
                                              )
                                            )
                                              return null
                                            return _vm.selectPreviousField(
                                              "doubleTime",
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            )
                                              return null
                                            return _vm.selectNextField(
                                              "doubleTime",
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return (e =>
                                              _vm.enterPressed(
                                                e,
                                                "doubleTime",
                                                item
                                              )).apply(null, arguments)
                                          }
                                        ]
                                      },
                                      model: {
                                        value: item.doubleTime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "doubleTime",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "item.doubleTime"
                                      }
                                    })
                                  : _c("fd-value-display", {
                                      attrs: {
                                        value: item.formattedDoubleTime,
                                        "no-value-key":
                                          "timesheets.list.no-time-value"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "item.units",
                            fn: function({ item }) {
                              return [
                                _vm.canEditUnitsForItem(item)
                                  ? _c("fd-text-field", {
                                      ref: _vm.getFieldRef("units", item),
                                      staticClass: "fd-time-entry-table-input",
                                      class: {
                                        "fd-show-details": item.overridden
                                      },
                                      attrs: {
                                        "data-id": item.id,
                                        "data-cy": "units",
                                        disabled: _vm.processing,
                                        type: "number",
                                        "hide-spin-buttons": "",
                                        outlined: false,
                                        "highlight-on-focus": "",
                                        hint: _vm.$t(
                                          "timesheets.existing.original-value",
                                          [item.originalUnits]
                                        ),
                                        "persistent-hint": item.overridden
                                      },
                                      on: {
                                        keydown: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "up",
                                                38,
                                                $event.key,
                                                ["Up", "ArrowUp"]
                                              )
                                            )
                                              return null
                                            return _vm.selectPreviousField(
                                              "units",
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            )
                                              return null
                                            return _vm.selectNextField(
                                              "units",
                                              item
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return (e =>
                                              _vm.enterPressed(
                                                e,
                                                "units",
                                                item
                                              )).apply(null, arguments)
                                          }
                                        ]
                                      },
                                      model: {
                                        value: item.units,
                                        callback: function($$v) {
                                          _vm.$set(item, "units", $$v)
                                        },
                                        expression: "item.units"
                                      }
                                    })
                                  : _c("fd-value-display", {
                                      attrs: {
                                        value: item.formattedUnits,
                                        "no-value-key":
                                          "timesheets.list.no-entries-value"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "body.append",
                            fn: function({ items }) {
                              return [
                                _c(
                                  "tr",
                                  { staticClass: "fd-table-summary-row" },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-start",
                                        attrs: {
                                          colspan: _vm.preTimeNumColsEmployee
                                        }
                                      },
                                      [
                                        _c("fd-alert", {
                                          staticClass: "pa-0 mt-0",
                                          attrs: {
                                            dense: true,
                                            label: _vm.$t(
                                              "timesheets.existing.grand-total-label"
                                            ),
                                            type: "hint",
                                            white: ""
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-container",
                                          { staticClass: "pa-0" },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "regularTime"
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value",
                                                "no-value-class": "white--text"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        [
                                                          _vm._v(
                                                            "\n                        ("
                                                          ),
                                                          _c(
                                                            "fd-value-display",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "bold !important",
                                                                display:
                                                                  "inline"
                                                              },
                                                              attrs: {
                                                                value: _vm.sum(
                                                                  _vm.timeSummaries,
                                                                  "totalRegularTime"
                                                                ),
                                                                "no-value-key":
                                                                  "timesheets.list.no-time-value",
                                                                "no-value-class":
                                                                  "white--text"
                                                              }
                                                            }
                                                          ),
                                                          _vm._v(
                                                            ")\n                      "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-container",
                                          { staticClass: "pa-0" },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "overTime"
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value",
                                                "no-value-class": "white--text"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                      ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.sum(
                                                              _vm.timeSummaries,
                                                              "totalOverTime"
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value",
                                                            "no-value-class":
                                                              "white--text"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                    "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-container",
                                          { staticClass: "pa-0" },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "doubleTime"
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value",
                                                "no-value-class": "white--text"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                      ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.sum(
                                                              _vm.timeSummaries,
                                                              "totalDoubleTime"
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value",
                                                            "no-value-class":
                                                              "white--text"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                    "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { fluid: "" }
                                          },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "units",
                                                  0
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-entries-value",
                                                "no-value-class": "white--text"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                      ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.sum(
                                                              _vm.timeSummaries,
                                                              "totalUnits",
                                                              0
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-entries-value",
                                                            "no-value-class":
                                                              "white--text"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                    "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        attrs: { colspan: _vm.postTimeNumCols }
                                      },
                                      [
                                        _vm._v(
                                          "\n                 \n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "group.header",
                            fn: function({ group, items, isOpen, toggle }) {
                              return [
                                _c(
                                  "td",
                                  {
                                    attrs: {
                                      colspan: isOpen
                                        ? _vm.numColsEmployee
                                        : _vm.preTimeNumColsEmployee
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        ref: `grouptoggle${group}`,
                                        on: { click: toggle }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            isOpen ? "mdi-minus" : "mdi-plus"
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml-1" }, [
                                      _vm._v(_vm._s(_vm.labelForGroup(group)))
                                    ]),
                                    _vm._v(" "),
                                    _vm.anyItemsMissingCostCode(items) &&
                                    !isOpen
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                  dark: "",
                                                                  small: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                      fas fa-exclamation-circle\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "timesheets.existing.entries-missing-cost-code-message"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                !isOpen
                                  ? _c(
                                      "td",
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { fluid: "" }
                                          },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "regularTime"
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                    ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.getTotalTimeForEmployee(
                                                              group,
                                                              "totalRegularTime"
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                  "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !isOpen
                                  ? _c(
                                      "td",
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { fluid: "" }
                                          },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "overTime"
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                    ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.getTotalTimeForEmployee(
                                                              group,
                                                              "totalOverTime"
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                  "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !isOpen
                                  ? _c(
                                      "td",
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { fluid: "" }
                                          },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "doubleTime"
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                    ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.getTotalTimeForEmployee(
                                                              group,
                                                              "totalDoubleTime"
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                  "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !isOpen
                                  ? _c(
                                      "td",
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { fluid: "" }
                                          },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "units",
                                                  0
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-entries-value"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                    ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.getTotalTimeForEmployee(
                                                              group,
                                                              "totalUnits",
                                                              0
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-entries-value"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                  "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !isOpen
                                  ? _c(
                                      "td",
                                      {
                                        attrs: { colspan: _vm.postTimeNumCols }
                                      },
                                      [_vm._v(" ")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "group.summary",
                            fn: function({ group, items, isOpen }) {
                              return [
                                isOpen
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "text-end fd-table-group-footer-cell",
                                        attrs: { colspan: "2" }
                                      },
                                      [
                                        _c("fd-alert", {
                                          staticClass:
                                            "pa-0 mt-0 fd-timesheet-entry-group-summary",
                                          attrs: {
                                            dense: true,
                                            label: _vm.$t(
                                              "timesheets.existing.group-totals-label"
                                            ),
                                            type: "hint"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                isOpen
                                  ? _c("td", {
                                      staticClass: "fd-table-group-footer-cell",
                                      attrs: {
                                        colspan: _vm.preTimeNumColsEmployee - 2
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                isOpen
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "fd-table-group-footer-cell"
                                      },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { fluid: "" }
                                          },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "regularTime"
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                    ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.getTotalTimeForEmployee(
                                                              group,
                                                              "totalRegularTime"
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                  "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                isOpen
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "fd-table-group-footer-cell"
                                      },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { fluid: "" }
                                          },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "overTime"
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                    ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.getTotalTimeForEmployee(
                                                              group,
                                                              "totalOverTime"
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                  "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                isOpen
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "fd-table-group-footer-cell"
                                      },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { fluid: "" }
                                          },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "doubleTime"
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-time-value"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                    ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.getTotalTimeForEmployee(
                                                              group,
                                                              "totalDoubleTime"
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-time-value"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                  "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                isOpen
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "fd-table-group-footer-cell"
                                      },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { fluid: "" }
                                          },
                                          [
                                            _c("fd-value-display", {
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                value: _vm.sum(
                                                  items,
                                                  "units",
                                                  0
                                                ),
                                                "no-value-key":
                                                  "timesheets.list.no-entries-value"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.isGroupedByEmployee
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _vm._v(
                                                          "\n                    ("
                                                        ),
                                                        _c("fd-value-display", {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold !important",
                                                            display: "inline"
                                                          },
                                                          attrs: {
                                                            value: _vm.getTotalTimeForEmployee(
                                                              group,
                                                              "totalUnits",
                                                              0
                                                            ),
                                                            "no-value-key":
                                                              "timesheets.list.no-entries-value"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          ")\n                  "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                isOpen
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "fd-table-group-footer-cell",
                                        attrs: { colspan: _vm.postTimeNumCols }
                                      },
                                      [
                                        _vm._v(
                                          "\n               \n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item.override",
                            fn: function({ item }) {
                              return [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.processing ||
                                                !_vm.timesheetCanBeOverridden ||
                                                item.overridden,
                                              text: "",
                                              "x-small": ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.overrideEmployeeEntryValues(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.existing.override"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.overridden
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.processing ||
                                                    !_vm.timesheetCanBeOverridden ||
                                                    !item.overridden,
                                                  text: "",
                                                  "x-small": ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.cancelOverrideEmployeeEntryValues(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheets.existing.cancel-override"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item.action",
                            fn: function({ item }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "table-1-action-column-min-width"
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          disabled:
                                            !_vm.$vuetify.breakpoint.lgAndUp ||
                                            _vm.processing,
                                          left: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          disabled:
                                                            _vm.processing ||
                                                            _vm.isReadonly ||
                                                            !_vm.timesheet
                                                              .currentUserPermissions
                                                              .canRemoveExistingEntries
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeExistingEntry(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      mdi-delete\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("common.remove"))
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-0", attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: _vm.inlineMessage.type,
                                      label: _vm.inlineMessage.message,
                                      "label-style": "white-space: pre-line;"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isReadonly && !_vm.timesheetIsSubmitted
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-0", attrs: { cols: "12" } },
                            [
                              _vm.timesheetIsApproved
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "timesheets.existing.cannot-be-edited-approved"
                                      )
                                    }
                                  })
                                : _vm.timesheetIsCancelled
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "timesheets.existing.cannot-be-edited-cancelled"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-3 mb-3 d-sm-none justify-end v-card__actions"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "approve-extra-small",
                            outlined: "",
                            color: "success",
                            loading: _vm.approving,
                            disabled:
                              _vm.processing || !_vm.timesheetCanBeApproved,
                            small: ""
                          },
                          on: { click: _vm.approveTimesheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.approve")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "decline-extra-small",
                            outlined: "",
                            color: "error",
                            loading: _vm.declining,
                            disabled:
                              _vm.processing || !_vm.timesheetCanBeDeclined,
                            small: ""
                          },
                          on: { click: _vm.declineTimesheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.decline")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-3 mb-3 d-flex d-md-none justify-end align-center v-card__actions"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "history-small",
                            outlined: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.showStatusLogDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.history")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "approve-small",
                            outlined: "",
                            color: "success",
                            loading: _vm.approving,
                            disabled:
                              _vm.processing || !_vm.timesheetCanBeApproved,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.approveTimesheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.approve")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "decline-small",
                            outlined: "",
                            color: "error",
                            loading: _vm.declining,
                            disabled:
                              _vm.processing || !_vm.timesheetCanBeDeclined,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.declineTimesheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.decline")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "submit-small",
                            outlined: "",
                            color: "primary",
                            loading: _vm.submitting,
                            disabled: _vm.isReadonly || _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.submitTimesheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.submit")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mx-3" },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "history",
                            outlined: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.showStatusLogDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.history")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.timesheet.currentUserPermissions.canApprove
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                outlined: "",
                                color: "success",
                                "data-cy": "approve",
                                loading: _vm.approving,
                                disabled:
                                  _vm.processing ||
                                  !_vm.timesheetCanBeApproved ||
                                  _vm.isOverriding,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.approveTimesheet }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.approve")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            outlined: "",
                            color: "success",
                            "data-cy": "approve",
                            loading: _vm.approving,
                            disabled:
                              _vm.processing ||
                              !_vm.timesheetCanBeApproved ||
                              !_vm.isOverriding,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveAndApproveTimesheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$vuetify.breakpoint.lgAndUp
                                  ? _vm.$t(
                                      "timesheets.approval.approve-with-overrides"
                                    )
                                  : _vm.$t(
                                      "timesheets.approval.approve-with-overrides-short"
                                    )
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.timesheet.currentUserPermissions.canApprove
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "d-none d-md-flex",
                              attrs: {
                                "data-cy": "decline",
                                outlined: "",
                                color: "error",
                                loading: _vm.declining,
                                disabled:
                                  _vm.processing || !_vm.timesheetCanBeDeclined,
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.declineTimesheet }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.decline")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "submit",
                            outlined: "",
                            color: "primary",
                            loading: _vm.submitting,
                            disabled:
                              _vm.processing || !_vm.timesheetCanBeSubmitted,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.submitTimesheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.submit")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("fd-menu-save", {
                        attrs: {
                          disabled: _vm.processing || !_vm.timesheetCanBeSaved,
                          loading: _vm.saving,
                          small: _vm.$vuetify.breakpoint.xsOnly
                        },
                        on: {
                          "click:save": function($event) {
                            return _vm.save(false)
                          },
                          "click:save-and-close": function($event) {
                            return _vm.save(true)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }