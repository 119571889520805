import FDVue from "@fd/lib/vue";
import { mapActions, mapMutations } from "vuex";
import { valueInArray } from "@fd/lib/client-util/array";
import {
  addDaysToDate,
  addMonthsToDate,
  isoDateString,
  stripTimeFromLocalizedDateTime
} from "../../../lib/client-util/datetime";
import userAccess from "../dataMixins/userAccess";
import {
  ContractorWithTags,
  Person,
  personService,
  timesheetService,
  TimesheetStatus,
  TimesheetType,
  TimesheetWithDetails,
  userService
} from "../services";
import {
  HasContractorName,
  JoinNameValues,
  PeopleFromSelectableList,
  PersonWithDetailsAndName,
  SelectablePerson
} from "../utils/person";
import archivedDataList from "../dataMixins/archivedDataList";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { DateRangePreset } from "../../../lib/vue/components/DateRangePicker.vue";
import { openTimesheetHistoryDialog } from "./components/dialogs/TimesheetHistoryDialog.vue";

type EntryGroupingType = "groupnone" | "groupperson" | "groupday";

export default FDVue.extend({
  name: "fd-timesheet-corrections",

  mixins: [userAccess, archivedDataList],

  directives: {
    "fd-column": FDColumnDirective,
    "fd-row-navigate": FDRowNavigateDirective
  },

  data: function() {
    return {
      selectedGroupingType: "groupday" as EntryGroupingType,
      timesheets: [] as TimesheetWithDetails[],

      allPeople: [] as Person[],
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined,

      showDirectTimesheets: true,
      showIndirectTimesheets: true,
      showEquipmentTimesheets: true,

      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },
  computed: {
    groupColumn(): string | undefined {
      if (this.selectedGroupingType == "groupperson") return "ownerName";
      else if (this.selectedGroupingType == "groupday") return "isoDay";
      return undefined;
    },
    groupSortDesc(): boolean {
      if (this.selectedGroupingType == "groupday") return true;
      return false;
    },
    visibleTimesheets() {
      let timesheets = this.timesheets;
      timesheets = timesheets.filter(
        x =>
          (this.showDirectTimesheets && x.timesheetTypeID == TimesheetType.Direct) ||
          (this.showIndirectTimesheets && x.timesheetTypeID == TimesheetType.Indirect) ||
          (this.showEquipmentTimesheets && x.timesheetTypeID == TimesheetType.Equipment)
      );
      return timesheets;
    },
    // *** FILTERING ***
    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },
    selectedOwnerID: {
      get() {
        let selectedOwnerIDs = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.peopleForFiltering;
        return !!selectedOwnerIDs?.length ? selectedOwnerIDs[0] : null;
      },
      set(val) {
        if (val == this.selectedOwnerID) return;

        let selectedOwnerIDs = [];
        if (!!val) selectedOwnerIDs.push(val);
        this.$store.commit("SET_PEOPLE_FOR_FILTERING", selectedOwnerIDs);
        // if (!this.screenLoaded) return;
        // this.timesheets = [];
        // this.reloadTableData();
      }
    },
    selectableContractors(): ContractorWithTags[] {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let selectableContractorIDs = this.allPeople.map(x => x.contractorID);
      selectableContractorIDs = [...new Set(selectableContractorIDs)];
      return allContractors
        .filter(x => !!x.tracksEmployeeTime && selectableContractorIDs.includes(x.id))
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;

          return 0;
        });
    },
    selectableContractorsWithCount(): any[] {
      return this.selectableContractors.map(x => {
        return {
          ...x,
          count: this.timesheetCountForContractor(x.id)
        };
      });
    },
    contractorIDsSelectedForFiltering: {
      get(): string[] {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val: string[]) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },
    selectableVisibleOwners(): SelectablePerson[] {
      return this.groupedSelectablePersonList(this.visibleOwners);
    },
    visibleOwners(): (PersonWithDetailsAndName & HasContractorName)[] {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let visibleOwnersWithoutDetails = !this.contractorIDsSelectedForFiltering?.length
        ? this.allPeople
        : this.allPeople.filter(x =>
            valueInArray(x.contractorID, this.contractorIDsSelectedForFiltering)
          );
      let visibleOwnersWithDetails = visibleOwnersWithoutDetails
        .map(x => {
          return {
            ...x,
            name: JoinNameValues(x.firstName, x.lastName),
            contractorName: allContractors.find(c => c.id == x.contractorID)?.name
          } as PersonWithDetailsAndName & HasContractorName;
        })
        .sort((a, b) => {
          let contractorA = (a.contractorName ?? "").toLowerCase();
          let contractorB = (b.contractorName ?? "").toLowerCase();
          if (contractorA < contractorB) return -1;
          else if (contractorA > contractorB) return 1;

          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;

          return 0;
        });

      return visibleOwnersWithDetails;
    },
    visibleOwnersWithTimesheets(): (PersonWithDetailsAndName & HasContractorName)[] {
      return this.visibleOwners.filter(x => this.timesheetCountForPerson(x.id));
    },
    selectableVisibleOwnersWithTimesheets(): SelectablePerson[] {
      return this.groupedSelectablePersonList(this.visibleOwnersWithTimesheets);
    },
    selectableOwnersWithCount(): any[] {
      return this.selectableVisibleOwnersWithTimesheets.map(x => {
        return {
          ...x,
          count: this.timesheetCountForPerson((x as PersonWithDetailsAndName)?.id)
        };
      });
    },
    canViewContractorFilter(): boolean {
      return this.curUserCanViewAllContractors || this.curUserContractorIDs.length != 1;
    },
    canViewPersonFilter(): boolean {
      // Hide the person filter if the current user is the only person in the list
      let people = PeopleFromSelectableList(this.selectableVisibleOwners);
      let isInOwnersList = people.find(x => x.id == this.curUserID);
      let hidePersonFilter = isInOwnersList && people.length == 1;
      return !hidePersonFilter;
    },
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: addDaysToDate(null, 0),
          toDate: addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: addDaysToDate(null, -6),
          toDate: addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: addDaysToDate(null, -13),
          toDate: addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: addMonthsToDate(null, -2),
          toDate: addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    }
  },
  watch: {},
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS"
    }),
    timesheetIsDeclined(item: TimesheetWithDetails) {
      return item.timesheetStatusID == TimesheetStatus.Declined;
    },
    timesheetIsSubmitted(item: TimesheetWithDetails) {
      return item.timesheetStatusID == TimesheetStatus.Submitted;
    },
    labelForGroup(groupValue: string) {
      if (this.selectedGroupingType == "groupperson")
        return this.$t("timesheets.list.person-group-label", [groupValue]);
      else if (this.selectedGroupingType == "groupday")
        return this.$t("timesheets.list.day-group-label", [
          stripTimeFromLocalizedDateTime(groupValue)
        ]);
      return undefined;
    },
    groupedSelectablePersonList(
      visibleOwnersWithDetails: (PersonWithDetailsAndName & HasContractorName)[]
    ): SelectablePerson[] {
      let contractorNames = visibleOwnersWithDetails
        .map(x => x.contractorName)
        .reduce((distinctNames: string[], contractorName: string) => {
          if (!distinctNames.includes(contractorName)) distinctNames.push(contractorName);

          return distinctNames;
        }, []);

      if (contractorNames.length > 1) {
        let contractorGroupedSelectableItems = [] as SelectablePerson[];
        contractorNames.forEach((name, index) => {
          let ownersForContractor = visibleOwnersWithDetails.filter(x => x.contractorName == name);
          if (!ownersForContractor?.length) return;

          if (index > 0) {
            contractorGroupedSelectableItems.push({
              divider: true
            });
          }
          if (!!name) {
            contractorGroupedSelectableItems.push({
              header: name
            });
          }

          contractorGroupedSelectableItems = contractorGroupedSelectableItems.concat(
            ownersForContractor
          );
        });
        return contractorGroupedSelectableItems;
      } else {
        return visibleOwnersWithDetails;
      }
    },
    timesheetCountForContractor(contractorID: string | null | undefined): number | undefined {
      return this.timesheets.filter(x => x.contractorID == contractorID).length;
    },
    timesheetCountForPerson(personID: string | null | undefined): number | undefined {
      return this.timesheets.filter(x => x.ownerID == personID).length;
    },
    // DOES NOT manage processing or error message logic
    async loadOwners(): Promise<void> {
      if (this.currentUserCanCreateIndirectTimesheets) {
        this.allPeople = await userService.getAll(false, null, null);
      } else {
        this.allPeople = await personService.getAllVisibleDirectTimesheetCreators();
      }

      let owners = this.allPeople;
      if (!this.selectedOwnerID) {
        if (owners.length == 1) {
          this.selectedOwnerID = owners[0].id!;
        } else {
          let currentUserOwner = this.visibleOwnersWithTimesheets.find(x => x.id == this.curUserID);
          this.selectedOwnerID = currentUserOwner?.id!;
        }
      }
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    formatDate(date: Date | string | null | undefined): string {
      return stripTimeFromLocalizedDateTime(date);
    },
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      // Load corrected timesheets data
      var timesheets = await timesheetService.getCorrectedTimesheets(
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      this.timesheets = timesheets.map(x => ({
        ...x,
        classification: !!x.ownerClassificationAlias
          ? x.ownerClassificationAlias
          : x.ownerClassificationName,
        timesheetNumberString: `00000${x.timesheetNumber}`.slice(-5),
        timesheetStatus: this.$t(`timesheets.status.${x.timesheetStatusID}`),
        isoDay: isoDateString(x.day),
        formattedDay: stripTimeFromLocalizedDateTime(x.day),
        formattedTotalRegularTime:
          !!x.totalRegularTime && x.totalRegularTime > 0
            ? x.totalRegularTime.toFixed(2)
            : undefined,
        formattedTotalOverTime:
          !!x.totalOverTime && x.totalOverTime > 0 ? x.totalOverTime.toFixed(2) : undefined,
        formattedTotalDoubleTime:
          !!x.totalDoubleTime && x.totalDoubleTime > 0 ? x.totalDoubleTime.toFixed(2) : undefined,
        formattedTotalUnits:
          !!x.totalUnits && x.totalUnits > 0 ? x.totalUnits.toFixed(2) : undefined,
        formattedTotalDays: !!x.totalDays && x.totalDays > 0 ? x.totalDays.toFixed(2) : undefined,
        formattedTotalQuantity:
          !!x.totalQuantity && x.totalQuantity > 0 ? x.totalQuantity.toFixed(2) : undefined,
        correctedBy: !x.lastStatusLog?.correctionMade ? undefined : x.lastStatusLog?.changedByName,
        correctedOn: !x.lastStatusLog?.correctionMade ? undefined : x.lastStatusLog?.changed
      }));

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    fromDateChanged(val: Date) {
      this.maxDate = addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = addMonthsToDate(val, -2);
    },
    async openNewDialog() {
      let timesheetID = await openTimesheetHistoryDialog("timesheetcorrections");
      if (!timesheetID) return;
      this.$router.push(`/timesheetcorrections/${timesheetID}`);
    }
  },
  created: async function() {
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "timesheetcorrections",
      parentalContext: null,
      contractorsForFiltering: [],
      searchStringForFiltering: "",
      contextForFiltering: "all",
      peopleForFiltering: [],
      statusesForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });

    this.notifyNewBreadcrumb({
      text: this.$t("timesheets.corrections.title"),
      to: "/timesheetcorrections",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadContractors(), this.loadOwners()]);
      await this.loadData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  },
  beforeDestroy: function() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});
