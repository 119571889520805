import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import {
  EquipmentClassification,
  contractorService,
  ContractorWithTags,
  EquipmentTimesheetEntryWithDetails,
  timesheetService,
  TimesheetWithDetails,
  ProjectCostCode,
  TimesheetType,
  EquipmentForContractor,
  EquipmentTimesheetEntry
} from "@fd/current/client/services";
import { mapActions } from "vuex";
import { SortItemsWithName } from "@fd/current/client/utils/person";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { VDataTable } from "@fd/lib/vue/types";
import {
  UpdatableEquipmentTimesheetEntryWithDetails,
  UpdatableTimesheetWithEntries
} from "../../../utils/timesheet";
import userAccess from "../../../dataMixins/userAccess";
import { SelectListOption } from "@fd/lib/vue/utility/select";
import { valueInArray } from "@fd/lib/client-util/array";

const TimesheetEquipmentEntriesAddDialog = FDVue.extend({
  name: "sp-add-timesheet-equipment-entries-dialog",

  mixins: [dialogSupport, rules, userAccess],

  components: {
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      tablepage: 1,
      timesheet: { currentUserPermissions: {} } as UpdatableTimesheetWithEntries,
      existingEntries: [] as EquipmentTimesheetEntry[],
      contractor: {} as ContractorWithTags,
      selectedCostCodeID: null as string | null,
      selectedEquipmentID: null as string | null
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return [
        "saveDialog",
        "getFieldRef",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "clearValues",
        "canEditRegularTimeForItem",
        "canEditDaysForItem",
        "canEditQuantityForItem"
      ];
    },
    timesheetRules(): any {
      return {
        costCodeID: [this.rules.required]
      };
    },
    canAddEntriesWithCurrentSelections(): boolean {
      if (!this.selectedEquipmentID) return false;
      return true;
    },
    selectableCostCodes(): ProjectCostCode[] {
      if (!this.contractor?.costCodeIDs?.length) return [];
      if (!this.selectedEquipment) return [];

      var environmentDefaultCostCodeID = this.$store.state.curEnvironment
        .defaultEquipmentCostCodeID;

      var equipmentClassification = this.getEquipmentClassificationForID(
        this.selectedEquipment.equipmentClassificationID
      );
      var defaultClassificationCostCodeID = equipmentClassification?.defaultCostCodeID;

      var contractorClassificationCostCodeID = this.contractor.equipmentClassifications?.find(
        x =>
          !!x.equipmentClassificationID &&
          x.equipmentClassificationID == this.selectedEquipment?.equipmentClassificationID
      )?.costCodeID;

      var allowedCostCodeIDs: string[] = [];
      if (!!environmentDefaultCostCodeID) allowedCostCodeIDs.push(environmentDefaultCostCodeID);
      if (!!defaultClassificationCostCodeID)
        allowedCostCodeIDs.push(defaultClassificationCostCodeID);
      if (!!contractorClassificationCostCodeID)
        allowedCostCodeIDs.push(contractorClassificationCostCodeID);
      allowedCostCodeIDs = [...new Set(allowedCostCodeIDs)];

      if (!valueInArray(this.selectedCostCodeID, allowedCostCodeIDs))
        this.selectedCostCodeID = null;
      if (!this.selectedCostCodeID && !!contractorClassificationCostCodeID)
        this.selectedCostCodeID = contractorClassificationCostCodeID;
      if (allowedCostCodeIDs.length == 1 && !this.selectedCostCodeID)
        this.selectedCostCodeID = allowedCostCodeIDs[0];

      return SortItemsWithName(
        (this.$store.state.projectCostCodes.fullList as ProjectCostCode[]).filter(
          x =>
            this.contractor.costCodeIDs?.includes(x.id!) && valueInArray(x.id, allowedCostCodeIDs)
        )
      );
    },
    selectedCostCode(): ProjectCostCode | undefined {
      return this.selectableCostCodes.find(x => x.id == this.selectedCostCodeID);
    },
    currentTimesheetIsEquipment(): boolean {
      return (this.timesheet.timesheetTypeID ?? 0) == TimesheetType.Equipment;
    },
    selectableEquipment(): SelectListOption<EquipmentForContractor>[] {
      let allEquipment = this.$store.state.equipment.fullList as EquipmentForContractor[];
      let selectableEquipment = allEquipment?.filter(
        x => x.contractorID == this.timesheet.contractorID
      );

      return SortItemsWithName(
        selectableEquipment.map(x => ({
          ...x,
          disabled:
            this.timesheet.equipmentEntries.findIndex(e => e.equipmentID == x.id) !== -1 ||
            this.existingEntries.findIndex(e => e.equipmentID == x.id) !== -1
        }))
      );
    },
    selectedEquipment(): EquipmentForContractor | undefined {
      return this.selectableEquipment.find(x => x.id == this.selectedEquipmentID);
    }
  },

  watch: {},

  methods: {
    ...mapActions({
      loadEquipmentForContractor: "LOAD_EQUIPMENT_FOR_CONTRACTOR",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadEquipmentClassifications: "LOAD_EQUIPMENT_CLASSIFICATIONS"
    }),
    async loadData() {
      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        await Promise.all([
          this.loadEquipmentForContractor({ contractorID: this.timesheet.contractorID! }),
          this.loadCostCodes(),
          this.loadEquipmentClassifications()
        ]);
        // await this.loadTimesheet(timesheetID);
        this.contractor = await contractorService.getByID(this.timesheet.contractorID!);
        if (this.selectableCostCodes.length == 1)
          this.selectedCostCodeID = this.selectableCostCodes[0].id!;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(timesheet: TimesheetWithDetails, existingEntries: EquipmentTimesheetEntry[]) {
      this.timesheet = new UpdatableTimesheetWithEntries(timesheet, []);
      this.existingEntries = existingEntries;
      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    async clearValues() {
      this.optOutOfErrorHandling();
      this.selectedCostCodeID = null;
      this.selectedEquipmentID = null;
    },
    async addEntriesToWorkspace() {
      this.optOutOfErrorHandling();
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.addform as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        if (!!this.selectedEquipment) {
          let newID = this.timesheet.equipmentEntries.length.toString();
          this.timesheet.equipmentEntries.push(
            new UpdatableEquipmentTimesheetEntryWithDetails({
              id: newID,
              timesheetID: this.timesheet.id,
              equipmentID: this.selectedEquipment.id,
              equipmentName: this.selectedEquipment.name,
              equipmentSerialNumber: this.selectedEquipment.serialNumber,
              equipmentModelNumber: this.selectedEquipment.modelNumber,
              equipmentClassificationID: this.selectedEquipment.equipmentClassificationID,
              classificationName: this.getEquipmentClassificationForID(
                this.selectedEquipment.equipmentClassificationID
              )?.name,
              costCodeName: this.selectedCostCode?.name,
              created: new Date()
            } as EquipmentTimesheetEntryWithDetails)
          );
          // The following properties get cached as "Loaded" when created as new
          // This means that unless the value changes it won't be considered modified and will get sent to the server as undefined:
          // costCodeID, quantity, days
          let existingEntry = this.timesheet.equipmentEntries.find(x => x.id == newID)!;
          existingEntry.costCodeID = this.selectedCostCode?.id;
          existingEntry.regularTime = 0;
          existingEntry.days = 0;
          existingEntry.quantity = 0;
        }

        this.timesheet.equipmentEntries.sort((a, b) => {
          // Sort with newest at top
          let aCreated = a.created!;
          let bCreated = b.created!;
          return bCreated.getTime() - aCreated.getTime();
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    removeEntry(entry: UpdatableEquipmentTimesheetEntryWithDetails) {
      const index = this.timesheet.equipmentEntries.indexOf(entry);
      if (index < 0) {
        return;
      }
      this.timesheet.equipmentEntries.splice(index, 1);
    },
    addFormOnSubmit(e: Event) {
      e.preventDefault();
      this.addEntriesToWorkspace;
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    async saveDialog() {
      if (!this.timesheet.equipmentEntries.length) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        let errorEntries = this.timesheet.equipmentEntries.filter(
          x => !x.regularTime && !x.days && !x.quantity
        );
        if (errorEntries.length) {
          this.inlineMessage.message = this.$t(
            "timesheets.entries.equipment-entries-missing-data-error-message"
          );
          this.processing = false;
          return;
        }
        await timesheetService.addEquipmentEntriesToTimesheetWithID(
          this.timesheet.id!,
          this.timesheet.equipmentEntries.map(
            x =>
              ({
                ...x,
                ...x.modifiedData,
                id: undefined,
                created: undefined
              } as EquipmentTimesheetEntryWithDetails)
          )
        );
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    getEquipmentClassificationForID(
      classificationID: string | null | undefined
    ): EquipmentClassification | undefined {
      return (this.$store.state.equipmentClassifications
        .fullList as EquipmentClassification[]).find(x => x.id == classificationID);
    },
    canEditRegularTimeForItem(item: EquipmentTimesheetEntryWithDetails): boolean {
      return (
        this.timesheet.timesheetTypeID != TimesheetType.Equipment &&
        this.timesheet.currentUserPermissions.canEditExistingEntries
      );
    },
    canEditDaysForItem(item: EquipmentTimesheetEntryWithDetails): boolean {
      return (
        this.timesheet.timesheetTypeID == TimesheetType.Equipment &&
        this.timesheet.currentUserPermissions.canEditExistingEntries
      );
    },
    canEditQuantityForItem(item: EquipmentTimesheetEntryWithDetails): boolean {
      return (
        this.timesheet.timesheetTypeID == TimesheetType.Equipment &&
        this.timesheet.currentUserPermissions.canEditExistingEntries
      );
    },

    // *** INLINE NAVIGATION ***
    getFieldRef(fieldName: string, item: EquipmentTimesheetEntryWithDetails) {
      let id = item.id!.replace("-", "").replace("-", "");
      return `${fieldName}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, index: number) {
      let visibleItems = (this.$refs.datatable as VDataTable).internalCurrentItems;
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(fieldName: string, item: EquipmentTimesheetEntryWithDetails) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.tablepage <= 1) return;
        this.tablepage -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, datatable.computedItemsPerPage);
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex);
    },
    async selectNextField(fieldName: string, item: EquipmentTimesheetEntryWithDetails) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex >= visibleParts.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(this.timesheet.equipmentEntries.length / datatable.computedItemsPerPage);
        if (this.tablepage >= maxPage) return;

        this.tablepage += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, 0);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex);
    },
    async enterPressed(
      e: KeyboardEvent,
      fieldName: string,
      item: EquipmentTimesheetEntryWithDetails
    ) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
  }
});

export default TimesheetEquipmentEntriesAddDialog;

export async function addTimesheetEquipmentEntries(
  timesheet: UpdatableTimesheetWithEntries
): Promise<string | boolean> {
  let dialog = createDialog(TimesheetEquipmentEntriesAddDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(timesheet, timesheet.equipmentEntries);
}
