import FDVue from "@fd/lib/vue";
import { PropValidator } from "vue/types/options";

import WorkOrderDetailAlertComponent, {
  WorkOrderAlertDetails,
  WalkdownAlertDetails
} from "./WorkOrderDetailAlert.vue";
import WorkOrderRequestDetailsComponent, {
  WorkOrderRequestDetails
} from "./WorkOrderRequestDetails.vue";
import WorkOrderLocationDetailsComponent, {
  WorkOrderLocationDetails
} from "./WorkOrderLocationDetails.vue";
import WorkOrderNotesComponent, { WorkOrderWithNotes } from "./WorkOrderNotes.vue";
import WorkOrderScopeDetailsComponent, { WorkOrderScopeDetails } from "./WorkOrderScopeDetails.vue";
import WalkdownWorkOrderDetailsComponent, {
  WalkdownWorkOrderDetails,
  WalkdownDetails
} from "./WorkOrderWalkdownDetails.vue";
import {
  reportService,
  WalkdownWithRequestDetails,
  WorkOrderWithAllDetails
} from "../../../../services";
import downloadBlob from "../../../../../../lib/client-util/downloadBlob";
import printBlob from "../../../../../../lib/client-util/printBlob";
import {
  ScaffoldRequestSubTypeLabels,
  ScaffoldRequestTypeLabels
} from "../../../../store/referenceData/enum";
import moment from "moment";

export type WorkOrderDetailRecord = WorkOrderAlertDetails &
  WorkOrderRequestDetails &
  WorkOrderLocationDetails &
  WorkOrderWithNotes &
  WorkOrderScopeDetails &
  WalkdownWorkOrderDetails;
export type WalkdownDetailRecord = WalkdownDetails & WalkdownAlertDetails;

export default FDVue.extend({
  name: "fd-work-order-details",

  components: {
    "fd-work-order-detail-alert": WorkOrderDetailAlertComponent,
    "fd-work-order-request-details": WorkOrderRequestDetailsComponent,
    "fd-work-order-location-details": WorkOrderLocationDetailsComponent,
    "fd-work-order-notes": WorkOrderNotesComponent,
    "fd-work-order-scope-details": WorkOrderScopeDetailsComponent,
    "fd-work-order-walkdown-details": WalkdownWorkOrderDetailsComponent
  },

  props: {
    workOrder: { type: [Object] } as PropValidator<WorkOrderDetailRecord>,
    walkdown: { type: [Object] } as PropValidator<WalkdownDetailRecord>,
    showEditControls: { type: Boolean, default: false },
    editControlsDisabled: { type: Boolean, default: false },
    hideScopeValues: { type: Boolean, default: false },
    cy: { type: String, default: "fd-work-order-details" }
  },

  methods: {
    async downloadAndPrintPlannerReport(reportType: string = "pdf") {
      const date = moment(this.$props.workOrder.lastStatusChangedDate).format(
        "ddd, MMM D, YYYY h:mm A"
      );

      var workOrder = this.$props.workOrder as WorkOrderWithAllDetails;
      var walkDown = this.$props.walkdown as WalkdownWithRequestDetails;
      this.setWorkOrderValues(workOrder);
      const iwp = Array.isArray(this.workOrder.workPackageNames)
        ? this.workOrder.workPackageNames?.join(",")
        : "";
      var blob = await reportService.getSchedulePrintoutReportContentWithData(
        workOrder,
        walkDown,
        reportType,
        this.workOrder.requestSubmittedOn!,
        iwp,
        date,
        true
      );
      if (reportType == "xls") {
        downloadBlob(blob, "schedular-printout.xlsx");
      } else {
        printBlob(blob, "schedular-printout.pdf", "application/pdf");
      }
    },

    setWorkOrderValues(workOrder: WorkOrderWithAllDetails) {
      workOrder.requestTypeName =
        ScaffoldRequestTypeLabels[
          this.$props.workOrder.requestType! as keyof typeof ScaffoldRequestTypeLabels
        ];
      workOrder.requestSubTypeName =
        ScaffoldRequestSubTypeLabels[
          this.$props.workOrder.requestSubType! as keyof typeof ScaffoldRequestSubTypeLabels
        ];
      workOrder.lastStatusChangedByCredentialID = this.$props.workOrder.lastStatusChangedBy;
      workOrder.requestSubmitterCredentialID = this.$props.workOrder.requestSubmitterIDs?.join(
        ", "
      );
    }
  }
});
