import FDVue from "@fd/lib/vue";
import { BasicSelectItem } from "@fd/lib/vue/utility/select";
import { AccessInformation, JobTypes } from "../services";

export type SoftCraftFilteringContextOptions =
  | "all"
  | "scaffold"
  | "maintenance"
  | "paint"
  | "insulation"
  | "heattrace"
  | "refractory"
  | "fireproofing";
export const AllSoftCraftOptions: Array<SoftCraftFilteringContextOptions> = [
  "scaffold",
  "maintenance",
  "paint",
  "insulation",
  "heattrace",
  "refractory",
  "fireproofing"
];
export function IncludeSoftCraftContextInArray(
  array: Array<SoftCraftFilteringContextOptions> | null | undefined,
  softCraftContext: SoftCraftFilteringContextOptions
): Array<SoftCraftFilteringContextOptions> {
  if (!array) return [softCraftContext];

  if (!array.includes(softCraftContext) && !array.includes("all")) {
    array.push(softCraftContext);
  }

  return array;
}
export function ExcludeSoftCraftContextInArray(
  array: Array<SoftCraftFilteringContextOptions> | null | undefined,
  softCraftContext: SoftCraftFilteringContextOptions
): Array<SoftCraftFilteringContextOptions> {
  if (!array) return [];

  // If the array contains "all" softcrafts, we need them itemized to remove the specific one we want gone
  // Set it to the list of individual soft crafts insteead
  if (array.includes("all")) {
    array = AllSoftCraftOptions;
  }

  // We don't need to look for "all" here since we just did it above
  let index = array.indexOf(softCraftContext);
  if (index > -1) {
    array.splice(index, 1);
  }

  return array;
}
export function GetDefaultVisibleSoftCraftContexts(
  curUserAccess: AccessInformation
): Array<SoftCraftFilteringContextOptions> {
  let visibleContexts: Array<SoftCraftFilteringContextOptions> = [];

  // Scaffold is currently always visible
  visibleContexts.push("scaffold");

  if (curUserAccess.canViewMaintenanceJobs) visibleContexts.push("maintenance");
  if (curUserAccess.canViewPaintJobs) visibleContexts.push("paint");
  if (curUserAccess.canViewInsulationJobs) visibleContexts.push("insulation");
  if (curUserAccess.canViewHeatTraceJobs) visibleContexts.push("heattrace");
  if (curUserAccess.canViewRefractoryJobs) visibleContexts.push("refractory");
  if (curUserAccess.canViewFireproofingJobs) visibleContexts.push("fireproofing");

  // if (visibleContexts.length == SoftCraftOptions.length) visibleContexts = ["all"];

  return visibleContexts;
}

export const softCrafts = FDVue.extend({
  name: "sp-soft-crafts",
  data: function() {
    return {
      allowAllSelection: true
    };
  },
  computed: {
    visibleJobCount(): number {
      return (
        (this.showScaffoldColumn ? 1 : 0) +
        (this.showMaintenanceColumn ? 1 : 0) +
        (this.showPaintColumn ? 1 : 0) +
        (this.showInsulationColumn ? 1 : 0) +
        (this.showHeatTraceColumn ? 1 : 0) +
        (this.showRefractoryColumn ? 1 : 0) +
        (this.showFireproofingColumn ? 1 : 0)
      );
    },
    onlyVisibleJobType(): JobTypes | null {
      if (this.visibleJobCount != 1) return null;

      if (this.showMaintenanceColumn) return JobTypes.Maintenance;
      else if (this.showPaintColumn) return JobTypes.Paint;
      else if (this.showInsulationColumn) return JobTypes.Insulation;
      else if (this.showHeatTraceColumn) return JobTypes.HeatTrace;
      else if (this.showRefractoryColumn) return JobTypes.Refractory;
      else if (this.showFireproofingColumn) return JobTypes.Fireproofing;
      else return JobTypes.Scaffold;
    },
    showCraftOptions(): boolean {
      return this.visibleJobCount > 1;
    },
    showScaffoldColumn(): boolean {
      return true;
    },
    showMaintenanceColumn(): boolean {
      return this.$store.state.curEnvironment.enableMaintenanceWork ?? false;
    },
    showPaintColumn(): boolean {
      return this.$store.state.curEnvironment.enablePaintWork ?? false;
    },
    showInsulationColumn(): boolean {
      return this.$store.state.curEnvironment.enableInsulationWork ?? false;
    },
    showHeatTraceColumn(): boolean {
      return this.$store.state.curEnvironment.enableHeatTraceWork ?? false;
    },
    showRefractoryColumn(): boolean {
      return this.$store.state.curEnvironment.enableRefractoryWork ?? false;
    },
    showFireproofingColumn(): boolean {
      return this.$store.state.curEnvironment.enableFireproofingWork ?? false;
    },
    filteringContextOptions(): BasicSelectItem[] {
      let options = [];
      if (this.allowAllSelection) {
        options.push({
          text: this.$t("common.all") as string,
          value: "all" as SoftCraftFilteringContextOptions
        });
      }
      if (this.showScaffoldColumn) {
        options.push({
          text: this.$t("countsheetgroups.is-scaffold-group") as string,
          value: "scaffold" as SoftCraftFilteringContextOptions
        });
      }
      if (this.showMaintenanceColumn) {
        options.push({
          text: this.$t("countsheetgroups.is-maintenance-group") as string,
          value: "maintenance" as SoftCraftFilteringContextOptions
        });
      }
      if (this.showPaintColumn) {
        options.push({
          text: this.$t("countsheetgroups.is-paint-group") as string,
          value: "paint" as SoftCraftFilteringContextOptions
        });
      }
      if (this.showInsulationColumn) {
        options.push({
          text: this.$t("countsheetgroups.is-insulation-group") as string,
          value: "insulation" as SoftCraftFilteringContextOptions
        });
      }
      if (this.showHeatTraceColumn) {
        options.push({
          text: this.$t("countsheetgroups.is-heattrace-group") as string,
          value: "heattrace" as SoftCraftFilteringContextOptions
        });
      }
      if (this.showRefractoryColumn) {
        options.push({
          text: this.$t("countsheetgroups.is-refractory-group") as string,
          value: "refractory" as SoftCraftFilteringContextOptions
        });
      }
      if (this.showFireproofingColumn) {
        options.push({
          text: this.$t("countsheetgroups.is-fireproofing-group") as string,
          value: "fireproofing" as SoftCraftFilteringContextOptions
        });
      }

      return options;
    }
  },

  methods: {
    jobTypeForFilteringContext(
      filterContext: SoftCraftFilteringContextOptions | null
    ): JobTypes | null {
      let jobType: JobTypes | null = null;
      if (filterContext == "scaffold") jobType = JobTypes.Scaffold;
      else if (filterContext == "paint") jobType = JobTypes.Paint;
      else if (filterContext == "maintenance") jobType = JobTypes.Maintenance;
      else if (filterContext == "insulation") jobType = JobTypes.Insulation;
      else if (filterContext == "heattrace") jobType = JobTypes.HeatTrace;
      else if (filterContext == "refractory") jobType = JobTypes.Refractory;
      else if (filterContext == "fireproofing") jobType = JobTypes.Fireproofing;
      return jobType;
    },
    filteringContextForJob(jobType: JobTypes | null): SoftCraftFilteringContextOptions {
      if (jobType == null) return "all";

      if (jobType == JobTypes.Maintenance) return "maintenance";
      else if (jobType == JobTypes.Fireproofing) return "fireproofing";
      else if (jobType == JobTypes.HeatTrace) return "heattrace";
      else if (jobType == JobTypes.Insulation) return "insulation";
      else if (jobType == JobTypes.Paint) return "paint";
      else if (jobType == JobTypes.Refractory) return "refractory";
      else return "scaffold";
    }
  }
});

export type SoftCraftFilteringContext = {
  showSoftCraftRequestFilter: Array<SoftCraftFilteringContextOptions>;
};
export const softCraftFiltering = FDVue.extend({
  mixins: [softCrafts],
  computed: {
    allSoftCraftOptionsSelected(): boolean {
      return this.softCraftFilter.length == AllSoftCraftOptions.length;
    },
    filteringContext: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },
    softCraftFilter: {
      get(): Array<SoftCraftFilteringContextOptions> {
        return this.filteringContext.showSoftCraftRequestFilter;
      },
      set(val: Array<SoftCraftFilteringContextOptions>) {
        // console.log(`softCraftFilter.set`);
        this.filteringContext.showSoftCraftRequestFilter = val;
      }
    },
    showScaffoldRequests: {
      get(): boolean {
        return this.softCraftFilter.includes("all") || this.softCraftFilter.includes("scaffold");
      },
      set(val: boolean) {
        if (val) {
          this.softCraftFilter = IncludeSoftCraftContextInArray(this.softCraftFilter, "scaffold");
        } else {
          this.softCraftFilter = ExcludeSoftCraftContextInArray(this.softCraftFilter, "scaffold");
        }
      }
    },
    showMaintenanceRequests: {
      get(): boolean {
        return this.softCraftFilter.includes("all") || this.softCraftFilter.includes("maintenance");
      },
      set(val: boolean) {
        if (val) {
          this.softCraftFilter = IncludeSoftCraftContextInArray(
            this.softCraftFilter,
            "maintenance"
          );
        } else {
          this.softCraftFilter = ExcludeSoftCraftContextInArray(
            this.softCraftFilter,
            "maintenance"
          );
        }
      }
    },
    showPaintRequests: {
      get(): boolean {
        return this.softCraftFilter.includes("all") || this.softCraftFilter.includes("paint");
      },
      set(val: boolean) {
        if (val) {
          this.softCraftFilter = IncludeSoftCraftContextInArray(this.softCraftFilter, "paint");
        } else {
          this.softCraftFilter = ExcludeSoftCraftContextInArray(this.softCraftFilter, "paint");
        }
      }
    },
    showInsulationRequests: {
      get(): boolean {
        return this.softCraftFilter.includes("all") || this.softCraftFilter.includes("insulation");
      },
      set(val: boolean) {
        if (val) {
          this.softCraftFilter = IncludeSoftCraftContextInArray(this.softCraftFilter, "insulation");
        } else {
          this.softCraftFilter = ExcludeSoftCraftContextInArray(this.softCraftFilter, "insulation");
        }
      }
    },
    showHeatTraceRequests: {
      get(): boolean {
        return this.softCraftFilter.includes("all") || this.softCraftFilter.includes("heattrace");
      },
      set(val: boolean) {
        if (val) {
          this.softCraftFilter = IncludeSoftCraftContextInArray(this.softCraftFilter, "heattrace");
        } else {
          this.softCraftFilter = ExcludeSoftCraftContextInArray(this.softCraftFilter, "heattrace");
        }
      }
    },
    showRefractoryRequests: {
      get(): boolean {
        return this.softCraftFilter.includes("all") || this.softCraftFilter.includes("refractory");
      },
      set(val: boolean) {
        if (val) {
          this.softCraftFilter = IncludeSoftCraftContextInArray(this.softCraftFilter, "refractory");
        } else {
          this.softCraftFilter = ExcludeSoftCraftContextInArray(this.softCraftFilter, "refractory");
        }
      }
    },
    showFireproofingRequests: {
      get(): boolean {
        return (
          this.softCraftFilter.includes("all") || this.softCraftFilter.includes("fireproofing")
        );
      },
      set(val: boolean) {
        if (val) {
          this.softCraftFilter = IncludeSoftCraftContextInArray(
            this.softCraftFilter,
            "fireproofing"
          );
        } else {
          this.softCraftFilter = ExcludeSoftCraftContextInArray(
            this.softCraftFilter,
            "fireproofing"
          );
        }
      }
    }
  }
});
